import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAllMapping, fetchMappingZunsolar } from "../../actions/fetchAllMapping";
import HeaderComponent from "../HeaderComponent";
import { editZunsolarLead } from "../../actions/fetchBitrixId";

class MtCreateLeadThird extends Component {

    componentWillMount() {
        let viewList = ["employees"];
        this.props.fetchAllMapping(viewList);
        this.props.fetchMappingZunsolar();
      
         
    }

    constructor(props) {
        super(props)

        this.state = {
            leadData: {},
            newLeadData: {},
            mappingData: {},
            employeesArr: [],
            userObj: {},
            finalLeadData: {
                onboardedBy: ''
            }

        }
    }
    componentWillReceiveProps(props) {

        // const bitrixId=this.props.bitrixId
        
        // if(!(this.props.bitrixId)){
        //     window.open(window.location.origin + "/mt/home", "_self")
        // }

        // let solarId = this.props.match.params.id

        // if(!solarId){
        //     window.open(window.location.origin + "/mt/home", "_self")
        //     return
        //  }
        let { finalLeadData } = this.state;
       
        let userObj = JSON.parse(localStorage.getItem("userObj"));
        let username = userObj.username;

        let onboardedBy = username;
        finalLeadData.onboardedBy = onboardedBy;
        let defaultResponsible = props.allMapping && props.allMapping.employees ? props.allMapping.employees.find((user) => user.e_email == username) : "";
        let allEmployees = props.allMapping && props.allMapping.employees ? props.allMapping.employees : []
        let employeesName = []

        allEmployees.forEach((emp) => {
            employeesName.push(emp.e_name)
        })

        this.setState({
            leadData: {
                source: "Referral",
                stage: "New",
                active: "Yes",
                responsible_person: defaultResponsible ? defaultResponsible.e_name : "Prateek Suraj",
                has_gst: "NO",
                onboardedBy: username,
                partner_onboarding_date: moment().format("YYYY-MM-DD")

            },
            userObj: userObj,
            finalLeadData,
            mappingData: (props.allMapping && props.allMapping.mapping) ? props.allMapping.mapping : {},
            stateCityMap: props.allMapping ? props.allMapping.stateCityMap : {},
            employeesArr: employeesName
        })

    }

    checkSelectString = (str) => {
        if (str == "not selected") {
            return str = ""
        }
        else {
            return str
        }
    }
    handleSubmitFinalData = (e) => {
        let { newLeadData, finalLeadData } = this.state;

        const mtLeadData=this.props.mtLeadData
        // let solarId = this.props.bitrixId;
          let solarId = this.props.match.params.id

        newLeadData.onboardedBy=finalLeadData.onboardedBy

        Object.keys(newLeadData).forEach((key)=>{
            if(!mtLeadData.hasOwnProperty(key)){
                mtLeadData[key]=''
            }
        })
        
        this.props.editZunsolarLead(newLeadData, mtLeadData,solarId, true);
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        value = this.checkSelectString(value);

        this.setState({

            newLeadData: {
                ...this.state.newLeadData,
                [name]: value,
            }
        })
    }

    inputType = (name, value, type, placeholder, disabled = false) => {
        return <input type={type} name={name} value={value} disabled={disabled} onChange={(e) => this.handleInputChange(e)} placeholder={placeholder} />
    }

    selectType = (name, value, options, placeholder) => {

        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleInputChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option>{placeholder}</option>
                    {(options ? options : []).map(e => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }
    render() {
        return (<>

            <HeaderComponent />
            <div className="createDealerContainer">

                <div className="dlr-sec-id">
                    <h3 className="dealerId-cnt">Dealer Id   <p>{this.props.match.params.id}</p> </h3>
                </div>

                <div className="form-select">
                    {this.selectType("stage", this.state.finalLeadData.stage, this.state.mappingData.stage || [], "Stage")}
                </div>

                <div className="form-select">
                    {this.selectType("responsible_person", this.state.finalLeadData.responsible_person, this.state.employeesArr || [], "Responsible")}
                </div>

                <div className="form-ctn">
                    {this.inputType("onboardedBy", this.state.finalLeadData.onboardedBy, "text", 'onboarded by')}
                </div>

                <div className="form-ctn">
                    {this.inputType("nextActionDate", this.state.finalLeadData.nextActionDate, 'date', "Next action date")}
                </div>

                <div className="dn">
                    <button className="dn-btn" onClick={(e) => this.handleSubmitFinalData(e)}>
                        Done
                    </button>
                </div>

            </div>
        </>)
    }
}


function mapStateToProps(state) {
    return {
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
        createLeadSuccess: state.createLead ? state.createLead.success : {},
        // bitrixId: state.createLead ? state.createLead.bitrixId : '',
        mtLeadData:state.createLead ? state.createLead.mtLeadData:{}
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllMapping, fetchMappingZunsolar, editZunsolarLead }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MtCreateLeadThird);