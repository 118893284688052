import { connect } from "react-redux";
import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { fetchMTPayment } from '../../actions/fetchMTActions'
import { fetchAllMapping } from "../../actions/fetchAllMapping";
import '../styles/paymentSummary.css'
import Select from 'react-select';
import moment from "moment";
import MobileHeaderComponent from "../MobileHeaderComponent";
import HeaderComponent from "../HeaderComponent";


class MTPaymentHistory extends Component {

    constructor(props) {

        super(props)

        this.state = {
            filters: {

            },

            paymentHistoryFilteredData: [],
            paymentHistoryOriginalData: [],
            userObj: {},
            optionsMapping: {},
            totalAmount: 0,
            statusMapping: [{ label: "all", value: "all" }, { label: "Cleared", value: "Cleared" }, { label: "uncleared", value: "uncleared" }]
        }
    }

    componentWillMount() {
        let userObj = JSON.parse(localStorage.getItem("userObj"))
        this.setState({
            userObj
        })
        this.props.fetchMTPayment(userObj.email)
        // this.props.fetchAllMapping(viewList);


    }
    componentWillReceiveProps(props) {
        let { mtPaymentHistory, allMapping } = props
        let totalAmount = 0;
        
        mtPaymentHistory.forEach((data)=>{totalAmount+=parseInt(data.amount)})

      mtPaymentHistory =   mtPaymentHistory.map((paymentData, index)=>{
         paymentData.paymentDate = moment(paymentData.paymentDate).format("DD-MM-YYYY")
         return paymentData
        })
        this.setState({
            paymentHistoryFilteredData: mtPaymentHistory,
            paymentHistoryOriginalData: mtPaymentHistory,
            totalAmount
        })
    }
    handleMultiSelectChange = (e, name) => {
        let { value } = e.target;
        let { filters } = this.state;
        filters = JSON.parse(JSON.stringify(filters))
        filters[name] = value;
        this.setState({
            filters
        })
    }
    handleFilterInputChange = (e) => {
        let { name, value } = e.target;
        let { filters } = this.state;
        filters = JSON.parse(JSON.stringify(filters))
        filters[name] = value;
        this.setState({
            filters
        })
    }

    multiSelectType = (name, options, placeholder, value) => {

        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { this.handleMultiSelectChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }
    handleSelectChange = (e, name) => {
        let { value } = e;
        let { filters } = this.state;
        filters = JSON.parse(JSON.stringify(filters))
        filters[name] = value;
        this.setState({
            filters
        })
    }

    inputTypeFilter = (name, value, type, placeholder) => {
        return <input type={type} placeholder={placeholder} style={{ padding: "8px 8px", border: "1px solid #ced4da" }} className="form-control" name={name} value={value} onChange={(e) => this.handleFilterInputChange(e)} />
    }


    optionFunction = (array, value) => {
        let multiSelectOptionsMap = array.map((data) => {
            let obj = {}

            if (data[value]) {
                obj = {
                    label: data[value].replace(/(^"|"$)/g, ''),
                    value: data[value].replace(/(^"|"$)/g, '')
                }
            } else {
                obj = {
                    label: data,
                    value: data
                }
            }
            return obj
        })
        return multiSelectOptionsMap;
    }

    optionFunctionState = (array) => {
        let multiSelectOptionsStateMap = array.map((val) => {
            let obj = {
                label: val.replace(/(^"|"$)/g, ''),
                value: val.replace(/(^"|"$)/g, ''),
            }
            return obj;
        })
        return multiSelectOptionsStateMap
    }
    InputTypeText = (name, value, type) => {
        return (
            <input type={type} name={name} value={value} className="form-control" style={{ border: "1px solid #ced4da" }} onChange={(e) => this.handleFilterInputChange(e)} ></input>
        )
    }
    inputType = (name, placeholder, type) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    {type == "date" && <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>}
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => this.handleFilterInputChange(e)} />
                </div>
            </div>
        )
    }

    onApplyFilter = () => {
        let { filters, paymentHistoryFilteredData, paymentHistoryOriginalData } = this.state;
        let startDate = filters.startDate;
        let endDate = filters.endDate;
        let status = filters.status;
        let filteredPaymentData = paymentHistoryOriginalData;
        if(startDate){
            startDate =  moment(startDate).format("DD-MM-YYYY")
            filteredPaymentData = filteredPaymentData.filter((paymentData)=>{
                return paymentData.paymentDate>=startDate
            })
        }
        if(endDate){
            endDate =  moment(endDate).format("DD-MM-YYYY")
            filteredPaymentData = filteredPaymentData.filter((paymentData)=>{
              return  paymentData.paymentDate<=endDate
            })
        }
        if(status){
            if(status =="Cleared"){
                filteredPaymentData = filteredPaymentData.filter((paymentData)=>{
                    return paymentData.status=="Cleared"
                })
            }
            if(status =="uncleared"){
                filteredPaymentData = filteredPaymentData.filter((paymentData)=>{
                    return paymentData.status!="Cleared"
                })
            }
        }
        console.log('filteredPaymentData after',filteredPaymentData);

        this.setState({
            paymentHistoryFilteredData:filteredPaymentData
        })
        


    }


    SelectType = ({ label, name, options, placeholder, disabled, required = false }) => {
        return (
            <div
                className="formgroupContainer"
           style={{margin:'0' , marginTop:'8px'}} >
                {/* <div className="form-group"> */}
                {/* <div className="mb-3"> */}
                <label>{label}</label> 
                <div style={{ width: "60%",marginLeft:'26px'}}>
                    <Select
                        isSearchable
                        onChange={(e) => { this.handleSelectChange(e, name) }}
                        placeholder={placeholder}
                        name={name}
                        options={options}
                        isDisabled={disabled}
                        required={required}
                        id={name}

                    />

                </div>
                {/* //   </div> */}
            </div>
        )
    }

    render() {

        const mystyle = {
            color: "#000",
            backgroundColor: "#e7e9eb",
          };
        const { paymentHistoryOriginalData, paymentHistoryFilteredData, filters, statusMapping,totalAmount } = this.state
        let { SelectType } = this
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        return (
            <div>
                <HeaderComponent/>
            <div className="mainPaymentHistory">
                <div className="topHeadContainer">
                    <h5> Payment History</h5>

                </div>
                <div className="paymentHistoryFilter">
                    <div className="date-status">
                        <div className="date-con">
                            <label>Start Date</label>
                            {this.inputTypeFilter("startDate", filters.startDate, "date",)}
                        </div>

                        <div className="date-con">
                            <label>End Date</label>
                            {this.inputTypeFilter("endDate", filters.endDate, "date")}
                        </div>

               <SelectType label="Status" name="status" options={statusMapping || []} placeholder="select status" value={filters.status} />



                        <div className="btn-container" style={{display:'flex',justifyContent:'flex-end'}}>
                            <button onClick={this.onApplyFilter} className="btn btn-success">Apply Filter</button>
                        </div>

                    </div>

                    <div className="ttl-fil-amt">
                        <h3>Total Filter Amount :</h3>
                        <h3>{totalAmount}</h3>
                    </div>

                </div>
                {
                    paymentHistoryFilteredData.map((paymentData, index)=>(
                        <div className="dlr-his" key={index}>

                        <div className="row-his">
                            <p>Date</p>
                            <p>{paymentData?.paymentDate}</p>
                        </div>
    
                        <div className="row-his" style={mystyle}>
                            <p>Dealer Id</p>
                            <p>{paymentData.dealerCode}</p>
                        </div>
    
                        <div className="row-his">
                            <p>Amount</p>
                            <p>{paymentData.amount}</p>
                        </div>
    
                        <div className="row-his" style={mystyle}>
                            <p>Status</p>
                            <p>{paymentData.status}</p>
                        </div>
    
                    </div>
                    ))
                }

               

                <div>

                </div>

            </div>
            </div>
        )

    }
}
function mapStateToProps(state) {
    let { mtPaymentHistory = [] } = state.MTVisitReducer;

    return {
        allLeads: state.allFetchLead ? state.allFetchLead : {},
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
        searchData: [],
        error: state.allFetchLead.error ? state.allFetchLead.error : undefined,
        mtPaymentHistory: mtPaymentHistory

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMTPayment, fetchAllMapping }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MTPaymentHistory);