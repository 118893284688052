import moment  from "moment";

export const mainDataMapper = (type, formData,freeProducts)=>{
  const userData =  JSON.parse(localStorage.getItem('userObj'));
  const createdBy = userData.email || "devteam@zunpulse.com"
  console.log('createdBy', createdBy);
  if(type==='flat'){
    return flatCouponDataMapper(type,formData, createdBy);
  }
  else if(type==='BXGY'){
    return BXGYCouponDataMapper(type,formData, createdBy);
  }
  else if(type==='percentage'){
    return percentageCouponDataMapper(type,formData, createdBy);
  }
  else if(type==='free'){
    return freeCouponDataMapper(type,formData,freeProducts, createdBy);
  }
  else if(type==='bulk'){
    return bulkCouponDataMapper(type,formData, createdBy);
  }
}

export const  flatCouponDataMapper  = (type, formData, createdBy)=>{
  console.log('form Data mapper', formData);
    const apiData = {
        coupon: formData.couponName,
        minCartValue: formData.minCartValue,
        maxDiscount: formData.maxDiscountValue,
        expiryDate: moment(formData.expiryDate).endOf('day').format('x'),
        value: formData.discountValue,
        type: type,
        usage: formData.maxUse || 1,
        firstTimeUser: formData.firstTimeUser,
        product: formData.product || [],
        createdBy: createdBy,
        isApproved:false,
        prefix:formData.bulkCoupon || false,
        quantity:formData.totalCoupons || 0
      }
      console.log(`return apiData ${type}`,apiData);
    return apiData;

}

export const  freeCouponDataMapper  = (type, formData,freeProducts, createdBy)=>{
  console.log('form Data mapper', formData);
    const apiData = {
        coupon: formData.couponName,
        minCartValue: formData.minCartValue,
        maxDiscount: formData.maxDiscountValue,
        expiryDate:  moment(formData.expiryDate).endOf('day').format('x'),
        value: formData.discountValue,
        type: type,
        usage: formData.maxUse || 1,
        firstTimeUser: formData.firstTimeUser,
        product: formData.product || [],
        freeProducts: freeProducts || [],
        createdBy: createdBy,
        isApproved:false,
        prefix:formData.bulkCoupon || false,
        quantity:formData.totalCoupons || 0
      }
      console.log(`return apiData ${type}`,apiData);
    return apiData;

}
export const  percentageCouponDataMapper  = (type, formData, createdBy)=>{
  console.log('form Data mapper', formData);
    const apiData = {
        coupon: formData.couponName,
        minCartValue: formData.minCartValue,
        maxDiscount: formData.maxDiscountValue,
        expiryDate:  moment(formData.expiryDate).endOf('day').format('x'),
        value: formData.discountValue,
        type: type,
        usage: formData.maxUse || 1,
        firstTimeUser: formData.firstTimeUser,
        product: formData.product || [],
        createdBy: createdBy,
        isApproved:false,
        prefix:formData.bulkCoupon || false,
        quantity:formData.totalCoupons || 0
      }
      console.log(`return apiData ${type}`,apiData);
    return apiData;

}
export const  BXGYCouponDataMapper  = (type, formData, createdBy)=>{
  console.log('form Data mapper', formData);
    const apiData = {
        coupon: formData.couponName,
        minCartValue: formData.minCartValue,
        maxDiscount: formData.maxDiscountValue,
        expiryDate:  moment(formData.expiryDate).endOf('day').format('x'),
        value: formData.discountValue,
        type: type,
        usage: formData.maxUse || 1,
        firstTimeUser: formData.firstTimeUser,
        product: formData.product || [],
        BXGY:{X: parseInt(formData.buyCount), Y: parseInt(formData.getCount)},
        createdBy: createdBy,
        isApproved:false,
        prefix:formData.bulkCoupon || false,
        quantity:formData.totalCoupons || 0
      }
      console.log(`return apiData ${type}`,apiData);
    return apiData;

}

export const productDataMapper = (allData)=>{
  const  filteredData = []
  Object.keys(allData).forEach((pid)=>{
    const obj = {};
    obj.label= allData[pid];
    obj.value= pid;
    filteredData.push(obj);
  
  })
  return filteredData;

}


export const repeatSalesBannerMapper = (allData)=>{
  const filteredData = [];
  allData.forEach(bannerData=>{
    const row = {};
    row.label = bannerData.id;
    row.value = bannerData.imageId;
    filteredData.push(row);
  })
  return filteredData
}
export const repeatSalesLandingUrlMapper = (allData)=>{
  const filteredData = [];
  allData.forEach(bannerData=>{
    const row = {};
    if(bannerData.landingUrl){
    row.label = bannerData.landingUrl || '';
    row.value = bannerData.landingUrl || '';
    filteredData.push(row);
    }
  })
  return filteredData
}


export const  bulkCouponDataMapper  = (type, formData, createdBy)=>{
  console.log('form Data mapper', formData);
  console.log('createdBy data', createdBy);

    const apiData = {
        prefix: formData.prefixName,
        minCartValue: formData.minCartValue,
        maxDiscount: formData.maxDiscountValue,
        expiryDate: moment(formData.expiryDate).endOf('day').format('x'),
        value: formData.discountValue,
        type: type,
        firstTimeUser: formData.firstTimeUser,
        product: formData.product || [],
        createdBy: createdBy,
        isApproved:false
      }
      console.log(`return apiData ${type}`,apiData);
    return apiData;

}
