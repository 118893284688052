import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './store';
import './index.css';
import App from './App';
import 'bootstrap';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import PropTypes from 'prop-types';


// import 'materialize-css';
// import 'materialize-css/dist/css/materialize.min.css';


// import registerServiceWorker from './registerServiceWorker';
ReactDOM.render(
 <Provider store={configureStore()}>
  <App />
 </Provider>,
 document.getElementById('root')
);
// registerServiceWorker();