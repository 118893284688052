import React from 'react';
import { fetchAllLeads, getComments } from '../actions/fetchAllLeads';
import { fetchAllMapping } from '../actions/fetchAllMapping';
import { getSearchResult } from '../actions/searchAction';
import { editBulkLeads } from '../actions/editMultipleLeadsAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../components/HeaderComponent';
import { connect } from 'react-redux';
import TableView from '../components/allLeadsView';
import PropTypes from 'prop-types';

class fetchAllLead extends Component {
  componentWillMount() {
    let viewList = ["stages", "employees", "saslots", "regions", "source", "stateregion"]
    this.props.fetchAllLeads(100, 1, []);
    this.props.fetchAllMapping(viewList);
    console.log("from search container", this.props.match.params.string);
    this.props.getSearchResult(this.props.match.params.string);
    // this.props.getSearchResult()
  }

  render() {
    return (
      <div>
        <Header getSearchResult={this.props.getSearchResult} />
        <TableView allLeads={this.props.allLeads} allMapping={this.props.allMapping} fetchAllLeads={this.props.fetchAllLeads} searchData={this.props.searchData} editBulkLeads={this.props.editBulkLeads} getComments={this.props.getComments} />
      </div>
    )
  }
}


function mapStateToProps(state) {
  // console.log("searchData", state.searchResults.tableData)
  return {
    allLeads: [],
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    searchData: state.searchResults.tableData ? state.searchResults.tableData : [],

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAllLeads, fetchAllMapping, editBulkLeads, getSearchResult, getComments }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(fetchAllLead);