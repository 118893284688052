import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import { ErrorComponent } from '../../components/ErrorComponent';
import { fetchAllProducts } from '../../actions/zunpulseProductActions';
import { Redirect } from 'react-router-dom';

const baseImageUrl = `https://d1vfpdpyv21g5i.cloudfront.net/zunpulse/products_v5/`;

class ZunpulseAllProducts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productsData: {},
            userObj: {}
        }
    }

    componentWillMount() {
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            if(userObj.role == "Super Admin") {
                this.props.fetchAllProducts();
            }
            this.setState({
                userObj
            })
        }
    }

    componentWillReceiveProps(props) {
        let { productsData = {} } = props;
        console.log('old data ', productsData);
        this.setState({
            productsData
        })
    }

    render() {
        if (this.props.error) {
            return (
                <div>
                    <ErrorComponent error={this.props.error} />
                </div>
            )
        }

        if(this.state.userObj.role != "Super Admin") {
            return <Redirect to={"/"} />
        }

        return (
            <div>
                <Header />
                <div className="container-fluid" style={{ marginTop: "80px" }}>
                    <div className="row">
                        {
                            Object.values(this.state.productsData || {}).map(product => {
                                return (
                                    <a href={`/zunpulse/admin/product/${product.pid}`} target={`_blank`} className="mt-3 shadow" style={{ display: 'table', border: '2px solid #A9A9A9', borderRadius: '6px', width: '31%', margin: '0 1%', textDecoration: 'none' }}>
                                        <span><img src={`${baseImageUrl}${product.images &&  product?.images[0]?.img}`} style={{ verticalAlign: 'middle', display: 'table-cell', width: "200px", height: "200px" }} /></span>
                                        <span style={{ verticalAlign: 'middle', display: 'table-cell', padding: '7px 30px', textDecoration: 'none' }}>{product.name}</span>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps({ zunpulseProducts }) {
    let { productsData = {} } = zunpulseProducts;
    return {
        productsData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, fetchAllProducts }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseAllProducts);