import { connect } from "react-redux";
import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { fetchMTPayment, fetchMTSearchVisits } from '../../actions/fetchMTActions'
import { fetchAllMapping, fetchMappingZunsolar } from "../../actions/fetchAllMapping";
import '../styles/paymentSummary.css'
import Select from 'react-select';
import eyeView from '../../assets/eyeView.png'
import message from '../../assets/message.png'
import { fetchAllLeads } from '../../actions/fetchAllLeads';
import { fetchZunsolarLead, fetchZunsolarDealerVisitData, addZunsolarDealerVisitData, editZunsolarLead } from '../../actions/fetchBitrixId'
import moment from "moment";
import MobileHeaderComponent from "../MobileHeaderComponent";
import HeaderComponent from "../HeaderComponent";


class MTAddVisitSingleDealer extends Component {

    constructor(props) {

        super(props)

        this.state = {
            filters: {
                responsible_person: []
            },

            mtSearchVisitsFilteredData: [],
            mtSearchVisitsOriginalData: [],
            userObj: {},
            username: {},
            limit: 100,
            page: 1,
            solarId: '',
            optionsMapping: {
                leadType: [{ value: "Retailer", label: "Retailer" }, { value: "Distributor", label: "Distributor" }, { value: "C&F", label: "C&F" }, { value: "B2G", label: "B2G" }, { value: "Cash & Carry", label: "Cash & Carry" }],

            },
            allLeads: [],
            count: 0,
            singleLeadData: {},
            allVisitedData: [],
            visitData: { visitDate: moment().format("YYYY-MM-DD"), nextActionDate: moment().add(7, 'd').format("YYYY-MM-DD") },
            statusMapping: [{ label: "all", value: "all" }, { label: "cleared", value: "cleared" }, { label: "uncleared", value: "uncleared" }],
            stageMapping: [{ label: 'Hot', value: "Hot" }, { label: 'Cold', value: "Cold" }, { label: 'Sold', value: "Sold" }]
        }
    }

    componentWillMount() {
        let userObj = JSON.parse(localStorage.getItem("userObj"))
        let username = userObj.username.split('@')[0].split('.')[0].toLowerCase() + ' ' + userObj.username.split('@')[0].split('.')[1].toLowerCase() || ""
        console.log('username', username);
        let solarId = this.props.match.params.id
        this.props.fetchZunsolarLead(solarId);
        this.props.fetchZunsolarDealerVisitData(solarId);
        this.props.fetchMappingZunsolar();

        console.log('solarId', solarId);
        let { filters, limit, page } = this.state;
        filters.responsible_person = ["prateek suraj"]

        this.setState({
            userObj,
            username,
            filters,
            solarId: solarId
        })

        this.props.fetchMTSearchVisits(userObj.email)
        let viewList = ["employees", "regions"]
        this.props.fetchAllMapping(viewList);
        this.props.fetchAllLeads(limit, page, [], filters, 'zunsolar');



    }

    handleRedirectMTAdd = () => {
        window.open(window.location.origin + "/mt/addVisit", "_self")

    }
    handleRedirectMTView = () => {
        window.open(window.location.origin + "/mt/viewDealer", "_self")

    }
    componentWillReceiveProps(props) {
        let { allMapping, mtSearchVisits, allLeads = {}, leadData, dealerVisitData } = props
        console.log('allMapping', allMapping);

        let singleLeadData = leadData && leadData.data || {}
        console.log('allLeads', singleLeadData);
        console.log('dealerVisitData', dealerVisitData);

        let { optionsMapping } = this.state
        let employees = this.optionFunction(props.allMapping && props.allMapping.employees ? props.allMapping.employees : [], "e_name")
        let states = this.optionFunction(props.allMapping.stateCityMap && props.allMapping.stateCityMap ? Object.keys(props.allMapping.stateCityMap) : [], "langauge_name");
        console.log('leadData', leadData);
        optionsMapping.employees = employees;
        optionsMapping.states = states

        this.setState({
            // paymentHistoryFilteredData: mtPaymentHistory,
            mtSearchVisitsOriginalData: mtSearchVisits,
            mtSearchVisitsFilteredData: mtSearchVisits,
            optionsMapping,
            allVisitedData: dealerVisitData,
            singleLeadData: singleLeadData

        })
    }
    handleMultiSelectChange = (e, name) => {
        // console.log('e',e);
        // return;
        let { value } = e;
        let { filters } = this.state;
        filters = JSON.parse(JSON.stringify(filters))
        filters[name] = value;
        this.setState({
            filters
        })
    }
    handleFilterInputChange = (e) => {
        let { name, value } = e.target;
        let { filters } = this.state;
        filters = JSON.parse(JSON.stringify(filters))
        filters[name] = value;
        this.setState({
            filters
        })
    }

    multiSelectType = (name, options, placeholder) => {

        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti={false}
                    isSearchable
                    onChange={(e) => { this.handleMultiSelectChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                    id={name}
                />
            </div>
        )
    }

    handleSelectChange = (e, name) => {
        let { value } = e;
        let { visitData = {} } = this.state;
        visitData = JSON.parse(JSON.stringify(visitData))
        visitData[name] = value;
        this.setState({
            visitData
        })

    }

    SelectType = ({ label, name, options, placeholder, disabled, required = false }) => {
        return (
            <div
                className="formgroupContainer"
            >
                {/* <div className="form-group"> */}
                {/* <div className="mb-3"> */}
                <label>{label}</label>
                <div style={{ width: "80%" }}>
                    <Select
                        isSearchable
                        onChange={(e) => { this.handleSelectChange(e, name) }}
                        placeholder={placeholder}
                        name={name}
                        options={options}
                        isDisabled={disabled}
                        required={required}
                        id={name}

                    />

                </div>
                {/* //   </div> */}
            </div>
        )
    }
    handleSelectChange = (e, name) => {
        let { value } = e;
        let { visitData } = this.state;
        visitData = JSON.parse(JSON.stringify(visitData))
        visitData[name] = value;
        this.setState({
            visitData
        })
    }

    inputTypeFilter = (name, value, type, placeholder, disabled = false) => {
        return <input type={type} disabled={disabled} placeholder={placeholder} style={{ padding: "8px 8px", border: "1px solid #ced4da" }} className="form-control" name={name} value={value} onChange={(e) => this.handleFilterInputChange(e)} />
    }
    handleFilterInputChange = (e) => {
        let { name, value } = e.target;
        let { visitData = {} } = this.state;
        visitData = JSON.parse(JSON.stringify(visitData));
        visitData[name] = value
        this.setState({
            visitData
        })
    }


    optionFunction = (array, value) => {
        let multiSelectOptionsMap = array.map((data) => {
            let obj = {}

            if (data[value]) {
                obj = {
                    label: data[value].replace(/(^"|"$)/g, ''),
                    value: data[value].replace(/(^"|"$)/g, '')
                }
            } else {
                obj = {
                    label: data,
                    value: data
                }
            }
            return obj
        })
        return multiSelectOptionsMap;
    }

    optionFunctionState = (array) => {
        let multiSelectOptionsStateMap = array.map((val) => {
            let obj = {
                label: val.replace(/(^"|"$)/g, ''),
                value: val.replace(/(^"|"$)/g, ''),
            }
            return obj;
        })
        return multiSelectOptionsStateMap
    }
    InputTypeText = (name, value, type) => {
        return (
            <input type={type} name={name} value={value} className="form-control" style={{ border: "1px solid #ced4da" }} onChange={(e) => this.handleFilterInputChange(e)} ></input>
        )
    }
    inputType = (name, placeholder, type) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    {type == "date" && <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>}
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => this.handleFilterInputChange(e)} />
                </div>
            </div>
        )
    }

    handleSubmitVisitData = (e) => {
        e.preventDefault();

        let { visitData, userObj, solarId, singleLeadData } = this.state
        let username = userObj.email;
        visitData.solarId = solarId
        let { visitDate } = visitData;
        visitData.createdBy = username
        visitData.visitBy = username
        let stage = visitData.stage;
        let nextActionDate = visitData.nextActionDate;
        console.log('visitData', visitData);

        if (!visitData || !visitDate || !username || !solarId) {
            return alert("missing required fields")
        }
        let leadNewData = { ...singleLeadData }
        console.log('stage', stage);
        leadNewData.stage = stage;
        leadNewData.nextActionDate = nextActionDate


        this.props.addZunsolarDealerVisitData(visitData)
        console.log('leadNewData', leadNewData, singleLeadData, solarId);
        this.props.editZunsolarLead(leadNewData, singleLeadData, solarId)

    }
    handleNextClick = () => {
        let { filters, limit, page, allLeads } = this.state;
        this.props.fetchAllLeads(limit, page + 1, allLeads, filters, 'zunsolar')

        this.setState({
            page: page + 1
        })


    }
    inputTypeFilter = (name, value, type, placeholder) => {
        return <input type={type} placeholder={placeholder} style={{ padding: "8px 8px" }} className="form-control" name={name} value={value} onChange={(e) => this.handleFilterInputChange(e)} />
    }

    handleLastClick = () => {
        let { filters, limit, page, allLeads } = this.state;
        this.props.fetchAllLeads(limit, page - 1, allLeads, filters, 'zunsolar')

        this.setState({
            page: page - 1
        })
    }

    onApplyFilter = () => {
        let { filters, allLeads, limit, page } = this.state;
        console.log('filters', filters);
        // return
        let state = filters.state;
        if (state && state.length) {
            this.props.fetchAllLeads(limit, page, [], filters, 'zunsolar');

        }

    }


    SelectType = ({ label, name, options, placeholder, disabled, required = false }) => {
        return (
            <div
                className="formgroupContainer">
                {/* <div className="form-group"> */}
                {/* <div className="mb-3"> */}
                <label>{label}</label>
                <div style={{ width: "80%" }}>
                    <Select
                        isSearchable
                        onChange={(e) => { this.handleSelectChange(e, name) }}
                        placeholder={placeholder}
                        name={name}
                        options={options}
                        isDisabled={disabled}
                        required={required}
                        id={name}

                    />

                </div>
                {/* //   </div> */}
            </div>
        )
    }

    render() {
        const { mtSearchVisitsOriginalData, mtSearchVisitsFilteredData, filters, statusMapping, optionsMapping, allLeads, singleLeadData, allVisitedData, visitData, stageMapping } = this.state
        let { SelectType } = this
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        return (
            <div>
                <HeaderComponent />
                <div className="mainPaymentHistory">

                    <div>

                        <div className="searched-detailed" >

                            <div className="specifiec-ctn">
                                <p>Dealer Id :</p>
                                <p>{singleLeadData.solarId + singleLeadData.companyName}</p>
                            </div>

                            <div className="specifiec-ctn">
                                <p>Name :</p>
                                <p>{singleLeadData.name}</p>
                            </div>

                            <div className="specifiec-ctn">
                                <p>phone :</p>
                                <p>{singleLeadData.phoneNumber}</p>
                            </div>





                        </div>
                        <div>
                            <form onSubmit={this.handleSubmitVisitData} >

                                <div className="formgroupContainer">
                                    <label>Date</label>
                                    {this.inputTypeFilter("visitDate", visitData.visitDate, 'Date', "date",)}
                                </div>






                                <div className="formgroupContainer">
                                    <label className="newPaymentLabel">visit By </label>
                                    {this.inputTypeFilter("visitBy", this.state.userObj.email, 'text', "visit By", true)}

                                </div>
                                <div className="formgroupContainer">
                                    <label className="newPaymentLabel">Next Action Date </label>
                                    {this.inputTypeFilter("nextActionDate", visitData.nextActionDate, 'text', "Next Action Date",)}

                                </div>
                                <div className="formgroupContainer">
                                    <label className="newPaymentLabel">Comment </label>
                                    {this.inputTypeFilter("comment", visitData.comment, 'text', "comment",)}

                                </div>

                                <SelectType label="Stage" name="stage" options={stageMapping || []} placeholder="select stage" value={visitData.stage} />



                                <div className="new-payment-submit">
                                    <button className="btnSubmit" type="submit" value="Submit">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            {
                                allVisitedData.map((visitData, index) => (
                                    <div className="dlr-his" key={index}>

                                        <div className="row-his">
                                            <p>Date</p>
                                            <p>{visitData?.visitDate}</p>
                                        </div>

                                        <div className="row-his">
                                            <p>visit by</p>
                                            <p>{visitData.visitBy}</p>
                                        </div>

                                        <div className="row-his">
                                            <p>comment</p>
                                            <p>{visitData.comment}</p>
                                        </div>



                                    </div>

                                ))
                            }

                        </div>

                    </div>







                </div>

            </div>
        )

    }
}
function mapStateToProps(state) {
    let { mtPaymentHistory = [], mtSearchVisits = [] } = state.MTVisitReducer;

    return {
        leadData: state.fetchlead ? state.fetchlead : '',
        allLeads: state.allFetchLead ? state.allFetchLead : {},
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
        searchData: [],
        error: state.allFetchLead.error ? state.allFetchLead.error : undefined,
        mtPaymentHistory: mtPaymentHistory,
        mtSearchVisits: mtSearchVisits,
        dealerVisitData: state.fetchlead && state.fetchlead.dealerVisitData || [],


    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMTPayment, fetchAllMapping, fetchMTSearchVisits, fetchAllLeads, fetchZunsolarDealerVisitData, fetchZunsolarLead, fetchMappingZunsolar, addZunsolarDealerVisitData, editZunsolarLead }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MTAddVisitSingleDealer);