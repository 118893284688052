import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { procurementVendorColumns, companyTypeOptions } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import Select from 'react-select';

const tableFields = [
    { name: 'companyName', type: 'text', label: "Company Name", required: true },
    { name: 'companyType', type: 'select', label: "Company Type", isMulti: false, optionKey: 'companyType', required: true },
    { name: 'address', type: 'text', label: "Address", required: true },
    { name: 'panNumber', type: 'text', label: "Pan Card", required: true },
    { name: 'pocName', type: 'text', label: "POC Name", required: true },
    { name: 'phoneNumber', type: 'tel', label: "Phone", required: true, pattern: "[0-9]{10}" },
    { name: 'email', type: 'text', label: "Email", required: true },
    { name: 'products', type: 'select', label: "Products", isMulti: true, optionKey: "products", required: true },
    { name: 'gst', type: 'text', label: "GST", required: true },
    { name: 'certifications', type: 'text', label: "Certfication", required: false },
    { name: 'beneficiary', type: 'text', label: "Beneficiary", required: true },
    { name: 'accNo', type: 'text', label: "Account No", required: true },
    { name: 'IFSC', type: 'text', label: "IFSC", required: true },
    { name: 'beneficiary2', type: 'text', label: "Beneficiary 2", required: false },
    { name: 'accNo2', type: 'text', label: "Account No 2", required: false },
    { name: 'IFSC2', type: 'text', label: "IFSC 2", required: false },
    { name: 'gstCertificate', type: 'file', label: "GST Certificate", required: true },
    { name: 'panCard', type: 'file', label: "Pan Card", required: true },
    { name: 'cancelledCheque', type: 'file', label: "Cancelled Cheque", required: true }
]

class ProcurementVendorList extends Component {

    constructor() {
        super();
        this.state = {
            vendorList: [],
            openModal: false,
            editStatus: {},
            vendorIndex: -1,
            editedData: {},
            vendorListColumns: []
        };
    }

    componentDidMount() {
        let vendorListColumns = procurementVendorColumns.map(row => {
            if(row.accessor == 'companyName') {
                row.Cell = (row => <a href="javascript:void(0);"><div onClick={() => this.showVendorDetails(row)}>{row.original.companyName || row.original.id}</div></a>);
            }
            if(row.accessor == 'deleteVendor') {
                row.Cell = (row => <button onClick={() => this.handleDeleteVendorClick(row)} className="btn btn-danger btn-sm">Delete</button>);
            }
            return row;
        });
        this.setState({ vendorListColumns });
    }

    componentWillMount() {
        this.props.fetchVendorList();
    }

    componentWillReceiveProps({ vendorList = [] }) {
        this.setState({ vendorList });
    }

    handleDeleteVendorClick = (row) => {
        let { vendorList = [] } = this.state;
        this.props.deleteVendor(vendorList, row.index, row.original.id);
    }

    EditVendorInput = ({ label, name, type, inputParams = {} }) => {
        let { editedData = {} } = this.state;
        let value = editedData[name] || "";
        return (
            <input className="form-control customInput" type={type} name={name} placeholder={label} onChange={(e) => this.handleAddVendorInputChange(e)} {...inputParams} defaultValue={value || ""} />
        )
    }

    EditVendorSelect = ({ isMulti, label, name, optionKey }) => {
        let { editedData = {} } = this.state;
        let value = editedData[name] || "";
        let selectedValue;
        if (value) selectedValue = isMulti ? (value || []).map(value => ({ label: value, value: value })) : { label: value, value: value };
        else selectedValue = null
        let options = this.getSelectOptions(optionKey) || [];
        return (
            <Select
                isSearchable={true}
                placeholder={label}
                name={name}
                options={options}
                onChange={(e) => this.handleAddVendorSelectChange(e, name)}
                isMulti={isMulti}
                defaultValue={selectedValue}
            />
        )
    }

    EditVendorFileInput = ({ label, name, inputParams = {} }) => {
        return (
            <input className="form-control customInput" type={"file"} name={name} placeholder={label} onChange={(e) => this.handleAddVendorFileChange(e)} {...inputParams} />
        )
    }

    handleAddVendorInputChange = (e) => {
        let { name, value } = e.target;
        console.log(name, value);
        let { editedData = {} } = this.state;
        editedData[name] = value;
        this.setState({
            editedData
        }, () => console.log(this.state.editedData));
    }

    handleAddVendorSelectChange = (e, name) => {
        if(!e) {
            let { editedData = {} } = this.state;
            editedData[name] = e;
            this.setState({
                editedData
            });
            return;
        }
        let value = e;
        if (Array.isArray(e)) value = e.map(row => row.value);
        else value = e.value;
        let { editedData = {} } = this.state;
        editedData[name] = value;
        this.setState({
            editedData
        })
    }

    handleAddVendorFileChange = (e) => {
        let { name, files } = e.target;
        this.setState(prevState => ({
            editedData: {
                ...prevState.editedData,
                [name]: files[0]
            }
        }))
    }

    showVendorDetails = (row) => {
        console.log(row);
        let data = JSON.parse(JSON.stringify(row.original));
        data.products = data.products ? data.products.split(',') : null;
        this.setState({
            openModal: true,
            editedData: data,
            vendorIndex: row.index
        })
    }

    LabelComponent = (row) => {
        return (<td className="tableDataLabel">{row.label}</td>)
    }

    ValueComponent = (row) => {
        let { EditableValueComponent, ViewValueComponent } = this;
        let { editStatus = {} } = this.state;
        let edit = editStatus[row.name];
        return edit ? <EditableValueComponent {...row} /> : <ViewValueComponent {...row} />;
    }

    ViewValueComponent = (row) => {
        let { vendorIndex = {}, vendorList = [] } = this.state;
        let vendorData = vendorList[vendorIndex] || {};
        if (row.type == 'file') {
            return (<td className="tableDataValue">
                {vendorData[row.name] ? <a href="javascript:void(0);"><div onClick={() => this.downloadClick(row)}>{"Download"}</div></a> : ""}
            </td>);
        }
        return <td className="tableDataValue">{vendorData[row.name] || ""}</td>;
    }

    EditableValueComponent = (row) => {
        let { type } = row;
        let { EditVendorFileInput, EditVendorInput, EditVendorSelect } = this;
        if (type == 'select') {
            return <td>
                <EditVendorSelect {...row} options={[]} />
            </td>
        }
        else if (type == 'file') {
            return <td>
                <EditVendorFileInput {...row} options={[]} />
            </td>
        }
        else {
            return <td>
                <EditVendorInput {...row} options={[]} />
            </td>
        }
    }

    ActionsComponent = (row) => {
        let { EditComponent, SaveComponent } = this;
        let { editStatus = {} } = this.state;
        let edit = editStatus[row.name];
        return edit ? <SaveComponent {...row} /> : <EditComponent {...row} />;
    }

    EditComponent = (row) => {
        return (<td><i className="fa fa-edit btn btn-light" style={{ cursor: "pointer" }} onClick={() => this.handleEditClick(row)} /></td>)
    }

    SaveComponent = (row) => {
        console.log("Save Component");
        return (<td className="actionsTd">
            <i className="fa fa-check btn btn-light" style={{ cursor: "pointer" }} onClick={() => this.handleSaveClick(row)} />
            <i className="fa fa-times btn btn-light" style={{ cursor: "pointer" }} onClick={() => this.handleCancelClick(row)} />
        </td>)
    }

    RowComponent = (row = {}) => {
        let { LabelComponent, ValueComponent, ActionsComponent } = this;
        return (
            <tr>
                <LabelComponent {...row} />
                <ValueComponent {...row} />
                <ActionsComponent {...row} />
            </tr>
        )
    }

    onCloseModal = () => {
        this.setState({
            openModal: false,
            editStatus: {},
            editedData: {},
            vendorIndex: -1
        })
    }

    handleEditClick = (row) => {
        this.setState({
            editStatus: {
                ...this.state.editStatus,
                [row.name]: true
            }
        })
    }

    handleCancelClick = (row) => {
        this.setState({
            editStatus: {
                ...this.state.editStatus,
                [row.name]: false
            }
        })
    }

    handleSaveClick = (row) => {
        console.log("handle save click", row);
        let { name } = row;
        let { vendorList, vendorIndex, editedData = {} } = this.state;
        let value = editedData[name];
        let isValid = this.validateInput(row, value);
        if(!isValid) {
            alert("Invalid input");
            return;
        }
        let vendorData = vendorList[vendorIndex] || {};
        this.props.updateVendorData(name, value, vendorList, vendorIndex, vendorData.id);
        this.setState({
            editStatus: {
                ...this.state.editStatus,
                [name]: false
            }
        })
    }

    validateInput = (row, value) => {
        if(!row.required) return true;
        if(row.pattern) {
            let pattern = new RegExp(row.pattern);
            return pattern.test(value);
        }
        if(row.type == 'select' && row.isMulti) {
            return (value || []).length > 0;
        }
        return value?true:false;
    }

    downloadClick = (row) => {
        let { vendorIndex = {}, vendorList = [] } = this.state;
        let vendorData = vendorList[vendorIndex] || {};
        let fileName = vendorData[row.name];
        this.props.downloadFile(fileName);
    }

    getSelectOptions = (optionKey) => {
        switch(optionKey) {
            case 'companyType': return companyTypeOptions;
            case 'products': return this.props.vendorProductsList || [];
            default: return [];
        }
    }

    render() {

        const modalStyles = {
            modal: {
                width: '100%'
            }
        }

        let { vendorList, vendorListColumns } = this.state;
        let { RowComponent } = this;

        return (
            <div className="procurementAddVendor">
                <Modal styles={modalStyles} open={this.state.openModal} onClose={this.onCloseModal} closeOnEsc={false}>
                    <div className="vendorDetailsModal">
                        <table className="vendorDetailsTable">
                            {
                                tableFields.map(val => <RowComponent {...val} />)
                            }
                        </table>
                    </div>
                </Modal>
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={vendorList}
                    columns={vendorListColumns}
                    defaultSorted={[{ id: "id", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    defaultPageSize={10}
                />
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementVendorList);