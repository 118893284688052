import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import { fetchMapping, fetchLeadData, fetchComments, updateLeadData, addComment, fetchHistory } from '../actions/projectSalesLeadActions';
import { projectSalesHistoryColumns } from '../config/column_fields';
import moment from 'moment';
import Header from '../components/HeaderComponent';
import { getSearchResult } from '../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../components/loader';
import swal from 'sweetalert';
import url from '../config';

const phoneFields = [ "poc1Phone", "poc2Phone", "poc3Phone" ];
const emailFields = [ "poc1Email", "poc2Email", "poc3Email" ];

class ProjectSalesLeadView extends Component {

    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            edit: false,
            leadData: {},
            editedLeadData: {},
            mapping: {},
            comments: [],
            username: ''
        };
    }


    componentWillMount() {
        this.props.fetchMapping();
        let leadId = decodeURIComponent(this.props.match.params.id);
        this.props.fetchLeadData(leadId);
        this.props.fetchComments(leadId);
        this.props.fetchHistory(leadId);
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            let username = userObj.username
            this.setState({
                username: username
            })
        }
    }

    componentWillReceiveProps(props) {
        let { mapping, leadData = {}, comments = [] } = props;
        this.setState({
            loading: false,
            received: true,
            mapping: mapping,
            leadData,
            comments
        })
    }

    inputType = (name, value, type) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    selectType = (name, value, options) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleInputChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    editOrderSubmit = () => {
        let { leadData, editedLeadData } = this.state;
        for(let index = 0; index < phoneFields.length; index++) {
            let key = phoneFields[index];
            if(editedLeadData[key] && !this.ValidatePhone(editedLeadData[key])) {
                alert(`Invalid phone number - ${key}`);
                return;
            }
        }
        for(let index = 0; index < emailFields.length; index++) {
            let key = emailFields[index];
            if(editedLeadData[key] && !this.ValidateEmail(editedLeadData[key])) {
                alert(`Invalid email - ${key}`);
                return;
            }
        }
        this.props.updateLeadData(leadData.leadId, leadData, editedLeadData);
        this.setState({
            edit: false
        })
    }

    ValidateEmail = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    }

    ValidatePhone = (phone) => {
        if (/^[0-9]{10}$/.test(phone)) {
            return true;
        }
        return false;
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        if(phoneFields.includes(name) && !(/^[0-9]{0,10}$/.test(value))) {
            return;
        }
        this.setState({
            editedLeadData: {
                ...this.state.editedLeadData,
                [name]: value,
            }
        })
    }

    handleEdit = () => {
        this.editDetails = {};
        if (this.state.edit) {
            this.setState({
                edit: false
            })
        } else {
            this.setState({
                editedLeadData: { ...this.state.leadData },
                edit: true
            })
        }
    }

    onClickCancel = () => {
        this.setState({
            edit: false
        })
    }


    onCommentChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            newComment: value
        })
    }

    commentSubmit = (e) => {
        let comments = [...this.state.comments];
        let newComment = this.state.newComment;
        let newCommentData = {
            leadId: this.state.leadData.leadId,
            commentedBy: this.state.username,
            commentBody: newComment,
            timestamp: new Date().getTime()
        };
        comments.push(newCommentData);
        this.props.addComment(newCommentData, comments);
        this.setState({
            comments: comments,
            newComment: ''
        })
    }


    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        let { mapping, leadData } = this.state;
        let { history = [] } = this.props;
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 60 }} className="container-fluid">
                    <div className="topbar-section" >
                        <div className="row">
                            <div className="col-md-9 col-xs-12 topbar-left">
                                <div className="col-md-6">
                                    <strong>Lead Id - {leadData.leadId}</strong>
                                </div>
                            </div>
                            <div className="col-md-3 col-xs-12 topbar-right">
                                <div className="col-md-12 col-xs-12">
                                    <span style={this.state.edit ? style.hide : style.show}><i onClick={this.handleEdit} style={{ cursor: "pointer", float: "right", marginTop: "8px" }} className="fas fa-pen"></i></span>
                                    <button style={this.state.edit ? style.show : style.hide} className="button-edit" onClick={(e) => this.editOrderSubmit(e)}>Save</button>
                                    <button style={this.state.edit ? style.show : style.hide} className="button-cancel" onClick={(e) => this.onClickCancel(e)}>Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table>
                                <tr>
                                    <td>Project Name</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("projectName", this.state.editedLeadData.projectName, "text")}
                                            </span> : <span>{this.state.leadData.projectName}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Developer</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("developer", this.state.editedLeadData.developer, "text")}
                                            </span> : <span>{this.state.leadData.developer}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Project Type</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("projectType", this.state.editedLeadData.projectType, mapping.projectType)}
                                            </span> : <span>{this.state.leadData.projectType}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Possession Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("possessionDate", this.state.editedLeadData.possessionDate, "date")}
                                            </span> : <span>{this.state.leadData.possessionDate}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Smart Provision</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("smartProvision", this.state.editedLeadData.smartProvision, mapping.smartProvision)}
                                            </span> : <span>{this.state.leadData.smartProvision}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Project Stage</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("projectStage", this.state.editedLeadData.projectStage, mapping.projectStage)}
                                            </span> : <span>{this.state.leadData.projectStage}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Launch Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("launchDate", this.state.editedLeadData.launchDate, "date")}
                                            </span> : <span>{this.state.leadData.launchDate}</span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table className="customer-info-left">
                                <tr>
                                    <td>Lead Status</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("status", this.state.editedLeadData.status, mapping.status)}
                                            </span> : <span>{this.state.leadData.status}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Responsible</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("responsible", this.state.editedLeadData.responsible, mapping.responsible)}
                                            </span> : <span>{this.state.leadData.responsible}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Last Visit Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("lastVisitDate", this.state.editedLeadData.lastVisitDate, "date")}
                                            </span> : <span>{this.state.leadData.lastVisitDate}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("address", this.state.editedLeadData.address, "text")}
                                            </span> : <span>{this.state.leadData.address}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("city", this.state.editedLeadData.city, mapping.city)}
                                            </span> : <span>{this.state.leadData.city}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Next Action Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("nextActionDate", this.state.editedLeadData.nextActionDate, "date")}
                                            </span> : <span>{this.state.leadData.nextActionDate}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Last Updated By</td>
                                    <td>
                                        {
                                            <span>{this.state.leadData.updatedBy}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Last Updated On</td>
                                    <td>
                                        {
                                            <span>{this.state.leadData.updatedAt}</span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info">
                            <table className="customer-info-right">
                            <tr>
                                    <td>Poc 1 Name</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc1Name", this.state.editedLeadData.poc1Name, "text")}
                                            </span> : <span>{this.state.leadData.poc1Name}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 1 Team</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc1Team", this.state.editedLeadData.poc1Team, "text")}
                                            </span> : <span>{this.state.leadData.poc1Team}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 1 Phone</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc1Phone", this.state.editedLeadData.poc1Phone, "number")}
                                            </span> : <span>{this.state.leadData.poc1Phone}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 1 Email</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc1Email", this.state.editedLeadData.poc1Email, "text")}
                                            </span> : <span>{this.state.leadData.poc1Email}</span>
                                        }
                                    </td>
                                </tr>
                            <tr>
                                    <td>Poc 2 Name</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc2Name", this.state.editedLeadData.poc2Name, "text")}
                                            </span> : <span>{this.state.leadData.poc2Name}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 2 Team</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc2Team", this.state.editedLeadData.poc2Team, "text")}
                                            </span> : <span>{this.state.leadData.poc2Team}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 2 Phone</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc2Phone", this.state.editedLeadData.poc2Phone, "number")}
                                            </span> : <span>{this.state.leadData.poc2Phone}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 2 Email</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc2Email", this.state.editedLeadData.poc2Email, "text")}
                                            </span> : <span>{this.state.leadData.poc2Email}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 3 Name</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc3Name", this.state.editedLeadData.poc3Name, "text")}
                                            </span> : <span>{this.state.leadData.poc3Name}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 3 Team</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc3Team", this.state.editedLeadData.poc3Team, "text")}
                                            </span> : <span>{this.state.leadData.poc3Team}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 3 Phone</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc3Phone", this.state.editedLeadData.poc3Phone, "number")}
                                            </span> : <span>{this.state.leadData.poc3Phone}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Poc 3 Email</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("poc3Email", this.state.editedLeadData.poc3Email, "text")}
                                            </span> : <span>{this.state.leadData.poc3Email}</span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="row" style={{ padding: "0px" }}>
                        <div className="topbar-section col-9">
                            <div className="container">
                                <div>
                                    <h4><b>Comments</b></h4>
                                </div>
                                <hr></hr>
                                <div>
                                    <div>
                                        {(this.state.comments || []).map(comment => {
                                            return <div><b>{comment.commentedBy.split('@')[0].split('.')[0] + ' ' + comment.commentedBy.split('@')[0].split('.')[1]} - {moment(+(comment.timestamp)).format('DD/MM/YYYY hh:mm a')}</b> - {comment.commentBody}</div>
                                        })}
                                    </div>
                                    <div className="row" style={{ width: "80%", margin: "0px", padding: "0px" }}>
                                        <div className="col-md-10 form-group">
                                            <label for="newComment"></label>
                                            <textarea placeholder="Add New Comment" className="form-control" rows="2" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
                                        </div>
                                        <div style={{ marginTop: "46px", marginLeft: '0px' }} className="col-md-1 form-group">
                                            <button className="btn btn-success" onClick={(e) => this.commentSubmit(e)}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="card shadow">
                            <div className="card-body">
                                <div className="card-title">
                                    <h4><b>History</b></h4>
                                    <hr></hr>
                                </div>
                                <ReactTable
                                    filterable={true}
                                    showPagination={true}
                                    data={history}
                                    columns={projectSalesHistoryColumns}
                                    defaultPageSize={5}
                                    defaultSorted={[{ id: "timestamp", desc: true }]}
                                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                />
                            </div>
                        </div>
                    </div>
                </div> : <Loader />}
            </div>
        );


    }
}

function mapStateToProps({ projectSales }) {
    let { mapping = {}, leadData = {}, comments = [], leadDataReceived = false, history = [] } = projectSales || {};
    console.log(history);
    return {
        mapping,
        leadData,
        comments,
        leadDataReceived,
        history
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMapping, fetchLeadData, fetchComments, updateLeadData, addComment, fetchHistory }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSalesLeadView);