import { combineReducers } from 'redux';
import fetchlead from './reducer_fetch_lead';
import allFetchLead from '../reducers/reducer_all_lead';
import fetchAllMapping from './reducer_all_mapping';
import searchResults from './reducer_search';
import register from './reducer_register';
import createLead from './reducer_create_lead';
import fetchUsers from './reducer_fetch_users';
import login from './reducer_login';
import resetPassword from './reducer_reset_password';
import forgotPassword from './reducer_forgot_password'
import userInfo from './reducer_user_info';
import existingPhonesList from './reducer_check_existing_phone'
import zunpulseOrders from './reducer_zunpulse_orders';
import leadTransferData from './reducer_lead_transfer';
import saViewData from './reducer_sa_view';
import comments from './reducer_fetch_comments';
import zunSolarOrderTracker from './reducer_zunsolar_ordertracker';
import whatsAppSingleCustomer from './reducer_whatsapp_customer';
import whatsAppMetricsData from './reducer_whatsapp_metrics';
import zunSolarOpsDashboard from './reducer_zunsolar_ops_dashboard';
import zunPulseOpsDashboard from './reducer_zunpulse_opsdashboard';
import zunpulseProducts from './reducer_zunpulse_products';
import zunsolarPiPortal from './reducer_zunsolar_pi_portal'; 
import zunsolarPiInvoice from './reducer_zunsolar_pi_invoice';
import procurementPortal from './reducer_procurement_portal';
import affliatePortal from './reducer_affliate_portal';
import zunpulseBurnDashboard from './reducer_zunpulse_burn_dashboard';
import wallet from './reducer_wallet';
import  CouponStore  from './reducer_coupons';
import repeatSalesStore from  './reducer_repeatSales';
import zunsolarBulkPIInvoice from './reducer_zunsolar_pi_invoiceV2'
import IVRReducer from './reducer_IVR';
import MTVisitReducer from './reducer_fetch_mt'
import logisticsTracker from './reducer_logistics_tracker';
import projectSales from './reducer_project_sales';
import followup from './reducer_followup';


export default combineReducers({
  fetchlead,
  allFetchLead,
  fetchAllMapping,
  searchResults,
  register,
  createLead,
  fetchUsers,
  login,
  forgotPassword,
  resetPassword,
  userInfo,
  existingPhonesList,
  zunpulseOrders,
  leadTransferData,
  saViewData,
  comments,
  zunSolarOrderTracker,
  whatsAppSingleCustomer,
  whatsAppMetricsData,
  zunSolarOpsDashboard,
  zunPulseOpsDashboard,
  zunpulseProducts,
  zunsolarPiPortal,
  procurementPortal,
  affliatePortal,
  zunsolarPiInvoice,
  zunpulseBurnDashboard,
  wallet,
  CouponStore,
  repeatSalesStore,
  zunsolarBulkPIInvoice,
  IVRReducer,
  MTVisitReducer,
  logisticsTracker,
  projectSales,
  followup
});
