import React from "react";
import { Component } from "react";
import '../styles/newPayment.css'
import Select from 'react-select'
import moment from "moment";
import {createMTPayment} from '../../actions/fetchMTActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MobileHeaderComponent from "../MobileHeaderComponent";
import HeaderComponent from "../HeaderComponent";

class CreatePaymentMobile extends Component {

    constructor(props) {

        super(props)
        this.ReactTable = React.createRef();

        this.state = {
            error: false,
            tableData: [],
            allMapping: [],
            userAccess: [],

           
            banksName: ['HDFC', 'ICICI', 'AXIS', 'BOI', 'CENTRAL BANK'],
            subCategory: [],
            newPaymentMap: {},
            statusFilter: [],
            paymentData:{
            },
            optionsMapping:{},
            subCategoryMapping:[],
            paymentModeMapping:[
                {
                    "label": "Cash",
                    "value": "Cash"
                },
                {
                    "label": "NEFT/IMPS",
                    "value": "NEFT/IMPS"
                },
                {
                    "label": "Cheque",
                    "value": "Cheque"
                },
                {
                    "label": "CC Avenue",
                    "value": "CC Avenue"
                },
                {
                    "label": "INSTAMOJO",
                    "value": "INSTAMOJO"
                },
                {
                    "label": "Payu",
                    "value": "Payu"
                },
                {
                    "label": "Cashfree",
                    "value": "Cashfree"
                },
                {
                    "label": "Write Off",
                    "value": "Write Off"
                }
            ],
            bankMapping: [
                {
                    "label": "HDFC",
                    "value": "HDFC"
                },
                {
                    "label": "IDFC",
                    "value": "IDFC"
                },
                {
                    "label": "CITI",
                    "value": "CITI"
                },
                {
                    "label": "CASH-FREE",
                    "value": "CASH-FREE"
                },
                {
                    "label": "IDFC ESCROW",
                    "value": "IDFC ESCROW"
                }
            ],
            userObj:{}

        }
        this.adminAccessLevel = "222222"
    }

    onApplyFilter = (e) => {
        let startDate = this.state.startDate ? new Date(this.state.startDate + " 00:00:00").getTime() : 0;
    }


    componentWillMount(){
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        let subCategoryMapping = [{label:"Partner Payment-Invoicing",value:"Partner Payment-Invoicing"},
        {label:"Partner Refund",value:"Partner Refund"},{label:"Partner Fee",value:"Partner fee"},{label:"Vendor customer payment",value:"Vendor customer payment"},
        {label:"Partner Payment Booking",value:"Partner Payment Booking"},{label:"Customer Payment",value:"Customer Payment"},{label:"Customer Refund",value:"Customer Refund"}]
        this.setState({
            subCategoryMapping,
            userObj
        })
    }

    handleSelectChange = (e, name)=>{
        let {value} = e;
        let {paymentData = {} } = this.state;
        paymentData = JSON.parse(JSON.stringify(paymentData))
        paymentData[name] = value;
        this.setState({
            paymentData
        })

    }
   
    SelectType = ({label,name, options, placeholder, disabled, required=false}) => {
        return (
          <div
          className="formgroupContainer"
        >
          {/* <div className="form-group"> */}
            {/* <div className="mb-3"> */}
              <label>{label}</label>
              <div style ={{width:"52%"}}>
              <Select
                      isSearchable
                      onChange={(e) => { this.handleSelectChange(e, name) }}
                      placeholder = {placeholder}
                      name = {name}
                      options={options}
                      isDisabled = {disabled}
                      required={required}
                      id={name}
                     
                  />
      
            </div>
        {/* //   </div> */}
        </div>
        )
      }

    handleFilterInputChange = (e) => {
        let { name, value } = e.target;
        let {paymentData ={}} = this.state;
        paymentData = JSON.parse(JSON.stringify(paymentData));
        paymentData[name]= value
        this.setState({
           paymentData
        })
    }

    onFormSubmit(e) {
        e.preventDefault();

        //  console.log("")


    }
    componentWillReceiveProps(props) { }

    dateType = (name, value, field) => {
        return (
            <div class="formgroupContainer">
                <span class="dateSideBar" />
                <input name={name} style={{ width: "100%" }} className="form-controlV2" type="date" onChange={(e) => { this.handleDateChange(e, field) }} value={value}></input>
            </div>
        )
    }

    handleChange = (e) => {
        this.setState({ selectedOption: e.target.value });
    }

    selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
        const changeHandle = (value) => { handleMultiSelectChange(value, name) }
        return (
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        )
    }



    inputTypeFilter = (name, value, type, placeholder) => {
        return <input type={type} placeholder={placeholder} style={{ padding: "8px 8px" }} className="form-control" name={name} value={value} onChange={(e) => this.handleFilterInputChange(e)} />
    }

    selectType = (disabled, placeholder, name, options, value) => {
        return (
            <div className="react-select-payment">
                <Select
                    isSearchable
                    onChange={(e) => this.handleSelectChange(e, name)}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                    value={value}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isDisabled={disabled}
                    defaultValue={(this.state.edit && this.state.allMapping[name]) ? this.state.allMapping[name].map(val => ({ label: this.state.newPaymentMap[name], value: this.state.newPaymentMap[name] })) : ""}
                />
            </div>
        )
    }

    handleSubmitPayment = (e)=>{
        e.preventDefault();
        let {paymentData, userObj} = this.state;
        console.log('handleSubmitPayment',paymentData);
        let missingFields = []

        let requiredFields = ["referenceNumber", "dealerId","amount","paymentDate"]
        requiredFields.forEach((field)=>{
            if(!paymentData[field] || !paymentData[field].length){
                missingFields.push(field)

            }
        })
        if(missingFields && missingFields.length){
            alert(`Following Required Fields are missing  ${missingFields.join(",")}`)
            return;
          }
        let userEmail = userObj.email
        paymentData.userEmail = userEmail;
        this.props.createMTPayment(paymentData);

    }
   

    render() {

        let {SelectType} = this;
        let {subCategoryMapping, paymentData, paymentModeMapping,bankMapping} = this.state
        const modalStyles = {
            modal: {
                width: '40%',
                maxWidth: '40%',
                padding: '40px',
                background: '#f7f7f7',
                textAlign: 'center'
            }
        }



        const style = {
            
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            },
            iconShow: {
                float: 'right',
                display: ''
            },
            iconHide: {
                float: 'right',
                display: 'none'
            },
            showError: {
                display: '',
                color: 'red',
            },
            hideError: {
                display: 'none',
            }
        };



        return (

            <div>
                <HeaderComponent/>
            <div className="payment-create-form" >
            <div className="topHeadContainer">
                    <h5>Create New Payment</h5>

                </div>

                <form onSubmit={this.handleSubmitPayment} className="form-controlV2">
                    {/* <p style={this.state.error ? style.showError : style.hideError}>Required fields Missing</p> */}

                    <div className="formgroupContainerPayment">
                        <label>Payment Date</label>
                        {this.inputTypeFilter("paymentDate", paymentData.paymentDate, 'Date', "date",)}
                    </div>

                    {/* <div className="formgroupContainer">
                        <label className="newPaymentLabel">Photo</label>
                    </div> */}

                   
                    <SelectType label = "Sub-category" name = "subcategory"  options={subCategoryMapping || []} placeholder="select" value = {paymentData.subcategory} required={true}/>


                    <div className="formgroupContainer">
                        {/* <span style={this.state.userAccess.join("") != this.adminAccessLevel ? { color: 'red' } : { display: 'none' }}>*</span> */}
                        <label className="newPaymentLabel">Dealer Id<span style={{ color: 'red' }}>*</span> </label>
                        {/* {this.inputType("quote id", "text", 'id', this.state.newPaymentMap.id || "", (this.state.edit && this.state.userAccess.join("") != this.adminAccessLevel ? true : false))} */}
                        {this.inputTypeFilter("dealerId", paymentData.dealerId, 'text', "Dealer Id",)}

                    </div>
                    <div className="formgroupContainer">
                        <label className="newPaymentLabel">Reference No. </label>
                        {/* {this.inputType("Reference No.", "text", 'referenceNumber', this.state.newPaymentMap.referenceNumber || "", (this.state.userAccess.join("") != this.adminAccessLevel ? true : false))} */}
                        {this.inputTypeFilter("referenceNumber", paymentData.referenceNumber, 'text', "Reference No",)}

                    </div>
                    <div className="formgroupContainer">
                        {/* <span style={this.state.userAccess.join("") != this.adminAccessLevel ? { color: 'red' } : { display: 'none' }}>*</span> */}
                        <label className="newPaymentLabel">Customer Name </label>
                        {/* {this.inputType("Full Name", "text", 'name', this.state.newPaymentMap.name || "", (this.state.edit && this.state.userAccess.join("") != this.adminAccessLevel ? true : false))} */}
                        {this.inputTypeFilter("customerName", paymentData.customerName, 'text', "customerName",)}

                    </div>
                    <div className="formgroupContainer">
                        <label className="newPaymentLabel">Amount <span style={{ color: 'red' }}>*</span></label>
                        {/* {this.inputType("Amount", "number", 'amount', this.state.newPaymentMap.amount || "", (false))} */}
                        {this.inputTypeFilter("amount", paymentData.amount, 'number', "Amount",)}

                    </div>
                    <SelectType label = "Mode" name = "paymentMode"  options={paymentModeMapping || []} placeholder="select mode" value = {paymentData.paymentMode} />
                    <SelectType label = "Bank" name = "bank"  options={bankMapping || []} placeholder="select bank" value = {paymentData.bank} />


                  

                   



                    <div className="new-payment-submit" >
                        <button className="btnSubmit" type="submit" value="Submit">Submit</button>
                    </div>
                </form>
            </div>
            </div>

        )
    }
}


function mapStateToProps(state){
    return{
      returnMapping: state.fetchAllMapping && state.fetchAllMapping.returnMapping || {},
      unicommerceMapping: state.zunsolarBulkPIInvoice && state.zunsolarBulkPIInvoice.unicommerceMapping || [],
      returnSubOrders :   state.zunSolarOrderTracker &&  state.zunSolarOrderTracker.returnSubOrders || {}
  
  
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({createMTPayment }, dispatch)
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreatePaymentMobile)

