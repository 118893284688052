import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/userProfile.css';

export class UserProfileComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      firstname : 'Shivam',
      lastname : 'Dubey',
      email : 'shivam.dubey@zunroof.com',
      access : 'bitrix',
      role : 'Super Admin'
    }
  }
  componentDidMount(){
    console.log("profile props",this.props)
    const {isProfile} = this.props
  }
  render() {
    return (
      <div className="container-fluid parentDiv">
        <p>close</p>
        <img class="userImg" src={require('../assets/userDefault.png')} width="100px"></img>
        <h4 class="heading">User Info</h4>
        <hr></hr>
        <div className="infoDiv">
          <div className="individual">
            <p class="fieldName">First name</p>
            <p>{this.state.firstname}</p>
          </div>
          <div className="individual">
            <p className="fieldName">Last name</p>
            <p>{this.state.lastname}</p>
          </div>
          <div className="email">
            <p className="fieldName">Email</p>
            <p>{this.state.email}</p>
          </div>
          <div className="individual">
            <p className="fieldName">Role</p>
            <p>{this.state.role}</p>
          </div>
          <div className="individual">
            <p className="fieldName">Access</p>
            <p>{this.state.access}</p>
          </div> 
        </div>
        
      </div>
    )
  }
}
