import React from 'react';
import axios from 'axios';
import url from '../config';
import moment from 'moment';
import { BULK_EDIT_START, BULK_EDIT_SUCCESS, FETCH_ALL_LEADS_SUCCESS, SEARCH_SUCCESS } from './actiontypes';
import swal from 'sweetalert';

export function editBulkLeads(selectedIds, currData, bulkAssignObj, count, view = "solar") {

  let dateFormats = {
    SITE_ASSESSMENT_DATE: 'YYYYMMDD',
    ADDED_ON: 'YYYYMMDD',
    NEXT_ACTION_DATE: 'YYYYMMDD',
    SALES_DATE: 'YYYYMMDD'
  }
  return async (dispatch) => {

    let arr = [];
    Object.keys(selectedIds).map((val) => {
      if (selectedIds[val] == 1) {
        arr.push(val)
      }
    })

    let route;
    let lead
    switch (view) {
      case 'solar':
        route = "lead/edit/naman";
        lead = {
          BITRIX_ID: arr,
          ...bulkAssignObj
        }
        break;
      case 'zunpulse':
        route = "zunpulse/lead/edit/naman";
        lead = {
          leadId: arr,
          ...bulkAssignObj
        }
        break;
      case 'zunsolar':
        route = "zunsolar/lead/edit/naman";
        lead = {
          solarId: arr,
          ...bulkAssignObj
        }
        break;
      default:
        route = "lead/edit/naman";
        lead = {
          BITRIX_ID: arr,
          ...bulkAssignObj
        }
        break;
    }
    dispatch({ type: BULK_EDIT_START, payload: {} })

    Object.keys(dateFormats).map((key) => {
      if (lead[key]) {
        lead[key] = moment(lead[key]).format(dateFormats[key])
      }
    });

    axios.post(url.pathBackend + route, { lead }).then((response) => {
      // console.log("reponse for bulk upload", response);
      if (response.data.data) {
        swal({
          text: "Leads Edit Successfully",
          button: {
            cancel: "close"
          },
          icon: "success"
        })
        // console.log("1", lead)
        Object.keys(selectedIds).map((leadId) => {

          let index = currData.findIndex((val) => (val.leadId || val.BITRIX_ID || val.solarId) == leadId);
          // console.log("index", index)
          Object.keys(bulkAssignObj).map((field) => {
            currData[index][field] = bulkAssignObj[field];
          })
        })
        // console.log("currData", currData)
        dispatch({ type: FETCH_ALL_LEADS_SUCCESS, payload: { data: currData, count: count } })
        dispatch({ type: SEARCH_SUCCESS, payload: currData })
      }
      if (response.data.error) {
        swal({
          text: "Some Error Occured With Bulk Edit, Please Refresh Page and Try Again",
          button: {
            cancel: 'close'
          }
        })
      }
    })
  }
}

