import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import {
	CreateMarketingSpending,
	getMarketingSpending,
	getCouponDetails,
	CreateCouponDetails,
	deleteMarketingSpending,
	deleteCouponDetails,
} from "../../actions/zunpulseBurnDashboardAction";
import Header from "../../components/HeaderComponent";
import Select from "react-select";

class ZunpulseCreateMarketingSpending extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		this.props.getMarketingSpending("monthlyspending");
		this.props.getMarketingSpending("dailyspending");
		this.props.getCouponDetails();
	}

	componentWillReceiveProps(props) {
		let {
			monthlyMarketingSpendingData,
			monthlyMarketingSpendingColumns,
			monthlyMarketingSpendingReceived,
			dailyMarketingSpendingData,
			dailyMarketingSpendingColumns,
			dailyMarketingSpendingReceived,
			zunpulseCouponData,
			zunpulseCouponColumns,
			zunpulseCouponReceived,
		} = props;
		monthlyMarketingSpendingColumns = [
			...monthlyMarketingSpendingColumns,
			{
				Header: "Buttons",
				Cell: (row) => {
					return row.original.id ? (
						<div
							className="row text-center"
							style={{
								width: "fit-content",
								color: "#FF0000",
								cursor: "pointer",
							}}
							onClick={(e) =>
								this.handleDeleteSpending(row.original.id, "monthlyspending")
							}
						>
							Delete
						</div>
					) : (
						""
					);
				},
				minWidth: 200,
			},
		];
		dailyMarketingSpendingColumns = [
			...dailyMarketingSpendingColumns,
			{
				Header: "Buttons",
				Cell: (row) => {
					return row.original.id ? (
						<div
							className="row text-center"
							style={{
								width: "fit-content",
								color: "#FF0000",
								cursor: "pointer",
							}}
							onClick={(e) =>
								this.handleDeleteSpending(row.original.id, "dailyspending")
							}
						>
							Delete
						</div>
					) : (
						""
					);
				},
				minWidth: 200,
			},
		];
		zunpulseCouponColumns = [
			...zunpulseCouponColumns,
			{
				Header: "Buttons",
				Cell: (row) => {
					return row.original.id ? (
						<div
							className="row text-center"
							style={{
								width: "fit-content",
								color: "#FF0000",
								cursor: "pointer",
							}}
							onClick={(e) =>
								this.handleDeleteSpending(row.original.id, "couponDetail")
							}
						>
							Delete
						</div>
					) : (
						""
					);
				},
				minWidth: 200,
			},
		];
		this.setState({
			monthlyMarketingSpendingData,
			monthlyMarketingSpendingColumns,
			monthlyMarketingSpendingReceived,
			dailyMarketingSpendingData,
			dailyMarketingSpendingColumns,
			dailyMarketingSpendingReceived,
			zunpulseCouponData,
			zunpulseCouponColumns,
			zunpulseCouponReceived,
		});
	}

	handleDeleteSpending = (id, formtype) => {
		if (formtype == "dailyspending" || formtype == "monthlyspending") {
			this.props.deleteMarketingSpending({ id: id }, formtype);
		} else if (formtype == "couponDetail") {
			this.props.deleteCouponDetails({ id: id }, formtype);
		}
	};

	handleSubmit = (e, formtype) => {
		e.preventDefault();
		this.props.CreateMarketingSpending(this.state[formtype] || {}, formtype);
	};

	handleCouponDetailSubmit = (e, formtype) => {
		e.preventDefault();
		this.props.CreateCouponDetails(this.state[formtype]);
	};

	InputField = ({ name, type, required, formtype, inputParams = {} }) => {
		return (
			<input
				type={type}
				className="form-control"
				name={name}
				onChange={(e) => this.handleInputChange(e, formtype)}
				formtype={formtype}
				required={required}
				{...inputParams}
			/>
		);
	};

	handleInputChange = (e, formtype) => {
		let { name, value } = e.target;
		this.setState({
			[formtype]: {
				...this.state[formtype],
				[name]: value,
			},
		});
	};

	handleSelectChange = (e, name, formtype) => {
		this.setState({
			[formtype]: {
				...this.state[formtype],
				[name]: e.value,
			},
		});
	};

	render() {
		let { InputField, state, handleCouponDetailSubmit } = this;
		let {
			monthlyMarketingSpendingData = [],
			monthlyMarketingSpendingColumns = [],
			monthlyMarketingSpendingReceived = false,
			dailyMarketingSpendingData = [],
			dailyMarketingSpendingColumns = [],
			dailyMarketingSpendingReceived = false,
			zunpulseCouponData = [],
			zunpulseCouponColumns = [],
			zunpulseCouponReceived = false,
		} = state;
		return (
			<div className="analyseAc">
				<Header />
				<div class="container">
					<h4 style={{ fontWeight: "bold" }}>Add Marketing Spending</h4>
				</div>
				<div class="container">
					<form onSubmit={(e) => this.handleSubmit(e, "monthlyspending")}>
						<div className="row" style={{ justifyContent: "space-between" }}>
							<h4 style={{ fontWeight: "bold" }}>Monthy Spending</h4>
							<button className="button-save col-1" type="submit">
								Save
							</button>
						</div>
						<hr className="analyseAcHr"></hr>
						<div>
							<table>
								<tr>
									<td> Date </td>
									<td>
										<InputField
											name={"date"}
											type={"date"}
											formtype={"monthlyspending"}
											required={true}
										/>
									</td>
									<td> AD Cost </td>
									<td>
										<InputField
											name={"adsCost"}
											type={"number"}
											required={true}
											formtype={"monthlyspending"}
											inputParams={{ min: 0 }}
										/>
									</td>
									{/* <td> COG </td>
                                    <td>
                                        <InputField
                                            name={'cog'}
                                            type={'number'}
                                            required={true}
                                            formtype={'monthlyspending'}
                                            inputParams={{min:0}}
                                        />
                                    </td> */}
								</tr>
								<tr>
									{/* <td> LOG </td>
                                    <td>
                                        <InputField
                                            name={'log'}
                                            type={'number'}
                                            required={true}
                                            formtype={'monthlyspending'}
                                            inputParams={{min:0}}
                                        />
                                    </td> */}
									{/* <td> Product Cost </td>
                                    <td>
                                        <InputField
                                            name={'productCost'}
                                            type={'number'}
                                            required={true}
                                            formtype={'monthlyspending'}
                                            inputParams={{min:0}}
                                        />
                                    </td> */}
									<td> Coupon Name </td>
									<td>
										<Select
											isSearchable
											onChange={(e) => {
												this.handleSelectChange(
													e,
													"couponName",
													"monthlyspending"
												);
											}}
											name={"couponName"}
											options={(zunpulseCouponData || []).reduce((result, val) => {
												if(val && val.couponName){
													result.push({
														label: val.couponName,
														value: val.couponName
													})
												}
												return result;
											},[])}
										/>
									</td>
									<td> Logistic Cost </td>
									<td>
										<InputField
											name={"logisticCost"}
											type={"number"}
											required={true}
											formtype={"monthlyspending"}
											inputParams={{ min: 0 }}
										/>
									</td>
								</tr>
							</table>
						</div>
					</form>
				</div>
				<div style={{ width: "80%", margin: "2vw auto" }}>
					<ReactTable
						filterable={true}
						sortable={true}
						showPagination={true}
						defaultPageSize={5}
						data={monthlyMarketingSpendingData}
						columns={monthlyMarketingSpendingColumns}
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id])
								.toLowerCase()
								.indexOf(filter.value.toLowerCase()) > -1
						}
						loading={!monthlyMarketingSpendingReceived}
						style={{ textAlign: "center" }}
					/>
				</div>
				<div class="container">
					<form onSubmit={(e) => this.handleSubmit(e, "dailyspending")}>
						<div className="row" style={{ justifyContent: "space-between" }}>
							<h4 style={{ fontWeight: "bold" }}>Daily Spending</h4>
							<button className="button-save col-1" type="submit">
								Save
							</button>
						</div>
						<hr className="analyseAcHr"></hr>
						<div>
							<table>
								<tr>
									<td> Date </td>
									<td>
										<InputField
											name={"date"}
											type={"date"}
											formtype={"dailyspending"}
											required={true}
										/>
									</td>
									<td> AD Cost </td>
									<td>
										<InputField
											name={"adsCost"}
											type={"number"}
											required={true}
											formtype={"dailyspending"}
											inputParams={{ min: 0 }}
										/>
									</td>
								</tr>
								<tr>
									<td> Coupon Name </td>
									<td>
										<Select
											isSearchable
											onChange={(e) => {
												this.handleSelectChange(
													e,
													"couponName",
													"dailyspending"
												);
											}}
											name={"couponName"}
											options={(zunpulseCouponData || []).reduce((result, val) => {
												if(val && val.couponName){
													result.push({
														label: val.couponName,
														value: val.couponName
													})
												}
												return result;
											},[])}
										/>
									</td>
								</tr>
							</table>
						</div>
					</form>
				</div>
				<div style={{ width: "80%", margin: "2vw auto" }}>
					<ReactTable
						filterable={true}
						sortable={true}
						showPagination={true}
						defaultPageSize={5}
						data={dailyMarketingSpendingData}
						columns={dailyMarketingSpendingColumns}
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id])
								.toLowerCase()
								.indexOf(filter.value.toLowerCase()) > -1
						}
						loading={!dailyMarketingSpendingReceived}
						style={{ textAlign: "center" }}
					/>
				</div>
				<div class="container">
					<form onSubmit={(e) => handleCouponDetailSubmit(e, "couponDetails")}>
						<div className="row" style={{ justifyContent: "space-between" }}>
							<h4 style={{ fontWeight: "bold" }}>Coupon Details</h4>
							<button className="button-save col-1" type="submit">
								Save
							</button>
						</div>
						<hr className="analyseAcHr"></hr>
						<div>
							<table>
								<tr>
									<td> Platform </td>
									<td>
										<InputField
											name={"platform"}
											type={"text"}
											formtype={"couponDetails"}
											required={true}
										/>
									</td>
									<td> Coupon Prefix </td>
									<td>
										<InputField
											name={"couponName"}
											type={"text"}
											required={false}
											formtype={"couponDetails"}
										/>
									</td>
									<td> Coupon Type </td>
									<td>
										<InputField
											name={"description"}
											type={"text"}
											required={false}
											formtype={"couponDetails"}
											inputParams={{ min: 0 }}
										/>
									</td>
								</tr>
							</table>
						</div>
					</form>
				</div>
				<div style={{ width: "80%", margin: "2vw auto" }}>
					<ReactTable
						filterable={true}
						sortable={true}
						showPagination={true}
						defaultPageSize={5}
						data={zunpulseCouponData}
						columns={zunpulseCouponColumns}
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id])
								.toLowerCase()
								.indexOf(filter.value.toLowerCase()) > -1
						}
						loading={!zunpulseCouponReceived}
						style={{ textAlign: "center" }}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ zunpulseBurnDashboard }) {
	let {
		monthlyMarketingSpendingData = [],
		monthlyMarketingSpendingColumns = [],
		monthlyMarketingSpendingReceived = false,
		dailyMarketingSpendingData = [],
		dailyMarketingSpendingColumns = [],
		dailyMarketingSpendingReceived = false,
		zunpulseCouponData = [],
		zunpulseCouponColumns = [],
		zunpulseCouponReceived = false,
	} = zunpulseBurnDashboard;
	return {
		monthlyMarketingSpendingData,
		monthlyMarketingSpendingColumns,
		monthlyMarketingSpendingReceived,
		dailyMarketingSpendingData,
		dailyMarketingSpendingColumns,
		dailyMarketingSpendingReceived,
		zunpulseCouponData,
		zunpulseCouponColumns,
		zunpulseCouponReceived,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			CreateMarketingSpending,
			getMarketingSpending,
			getCouponDetails,
			CreateCouponDetails,
			deleteMarketingSpending,
			deleteCouponDetails,
		},
		dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ZunpulseCreateMarketingSpending);
