import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import '../../styles/pulseOpsDashboardStyles.css';
import Select from 'react-select';
import { fetchLastEditTime, fetchMetricsSummary, fetchSalesSummaryOrderView, fetchShippingSummaryOrderView, fetchSubOrderValueViewNew, fetchLastTenDaysSummaryOrderView, fetchCategorySummary, fetchStateSummary, fetchSalesSummaryUnitView, fetchShippingSummaryUnitView, fetchLastTenDaysSummaryUnitView, fetchMapData, fetchSalesSummaryDisplayOrderIdView, fetchOrderValueNew } from '../../actions/zunsolarOpsDashboardActions';
import { MetricsSummaryComponent, SalesSummaryComponent, ShippingSummaryComponent, SubOrderValueViewComponent, LastTenDaysSummaryComponent, CategorySummaryComponent, StateSummaryComponent, MapViewComponent, OrderValueViewNewComponent } from '../../components/zunsolar/ZunsolarOpsDashboardComponent';
import { fetchMapping } from '../../actions/zunsolarOrderTrackerActions';
import { Redirect } from 'react-router-dom';

const mapViewTypeOptions = [
    { label: 'Sub Order', value: 'subOrder' },
    { label: 'Unit', value: 'Unit' }
]

class ZunsolarOpsDashboardNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableType: 'Order',
            orderDateFilters: {
                startDate: '',
                endDate: ''
            },
            stateTableSelectedCategory: 'lighting',
            mapViewType: 'subOrder',
            mapping: {},
            userObj:{}
        };
    }

    componentWillMount() {
        let userObj = JSON.parse(localStorage.getItem("userObj"));
        if (Object.keys(userObj).length > 0) {
            this.setState({
              userObj: userObj
            })
          }
         if(userObj.role!="Super Admin") {
            return;
            
        }
       
        let { stateTableSelectedCategory, mapViewType } = this.state
        this.props.fetchMapping([this.props.fetchSalesSummaryOrderView, this.props.fetchSalesSummaryUnitView, this.props.fetchSalesSummaryDisplayOrderIdView]);
        this.props.fetchLastEditTime();
        this.props.fetchMetricsSummary();
        this.props.fetchShippingSummaryOrderView();
        this.props.fetchSubOrderValueViewNew();
        this.props.fetchLastTenDaysSummaryOrderView();
        this.props.fetchCategorySummary();
        this.props.fetchStateSummary(stateTableSelectedCategory);
        this.props.fetchShippingSummaryUnitView();
        this.props.fetchLastTenDaysSummaryUnitView();
        this.props.fetchMapData(mapViewType);
        this.props.fetchOrderValueNew();  
    }

    componentWillReceiveProps(props) {
        let { lastEditTime = "", metricsSummaryReceived = false, metricsSummary = {}, salesSummaryOrderColumns = [], salesSummaryOrderReceived = false, salesSummaryOrder = [], salesSummaryDisplayOrderId = [], salesSummaryDisplayOrderIdColumns = [], salesSummaryDisplatOrderIdReceived = false, mapping = {}, shippingSummarySubOrder = [], shippingSummarySubOrderColumns = [], shippingSummarySubOrderReceived = false, subOrderValueView = [], subOrderValueViewColumns = [], subOrderValueViewReceived = false, lastTenDaysSummaryOrder = [], lastTenDaysSummaryOrderColumns = [], lastTenDaysSummaryOrderReceived = false, categorySummary = [], categorySummaryColumns = [], categorySummaryReceived = false, stateSummary = [], stateSummaryColumns = [], stateSummaryReceived = false, salesSummaryUnit = [], salesSummaryUnitColumns = [], salesSummaryUnitReceived = false, shippingSummaryUnit = [], shippingSummaryUnitColumns = [], shippingSummaryUnitReceived = false, lastTenDaysSummaryUnit = [], lastTenDaysSummaryUnitColumns = [], lastTenDaysSummaryUnitReceived = false, mapData = [], mapDataReceived = false, orderValueNew = [], orderValueNewReceived = false, orderValueNewColumns = [] } = props;

        let stateOptions = (mapping.states || []).map(state => ({ label: state, value: state }))
        let statusOptions = (mapping.status || []).map((status) => ({ label: status, value: status }));
        let platformOptions = (mapping.platform || []).map((plat) => ({ label: plat, value: plat }));
        let platformTypeOptions = Object.keys(mapping.platformType || {}).map((platType) => ({ label: platType, value: mapping.platformType[platType] || [] }));
        let categoryOptions = (Object.keys(mapping.category || {})).map(cat => ({ label: cat, value: cat }));
        // let productOptions = [].concat.apply([], Object.keys(mapping.category || {}).map((cat) => { return (mapping.category[cat] || []).map((product) => ({ label: `${cat} ${product}`, value: `${product}` })) }))
        // console.log("mapping", mapping);
        let productOptions = (mapping.uniProductIds || []).filter(row => row.category.toLowerCase() != "combo").map(row => ({ label: `${row.pID} ${row.productName}`, value: row.pID }));
        this.setState({
            shippingSummarySubOrder,
            shippingSummarySubOrderColumns,
            shippingSummarySubOrderReceived,
            lastEditTime,
            metricsSummaryReceived,
            metricsSummary,
            salesSummaryOrderColumns,
            salesSummaryOrderReceived,
            salesSummaryOrder,
            salesSummaryDisplayOrderId,
            salesSummaryDisplayOrderIdColumns,
            salesSummaryDisplatOrderIdReceived,
            mapping,
            subOrderValueView,
            subOrderValueViewColumns,
            subOrderValueViewReceived,
            lastTenDaysSummaryOrder,
            lastTenDaysSummaryOrderColumns,
            lastTenDaysSummaryOrderReceived,
            categorySummary,
            categorySummaryColumns,
            categorySummaryReceived,
            stateSummary,
            stateSummaryColumns,
            stateSummaryReceived,
            salesSummaryUnit,
            salesSummaryUnitColumns,
            salesSummaryUnitReceived,
            shippingSummaryUnit,
            shippingSummaryUnitColumns,
            shippingSummaryUnitReceived,
            lastTenDaysSummaryUnit,
            lastTenDaysSummaryUnitColumns,
            lastTenDaysSummaryUnitReceived,
            mapData,
            mapDataReceived,
            stateOptions,
            statusOptions,
            platformOptions,
            categoryOptions,
            productOptions,
            platformTypeOptions,
            orderValueNew,
            orderValueNewReceived,
            orderValueNewColumns
        })
    }

    handleStateCategoryChange = (e) => {
        let value = e.value;
        this.setState({
            stateTableSelectedCategory: value
        })
    }

    handleFilterChange = (type, name, value) => {
        let appliedFilters = (value || []).map(option => option.value);
        this.setState({
            [type]: {
                ...this.state[type],
                [name]: appliedFilters
            }
        })
    }

    handleObjArrtFilterChange = (type, name, value) => {
        let appliedFilters = [];
        (value || []).forEach(option => {
            (option.value || []).forEach( option => {
                appliedFilters.push(option);
            })
        });
        this.setState({
            [type]: {
                ...this.state[type],
                [name]: appliedFilters
            }
        })
    }

    handleOnViewChange = (e) => {
        let { name = '' } = e.target;
        this.setState({ tableType: name });
    }

    TabsComponent = ({ name, label }) => {
        let { tableType = '' } = this.state;
        let { handleOnViewChange } = this;
        return <button className={`btn ${tableType === name ? "btn-info" : "btn-outline-info"}`} name={name} onClick={handleOnViewChange} style={{ margin: '10px 0 0 16px' }}>{label}</button>;
    }

    handleMapViewFiltersChange = (name, value) => {
        if (name == "mapViewType") {
            this.setState({
                mapViewType: value.value,
                productsFilterMapView: []
            })
        }
        else {
            this.setState({ [name]: value })
        }
    }

    handleDateFilterChange = (e) => {
        let { name, value } = e.target;
        let val;
        if (name == "startDate") {
            val = value ? moment(value).startOf('day').format("YYYY-MM-DD") : "";
        }
        else {
            val = value ? moment(value).endOf('day').format("YYYY-MM-DD") : "";
        }
        this.setState({
            orderDateFilters: {
                ...this.state.orderDateFilters,
                [name]: val
            }
        })
    }

    applyFilter = (type) => {
        let filters = this.state[type] || {};
        let { orderDateFilters, stateTableSelectedCategory, mapping } = this.state;
        filters = { ...filters, date: orderDateFilters, platform: [...(filters.platform || []), ...(filters.platformsByplatType || [])] };
        delete filters.platformsByplatType;
        switch (type) {
            case "salesSummaryOrderFilters":
                this.props.fetchSalesSummaryOrderView(mapping, filters);
                this.setState({ salesSummaryOrderReceived: false })
                break;
            case "salesSummaryDisplayOrderIdFilters":
                this.props.fetchSalesSummaryDisplayOrderIdView(mapping, filters);
                this.setState({ salesSummaryDisplatOrderIdReceived: false });
                break;
            case "shippingSummaryOrderFilters":
                this.props.fetchShippingSummaryOrderView(filters);
                this.setState({ shippingSummarySubOrderReceived: false })
                break;
            case "orderValueFilters":
                this.props.fetchSubOrderValueViewNew(filters);
                this.setState({ subOrderValueViewReceived: false })
                break;
            case "lastTenDaysSummaryOrderFilters":
                this.props.fetchLastTenDaysSummaryOrderView(filters);
                this.setState({ lastTenDaysSummaryOrderReceived: false })
                break;
            case "stateSummaryFilters":
                this.props.fetchStateSummary(stateTableSelectedCategory, filters);
                this.setState({ stateSummaryReceived: false })
                break;
            case "salesSummaryUnitFilters":
                this.props.fetchSalesSummaryUnitView(mapping, filters);
                this.setState({ salesSummaryUnitReceived: false })
                break;
            case "shippingSummaryUnitFilters":
                this.props.fetchShippingSummaryUnitView(filters);
                this.setState({ shippingSummaryUnitReceived: false })
                break;
            case "lastTenDaysSummaryUnitFilters":
                this.props.fetchLastTenDaysSummaryUnitView(filters);
                this.setState({ lastTenDaysSummaryUnitReceived: false })
                break;
            case "categorySummaryFilters":
                this.props.fetchCategorySummary(filters);
                this.setState({ categorySummaryReceived: false })
            case "orderValueNewFilters":
                this.props.fetchOrderValueNew(filters);
                this.setState({ orderValueNewReceived: false })
            default:
                break;
        }
    }

    onApplyDateFilter = () => {
        let { orderDateFilters = {}, mapViewType, productsFilterMapView = [], mapping } = this.state;
        if (this.state.tableType == "Map") {
            let mapViewFilters = {
                date: orderDateFilters,
                pID: (productsFilterMapView || []).map(row => row.value)
            }
            this.props.fetchMapData(mapViewType, mapViewFilters);
        }
        else {
            let { orderDateFilters = {}, salesSummaryUnitFilters = {}, shippingSummaryUnitFilters = {}, orderValueFilters = {}, stateSummaryFilters = {}, salesSummaryOrderFilters = {}, shippingSummaryOrderFilters = {}, categorySummaryFilters = {}, salesSummaryDisplayOrderIdFilters = {}, orderValueNewFilters = {} } = this.state;
            this.props.fetchSalesSummaryUnitView(mapping,{ ...salesSummaryUnitFilters, date: orderDateFilters });
            this.props.fetchShippingSummaryUnitView({ ...shippingSummaryUnitFilters, date: orderDateFilters });
            this.props.fetchSubOrderValueViewNew({ ...orderValueFilters, date: orderDateFilters });
            this.props.fetchSalesSummaryOrderView(mapping,{ ...salesSummaryOrderFilters, date: orderDateFilters });
            this.props.fetchSalesSummaryDisplayOrderIdView(mapping,{ ...salesSummaryDisplayOrderIdFilters, date: orderDateFilters })
            this.props.fetchCategorySummary({ ...categorySummaryFilters, date: orderDateFilters });
            this.props.fetchStateSummary(this.state.stateTableSelectedCategory, { ...stateSummaryFilters, date: orderDateFilters });
            this.props.fetchShippingSummaryOrderView({ ...shippingSummaryOrderFilters, date: orderDateFilters });
            this.props.fetchOrderValueNew({ ...orderValueNewFilters, date: orderDateFilters });
            this.setState({
                salesSummarySubOrderReceived: false,
                salesSummaryUnitReceived: false,
                lastTenDaysSummarySubOrderReceived: false,
                lastTenDaysSummaryUnitReceived: false,
                shippingSummarySubOrderReceived: false,
                shippingSummaryUnitReceived: false,
                orderValueViewReceived: false,
                categorySummaryReceived: false,
                stateSummaryReceived: false,
                salesSummaryOrderReceived: false,
                shippingSummaryOrderReceived: false,
                lastTenDaysSummaryOrderReceived: false,
                orderValueNewReceived: false
            })
        }
    }

    render() {
        
        if(this.state.userObj.role!='Super Admin'){
            return <Redirect to={"/"}/>
        }
        let { TabsComponent, state, applyFilter, handleFilterChange, handleStateCategoryChange, handleMapViewFiltersChange, handleDateFilterChange, onApplyDateFilter, handleObjArrtFilterChange } = this
        let { lastEditTime, metricsSummary = {}, tableType, salesSummaryOrderColumns = [], salesSummaryOrderReceived = false, salesSummaryOrder = [], shippingSummarySubOrder = [], shippingSummarySubOrderColumns = [], shippingSummarySubOrderReceived = false, subOrderValueView = [], subOrderValueViewColumns = [], subOrderValueViewReceived = false, lastTenDaysSummaryOrder = [], lastTenDaysSummaryOrderColumns = [], lastTenDaysSummaryOrderReceived = false, categorySummary = [], categorySummaryColumns = [], categorySummaryReceived = false, stateSummary = [], stateSummaryColumns = [], stateSummaryReceived = false, stateTableSelectedCategory, salesSummaryUnit = [], salesSummaryUnitColumns = [], salesSummaryUnitReceived = false, shippingSummaryUnit = [], shippingSummaryUnitColumns = [], shippingSummaryUnitReceived = false, lastTenDaysSummaryUnit = [], lastTenDaysSummaryUnitColumns = [], lastTenDaysSummaryUnitReceived = false, stateOptions, statusOptions, platformOptions, platformTypeOptions, categoryOptions, productOptions, mapData = [], mapDataReceived = false, mapViewType, mapping = {}, salesSummaryDisplayOrderId = [], salesSummaryDisplayOrderIdColumns = [], salesSummaryDisplatOrderIdReceived = false, orderValueNew = [], orderValueNewReceived = false, orderValueNewColumns = [] } = state;
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <div style={{ "marginTop": 80 }} className="container-fluid zunpulseOpsDashboardMainDiv">
                    <b>Last Edited - {lastEditTime ? moment(lastEditTime).format('MMMM Do YYYY, h:mm:ss a') : ""}</b>
                    <hr />
                    <MetricsSummaryComponent
                        data={metricsSummary}
                    />
                    <div className="mb-3 mt-3">
                        <div style={{ float: 'left' }}>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group">
                                        <label>{"Start Date"}</label>
                                        <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateFilterChange(e)} />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                        <label>{"End Date"}</label>
                                        <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateFilterChange(e)} />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button className="form-control btn btn-info btn-sm" onClick={onApplyDateFilter}>Apply Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ float: 'right' }}>
                            <TabsComponent name="Order" label="Order" />
                            <TabsComponent name="Unit" label="Unit" />
                            <TabsComponent name="Map" label="Map" />
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    {
                        tableType == "Map" ? <div className="row">
                            <div className="col-md-2">
                                <Select
                                    isSearchable
                                    onChange={(e) => handleMapViewFiltersChange("mapViewType", e)}
                                    placeholder={"Choose Type"}
                                    name={"mapViewType"}
                                    options={mapViewTypeOptions}
                                    defaultValue={{ label: mapViewType, value: mapViewType }}
                                />
                            </div>
                            {mapViewType != "subOrder" ? <div className="col-md-2">
                                <Select
                                    isSearchable
                                    isMulti
                                    onChange={(e) => handleMapViewFiltersChange("productsFilterMapView", e)}
                                    placeholder={"Choose Products"}
                                    name={"productsFilterMapView"}
                                    options={productOptions}
                                />
                            </div> : ""}
                        </div> : ""
                    }
                    {tableType == "Order" ? <SalesSummaryComponent
                        data={salesSummaryOrder}
                        columns={salesSummaryOrderColumns}
                        loading={!salesSummaryOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummaryOrderFilters"}
                        applyFilter={applyFilter}
                        tableType={tableType}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType == "Order" ? <SalesSummaryComponent
                        data={salesSummaryDisplayOrderId}
                        columns={salesSummaryDisplayOrderIdColumns}
                        loading={!salesSummaryDisplatOrderIdReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummaryDisplayOrderIdFilters"}
                        applyFilter={applyFilter}
                        tableType={tableType}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                        orderIdName={"Display Order"}
                    /> : ""}
                    {tableType == "Unit" ? <SalesSummaryComponent
                        data={salesSummaryUnit}
                        columns={salesSummaryUnitColumns}
                        loading={!salesSummaryUnitReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={productOptions}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummaryUnitFilters"}
                        applyFilter={applyFilter}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType == "Order" ? <ShippingSummaryComponent
                        data={shippingSummarySubOrder}
                        columns={shippingSummarySubOrderColumns}
                        loading={!shippingSummarySubOrderReceived}
                        handleSelectChange={handleFilterChange}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                        stateOptions={stateOptions}
                        shippingStatusOptions={statusOptions}
                        type={"shippingSummaryOrderFilters"}
                        applyFilter={applyFilter}
                        tableType={tableType}
                        platformOptions={platformOptions}
                        platformTypeOptions={platformTypeOptions}
                    /> : ""}
                    {tableType == "Unit" ? <ShippingSummaryComponent
                        data={shippingSummaryUnit}
                        columns={shippingSummaryUnitColumns}
                        loading={!shippingSummaryUnitReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={productOptions}
                        platformOptions={platformOptions}
                        type={"shippingSummaryUnitFilters"}
                        applyFilter={applyFilter}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType != "Map" ? <SubOrderValueViewComponent
                        data={subOrderValueView}
                        columns={subOrderValueViewColumns}
                        loading={!subOrderValueViewReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        platformOptions={platformOptions}
                        shippingStatusOptions={statusOptions}
                        type={"orderValueFilters"}
                        applyFilter={applyFilter}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType != "Map" ? <OrderValueViewNewComponent
                        data={orderValueNew}
                        columns={orderValueNewColumns}
                        loading={!orderValueNewReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        platformOptions={platformOptions}
                        type={"orderValueNewFilters"}
                        applyFilter={applyFilter}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType == "Order" ? <LastTenDaysSummaryComponent
                        data={lastTenDaysSummaryOrder}
                        columns={lastTenDaysSummaryOrderColumns}
                        loading={!lastTenDaysSummaryOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        shippingStatusOptions={statusOptions}
                        type={"lastTenDaysSummaryOrderFilters"}
                        applyFilter={applyFilter}
                        tableType={tableType}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType == "Unit" ? <LastTenDaysSummaryComponent
                        data={lastTenDaysSummaryUnit}
                        columns={lastTenDaysSummaryUnitColumns}
                        loading={!lastTenDaysSummaryUnitReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={productOptions}
                        shippingStatusOptions={statusOptions}
                        type={"lastTenDaysSummaryUnitFilters"}
                        applyFilter={applyFilter}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType != "Map" ? <CategorySummaryComponent
                        data={categorySummary}
                        columns={categorySummaryColumns}
                        loading={!categorySummaryReceived}
                        type={"categorySummaryFilters"}
                        applyFilter={applyFilter}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType != "Map" ? <StateSummaryComponent
                        data={stateSummary}
                        columns={stateSummaryColumns}
                        loading={!stateSummaryReceived}
                        type={"stateSummaryFilters"}
                        stateOptions={stateOptions}
                        selectedCategory={stateTableSelectedCategory}
                        categoryOptions={categoryOptions}
                        handleCategoryChange={handleStateCategoryChange}
                        handleSelectChange={handleFilterChange}
                        applyFilter={applyFilter}
                        platformTypeOptions={platformTypeOptions}
                        handleObjArrtFilterChange={handleObjArrtFilterChange}
                    /> : ""}
                    {tableType == "Map" && mapDataReceived && mapData.length > 1 ? <MapViewComponent
                        data={mapData}
                    /> : ""}
                </div>
            </div>
        );


    }
}

function mapStateToProps({ zunSolarOrderTracker, zunSolarOpsDashboard = {} }) {
    let { mapping = {} } = zunSolarOrderTracker || {};
    let { lastEditTime = '', metricsSummary = {}, metricsSummaryReceived = false, salesSummaryOrder = [], salesSummaryOrderColumns = [], salesSummaryOrderReceived = false, shippingSummarySubOrder = [], shippingSummarySubOrderColumns = [], shippingSummarySubOrderReceived = false, subOrderValueView = [], subOrderValueViewColumns = [], subOrderValueViewReceived = false, lastTenDaysSummaryOrder = [], lastTenDaysSummaryOrderColumns = [], lastTenDaysSummaryOrderReceived = false, categorySummary = [], categorySummaryColumns = [], categorySummaryReceived = false, stateSummary = [], stateSummaryColumns = [], stateSummaryReceived = false, salesSummaryUnit = [], salesSummaryUnitColumns = [], salesSummaryUnitReceived = false, shippingSummaryUnit = [], shippingSummaryUnitColumns = [], shippingSummaryUnitReceived = false, lastTenDaysSummaryUnit = [], lastTenDaysSummaryUnitColumns = [], lastTenDaysSummaryUnitReceived = false, mapData = [], mapDataReceived = false, salesSummaryDisplayOrderId = [], salesSummaryDisplayOrderIdColumns = [], salesSummaryDisplatOrderIdReceived = false, orderValueNew = [], orderValueNewReceived = false, orderValueNewColumns = [] } = zunSolarOpsDashboard;
    return {
        mapping,
        lastEditTime,
        metricsSummary,
        metricsSummaryReceived,
        salesSummaryOrderReceived,
        salesSummaryOrder,
        salesSummaryOrderColumns,
        salesSummaryDisplayOrderId,
        salesSummaryDisplayOrderIdColumns,
        salesSummaryDisplatOrderIdReceived,
        shippingSummarySubOrder,
        shippingSummarySubOrderColumns,
        shippingSummarySubOrderReceived,
        subOrderValueView,
        subOrderValueViewColumns,
        subOrderValueViewReceived,
        lastTenDaysSummaryOrder,
        lastTenDaysSummaryOrderColumns,
        lastTenDaysSummaryOrderReceived,
        categorySummary,
        categorySummaryColumns,
        categorySummaryReceived,
        stateSummary,
        stateSummaryColumns,
        stateSummaryReceived,
        salesSummaryUnit,
        salesSummaryUnitColumns,
        salesSummaryUnitReceived,
        shippingSummaryUnit,
        shippingSummaryUnitColumns,
        shippingSummaryUnitReceived,
        lastTenDaysSummaryUnit,
        lastTenDaysSummaryUnitColumns,
        lastTenDaysSummaryUnitReceived,
        mapData,
        mapDataReceived,
        orderValueNew,
        orderValueNewReceived,
        orderValueNewColumns
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, fetchLastEditTime, fetchMetricsSummary, fetchSalesSummaryOrderView, fetchMapping, fetchShippingSummaryOrderView, fetchSubOrderValueViewNew, fetchLastTenDaysSummaryOrderView, fetchCategorySummary, fetchStateSummary, fetchSalesSummaryUnitView, fetchShippingSummaryUnitView, fetchLastTenDaysSummaryUnitView, fetchMapData, fetchSalesSummaryDisplayOrderIdView, fetchOrderValueNew }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarOpsDashboardNew);