import React from "react";
import { getSearchResult } from "../../actions/searchAction";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { affiliateListColumns } from "../../config/column_fields";

class ShowAffiliates extends Component {
  constructor() {
    super();
    this.state = {
      affiliateList: [],
      ListColumns: [],
    };
  }

  componentDidMount() {
    let ListColumns = affiliateListColumns.map((row) => {
      return row;
    });
    this.setState({ ListColumns });
  }

  componentWillMount() {
    this.props.fetchAffiliateList();
  }

  componentWillReceiveProps({ affiliateList = [] }) {
    let list = Object.keys(affiliateList).map(e=>{
      return affiliateList[e]
    })
    console.log(list);
    // console.log(affiliateList);
    affiliateList=list
    this.setState({ affiliateList });
  }


  render() {
    let { affiliateList, ListColumns } = this.state;

    return (
      <div className="procurementAddVendor">
        <ReactTable
          filterable={true}
          showPagination={true}
          sortable={true}
          data={affiliateList}
          columns={ListColumns}
          defaultSorted={[{ id: "id", desc: true }]}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowAffiliates);
