import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import '../../styles/reactTable.css'
import '../../styles/allLeadsView.css';
import { Link } from 'react-router-dom';
import Select, { NonceProvider } from 'react-select';
import moment from 'moment';
import swal from 'sweetalert';
import 'react-dates/initialize';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Loader } from '../loader'
import { CSVLink } from 'react-csv';
import url from '../../config';


class AllOfflineDistributorLeadView extends Component {

  constructor(props) {

    super(props);
    this.reactTable = React.createRef();
    this.state = {
      stages: [],
      responsible: [],
      source: [],
      regions: [],
      statesArr: [],
      leadType : [ { value: "Distributor", label: "Distributor" },{ value: "Distributor ZP", label: "Distributor ZP" },{ value: "Distributor ZV", label: "Distributor ZV" }],
      type: [],
      city: [],
      languages: [],
      updatedByOptions: [],
      highValue: [{ value: 0, label: 0 }, { value: 1, label: 1 }],
      active: [],
      modified: [{ value: "yes", label: "yes" }, { value: "no", label: "no" }],
      allLeadData: [],
      tableData: [],
      mappingData: {},
      limit: 100,
      limitoption: [
        { value: 500, label: '500' },
        { value: 1000, label: '1000' },
        { value: 5000, label: '5000' }
      ],
      page: 1,
      showFilter: false,
      filterMap: {
        leadType: true,
        CITY: true,
        STAGE: true,
        ACTIVE: true,
        ADDED_ON: true,
        nextActionDate: true,
        responsible_person: true,
        existing_dealership: true,
        has_gst: true,
        turnover: true,
        highValue: true,
        updatedBy: true,
        modifiedOn: true,
        partner_onboarding_date: true,
        drop_off_reason: true,
        state: true,
        source: true,
        reasonForHold: true,
        currentBusiness: true,
        marketingMaterialStatus: true,
        leadSubType: true,
        onboardedBy: true,
        meetingDate: true,
        meetingScheduledBy: true,
        meetingDoneBy: true
      },
      tableMap: {
        solarId: true,
        name: true,
        leadType: true,
        city: true,
        responsiblePerson: true,
        companyName: true,
        stage: true,
        active: true,
        addedOn: true,
        created: true,
        source: true,
        nextActionDate: true,
        state: true,
        has_gst: true,
        turnover: true,
        existing_dealership: true,
        gst_number: true,
        UTM_SOURCE: true,
        UTM_MEDIUM: true,
        UTM_CAMPAIGN: true,
        placement: true,
        reasonForHold: true,
        currentBusiness: true,
        marketingMaterialStatus: true,
        meetingDate: true,
        meetingScheduledBy: true,
        meetingDoneBy: true,
        latitude: true,
        longitude: true,
        establishment_type: true
      },
      filterObj: false,
      filterData: {
        stage:["Sold"]
      },
      filterDate: {},
      selectedLeadIds: {},
      selectAll: false,
      bulkAssign: {},
      startDate: moment(),
      endDate: null,
      // startDate: moment().subtract(29, 'days'),
      // endDate: moment(),
      focusedInput: null,
      count: "",
      loading: true,
      recieved: false,
      downloadData: [],
      cityArr: []
      // ranges: {
      //   'Today': [moment(), moment()],
      //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      //   'This Month': [moment().startOf('month'), moment().endOf('month')],
      //   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      // },
    }
  }

  componentDidMount() {
    this.setState({
      filterMap: localStorage.getItem("filterMapZunsolar") ? JSON.parse(localStorage.getItem("filterMapZunsolar")) : this.state.filterMap,
      tableMap: localStorage.getItem("tableMapZunsolar") ? JSON.parse(localStorage.getItem("tableMapZunsolar")) : this.state.tableMap
    })
  }

  componentWillReceiveProps(props) {
    // console.log("1", props.allMapping.mapping);

    let stages = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.stage ? props.allMapping.mapping.stage : [], "STATUS_NAME");
    let source = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.source ? props.allMapping.mapping.source : [], "language_name");
    let languages = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.language ? props.allMapping.mapping.language : [], "language_name");
    let active = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.active ? props.allMapping.mapping.active : [], "language_name");
    let type = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.type ? Object.keys(props.allMapping.mapping.type) : [], "language_name");
    let state = this.optionFunction(props.allMapping.stateCityMap && props.allMapping.stateCityMap ? Object.keys(props.allMapping.stateCityMap) : [], "langauge_name");
    let reasonForHold = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.reasonForHold ? props.allMapping.mapping.reasonForHold : []);
    let cityList = props.allMapping.stateCityMap && props.allMapping.stateCityMap ? [].concat(...Object.values(props.allMapping.stateCityMap)) : []
    let city = this.optionFunction(cityList, "language_name");
    // let city = this.optionFunction(props.allMapping.mapping.city ? props.allMapping.mapping.city : [], "language_name");
    let employees = this.optionFunction(props.allMapping && props.allMapping.employees ? props.allMapping.employees : [], "e_name")
    let turnover = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.turnover ? props.allMapping.mapping.turnover : [], "e_name")
    let drop_off_reason = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.drop_off_reason ? props.allMapping.mapping.drop_off_reason : [], "e_name")
    let updatedByOptions = (props.allMapping && props.allMapping.employees || []).map(employee => ({ label: employee.e_name, value: employee.e_email }))
    let mappingData = props.allMapping ? props.allMapping.mapping : {};
    let currentBusiness = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.currentBusiness ? props.allMapping.mapping.currentBusiness : [], "language_name");
    let marketingMaterialStatus = this.optionFunction(props.allMapping.mapping && props.allMapping.mapping.marketingMaterialStatus ? props.allMapping.mapping.marketingMaterialStatus : [], "language_name");

    if (props.searchData.length > 0) {
      this.setState({
        allLeadData: props.searchData,
        tableData: props.searchData,
        stages: stages,
        source: source,
        mappingData: mappingData,
        active: active,
        type: type,
        city: city,
        turnover,
        count: props.searchData.length,
        updatedByOptions,
        drop_off_reason,
        loading: false,
        recieved: true,
        statesArr: state,
        currentBusiness
      }, () => { })
    } if (Object.keys(props.allLeads).length > 0 && Object.keys(props.allMapping).length > 0) {

      this.setState({
        loading: false,
        recieved: true,
        stages: stages,
        source: source,
        allLeadData: props.allLeads.data,
        tableData: props.allLeads.data,
        languages: languages,
        mappingData: mappingData,
        active: active,
        city: city,
        type: type,
        count: props.allLeads.count || "",
        employees: employees,
        turnover,
        updatedByOptions,
        drop_off_reason,
        statesArr: state,
        reasonForHold,
        currentBusiness,
        marketingMaterialStatus
      })
    }

  }

  optionFunction = (array, value) => {
    let multiSelectOptionsMap = array.map((data) => {
      let obj = {}

      if (data[value]) {
        obj = {
          label: data[value].replace(/(^"|"$)/g, ''),
          value: data[value].replace(/(^"|"$)/g, '')
        }
      } else {
        obj = {
          label: data,
          value: data
        }
      }
      return obj
    })
    return multiSelectOptionsMap;
  }

  optionFunctionState = (array) => {
    let multiSelectOptionsStateMap = array.map((val) => {
      let obj = {
        label: val.replace(/(^"|"$)/g, ''),
        value: val.replace(/(^"|"$)/g, ''),
      }
      return obj;
    })
    return multiSelectOptionsStateMap
  }

  handleSelectChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: [value]
      }
    }, () => {
    })
  }

  handleMultiSelectChange = (e, name) => {
    if (name == "state") {
      let cityArr = [];
      let selectedList = (e || []).map((data) => {
        cityArr = cityArr.concat(this.props.allMapping.stateCityMap[data.label])
        return data.value
      });
      this.setState({
        cityArr: cityArr.length > 0 ? cityArr.map(val => ({ label: val, value: val })) : [],
        filterData: {
          ...this.state.filterData,
          [name]: selectedList
        },
        page: 1
      }, () => {
        // console.log("filterData after multiSelect", this.state.filterData)
      })
    } else if (name == "leadType") {
      let subTypesArr = [];
      let selectedList1 = (e || []).map((data) => {
        console.log("this.props.allnaoiofjsjif", this.props.allMapping.mapping.type[data.label])
        subTypesArr = subTypesArr.concat(this.props.allMapping.mapping.type[data.label]);
        return data.value
      });
      console.log("selected list 1", subTypesArr);
      this.setState({
        subTypesArr: subTypesArr.length > 0 ? subTypesArr.map(val => ({ label: val, value: val })) : [],
        filterData: {
          ...this.state.filterData,
          [name]: selectedList1
        },
        page: 1
      })
    } else {
      let selectedList = (e || []).map((data) => {
        return data.value
      });
      this.setState({
        filterData: {
          ...this.state.filterData,
          [name]: selectedList
        },
        page: 1
      }, () => {
        // console.log("filterData after multiSelect", this.state.filterData)
      })
    }


  }

  handleDateChange = (e, field) => {
    let { name, value } = e.target
    this.setState({
      filterData: {
        ...this.state.filterData,
        [field]: {
          ...this.state.filterData[field] ? this.state.filterData[field] : {},
          [name]: value
        }
      },
      page: 1
    }, () => {
    });
  }

  handleInputChange = (e) => {
    let { name, value } = e.target
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: value,
      }
    })
  }

  handleNewDateChange = (e) => {
    console.log("eeeeeee", e)
  }

  handleStateChange = (e, name) => {
    let regionArr = []
    let selectedState = (e || []).map((data) => {
      regionArr = regionArr.concat(this.props.allMapping.stateregion[data.label])
      return data.label
    });
    this.setState({
      regions: regionArr,
      filterData: {
        ...this.state.filterData,
        [name]: selectedState
      }
    })
  }

  handleSelectChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      bulkAssign: {
        ...this.state.bulkAssign,
        [name]: value
      }
    }, () => {
    })
  }

  multiSelectType = (name, options, placeholder, value) => {
    return (
      <div className="form-group" style={{ width: '100%' }}>
        <Select
          isMulti
          isSearchable
          onChange={(e) => { this.handleMultiSelectChange(e, name) }}
          placeholder={placeholder}
          name={name}
          options={options}
        />
      </div>
    )
  }

  selectType = (name, value, options) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  inputTypeText = (name, value, type, placeholder) => {

    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />

  }

  dateType = (name, value, field) => {
    return (
      <div class="form-group">
        <span class="dateSideBar" />
        <input name={name} style={{ width: "100%" }} className="form-control" type="date" onChange={(e) => { this.handleDateChange(e, field) }} value={value}></input>

      </div>
    )
  }

  newDateType = () => {
    return (
      <div>
        <DateRangePicker
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={(e) => this.handleNewDateChange(e)} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          isOutsideRange={() => false}
        />
      </div>
    )
  }

  fetchLeads = (e) => {
    let { limit, page, allLeadData } = this.state;
    if (Object.keys(this.state.filterData).length > 0) {
      let Obj = { ...this.state.filterData }
      let filterObj = this.removingNullKeys(Obj);
      if(!filterObj.leadType){
      filterObj.leadType= ["Distributor","Distributor ZP","Distributor ZV"]
      }
      filterObj.stage = ["Sold"]
      this.props.fetchAllLeads(limit, page + 1, allLeadData, filterObj, 'zunsolar',true);
    } else {
      let filterObj = {}
      filterObj.leadType= ["Distributor","Distributor ZV","Distributor ZP"]
      filterObj.stage = ["Sold"]

      this.props.fetchAllLeads(limit, page + 1, allLeadData, filterObj, 'zunsolar',true)
    }
    this.setState({
      page: page + 1
    })
  }

  selectFetchLeads = (lim, e) => {
    this.setState({
      page: 1,
      limit: lim,
      allLeadData: []
    },
      () => {
        let { limit, page, allLeadData } = this.state;
        let  filterObj = {}
        filterObj.leadType = ["Distributor","Distributor ZP","Distributor ZV"]
        filterObj.stage = ["Sold"]
        this.props.fetchAllLeads(limit, page, allLeadData, filterObj, 'zunsolar',true)
      })
  }


  handleFilterToggle = (e) => {
    let val = !this.state.showFilter;
    this.setState({
      showFilter: val
    })
  }

  handleShowFilter = (e) => {
    let obj = { ...this.state.filterMap }
    obj[e.target.value] = e.target.checked

    this.setState({
      filterMap: {
        ...obj,
      }
    }, () => {
      localStorage.setItem("filterMapZunsolar", JSON.stringify(obj))
    })
  }

  handleShowTable = (e) => {
    let obj = { ...this.state.tableMap }
    obj[e.target.value] = e.target.checked

    this.setState({
      tableMap: {
        ...obj
      }
    }, () => {
      localStorage.setItem("tableMapZunsolar", JSON.stringify(obj))
    })
  }

  handleCheckbox = (e, solarId, row) => {

    if (e.nativeEvent.shiftKey == true) {
      let selectedLeadIds = { ...this.state.selectedLeadIds }
      let sortedArray = this.reactTable.current.state.sortedData;
      let selectedCheckboxCount = Object.keys(this.state.selectedLeadIds || {}).filter((val) => this.state.selectedLeadIds[val] == 1);
      let indexees = [];
      let lastSelectedIndex = -1;
      sortedArray.map((lead, index) => {
        if (selectedLeadIds[lead.solarId]) {
          // console.log("123", index)
          lastSelectedIndex = index;
        }
      })
      if (lastSelectedIndex == -1) {
        selectedLeadIds[solarId] = 1
      } else {
        let currentIndex = sortedArray.findIndex(row => row.solarId == solarId);
        let [start, end] = currentIndex > lastSelectedIndex ? [lastSelectedIndex, currentIndex] : [currentIndex, lastSelectedIndex];
        for (let i = start; i <= end; i++) {
          // console.log("123456", selectedLeadIds[sortedArray])
          selectedLeadIds[sortedArray[i].solarId] = 1;
        }
      }
      this.setState({
        selectedLeadIds: selectedLeadIds
      })
    } else {
      let status = this.state.selectedLeadIds[solarId];
      let selectedBitrixId = JSON.parse(JSON.stringify(this.state.selectedLeadIds))
      let selectedLeadIds = { ...selectedBitrixId, [solarId]: status ? 0 : 1, };
      this.setState({
        selectedLeadIds: selectedLeadIds,
        selectAll: this.state.selectAll && status ? false : this.state.selectAll
        // selectedLeadIds: [...selectedLeadIds]
      }, () => {
        // console.log(this.state.selectedLeadIds)
      })
    }
  }

  handleAllCheckbox = (e, row) => {

    let page = this.reactTable.current.state.page;
    let pageSize = this.reactTable.current.state.pageSize;
    let start = page * pageSize;
    let end = (page * pageSize + pageSize);
    let actualEnd = (row.data.length > end) ? end : row.data.length
    let data = row.data;
    let selectedLeadIds = {};
    if (!this.state.selectAll) {
      for (let i = start; i <= (actualEnd - 1); i++) {
        selectedLeadIds[data[i].solarId] = 1
      }
      // console.log(selectedLeadIds);
    }

    this.setState({
      selectedLeadIds: { ...selectedLeadIds },
      selectAll: !(this.state.selectAll)
    })

    // console.log("row.original", row);
    // let selectedLeadIds = {};
    // // let obj = {}
    // if (!this.state.selectAll) {
    //   row.data.map((val) => {
    //     selectedLeadIds[val.solarId] = 1;
    //   })
    //   console.log(selectedLeadIds);
    // }
    // this.setState({
    //   selectedLeadIds: { ...selectedLeadIds },
    //   selectAll: !(this.state.selectAll)
    // }, () => { console.log(this.state.selectAll) })
  }

  handleBulkAssign = (e) => {
    let tableData = [...this.state.tableData];
    let selectedLeadIds = { ...this.state.selectedLeadIds };
    let bulkAssign = { ...this.state.bulkAssign }
    let count = this.state.count;
    // let arr = []
    // Object.keys(selectedLeadIds).map((val) => {
    //   if (selectedLeadIds[val] == 1) {
    //     arr.push(val)
    //   }
    // })
    // let obj = { ...this.state.bulkAssign };
    // let lead = {
    //   solarId: arr,
    //   ...obj
    // }

    this.props.editBulkLeads(selectedLeadIds, tableData, bulkAssign, count, "zunsolar");
    // setTimeout(() => {
    //   this.props.fetchAllLeads(100, 1, []);
    // }, 10000);

    // Object.keys(selectedLeadIds).map((bitrixId) => {

    //   let index = this.state.tableData.findIndex((val) => val.solarId == bitrixId);
    //   Object.keys(obj).map((field) => {
    //     // console.log("tableData", tableData, "tableDatataindeddd", tableData[index])
    //     tableData[index][field] = obj[field];
    //   })
    // })

    this.setState({
      // tableData: [...tableData]
    })
  }

  removingNullKeys = (obj) => {

    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }

    return obj
  }


  onApplyFilter = (e) => {
    let filterData = { ...this.state.filterData }
    filterData = JSON.parse(JSON.stringify(filterData));
    let filterObj = this.removingNullKeys(filterData);
    if(filterObj  && !filterObj.leadType){
    filterObj.leadType= ["Distributor","Distributor ZP","Distributor ZV"];
    }
    filterObj.stage = ["Sold"]
    
    console.log('filterObj', filterObj);
    if (Object.keys(filterObj).length > 0) {
      let { limit, page } = this.state;
      this.props.fetchAllLeads(limit, page, [], filterObj, 'zunsolar',true);
      this.setState({
        // filterObj: filterObj
      })
    } else {
      swal("Please apply atleast one filter")
    }

  }

  downloadInventoryData = () => {
    window.open(`${url.pathBackend}zunsolar/lead/inventory/download`);
  }

  downloadLeadData = () => {
    let { tableData = [] } = this.state;
    let downloadData = tableData.map(row => {
      row.nextActionDate = row.nextActionDate?moment(row.nextActionDate).format("YYYY-MM-DD"):"";
      row.ADDED_ON = row.ADDED_ON?moment(row.ADDED_ON).format("YYYY-MM-DD"):"";
      return row;
    })
    this.setState({
      downloadData
    });
  }

  render() {

    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }

    const columns = [
      {
        Header: <i data-toggle="modal" data-target="#selectTableOptionsModal" class="fa fa-cog fa-lg"></i>,
        width: 40,
        filterable: false,
        columns: false
      },
      {
        Header: row => {
          return (
            <input type="checkbox" onChange={(e) => { this.handleAllCheckbox(e, row) }} checked={this.state.selectAll ? 'checked' : ''} ></input>
          )
        },
        filterable: true,
        width: 50,
        sortable: false,
        columns: false,
        Filter: () => {
          let selectedCheckboxCount = Object.keys(this.state.selectedLeadIds).filter((val) => this.state.selectedLeadIds[val] == 1);
          return (
            <div>{selectedCheckboxCount.length}</div>
          )
        },
        Cell: row => (
          // console.log(row.original),

          <input type="checkbox" onChange={(e) => { this.handleCheckbox(e, row.original.solarId, row) }} checked={this.state.selectedLeadIds[row.original.solarId] ? 'checked' : ''} ></input>


        )
      },
      {
        Header: 'ID',
        accessor: 'solarId',

        show: this.state.tableMap.solarId ? true : false,
        Cell: row => (
          <div>
            <Link to={"/lead/details/distributor" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/zunsolar/details/distributor/" + row.value); }}>{row.value}</Link>
          </div>
        )
      },
      {
        Header: 'Name',
        accessor: 'name',
        show: this.state.tableMap.name ? true : false,


      },
      {
        Header: 'Type',
        accessor: 'leadType',
        show: this.state.tableMap.leadType ? true : false,
      },
      {
        Header: 'State',
        accessor: 'state',
        show: this.state.tableMap.state ? true : false,
      },
      {
        Header: 'City',
        accessor: 'city',
        show: this.state.tableMap.city ? true : false,
      },
      {
        Header: 'Responsible',
        accessor: 'responsible_person',
        show: this.state.tableMap.responsiblePerson ? true : false,
      },
      {
        Header: 'Company Name',
        accessor: 'companyName',
        show: this.state.tableMap.companyName ? true : false,
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        show: this.state.tableMap.stage ? true : false,

      },
      {
        Header: 'Next Action Date',
        accessor: 'nextActionDate',
        show: this.state.tableMap.nextActionDate ? true : false,
        Cell: row => {
          return (
            row.value ? moment(row.value).format("YYYY-MM-DD") : ''
          )
        }
      },
      {
        Header: 'Source',
        accessor: 'source',
        show: this.state.tableMap.source ? true : false,
      },
      {
        Header: 'Added On',
        accessor: 'ADDED_ON',
        show: this.state.tableMap.ADDED_ON ? true : false,
        Cell: row => {
          return (
            row.value ? moment(row.value).format("YYYY-MM-DD") : ''
          )
        }
      },
      {
        Header: 'Active',
        accessor: 'active',
        show: this.state.tableMap.active ? true : false
      },
      {
        Header: 'High Value',
        accessor: 'highValue',
        show: this.state.tableMap.highValue ? true : false
      },
      {
        Header: 'Has GST',
        accessor: 'has_gst',
        show: this.state.tableMap.has_gst ? true : false
      },
      {
        Header: 'GST Number',
        accessor: 'gst_number',
        show: this.state.tableMap.gst_number ? true : false
      },
      {
        Header: 'Dealership',
        accessor: 'existing_dealership',
        show: this.state.tableMap.existing_dealership ? true : false
      },
      {
        Header: 'Turnover',
        accessor: 'turnover',
        show: this.state.tableMap.turnover ? true : false
      },
      {
        Header: 'Modified On',
        accessor:'DATE_MODIFY',
        show:true
      },
      {
        Header: 'Modified By',
        accessor:'updatedby',
        show:true
      },
      {
        Header: 'Drop Off Reason',
        accessor: 'drop_off_reason',
        show: this.state.tableMap.drop_off_reason ? true : false
      },
      {
        Header: 'UTM Source',
        accessor: 'UTM_SOURCE',
        show: this.state.tableMap.UTM_SOURCE ? true : false
      },
      {
        Header: 'Medium',
        accessor: 'UTM_MEDIUM',
        show: this.state.tableMap.UTM_MEDIUM ? true : false
      },
      {
        Header: 'Campaign',
        accessor: 'UTM_CAMPAIGN',

        show: this.state.tableMap.UTM_CAMPAIGN ? true : false
      },
      {
        Header: 'Placement',
        accessor: 'placement',
        show: this.state.tableMap.placement ? true : false
      },
      {
        Header: 'Reason for hold',
        accessor: 'reasonForHold',
        width: 200,
        show: this.state.tableMap.reasonForHold ? true : false
      },
      {
        Header: 'Current Business',
        accessor: 'currentBusiness',
        width: 200,
        show: this.state.tableMap.currentBusiness ? true : false
      },
      {
        Header: 'Marketing Material Status',
        accessor: 'marketingMaterialStatus',
        width: 200,
        show: this.state.tableMap.marketingMaterialStatus ? true : false
      },
      {
        Header: 'Meeting Date',
        accessor: 'meetingDate',
        show: this.state.tableMap.meetingDate ? true : false
      },
      {
        Header: 'Meeting Scheduled By',
        accessor: 'meetingScheduledBy',
        show: this.state.tableMap.meetingScheduledBy ? true : false,
      },
      {
        Header: 'Meeting Done By',
        accessor: 'meetingDoneBy',
        show: this.state.tableMap.meetingDoneBy ? true : false,
      },
      {
        Header: 'Latitude',
        accessor: 'latitude',
        show: this.state.tableMap.latitude ? true : false,
      },
      {
        Header: 'Longitude',
        accessor: 'longitude',
        show: this.state.tableMap.longitude ? true : false,
      },
      {
        Header: 'Est. Type',
        accessor: 'establishment_type',
        show: this.state.tableMap.establishment_type ? true : false
      },
    ]
    const filterOptions = [
      { label: "Type", value: "leadType" },
      { label: "City", value: "CITY" },
      { label: 'Stage', value: "STAGE" },
      { label: "Active", value: "ACTIVE" },
      { label: "Added On", value: "ADDED_ON" },
      { label: "NEXT ACTION DATE", value: "nextActionDate" },
      { label: "Responsible Person", value: "responsible_person" },
      { label: "Onboarded By", value: "onboardedBy" },
      { label: "Partner Onboarding Date", value: "partner_onboarding_date" },
      { label: "High Value", value: "highValue" },
      { label: "Dealership", value: "existing_dealership" },
      { label: "Has Gst", value: "has_gst" },
      { label: "Turnover", value: "turnover" },
      { label: "Modified By", value: "updatedBy" },
      { label: "Modified On", value: "modifiedOn" },
      { label: "Drop Off Reason", value: "drop_off_reason" },
      { label: "State", value: "states" },
      { label: "Source", value: "source" },
      { label: "Reason For Hold", value: "reasonForHold" },
      { label: "Current Business", value: "currentBusiness" },
      { label: "Marketing Material Status", value: "marketingMaterialStatus" },
      { label: "Lead Sub Type", value: "leadSubType" },
      { label: "Meeting Date", value: "meetingDate" },
      { label: "Meeting Scheduled By", value: "meetingScheduledBy" },
      { label: "Meeting Done By", value: "meetingDoneBy" }
    ]
    const tableOptions = columns.map(val => {
      if (val.accessor) {
        return { label: val.Header, value: val.accessor };
      }
    }).filter(val => val !== undefined);

    const dealershipFilter = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]

    if (this.state.recieved) {
      return (
        <div className="container-fluid" style={{ marginTop: "60px" }}>

          <div className="topbar-section allLeadViewFilters" style={this.props.searchData.length > 0 ? style.hide : style.show}>
            <div className="pull-right">
              <i data-toggle="modal" data-target="#selectFilterModal" onClick={this.handleFilterModal} class="fa fa-cog" style={{ float: "right", fontSize: "25px", cursor: "pointer" }} aria-hidden="true"></i>
              {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                                Launch demo modal
                            </button> */}
              {/* modal body */}
              <div class="modal fade" id="selectFilterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Select Filters</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div className="row">
                        {filterOptions.map(option => {
                          return <div className="col-md-6"><input name="filterMap" type="checkbox" value={option.value} checked={this.state.filterMap[option.value] ? true : false} onChange={this.handleShowFilter} /> <span>{option.label}</span></div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
            <hr style={this.state.showFilter ? style.show : style.hide}></hr>
            <div className="row" style={this.state.showFilter ? style.show : style.hide}>

              <div className="col-md-2" style={this.state.filterMap.STAGE ? style.show : style.hide}>
                <label>Stage</label>
                {this.multiSelectType("stage", this.state.stages, "Stage", this.state.filterData.stage)}
                {/* {this.selectType("stages",this.state.mappingData.stages?this.state.mappingData.stages:[],"Stage","STATUS_NAME",this.state.filterData.stages,)} */}
              </div>
              <div className="col-md-2" style={this.state.filterMap.ACTIVE ? style.show : style.hide}>
                <label>Active</label>
                {this.multiSelectType("active", this.state.active, "Active")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.leadType ? style.show : style.hide}>
                <label>Type</label>
                {this.multiSelectType("leadType", this.state.leadType, "Type")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.leadSubType ? style.show : style.hide}>
                <label>Sub Type</label>
                {this.multiSelectType("leadSubType", this.state.subTypesArr, "Sub Type")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.states ? style.show : style.hide}>
                <label>State</label>
                {this.multiSelectType("state", this.state.statesArr, "State")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.CITY ? style.show : style.hide}>
                <label>City</label>
                {this.multiSelectType("city", this.state.cityArr, "City")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.ADDED_ON ? style.show : style.hide}>
                <label>Added On Start</label>
                {this.dateType("startDate", this.state.filterData.ADDED_ON ? this.state.filterData.ADDED_ON.startDate : "", "ADDED_ON")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.ADDED_ON ? style.show : style.hide}>
                <label>Added On End</label>
                {this.dateType("endDate", this.state.filterData.ADDED_ON ? this.state.filterData.ADDED_ON.endDate : "", "ADDED_ON")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.nextActionDate ? style.show : style.hide}>
                <label>Next Action Start</label>
                {this.dateType("startDate", this.state.filterData.nextActionDate ? this.state.filterData.nextActionDate.startDate : '', "nextActionDate")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.nextActionDate ? style.show : style.hide}>
                <label>Next Action End</label>
                {this.dateType("endDate", this.state.filterData.nextActionDate ? this.state.filterData.nextActionDate.endDate : '', "nextActionDate")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.responsible_person ? style.show : style.hide}>
                <label>Responsible</label>
                {this.multiSelectType("responsible_person", this.state.employees, "Responsible", this.state.filterData.responsible_person)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.onboardedBy ? style.show : style.hide}>
                <label>Onboarded By</label>
                {this.multiSelectType("onboardedBy", this.state.employees, "Onboarded By", this.state.filterData.onboardedBy)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.partner_onboarding_date ? style.show : style.hide}>
                <label>Partner Onboarding Date Start</label>
                {this.dateType("startDate", this.state.filterData.partner_onboarding_date ? this.state.filterData.partner_onboarding_date.startDate : '', "partner_onboarding_date")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.partner_onboarding_date ? style.show : style.hide}>
                <label>Partner Onboarding Date End</label>
                {this.dateType("endDate", this.state.filterData.partner_onboarding_date ? this.state.filterData.partner_onboarding_date.endDate : '', "partner_onboarding_date")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.has_gst ? style.show : style.hide}>
                <label>Has Gst</label>
                {this.multiSelectType("has_gst", dealershipFilter, "Has GST", this.state.filterData.has_gst)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.existing_dealership ? style.show : style.hide}>
                <label>Dealership</label>
                {this.multiSelectType("existing_dealership", dealershipFilter, "Dealership", this.state.filterData.existing_dealership)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.turnover ? style.show : style.hide}>
                <label>Turnover</label>
                {this.multiSelectType("turnover", this.state.turnover, "Turnover", this.state.filterData.turnover)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.highValue ? style.show : style.hide}>
                <label>High Value</label>
                {this.multiSelectType("highValue", dealershipFilter, "High Value", this.state.filterData.highValue)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.modifiedOn ? style.show : style.hide}>
                <label>Modified On Start</label>
                {this.dateType("startDate", this.state.filterData.modifiedOn ? this.state.filterData.modifiedOn.startDate : '', "modifiedOn")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.modifiedOn ? style.show : style.hide}>
                <label>Modified On End</label>
                {this.dateType("endDate", this.state.filterData.modifiedOn ? this.state.filterData.modifiedOn.endDate : '', "modifiedOn")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.updatedBy ? style.show : style.hide}>
                <label>Modified By</label>
                {this.multiSelectType("updatedBy", this.state.updatedByOptions, "Modified By", this.state.filterData.updatedBy)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.drop_off_reason ? style.show : style.hide}>
                <label>Drop Off Reason</label>
                {this.multiSelectType("drop_off_reason", this.state.drop_off_reason, "Drop Off Reason", this.state.filterData.drop_off_reason)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.source ? style.show : style.hide}>
                <label>Source</label>
                {this.multiSelectType("source", this.state.source, "Source")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.reasonForHold ? style.show : style.hide}>
                <label>Reason For Hold</label>
                {this.multiSelectType("reasonForHold", this.state.reasonForHold, "Select")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.currentBusiness ? style.show : style.hide}>
                <label>Current Business</label>
                {this.multiSelectType("currentBusiness", this.state.currentBusiness, "Select")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.marketingMaterialStatus ? style.show : style.hide}>
                <label>Marketing Material Status</label>
                {this.multiSelectType("marketingMaterialStatus", this.state.marketingMaterialStatus, "Select")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.meetingDate ? style.show : style.hide}>
                <label>Meeting Date Start</label>
                {this.dateType("startDate", this.state.filterData.meetingDate ? this.state.filterData.meetingDate.startDate : '', "meetingDate")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.meetingDate ? style.show : style.hide}>
                <label>Meeting Date End</label>
                {this.dateType("endDate", this.state.filterData.meetingDate ? this.state.filterData.meetingDate.endDate : '', "meetingDate")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.meetingScheduledBy ? style.show : style.hide}>
                <label>Meeting Scheduled By</label>
                {this.multiSelectType("meetingScheduledBy", this.state.employees, "Meeting Scheduled By", this.state.filterData.meetingScheduledBy)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.meetingDoneBy ? style.show : style.hide}>
                <label>Meeting Done By</label>
                {this.multiSelectType("meetingDoneBy", this.state.employees, "Meeting Done By", this.state.filterData.meetingDoneBy)}
              </div>
              <div style={Object.values(this.state.filterMap).includes(true) ? style.show : style.hide}>
                <label></label><br></br>
                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
              </div>
            </div>
          </div>
          <div>
            <div className="row" style={{ margin: '20px 0px' }}>
              <div className="col-md-8">
                {/* <span> Leads : {this.state.tableData.length} </span> */}
                <span style={this.props.searchData.length > 0 ? style.hide : style.show}><div style={{ display: 'inline-block', width: '150px' }}><Select placeholder="No. of Leads" options={this.state.limitoption} onChange={e => this.selectFetchLeads(e.value, e)} /></div></span>
                <span style={this.props.searchData.length > 0 ? style.hide : style.show}><button className="moreLeads" onClick={e => this.fetchLeads()}> More Leads <i class="fas fa-angle-double-right" aria-hidden="true"></i></button></span>
                <span><CSVLink filename={"Zunsolar.csv"} data={this.state.downloadData} onClick={this.downloadLeadData} headers={(columns||[]).filter(row => row.accessor && row.show).map(val => ({ label: val.csvHeader || val.Header, key: val.accessor }))} className="moreLeads" type="button" style={{textDecoration: "none"}}> Download <i class="fa fa-download" aria-hidden="true"></i></CSVLink></span>
                <span><button onClick={this.downloadInventoryData} className="btn btn-default" type="button" style={{textDecoration: "none"}}> Download Inventory</button></span>
                <span style={{ paddingLeft: "30px" }}><b>Total Leads</b> : <b>{this.state.count}</b></span>
              </div>
              <div className="col-md-4" style={{ textAlign: "right" }}>
                <Link to="/zunsolar/createlead" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> New Lead</button></Link>
              </div>
            </div>

            <div className="row" style={{ marginTop: "25px" }} style={Object.values(this.state.selectedLeadIds).includes(1) ? style.show : style.hide}>
              <div class="col-md-2">
                <label>Responsible</label>
                {this.selectNewType("responsible_person", this.props.allMapping ? this.props.allMapping.employees : '', "e_name", this.state.bulkAssign.responsible_person)}
              </div>
              <div class="col-md-2">
                <label>Stage</label>
                {this.selectType("stage", this.state.bulkAssign ? this.state.bulkAssign.stage : [], this.state.mappingData ? this.state.mappingData.stage : "")}
              </div>

              <div className="col-md-1" style={{ marginTop: "32px" }}>
                <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkAssign(e) }}>Submit</button>
              </div>
            </div>


            {/* Modal for Selecting Table Headers */}

            <div style={{ marginTop: '16.229vw' }} class="modal fade" id="selectTableOptionsModal" tabindex="-1" role="dialog" aria-labelledby="selectTableOptionsLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="selectTableOptionsLabel">Select Table Headers</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      {tableOptions.map(option => {
                        return <div className="col-md-6"><input name="tableMap" type="checkbox" value={option.value} checked={this.state.tableMap[option.value] ? true : false} onChange={(e) => this.handleShowTable(e)} /> <span>{option.label}</span></div>
                      })}
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* Modal Ends */}

            <div style={{ marginTop: "10px" }}>
              <ReactTable
                // loading = {true}
                filterable={true}
                showPagination={true}
                data={this.state.tableData}
                defaultPageSize={100}
                columns={columns}
                ref={this.reactTable}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
              //    defaultPageSize = {2}
              //    pageSizeOptions = {[2,4,6]}
              />

            </div>
          </div>
        </div>
      )

    }

    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }


  }

}

export default AllOfflineDistributorLeadView;

