import React from 'react';
import axios from 'axios';
import url from '../config';
import { FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_SUCCESS, CREATE_NEW_PASSWORD_ERROR, CREATE_NEW_PASSWORD_SUCCESS } from './actiontypes'

export function forgotPassword(obj) {
  return (dispatch) => {
    axios.post(url.pathBackend + "user/password/reset/request", obj).then((response) => {
      // console.log("response",response.data);
      if (response.data.error) {
        dispatch({ type: FORGOT_PASSWORD_ERROR, payload: response.data.error })
      } else {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: response.data.data ? response.data.data : '' })
      }
    })
  }
}

export function resetPassword(obj) {
  return (dispatch) => {
    axios.post(url.pathBackend + "user/password/reset/generate/", obj).then((response) => {
      // console.log(response.data.error);
      if (response.data.error) {
        dispatch({ type: CREATE_NEW_PASSWORD_ERROR, payload: response.data.error })
      } else {
        dispatch({ type: CREATE_NEW_PASSWORD_SUCCESS, payload: response.data.data ? response.data.data : '' })
      }
    })
  }
}