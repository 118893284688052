import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { bindActionCreators } from "redux";
import { fetchReturnOrder,updateRetunOrderData, bulkUpdateReturnDataV2 } from '../../actions/zunsolarOrderTrackerActions'
import HeaderComponent from "../../components/HeaderComponent";
import { fetchReturnOrderMapping } from "../../actions/fetchAllMapping";
import { getUnicommerceMapping } from '../../actions/zunsolarPiInvoiceAction';
import '../../styles/returnOrder.css'
import Select from "react-select";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Redirect } from 'react-router-dom';
import Modal from "react-responsive-modal";
import axios from 'axios';
import url from "../../config";


class AllReturnOrdersView extends Component {
    constructor(prop) {
        super();
        this.reactTable = React.createRef();
        this.state = {
            returnOrders: [],
            filters: {
                platform: [],
                qcStatus: [],
                putaway: [],
                returnDate: '',
                damageClaimId: ''
            },
            showFilter: false,
            returnMapping: {},
            page: 0,
            limit: 500,
            returnOrdersReceived: false,
            loading: true,
            userObj: {},
            returnOrderEditFlag:{},
            editedReturnOrderData:{},
            uploadCsvModal: false,
            uploadFile:'',
            selectedOrders: {},
            selectAllEnabled: false,
            bulkUpdateValues: {}
        }
    }
    componentDidMount(){
        this.props.fetchReturnOrderMapping();
        this.props.getUnicommerceMapping();
        // if(localStorage.getItem("userObj")){
        //   let userObj = JSON.parse(localStorage.getItem("userObj"));
        //   this.setState({
        //     userObj
        //   })
        // }
    
      }
    


    componentWillMount() {
        this.props.fetchReturnOrder();
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
    }

    componentWillReceiveProps(props) {
        let { returnOrders, returnOrdersReceived = false, returnMapping, unicommerceMapping = [] } = props
        console.log('returnMapping',returnMapping);
        let productsMapping = unicommerceMapping.filter(row => row.isActive && row.unicommerceId).map(row => row.unicommerceId);
        let updatedReturnOrders = returnOrders || [];
        if(returnOrders && returnMapping) {
            let courierCodeNameMap = {};
            if(returnMapping.courierPartner) {
                returnMapping.courierPartner.forEach(row => courierCodeNameMap[row.value] = row.label);
            }
            updatedReturnOrders = (returnOrders || []).map(row => {
                if(returnMapping && returnMapping.damageClassification && row.defect) {
                    row.damageClassification = returnMapping.damageClassification[row.defect] || "";
                }
                row.courierPartner = row.courierPartnerCode && courierCodeNameMap[row.courierPartnerCode] ? courierCodeNameMap[row.courierPartnerCode] : (row.courierPartnerCode || "");
                return row;
            })
        }
        this.setState({
            returnOrders: updatedReturnOrders,
            loading: false,
            returnOrdersReceived,
            returnMapping,
            productsMapping
        })
    }
    handleFilterToggle = () => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val,

        })
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[name] = value;
        this.setState({
            filters
        })
    }
    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);

        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })

    }

    fetchMoreOrders = () => {
        let { page, limit, returnOrders, filters } = this.state;
        this.props.fetchReturnOrder(page + 1,limit,returnOrders,filters);
        this.setState({
            page: page + 1,
            returnOrdersReceived: false
        })
    }
    fetchAllOrders = () => {
        let { page, limit, returnOrders, filters } = this.state;
        this.props.fetchReturnOrder(0,"all",returnOrders,filters);
        this.setState({
            returnOrdersReceived: false
        })
    }


    onApplyFilter = () => {
        let { limit, filters } = this.state;
        this.props.fetchReturnOrder(0, limit, [], filters);
        // this.props.fetchOrders(0,limit,[],filters, searchText);
        this.setState({
            page: 0,
            returnOrdersReceived: false
        })
    }

    handleEditReturnOrderDataClick = (row)=>{
        let{uniqueRowId} = row;
        this.setState({
            returnOrderEditFlag:{
                ...this.state.returnOrderEditFlag,
                [uniqueRowId]:true
            },
            editedReturnOrderData:{
                ...this.state.editedReturnOrderData,
                [uniqueRowId]:row
            }
        })
    }

    handleCancelReturnOrderDataClick = (row)=>{
        let {uniqueRowId} = row.original;
        this.setState({
            returnOrderEditFlag:{
                ...this.state.returnOrderEditFlag,
                [uniqueRowId]: false
            },
            editedReturnOrderData:{
                ...this.state.editedReturnOrderData,
                [uniqueRowId]:false
            }
        })


    }

    handleSaveReturnOrderDataClick = (row) => {
        let {uniqueRowId} = row.original;
        let KeysCanBeChanged = ["qcChecked", "qcStatus",'qcDate', 'sent', "damageClaimAmount","damageClaimDate","damageClaimId","itemReceived","putaway","qcFeedback", "platform","damageClaimResult","refurbishmentStatus", "itemPart", "batchCode", "defect", "putawayId"]
        let {editedReturnOrderData,returnOrders, userObj} = this.state
        let newData = editedReturnOrderData[uniqueRowId];
        if(newData.qcChecked == 'Yes' && !newData.qcStatus) {
            alert("Please fill QC Status");
            return;
        }
        if(newData.qcStatus == 'QC Fail' && !newData.itemPart) {
            alert("Please fill item part");
            return;
        }
        if(['QC Fail', 'QC Pass'].includes(newData.qcStatus) && !newData.defect) {
            alert("Please fill defect");
            return;
        }
        if(newData.putaway == 'done' && !newData.putawayId) {
            alert("Please fill putaway id");
            return;
        }
        let newDataToSend = {};
        let updatedBy = userObj.email;
        KeysCanBeChanged.map((key)=>{
            if(newData[key]!= row.original[key]){
                newDataToSend[key] = newData[key];
            }
        })
        
        newDataToSend.updatedBy = updatedBy;
        if(!Object.keys(newDataToSend).length){
            return;
        }
        this.props.updateRetunOrderData(uniqueRowId,newDataToSend, returnOrders)
        this.setState({
            returnOrderEditFlag:{
                ...this.state.returnOrderEditFlag,
                [uniqueRowId]: false
            },
            editedReturnOrderData:{
                ...this.state.editedReturnOrderData,
                [uniqueRowId]:false
            }
        })

    }
    
    multiSelectType = (name, options, placeholder) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { this.handleSelectChangeFilters(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }
    inputType = (name, placeholder, type) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                   {type=="date" &&  <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>}
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => this.handleInputDateChangeFilters(e)} />
                </div>
            </div>
        )
    }

    handleReturnOrderChanges = (e,row)=>{
        let {name, value} = e.target;
        let {uniqueRowId} = row.original
        let {editedReturnOrderData} = this.state;
        editedReturnOrderData = JSON.parse(JSON.stringify(editedReturnOrderData));
        editedReturnOrderData[uniqueRowId][name] = value;
        this.setState({
            editedReturnOrderData
        })


    }
    InputTypeText = (name, value, type, row ) => {
        return (
            <input type={type} name ={name} value = {value} className="form-control"  onChange={(e) => this.handleReturnOrderChanges(e, row)} ></input>
        )
    }
    renderInputCell = (row) => {
        let { id, type } = row.column;
        let {returnOrderEditFlag, editedReturnOrderData} = this.state
        let checked = ["damaged/missing product","Wrong Product","damaged","QC Fail"]
        return (
                returnOrderEditFlag[row.original.uniqueRowId]  && checked.includes(row.original.qcStatus) || editedReturnOrderData[row.original.uniqueRowId] &&  checked.includes(editedReturnOrderData[row.original.uniqueRowId].qcStatus)? this.InputTypeText( id,  this.state.editedReturnOrderData[id] , type, row): row.value
        )
    }
    openUploadCsvModal = () => {
        this.setState({
        uploadCsvModal: true,

        })
    }

    closeUploadCsvModal = () => {
        this.setState({
          uploadCsvModal: false,
          successMessage: undefined,
          errorMessage: undefined
        })
      }
      handleUploadFile = (e) => {
        let files = e.target.files
        this.setState({
            uploadFile: files[0]
        })
    }
    handleSubmitFileUpload = async()=>{
        try {
            const file = this.state.uploadFile;
            if(!file){
                return alert("Please upload file")
                
            }
            const apiFormData = new FormData();
            apiFormData.append('file', file)
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/bulkUpdateReturnData`,apiFormData);
            response = response.data;
            if(response && response.status=="success"){
                return alert("updated successfully")
            }
            return alert("somwthing went wrong")
        } catch (error) {
            return alert("somwthing went wrong")
            
        }
    

        
    }
    renderInputCellQC = (row) => {
        let { id, type } = row.column;
        // console.log('idColumn', row.original);
        let uniqueRowId = row.original.uniqueRowId;
        let {returnOrderEditFlag, editedReturnOrderData, returnOrders} = this.state
        let checked =  editedReturnOrderData[row.original.uniqueRowId]?.qcChecked == "Yes";
        // if( returnOrderEditFlag[row.original.uniqueRowId]  && checked){
        //     editedReturnOrderData = JSON.parse(JSON.stringify(editedReturnOrderData));
        //     console.log('id', id);
        //     editedReturnOrderData[row.original.uniqueRowId][id] = moment().format("YYYY-MM-DD");
        //     console.log('editedReturnOrderData',editedReturnOrderData);
        //     this.setState({
        //         editedReturnOrderData: editedReturnOrderData
        //     })
        // }
        return (
                returnOrderEditFlag[row.original.uniqueRowId]  && checked? this.InputTypeText( id,  this.state.editedReturnOrderData[row.original.uniqueRowId][id] , type, row): row.value
        )
    }

    renderInputCellBatchCode = (row) => {
        let { id, type } = row.column;
        let {returnOrderEditFlag, editedReturnOrderData} = this.state;
        if(!returnOrderEditFlag[row.original.uniqueRowId]) return row.value;
        if(id == 'putawayId' && this.state.editedReturnOrderData[row.original.uniqueRowId].qcChecked != "Yes") {
            return row.value || "";
        }
        return (
                returnOrderEditFlag[row.original.uniqueRowId] ? this.InputTypeText(id,  this.state.editedReturnOrderData[row.original.uniqueRowId][id] , type, row): row.value
        )
    }

    returnOrderSelect = (name, options,  placeholder, row, value='' ) => {
        // console.log('options', options);
        return (
            <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleReturnOrderChanges(e,row) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>{placeholder}</option>
          {(options ? options : []).map(e => <option key={e.value}>{e.label}</option>)}
        </select>
      </div>
        )
    }

    handleCheckBoxChange = (e, id) => {
        let { checked } = e.target;
        console.log(checked);
        if(!checked) {
            if(id == -1) {
                this.setState({
                    selectedOrders: {},
                    selectAllEnabled: false
                })
            } else {
                let selectedOrders = this.state.selectedOrders;
                delete selectedOrders[id];
                this.setState({
                    selectedOrders,
                    selectAllEnabled: false
                })
            }
        }
        else {
            let sortedData = this.reactTable.current.state.sortedData || [];
            if(id == -1) {
                let selectedIds = sortedData.map(row => row._original.id) || [];
                let selectedOrders = {};
                selectedIds.forEach(selectedId => {
                    selectedOrders[selectedId] = true;
                });
                this.setState({
                    selectedOrders,
                    selectAllEnabled: true
                })
            } else {
                let selectedOrders = {
                    ...this.state.selectedOrders,
                    [id]: true
                };
                let selectAllEnabled = Object.keys(selectedOrders).length == sortedData.length;
                this.setState({
                    selectedOrders: selectedOrders,
                    selectAllEnabled
                })
            }
        }
    }

    selectInput = (name, options, value) => {
        return (
            <div style={{ width: '100%' }} >
                <select style={{ height: '35px' }}
                    onChange={(e) => { this.handleSelectChangeBulkUpdate(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option value="">not selected</option>
                    {(options ? options : []).map((e) => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    BulkUpateInput = (name, value, type) => {
        return (
            <input type={type} name ={name} value = {value} className="form-control"  onChange={(e) => this.handleSelectChangeBulkUpdate(e)} ></input>
        )
    }

    handleSelectChangeBulkUpdate = (e) => {
        let { name, value } = e.target;
        console.log(name, value);
        this.setState({
            bulkUpdateValues: {
                ...this.state.bulkUpdateValues,
                [name]: value
            }
        })
    }

    handleBulkUpdateSubmit = () => {
        let { selectedOrders, bulkUpdateValues } = this.state;
        console.log(selectedOrders, bulkUpdateValues);
        console.log(Object.keys(bulkUpdateValues).length);
        if(Object.keys(bulkUpdateValues).length == 0 || !Object.keys(selectedOrders).length) return;
        if(bulkUpdateValues.putaway == 'done' && !bulkUpdateValues.putawayId) {
            alert("Please fill putaway id");
            return;
        }
        this.props.bulkUpdateReturnDataV2(Object.keys(selectedOrders), bulkUpdateValues);
        this.setState({
            selectedOrders: {},
            selectAllEnabled: false
        })
    }

    render() {
        let { showFilter, returnOrders, returnOrdersReceived, returnMapping, returnOrderEditFlag , editedReturnOrderData, userObj, uploadCsvModal, productsMapping, bulkUpdateValues = {} } = this.state
        let { handleFilterToggle, inputType, onApplyFilter, fetchMoreOrders, handleSaveReturnOrderDataClick, handleCancelReturnOrderDataClick, handleEditReturnOrderDataClick, returnOrderSelect,renderInputCell, renderInputCellQC, fetchAllOrders,openUploadCsvModal,closeUploadCsvModal,handleUploadFile,handleSubmitFileUpload, renderInputCellBatchCode, BulkUpateInput } = this;
        let selectedOrdersCount = Object.keys(this.state.selectedOrders).length;
        if(!((userObj.access || []).includes("Return-Admin"))) {
            return <Redirect to={"/"} />
        }
        const modalStyles = {
            modal: {
              width: '40%',
              maxWidth: '40%',
              padding: '40px',
              background: '#f7f7f7',
              textAlign: 'center'
            }
        };

         const returnOrderColumns = [
            {
                 Header: row => {
                     return (
                         <div>
                             <input type="checkbox" onChange={(e) => { this.handleCheckBoxChange(e, '-1') }} checked={this.state.selectAllEnabled ? 'checked' : ''} ></input>
                         </div>
                     )
                 },
                 Cell: row => (
                     <div>
                         <input type="checkbox" onChange={(e) => { this.handleCheckBoxChange(e, row.original.id) }} checked={this.state.selectedOrders[row.original.id] ? 'checked' : ''} ></input>
                     </div>
                 ),
                 filterable: true,
                 sortable: false,
                 columns: false,
                 Filter: () => {
                     return (
                         <div>{selectedOrdersCount}</div>
                     )
                 },
            },
            { 
                Header: 'Edit',
                Cell: row => {
                    return (
                        returnOrderEditFlag[row.original.uniqueRowId] ? <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-success btn-sm" style={{marginRight: '3px'}} onClick={(e) => handleSaveReturnOrderDataClick(row)}>Save</button><button className="btn btn-light btn-sm" onClick={(e) => handleCancelReturnOrderDataClick(row)}>Cancel</button></div> : <div className="row text-center" style={{width: 'fit-content'}}>{((this.state.userObj || {}).access || []).includes("Return-Admin")?<button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => handleEditReturnOrderDataClick (row.original)}>Edit</button>:''}</div>
                    )
                },
                minWidth: 150
        },
            {
                Header: "Incoming Date",
                accessor: "returnDate",
            },
            {
                Header: "Courier partner",
                accessor: "courierPartner",
            },
            {
                Header: "Return Id",
                accessor: "returnId",
            },
            {
                Header: "Order Id",
                accessor: "orderId",
            },
            {
                Header: "subOrder Id",
                accessor: "subOrderId",
            },
            {
                Header: "Return Mode",
                accessor: "returnMode",
            },
           
            {
                Header: "Platform",
                accessor: "platform",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] ? row.value : returnOrderSelect("platform",  returnMapping.platform, "select platform" , row, editedReturnOrderData[row.original.uniqueRowId]["platform"])
                    )
                }
            },
            {
                Header: "Batch Code",
                accessor: "batchCode",
                type: 'text',
                Cell: renderInputCellBatchCode
            },
            {
                Header: "Sku Code",
                accessor: "skuCode",
            },
           
            {
                Header: "SKU",
                accessor: "sku",
            },
            {
                Header: "Sub Category",
                accessor: "subcategory",
            },
            {
                Header: "Item Part",
                accessor: "itemPart",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] || editedReturnOrderData[row.original.uniqueRowId]?.qcStatus!="QC Fail" ? row.value : returnOrderSelect("itemPart",  returnMapping && returnMapping.itemParts ? (returnMapping.itemParts[editedReturnOrderData[row.original.uniqueRowId]["subcategory"] || 'other'] || returnMapping.itemParts['other']) : [], "select item part" , row, editedReturnOrderData[row.original.uniqueRowId]["itemPart"])
                    )
                }
            },
           
            {
            
                Header: "QC Checked",
                accessor: "qcChecked",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] || row.value== "Yes" ? row.value : returnOrderSelect("qcChecked",  returnMapping.qcChecked, "select QC checked" , row, editedReturnOrderData[row.original.uniqueRowId]["qcChecked"])
                    )
                }
            },
            {
                Header: "QC Date",
                accessor: "qcDate",
                type:"date",
            },
            {
                Header: "responsible",
                accessor: "responsible"
            },
            {
                Header: "QC Status",
                accessor: "qcStatus",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] || (editedReturnOrderData[row.original.uniqueRowId].qcChecked!="Yes"  && row.original.qcChecked!= "Yes")  ? row.value : returnOrderSelect("qcStatus",  returnMapping.qcStatus, "select QC Status" , row, editedReturnOrderData[row.original.uniqueRowId]["qcStatus"])
                    )
                }
            },
            {
                Header: "QC Feedback",
                accessor: "qcFeedback",
                // Cell: row => {
                //     return (
                //         !returnOrderEditFlag[row.original.uniqueRowId] || editedReturnOrderData[row.original.uniqueRowId]?.qcChecked!="Yes" && row.original.qcChecked!= "Yes" ? row.value : returnOrderSelect("qcFeedback",  returnMapping.qcFeedback, "select QC Feedback" , row, editedReturnOrderData[row.original.uniqueRowId]["qcFeedback"])
                //     )
                // }
            },
            {
                Header: "Defect",
                accessor: "defect",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] ? row.value : returnOrderSelect("defect", editedReturnOrderData[row.original.uniqueRowId].qcStatus == 'QC Pass' ? returnMapping.defectQCPass : (editedReturnOrderData[row.original.uniqueRowId].qcStatus == 'QC Fail' ? returnMapping.defectQCFail : []), "select defect" , row, editedReturnOrderData[row.original.uniqueRowId]["defect"])
                    )
                }
            },
            {
                Header: 'Damage Claim Id',
                accessor: 'damageClaimId',
                type:"text",
                Cell: renderInputCell
            },
            {
                Header: "Damage Claim Date",
                accessor: "damageClaimDate",
                type:"date",
                Cell: renderInputCell
                
            },
            {
                Header: "Damage Category Classification",
                accessor: "damageClassification",
                type:"text"
                
            },

            {
            
                Header: "Damage Claim Result",
                accessor: "damageClaimResult",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] || !["QC Fail", "Wrong Product"].includes(editedReturnOrderData[row.original.uniqueRowId]?.qcStatus) ? row.value : returnOrderSelect("damageClaimResult",  returnMapping.damageClaimResult, "select Damage Claim Result" , row, editedReturnOrderData[row.original.uniqueRowId]["damageClaimResult"])
                    )
                }
            },
            {
                Header: "Damage Claim Amount",
                accessor: "damageClaimAmount",
                type:"number",
                Cell: renderInputCell
            },
            {
                Header: "Putaway",
                accessor: "putaway",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] ||  editedReturnOrderData[row.original.uniqueRowId].qcChecked!="Yes" && row.original.qcChecked!= "Yes" ? row.value : returnOrderSelect("putaway",  returnMapping.putaway, "select putaway" , row, editedReturnOrderData[row.original.uniqueRowId]["putaway"])
                    )
                }
            },
            {
                Header: "Putaway Id",
                accessor: "putawayId",
                type: 'text',
                Cell: renderInputCellBatchCode
            },
            {
                Header: "sent (refurbish/warranty)",
                accessor: "sent",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] || editedReturnOrderData[row.original.uniqueRowId].qcChecked!="Yes" && row.original.qcChecked!= "Yes" ? row.value : returnOrderSelect("sent",  returnMapping.sent, "select sent" , row, editedReturnOrderData[row.original.uniqueRowId]["sent"])
                    )
                }
            },
            {
            
                Header: "Refurbishment Status",
                accessor: "refurbishmentStatus",
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] || editedReturnOrderData[row.original.uniqueRowId]?.qcStatus!="QC Fail" ? row.value : returnOrderSelect("refurbishmentStatus",  returnMapping.refurbishmentStatus, "select Refurbishment Status" , row, editedReturnOrderData[row.original.uniqueRowId]["refurbishmentStatus"])
                    )
                }
            },
            {
                Header: 'Item Received',
                accessor: 'itemReceived',
                Cell: row => {
                    return (
                        !returnOrderEditFlag[row.original.uniqueRowId] || !["damaged/missing product","fail","damaged","QC Fail"].includes(editedReturnOrderData[row.original.uniqueRowId]?.qcStatus) && !["damaged/missing product","fail","damaged","QC Fail"].includes(row.original.qcStatus) ? row.value : returnOrderSelect("itemReceived",  returnMapping.itemReceived, "select itemReceived" , row, editedReturnOrderData[row.original.uniqueRowId]["itemReceived"])
                    )
                }
            },
           
            {
                Header: "Created On",
                accessor: "createdOn",
            },
            
            
            {
                Header: "Product ID",
                accessor: "pid",
            },
            
           
           
        
            {
                Header: "SubOrder Id",
                accessor: "subOrderId",
            },
            
        {
            Header: "Quantity",
            accessor: "quantity",
        }, 
        {
            Header: "Remarks",
            accessor: "remarks",
        },
        {
            Header: "Unicommerce Id",
            accessor: "unicommerceId",
        },
        {
            Header: "Unicommerce Name",
            accessor: "unicommerceName",
        },
        {
            Header: "Created By",
            accessor: "createdBy",
        },
        
        ]

        return (
            <div>
                <HeaderComponent />
            <div className="container-fluid">
                <div style={{ "marginTop": 80 }} className="topbar-section allLeadViewFilters">
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                    <hr className={showFilter ? "showFilter" : "hideFilter"}></hr>
                    <div className={`${showFilter ? "showFilter" : "hideFilter"} ${"row"}`}>
                        <div className="col-md-3">
                            {inputType("startDate", "Incoming  Start Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("endDate", "Incoming End Date", "date")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("platform", returnMapping.platform, "Platform")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("qcStatus", returnMapping.qcStatus, "QC Status")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("putaway", returnMapping.putaway, "Putaway")}
                        </div>
                        <div className="col-md-3">
                            {inputType("damageClaimId", "Damage Claim Id", "text")}
                        </div>
                        <div className="col-md-3">
                            {inputType("qcStartDate", "QC Checked Start Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("qcEndDate", "QC Checked End Date", "date")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("unicommerceId", (productsMapping || []).map(product => ({ label: product, value: product })), "Unicommerce Id")}
                        </div>
                        <div className="col-md-3">
                            <button onClick={onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>


                </div>
                { selectedOrdersCount == 0 ? <div className="moreDataContainer">
                    <div>
                        <button className="btn btn-light" style={{ margin: '0 10px' }} onClick={() => fetchMoreOrders()}>More Orders</button>

                    </div>
                    <div>
                        <button className="btn btn-light" style={{ margin: '0 10px' }} onClick={() => fetchAllOrders()}>All Orders</button>

                    </div>
                    <div>
                    <CSVLink filename={"returnOrders Data.csv"} data={returnOrders || []}   className="btn btn-light">
                                Download CSV <i className="fa fa-download"></i>
                    </CSVLink>
                    </div>
                    <div className="btn btn-light"  style={{ margin: '0 10px' }}  onClick={openUploadCsvModal}>
                        Upload CSV

                </div>

                </div> : "" }
                {selectedOrdersCount > 0 ? <div className = "mb-4">
                    <div className="row">
                        <div className="col-md-2">
                            <label>Putaway</label>
                            {this.selectInput("putaway", (returnMapping.putaway || []).map(row => row.value), bulkUpdateValues.putaway)}
                        </div>
                        <div className="col-md-2">
                            <label>Putaway Id</label>
                            {this.BulkUpateInput("putawayId", bulkUpdateValues.putawayId, "text")}
                        </div>
                        <div className="col-md-2" style={{ marginTop: "32px" }}>
                            <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkUpdateSubmit() }}>Submit</button>
                        </div>
                    </div>
                </div> : ""}
                <Modal open={uploadCsvModal} onClose={closeUploadCsvModal} styles={modalStyles} center>
                            <div style={{ border: "1px solid #92b0b3", padding: "5px" }}>
                            <div className=" container-fluid uploadBox">
                            <input style={{ width: "50 %", height: '42px', fontWeight: "bold" }} type="file" onChange={(e) => { handleUploadFile(e) }} accept=".csv"></input>
                            <button style={{ height: '38px', fontWeight: "bold" }} className="btn btn-info btn-sm" onClick={handleSubmitFileUpload}>Upload <i className="fa fa-upload"></i></button>
                            <br></br>
                            <div style={{ margin: "20px" }} >
                                <span style={{ color: "red" }}>{this.state.errorMessage ? this.state.errorMessage : ""}</span>
                                <span style={{ color: "green" }}>{this.state.successMessage ? this.state.successMessage : ""}</span>
                                <span style={{ color: "green" }}>{this.state.uploadMessage ? this.state.uploadMessage : ""}</span>
                            </div>
                            </div>
                            </div>
                    </Modal>

                <ReactTable
                    ref={this.reactTable}
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={returnOrders}
                    columns={returnOrderColumns}
                    defaultSorted={[{ id: "createdOn", desc: true }]}
                    style={{ height: "90vh", textAlign: 'center' }}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={!returnOrdersReceived}
                />

            </div>
            </div>
        )



    }
}



function mapStateToProps(state) {
    let { returnOrders, returnOrdersReceived } = state.zunSolarOrderTracker || {};
    let returnMapping = state.fetchAllMapping && state.fetchAllMapping.returnMapping || {}
    let { unicommerceMapping = [] } = state.zunsolarBulkPIInvoice || {};
    return {
        returnOrders,
        returnOrdersReceived,
        returnMapping,
        unicommerceMapping
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchReturnOrder,fetchReturnOrderMapping,updateRetunOrderData, getUnicommerceMapping, bulkUpdateReturnDataV2 }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(AllReturnOrdersView);
