import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTable from 'react-table';
import swal from 'sweetalert';

class CreateProcurement extends Component {

    constructor() {
        super();
        this.state = {
            procurementData: {},
            paymentsDataByOrderId: [],
            procurementMapping: {}
        };
    }

    componentWillReceiveProps(props) {
        let { paymentsDataByOrderId, procurementMapping } = props
        this.setState({
            paymentsDataByOrderId,
            procurementMapping
        })
    }

    componentDidMount() {
        this.props.fetchVendorList()
        this.props.fetchProcurementPayments()
        this.props.fetchMappingZunsolar()
        this.props.fetchMappingProcurement()
    }

    AddProcurementInput = ({ label, name, type, inputParams = {} }) => {
        let { procurementData = {} } = this.state;
        let value = procurementData[name] || '';
        return (
            <div className="col-12 col-md-2">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{label}</label>
                        <input className="form-control customInput" type={type} name={name} value={value} placeholder={label} onChange={(e) => this.handleProcurementInputChange(e)} {...inputParams} />
                    </div>
                </div>
            </div>
        )
    }

    AddProcurementSelect = ({ isMulti, label, name, options = [] }) => {
        let { procurementData = {} } = this.state;
        let value = procurementData[name] || '';
        return (
            <div className="col-12 col-md-2">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{label}</label>
                        <Select
                            isSearchable={true}
                            placeholder={label}
                            name={name}
                            options={options}
                            onChange={(e) => this.handleProcurementSelectChange(e, name)}
                            isMulti={isMulti}
                            value={{ value: value, label: value }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    handleProcurementInputChange = (e) => {
        let { name, value } = e.target;
        let { procurementData } = this.state;
        procurementData[name] = value;
        this.setState({
            procurementData
        })
    }

    calculteValues = () => {
        let { procurementData } = this.state
        let { pricePerUnit, noOfUnits, gstRate, deliveryCharges = 0, discount=0, tcs=0 } = procurementData
        if (!pricePerUnit || !noOfUnits || !gstRate || !deliveryCharges || !discount || !tcs) {
            swal("Fill 'Price per unit', 'No. of units', 'GST rate', 'Delivery Charges', 'Discount' and 'TCS' before calculating")
            return;
        }
        console.log(procurementData)
        this.setState({
            procurementData: {
                ...procurementData,
                total: parseFloat((pricePerUnit * noOfUnits * (1 + (gstRate / 100))) + parseInt(deliveryCharges) - parseInt(discount) - parseInt(tcs)).toFixed(2),
                subTotal: parseFloat((pricePerUnit * noOfUnits) + parseInt(deliveryCharges)).toFixed(2)
            }
        })
    }

    handleProcurementSelectChange = (e, name) => {
        let { procurementMapping, procurementData, paymentsDataByOrderId } = this.state
        let value = Array.isArray(e) ? e.map(row => row.value) : e.value
        if (!Array.isArray(e)) {
            switch (name) {
                case 'orderId':
                    if (e.value == 'new') {
                        paymentsDataByOrderId = []
                    } else {
                        this.props.fetchProcurementPaymentsById(e.value)
                    }
                    break
                case 'category':
                    let unitOfMeasurement = (procurementMapping['zunsolarUnits'] || {})[e.value] || ''
                    let gstRate = (procurementMapping['zunsolarGstMappings'] || {})[e.value] || ''
                    procurementData = {
                        ...procurementData,
                        unitOfMeasurement,
                        gstRate
                    }
                    break
            }
        }
        this.setState({
            procurementData: {
                ...procurementData,
                [name]: value
            },
            paymentsDataByOrderId
        })
    }

    handleSubmitProcurementForm = (e) => {
        e.preventDefault();
        let { procurementData } = this.state;
        this.props.createProcurement(procurementData);
        console.log("Submit", procurementData);
    }

    AddProcurementFileInput = ({ label, name, inputParams = {}, value = '' }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{label}</label>
                        <input className="form-control customInput" type={"file"} name={name} placeholder={label} onChange={(e) => this.handleProcurementFileChange(e, name)} {...inputParams} />
                    </div>
                </div>
            </div>
        )
    }

    handleProcurementFileChange = (e, name) => {
        let { files } = e.target;
        this.setState(prevState => ({
            procurementData: {
                ...prevState.procurementData,
                [name]: files[0]
            }
        }))
    }

    handleCopyClick = (row) => {
        let { procurementData } = this.state
        this.setState({
            procurementData: {
                ...procurementData,
                category: row.category || '',
                subcategory: row.subcategory || '',
                unitOfMeasurement: row.unitOfMeasurement || '',
                pricePerUnit: row.pricePerUnit || '',
                noOfUnits: row.noOfUnits || '',
                gstRate: row.gstRate || '',
                discount: row.discount || '',
                deliveryCharges: row.deliveryCharges || '',
                tcs: row.tcs || '',
                total: row.total || '',
                subTotal: row.subTotal || '',
                piNumber: row.piNumber || ''
            }
        }, () => {
            this.calculteValues()
        })
    }

    render() {
        console.log(this.state)
        let { handleSubmitProcurementForm, AddProcurementSelect, AddProcurementInput, AddProcurementFileInput, props, state } = this;
        let procurementOrderIdOptions = (props.paymentsData.data || []).map((element) => { return { label: element.orderId, value: element.orderId } })
        // procurementOrderIdOptions.unshift({ label: 'New', value: '23' })

        let SingleOrderIdColumns = [
            {
                Header: "Buttons",
                Cell: row => {
                    return (
                        <button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => this.handleCopyClick(row.original)}>Copy</button>
                    )
                },
                minWidth: 150
            },
            {
                Header: 'Order Id',
                accessor: 'orderId'
            },
            {
                Header: 'Category',
                accessor: 'category'
            },
            {
                Header: 'Sub Category',
                accessor: 'subcategory'
            },
            {
                Header: 'Price Per Unit',
                accessor: 'pricePerUnit'
            },
            {
                Header: 'No Of Units',
                accessor: 'noOfUnits'
            },
            {
                Header: 'Items Received',
                accessor: 'itemsReceived'
            },
            {
                Header: 'GST Rate',
                accessor: 'gstRate'
            },
            {
                Header: 'Discount',
                accessor: 'discount'
            },
            {
                Header: 'Delivery Charges',
                accessor: 'deliveryCharges'
            },
            {
                Header: 'Payment Percentage',
                accessor: 'paymentPercentage'
            },
            {
                Header: 'TCS',
                accessor: 'tcs'
            },
            {
                Header: 'Total',
                accessor: 'total'
            },
            {
                Header: 'Status',
                accessor: 'status'
            }
        ]

        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Add Procurement</h4>
                <div className="addVendorForm">
                    <form onSubmit={handleSubmitProcurementForm}>
                        <div className="row">
                            <AddProcurementSelect
                                label="Vendor Id"
                                name="vendorId"
                                isMulti={false}
                                options={(props.vendorList || []).map((element) => { return { label: `${element.id} ${element.companyName}`, value: element.id } })}
                            />
                            <AddProcurementSelect
                                label="Order Id"
                                name="orderId"
                                isMulti={false}
                                options={procurementOrderIdOptions}
                            />
                            <AddProcurementSelect
                                label="Category"
                                name="category"
                                isMulti={false}
                                options={Object.keys(props.zunsolarCategoryMapping || {}).map((element) => { return { label: element, value: element } })}
                            />
                            <AddProcurementSelect
                                label="Sub Category"
                                name="subcategory"
                                isMulti={false}
                                options={(this.state.procurementData.category ? props.zunsolarCategoryMapping[this.state.procurementData.category] : []).map((element) => { return { label: element, value: element } })}
                            />
                            <AddProcurementInput
                                label="Unit of measurement"
                                name="unitOfMeasurement"
                                type="text"
                            />
                            <AddProcurementInput
                                label="Price per unit"
                                name="pricePerUnit"
                                type="number"
                                inputParams={{'min':0,step:'any'}}
                            />
                            <AddProcurementInput
                                label="No. Of Units"
                                name="noOfUnits"
                                type="number"
                                inputParams={{'min':0}}
                            />
                            <AddProcurementInput
                                label="GST rate"
                                name="gstRate"
                                type="number"
                                inputParams={{'min':0}}
                            />
                            <AddProcurementInput
                                label="Discount"
                                name="discount"
                                type="number"
                                inputParams={{'min':0}}
                            />
                            <AddProcurementInput
                                label="Delivery Charges"
                                name="deliveryCharges"
                                type="number"
                                inputParams={{'min':0}}
                            />
                            <AddProcurementInput
                                label="Payment Percentage"
                                name="paymentPercentage"
                                type="number"
                                inputParams={{'max': 100, 'min':0}}
                            />
                            <AddProcurementInput
                                label="TCS"
                                name="tcs"
                                type="number"
                                inputParams={{'min':0}}
                            />
                            <AddProcurementInput
                                label="Total"
                                name="total"
                                type="number"
                                inputParams={{'disabled':'true'}}
                            />
                            <AddProcurementInput
                                label="Sub Total"
                                name="subTotal"
                                type="number"
                                inputParams={{'disabled':'true'}}
                            />
                            <AddProcurementInput
                                label="Items Received"
                                name="itemsReceived"
                                type="number"
                                inputParams={{'min':0, 'max':state.procurementData['noOfUnits']}}
                            />
                            <AddProcurementInput
                                label="PI Number"
                                name="piNumber"
                                type="text"
                            />
                            <AddProcurementSelect
                                label="Bank"
                                name="bank"
                                isMulti={false}
                                options={(props.banksMapping || []).map((element) => { return { label: element, value: element } })}
                            />
                            {/* <AddProcurementSelect
                                label="Status"
                                name="status"
                                isMulti={false}
                                options={(props.statusMapping || []).map((element) => { return { label: element, value: element } })}
                            /> */}
                            <AddProcurementInput
                                label="Payment Date"
                                name="paymentDate"
                                type="date"
                                value={this.state.procurementData.paymentDate}
                            />
                        </div>
                        <h5 className="col-12">Files</h5>
                        <div className="row">
                            <AddProcurementFileInput
                                label="Invoice File"
                                name="invoiceFile"
                            />
                            <AddProcurementFileInput
                                label="Pi File"
                                name="pIFile"
                            />
                        </div>
                        <button type='submit' className="btn btn-outline-success" style={{ float: 'right', marginLeft: '10px' }}>Submit</button>
                        <button type='button' className="btn btn-primary" style={{ float: 'right' }} onClick={(e) => this.calculteValues()}>Calculate</button>
                    </form>
                    <div style={{ clear: 'both' }}></div>
                    <div>
                        <h5 className="procurementAddVendorHeading">History</h5>
                        <div>
                            <ReactTable
                                showPagination={true}
                                data={this.state.paymentsDataByOrderId}
                                columns={SingleOrderIdColumns}
                                ref={this.reactTable}
                                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                defaultPageSize={5}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProcurement);