import { FETCH_FOLLOWUP_LEADS_SUCCESS, FETCH_FOLLOWUP_MAPPING } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_FOLLOWUP_LEADS_SUCCESS: {
            return { ...state, leads: action.payload, leadDataReceived: true };
        }
        case FETCH_FOLLOWUP_MAPPING: {
            return { ...state, mapping: action.payload };
        }
        default:
            return state;
    }
}