import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import '../../styles/reactTable.css'
import '../../styles/allLeadsView.css';
import { Link } from 'react-router-dom';
import Select, { NonceProvider } from 'react-select';
import moment from 'moment';
import swal from 'sweetalert';
import 'react-dates/initialize';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Loader } from '../loader'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import url from '../../config';
import { CSVLink } from 'react-csv';

class ZunsolarConsultativeSalesView extends Component {

  constructor(props) {

    super(props);
    this.reactTable = React.createRef();
    this.state = {
      loading: true,
      recieved: false,
      stages: [],
      responsible: [],
      source: [],
      UTMSourceOptions: ["google","googletoggle","facebook","linkedin","calendly","zunpulseAPP","phonepe","airtel","grabon","whatsapp","googlepay","test_utm"],
      UTMSource:[],
      regions: [],
      states: [],
      type: [],
      city: [],
      employees: [],
      companies : [],
      languages: [],
      leadType : [{ value: "Consultative Sales", label: "Consultative Sales" }],
      updatedByOptions: [],
      highValue: [{ value: 0, label: 0 }, { value: 1, label: 1 }],
      active: [],
      modified: [{ value: "yes", label: "yes" }, { value: "no", label: "no" }],
      allLeadData: [],
      tableData: [],
      mappingData: {},
      limit: 100,
      limitoption: [
        { value: 500, label: '500' },
        { value: 1000, label: '1000' },
        { value: 5000, label: '5000' }
      ],
      page: 1,
      showFilter: false,
      filterMap: {
        leadType: true,
        CITY: true,
        STAGE: true,
        ACTIVE: true,
        ADDED_ON: true,
        nextActionDate: true,
        companyName: true,
        responsible_person: true,
        co_responsible_person: true,
        has_gst: true,
        establishment_type: true,
        modifiedOn: true,
        updatedBy: true,
        isHighPriority: true
      },
      tableMap: {
        leadId: true,
        name: true,
        leadType: true,
        city: true,
        responsible_person: true,
        companyName: true,
        stage: true,
        active: true,
        addedOn: true,
        created: true,
        source: true,
        nextActionDate: true,
        has_gst: true,
        gst_number: true,
        establishment_type: true,
        establishment_question: true,
        establishment_answer: true,
        pincode: true,
        isHighPriority: true
      },
      filterObj: false,
      filterData: {},
      filterDate: {},
      selectedLeadIds: {},
      selectAll: false,
      bulkAssign: {},
      startDate: moment(),
      endDate: null,
      // startDate: moment().subtract(29, 'days'),
      // endDate: moment(),
      focusedInput: null,
      savedFiltersPulse: {},
      filterName: '',
      count: "",
      comment: {},
      percentzge: 0,
      uploadFile: undefined,
      uploadCsvModal: false,
      uploadMessage: undefined,
      uploadError: undefined,
    }
  }

  componentDidMount() {
    this.setState({
      filterMap: localStorage.getItem("filterMapZunpulse") ? JSON.parse(localStorage.getItem("filterMapZunpulse")) : this.state.filterMap,
      tableMap: localStorage.getItem("tableMapZunpulse") ? JSON.parse(localStorage.getItem("tableMapZunpulse")) : this.state.tableMap,
      savedFiltersPulse: localStorage.getItem("savedFiltersPulse") ? JSON.parse(localStorage.getItem("savedFiltersPulse")) : this.state.savedFiltersPulse,
    })
  }

  componentWillReceiveProps(props) {

    let stages = this.optionFunction(props.allMapping.stage ? props.allMapping.stage : [], "STATUS_NAME");
    let source = this.optionFunction(props.allMapping.source ? props.allMapping.source : [], "source_name");
    let languages = this.optionFunction(props.allMapping.language ? props.allMapping.language : [], "language_name");
    let active = this.optionFunction(props.allMapping.active ? props.allMapping.active : [], "language_name");
    let type = this.optionFunction(props.allMapping.type ? props.allMapping.type : [], "language_name");
    let city = this.optionFunction(props.allMapping.city ? props.allMapping.city : [], "language_name");
    let employees = this.optionFunction(props.allMapping.employees ? props.allMapping.employees : [], "e_name");
    let companies = this.optionFunction(props.allMapping.pulsePOC ? props.allMapping.pulsePOC : [], "companyName");
    let hasGSTOptions = this.optionFunction(['Yes', 'No'], "e_name");
    let establishmentTypes = this.optionFunction(props.allMapping.establishments ? Object.keys(props.allMapping.establishments) : [], "e_name")
    let updatedByOptions = (props.allMapping.employees || []).map(employee => ({ label: employee.e_name, value: employee.e_email }))
    let UTMSource = this.handleOptions(this.state.UTMSourceOptions);
    let mappingData = props.allMapping
    if (props.searchData.length > 0) {
      this.setState({
        allLeadData: props.searchData,
        tableData: props.searchData,
        stages: stages,
        source: source,
        mappingData: mappingData,
        active: active,
        type: type,
        city: city,
        count: props.searchData.length,
        loading: false,
        recieved: true,
        hasGSTOptions,
        isHighPriorityOptions: hasGSTOptions,
        establishmentTypes,
        updatedByOptions,
        comment: props.comments.length > 0 ? props.comments.pop() : {},
        // UTMSource: UTMSource
      })
    } if (Object.keys(props.allLeads).length > 0 && Object.keys(props.allMapping).length > 0) {
      this.setState({
        stages: stages,
        source: source,
        allLeadData: props.allLeads.data,
        tableData: props.allLeads.data,
        languages: languages,
        mappingData: mappingData,
        active: active,
        city: city,
        type: type,
        loading: false,
        recieved: true,
        employees: employees,
        companies: companies,
        count: props.allLeads.count || {},
        hasGSTOptions,
        isHighPriorityOptions: hasGSTOptions,
        establishmentTypes,
        updatedByOptions,
        comment: props.comments.length > 0 ? props.comments.pop() : {},
        UTMSource: UTMSource

      })
    }
  }

  optionFunction = (array, value) => {
    let multiSelectOptionsMap = array.map((data) => {
      let obj = {}
      if (data[value]) {
        obj = {
          label: data[value].replace(/(^"|"$)/g, ''),
          value: data[value].replace(/(^"|"$)/g, '')
        }
      } else {
        obj = {
          label: data,
          value: data
        }
      }
      return obj
    })
    return multiSelectOptionsMap;
  }

  optionFunctionState = (array) => {
    let multiSelectOptionsStateMap = array.map((val) => {
      let obj = {
        label: val.replace(/(^"|"$)/g, ''),
        value: val.replace(/(^"|"$)/g, ''),
      }
      return obj;
    })
    return multiSelectOptionsStateMap
  }

  handleOptions = (array)=>{
    let optionsArray = array.map(val=>{
      let obj  = {
        label: val,
        value: val
      }
      return obj;
    })
    return optionsArray
  }

  handleSelectChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: [value]
      }
    }, () => {
    })
  }

  handleMultiSelectChange = (e, name) => {
    let selectedList = (e || []).map((data) => {
      return data.value
    });
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: selectedList
      },
      page: 1
    }, () => {
    })

  }

  handleDateChange = (e, field) => {
    let { name, value } = e.target
    this.setState({
      filterData: {
        ...this.state.filterData,
        [field]: {
          ...this.state.filterData[field] ? this.state.filterData[field] : {},
          [name]: value
        }
      },
      page: 1
    }, () => {
    });
  }

  handleInputChange = (e) => {
    let { name, value } = e.target
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: value,
      }
    })
  }

  handleNewDateChange = (e) => {
  }

  handleStateChange = (e, name) => {

    let regionArr = []
    let selectedState = (e || []).map((data) => {
      regionArr = regionArr.concat(this.props.allMapping.stateregion[data.label])
      return data.label
    });
    this.setState({
      regions: regionArr,
      filterData: {
        ...this.state.filterData,
        [name]: selectedState
      }
    })
  }

  handleSelectChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      bulkAssign: {
        ...this.state.bulkAssign,
        [name]: value
      }
    }, () => {
    })
  }

  multiSelectType = (name, options, placeholder, value) => {
    return (
      <div className="form-group" style={{ width: '100%' }}>
        <Select
          isMulti
          isSearchable
          onChange={(e) => { this.handleMultiSelectChange(e, name) }}
          placeholder={placeholder}
          name={name}
          options={options}
          value={value}
        />
      </div>
    )
  }

  selectType = (name, value, options) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  inputTypeText = (name, value, type, placeholder) => {

    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />

  }

  dateType = (name, value, field) => {
    return (
      <div class="form-group">
        <span class="dateSideBar" />
        <input name={name} style={{ width: "100%" }} className="form-control" type="date" onChange={(e) => { this.handleDateChange(e, field) }} value={value}></input>

      </div>
    )
  }

  fetchLeads = (e) => {
    let { limit, page, allLeadData } = this.state;
    if (Object.keys(this.state.filterData).length > 0) {
      let Obj = { ...this.state.filterData }
      let filterObj = this.removingNullKeys(Obj);
      if(!filterObj.leadType){
        filterObj.leadType = ["Consultative Sales"]
      }
      this.props.fetchAllLeads(limit, page + 1, allLeadData, filterObj, 'zunpulse');
    } else {
        let  filterObj = {}
      filterObj.leadType = ["Consultative Sales"]
      this.props.fetchAllLeads(limit, page + 1, allLeadData, filterObj, 'zunpulse')
    }
    this.setState({
      page: page + 1
    })
  }

  selectFetchLeads = (lim, e) => {
    this.setState({
      page: 1,
      limit: lim,
      allLeadData: []
    },
      () => {
        let { limit, page, allLeadData } = this.state;
        let  filterObj = {}
        filterObj.leadType = ["Consultative Sales"]
        this.props.fetchAllLeads(limit, page, allLeadData, filterObj, 'zunpulse')
      })
  }

  handleFilterToggle = (e) => {
    let val = !this.state.showFilter;
    this.setState({
      showFilter: val
    })
  }

  handleShowFilter = (e) => {
    let obj = { ...this.state.filterMap }
    obj[e.target.value] = e.target.checked

    this.setState({
      filterMap: {
        ...obj,
      }
    }, () => {
      localStorage.setItem("filterMapZunpulse", JSON.stringify(obj))
    })
  }

  handleShowTable = (e) => {
    let obj = { ...this.state.tableMap }
    obj[e.target.value] = e.target.checked

    this.setState({
      tableMap: {
        ...obj
      }
    }, () => {
      localStorage.setItem("tableMapZunpulse", JSON.stringify(obj))
    })
  }

  handleCheckbox = (e, leadId, row) => {

    if (e.nativeEvent.shiftKey == true) {
      let selectedLeadIds = { ...this.state.selectedLeadIds }
      let sortedArray = this.reactTable.current.state.sortedData;
      let selectedCheckboxCount = Object.keys(this.state.selectedLeadIds || {}).filter((val) => this.state.selectedLeadIds[val] == 1);
      let indexees = [];
      let lastSelectedIndex = -1;
      sortedArray.map((lead, index) => {
        if (selectedLeadIds[lead.leadId]) {
          lastSelectedIndex = index;
        }
      })
      if (lastSelectedIndex == -1) {
        selectedLeadIds[leadId] = 1
      } else {
        let currentIndex = sortedArray.findIndex(row => row.leadId == leadId);
        let [start, end] = currentIndex > lastSelectedIndex ? [lastSelectedIndex, currentIndex] : [currentIndex, lastSelectedIndex];
        for (let i = start; i <= end; i++) {
          // console.log("123456", selectedLeadIds[sortedArray])
          selectedLeadIds[sortedArray[i].leadId] = 1;
        }
      }
      this.setState({
        selectedLeadIds: selectedLeadIds
      })
    } else {
      let status = this.state.selectedLeadIds[leadId];
      let selectedBitrixId = JSON.parse(JSON.stringify(this.state.selectedLeadIds))
      let selectedLeadIds = { ...selectedBitrixId, [leadId]: status ? 0 : 1, };
      this.setState({
        selectedLeadIds: selectedLeadIds,
        selectAll: this.state.selectAll && status ? false : this.state.selectAll
        // selectedLeadIds: [...selectedLeadIds]
      }, () => {
      })
    }
  }

  handleAllCheckbox = (e, row) => {
    let page = this.reactTable.current.state.page;
    let pageSize = this.reactTable.current.state.pageSize;
    let start = page * pageSize;
    let end = (page * pageSize + pageSize);
    let actualEnd = (row.data.length > end) ? end : row.data.length
    let data = row.data;
    let selectedLeadIds = {};
    if (!this.state.selectAll) {
      for (let i = start; i <= (actualEnd - 1); i++) {
        selectedLeadIds[data[i].leadId] = 1
      }
    }

    this.setState({
      selectedLeadIds: { ...selectedLeadIds },
      selectAll: !(this.state.selectAll)
    })
  }

  handleBulkAssign = (e) => {

    let tableData = [...this.state.tableData];
    let selectedLeadIds = { ...this.state.selectedLeadIds };
    let bulkAssign = { ...this.state.bulkAssign }
    let count = this.state.count;
    // console.log(count, tableData, selectedLeadIds, bulkAssign)
    // let arr = []
    // Object.keys(selectedLeadIds).map((val) => {
    //   if (selectedLeadIds[val] == 1) {
    //     arr.push(val)
    //   }
    // })
    // let obj = { ...this.state.bulkAssign };
    // let lead = {
    //   leadId: arr,
    //   ...obj
    // }

    this.props.editBulkLeads(selectedLeadIds, tableData, bulkAssign, count, "zunpulse");

    // Object.keys(selectedLeadIds).map((leadId) => {

    //   let index = this.state.tableData.findIndex((val) => val.leadId == leadId);
    //   Object.keys(obj).map((field) => {
    //     tableData[index][field] = obj[field];
    //   })
    // })

    this.setState({
      // tableData: [...tableData]
    })
  }

  handleModalInput = (e) => {
    let { value } = e.target
    this.setState({
      filterName: value
    })
  }

  handleSavedFilterClick = (e, filter) => {
    let savedFiltersPulse = { ...this.state.savedFiltersPulse };

    let newFilterData = savedFiltersPulse[filter];
    this.setState({
      filterData: {
        ...newFilterData
      }
    })
  }

  handleSavedFilterDelete = (e, button) => {

    let savedFiltersPulse = { ...this.state.savedFiltersPulse }
    delete savedFiltersPulse[button];

    this.setState({
      savedFiltersPulse: { ...savedFiltersPulse }
    }, () => {
      localStorage.setItem("savedFiltersPulse", JSON.stringify(this.state.savedFiltersPulse))
    })
  }

  onSaveFilter = (e) => {
    let name = this.state.filterName;
    let filterObj = { ...this.state.filterData };
    filterObj = this.removingNullKeys(filterObj);
    if (Object.keys(this.state.savedFiltersPulse).length < 5) {
      if (Object.keys(filterObj).length > 0) {

        this.setState({
          savedFiltersPulse: {
            ...this.state.savedFiltersPulse,
            [name]: filterObj
          }
        }, () => {
          localStorage.setItem("savedFiltersPulse", JSON.stringify(this.state.savedFiltersPulse))
        })
      } else {
        swal({
          text: "No filters Selected"
        })
      }

    } else {
      swal({
        text: "You can save upto 5 filters",
        button: {
          cancel: "close"
        }
      })
    }
  }

  removingNullKeys = (obj) => {

    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  onApplyFilter = (e) => {
    let filterData = { ...this.state.filterData }
    filterData = JSON.parse(JSON.stringify(filterData));
    let filterObj = this.removingNullKeys(filterData);
    filterObj.leadType = ["Consultative Sales"]
    this.setState({
      loading: true
    })
    // if (Object.keys(filterObj).length > 0) {
    let { limit, page } = this.state;
    this.props.fetchAllLeads(limit, page, [], filterObj, 'zunpulse');
    this.setState({
      // filterObj: filterObj
      // })
    })
  }

  //==============comment icon click============//

  commentIconClicked = (e, row) => {
    let leadId = row.leadId;
    this.props.getComments(leadId);
  }

  //=======================================//

  //============csv upload====================//
  openUploadCsvModal = () => {
    this.setState({
      uploadCsvModal: true,

    })
  }

  closeUploadCsvModal = () => {
    this.setState({
      uploadCsvModal: false,
      successMessage: undefined,
      errorMessage: undefined
    })
  }

  uploadFile = (e) => {
    let files = e.target.files
    this.setState({
      uploadFile: files[0]
    })
  }

  submitFileUpload = async () => {
    if (this.state.uploadFile) {
      if (this.state.uploadFile.type == "text/csv") {
        let csv = this.state.uploadFile;
        let formData = new FormData();
        formData.append("file", csv);
        this.setState({
          errorMessage: undefined,
          successMessage: undefined,
          uploadMessage: 'Uploading...'
        })
        let response = await axios.post(url.pathBackend + 'zunpulse/csv/upload', formData);
        // console.log("1", response)
        if (response.data.error) {
          this.setState({
            errorMessage: response.data.error,
            successMessage: undefined,
            uploadMessage: undefined,
          })
        }
        if (!response.data.error) {
          this.setState({
            successMessage: response.data.text,
            errorMessage: undefined,
            uploadMessage: undefined,
          })
        }
      } else {
        this.setState({
          errorMessage: "Unsupported File Format!!"
        })
      }
    }
  };

  downloadDummyCsv = () => {
    this.props.downloadCsv()
  }
  //=======================================//
  render() {
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }
    const modalStyles = {
      modal: {
        width: '40%',
        maxWidth: '40%',
        padding: '40px',
        background: '#f7f7f7',
        textAlign: 'center'
      }
    }

    const columns = [
      {
        Header: <i data-toggle="modal" data-target="#selectTableOptionsModal" class="fa fa-cog fa-lg"></i>,
        csvHeader: "settings",
        width: 40,
        filterable: false,
        columns: false
      },
      {
        Header: row => {
          // console.log("301", this.state.selectedLeadIds)

          return (
            <input type="checkbox" onChange={(e) => { this.handleAllCheckbox(e, row) }} checked={this.state.selectAll ? 'checked' : ''} ></input>
          )
        },
        csvHeader: "checkbox",
        width: 50,
        filterable: true,
        sortable: false,
        columns: false,
        Filter: () => {
          let selectedCheckboxCount = Object.keys(this.state.selectedLeadIds).filter((val) => this.state.selectedLeadIds[val] == 1);
          return (
            <div>{selectedCheckboxCount.length}</div>
          )
        },
        Cell: row => (
          < div >
            <input type="checkbox" onChange={(e) => { this.handleCheckbox(e, row.original.leadId, row) }} checked={this.state.selectedLeadIds[row.original.leadId] ? 'checked' : ''} ></input>
          </div >

        )
      },
      {
        Header: 'ID',
        accessor: 'leadId',

        show: this.state.tableMap.leadId ? true : false,
        Cell: row => (
          <div>
            <Link to={"/lead/details/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/zunpulse/details/" + row.value); }}>{row.value}</Link>
          </div>
        )
      },
      {
        Header: 'Name',
        accessor: 'name',
        show: this.state.tableMap.name ? true : false,
      },
      {
        Header: 'Type',
        accessor: 'leadType',
        show: this.state.tableMap.leadType ? true : false,

      },
      {
        Header: 'City',
        accessor: 'city',
        show: this.state.tableMap.city ? true : false,
        // width: 200,
      },
      {
        Header: 'Account Manager',
        accessor: 'responsible_person',
        show: this.state.tableMap.responsible_person ? true : false,
        // width: 200,
      },
      {
        Header: 'Co Manager',
        accessor: 'co_responsible_person',
        show: this.state.tableMap.co_responsible_person ? true : false,
        // width: 200,
      },
      {
        Header: 'Point Of Contact',
        accessor: 'companyName',
        show: this.state.tableMap.companyName ? true : false,
        // width: 200,
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        show: this.state.tableMap.stage ? true : false,
        // width: 220,

      },
      {
        Header: 'Next Action Date',
        accessor: 'nextActionDate',
        show: this.state.tableMap.nextActionDate ? true : false,
        // width: 200,
        Cell: row => {
          return (
            row.value ? moment(row.value).format("YYYY-MM-DD") : ''
          )
        }
      },
      {
        Header: 'Source',
        accessor: 'source',
        show: this.state.tableMap.source ? true : false,
        // width: 200,
      },
      {
        Header: 'Added On',
        accessor: 'ADDED_ON',
        show: this.state.tableMap.ADDED_ON ? true : false,
        // width: 200,
        Cell: row => {
          return (
            row.value ? moment(row.value).format("YYYY-MM-DD") : ''
          )
        }
      },
      {
        Header: 'Active',
        accessor: 'active',
        // width: 200,
        show: this.state.tableMap.active ? true : false
      },
      {
        Header: 'High Priority',
        accessor: 'isHighPriority',
        // width: 200,
        show: this.state.tableMap.isHighPriority ? true : false
      },
      {
        Header: 'Has GST',
        accessor: 'has_gst',
        // width: 200,
        show: this.state.tableMap.has_gst ? true : false
      },
      {
        Header: 'GST Number',
        accessor: 'gst_number',
        // width: 200,
        show: this.state.tableMap.gst_number ? true : false
      },
      {
        Header: 'Est. Type',
        accessor: 'establishment_type',
        // width: 200,
        show: this.state.tableMap.establishment_type ? true : false
      },
      {
        Header: 'Est. Question',
        accessor: 'establishment_question',
        // width: 200,
        show: this.state.tableMap.establishment_question ? true : false
      },
      {
        Header: 'Est. Answer',
        accessor: 'establishment_answer',
        // width: 200,
        show: this.state.tableMap.establishment_answer ? true : false
      },
      {
        Header: 'Pincode',
        accessor: 'pincode',
        // width: 200,
        show: this.state.tableMap.pincode ? true : false
      },
      {
      Header: 'Wifi Connection',
      accessor: 'wifiConnection',
      },
      {
        Header: 'User Feedback',
        accessor: 'userFeedback',
        // width: 200,
      },
      {
        Header: "Comment",
        csvHeader: "comments",
        Cell: row => {
          return (
            <i className="fa fa-comments fa-lg" style={{ cursor: "pointer" }} data-toggle="modal" data-target="#commentModal" onClick={(e) => { this.commentIconClicked(e, row.original) }}></i>
          )
        }
      },
    ];

    const csvColumns = [];

    columns.forEach((obj) => {
      if (!(obj.csvHeader == "comments") && !(obj.csvHeader == "settings") && !(obj.csvHeader == "checkbox")) {
        csvColumns.push(obj);
      }
    });


    const filterOptions = [
      { label: "Type", value: "leadType" },
      { label: "City", value: "CITY" },
      { label: 'Stage', value: "STAGE" },
      { label: "Active", value: "ACTIVE" },
      { label: "Added On", value: "ADDED_ON" },
      { label: "NEXT ACTION DATE", value: "nextActionDate" },
      { label: "Point Of contact", value: "companyName" },
      { label: "Account Manager", value: "responsible_person" },
      { label: "Co Manager", value: "co_responsible_person" },
      { label: "Has GST", value: "has_gst" },
      { label: "Establishment Type", value: "establishment_type" },
      { label: "Modified By", value: "updatedBy" },
      { label: "Modified On", value: "modifiedOn" },
      { label: "High Priority", value: "isHighPriority" },
    ]
    const tableOptions = columns.map(val => {
      if (val.accessor) {
        return { label: val.Header, value: val.accessor };
      }
    }).filter(val => val !== undefined);

    if (this.state.recieved) {
      return (
        <div className="container-fluid" style={{ marginTop: "80px", backgroundColor: "#f7f7f7" }}>

          <div className="topbar-section allLeadViewFilters" style={this.props.searchData.length > 0 ? style.hide : style.show}>
            <div className="pull-right">
              <i data-toggle="modal" data-target="#selectFilterModal" onClick={this.handleFilterModal} class="fa fa-cog" style={{ float: "right", fontSize: "25px", cursor: "pointer" }} aria-hidden="true"></i>
              {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                                Launch demo modal
                            </button> */}
              {/* modal body */}
              <div class="modal fade" id="selectFilterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Select Filters</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div className="row">
                        {filterOptions.map(option => {
                          return <div className="col-md-6"><input name="filterMap" type="checkbox" value={option.value} checked={this.state.filterMap[option.value]} onChange={this.handleShowFilter} /> <span>{option.label}</span></div>
                        })}
                      </div>
                    </div>
                    {/* <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b>
              <span style={Object.keys(this.state.savedFiltersPulse).length > 0 ? style.show : style.hide}>

                {Object.keys(this.state.savedFiltersPulse).map((button) => {
                  return (
                    <span style={{ marginLeft: "20px" }}>
                      <button class="btn btn-outline-secondary savedFilterBtn" onClick={(e) => this.handleSavedFilterClick(e, button)}>{button}</button>
                      {/* <button class="btn btn-outline editFilterName"><i class="fa fa-pencil"></i></button> */}
                      <button class="btn btn-outline deleteFilterBtn"><i onClick={(e) => this.handleSavedFilterDelete(e, button)} class="fa fa-times trashButton"></i></button>
                    </span>
                  )
                })}
              </span>
            </div>
            <hr style={this.state.showFilter ? style.show : style.hide}></hr>
            <div className="row" style={this.state.showFilter ? style.show : style.hide}>

              <div className="col-md-2" style={this.state.filterMap.STAGE ? style.show : style.hide}>
                <label>Stage</label>
                {this.multiSelectType("STAGE", this.state.stages, "Stage", (this.state.filterData.STAGE || []).map((val) => ({ label: val, value: val })))}
                {/* {this.selectType("stages",this.state.mappingData.stages?this.state.mappingData.stages:[],"Stage","STATUS_NAME",this.state.filterData.stages,)} */}
              </div>
              <div className="col-md-2" style={this.state.filterMap.ACTIVE ? style.show : style.hide}>
                <label>Active</label>
                {this.multiSelectType("ACTIVE", this.state.active, "Active", (this.state.filterData.ACTIVE || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.leadType ? style.show : style.hide}>
                <label>Type</label>
                {this.multiSelectType("leadType", this.state.leadType, "Type", (this.state.filterData.leadType || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.CITY ? style.show : style.hide}>
                <label>city</label>
                {this.multiSelectType("CITY", this.state.city, "City", (this.state.filterData.CITY || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.ADDED_ON ? style.show : style.hide}>
                <label>Added On Start</label>
                {this.dateType("startDate", this.state.filterData.ADDED_ON ? this.state.filterData.ADDED_ON.startDate : "", "ADDED_ON")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.ADDED_ON ? style.show : style.hide}>
                <label>Added On End</label>
                {this.dateType("endDate", this.state.filterData.ADDED_ON ? this.state.filterData.ADDED_ON.endDate : "", "ADDED_ON")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.nextActionDate ? style.show : style.hide}>
                <label>Next Action Start</label>
                {this.dateType("startDate", this.state.filterData.nextActionDate ? this.state.filterData.nextActionDate.startDate : '', "nextActionDate")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.nextActionDate ? style.show : style.hide}>
                <label>Next Action End</label>
                {this.dateType("endDate", this.state.filterData.nextActionDate ? this.state.filterData.nextActionDate.endDate : '', "nextActionDate")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.companyName ? style.show : style.hide}>
                <label>Point Of Contact</label>
                {this.multiSelectType("companyName", this.state.companies, "POC", (this.state.filterData.companyName || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.responsible_person ? style.show : style.hide}>
                <label>Account Manager</label>
                {this.multiSelectType("responsible_person", this.state.employees, "Manager", (this.state.filterData.responsible_person || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.co_responsible_person ? style.show : style.hide}>
                <label>Co Manager</label>
                {this.multiSelectType("co_responsible_person", this.state.employees, "Co-Manager", (this.state.filterData.co_responsible_person || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.has_gst ? style.show : style.hide}>
                <label>Has GST</label>
                {this.multiSelectType("has_gst", this.state.hasGSTOptions, "Has Gst", (this.state.filterData.has_gst || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.isHighPriority ? style.show : style.hide}>
                <label>High Priority</label>
                {this.multiSelectType("isHighPriority", this.state.isHighPriorityOptions, "High Priority", (this.state.filterData.isHighPriority || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.establishment_type ? style.show : style.hide}>
                <label>Establishment Type</label>
                {this.multiSelectType("establishment_type", this.state.establishmentTypes, "Establishment Type", (this.state.filterData.establishment_type || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.modifiedOn ? style.show : style.hide}>
                <label>Modified On Start</label>
                {this.dateType("startDate", this.state.filterData.modifiedOn ? this.state.filterData.modifiedOn.startDate : '', "modifiedOn")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.modifiedOn ? style.show : style.hide}>
                <label>Modified On End</label>
                {this.dateType("endDate", this.state.filterData.modifiedOn ? this.state.filterData.modifiedOn.endDate : '', "modifiedOn")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.updatedBy ? style.show : style.hide}>
                <label>Modified By</label>
                {this.multiSelectType("updatedBy", this.state.updatedByOptions, "Modified By", this.state.updatedByOptions.filter(responsible => (this.state.filterData.updatedBy || []).includes(responsible.value)))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.leadType ? style.show : style.hide}>
                <label>Source</label>
                {this.multiSelectType("source", this.state.source, "source", (this.state.filterData.source || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.leadType ? style.show : style.hide}>
                <label>UTM Source</label>
                {this.multiSelectType("UTM_SOURCE", this.state.UTMSource, "UTM Source", (this.state.filterData.UTM_SOURCE || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div style={Object.values(this.state.filterMap).includes(true) ? style.show : style.hide}>
                <label></label><br></br>
                <button style={{ marginTop: "10px" }} onClick={this.onApplyFilter} className="btn btn-success btn-sm">Apply Filter</button>
              </div>
              <div className="col-md-2" style={(Object.values(this.state.filterMap).includes(true) && Object.keys(this.state.filterData).length > 0) ? style.show : style.hide}>
                <label></label><br></br>
                <button style={{ marginTop: "10px" }} class="btn btn-outline-secondary btn-sm" data-toggle="modal" data-target="#exampleModal">Save Filter</button>
              </div>

              {/* modal for save filter */}
              <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-body">
                      <form>
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Type FilterName:</label>
                          <input type="text" class="form-control" id="recipient-name" name={this.state.filterName} onChange={(e) => { this.handleModalInput(e) }} />
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-primary btn-sm" onClick={this.onSaveFilter}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* modal for save filter end */}

            </div>
          </div>
          <div>
            <div className="row" style={{ margin: '20px 0px' }}>
              <div className="col-md-8">
                {/* <span> Leads : {this.state.tableData.length} </span> */}
                <span style={this.props.searchData.length > 0 ? style.hide : style.show}><div style={{ display: 'inline-block', width: '150px' }}><Select placeholder="No. of Leads" options={this.state.limitoption} onChange={e => this.selectFetchLeads(e.value, e)} /></div></span>
                <span style={this.props.searchData.length > 0 ? style.hide : style.show}><button className="moreLeads" onClick={e => this.fetchLeads()}> More Leads <i class="fas fa-angle-double-right" aria-hidden="true"></i></button></span>
                <span style={{ paddingLeft: "30px" }}><b>Total Leads</b> : <b>{this.state.count}</b></span>

                <CSVLink filename={"Pulse Orders Data.csv"} data={this.state.tableData} headers={csvColumns.map(val => ({ label: val.csvHeader || val.Header, key: val.accessor }))} type="button" className="btn btn-light">
                  Download <i className="fa fa-download"></i>
                </CSVLink>
              </div>
              <div className="col-md-4" style={{ textAlign: "right" }}>
                <span className="csvSpan" onClick={this.openUploadCsvModal}>Working with CSV ? </span>
                <Link to="/zunpulse/createlead" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> New Lead</button></Link>
              </div>
            </div>

            <Modal open={this.state.uploadCsvModal} onClose={this.closeUploadCsvModal} styles={modalStyles} center>
              <div style={{ border: "1px solid #92b0b3", padding: "5px" }}>
                <div className=" container-fluid uploadBox">
                  <input style={{ width: "50 %", height: '42px', fontWeight: "bold" }} type="file" onChange={(e) => { this.uploadFile(e) }} accept=".csv"></input>
                  <button style={{ height: '38px', fontWeight: "bold" }} className="btn btn-info btn-sm" onClick={this.submitFileUpload}>Upload <i className="fa fa-upload"></i></button>
                  <br></br>
                  <div style={{ margin: "20px" }} >
                    <span style={{ color: "red" }}>{this.state.errorMessage ? this.state.errorMessage : ""}</span>
                    <span style={{ color: "green" }}>{this.state.successMessage ? this.state.successMessage : ""}</span>
                    <span style={{ color: "green" }}>{this.state.uploadMessage ? this.state.uploadMessage : ""}</span>
                  </div>

                 &nbsp;&nbsp;&nbsp;&nbsp; <button className="btn btn-success btn-sm" style={{ marginTop: "20px", height: '42px', fontWeight: "bold" }} onClick={this.downloadDummyCsv}>Download CSV format <i className="fa fa-download"></i></button><br></br>
                  <div style={{ textAlign: "left", margin: "15px" }}>
                    <span style={{ color: "gray" }}> 1. Insert data in the given CSV file. <br></br>
                        2. <b>Don't change headers of any column</b>.<br></br>
                        3. <b>Name, Phone Number, Responsibe, Source</b> are compulsory fields <br></br>
                     4. Phone Number <b>Must be of 10 Digits</b>. <br></br>
                  5. Added On and Next Action Date (if any) must be in <b>YYYYMMDD format</b>.<br></br>
                  6. Dropdown variables like Source, Stage etc. must have same values as in dropdown.
                    </span>
                  </div>

                </div>
              </div>
            </Modal>

            <div className="row" style={{ marginTop: "25px" }} style={Object.values(this.state.selectedLeadIds).includes(1) ? style.show : style.hide}>
              <div class="col-md-2">
                <label>Responsible</label>
                {this.selectNewType("responsible_person", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.bulkAssign.responsible_person)}
              </div>
              <div class="col-md-2">
                <label>Stage</label>
                {this.selectType("stage", this.state.bulkAssign.stage, this.state.mappingData.stage ? this.state.mappingData.stage : "")}
              </div>

              <div className="col-md-1" style={{ marginTop: "32px" }}>
                <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkAssign(e) }}>Submit</button>
              </div>
            </div>


            {/* Modal for Selecting Table Headers */}

            <div style={{ marginTop: '350px' }} class="modal fade" id="selectTableOptionsModal" tabindex="-1" role="dialog" aria-labelledby="selectTableOptionsLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="selectTableOptionsLabel">Select Table Headers</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      {tableOptions.map(option => {
                        return <div className="col-md-6"><input name="tableMap" type="checkbox" value={option.value} checked={this.state.tableMap[option.value]} onChange={(e) => this.handleShowTable(e)} /> <span>{option.label}</span></div>
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Ends */}

            {/* modal for comments */}
            <div className="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModalTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">Comments</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {Object.keys(this.state.comment).length > 0 ? <div><p><b>{this.state.comment.commentedBy.split('@')[0].split('.')[0] + ' ' + this.state.comment.commentedBy.split('@')[0].split('.')[1]} {moment(+(this.state.comment.timestamp)).format('DD/MM/YYYY hh:mm a')} -  </b> &nbsp;&nbsp; {this.state.comment.commentBody.replace(/&nbsp;/g, '  ')}</p></div> : "No Comments found"}

                  </div>
                </div>
              </div>
            </div>
            {/* modal for comments end */}

            <div style={{ marginTop: "10px" }}>
              <ReactTable
                loading={this.state.loading}
                filterable={true}
                showPagination={true}
                data={this.state.tableData || []}
                defaultPageSize={100}
                columns={columns}
                ref={this.reactTable}
                style={{ backgroundColor: "#ffffff" }}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
              //    defaultPageSize = {2}
              //    pageSizeOptions = {[2,4,6]}
              />

            </div>
          </div>
        </div >
      )
    }
    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }

  }

}

export default ZunsolarConsultativeSalesView;

