import React, { Component } from 'react'
import { getSearchResult } from "../../actions/searchAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Select from 'react-select';
import { detailedListColumns, orderColumns } from "../../config/column_fields";
import moment from 'moment';

const filterList=["Not shipped", "Cancelled", "Returned (Before Delivery)", "Returned (After Delivery)", "Returning (Before Delivery)", "Returning (After Delivery)", "In Transit", "Delivered","30 days Delivered", "Truncated", "Lost/Damaged - Claim Pending", "Lost/Damaged - Claim Received", "Lost/Damaged - Claim Rejected", "Booked", "Invoiced", "Out of stock", "Pre-Booked"]
class DetailedView extends Component {
    constructor() {
        super();
        this.state = {
          detailedList: [],
          detailedCopy: [],
          ListColumns: [],
        };
      }

    componentDidMount() {
        let ListColumns = detailedListColumns.map((row) => {
          return row;
        });
        this.setState({ ListColumns });
      }
    
      componentWillMount() {
        this.props.fetchDetailedList();
      }
    
      componentWillReceiveProps({ detailedList = [] }) {
        detailedList.map((list)=>{
          if (list.status == "Delivered") {
             let orderDate=moment(moment(list.date,'MMMM,Do YYYY').format('YYYY-MM-DD'));
             let todayDate=moment(moment().format('YYYY-MM-DD'));
             console.log(orderDate,todayDate);
             let days = Math.abs(moment.duration(orderDate.diff(todayDate)).asDays());
             list.status = days>=30 ? "30 days Delivered" : list.status;
          }
        })
      const detailedCopy=JSON.parse(JSON.stringify(detailedList));
      this.setState({ detailedList, detailedCopy });
      }
    
    handleStatusChange = (e) => {
      if (e==null){
        this.setState({detailedList:this.state.detailedCopy})
      }
      // console.log(e.value);
      else {
        let filteredList = this.state.detailedList.filter((data)=>{
          return  e!=null && data.status === e.value;
        })
        console.log(filteredList);
        this.setState({detailedList:filteredList})
      }
    }
    
    
    render() {
        let { detailedList, ListColumns } = this.state;
        let { handleSubmitClick } = this;
        return (
          <div className="procurementAddVendor">
            <div className="row">
            <div className="col-12 col-md-2 addVendorInput">
              <div className="form-group">
                  <div className="mb-3">
                      <label>Order Status</label>
                      <Select
                        isSearchable={true}
                        isClearable={true}
                        placeholder={"Order Status"}
                        onChange={(e) => this.handleStatusChange(e)}
                        options={filterList.map(row => ({ label: `${row}`, value: row }))}
                        isMulti={false}
                        />
                  </div>
              </div>
            </div>
            </div>
          
            <ReactTable
              filterable={true}
              showPagination={true}
              sortable={true}
              data={detailedList}
              columns={ListColumns}
              defaultSorted={[{ id: "id", desc: true }]}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) > -1
              }
              defaultPageSize={10}
            />
          </div>
        );
    }
}

function mapStateToProps() {
    return {};
}
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailedView);

