import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/leadsView.css';
import ReactTable from "react-table";
import moment from "moment";
import swal from 'sweetalert';
import { Loader } from './loader'
import { handleConnectOutboundCallIS } from '../config/column_fields';

class leadsView extends Component {


  constructor(props) {

    super(props);

    this.state = {
      loading: true,
      recieved: false,
      edit: false,
      newComment: '',
      leadData: {},
      newLeadData: {},
      propertyType: ["Residential", "Commercial", "Government", "Trust Run Institution", "SME", "Society"],
      active: [1, 0],
      saType: ["prepaid", "postpaid", "SA ONSITE", "VIDEO ASSESSMENT"],
      regions: [],
      pincodeRegionMap: [],
      comments: [],
      mappingData: {},
      roofOwnership: ["yes", "no"],
      username: '',
      product: ["Grid Tied", "Off Grid", "Hybrid", "Inverter Backup", "Street Light", "Water Pump", "Affiliate", "PV Panel", "Diesel Generator", "Solar AC", "Water Heater", "RESCO", "Other"],
      source: ["Inbound (Online)", "SMS_Inbound", "Email_Inbound", "Google_WhatsApp", "Flyer_Inbound", "Google", "Facebook", "Adjust", "Direct", "Referral", "BD", "Facebook_Chat", "Google_Chat", "Inmobi", "Direct_Chat", "Direct_Blog", "PR", "SMS", "SMS_Chat", "Email", "Email_Chat", "LinkedIn", "YouTube", "Twitter", "APP_Install", "APP_Referral", "Maint_Referral", "MNRE", "Facebook/Google", "Offline", "Google Display", "Taboola", "WhatsAPP", "Quora", "zunprime", "Paytm", "Client Happiness"],
      saCount: [1, 2, 3, 4, 5, '>5'],
      referredByType : ["Client", "Vendor/Affiliate"],
      showUtmVariables: false,
      userObj: {},
      showCallButton: false
    }

    this.commentColumns = [
      {
        Header: 'Comment By',
        accessor: 'commentedBy'
      },
      {
        Header: 'Time',
        accessor: 'timestamp',
        Cell: row => {
          return new Date(parseInt(row.original.timestamp)).toLocaleDateString();
        }
      },
      {
        Header: 'Comment',
        accessor: 'commentBody',
        style: { 'whiteSpace': 'unset' }
      }
    ];
    this.historyColumns = [
      {
        Header: 'Time',
        accessor: 'timestamp',
        Cell: row => {

          return moment(+row.original.timestamp).format('DD/MM/YYYY hh:mm a');
        }
      },
      {
        Header: 'Updated By',
        accessor: 'updatedBy',
        Cell: row => {
          return (row.original.updatedBy.split('@')[0].split('.')[0]) + ' ' + row.original.updatedBy.split('@')[0].split('.')[1]
        }
      },
      {
        Header: 'Field Name',
        accessor: 'updatedField'
      },
      {
        Header: 'Old Value',
        accessor: 'updatedFrom'
      },
      {
        Header: 'New Value',
        accessor: 'updatedTo'
      }
    ];
  }

  componentWillMount() {
    if (localStorage.getItem("userObj")) {
      let userObj = JSON.parse(localStorage.getItem("userObj"))
      let showCallButton = false;
      if((userObj.access || []).includes("Pre-Sales")) showCallButton = true;
      this.setState({
        username: userObj.username,
        userObj: userObj,
        showCallButton: showCallButton
      })
    }
  }

  componentWillReceiveProps(props) {
    let leadDetails = props.leadDetails;
    let mappingData = props.allMapping ? props.allMapping : {}

    if (leadDetails.data && Object.keys(mappingData).length > 0) {
      let pincodeRegionMap = props.allMapping.pincodes;
      let regions = Object.values(props.allMapping.stateregion);
      regions = [].concat(...regions)
      regions = this.titleCase(regions);
      // ("regions", regions);
      this.setState({
        leadData: (leadDetails && leadDetails.data) ? leadDetails.data : {},
        mappingData: mappingData,
        loading: false,
        recieved: true,
        pincodeRegionMap: pincodeRegionMap,
        regions: regions,
      })
    }

  }

  onCommentChange = (e) => {
    let { name, value } = e.target;
    // let comments = [...this.state.leadData.comments];
    // comments.push({
    //     bitrixId : this.state.leadData.BITRIX_ID,
    //     commentedBy : "Shivam Dubey",
    //     commentBody : e.target.value
    // })

    this.setState({
      newComment: value,
      // leadData:{
      //     ...this.state.leadData,
      //     comments:comments
      // }
    })
  }

  handleZipcodeInputChange = (e) => {

    let { name, value } = e.target;

    let arrValue = value.split('');
    if (arrValue[0] == "9" || arrValue.length > 6) {
      swal({
        text: "Invalid Zipcode",
        icon: "warning",
      })
    } else if (arrValue.length == 6) {

      let pincodeRegionMap = [...this.state.pincodeRegionMap];
      let regions = pincodeRegionMap.map((obj) => {
        if (obj.PINCODE == value) {
          return obj.REGION
        }
      });
      let region = regions.filter((reg) => {
        return reg != undefined;
      })
      region = this.titleCase(region)
      region = region[0] || '';
      this.setState({
        newLeadData: {
          ...this.state.newLeadData,
          [name]: value,
          REGION: region,
        },

      }, () => {

      })
    } else {
      this.setState({
        newLeadData: {
          ...this.state.newLeadData,
          [name]: value,
          REGION: []
        }
      })
    }
  }

  commentSubmit = (e) => {
    let comments = [...this.state.leadData.comments];
    let newComment = this.state.newComment;
    newComment = newComment.trim();
    if (newComment.length > 0) {
      let newCommentData = {
        bitrixId: this.state.leadData.BITRIX_ID,
        commentedBy: this.state.username,
        commentBody: newComment,
        timestamp: new Date().getTime()
      };
      comments.push(newCommentData);
      this.props.addComments(newCommentData);
      this.setState({
        newLeadData: {
          ...this.state.newLeadData,
          comments: comments,
        },
        // newComment: e.target.value,
        // comments : this.state.comments.push({by:"user1",time:new Date(),text:"hey there"})
        leadData: {
          ...this.state.leadData,
          comments: comments,
        },
        newComment: ''
      })
    }

  }

  handlePageRefresh = () => {
    this.props.fetchBitrixId(this.state.leadData.BITRIX_ID);
  }

  handleUtmDivToggle = () => {
    this.setState({
      showUtmVariables: !this.state.showUtmVariables
    })
  }

  titleCase = (arr) => {
    let titleCaseArr = arr.map((str) => {
      str = str.trim();
      str = str.replace('-', ' ')
      let splitStr = str.toLowerCase().split(' ');

      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ')
    })
    return titleCaseArr
  }

  handleEdit = () => {
    this.editDetails = {};
    if (this.state.edit) {
      this.setState({
        edit: false
      })
    } else {
      this.setState({
        mappingData: { ...this.props.allMapping },
        newLeadData: { ...this.state.leadData },
        regions: Object.keys(this.state.mappingData).length > 0 ? this.state.mappingData.stateregion[this.state.leadData.STATE] : [],
        edit: true
      })
    }
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    value = this.checkSelectString(value);
    this.setState({

      newLeadData: {
        ...this.state.newLeadData,
        [name]: value,
      }
    })

  }

  onClickCancel = () => {
    this.setState({
      edit: false
    })
  }

  handleStateChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      regions: [...this.state.mappingData.stateregion[value]],
      newLeadData: {
        ...this.state.newLeadData,
        [name]: value,
      }
    })
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }

  onNewLeadDataSubmit = (e) => {
    // ("new data of lead",e);
    let bitrixId = this.state.leadData.BITRIX_ID;
    let oldData = this.state.leadData;
    let newData = this.state.newLeadData;
    this.props.editLead(newData, oldData, bitrixId)
    this.setState({
      edit: false,
      leadData: { ...this.state.newLeadData },
    })
  }

  // inputTypeDate = (name,value,type)

  inputType = (name, value, type, placeholder) => {

    if (name == "ZIP_CODE") {
      return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleZipcodeInputChange(e)} />
    }

    if (name == "PHONE_VALUE" || name == "alternate_number") {
      return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} minLength="10" maxLength="10" pattern="[0-9]{10}"/>
    }

    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />

  } 

  selectType = (name, value, options) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  handleCallButtonClick = () => {
    let { leadData = {}, userObj = {} } = this.state;
    let phoneNumber = leadData.PHONE_VALUE;
    console.log("call", phoneNumber);
    if(!phoneNumber || phoneNumber.length!=10) return alert("Invalid mobile number");
    let email = userObj.email;
    if(!email) {
      alert("Some error occured");
      return;
    }
    console.log(email, phoneNumber);
    handleConnectOutboundCallIS(email, phoneNumber);
  }


  render() {
    // ("this is me",this.state.leadData);
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }

    let { sourceBasedAccess = false } = this.state.userObj || {};
    console.log(this.state.userObj);

    if (this.state.recieved) {
      return (

        <div className="container-fluid" style={{ marginTop: "80px" }}>
          <div className="topbar-section" >
            <div className="row">
              <div className="col-md-9 col-xs-12 topbar-left">
                <div className="col-md-12">
                  <strong>Lead</strong>
                  <strong style={{ marginRight: "5px" }}> # {(this.state.leadData.BITRIX_ID ? this.state.leadData.BITRIX_ID : '')}</strong>
                  <strong style={this.state.edit ? style.hide : style.show}>{this.state.leadData.TITLE ? this.state.leadData.TITLE.replace(/(^"|"$)/g, '') : ''}</strong>
                  <span style={this.state.edit ? style.show : style.hide} className="input-group titleInput" >
                    {this.inputType("TITLE", this.state.newLeadData.TITLE, "text", "Lead Title")}
                  </span>
                  { this.state.showCallButton ? <span style={this.state.edit ? style.hide : style.show} >
                    <button className="btn btn-outline-success ml-3" onClick={this.handleCallButtonClick}>Call</button>
                  </span> : "" }
                </div>
              </div>
              <div className="col-md-3 col-xs-12 topbar-right">
                { !sourceBasedAccess ? <div className="col-md-12 col-xs-12">
                  <span style={this.state.edit ? style.hide : style.show}><i onClick={this.handleEdit} style={{ cursor: "pointer", float: "right", marginTop: "8px" }} className="fas fa-pen"></i></span>
                  <button style={this.state.edit ? style.show : style.hide} className="button-edit" onClick={(e) => this.onNewLeadDataSubmit(e)}>Save</button>
                  <button style={this.state.edit ? style.show : style.hide} className="button-cancel" onClick={(e) => this.onClickCancel(e)}>Cancel</button>
                </div> : "" }
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-md-1" >
              <h5> <b>Stage :</b> </h5>
            </div>
            <div className="col-md-3">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.STATUS_ID}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectNewType("STATUS_ID", this.state.mappingData.stages ? this.state.mappingData.stages : [], "STATUS_NAME", this.state.newLeadData.STATUS_ID)}
                </span>
              </h5>
            </div>
            <div className="col-md-1">
              <h5><b>Active :</b></h5>
            </div>
            <div className="col-md-3">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.ACTIVE}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectType("ACTIVE", this.state.newLeadData.ACTIVE, this.state.active)}
                </span>
              </h5>
            </div>
            <div className="col-md-1">
              <h5><b>High Value :</b></h5>
            </div>
            <div className="col-md-3">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.HIGH_VALUE}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectType("HIGH_VALUE", this.state.newLeadData.HIGH_VALUE, this.state.active)}
                </span>
              </h5>
            </div>
          </div>

          {/* <div className="row progressbar">
                      <ul className="col-md-12" id="progressbar">
                          <li className="active">Sale Completed</li>
                          <li>Vendor Allocated</li>
                          <li>Documents Collected</li>
                          <li>Application for special requirements</li>
                          <li>SECI signed</li>
                          <li>Structures and materials delivered</li>
                          <li>Installation Complete</li>
                          <li>Net Metering Complete</li>
                          <li>Deal Closed</li>
                      </ul>
                  </div> */}


          <div className="bottom-section">
            <div className="row">
              <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                <table>
                  {/* <tr style={{borderBottom: '1px solid #ddd',backgroundColor: '#5fc0ea'}} >
                                      <th><img src="img/profile.png" alt="logo" width="35px" height="35px" style={{margin:"-6px 10px -6px -5px", borderRadius: "50%"}} /></th>
                          <th style={{textAlign: 'right',color: 'white', fontWeight: 400, fontSize: '16px'}}>{this.props.leadDetails.N_Responsible}<br /><small style={{margin: '0px'}}>Responsible</small>
                          </th>
                          </tr> */}

                  <tr>
                    <td>Phone</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.PHONE_VALUE}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("PHONE_VALUE", this.state.newLeadData.PHONE_VALUE, "text")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Alternate Phone</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.alternate_number}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("alternate_number", this.state.newLeadData.alternate_number, "text")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.EMAIL_VALUE}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("EMAIL_VALUE", this.state.newLeadData.EMAIL_VALUE, "email")}
                      </span>
                    </td>

                  </tr>

                  <tr>
                    <td>Address</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.ADDRESS}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("ADDRESS", this.state.newLeadData.ADDRESS, "text")}
                      </span>
                    </td>

                  </tr>
                  <tr>
                    <td>ZIP CODE</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.ZIP_CODE}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("ZIP_CODE", this.state.newLeadData.ZIP_CODE, "text")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Region</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.REGION}</span>

                      <span style={this.state.edit ? style.show : style.hide}>


                        {this.selectType("REGION", this.state.newLeadData.REGION ? this.state.newLeadData.REGION : '', this.titleCase(this.state.regions || []))}
                        {/* {this.selectNewType("STAGE",this.state.mappingData.stages?this.state.mappingData.stages:[],"STATUS_NAME")} */}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>City</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.ADDRESS_CITY}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">

                        {this.selectType("ADDRESS_CITY", this.state.newLeadData.ADDRESS_CITY ? this.state.newLeadData.ADDRESS_CITY : '', this.titleCase(this.state.regions || []))}

                      </span>

                    </td>
                  </tr>
                  <tr>
                    <td>State</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.STATE}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {/* {this.selectType("STATE", this.state.newLeadData.STATE ? this.state.newLeadData.STATE : '', this.state.states)}
                                               */}

                        {/* {this.selectNewType("STATE",this.state.mappingData.stateregion?Object.keys(this.state.mappingData.stateregion):'','',this.state.newLeadData.STATE)} */}
                        <div class="form-group" style={{ width: '100%' }} >

                          <select
                            onChange={(e) => { this.handleStateChange(e) }}
                            className="form-control" id="sel1" name={"STATE"} value={this.state.newLeadData.STATE}
                          >
                            <option>Select</option>
                            {(this.state.mappingData.stateregion ? Object.keys(this.state.mappingData.stateregion) : []).map((e) => <option key={e}>{e}</option>)}
                          </select>
                        </div>

                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Bill</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.MONTHLY_BILL_FORM}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("MONTHLY_BILL_FORM", this.state.newLeadData.MONTHLY_BILL_FORM, "number")}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>


              <div className="col-md-8 table-wrapper">
                <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
                  <table className="customer-info-left">

                    <tr>
                      <td>Responsible</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.N_RESPONSIBLE ? this.state.leadData.N_RESPONSIBLE.replace(/(^"|"$)/g, '') : ''}</span>

                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("N_RESPONSIBLE", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.newLeadData.N_RESPONSIBLE)}
                        </span>
                      </td>

                    </tr>
                    <tr>
                      <td>Language</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.LANGUAGE}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("LANGUAGE", this.state.mappingData.languages, "language_name", this.state.newLeadData.LANGUAGE)}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Next Action Date</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.NEXT_ACTION_DATE ? moment(this.state.leadData.NEXT_ACTION_DATE).format("DD MMM, YYYY") : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("NEXT_ACTION_DATE", moment(this.state.newLeadData.NEXT_ACTION_DATE, 'YYYYMMDD').format('YYYY-MM-DD'), "date")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Property Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.PROPERTY_TYPE}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("PROPERTY_TYPE", this.state.newLeadData.PROPERTY_TYPE, this.state.propertyType)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Roof Ownership</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.ROOF_OWNERSHIP}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("ROOF_OWNERSHIP", this.state.newLeadData.ROOF_OWNERSHIP, this.state.roofOwnership)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Inside Sales Opinion</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.INSIDE_SALES_OPINION}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("INSIDE_SALES_OPINION", this.state.newLeadData.INSIDE_SALES_OPINION, this.state.roofOwnership)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>SA Schedule Count</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SA_COUNT}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("SA_COUNT", this.state.newLeadData.SA_COUNT, this.state.saCount)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Source</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SOURCE_ID}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("SOURCE_ID", this.state.mappingData.source ? this.state.mappingData.source : [], "source_name", this.state.newLeadData.SOURCE_ID)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Added On</td>
                      <td><span style={this.state.edit ? style.hide : style.show} >{this.state.leadData.ADDED_ON ? moment(this.state.leadData.ADDED_ON).format("DD MMM, YYYY") : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("ADDED_ON", moment(this.state.newLeadData.ADDED_ON, "YYYYMMDD").format('YYYY-MM-DD'), "date")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Last reach out</td>
                      <td><span>{this.state.leadData.lastReachOut ? moment(this.state.leadData.lastReachOut).format("DD MMM, YYYY") : ''}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Referred By</td>
                      <td><span style={this.state.edit ? style.hide : style.show} >{this.state.leadData.referredByType}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.selectType("referredByType", this.state.newLeadData.referredByType, this.state.referredByType)}
                        </span>
                      </td>
                    </tr>
                    <tr style={(this.state.leadData.referredByType === "Client" || this.state.newLeadData.referredByType === "Client") ? style.show : style.hide}>
                      <td>Referred By QuoteId</td>
                      <td><span style={this.state.edit ? style.hide : style.show} >{this.state.leadData.referredByQuoteId}</span>
                        <span style={(this.state.edit && (this.state.leadData.referredByType === "Client" || this.state.newLeadData.referredByType === "Client")) ? style.show : style.hide} className="input-group">
                        {this.inputType("referredByQuoteId", this.state.newLeadData.referredByQuoteId, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                    <td>Referred By Name</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.referredByName}</span>
                      <span style={(this.state.edit && (this.state.leadData.referredByType || this.state.newLeadData.referredByType)) ? style.show : style.hide} className="input-group">
                        {this.inputType("referredByName", this.state.newLeadData.referredByName, "text")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Referred By Number</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.referredByNumber}</span>
                      <span style={(this.state.edit && (this.state.leadData.referredByType || this.state.newLeadData.referredByType)) ? style.show : style.hide} className="input-group">
                        {this.inputType("referredByNumber", this.state.newLeadData.referredByNumber, "number")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Referral Amount</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.referralAmount}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("referralAmount", this.state.newLeadData.referralAmount, "number")}
                      </span>
                    </td>
                  </tr>
                    {/* <tr>
                                          <td>UTM Source</td>
                                          <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SOURCE_ID}</span>
                                              <span style={this.state.edit ? style.show : style.hide} className="input-group">
                                                  {this.inputType("SOURCE_ID", this.state.leadData.SOURCE_ID, "text")}
                                              </span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>Added On</td>
                                          <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.ADDED_ON}</span>
                                              <span style={this.state.edit ? style.show : style.hide} className="input-group">
                                                  {this.inputType("ADDED_ON", this.state.leadData.ADDED_ON, "text")}
                                              </span>
                                          </td>
                                      </tr> */}


                  </table>
                </div>
                <div className="col-md-6 customer-info">
                  <table className="customer-info-right">
                    <tr>
                      <td>SA Date</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SITE_ASSESSMENT_DATE ? moment(this.state.leadData.SITE_ASSESSMENT_DATE).format("DD MMM, YYYY") : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("SITE_ASSESSMENT_DATE", moment(this.state.newLeadData.SITE_ASSESSMENT_DATE, "YYYYMMDD").format('YYYY-MM-DD'), "date")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>SA Slot</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SA_SLOT}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("SA_SLOT", this.state.mappingData.saslots ? this.state.mappingData.saslots : [], "SLOT_VALUE", this.state.newLeadData.SA_SLOT)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>WhatsApp SA</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.WHATSAPP_SA}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("WHATSAPP_SA", this.state.newLeadData.WHATSAPP_SA, this.state.active)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>SA Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SA_TYPE}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("SA_TYPE", this.state.newLeadData.SA_TYPE, this.state.saType)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>SA Paid</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SITE_ASSESSMENT_PAID}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("SITE_ASSESSMENT_PAID", this.state.newLeadData.SITE_ASSESSMENT_PAID, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>SA Schedule Date</td>
                      <td><span style={!this.state.leadData.saScheduleDate && this.state.edit ? style.hide : style.show}>{this.state.leadData.saScheduleDate ? moment(this.state.leadData.saScheduleDate, "YYYYMMDD").format("DD MMM, YYYY") : ''}</span>
                        <span style={!this.state.leadData.saScheduleDate && this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("saScheduleDate", moment(this.state.newLeadData.saScheduleDate, "YYYYMMDD").format("YYYY-MM-DD"), "date")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Quote Id</td>

                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.QUOTE_ID}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("QUOTE_ID", this.state.newLeadData.QUOTE_ID, "text")}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Sales Date</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SALES_DATE ? moment(this.state.leadData.SALES_DATE).format("DD MMM, YYYY") : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("SALES_DATE", moment(this.state.newLeadData.SALES_DATE, "YYYYMMDD").format('YYYY-MM-DD'), "date")}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>MPP</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.MPP}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("MPP", this.state.newLeadData.MPP, this.state.roofOwnership)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Reason For Verified</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.REASON_FOR_VERIFIED}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("REASON_FOR_VERIFIED", this.state.newLeadData.REASON_FOR_VERIFIED, this.state.mappingData.reasonforverified)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Product</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.PRODUCT || ""}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("PRODUCT", this.state.newLeadData.PRODUCT, this.state.product)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Sanctioned Load</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SANCTIONED_LOAD}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("SANCTIONED_LOAD", this.state.newLeadData.SANCTIONED_LOAD, "number")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>System Size</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.systemSize}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("systemSize", this.state.newLeadData.systemSize, "number")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Final Price</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.finalPrice}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("finalPrice", this.state.newLeadData.finalPrice, "number")}
                        </span>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>SA Count</td>

                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SITE_ASSESSMENT_COUNT}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("SITE_ASSESSMENT_COUNT", this.state.newLeadData.SITE_ASSESSMENT_COUNT, "text")}
                        </span>
                      </td>
                    </tr> */}
                  </table>
                </div>
              </div>
            </div>

            {/* <div className="comments" style={{ "overflowY": "scroll", "maxHeight": "300px" }}>
                          <p>Comments:</p>
                          {
                              this.state.comments.map((comment, i) => {
                                  return (
                                      <p className="comment" style={{ backgroundColor: "#ffffff", margin: "15px", width: "40.5%", borderRadius: "5px" }}>
                                          <p className="commentBy" style={{ padding: "3px", marginLeft: "10px" }}><strong>user</strong>
                                          </p>
                                          <div className="commentText" style={{ padding: "3px", fontFamily: "sans-serif", fontSize: "15px", marginLeft: "10px", whiteSpace: "pre-wrap" }}>
                                              {comment}
                                          </div>
                                      </p>
                                  )
                              })
                          }
                      </div> */}





            {/* <div className="comments" style={{ "overflowY": "scroll","maxHeight": "300px" }}>

                          {
                              (this.state.leadData.comments?this.state.leadData.comments:[]).slice(0).reverse().map((comment, i) => {

                                  return (
                                      <div className="card" style={{ borderRadius:"10px", margin:"10px 0px 0px 15px", width: "40.3%"}}>
                                          <div className="card-body" style={{padding:"0.2rem"}}>
                                              <div className="card-title">
                                                 <strong>{comment.commentedBy + " "}</strong>
                                                  <div style={{float:"right"}}>{new Date(parseInt(comment.timestamp)).toLocaleDateString()}</div>
                                              </div>
                                              {comment.commentBody}
                                          </div>
                                      </div>
                                  )
                              })
                          }
                      </div> */}
            <div className="row" style={{ padding: "0px" }}>
              <div className="topbar-section col-9">
                <div className="container">
                  <div>
                    <h4><b>Comments</b></h4>
                  </div>
                  <hr></hr>
                  <div>
                    <div>
                      {(this.state.leadData.comments || []).map(comment => {
                        return <div><b>{comment.commentedBy.split('@')[0].split('.')[0] + ' ' + comment.commentedBy.split('@')[0].split('.')[1]} - {moment(+(comment.timestamp)).format('DD/MM/YYYY hh:mm a')}</b> - {comment.commentBody}</div>
                      })}
                    </div>
                    { !sourceBasedAccess ? <div className="row" style={{ width: "80%", margin: "0px", padding: "0px" }}>
                      <div className="col-md-10 form-group"  >
                        <label for="newComment"></label>
                        <textarea placeholder="Add New Comment" className="form-control" rows="2" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
                      </div>
                      <div style={{ marginTop: "46px", marginLeft: '0px' }} className="col-md-1 form-group">
                        <button className="btn btn-success" onClick={(e) => this.commentSubmit(e)}>Submit</button>
                      </div>
                    </div> : "" }
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="scrap-variables">
            <div className="row">
              <div className="col-md-1">
                <p><b>Source :</b></p>
              </div>
              <div className="col-md-3">
                <span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.SOURCE_ID}</span>
                <span style={this.state.edit ? style.show : style.hide}>
                  {this.selectNewType("SOURCE_ID", this.state.mappingData.source ? this.state.mappingData.source : [], "source_name", this.state.newLeadData.SOURCE_ID)}
                </span>
              </div>
              <div className="col-md-1">
                <p><b>Added On : </b></p>
              </div>
              <div className="col-md-3">
                <span style={this.state.edit ? style.hide : style.show} >{this.state.leadData.ADDED_ON ? moment(this.state.leadData.ADDED_ON).format("DD MMM, YYYY") : ''}
                </span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group">
                  {this.inputType("ADDED_ON", moment(this.state.newLeadData.ADDED_ON, "YYYYMMDD").format('YYYY-MM-DD'), "date")}
                </span>
              </div>

            </div>
          </div> */}

          <div className="scrap-variables">
            <h6 style={{ paddingLeft: "10px" }}><b>UTM Variables | {this.state.showUtmVariables ? <i className="fa fa-eye-slash" style={{ cursor: "pointer" }} onClick={this.handleUtmDivToggle}></i> : <i className="fa fa-eye" onClick={this.handleUtmDivToggle} style={{ cursor: "pointer" }}></i>}</b></h6>
            <hr style={this.state.showUtmVariables ? style.show : style.hide}></hr>

            <div className="row utmVariables" style={this.state.showUtmVariables ? style.show : style.hide}>
              <div className="col-md-1">
                <p> Source </p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_SOURCE}
              </div>
              <div className="col-md-1">
                <p>Campaign </p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_CAMPAIGN}
              </div>
              <div className="col-md-1">
                <p> Content </p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_CONTENT}
              </div>
              <div className="col-md-1">
                <p> Medium </p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_MEDIUM}
              </div>
              <div className="col-md-1">
                <p> Term</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_TERM}
              </div>
              <div className="col-md-1">
                <p>Adposition</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.adposition}
              </div>
              <div className="col-md-1">
                <p>Placement</p>
              </div>
              <div className="col-md-2">
                <p>{this.state.leadData.placement}</p>
              </div>
              <div className="col-md-1">
                <p>Loc physical ms</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.loc_physical_ms}
              </div>
              <div className="col-md-1">
                <p>Loc interest ms</p>
              </div>
              <div className="col-md-2">
                <p>{this.state.leadData.loc_interest_ms}</p>
              </div>
              <div className="col-md-1">
                <p>Device</p>
              </div>
              <div className="col-md-2">
                <p>{this.state.leadData.device}</p>
              </div>
              <div className="col-md-1">
                <p>Keyword</p>
              </div>
              <div className="col-md-2">
                <p>{this.state.leadData.keyword}</p>
              </div>
            </div>
          </div>

          <div>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"><button><i className="fa fa-refresh" onClick={this.handlePageRefresh}></i></button></span>
                    <h4><b>History</b></h4>
                    <hr></hr>
                  </div>
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.state.leadData.history}
                    columns={this.historyColumns}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }


  }
}

export default leadsView;
