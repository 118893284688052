import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { vendorLedgerColumns } from '../../config/column_fields';
import Select from 'react-select';
import { CSVLink } from 'react-csv';

class ProcurementVendorLedger extends Component {

    constructor() {
        super();
        this.state = {
            vendorList: [],
            ledgerCsvColumns: [],
            filters: {
                vendorId: []
            }
        };
    }

    componentWillMount() {
        this.props.fetchVendorList();
    }

    componentDidMount() {
        let ledgerCsvColumns = vendorLedgerColumns.map(row => ({label: row.Header, key: row.accessor}));
        this.setState({ ledgerCsvColumns });
    }

    handleVendorChange = (e) => {
        this.setState({
            filters: {
                ...this.state.filters,
                vendorId: [e.value]
            }
        })
    }

    handleDateFilterChange = (e) => {
        let { name, value } = e.target;
        let { filters = {} } = this.state;
        if (!filters.date) filters.date = {};
        filters["date"][name] = value;
        this.setState({ filters });
    }

    handleSubmitClick = () => {
        let { filters = {} } = this.state;
        console.log("submit click", filters);
        this.props.fetchVendorLedger(filters);
    }

    render() {

        let { handleSubmitClick } = this;
        let { ledgerData = [], vendorList = [] } = this.props;
        let { ledgerCsvColumns = [] } = this.state;

        return (
            <div className="procurementVendorLedger">
                <div className="row">
                    <div className="col-12 col-md-2 addVendorInput">
                        <div className="form-group">
                            <div className="mb-3">
                                <label>Vendor</label>
                                <Select
                                    isSearchable={true}
                                    placeholder={"Vendor"}
                                    options={vendorList.map(row => ({ label: `${row.id} ${row.companyName}`, value: row.id }))}
                                    onChange={(e) => this.handleVendorChange(e)}
                                    isMulti={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 addVendorInput">
                        <div className="form-group">
                            <div className="mb-3">
                                <label>Start Date</label>
                                <input className="form-control customInput" type={'date'} name={'startDate'} placeholder={'Start Date'} onChange={(e) => this.handleDateFilterChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 addVendorInput">
                        <div className="form-group">
                            <div className="mb-3">
                                <label>End Date</label>
                                <input className="form-control customInput" type={'date'} name={'endDate'} placeholder={'End Date'} onChange={(e) => this.handleDateFilterChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 addVendorInput">
                        <div className="form-group">
                            <div className="mb-3">
                                <label>&nbsp;</label>
                                <button onClick={handleSubmitClick} className="form-control btn btn-info">Apply Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
                <CSVLink filename={"Ledger Data.csv"} data={ledgerData || []} headers={ledgerCsvColumns || []} type="button" className="btn btn-light mb-3">
                    Download <i className="fa fa-download"></i>
                </CSVLink>
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={ledgerData}
                    columns={vendorLedgerColumns}
                    defaultSorted={[{ id: "id", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    defaultPageSize={10}
                />
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementVendorLedger);