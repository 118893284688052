import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import "../../styles/ProcurementPortalStyles.css";
import ProcurementAddVendor from '../../components/zunsolar/ProcurementAddVendor';
import ProcurementVendorList from '../../components/zunsolar/ProcurementVendorList';
import ProcurementVendorLedger from '../../components/zunsolar/ProcurementVendorLedger';
import ProcurementAddPayout from '../../components/zunsolar/ProcurementAddPayout';
import CreateProcurement from '../../components/zunsolar/ProcurementCreate';
import ProcurementTransactions from '../../components/zunsolar/ProcurementTransactions';
import ProcurementSupply from '../../components/zunsolar/ProcurementSupply';
import { vendorProductsList } from '../../config/column_fields';
import { addVendor, fetchVendorList, fetchProcurementPayments, fetchMappingProcurement, fetchProcurementPaymentsById, procurementStatusChange, createProcurement, downloadFile, updateVendorData, fetchVendorLedger, addPayout, deleteVendor, fetchSupplyDetails, createDelivery } from '../../actions/procurementPortalActions';
import { fetchMapping } from '../../actions/zunsolarOrderTrackerActions';


class ProcurementPortal extends Component {

    constructor() {
        super();
        this.state = {
            selectedTab: "supply",
            banksMapping: ['IDFC 2', 'HDFC', 'RBL', 'CITI', 'Cashfree', 'TCS'],
            statusMapping: ["Initiated", "Approved0", "Approved1", "Rejected"]
        };
    }

    componentWillMount() {
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
    }

    handleOnViewChange = (e) => {
        let { name = '' } = e.target;
        this.setState({ selectedTab: name });
    }

    TabsComponent = ({ name, label }) => {
        let { selectedTab = '' } = this.state;
        let { handleOnViewChange } = this;
        return <button className={`tabButtons btn btn-sm ${selectedTab === name ? "btn-info" : "btn-outline-info"}`} name={name} onClick={handleOnViewChange}>{label}</button>;
    }

    render() {

        let { TabsComponent, state, props } = this;
        let { selectedTab } = state;
        let { addVendor, vendorList, fetchVendorList, fetchProcurementPayments, paymentsData, fetchMapping, zunsolarCategoryMapping, fetchProcurementPaymentsById, paymentsDataByOrderId, fetchMappingProcurement, procurementMapping, procurementStatusChange, createProcurement, downloadFile, updateVendorData, ledgerData, fetchVendorLedger, addPayout, deleteVendor, supplyData, fetchSupplyDetails, createDelivery } = props;

        return (
            <div>
                <Header />
                <div className={`container-fluid procurementMainDiv`}>
                    <div className="tabComponent">
                        <TabsComponent name="addVendor" label="Add Vendor" />
                        <TabsComponent name="vendorList" label="Vendor List" />
                        <TabsComponent name="createProcurement" label="Add Payment" />
                        <TabsComponent name="transactions" label="Transactions" />
                        <TabsComponent name="ledger" label="Ledger" />
                        <TabsComponent name="addPayout" label="Add Payout" />
                        <TabsComponent name="supply" label="Supply" />
                    </div>
                    {
                        selectedTab === "addVendor" ? <ProcurementAddVendor
                            addVendor={addVendor}
                            vendorProductsList={vendorProductsList}
                        /> : ""
                    }
                    {
                        selectedTab === "vendorList" ? <ProcurementVendorList
                            fetchVendorList={fetchVendorList}
                            vendorList={vendorList}
                            downloadFile={downloadFile}
                            updateVendorData={updateVendorData}
                            deleteVendor={deleteVendor}
                            vendorProductsList={vendorProductsList}
                        /> : ""
                    }
                    {
                        selectedTab === "ledger" ? <ProcurementVendorLedger
                            fetchVendorList={fetchVendorList}
                            vendorList={vendorList}
                            fetchVendorLedger={fetchVendorLedger}
                            ledgerData={ledgerData}
                        /> : ""
                    }
                    {
                        selectedTab === "addPayout" ? <ProcurementAddPayout
                            fetchVendorList={fetchVendorList}
                            vendorList={vendorList}
                            addPayout={addPayout}
                        /> : ""
                    }
                    {
                        selectedTab === "createProcurement" ? <CreateProcurement
                            fetchVendorList={fetchVendorList}
                            vendorList={vendorList}
                            fetchProcurementPayments={fetchProcurementPayments}
                            paymentsData={paymentsData}
                            fetchMappingZunsolar={fetchMapping}
                            zunsolarCategoryMapping={zunsolarCategoryMapping}
                            banksMapping={this.state.banksMapping}
                            statusMapping={this.state.statusMapping}
                            fetchProcurementPaymentsById={fetchProcurementPaymentsById}
                            paymentsDataByOrderId={paymentsDataByOrderId}
                            fetchMappingProcurement={fetchMappingProcurement}
                            procurementMapping={procurementMapping}
                            createProcurement={createProcurement}
                        /> : ""
                    }
                    {
                        selectedTab === "transactions" ? <ProcurementTransactions
                            fetchProcurementPayments={fetchProcurementPayments}
                            paymentsData={paymentsData}
                            fetchMappingZunsolar={fetchMapping}
                            zunsolarCategoryMapping={zunsolarCategoryMapping}
                            statusMapping={this.state.statusMapping}
                            procurementStatusChange={procurementStatusChange}
                            downloadFile={downloadFile}
                        /> : ""
                    }
                    {
                        selectedTab === "supply" ? <ProcurementSupply
                            fetchProcurementPayments={fetchProcurementPayments}
                            paymentsData={paymentsData}
                            fetchMappingZunsolar={fetchMapping}
                            zunsolarCategoryMapping={zunsolarCategoryMapping}
                            statusMapping={this.state.statusMapping}
                            procurementStatusChange={procurementStatusChange}
                            supplyData={supplyData}
                            fetchSupplyDetails={fetchSupplyDetails}
                            downloadFile={downloadFile}
                            createDelivery={createDelivery}
                        /> : ""
                    }
                </div>
            </div>
        )
    }

}

function mapStateToProps({ procurementPortal, zunSolarOrderTracker }) {
    let { vendorList = [], ledgerData = [], paymentsData = [], paymentsDataByOrderId = [], procurementMapping = {}, supplyData = [] } = procurementPortal;
    let { mapping = {} } = zunSolarOrderTracker || {};
    return {
        vendorList,
        mapping,
        ledgerData,
        paymentsData,
        zunsolarCategoryMapping: mapping.category || {},
        paymentsDataByOrderId,
        procurementMapping,
        supplyData
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, addVendor, fetchVendorList, fetchProcurementPayments, fetchMapping, fetchMappingProcurement, fetchProcurementPaymentsById, procurementStatusChange, createProcurement, downloadFile, updateVendorData, fetchVendorLedger, addPayout, deleteVendor, fetchSupplyDetails, createDelivery }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementPortal);