import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { zunpulseOrderColumns, zunpulseOrdersCsvColumns } from '../../config/column_fields';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { fetchAllOrders, updateOrderStatus, editBulkLeads } from '../../actions/zunpulseOrderTrackingActions.js';
import { fetchAllMapping } from '../../actions/fetchAllMapping';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

class ZunpulseOrders extends Component {

    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            ordersData: [],
            ordersDataCopy: [],
            tableColumns: [],
            editStatus: {},
            editedOrderStatus: {},
            mapping: {},
            stateChosen: [],
            cityChosen: [],
            paymentStatusChosen: [],
            paymentStatusArr: [],
            deliveryStatusChosen: [],
            sourceChosen: [],
            responsibleChosen: [],
            modifiedByChosen: [],
            editDate: {},
            selectedOrderIds: {},
            bulkAssignObj: {},
            employeeDetails: {},
            responsibleOptions: [],
            modifiedByOptions: [],
            highValueChosen: [],
            stageChosen: []
        };
        this.statusMap = ["Not Procured", "Procured", "Shipped", "Delivered", "Cancelled", "Returning (Before Delivery)", "Returning (After Delivery)", "Returned", "Replacement", "Truncated"];
        this.paymentStatusMap = ["success", "aborted", "failure"];
        this.sourceMap = ["app", "web", "manual", "Flipkart", "Snapdeal", "Amazon", "Cred", "City sales", "B2B"];
        this.stageMap = ["New", "Warm", "Hot", "Cold", "Duplicate", "Bought"];
    }

    componentWillMount() {
        let viewList = ["employees"]
        this.props.fetchAllMapping(viewList);
        this.props.fetchAllOrders();
        let tableColumns = zunpulseOrderColumns.map(val => {
            if (val.accessor == 'status') {
                val.Cell = row => {
                    return (<div>{this.statusMap[row.value]}</div>)
                }
            }
            // if (val.accessor == 'orderDate') {
            //     val.Cell = row => {
            //         return ((row.original.status == "0" || row.original.status == "4" || row.value == 0 ? "N/A" : moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : ""))
            //     }
            // }
            // if (val.accessor == 'shippingDate') {
            //     val.Cell = row => {
            //         return ((row.original.status == "0" || row.original.status == "4" || row.original.status == "1" || row.value == 0) ? "N/A" : moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "")
            //     }
            // }
            // if (val.accessor == "deliveryDate") {
            //     val.Cell = row => {
            //         return ((row.original.status == "0" || row.original.status == "4" || row.original.status == "1" || row.original.status == "2" || row.value == 0) ? "N/A" : moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "")
            //     }
            // }
            if (val.accessor === "checkbox") {
                val.Header = row => {
                    return (
                        <div>
                            <input type="checkbox" onChange={(e) => { this.handleAllCheckbox(e, row) }} checked={this.state.selectAll ? 'checked' : ''} ></input>
                        </div>
                    )
                };
                val.Cell = row => (
                    <div>
                        <input type="checkbox" onChange={(e) => { this.handleCheckbox(e, row.original.orderId, row.original, row.index) }} checked={this.state.selectedOrderIds[row.original.orderId] ? 'checked' : ''} ></input>
                    </div>
                );
                val.Filter = () => {
                    let selectedCheckboxCount = Object.keys(this.state.selectedOrderIds || {}).filter((val) => this.state.selectedOrderIds[val] == 1);
                    return (
                        <div>{selectedCheckboxCount.length}</div>
                    )
                }
            };
            return val;
        });
        this.setState({
            tableColumns
        })
    }

    componentWillReceiveProps(props) {
        // console.log("props", props)
        let { zunpulseOrders = {}, mapping = {}, allMapping = {} } = props;
        let state = mapping ? Object.keys(mapping).map((val) => ({ value: val, label: val })) : [];
        let paymentStatusMap = this.paymentStatusMap.map((val) => ({ value: val, label: val }));
        // console.log("Object.values(zunpulseOrders)", Object.values(zunpulseOrders));
        let tableData = Object.values(zunpulseOrders || {});
        tableData.forEach((order) => {
            order.viewOrderDate = order.orderDate ? moment(order.orderDate).format('YYYY-MM-DD') : "NA";
            order.viewShippingDate = order.shippingDate ? moment(order.shippingDate).format('YYYY-MM-DD') : "NA";
            order.viewDeliveryDate = order.deliveryDate ? moment(order.deliveryDate).format('YYYY-MM-DD') : "NA";
            order.viewStatus = this.statusMap[order.status];
            order.viewCustomerOrderDate = order.customerOrderDate ? moment(order.customerOrderDate).format('YYYY-MM-DD') : "NA";
        });
        let employeeDetails = JSON.parse(localStorage.getItem("userObj"))
        let responsibleOptions = allMapping.employees ? allMapping.employees.map((val) => ({value: val.e_name , label: val.e_name})) : [];
        let modifiedByOptions = allMapping.employees ? allMapping.employees.map((val) => ({value: val.e_email.replace(/(^"|"$)/g, '') , label: val.e_name})) : [];
        this.setState({
            ordersData: tableData,
            // ordersDataCopy: zunpulseOrders,
            mapping: mapping,
            state: state,
            paymentStatusArr: paymentStatusMap,
            ordersDataCopy: tableData,
            employeeDetails: employeeDetails,
            responsibleOptions: responsibleOptions,
            modifiedByOptions: modifiedByOptions
        })
    }

    //=======================================//
    selectInputDiv = (row, options) => {
        return (
            <div>
                <select
                    onKeyUp={(e) => { this.handleOnKeyPress(e, row) }}
                    onChange={(e) => { this.handleOnSelectChange(e, row) }}
                    className="form-control" name={`${row.original.key}`}
                    defaultValue={row.value ? row.value : ""}>
                    {Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)}
                </select>
            </div>
        )
    }

    // inputTypeDate = (name, row) => {
    //     return (<input type="date" className="form-control" name={name} onChange={(e) => this.handleInputChange(e)} onKeyUp={(e) => { this.handleOnKeyPress(e, row, "orderDate") }}></input>
    //     )
    // }

    // handleOnKeyPress = (e, row) => {
    //     let { id } = row.original;
    //     let { ordersData, editedOrderStatus } = this.state;
    //     console.log("editedOrderStatus", editedOrderStatus)
    //     let value = editedOrderStatus[id];
    //     if (e.keyCode === 13 && !e.shiftKey) {
    //         // console.log("updateOrderStatus", ordersData, id, value);
    //         this.props.updateOrderStatus(ordersData, id, value);
    //         this.setState({
    //             editStatus: {
    //                 ...this.state.editStatus,
    //                 [id]: false
    //             }
    //         })
    //     }
    //     if (e.keyCode === 27) {
    //         this.setState({
    //             editStatus: {
    //                 ...this.state.editStatus,
    //                 [id]: false
    //             }
    //         })
    //     }

    // }

    handleOnSelectChange = (e, row) => {
        let { id } = row.original;
        let { value } = e.target;
        console.log("handleOnSelectChange", id, value)
        this.setState({
            editedOrderStatus: {
                ...this.state.editedOrderStatus,
                [id]: value
            }
        }, () => {
            console.log("state editedOrder", this.state.editedOrderStatus)
        })
        // console.log(e.target.value, row.original.id);
    }

    handleOnTableCellClick = (row) => {
        // if (name == "orderDate") {
        //     let { id, orderedDate } = row.original;
        //     this.setState({
        //         editDate: {
        //             ...this.state.editDate,
        //             [id]: true
        //         }
        //     })
        // }
        // if (name == "status") {
        let { id, status } = row.original;
        console.log(id, status)
        this.setState({
            editStatus: {
                ...this.state.editStatus,
                [id]: true
            },
            editedOrderStatus: {
                ...this.state.editedOrderStatus,
                [id]: status
            }
        }, () => { console.log("editStatus", this.state.editStatus, this.state.editedOrderStatus) })
        // }
    }

    inputType = (name, value, type, placeholder) => {
        return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    selectType = (options, name, placeholder, value) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { this.handleMultiSelectChange(e, name) }}
                    name={name}
                    options={options}
                    value={value}
                    placeholder={placeholder}
                />
            </div>
        )
    }

    handleMultiSelectChange = (selectedOption, name) => {
        // console.log(selectedOption, name);
        if (name == "stateChosen") {
            let cityList = [];
            let { mapping = {} } = this.props;
            (selectedOption || []).map(obj => {
                cityList = [...cityList, ...(mapping[obj.value] || [])];
            })
            this.setState({
                [name]: selectedOption,
                city: cityList.map(city => ({ label: city, value: city }))
            })
            return;
        }
        this.setState({
            [name]: selectedOption
        });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        value = this.checkSelectString(value);
        value = this.removingNullKeys(value);
        // console.log(e.target.value);
        this.setState({
            [name]: value
        })
    }

    onApplyFilter = () => {
        let startDate = this.state.startDate ? moment(this.state.startDate).format('x') : 0;
        let endDate = this.state.endDate ? moment(this.state.endDate).endOf('day').format('x') : 9999999999999;
        let nextactionendDate = this.state.nextactionendDate ? moment(this.state.endDate).endOf('day').format('x') : 9999999999999;
        let nextactionstartDate = this.state.nextactionstartDate ? moment(this.state.nextactionstartDate).format('x') : 0;
        let stateChosen = this.state.stateChosen ? this.state.stateChosen.map(val => val.value) : [];
        let cityChosen = this.state.cityChosen ? this.state.cityChosen.map(val => val.value) : [];
        let paymentStatusChosen = this.state.paymentStatusChosen ? this.state.paymentStatusChosen.map(val => val.value) : [];
        let deliveryStatusChosen = this.state.deliveryStatusChosen ? this.state.deliveryStatusChosen.map((val) => val.value) : [];
        let sourceChosen = this.state.sourceChosen ? this.state.sourceChosen.map((val) => val.value) : [];
        let responsibleChosen = this.state.responsibleChosen ? this.state.responsibleChosen.map((val) => val.value) : [];
        let modifiedByChosen = this.state.modifiedByChosen ? this.state.modifiedByChosen.map((val) => val.value) : [];
        let stageChosen = this.state.stageChosen ? this.state.stageChosen.map((val) => val.value) : [];
        let highValueChosen = this.state.highValueChosen ? this.state.highValueChosen.map((val) => val.value) : [];
        let tableData = JSON.parse(JSON.stringify(this.state.ordersData));
        let filteredTable = (tableData).filter(obj => {
            let addedOn = obj.customerOrderDate ? new Date(obj.customerOrderDate).getTime() : 0;
            let nextactionOn = obj.nextActionDate ? new Date(obj.nextActionDate).getTime() : 0;
            return ((addedOn >= startDate && addedOn <= endDate) && (nextactionOn >= nextactionstartDate && nextactionOn <= nextactionendDate) && (responsibleChosen.length > 0 ? responsibleChosen.includes(obj.responsible) : true) && (modifiedByChosen.length > 0 ? modifiedByChosen.includes(obj.lastUpdatedBy) : true) && (stateChosen.length > 0 ? stateChosen.includes(obj.deliveryAddress ? obj.deliveryAddress.state : "") : true) && (cityChosen.length > 0 ? cityChosen.includes(obj.deliveryAddress.city) : true) && (paymentStatusChosen.length > 0 ? paymentStatusChosen.includes(obj.paymentStatus) : true)  && (deliveryStatusChosen.length > 0 ? deliveryStatusChosen.includes(this.statusMap[obj.status]) : true) && (sourceChosen.length > 0 ? sourceChosen.includes(obj.source) : true) && (stageChosen.length > 0 ? stageChosen.includes(obj.stage) : true) && (highValueChosen.length > 0 ? highValueChosen.includes(obj.highValue) : true))
        });
        // console.log(filteredTable);
        let filteredTableMap = {}
        filteredTable.map((obj) => {
            filteredTableMap[obj.id] = obj
        })
        // console.log(filteredTableMap)
        this.setState({
            ordersDataCopy: filteredTable,
        })
    }

    checkSelectString = (str) => {
        if (str == "not selected") {
            return str = ""
        }
        else {
            return str
        }
    }

    removingNullKeys = (obj) => {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
                delete obj[propName];
            }
        }
        return obj
    }

    //==========Bulk edit functions========//

    handleAllCheckbox = (e, row) => {
        let page = this.reactTable.current.state.page;
        let pageSize = this.reactTable.current.state.pageSize;
        let start = page * pageSize;
        let end = (page * pageSize + pageSize);
        let actualEnd = (row.data.length > end) ? end : row.data.length;
        let data = row.data;
        let selectedOrderIds = {};
        if (!this.state.selectAll) {
            for (let i = start; i <= (actualEnd - 1); i++) {
                selectedOrderIds[data[i].orderId] = 1
            }
        }
        this.setState({
            selectedOrderIds: { ...selectedOrderIds },
            selectAll: !(this.state.selectAll)
        })
    };

    handleCheckbox = (e, key, row, index) => {
        if (e.nativeEvent.shiftKey == true) {
            let selectedOrderIds = { ...this.state.selectedOrderIds }
            let sortedArray = this.reactTable.current.state.sortedData;
            let lastSelectedIndex = -1;
            console.log(sortedArray);
            sortedArray.map((lead, index) => {
                if (selectedOrderIds[lead.orderId]) {
                    lastSelectedIndex = index;
                }
            })
            if (lastSelectedIndex == -1) {
                selectedOrderIds[key] = 1
            } else {
                let currentIndex = sortedArray.findIndex(row => row.orderId == key);
                let [start, end] = currentIndex > lastSelectedIndex ? [lastSelectedIndex, currentIndex] : [currentIndex, lastSelectedIndex];
                for (let i = start; i <= end; i++) {
                    selectedOrderIds[sortedArray[i].orderId] = 1;
                }
            }
            this.setState({
                selectedOrderIds: selectedOrderIds
            })
        } else {
            let status = this.state.selectedOrderIds[key];
            let selectedIdCopy = JSON.parse(JSON.stringify(this.state.selectedOrderIds))
            let selectedOrderIds = { ...selectedIdCopy, [key]: status ? 0 : 1, };
            this.setState({
                selectedOrderIds: selectedOrderIds,
                selectAll: this.state.selectAll && status ? false : this.state.selectAll
                // selectedOrderIds: [...selectedOrderIds]
            }, () => {
                console.log(selectedOrderIds)
            })
        }
    }

    selectNewType = (name, options, value) => {
        return (
            <div style={{ width: '100%' }} >
                <select style={{ height: '35px' }}
                    onChange={(e) => { this.handleSelectChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option>not selected</option>
                    {(options ? options : []).map((e) => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    newInputType = (name, value, type, placeholder) => {
        return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleSelectChange(e)} />
    }

    handleSelectChange = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
        value = this.checkSelectString(value);
        if (name == "status") {
            value = this.statusMap.indexOf(value)
        }
        this.setState({
            bulkAssignObj: {
                ...this.state.bulkAssignObj,
                [name]: value
            }
        }, () => {
            console.log("bulk", this.state.bulkAssignObj)
        })
    }

    handleBulkAssign = () => {
        let { ordersDataCopy, selectedOrderIds, bulkAssignObj } = this.state;
        let bulkAssignObjCopy = JSON.parse(JSON.stringify(bulkAssignObj));
        // console.log("selectedorderids", selectedOrderIds, "bulkAssignObj", bulkAssignObjCopy, "ordersDataCopy", ordersDataCopy);
        this.props.editBulkLeads(ordersDataCopy, selectedOrderIds, bulkAssignObjCopy, this.state.employeeDetails.username);
    }

    //==========================//

    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        };

        let { ordersData, tableColumns } = this.state;
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <div style={{ marginTop: "80px", backgroundColor: "#f7f7f7" }} className="container-fluid">
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontWeight: "bold" }}>Order View</h2>
                    </div>
                    <div className="row leadtransferTopbarSection">
                        <div className="col-md-2">
                            <label>Added On Start Date </label>
                            {this.inputType("startDate", this.state.startDate, "date")}
                        </div>
                        <div className="col-md-2">
                            <label>Added On End Date</label>
                            {this.inputType("endDate", this.state.endDate, "date")}
                        </div>
                        <div className="col-md-2">
                            <label>State</label>
                            {this.selectType(this.state.state, "stateChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <label>City</label>
                            {this.selectType(this.state.city, "cityChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <label>Payment Status</label>
                            {this.selectType(this.state.paymentStatusArr, "paymentStatusChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <label>Delivery Status</label>
                            {this.selectType(this.statusMap.map((val) => ({ value: val, label: val })), "deliveryStatusChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <label>Source</label>
                            {this.selectType(this.sourceMap.map((val) => ({ value: val, label: val })), "sourceChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <label>Responsible</label>
                            {this.selectType(this.state.responsibleOptions, "responsibleChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <label>Modified By</label>
                            {this.selectType(this.state.modifiedByOptions, "modifiedByChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <label>Next Action Start Date </label>
                            {this.inputType("nextactionstartDate", this.state.nextactionstartDate, "date")}
                        </div>
                        <div className="col-md-2">
                            <label>Next Action End Date</label>
                            {this.inputType("nextactionendDate", this.state.nextactionendDate, "date")}
                        </div>
                        <div className="col-md-2">
                            <label>Stage</label>
                            {this.selectType(this.stageMap.map((val) => ({ value: val, label: val })), "stageChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <label>High Value</label>
                            {this.selectType(["Yes","No"].map((val) => ({ value: val, label: val })), "highValueChosen", "--Select--")}
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-success btn-sm leadTransferSubmitButton" onClick={(e) => { this.onApplyFilter() }}>Apply Filter</button>
                        </div>
                    </div>
                    <div class="col-md-12" style={{ textAlign: "right" }}>
                        {/* <span style={{ paddingRight: "30px" }}><b>Total orders</b> : <b>{this.state.ordersData.length}</b></span> */}
                        <CSVLink style={{ paddingRight: "30px" }} filename={"paymentData.csv"} data={this.state.ordersDataCopy ? this.state.ordersDataCopy : []} headers={zunpulseOrdersCsvColumns} type="button" className="btn btn-light btn-lg">
                            Download CSV <i className="fa fa-download"></i>
                        </CSVLink>
                        <Link style={{ paddingRight: "30px" }} to="/zunpulse/createorder/New" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> New Order</button></Link>
                    </div>
                    <div className="row" style={{ marginTop: "25px" }} style={Object.values(this.state.selectedOrderIds).includes(1) ? style.show : style.hide}>
                        <div className="col-md-2">
                            <label>Order Status</label>
                            {this.selectNewType("status", this.statusMap, this.state.bulkAssignObj.status ? this.statusMap[this.state.bulkAssignObj.status] : "")}
                        </div>
                        <div className="col-md-2">
                            <label>Order Date</label>
                            {this.newInputType("orderDate", this.state.bulkAssignObj.orderDate, "date", "Order Date")}
                        </div>
                        <div className="col-md-2">
                            <label>Shipping Date</label>
                            {this.newInputType("shippingDate", this.state.bulkAssignObj.shippingDate, "date", "Shipping Date")}
                        </div>
                        <div className="col-md-2">
                            <label>Delivery Date</label>
                            {this.newInputType("deliveryDate", this.state.bulkAssignObj.deliveryDate, "date", "Delivery Date")}
                        </div>
                        <div className="col-md-2">
                            <label>Return Date</label>
                            {this.newInputType("returnDate", this.state.bulkAssignObj.returnDate, "date", "Return Date")}
                        </div>
                        <div className="col-md-1" style={{ marginTop: "32px" }}>
                            <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkAssign(e) }}>Submit</button>
                        </div>
                    </div>
                    <div class="saview-table-section">
                        <ReactTable
                            ref={this.reactTable}
                            filterable={true}
                            showPagination={true}
                            sortable={true}
                            data={(this.state.ordersDataCopy)}
                            columns={tableColumns}
                            defaultSorted={[{ id: "customerOrderData", desc: true }]}
                            style={{ height: "90vh", textAlign: 'center' }}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    // console.log("state", state)
    let { zunpulseOrders } = state;
    return {
        zunpulseOrders: zunpulseOrders.orders,
        mapping: zunpulseOrders.mapping,
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllOrders, updateOrderStatus, editBulkLeads, fetchAllMapping }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseOrders);
