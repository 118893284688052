import { SEARCH_START, SEARCH_SUCCESS } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case SEARCH_START: {
            return {};
        }
        case SEARCH_SUCCESS: {
            return { ...state, tableData: action.payload }
        }
        default:
            return state;
    }
}