import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { fetchCouponData } from '../../actions/couponActions';
import Header from '../../components/HeaderComponent';
import { couponDetailColumnConfig } from '../../config/column_fields';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Redirect } from 'react-router-dom';



class ZunpulseCouponView extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            tableData: [],
            filters: {},
            userObj: {},
        }
    }


    componentWillMount() {
       
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
        console.log('component will mount');
        this.props.fetchCouponData();

    }

    componentWillReceiveProps(props) {

    console.log('table Data', props.couponsData);
        this.setState({
            tableData: props.couponsData
        })
    }
    




    render() {
        if(!((this.state.userObj.access || []).includes("Website-Admin"))) {
            return <Redirect to={"/"} />
        }
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <div style={{ "marginTop": 80, backgroundColor: "#f7f7f7" }} className="container-fluid">
                    {/* <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontWeight: "bold" }}>Warranty Details</h2>
                    </div>
                    <div className="row leadtransferTopbarSection">
                        <div className="col-md-3">
                            <label>Added On Start</label>
                            {this.filtersInputType("startDate", "Registered On", 'date', this.handleInputDateChangeFilters, 'created')}
                        </div>
                        <div className="col-md-3">
                            <label>Added On End</label>
                            {this.filtersInputType("endDate", "Registered On", 'date', this.handleInputDateChangeFilters, 'created')}
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-success btn-sm leadTransferSubmitButton" onClick={(e) => { this.onApplyFilter() }}>Apply Filter</button>
                        </div>
                    </div> */}
                    <div class="table-section">
                        <ReactTable
                            filterable={true}
                            showPagination={true}
                            sortable={true}
                            data={this.state.tableData}
                            columns={couponDetailColumnConfig}
                            defaultSorted={[{ id: "createdAt", desc: true }]}
                            style={{ height: "90vh", textAlign: 'center' }}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    console.log("123334444", state)
    let { couponsData = [] } = state.CouponStore
    return {
        couponsData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchCouponData}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseCouponView)
