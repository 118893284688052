import axios from 'axios';
import { zcommerceUrl } from '../config';
import { FETCH_COUPONS_DATA } from './actiontypes';


export function fetchCouponData(){
    return async(dispatch)=>{
        try {
            console.log('call Fetch coupons api ');
            let couponsRes = await  axios.get(`${zcommerceUrl.couponPathBackend}getAllCouponsV2`);
            couponsRes = couponsRes.data;
            console.log('couponRes', couponsRes);
            if(couponsRes.status!=='success'){
                alert("some error occured");
                return;
            }
            dispatch({type: FETCH_COUPONS_DATA, payload: couponsRes.data || []})
        } catch (error) {
            console.log("error", error)
            alert(error.message|| "some error occured");
        }

       
        
    }
}