import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { procurementVendorColumns } from '../../config/column_fields';
import Select from 'react-select';
import moment from 'moment';
import { element } from 'prop-types';

class ProcurementTransactions extends Component {

    constructor() {
        super();
        this.state = {
            transactions: [],
            showFilter: false,
            filters: {},
            procurementTransactionEditFlag: {}
        };
    }

    componentWillMount() {
        this.props.fetchProcurementPayments()
        this.props.fetchMappingZunsolar()
    }

    componentWillReceiveProps(props) {
        this.setState({
            transactions: (props.paymentsData || {}).data || []
        })
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = (e) => {
        let { filters } = this.state
        this.props.fetchProcurementPayments(filters)
    }

    multiSelectType = (name, options, placeholder, onChange) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }

    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        if (name == 'category') {
            let category = this.props.zunsolarCategoryMapping || {}
            let subCategoryOptions = [];
            value.map(val => {
                let options = (category[val] || []).map((subCategory) => { return { label: subCategory, value: subCategory } });
                subCategoryOptions = [...subCategoryOptions, ...(options || [])]
            })
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value,
                },
                subCategoryOptions
            })
        }
        else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value
                }
            })
        }
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[type] = filters[type] || {};
        filters[type][name] = value;
        this.setState({
            filters
        })
    }

    handleSaveProcurementTransactionClick = (row) => {
        let { siNo } = row.original;
        let { editedProcurementTransactionData } = this.state
        let newData = editedProcurementTransactionData[siNo] || {};
        if (newData['status'] != row.original['status']) {
            this.props.procurementStatusChange(newData, this.state.transactions)
        }
        this.setState({
            procurementTransactionEditFlag: {
                ...this.state.procurementTransactionEditFlag,
                [siNo]: false
            }
        })
    }

    handleCancelProcurementTransactionClick = (row) => {
        let { siNo } = row.original;
        this.setState({
            procurementTransactionEditFlag: {
                ...this.state.procurementTransactionEditFlag,
                [siNo]: false
            }
        })
    }

    handleEditProcurementTransactionClick = (row) => {
        let { siNo } = row.original;
        this.setState({
            procurementTransactionEditFlag: {
                ...this.state.procurementTransactionEditFlag,
                [siNo]: true
            },
            editedProcurementTransactionData: {
                ...this.state.editedProcurementTransactionData,
                [siNo]: row.original
            }
        })
    }

    handleProcurementTransactionChange = (e, row) => {
        let { name, value } = e.target;
        let { siNo } = row.original;
        let { editedProcurementTransactionData } = this.state;
        editedProcurementTransactionData = JSON.parse(JSON.stringify(editedProcurementTransactionData))
        editedProcurementTransactionData[siNo][name] = value;
        this.setState({
            editedProcurementTransactionData
        })
    }

    procurementTransactionSelect = (name, value, options, row) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleProcurementTransactionChange(e, row) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    handleDownloadFileClick = (fileName) => {
        this.props.downloadFile(fileName)
    }

    render() {
        let { state, props, handleSaveProcurementTransactionClick, handleCancelProcurementTransactionClick, handleEditProcurementTransactionClick, handleDownloadFileClick } = this
        let { transactions, procurementTransactionEditFlag, editedProcurementTransactionData } = state;

        let transactionsColumn = [
            {
                Header: 'S No.',
                accessor: 'siNo'
            },
            {
                Header: 'Payment Date',
                accessor: 'paymentDate'
            },
            {
                Header: 'Order Id',
                accessor: 'orderId'
            },
            {
                Header: 'Vendor Id',
                accessor: 'vendorId'
            },
            {
                Header: 'Category',
                accessor: 'category'
            },
            {
                Header: 'Sub Category',
                accessor: 'subcategory'
            },
            {
                Header: 'Unit Of Measurement',
                accessor: 'unitOfMeasurement'
            },
            {
                Header: 'Price Per Unit',
                accessor: 'pricePerUnit'
            },
            {
                Header: 'No. Of Unit',
                accessor: 'noOfUnits'
            },
            {
                Header: 'GST Rate',
                accessor: 'gstRate'
            },
            {
                Header: 'Discount',
                accessor: 'discount'
            },
            {
                Header: 'Delivery Charges',
                accessor: 'deliveryCharges'
            },
            {
                Header: 'PaymentPercentage',
                accessor: 'paymentPercentage'
            },
            {
                Header: 'Bank',
                accessor: 'bank'
            },
            {
                Header: 'TCS',
                accessor: 'tcs'
            },
            {
                Header: 'Total',
                accessor: 'total'
            },
            {
                Header: 'Sub Total',
                accessor: 'subTotal'
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: row => {
                    return (
                        !procurementTransactionEditFlag[row.original.siNo] || (['Rejected', 'Approved1']).includes(row.value) ? row.value : this.procurementTransactionSelect('status', editedProcurementTransactionData[row.original.siNo]['status'], props.statusMapping, row)
                    )
                }
            },
            {
                Header: "Buttons",
                Cell: row => {
                    return (
                        procurementTransactionEditFlag[row.original.siNo] ? <div className="row text-center" style={{ width: 'fit-content' }}><button className="btn btn-success btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleSaveProcurementTransactionClick(row)}>Save</button><button className="btn btn-light btn-sm" onClick={(e) => handleCancelProcurementTransactionClick(row)}>Cancel</button></div> : <div className="row text-center" style={{ width: 'fit-content' }}><button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleEditProcurementTransactionClick(row)}>Edit</button>{row.original['invoiceFile'] ? <button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleDownloadFileClick(row.original['invoiceFile'])}>Invoice</button> : ''}{row.original['pIFile'] ? <button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleDownloadFileClick(row.original['pIFile'])}>PI File</button> : ''}</div>
                    )
                },
                minWidth: 200
            }

        ]

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        console.log(this.state)

        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Transactions</h4>
                <div className="topbar-section allLeadViewFilters">
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye"></i>} </b></div>
                    <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                    <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                        <div className="col-md-3">
                            {this.inputType("startDate", "Start Date", 'date', this.handleInputDateChangeFilters, 'paymentDate')}
                        </div>
                        <div className="col-md-3">
                            {this.inputType("endDate", "End Date", 'date', this.handleInputDateChangeFilters, 'paymentDate')}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("vendorId", ((props.paymentsData || {}).data || []).map((element) => { if (element.vendorId) return { label: `${element.vendorId} ${element.vendorName}`, value: element.vendorId } }), "Vendor Id", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("category", Object.keys(props.zunsolarCategoryMapping || {}).map((element) => { return { label: element, value: element } }), "Category", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("subCategory", this.state.subCategoryOptions || [], "Sub Category", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("status", (props.statusMapping || []).map((element) => { return { label: element, value: element } }), "Status", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={transactions}
                    columns={transactionsColumn}
                    defaultSorted={[{ id: "siNo", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                />
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementTransactions);