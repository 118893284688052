import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import "../../styles/ProcurementPortalStyles.css";
import { getAllBulkPIInvoice, getAllBulkPILedger, getUnicommerceMapping} from '../../actions/zunsolarPiInvoiceAction';
import PIInvoiceAllV2 from '../../components/zunsolar/PIInvoiceAllV2';
import PIInvoiceLedgerV2 from '../../components/zunsolar/PIInvoiceLedgerV2';
import { Redirect } from 'react-router-dom';


class ZunsolarPIInvoiceV2 extends Component {

    constructor() {
        super();
        this.state = {
            selectedTab: "invoices",
            userObj: {},
        };
    }

    componentWillMount() {
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
    }

    handleOnViewChange = (e) => {
        let { name = '' } = e.target;
        this.setState({ selectedTab: name });
    }

    TabsComponent = ({ name, label }) => {
        let { selectedTab = '' } = this.state;
        let { handleOnViewChange } = this;
        return <button className={`tabButtons btn btn-sm ${selectedTab === name ? "btn-info" : "btn-outline-info"}`} name={name} onClick={handleOnViewChange}>{label}</button>;
    }

    render() {

        if(!((this.state.userObj.access || []).includes("Accounts"))) {
            return <Redirect to={"/"} />
        }

        let { TabsComponent, state, props } = this;
        let { selectedTab, userObj } = state;
        let {   getAllBulkPIInvoice, getAllBulkPILedger, allPiInvoices, allPiLedgerData,unicommerceMapping, getUnicommerceMapping} = props;
        return (
            <div>
                <Header />
                <div className={`container-fluid procurementMainDiv`}>
                    <div className="tabComponent">
                        <TabsComponent name="invoices" label="Invoices" />
                        {/* <TabsComponent name="ledger" label="Ledger" /> */}
                    </div>
                    {
                        selectedTab === "invoices" ? <PIInvoiceAllV2
                            allInvoices={allPiInvoices}
                            unicommerceMapping={unicommerceMapping}
                            getAllPIInvoiceOrders={getAllBulkPIInvoice}
                            getUnicommerceMapping={getUnicommerceMapping}
                            // invoiceTypeMapping = {invoiceTypeMapping}
                            userObj = {userObj}
                            // downloadFile = {downloadFile}
                        /> : ""
                    }
                    {/* {
                        selectedTab === "ledger" ? <PIInvoiceLedgerV2
                            allLedger = {allPiLedgerData}
                            getAllPIInvoiceLedger = {getAllBulkPILedger}
                            // downloadFileByInvoiceNumber = {downloadFileByInvoiceNumber}
                        /> : ""
                    } */}
                </div>
            </div>
        )
    }

}

function mapStateToProps({ zunsolarBulkPIInvoice }) {
    let { allPiInvoices = [], allPiLedgerData = [], unicommerceMapping=[]} = zunsolarBulkPIInvoice;
    return {
        allPiInvoices,
        allPiLedgerData,
        unicommerceMapping
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAllBulkPIInvoice, getAllBulkPILedger,getUnicommerceMapping }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarPIInvoiceV2);