import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
// import { inventoryColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchInventory, fetchMapping, updateInventoryData, downloadInventoryFile, deleteInventoryItem } from '../../actions/zunsolarOrderTrackerActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader';
import { CSVLink } from 'react-csv';
import { inventoryCsvColumns } from '../../config/column_fields'
class ZunsolarInventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            allData: [],
            viewData: [],
            filters: {
                startDate: 0,
                endDate: 0,
                category: [],
                subCategory: [],
                subCategoryOptions: []
            },
            loading: true,
            received: false,
            mapping: {},
            inventoryEditFlag: {},
            editedInventoryData: {}
        };
    }

    componentWillMount() {
        this.props.fetchInventory();
        this.props.fetchMapping();
    }

    componentWillReceiveProps(props) {
        let { inventoryData, mapping, inventoryDataReceived = false } = props;
        this.setState({
            loading: false,
            received: true,
            viewData: inventoryData,
            allData: inventoryData,
            mapping,
            inventoryDataReceived
        })
    }

    handleInputChangeFilters = (e) => {
        let { name, value } = e.target;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    handleSelectChangeFilters = (e, name) => {
        let { mapping } = this.state;
        let { category } = mapping;
        let value = (e || []).map(option => option.value);
        if (name == 'category') {
            let subCategoryOptions = [];
            value.map(val => {
                subCategoryOptions = [...subCategoryOptions, ...(category[val] || [])]
            })
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value,
                    subCategoryOptions
                }
            })
        }
        else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value
                }
            })
        }
    }

    multiSelectType = (name, options, placeholder, onChange, value) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }


    inputType = (name, placeholder, type, onChange, value) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={"End Date"} onChange={(e) => onChange(e)} />
                </div>
            </div>
        )
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = () => {
        let { filters, allData } = this.state;
        console.log(allData)
        let { startDate = "", endDate = "", category = [], subCategory = [] } = filters;
        let viewData = allData.filter(row => {
            return (
                (!startDate ? true : row.date >= startDate) &&
                (!endDate ? true : row.date <= endDate) &&
                (!category.length ? true : category.includes(row.category)) &&
                (!subCategory.length ? true : subCategory.includes(row.subCategory))
            )
        })
        this.setState({
            viewData
        })
        console.log("APPLY FILTER", filters);
    }

    handleEditInventoryClick = (row) => {
        let { id } = row;
        this.setState({
            inventoryEditFlag: {
                ...this.state.inventoryEditFlag,
                [id]: true
            },
            editedInventoryData: {
                ...this.state.editedInventoryData,
                [id]: row
            }
        })
    }

    handleSaveInventoryClick = (row) => {
        let { id } = row.original;
        let { editedInventoryData, allData } = this.state;
        let newData = editedInventoryData[id] || {};
        console.log("oldData", row.original)
        console.log("newData", newData)
        console.log(row);
        this.props.updateInventoryData(id, row.original, newData, allData)
        this.setState({
            inventoryEditFlag: {
                ...this.state.inventoryEditFlag,
                [id]: false
            }
        })
    }

    handleCancelInventoryClick = (row) => {
        let { id } = row.original;
        this.setState({
            inventoryEditFlag: {
                ...this.state.inventoryEditFlag,
                [id]: false
            }
        })
    }

    inventoryInput = (name, value, type, row) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInventoryChange(e, row)} />
    }

    inventorySelect = (name, value, options, row) => {
        return <select
            onChange={(e) => { this.handleInventoryChange(e, row) }}
            className="form-control" id="sel1" name={name} value={value}
        >
            <option>not selected</option>
            {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
    }

    handleInventoryChange = (e, row) => {
        let { name, value } = e.target;
        let { id } = row.original;
        let { editedInventoryData } = this.state;
        editedInventoryData = JSON.parse(JSON.stringify(editedInventoryData))
        editedInventoryData[id][name] = value;
        this.setState({
            editedInventoryData
        })
    }

    renderInputCell = (row) => {
        let { id, type } = row.column;
        let { inventoryEditFlag, editedInventoryData } = this.state;
        return (!inventoryEditFlag[row.original.id] ? row.value : this.inventoryInput(id, editedInventoryData[row.original.id][id], type, row))
    }

    renderSelectCell = (row) => {
        let { id, options } = row.column;
        let { inventoryEditFlag, editedInventoryData } = this.state;
        return (!inventoryEditFlag[row.original.id] ? row.value : this.inventorySelect(id, editedInventoryData[row.original.id][id], options, row))
    }

    downloadFile = (type, filename) => {
        this.props.downloadInventoryFile(type, filename);
    }

    handleDelete = (id) => {
        if (id) {
            this.props.deleteInventoryItem(id)
        }
    }

    render() {

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        let { renderInputCell, handleCancelInventoryClick, handleSaveInventoryClick, handleEditInventoryClick, downloadFile, renderSelectCell } = this;
        let { viewData, mapping = {}, filters, inventoryEditFlag, inventoryDataReceived } = this.state;

        const inventoryColumns = [
            {
                Header: 'Payment Centre',
                accessor: 'paymentCentre',
                options: this.state.mapping.paymentCentre,
                Cell: renderSelectCell

            },
            {
                Header: 'Cost Centre',
                accessor: 'costCentre',
                options: this.state.mapping.costCentre,
                Cell: renderSelectCell
            },
            {
                Header: 'Sub Cost Centre',
                accessor: 'subCostCentre',
                options: this.state.mapping.subCostCentre,
                Cell: renderSelectCell
            },
            {
                Header: "PO/PI Date",
                accessor: "date",
                type: "date",
                Cell: renderInputCell
            },
            {
                Header: "PO/PI Value",
                accessor: "poValue",
                type: "text",
                Cell: renderInputCell
            },
            {
                Header: "PO/PI No",
                accessor: "supplyOrderId",
                type: "text",
                Cell: renderInputCell
            },
            {
                Header: row => {
                    let { data } = row;
                    return `Lot No ${data.length}`;
                },
                accessor: "lotNo",
            },
            {
                Header: "Category",
                accessor: "category",
            },
            {
                Header: "Sub Category",
                accessor: "subCategory"
            },
            {
                Header: "Invoice No",
                accessor: "invoiceNo",
                type: "text",
                Cell: renderInputCell
            },
            {
                Header: "Invoice Date",
                accessor: "preferenceDate",
            },
            {
                Header: "Invoice Value",
                accessor: "invoiceValue",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Order Qty",
                accessor: "orderQty",
            },
            {
                Header: "Invoice/Recieved Qty",
                accessor: "inStock",
            },
            {
                Header: "Gst",
                accessor: "gst",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Freight",
                accessor: "freight",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Freight Tax",
                accessor: "freightTax",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Unit Price",
                accessor: "unitPrice",
                type: "text",
                Cell: renderInputCell
            },
            {
                Header: "Expense Month",
                accessor: "expenseMonth",
                type: "month",
                Cell: renderInputCell
            },
            {
                Header: "Booking Month",
                accessor: "bookingMonth",
                type: "month",
                Cell: renderInputCell
            },
            {
                Header: "Invoice Month",
                accessor: "invoiceMonth",
                type: "month",
                Cell: renderInputCell
            },
            {
                Header: "Account Booked Status",
                accessor: "accountBookedStatus",
                options: this.state.mapping.accountBookingStatus,
                Cell: renderSelectCell
            },
            {
                Header: "Total",
                accessor: "total",
            },
            // {
            //     Header: "UOM",
            //     accessor: "uom",
            // },
            {
                Header: "Proforma Invoice",
                accessor: "purchaseInvoice",
                Cell: row => row.value ? <button onClick={() => downloadFile("purchaseInvoice", row.value)} className="btn btn-sm btn-link">Download</button> : ""
            },
            {
                Header: "Transport Challan",
                accessor: "transportChallan",
                Cell: row => row.value ? <button onClick={() => downloadFile("transportChallan", row.value)} className="btn btn-sm btn-link">Download</button> : ""
            },
            {
                Header: "Purchase Order",
                accessor: "purchaseOrder",
                Cell: row => row.value ? <button onClick={() => downloadFile("purchaseOrder", row.value)} className="btn btn-sm btn-link">Download</button> : ""
            },
            {
                Header: "GST Invoice",
                accessor: "gstInvoice",
                Cell: row => row.value ? <button onClick={() => downloadFile("gstInvoice", row.value)} className="btn btn-sm btn-link">Download</button> : ""
            },
            {
                Header: "MIS",
                accessor: "mis",
                Cell: row => row.value ? <button onClick={() => downloadFile("mis", row.value)} className="btn btn-sm btn-link">Download</button> : ""
            },
            // {
            //     Header: "Packaging",
            //     accessor: "packaging",
            // },
            // {
            //     Header: "Transportation Charges",
            //     accessor: "transportationCharge",
            // },
            {
                Header: "Edit",
                Cell: row => {
                    return (
                        inventoryEditFlag[row.original.id] ? <div className="row"><button className="btn btn-success btn-sm col-md-6" onClick={(e) => handleSaveInventoryClick(row)}>Save</button><button className="btn btn-light btn-sm col-md-6" onClick={(e) => handleCancelInventoryClick(row)}>Cancel</button></div> : <button className="btn btn-info btn-sm" onClick={(e) => handleEditInventoryClick(row.original)}>Edit</button>
                    )
                }
            },
            {
                Header: "Delete",
                Cell: row => {
                    return (
                        <button className="btn btn-warning btn-sm" onClick={(e) => this.handleDelete(row.original.id)}>Delete</button>
                    )
                }
            },
            // {
            //     Header: "id",
            //     accessor: 'id'
            // },
            // {
            //     Header: `Active`,
            //     accessor: 'active'
            // }
        ]


        let categoryOptions = Object.keys(mapping.category || {}).map(category => ({ label: category, value: category }));
        let subCategoryOptions = (filters.subCategoryOptions || []).map(subCategory => ({ label: subCategory, value: subCategory }))
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <div className="topbar-section allLeadViewFilters">
                        <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                        <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                        <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                            <div className="col-md-2">
                                {this.inputType("startDate", "Start Date", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("endDate", "End Date", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.multiSelectType("category", categoryOptions, "Category", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.multiSelectType("subCategory", subCategoryOptions, "Sub Category", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '20px 0px' }}>
                        <div style={{ float: "left" }}>
                            <CSVLink filename={"Inventory Data.csv"} data={viewData} headers={inventoryCsvColumns} type="button" className="btn btn-light">
                                Download <i className="fa fa-download"></i>
                            </CSVLink>
                        </div>
                        <div className="col-md-12" style={{ textAlign: "right" }}>
                            <Link to="/zunsolar/addinventory" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> Add New</button></Link>
                        </div>
                    </div>

                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={viewData}
                        columns={inventoryColumns}
                        defaultSorted={[{ id: "date", desc: true }]}
                        style={{ height: "90vh", textAlign: 'center' }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        loading={!inventoryDataReceived}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps({ zunSolarOrderTracker }) {
    let { inventoryData = [], mapping = {}, inventoryDataReceived = false } = zunSolarOrderTracker || {};
    return {
        inventoryData,
        mapping,
        inventoryDataReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchInventory, fetchMapping, updateInventoryData, downloadInventoryFile, getSearchResult, deleteInventoryItem }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarInventory);