import React, { Component } from 'react'
import '../styles/pulseLeadTransfer.css';
import Select, { NonceProvider } from 'react-select';
import { Loader } from './loader';
import ReactTable from "react-table";
import swal from 'sweetalert';
import moment from 'moment';
import url from '../config';
import axios from 'axios';

export default class SAViewComponent extends Component {
  constructor(props) {
    super(props)
    this.reactTable = React.createRef();
    this.state = {
      filterData: {},
      mappingData: {},
      tableData: [],
      comments: [],
      reasons: ["Reschedule by Us", "Reschedule by Client", "Client not interested", "Site too far", "Out of operation area", "Lack of time", "Clash of time", "Audio Assessment", "DNA/NR"],
      reasonMap: {},
      loading: true,
      recieved: false
    }
    this.columns = [
      {
        Header: "Comments",
        Cell: row => {
          return (
            <i className="fas fa-2x fa-plus-square" style={{ color: "#007bff", cursor: "pointer" }} data-toggle="modal" data-target="#commentModal" onClick={(e) => { this.commentIconClicked(e, row.original) }}></i>
          )
        }
      },
      {
        Header: "Name",
        accessor: "TITLE",
      },
      {
        Header: "Phone",
        accessor: "PHONE_VALUE",
      },
      {
        Header: "Language Preferred",
        accessor: "LANGUAGE",
      },
      {
        Header: "Region",
        accessor: "REGION",
      },
      {
        Header: "SA Date",
        accessor: "SITE_ASSESSMENT_DATE",
      },
      {
        Header: "SA Slot",
        accessor: "SA_SLOT",
      },
      {
        Header: "SA Type",
        accessor: "SA_TYPE",
      },
      {
        Header: "Bill Value",
        accessor: "MONTHLY_BILL_FORM",
      },
      {
        Header: "Next Action",
        accessor: "NEXT_ACTION",
        Cell: row => {
          return (
            row.original.value ? row.original.value : "N/A"
          )
        }
      },
      {
        Header: "Responsible",
        accessor: "N_RESPONSIBLE"
      },
      {
        Header: "Address",
        accessor: "ADDRESS"
      },
      {
        Header: "Location",
        Cell: row => {
          return (
            row.original.latitudeWA && row.original.longitudeWA ? <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.latitudeWA}%2C${row.original.longitudeWA}`} target="_blank">Click</a>: ""
          )
        }
      },
      {
        Header: "Reason",
        Cell: row => {
          return (
            <button className="btn btn-primary btn-sm" data-toggle="modal" data-target="#reasonModal" onClick={(e) => { this.handleReasonButtonClicked(e, row.original) }}>Add</button>
          )
        }
      }
    ]
  }



  componentWillReceiveProps(props) {
    // console.log("100000", props);
    let mappingData = props.allMapping ? props.allMapping : {};
    let saViewData = props.saViewData ? props.saViewData : {};

    if (Object.keys(mappingData).length > 0 && Object.keys(saViewData).length > 0) {
      this.setState({
        mappingData: mappingData,
        tableData: saViewData ? saViewData.data : [],
        loading: false,
        recieved: true
      })
    }
  }

  commentIconClicked = async (e, row) => {
    this.setState({ comments: [] })
    let bitrixId = row.BITRIX_ID;
    let tableData = [...this.state.tableData];
    let comments = []
    let commentsApiResponse = await axios.post(url.pathBackend + "lead/comments/" + row.BITRIX_ID);
    if(commentsApiResponse && commentsApiResponse.data && commentsApiResponse.data.length) {
      comments = comments.concat(commentsApiResponse.data);
      this.setState({
        comments: comments
      })
    }
  }


  handleInputChange = (e) => {
    let { name, value } = e.target;
    value = this.checkSelectString(value);
    if (name == "reason" || name == "rescheduleDate" || name == "remarks") {
      this.setState({
        reasonMap: {
          ...this.state.reasonMap,
          [name]: value
        }
      }, () => {
        // console.log("500", this.state.filterData, "600", this.state.reasonMap)
      })
    } else {
      this.setState({
        filterData: {
          ...this.state.filterData,
          [name]: value
        }
      }
      )
    }
  }

  inputType = (name, value, type, placeholder) => {
    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
  }

  selectType = (name, options, value) => {

    return (
      <div>
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }


  onApplyFilter = () => {
    let filterData = JSON.parse(JSON.stringify(this.state.filterData));
    let { state, startDate, endDate } = filterData
    this.props.saViewGet(state, startDate, endDate);
    this.setState({
      loading: true,
    })
  }

  handleReasonButtonClicked = (e, row) => {
    let bitrixId = row.BITRIX_ID
    this.setState({
      reasonMap: {
        ...this.state.reasonMap,
        bitrixId: bitrixId
      }
    }, () => { console.log(this.state.reasonMap) })
  }

  handleReasonSave = (e) => {
    let reasonMap = JSON.parse(JSON.stringify(this.state.reasonMap))
    let bitrixId = reasonMap.bitrixId;
    this.props.saveRemarks(reasonMap);
    let tableData = [...this.state.tableData]
    let newTableData = tableData.filter((lead) => lead.BITRIX_ID !== bitrixId);
    this.setState({
      reasonMap: {},
      tableData: newTableData
    }, () => { console.log("1", this.state.reasonMap) })
  }

  render() {
    if (this.state.recieved) {
      return (
        <div className="container-fluid" style={{ marginTop: "80px", backgroundColor: "#f7f7f7" }}>
          <div style={{ textAlign: "center" }}>
            <h2 style={{ fontWeight: "bold" }}>SA VIEW</h2>
          </div>
          <div className="row leadtransferTopbarSection">
            <div className="col-md-3">
              <label>State </label>
              {this.selectType("state", this.state.mappingData.stateregion ? Object.keys(this.state.mappingData.stateregion) : {}, this.state.filterData.state)}
            </div>
            <div className="col-md-3">
              <label>SA Start Date</label>
              {this.inputType("startDate", this.state.filterData.startDate, "date")}
            </div>
            <div className="col-md-3">
              <label>SA End Date</label>
              {this.inputType("endDate", this.state.filterData.endDate, "date")}
            </div>
            <div className="col-md-3">
              <button className="btn btn-success leadTransferSubmitButton" onClick={(e) => { this.onApplyFilter() }}>Submit</button>
            </div>
          </div>
          <div class="saview-table-section">
            <ReactTable
              loading={this.state.loading}
              filterable={true}
              sortable={false}
              showPagination={true}
              data={this.state.tableData}
              defaultPageSize={20}
              columns={this.columns}
              ref={this.reactTable}
              style={{ backgroundColor: "#fff" }}
              defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
            //    defaultPageSize = {2}
            //    pageSizeOptions = {[2,4,6]}
            />
          </div>
          {/* modal for reason */}
          <div className="modal fade" id="reasonModal" tabindex="-1" role="dialog" aria-labelledby="reasonModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <h5 className="modal-title" id="reasonModalLabel">Modal title</h5> */}
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row" style={{ width: "100%", marginLeft: "5px", textAlign: "left" }}>
                    <label className="col-md-12" style={{ paddingLeft: "0px" }}><b>Reason <span style={{ color: "red" }}>*</span></b></label>
                    {this.selectType("reason", this.state.reasons, this.state.reasonMap.reason || [])}
                  </div>
                  <div className="row" style={{ width: "50%", marginTop: "20px", marginLeft: "5px" }}>
                    <label className="col-md-12" style={{ paddingLeft: "0px" }}><b>Reschedule Date (optional)</b></label>
                    {this.inputType("rescheduleDate", this.state.reasonMap.rescheduleDate || "", "date")}
                  </div>
                  <div className="row" style={{ width: "70%", marginTop: "20px", marginLeft: "5px" }}>
                    <label className="col-md-12" style={{ paddingLeft: "0px" }}><b>Remarks (optional)</b></label>
                    <textarea className="form-control" rows="3" name="remarks" type="text" id="remarks" value={this.state.reasonMap.remarks || ""} onChange={(e) => this.handleInputChange(e)}></textarea>
                  </div>
                  <div className="row" style={{ marginTop: "25px" }}>
                    <button class="btn btn-success btn-sm" onClick={(e) => { this.handleReasonSave(e) }} data-dismiss="modal" aria-label="Close">Save Changes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal for reason ends */}

          {/* modal for comments */}
          <div className="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Comments</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.comments.map((comment) => {
                    return <div><p><b>{comment.commentedBy} {moment(+(comment.timestamp)).format('DD/MM/YYYY hh:mm a')} </b> {comment.commentBody.replace(/&nbsp;/g, '  ')}</p></div>
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* modal for comments end */}
        </div>

      )
    }
    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }

  }
}
