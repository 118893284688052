import {FETCH_REPEAT_SALES_BANNER_DATA, FETCH_REPEAT_SALES_OFFER_DATA, FETCH_REPEAT_SALES_VERSION_DATA } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_REPEAT_SALES_BANNER_DATA: {
            return { ...state, bannerData: [...action.payload ] };
        }
        case FETCH_REPEAT_SALES_OFFER_DATA:{
            return {...state, offerData:[...action.payload]}
        }
        case FETCH_REPEAT_SALES_VERSION_DATA:{
            return {...state, versionData : [...action.payload]}
        }
        default:
            return state;
    }
}