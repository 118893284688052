import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { fetchOrdersMappingNew, createNewOrderZunpulseNew } from '../../actions/zunpulseOrderTrackingActions.js';
import { fetchMapping } from '../../actions/zunpulseOpsDashboardActions.js';
import moment from 'moment';

class ZunpulseNewCreateOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mapping: {},
            orderData: {
                cartData: [{}]
            },
            country: ["India"],
            city: [],
            allDevices: {}
        };
        this.statusMap = ["Not Procured", "Procured", "Shipped", "Delivered", "Cancelled", "Returning (Before Delivery)", "Returning (After Delivery)", "Returned", "Replacement", "Truncated"];
        this.deviceMap = {
            "Smart Light": 10000,
            "Smart Downlight": 10020,
            "Smart Energy Monitor": 2,
            "Smart Plug": 20000,
            "AC Remote": 30000,
            "TV Remote": 31000,
            "Smart Security": 40000,
            "Smart Camera": 50000,
            "Smart Doorbell": 51000
        };
        this.sourceMap = ["web", "app", "manual", "Flipkart", "Snapdeal", "Amazon", "Cred", "City sales", "B2B", "PEPPERFRY", "TATA CLIQ","Meesho","Livspace","NobrookerHood","Shopee"];
        this.paymentStatus = ["success", "aborted", "failure"];
        this.isPreBooked = ["yes", "no"];
        this.addressType = ["home", "office", "others"];
        this.orderType = ["Normal", "Return Pickup", "Replacement"];
    }

    componentWillMount() {
        this.props.fetchOrdersMappingNew();
        this.props.fetchMapping();
    }
    componentWillReceiveProps(props) {
        let { mapping = {}, comboMapping = {}, opsDashboardMapping = {}, singleProducts = [] } = props;
        let productAndComboMapping = {};
        (singleProducts || []).map((singleProducts) => {
            productAndComboMapping[singleProducts.product] = {productId:singleProducts.productId, isCombo:'false'};
        });
        (opsDashboardMapping.comboProducts || []).forEach((combo) => {
            productAndComboMapping[combo.product] = {productId:combo.productId, isCombo:'true'};
        });
        this.setState({
            mapping: mapping,
            allDevices: productAndComboMapping,
            opsDashboardMapping: opsDashboardMapping,
        })
    }


    checkSelectString = (str) => {
        if (str == "not selected") {
            return str = ""
        }
        else {
            return str
        }
    }


    //============html inputs and select type functions ==========//

    inputType = (name, value, type, placeholder) => {
        return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleChange(e)} />
    }

    selectType = (name, value, options) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option>not selected</option>
                    {(options ? options : []).map(e => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
        // console.log(this.state.orderData)
        value = this.checkSelectString(value);
        if (name == "address" || name == "state" || name == "city" || name == "addressType" || name == "pincode" || name == "country") {
            if (name == "state") {
                this.setState({
                    city: [...this.state.mapping[value]]
                })
            }
            this.setState({
                orderData: {
                    ...this.state.orderData,
                    deliveryAddress: {
                        ...this.state.orderData.deliveryAddress,
                        [name]: value
                    }
                }
            })
        } else {
            if (name == "status") {
                this.setState({
                    orderData: {
                        ...this.state.orderData,
                        [name]: this.statusMap.indexOf(value)
                    }
                }, () => { })
            } else {
                this.setState({
                    orderData: {
                        ...this.state.orderData,
                        [name]: value,
                    }
                })
            }

        }
    }

    //============================================//

    //=================Cart Html functions==================//
    cartDetailInput = (name, value, type, index) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleCartDetailChange(e, index)} />
    }

    cartDetailSelect = (name, value, options, index) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleCartDetailChange(e, index) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">not selected</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    handleCartDetailChange = (e, index) => {
        let { name, value } = e.target;
        let { orderData } = this.state;
        let { cartData } = orderData;

        cartData[index][name] = value;
        if (name == "deviceName") {
                cartData[index]["deviceType"] = this.state.allDevices[value].productId;
                cartData[index]["isCombo"] = this.state.allDevices[value].isCombo;
            this.setState({
                orderData: {
                    ...this.state.orderData,
                    cartData
                }
            }, () => { })
        } else {
            this.setState({
                orderData: {
                    ...this.state.orderData,
                    cartData
                }
            }, () => { })
        }
    }

    addMoreCartDetails = () => {
        let { orderData } = this.state;
        let { cartData } = orderData;
        cartData = [...cartData, {}];
        this.setState({
            orderData: {
                ...this.state.orderData,
                cartData: cartData
            }
        }, () => {
            // console.log(this.state.orderData)
        })
    }
    //================================================//

    //=================Submit Order======================//

    removingNullKeys = (obj) => {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
                delete obj[propName];
            }
        }
        return obj
    }

    cartDetailsValidation = (cartData) => {
        let isValid = true;
        cartData.map((cart) => {
            if (!cart.quantity || !cart.deviceName || !cart.sellingPrice || !cart.amountPaid || !cart.mrp) {
                isValid = false;
            }
        })
        return isValid;
    }

    onNewOrderDataSubmit = () => {
        let { orderData } = this.state;
        let { cartData } = orderData;
        let { deliveryAddress } = orderData;
        orderData = this.removingNullKeys(orderData);
        // console.log("orderData", orderData);

        if (!orderData.authPhoneNumber) {
            // alert("Please Fill Required Field - Phone")
            // return;
            orderData.authPhoneNumber = "4231851867";
        }
        if (orderData.authPhoneNumber.length != 10) {
            alert("Phone Number Must be of 10 Digits")
            return;
        }
        if (!orderData.name) {
            alert("Please Fill Required Field - Name")
            return;
        }
        if (!deliveryAddress.address) {
            alert("Please Fill Required Field - Address")
            return;
        }
        if (!deliveryAddress.addressType) {
            alert("Please Fill Required Field - Address Type")
            return;
        }
        if (!deliveryAddress.state) {
            alert("Please Fill Required Field - State")
            return;
        }
        if (!deliveryAddress.city) {
            alert("Please Fill Required Field - City")
            return;
        }
        if (!deliveryAddress.pincode) {
            alert("Please Fill Required Field - Zipcode")
            return;
        }
        if (!deliveryAddress.country) {
            alert("Please Fill Required Field - Country")
            return;
        }
        if (!cartData.length || !this.cartDetailsValidation(cartData)) {
            alert("Please Fill Required Field - Cart Details")
            return;
        }
        this.props.createNewOrderZunpulseNew(orderData);
    }

    //===============================================//


    render() {
        let { mapping, orderData } = this.state;
        let { cartData } = orderData
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        if (Object.keys(this.state.mapping).length > 0) {
            return (
                <div>
                    <Header getSearchResult={this.props.getSearchResult} />
                    <div className="container-fluid" style={{ marginTop: "80px" }}>
                        <div className="topbar-section" style={{ marginBottom: "30px" }} >
                            <div className="row">
                                <div className="col-md-10 col-xs-12 topbar-left">
                                    <div className="col-md-12">
                                        <strong>Create New Order</strong>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-12 topbar-right">
                                    <div className="col-md-12 col-xs-12">
                                        <button className="button-save" onClick={(e) => this.onNewOrderDataSubmit(e)}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                                <table>
                                    <tr>
                                        <td>Display Order Id</td>
                                        <td>
                                            <span className="input-group">
                                                {this.inputType("displayOrderId", this.state.orderData.displayOrderId, "text")}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Type</td>
                                        <td>
                                            <span className="input-group">
                                                {this.selectType("orderType", this.state.orderData.orderType, (this.orderType || []))}
                                            </span>
                                        </td>
                                    </tr>
                                    {
                                        this.state.orderData && this.state.orderData.orderType && ["Return Pickup", "Replacement"].includes(this.state.orderData.orderType) ? 
                                        <tr>
                                            <td>Parent Order Id</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.inputType("parentOrderId", this.state.orderData.parentOrderId, "text")}
                                                </span>
                                            </td>
                                        </tr> :
                                        ""
                                    }
                                    <tr>
                                        <td>Phone</td>
                                        <td>
                                            <span className="input-group">
                                                {this.inputType("authPhoneNumber", this.state.orderData.authPhoneNumber, "number")}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Customer Email</td>
                                        <td>
                                            <span className="input-group">
                                                {this.inputType("customerEmail", this.state.orderData.customerEmail, "text")}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>
                                            <span className="input-group">
                                                {this.inputType("email", this.state.orderData.email, "text")}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>
                                            <span className="input-group">
                                                {this.inputType("name", this.state.orderData.name, "text")}
                                            </span>
                                        </td>
                                    </tr>

                                    {/* <tr>
                                        <td>amount</td>
                                        <td>
                                            <span className="input-group">
                                                {this.inputType("amount", this.state.orderData.amount, "number")}
                                            </span>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>Address</td>
                                        <td>
                                            <span className="input-group">
                                                {this.inputType("address", this.state.orderData.deliveryAddress ? this.state.orderData.deliveryAddress.address : "", "text")}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-md-8 table-wrapper">
                                <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
                                    <table className="customer-info-left">

                                        <tr>
                                            <td>Address Type</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.selectType("addressType", this.state.orderData.deliveryAddress ? this.state.orderData.deliveryAddress.addressType : "", this.addressType)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.selectType("state", this.state.orderData.deliveryAddress ? this.state.orderData.deliveryAddress.state : "", Object.keys(mapping))}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.selectType("city", this.state.orderData.deliveryAddress ? this.state.orderData.deliveryAddress.city : "", (this.state.city || []))}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Country</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.selectType("country", this.state.orderData.deliveryAddress ? this.state.orderData.deliveryAddress.country : "", (this.state.country || []))}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Zipcode</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.inputType("pincode", this.state.orderData.deliveryAddress ? this.state.orderData.deliveryAddress.pincode : "", "number")}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-md-6 customer-info">
                                    <table className="customer-info-left">
                                        {/* <tr><p style={{ color: "green", marginLeft: "10px" }}>--Optional--</p></tr> */}
                                        {/* <tr>
                                            <td>Order Status</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.selectType("status", this.statusMap[this.state.orderData.status], (this.statusMap || []))}
                                                </span>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td>Payment Status</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.selectType("paymentStatus", this.state.orderData.paymentStatus, (this.paymentStatus || []))}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Source</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.selectType("source", this.state.orderData.source, (this.sourceMap || []))}
                                                </span>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>Order Date</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.inputType("orderDate", this.state.orderData.orderDate ? this.state.orderData.orderDate : "", "date")}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shipping Date</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.inputType("shippingDate", this.state.orderData.shippingDate ? this.state.orderData.shippingDate : "", "date")}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Delivery Date</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.inputType("deliveryDate", this.state.orderData.deliveryDate ? this.state.orderData.deliveryDate : "", "date")}
                                                </span>
                                            </td>
                                        </tr> */}
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div className="topbar-section" >
                            <div className="row">
                                <div className="col-md-10 col-xs-12 topbar-left">
                                    <div className="col-md-12">
                                        <strong>Cart-Details</strong>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-12 topbar-right">
                                    <div className="col-md-12 col-xs-12">
                                        <button style={{ float: "right", marginRight: "40px" }} className="createLeadBtn" onClick={(e) => this.addMoreCartDetails(e)}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {cartData.map((row, i) => {
                                return (
                                    <div className="col-md-3 customer-info">
                                        <table>
                                            <tr>
                                                <td>Quantity</td>
                                                <td>
                                                    <span className="input-group">
                                                        {this.cartDetailInput("quantity", row.quantity, "number", i)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Device Name</td>
                                                <td>
                                                    <span className="input-group">
                                                        {this.cartDetailSelect("deviceName", row.deviceName, Object.keys(this.state.allDevices), i)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Category</td>
                                                <td>
                                                    <span className="input-group">
                                                        {this.cartDetailSelect("category", row.category, [...((this.state.opsDashboardMapping || {}).categoryList || []), 'combo'], i)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Type</td>
                                                <td>
                                                    <span className="input-group">
                                                        {this.cartDetailSelect("type", row.type, ['b22', 'e27'], i)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Selling Price</td>
                                                <td>
                                                    <span className="input-group">
                                                        {this.cartDetailInput("sellingPrice", row.sellingPrice, "number", i)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>MRP</td>
                                                <td>
                                                    <span className="input-group">
                                                        {this.cartDetailInput("mrp", row.mrp, "number", i)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Amount Paid</td>
                                                <td>
                                                    <span className="input-group">
                                                        {this.cartDetailInput("amountPaid", row.amountPaid, "number", i)}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table >
                                    </div >

                                )

                            })
                            }
                        </div >
                    </div >
                </div >

            );
        } else {
            return (
                <div><Header getSearchResult={this.props.getSearchResult} /></div>
            )
        }
    }
}

function mapStateToProps(state) {
    // console.log("state", state)
    let { zunpulseOrders, zunPulseOpsDashboard } = state;
    return {
        mapping: zunpulseOrders.mapping ? zunpulseOrders.mapping.stateCityMapping : {},
        comboMapping: zunpulseOrders.mapping ? zunpulseOrders.mapping.combos : {},
        opsDashboardMapping: zunPulseOpsDashboard.mapping || {},
        singleProducts: (zunPulseOpsDashboard.mapping || {}).singleProducts || []
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchOrdersMappingNew, createNewOrderZunpulseNew, fetchMapping }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseNewCreateOrder);