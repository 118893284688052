import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { orderColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchOrders, fetchMapping, fetchCombo, downloadInventoryFile } from '../../actions/zunsolarOrderTrackerActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader'
import { CSVLink } from 'react-csv';
import url from '../../config';
import axios from 'axios';

class ZunsolarAllOrderView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            showColumns: [],
            allData: [],
            viewData: [],
            filters: {
                date: {
                    startDate: '',
                    endDate: ''
                },
                pickUpDate: {
                    startDate: '',
                    endDate: ''
                },
                deliveryDate: {
                    startDate: '',
                    endDate: ''
                },
                platform: [],
                category: [],
                subCategory: [],
                subCategoryOptions: [],
                status: [],
                shippingPartner: [],
                brand: [],
                paymentStatus: []
            },
            loading: true,
            received: false,
            mapping: {},
            orderDataReceived: false,
            page: 0,
            limit: 500,
            limitoption: [
                { value: 500, label: '500' },
                { value: 1000, label: '1000' },
                { value: 5000, label: '5000' },
                { value: -1, label: 'All Leads'}
            ],
            uploadCsvModal: false,
            uploadFile: undefined,
            employeeDetails:{},
            searchText: ""
        };
    }

    componentWillMount() {
        this.props.fetchOrders();
        this.props.fetchMapping();
        this.props.fetchCombo();

        let showColumns = orderColumns.map((val) => {
            if (val.accessor == "orderInvoice") {
                val.Cell = row => {
                    return (row.value ? <button onClick={() => this.downloadFile("orderInvoice", row.value)} className="btn btn-sm btn-link">Download</button> : "")
                }
            }
            return val;
        })
        this.setState({
            showColumns
        })
    }

    fetchLeads = (e) => {
        let { page, limit, allData, filters, searchText = "" } = this.state;
        this.props.fetchOrders(page + 1,limit,allData,filters,searchText);
        this.setState({
          page: page + 1,
          orderDataReceived : false
        })
      }

    componentWillReceiveProps(props) {
        let { allOrders, mapping, comboList = [], orderDataReceived = false } = props;
        let employeeDetails = JSON.parse(localStorage.getItem("userObj"));
        allOrders = allOrders.map(row => {
            row.shipmentLocationView = row.shipmentLocation || (mapping.shipmentLocationMapping && row.state ? (mapping.shipmentLocationMapping[row.state.toUpperCase()] || "GAURGAON" ) : "GURGAON");
            return row;
        })
        this.setState({
            loading: false,
            received: true,
            viewData: allOrders,
            allData: allOrders,
            employeeDetails:employeeDetails,
            mapping,
            comboList,
            orderDataReceived
        })
    }

    downloadFile = (type, filename) => {
        this.props.downloadInventoryFile(type, filename);
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[type] = filters[type] || {};
        filters[type][name] = value;
        this.setState({
            filters
        })
    }

    handleSelectChangeFilters = (e, name) => {
        let { mapping, comboList = [] } = this.state;
        let { category } = mapping;
        let value = (e || []).map(option => option.value);
        if (name == 'category') {
            let subCategoryOptions = [];
            value.map(val => {
                let options = Object.keys(category[val]);
                subCategoryOptions = [...subCategoryOptions, ...(options || [])]
            })
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value,
                    subCategoryOptions
                }
            })
        }
        else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value
                }
            })
        }
    }

    selectFetchLeads = (lim, e) => {
        this.setState({
          page: 0,
          limit: lim,
          allData: [],
          orderDataReceived : false
        },() => {
            let { limit, page, filters, searchText = "" } = this.state;
            this.props.fetchOrders(page,limit,[],filters,searchText);
        })
      }

    multiSelectType = (name, options, placeholder, onChange, value) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }


    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={"End Date"} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = () => {
        let { limit, filters, searchText = "" } = this.state;
        this.props.fetchOrders(0,limit,[],filters, searchText);
        this.setState({
          page: 0,
          orderDataReceived : false
        })
    }

       //============csv upload====================//
       openUploadCsvModal = () => {
        this.setState({
        uploadCsvModal: true,

        })
    }

    closeUploadCsvModal = () => {
        this.setState({
          uploadCsvModal: false,
          successMessage: undefined,
          errorMessage: undefined
        })
      }
    
    uploadFile = (e) => {
        let files = e.target.files
        this.setState({
            uploadFile: files[0]
        })
    }

    handleSearchInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    submitFileUpload = async () => {
        if (this.state.uploadFile) {
        //   if (this.state.uploadFile.type == "text/csv") {
            let csv = this.state.uploadFile;
            let formData = new FormData();
            formData.append("file", csv);
            this.setState({
              errorMessage: undefined,
              successMessage: undefined,
              uploadMessage: 'Uploading...'
            })
            let response = await axios.post(url.pathBackend + 'zunsolar/ordertracker/bulkupload', formData);
            // console.log("1", response)
            console.log("1",response);
            if (response.data.error) {
              this.setState({
                errorMessage: response.data.error,
                successMessage: undefined,
                uploadMessage: undefined,
              })
            }
            if (!response.data.error && response.data.message == "Orders Added Successfully") {
              this.setState({
                successMessage: response.data.message,
                errorMessage: undefined,
                uploadMessage: undefined,
              })
              setTimeout(()=>{
                this.closeUploadCsvModal();
              },800);
            } else if (!response.data.error && response.data.message.message !== "Orders Added Successfully") {

                this.setState({
                    successMessage: undefined,
                    errorMessage: response.data.message.message + " : - " + response.data.message.order,
                    uploadMessage: undefined,
                })
            }
        //   } 
        //   else {
        //     this.setState({
        //       errorMessage: "Unsupported File Format!!"
        //     })
        //   }
        }
      };

    render() {

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        };
        
        const modalStyles = {
            modal: {
              width: '40%',
              maxWidth: '40%',
              padding: '40px',
              background: '#f7f7f7',
              textAlign: 'center'
            }
        };

        let { showColumns, viewData, mapping = {}, filters, orderDataReceived } = this.state;
        let { platform } = mapping;
        let platformOptions = (platform || []).map(option => ({ label: option, value: option }))
        let categoryOptions = Object.keys(mapping.category || {}).map(category => ({ label: category, value: category }));
        let subCategoryOptions = (filters.subCategoryOptions || []).map(subCategory => ({ label: subCategory, value: subCategory }))
        let statusOptions = (mapping.status || []).map(status => ({ label: status, value: status }));
        let brandOptions = (mapping.companyBrand || []).map(status => ({ label: status, value: status }));
        let paymentStatusOptions = (Object.keys(mapping.paymentStatus || {}) || []).map(statusKey => ({ label: mapping.paymentStatus[statusKey], value: mapping.paymentStatus[statusKey] }));
        let shippingPartnerOptions = (mapping.shippingPartner || []).map(shippingPartner => ({ label: shippingPartner, value: shippingPartner }));
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 60 }} className="container-fluid">
                    <div className="topbar-section allLeadViewFilters">
                        <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                        <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                        <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                            <div className="col-md-3">
                                {this.inputType("startDate", "Start Date", "date", this.handleInputDateChangeFilters, "date")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("endDate", "End Date", "date", this.handleInputDateChangeFilters, "date")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("startDate", "Pick Up Date (Start)", "date", this.handleInputDateChangeFilters, "pickUpDate")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("endDate", "Pick Up Date (End)", "date", this.handleInputDateChangeFilters, "pickUpDate")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("startDate", "Delivery Date (Start)", "date", this.handleInputDateChangeFilters, "deliveryDate")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("endDate", "Delivery Date (End)", "date", this.handleInputDateChangeFilters, "deliveryDate")}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("platform", platformOptions, "Platform", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("category", categoryOptions, "Category", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("subCategory", subCategoryOptions, "Sub Category", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("status", statusOptions, "Status", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("brand", brandOptions, "brand", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("paymentStatus", paymentStatusOptions, "paymentStatus", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("shippingPartner", shippingPartnerOptions, "Shipping Partner", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input className="form-control customInput" type="text" name="searchText" placeholder="Search" onChange={(e) => this.handleSearchInputChange(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: '20px 0px' }}>
                        <div style={{ float: "left" }}>
                            <div style={{ display: 'inline-block', width: '150px', margin: '0 10px'}}><Select placeholder="No. of Leads" options={this.state.limitoption} onChange={e => this.selectFetchLeads(e.value, e)} /></div>
                            <button className="btn btn-light" style={{ margin: '0 10px'}} onClick={e => this.fetchLeads()}>More Leads</button>
                            <CSVLink filename={"Orders Data.csv"} data={viewData} headers={showColumns.map(val => ({ label: val.csvHeader || val.Header, key: val.accessor }))} type="button" className="btn btn-light">
                                Download <i className="fa fa-download"></i>
                            </CSVLink>
                            {
                                     <span className="csvSpan" onClick={this.openUploadCsvModal}>Working with CSV ? </span>
                            }
                        </div>
                        <Modal open={this.state.uploadCsvModal} onClose={this.closeUploadCsvModal} styles={modalStyles} center>
                            <div style={{ border: "1px solid #92b0b3", padding: "5px" }}>
                            <div className=" container-fluid uploadBox">
                            <input style={{ width: "50 %", height: '42px', fontWeight: "bold" }} type="file" onChange={(e) => { this.uploadFile(e) }} accept=".csv"></input>
                            <button style={{ height: '38px', fontWeight: "bold" }} className="btn btn-info btn-sm" onClick={this.submitFileUpload}>Upload <i className="fa fa-upload"></i></button>
                            <br></br>
                            <div style={{ margin: "20px" }} >
                                <span style={{ color: "red" }}>{this.state.errorMessage ? this.state.errorMessage : ""}</span>
                                <span style={{ color: "green" }}>{this.state.successMessage ? this.state.successMessage : ""}</span>
                                <span style={{ color: "green" }}>{this.state.uploadMessage ? this.state.uploadMessage : ""}</span>
                            </div>
                            </div>
                            </div>
                        </Modal>
                        <div style={{ float: "right" }}>
                            <Link to="/zunsolar/createorder" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> Add New</button></Link>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={viewData}
                        columns={showColumns}
                        defaultSorted={[{ id: "createdOn", desc: true }]}
                        style={{ height: "90vh", textAlign: 'center' }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        loading={!orderDataReceived}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps({ zunSolarOrderTracker }) {
    let { allOrders = [], mapping = {}, comboList = [], orderDataReceived = false } = zunSolarOrderTracker || {};
    return {
        allOrders,
        mapping,
        comboList,
        orderDataReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchOrders, fetchMapping, fetchCombo, getSearchResult, downloadInventoryFile }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarAllOrderView);