import axios from 'axios';
import moment from 'moment';
import { FETCH_WALLET_TRANSACTIONS, FETCH_REDEEMED_VOUCHERS } from './actiontypes';

export function fetchWalletTrasactions() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`https://zunroof.com:3000/zuncoin/api/transactions/getAllTransactionsZrm?key=1D423rwmKnijvYfQ`);
            response = response.data || {};
            let { transactions = [] } = response;
            if(!transactions.length) return;
            dispatch({ type: FETCH_WALLET_TRANSACTIONS, payload: transactions || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}
export function fetchRedeemedVouchers() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`https://zunroof.com:3000/zuncoin/api/transactions/getredeemedvouchers?key=1D423rwmKnijvYfQ`);
            response = response.data || {};
            let { redeemedVouchers = [] } = response;
            if(!redeemedVouchers.length) return;
            dispatch({ type: FETCH_REDEEMED_VOUCHERS, payload: redeemedVouchers || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}