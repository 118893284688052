import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import url, { zcommerceUrl } from '../config'
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_ORDERS_SUCCESS, FETCH_ORDER_MAPPING_SUCCESS, FETCH_ORDERS_SUCCESS_NEW, FETCH_ORDER_MAPPING_SUCCESS_NEW, FETCH_ZUNPULSE_COMBO_LIST_SUCCESS, FETCH_ZUNSOLAR_WARRANTY_DETAILS_SUCCESS, FETCH_ZUNPULSE_COMBO_DETAILS ,FETCH_ORDERS_INVOICES_SUCCESS } from './actiontypes';
import { zunpulseOrderColumns } from '../config/column_fields';


export function fetchOrder(id) {
    return async (dispatch) => {
        try {
            let zunpulseOrders = await axios.post(`${url.pathBackend}zunpulse/orders/fetch`, { orderId: id });
            zunpulseOrders = zunpulseOrders.data || {};
            if(zunpulseOrders.order && zunpulseOrders.order.phoneNumber) {
                let { phoneNumber, orderId } = zunpulseOrders.order;
                let otherOrders = await axios.post(`${url.pathBackend}zunpulse/orders/fetch/all`, { phone: phoneNumber, id: orderId });
                otherOrders = otherOrders.data || {};
                zunpulseOrders.otherOrders = otherOrders.orders || {};
            }
            dispatch({ type: FETCH_ORDERS_SUCCESS, payload: zunpulseOrders || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchOrderNew(id) {
    return async (dispatch) => {
        try {
            let zunpulseOrders = await axios.post(`${url.pathBackend}zunpulse/newOrders/fetch`, { orderId: id });
            console.log(zunpulseOrders);
            zunpulseOrders = zunpulseOrders.data || {};
            let currentOrder = zunpulseOrders.order[0] || {};
            if(currentOrder && currentOrder.phoneNumber) {
                let { phoneNumber, orderId } = currentOrder;
                let otherOrders = await axios.post(`${url.pathBackend}zunpulse/newOrders/fetch/all`, { phone: phoneNumber, id: orderId });
                otherOrders = otherOrders.data || {};
                zunpulseOrders.otherOrders = otherOrders.orders || {};
            }
            currentOrder.firstReferralCode = zunpulseOrders.firstReferralCode || "";
            console.log({order: currentOrder, otherOrders: zunpulseOrders.otherOrders, products: zunpulseOrders.order}, zunpulseOrders, 'fetchOrderNew')
            dispatch({ type: FETCH_ORDERS_SUCCESS_NEW, payload: {order: currentOrder, otherOrders: zunpulseOrders.otherOrders, products: zunpulseOrders.order, comments: zunpulseOrders.comments, orderHistory: zunpulseOrders.orderHistory, invoiceType:zunpulseOrders.invoiceType} || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchOrdersMapping() {
    return async (dispatch) => {
        let mapping = await axios.post(`${url.pathBackend}zunpulse/orders/mapping`)
        dispatch({ type: FETCH_ORDER_MAPPING_SUCCESS, payload: { stateCityMapping: mapping.data.mapping, combos: mapping.data.combos } })
    }
}

export function fetchOrdersMappingNew() {
    return async (dispatch) => {
        let mapping = await axios.post(`${url.pathBackend}zunpulse/orders/mapping`)
        // console.log(mapping.data.mapping, mapping.data.combos);
        dispatch({ type: FETCH_ORDER_MAPPING_SUCCESS_NEW, payload: { stateCityMapping: mapping.data.mapping, combos: mapping.data.combos } })
    }
}

export function fetchAllOrdersNew(page = 1,limit = 500,oldData = [],conditions = undefined,searchText='') {
    return async (dispatch) => {
        try {
            if(Object.keys(conditions || {}).length < 1){
                conditions = undefined
            }
            let zunpulseOrders = await axios.post(`${url.pathBackend}zunpulse/newOrders/fetch/all`,{page, pageLimit:limit, conditions,searchText});

            zunpulseOrders = zunpulseOrders.data;
            dispatch({ type: FETCH_ORDERS_SUCCESS_NEW, payload: (oldData).concat(zunpulseOrders.orders || []) || {} });
            // dispatch({ type: FETCH_ORDER_MAPPING_SUCCESS_NEW, payload: zunpulseOrders.mapping || {} })
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchAllOrders() {
    return async (dispatch) => {
        try {
            let zunpulseOrders = await axios.post(`${url.pathBackend}zunpulse/orders/fetch/all`);
            // console.log("Orders Data", zunpulseOrders);
            zunpulseOrders = zunpulseOrders.data;
            let mapping = zunpulseOrders.mapping;
            // console.log("mapping", mapping)
            dispatch({ type: FETCH_ORDERS_SUCCESS, payload: zunpulseOrders.orders || {} });
            dispatch({ type: FETCH_ORDER_MAPPING_SUCCESS, payload: zunpulseOrders.mapping || {} })
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function generateInvoice(orderId,status,invoiceType,shipmentLocation) {
    return async(dispatch) => {
        try {
            // console.log("invoice generation working");
            let result = await axios.post(`${url.pathBackend}zunpulse/invoice/generateinvoice`,{orderId,status,invoiceType,shipmentLocation});
            // console.log(result);
            if (result.error) {
                swal(result.error);
            }
            swal(result.data.message);
        } 
        catch (e) {
            console.log(e);
        }
    }
}


export function downloadInvoicePdf (orderId,invoiceType) {
    return async(dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/invoice/downloadFile`,{orderId,invoiceType});
            console.log(response);
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            console.log(orderId,invoiceType);
            response = response.data;
            let fileData = response.fileData;
            let { Body = {}, ContentType } = fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'zunpulse_invoice.pdf');
            document.body.appendChild(link);
            link.click();
            console.log("download invoice pdf working");
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function fetchOrdersInvoices(page = 0,limit = 500,oldData = [],filters = undefined) {
    return async (dispatch) => {
        try {
            if(Object.keys(filters || {}).length < 1){
                filters = undefined
            }
            let zunpulseOrdersInvoices = await axios.post(`${url.pathBackend}zunpulse/invoice/`,{page, pageLimit:limit, filters});

            zunpulseOrdersInvoices = zunpulseOrdersInvoices.data.data;
            dispatch({ type: FETCH_ORDERS_INVOICES_SUCCESS, payload: (oldData).concat(zunpulseOrdersInvoices || []) || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateOrderStatusNew(ordersData, id, value, newData, updatedBy) {
    return async (dispatch) => {
        // console.log("before update", ordersData)
        try {
            let modifiedObj = {}
            Object.keys(newData).map((obj) => {
                if (ordersData[obj] != newData[obj]) {
                    modifiedObj[obj] = newData[obj]
                }
            });
            console.log("modifiedObj", modifiedObj)
            // if (Object.keys(modifiedObj).length) {
            //     Object.keys(modifiedObj).map((val) => {
            //         if (val == "orderDate" || val == "shippingDate" || val == "deliveryDate" || val == "customerOrderDate" || val == "nextActionDate" || val == "returnDate") {
            //             modifiedObj[val] = moment(modifiedObj[val], "YYYY-MM-DD").valueOf();
            //         }
            //     })
            // }
            modifiedObj["lastUpdatedBy"] = updatedBy || 'unknown'
            let payload = {
                id: id,
                status: value,
                newData: modifiedObj
            };
            console.log("payload", payload)
            let content = await axios.post(`${url.pathBackend}zunpulse/newOrders/update`, payload);
            console.log(content,'content')
            content = content.data;
            if (content.validate) {
                swal(content.validate);
            }
            if (content.error) {
                alert(content.error);
            }
            else {
                let updatedOrderData = content.updatedOrder;
                // if (ordersData && newData && +(ordersData.status) != +(newData.status) && ordersData.phoneNumber) {
                //     let whatsappRequestData = {
                //         key: "4VhfN1xiAD",
                //         orderId: ordersData.orderId || '',
                //         phoneNumber: ordersData.phoneNumber || '',
                //         customerName: ordersData.customerName || ''
                //     }
                //     switch (newData.status) {
                //         case 2:
                //             whatsappRequestData['trigger'] = 'orderShipped'
                //             axios.post('https://zuntalks.in:3000/zunpulse/whatsapp/trigger', whatsappRequestData)
                //             break;
                //         case 3:
                //             whatsappRequestData['trigger'] = 'orderDelivered'
                //             whatsappRequestData['productName'] = [ordersData.deviceName]
                //             axios.post('https://zuntalks.in:3000/zunpulse/whatsapp/trigger', whatsappRequestData)
                //             break;
                //     }
                // }
                if (!newData) {
                    // console.log("updatedOrderData", updatedOrderData)
                    // console.log("orderData", ordersData)
                    console.log("updated", { ...ordersData, [id]: updatedOrderData })
                    dispatch({ type: FETCH_ORDERS_SUCCESS_NEW, payload: { ...ordersData, [id]: updatedOrderData } });
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateOrderStatus(ordersData, id, value, newData, updatedBy) {
    return async (dispatch) => {
        // console.log("before update", ordersData)
        try {
            let modifiedObj = {}
            Object.keys(newData).map((obj) => {
                if (ordersData[obj] != newData[obj]) {
                    modifiedObj[obj] = newData[obj]
                }
            });
            if (Object.keys(modifiedObj).length) {
                Object.keys(modifiedObj).map((val) => {
                    if (val == "orderDate" || val == "shippingDate" || val == "deliveryDate" || val == "customerOrderDate") {
                        modifiedObj[val] = moment(modifiedObj[val], "YYYY-MM-DD").valueOf();
                    }
                })
            }
            modifiedObj["lastUpdatedBy"] = updatedBy || 'unknown'
            let payload = {
                id: id,
                status: value,
                newData: modifiedObj
            };
            let content = await axios.post(`${url.pathBackend}zunpulse/orders/update`, payload);
            content = content.data;
            if (content.error) {
                alert(content.error);
            }
            else {
                let updatedOrderData = content.updatedOrder;
                // if (ordersData && newData && +(ordersData.status) != +(newData.status) && ordersData.phoneNumber) {
                //     let whatsappRequestData = {
                //         key: "4VhfN1xiAD",
                //         orderId: ordersData.orderId || '',
                //         phoneNumber: ordersData.phoneNumber || '',
                //         customerName: ordersData.customerName || ''
                //     }
                //     switch (newData.status) {
                //         case 2:
                //             whatsappRequestData['trigger'] = 'orderShipped'
                //             axios.post('https://zuntalks.in:3000/zunpulse/whatsapp/trigger', whatsappRequestData)
                //             break;
                //         case 3:
                //             whatsappRequestData['trigger'] = 'orderDelivered'
                //             whatsappRequestData['productName'] = [ordersData.deviceName]
                //             axios.post('https://zuntalks.in:3000/zunpulse/whatsapp/trigger', whatsappRequestData)
                //             break;
                //     }
                // }
                if (!newData) {
                    dispatch({ type: FETCH_ORDERS_SUCCESS_NEW, payload: { ...ordersData, [id]: updatedOrderData } });
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function createNewOrderZunpulse(newOrderData) {
    return async (dispatch) => {
        try {
            let amount = 0;
            // Object.keys(newOrderData.cartDetails).map((val) => {
            //     amount = amount += parseInt(val.amountPaid)
            // })

            Object.keys(newOrderData).map((val) => {
                if (val == "orderDate" || val == "shippingDate" || val == "deliveryDate") {
                    newOrderData[val] = moment(newOrderData[val], "YYYY-MM-DD").valueOf();
                }
                if (val == "authPhoneNumber") {
                    newOrderData[val] = `+91${newOrderData[val]}`
                }
                if (val == "cartData") {
                    newOrderData[val].map((obj) => {
                        amount = amount + parseInt((obj.quantity)) * parseInt((obj.amountPaid))
                    })
                }
            })
            // console.log("amount", amount);
            let orderId = `ZPM${newOrderData.authPhoneNumber.substring(8, 12)}${new Date().getTime()}`;
            console.log(orderId, newOrderData)
            let data = {
                "apiKey": "VVyyTThghg67675757bbcYUU_###*!@@@#",
                "orderId": orderId,
                "amount": amount,
                ...newOrderData
            }
            // console.log("after date convrting", data);
            let response = await axios.post(`https://us-central1-zunpulsedevapp.cloudfunctions.net/createManualOrder`, data);
            console.log("response", response);
            if (response.data.status == "success") {
                if (newOrderData.paymentStatus == 'success' && newOrderData.authPhoneNumber) {
                    let whatsappRequestData = {
                        key: '4VhfN1xiAD',
                        orderId: orderId || '',
                        phoneNumber: newOrderData.authPhoneNumber || '',
                        customerName: newOrderData.name || '',
                        date: moment().format('DD/MM/YYYY'),
                        productName: (newOrderData.cartData || []).map((product) => product.deviceName),
                        trigger: 'orderSuccessful'
                    }
                    console.log(whatsappRequestData)
                    let whatsappTrigger = axios.post('https://zuntalks.in:3000/zunpulse/whatsapp/trigger', whatsappRequestData)
                }
                swal({
                    text: "Order Created Successfully",
                    buttons: {
                        cancel: "Close"
                    },
                    icon: "success",
                    content: (
                        <div>
                            <p>Order Id <span><a style={{ cursor: "pointer", color: "blue" }}>{orderId}</a></span></p>
                        </div>
                    )
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function createNewOrderZunpulseNew(newOrderData) {
    return async () => {
        try {
            let amount = 0;
            // Object.keys(newOrderData.cartDetails).map((val) => {
            //     amount = amount += parseInt(val.amountPaid)
            // })

            Object.keys(newOrderData).map((val) => {
                if (val == "orderDate" || val == "shippingDate" || val == "deliveryDate" || val == "nextActionDate" || val == "returnDate") {
                    newOrderData[val] = moment(newOrderData[val], "YYYY-MM-DD").valueOf();
                }
                if (val == "authPhoneNumber") {
                    newOrderData[val] = `+91${newOrderData[val]}`
                }
                if (val == "cartData") {
                    newOrderData[val].map((obj) => {
                        amount = amount + parseInt((obj.quantity)) * parseInt((obj.amountPaid))
                    })
                }
            })
            // console.log("amount", amount);
            let orderId = newOrderData.orderId;
            if(!orderId){
                    switch(newOrderData.orderType){
                        case "Return Pickup":
                            orderId = `RPU${newOrderData.authPhoneNumber.substring(8, 12)}${new Date().getTime()}`;
                            break;
                        case "Replacement":
                            orderId = `RP${newOrderData.authPhoneNumber.substring(8, 12)}${new Date().getTime()}`;
                            break;
                        default:
                            orderId = `ZPM${newOrderData.authPhoneNumber.substring(8, 12)}${new Date().getTime()}`;
                    }
            }
            console.log(orderId, newOrderData)
            let data = {
                "apiKey": "VVyyTThghg67675757bbcYUU_###*!@@@#",
                "key": "8ALS!Fcm#iWX_IgN",
                "orderId": orderId,
                "amount": amount,
                ...newOrderData
            }
            console.log(JSON.stringify(data));
            let response = await axios.post(url.pathBackend + "zunpulse/appApi/createManualOrder", data);
            response = response.data;
            console.log("response", response);
            // if (response.data == "Added succesfully") {
                // if (newOrderData.paymentStatus == 'success' && newOrderData.authPhoneNumber) {
                //     let whatsappRequestData = {
                //         key: '4VhfN1xiAD',
                //         orderId: orderId || '',
                //         phoneNumber: newOrderData.authPhoneNumber || '',
                //         customerName: newOrderData.name || '',
                //         date: moment().format('DD/MM/YYYY'),
                //         productName: (newOrderData.cartData || []).map((product) => product.deviceName),
                //         trigger: 'orderSuccessful'
                //     }
                //     console.log(whatsappRequestData)
                //     let whatsappTrigger = axios.post('https://zuntalks.in:3000/zunpulse/whatsapp/trigger', whatsappRequestData)
                // }
            swal({
                text: response.data,
                buttons: {
                    cancel: "Close"
                },
                // icon: "success"
            })
            // }
        } catch (error) {
            console.log(error)
        }
    }
}



export function addZunpulseOrderComment(newComment) {
    return async () => {
        let response = await axios.post(url.pathBackend + "zunpulse/orders/addComment", { newComment });
        console.log(response)
    }
}

export function addZunpulseOrderCommentNew(newComment) {
    return async () => {
        let response = await axios.post(url.pathBackend + "zunpulse/newOrders/addComment", { newComment });
        console.log(response)
    }
}

export function addZunpulseCombo(comboDetails) {
    return async () => {
        try {
            let response = await axios.post(url.pathBackend + "zunpulse/orders/addCombo", comboDetails);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            swal('combo Created')
        } catch (error) {
            alert('some error occured')
            console.log(error)
        }
        // let response = await axios.post(url.pathBackend + "zunpulse/orders/addCombo", { comboDetails });
        // console.log(response)
        // if (response.data.success) {
        //     swal({
        //         text: "Combo added",
        //         buttons: {
        //             cancel: "Close"
        //         },
        //         icon: "success",
        //         content: (
        //             <div>
        //                 <p><a style={{ cursor: "pointer", color: "blue" }} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + '/zunpulse/combo'); }}>Click here</a> to see added combo in table view</p>
        //             </div>
        //         )
        //     })
        // }
        // if (response.data.error) {
        //     swal({
        //         text: "Some error occured! Please try again",
        //         buttons: {
        //             cancel: "Close"
        //         },
        //         icon: "error"
        //     })
        // }
    }
}

export function updateCombo(comboData, productsData){
    return async () => {
        try {
            let response = await axios.post(url.pathBackend + "zunpulse/orders/updateCombo", {comboData, productsData});
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            swal('combo updated')
        } catch (error) {
            alert('some error occured')
            console.log(error)
        }
    }
}

export function fetchComboList() {
    return async (dispatch) => {
        let response = await axios.post(url.pathBackend + "zunpulse/orders/fetchCombos");
        response = response.data
        if(response.error){
            alert("Some Error Ocurred");
            return;
        }
        dispatch({ type: FETCH_ZUNPULSE_COMBO_LIST_SUCCESS, payload: { tableData: response.data || [], maxProduct: response.maxProduct || 1 } })
    }
}

export function fetchComboDetails(comboId) {
    return async (dispatch) => {
        let response = await axios.post(url.pathBackend + "zunpulse/orders/fetchComboByComboId", {comboId});
        response = response.data
        if(response.error){
            alert("Some Error Ocurred");
            return;
        }
        dispatch({ type: FETCH_ZUNPULSE_COMBO_DETAILS, payload: { combo: response.combo, productList: response.productList } })
    }
}

export function fetchWarrantyDetails(conditions = undefined) {
    return async (dispatch) => {
        if(Object.keys(conditions || {}).length < 1){
            conditions = undefined
        }
        // console.log(conditions);
        let warrantyDetails = await axios.post(`${zcommerceUrl.productPathBackend}user/getWarranty`,conditions);
        // console.log("232333434434", warrantyDetails);
        dispatch({ type: FETCH_ZUNSOLAR_WARRANTY_DETAILS_SUCCESS, payload: warrantyDetails.data.data || [] })
    }
};

export const handleWarrantyStatus = (status, warrantyId,allData)=>{
    return async(dispatch)=>{
	try {
        let warrantyMap = {}
        allData.forEach((data)=>{
            warrantyMap[data.warrantyId] = data
        })
		let text = "Press a button!\nEither Ok or Cancel.";
		if (window.confirm(text) == true) {
		  text = "You pressed OK!";
	

		const userData =  JSON.parse(localStorage.getItem('userObj'));
		console.log('userData', userData);
		const approvedBy = userData.email || "devteam@zunpulse.com"
		const apiData = {
			adminEmail: approvedBy,
			status: status,
			warrantyId: warrantyId
		}
		console.log('apiData', apiData)
		let warrantyRes = await axios.post(`${zcommerceUrl.productPathBackend}user/upadteWarrantyStatus`,apiData)
		warrantyRes = warrantyRes.data;
		console.log('warrantyRes',warrantyRes);
		if(warrantyRes && warrantyRes.data && warrantyRes.data.length){
			// window.location.reload(false);
         warrantyMap[warrantyId].status = status;
         allData = Object.values(warrantyMap)
        dispatch({ type: FETCH_ZUNSOLAR_WARRANTY_DETAILS_SUCCESS, payload: allData|| [] })

			alert("Operation Performed successfully");
			return;
		}
		alert("some error occured");
			return;
		console.log('warrantyres', warrantyRes );
	}
		
	} catch (error) {
		console.log('error', error);
		alert("some error occured");
		return;
		
	}
}
}

export function updateWarrantyClaimedStatus(allData, newDataToSend, warrantyId, adminEmail){
    return async(dispatch)=>{
        try {
            let apiData = {
                claimedStatus:newDataToSend.claimedStatus,
                adminEmail:adminEmail,
                warrantyId:warrantyId

            }
            let response = await axios.post(`${zcommerceUrl.productPathBackend}user/updateWarrantyClaimed`,apiData );
            response = response.data;
            if(response && response.data){
                let index = allData.findIndex(row => row.warrantyId == warrantyId);
                allData = JSON.parse(JSON.stringify(allData));
                Object.keys(newDataToSend).map((key) => {
                    allData[index][key] = newDataToSend[key]
                })
                dispatch({ type: FETCH_ZUNSOLAR_WARRANTY_DETAILS_SUCCESS, payload: allData || [] });

            }

            
        } catch (error) {
            
        }
    }
}

export function editBulkLeadsNew(tableData, selectedOrderIds, bulkAssignObj, updatedBy) {
    try {
        return async (dispatch) => {
            let selectedIds = [];
            Object.keys(selectedOrderIds).map((val) => {
                if (selectedOrderIds[val] == 1) {
                    selectedIds.push(val)
                }
            });
            Object.keys(bulkAssignObj).map((val) => {
                if (val == "orderDate" || val == "shippingDate" || val == "deliveryDate" || val == "returnDate") {
                    bulkAssignObj[val] = new Date(bulkAssignObj[val]).getTime();
                }
            });
            bulkAssignObj['lastUpdatedBy'] = updatedBy || 'unknown'
            bulkAssignObj['lastUpdatedOn'] = new Date().getTime();
            let response = await axios.post(`${url.pathBackend}zunpulse/newOrders/bulkUpdate`, { selectedIds, bulkAssignObj });
            console.log(response);
            if (response.error) {
                alert('Some error occured')
            } else {
                swal({
                    text: "Bulk Edit Successfull!! Please refresh page to see the changes",
                    buttons: {
                        cancel: "Close"
                    },
                    icon: "success"
                });
            }
        }
    } catch (e) {
        console.log(e)
    }
}

export function editBulkLeads(tableData, selectedOrderIds, bulkAssignObj, updatedBy) {
    try {
        return async (dispatch) => {
            let selectedIds = [];
            Object.keys(selectedOrderIds).map((val) => {
                if (selectedOrderIds[val] == 1) {
                    selectedIds.push(val)
                }
            });
            Object.keys(bulkAssignObj).map((val) => {
                if (val == "orderDate" || val == "shippingDate" || val == "deliveryDate" || val == "returnDate") {
                    bulkAssignObj[val] = new Date(bulkAssignObj[val]).getTime();
                }
            });
            bulkAssignObj['lastUpdatedBy'] = updatedBy || 'unknown'
            bulkAssignObj['lastUpdatedOn'] = new Date().getTime();
            let response = await axios.post(`${url.pathBackend}zunpulse/orders/bulkUpdate`, { selectedIds, bulkAssignObj });
            console.log(response);
            if (response.error) {
                alert('Some error occured')
            } else {
                swal({
                    text: "Bulk Edit Successfull!! Please refresh page to see the changes",
                    buttons: {
                        cancel: "Close"
                    },
                    icon: "success"
                });
            }
        }
    } catch (e) {
        console.log(e)
    }
}