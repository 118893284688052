import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import "../../styles/ProcurementPortalStyles.css";
import AffliateAddKYC from '../../components/zunsolar/AffliateAddKYC';
import AffliateKYCList from '../../components/zunsolar/AffliateKYCList';
import AddAffliate from '../../components/zunsolar/AddAffliate';
import ShowAffiliates from '../../components/zunsolar/ShowAffiliates';
import LedgerView from '../../components/zunsolar/LedgerView';
import AddPayment from '../../components/zunsolar/AddPayment';
import { addKYC, fetchKYCList , updateKYCStatus ,addAffliate , fetchAffiliateList, addPayment,downloadFile } from '../../actions/affliatePortalActions';


class AffiliatePortal extends Component {

    constructor() {
        super();
        this.state = {
            selectedTab: "addKYC",
        };
    }

    componentWillMount() {
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
    }

    handleOnViewChange = (e) => {
        let { name = '' } = e.target;
        this.setState({ selectedTab: name });
    }

    TabsComponent = ({ name, label }) => {
        let { selectedTab = '' } = this.state;
        let { handleOnViewChange } = this;
        return <button className={`tabButtons btn btn-sm ${selectedTab === name ? "btn-info" : "btn-outline-info"}`} name={name} onClick={handleOnViewChange}>{label}</button>;
    }

    render() {

        let { TabsComponent, state, props } = this;
        let { selectedTab } = state;
        let { addKYC ,KYCList ,fetchKYCList ,updateKYCStatus ,addAffliate , affiliateList , fetchAffiliateList , addPayment , isAdded, isAffiliateAdded, isPaymentAdded, downloadFile }  = props;

        return (
            <div>
                <Header />
                <div className={`container-fluid procurementMainDiv`}>
                    <div className="tabComponent">
                        <TabsComponent name="addKYC" label="Add KYC" />
                        <TabsComponent name="KYCList" label="KYC List" />
                        <TabsComponent name="addAffiliate" label="Add Affiliate" />
                        <TabsComponent name="affiliateList" label="Show Affiliates" />
                        <TabsComponent name="ledger" label="Ledger" />
                        <TabsComponent name="addPayment" label="Add Payment" />
                    </div>
                    {
                        selectedTab === "addKYC" ? <AffliateAddKYC
                            addKYC={addKYC}
                            isAdded={isAdded}
                        /> : ""
                    }
                    {
                        selectedTab === "KYCList" ? <AffliateKYCList
                            fetchKYCList={fetchKYCList}
                            KYCList={KYCList}
                            downloadFile={downloadFile}
                            updateKYCStatus={updateKYCStatus}
                        />:""
                    }
                    {
                        selectedTab === "addAffiliate" ? <AddAffliate
                            addAffliate={addAffliate}
                            isAffiliateAdded={isAffiliateAdded}
                        /> : ""
                    }
                    {
                        selectedTab === "affiliateList" ? <ShowAffiliates
                            fetchAffiliateList={fetchAffiliateList}
                            affiliateList={affiliateList}
                        />:""
                    }
                    {
                        selectedTab === "ledger" ? <LedgerView
                        />:""
                    }
                    {
                        selectedTab === "addPayment" ? <AddPayment
                        addPayment={addPayment}
                        isPaymentAdded={isPaymentAdded}
                        />:""
                    }
                </div>
            </div>
        )
    }

}

function mapStateToProps({ affliatePortal }) {
    let { KYCList , affiliateList , isAdded, isAffiliateAdded, isPaymentAdded } = affliatePortal ;
    console.log(affiliateList);
    return {
        KYCList,
        affiliateList,
        isAdded,
        isAffiliateAdded,
        isPaymentAdded
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, addKYC, fetchKYCList ,updateKYCStatus ,addAffliate , fetchAffiliateList , addPayment, downloadFile }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AffiliatePortal);