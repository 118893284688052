import { FETCH_USERS_START,FETCH_USERS_SUCCESS,FETCH_USERS_ERROR } from '../actions/actiontypes';

export default function(state=[],action){
  switch(action.type){
		case FETCH_USERS_START:{
			return [];
    }
    case FETCH_USERS_ERROR : {
      return {...state,error:action.payload}
    }
		case FETCH_USERS_SUCCESS:{
			return {...state, ...action.payload};
		}
		default:
			return state;
	}
}