import { FETCH_MT_PAYMENT_HISTORY, FETCH_MT_SEARCH_VISITS, FETCH_MT_VISIT_DETAILS } from "../actions/actiontypes";

export default function(state = '', action) {
	switch(action.type){
		case FETCH_MT_VISIT_DETAILS:{
			return {...state,mtVisitDetails:action.payload};
		}
		case FETCH_MT_PAYMENT_HISTORY:{
			return {...state, mtPaymentHistory:action.payload}
		}
		case FETCH_MT_SEARCH_VISITS:{
			return {...state, mtSearchVisits: action.payload}
		}
		
		default:
			return state;
	}
}