import React from 'react';
import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from 'sweetalert';
import { SA_VIEW_FETCH_DATA_START, SA_VIEW_FETCH_DATA_ERROR, SA_VIEW_FETCH_DATA_SUCCESS } from './actiontypes';

export function saViewGet(state, startDate, endDate) {
  return async (dispatch) => {
    dispatch({ type: SA_VIEW_FETCH_DATA_START, payload: [] });

    if (!state && !startDate && !endDate) {
      let response = await axios.post(url.pathBackend + 'sales/')
      let data = response.data ? response.data : {}
      if (Object.keys(data).length > 0) {
        dispatch({ type: SA_VIEW_FETCH_DATA_SUCCESS, payload: data })
      }
    } else {
      let response = await axios.post(url.pathBackend + 'sales/', { state, startDate, endDate })
      let data = response.data ? response.data : {}
      if (Object.keys(data).length > 0) {
        dispatch({ type: SA_VIEW_FETCH_DATA_SUCCESS, payload: data })
      }
    }
  }
}

export function saveRemarks(reasonMap) {
  return () => {
    axios.post(url.pathBackend + 'sales/reason', reasonMap).then((response) => {
    }).catch((error) => {
      console.log(error)
    })
  }
}