import axios from 'axios';
import url from '../config';
import { salesSummaryData, orderValueData, shippingSummaryData, lastTenDaysSummaryData } from '../helper/zunpulseDashboardHelper';
import { FETCH_ZUNPULSEOPS_ORDERS_DATA, FETCH_ZUNPULSEOPS_SALES_SUMMARY, FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY, FETCH_ZUNPULSEOPS_ORDER_VALUE, FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY, FETCH_LATEST_EDITED_ZUNPULSE_ORDER, FETCH_METRICS_SUMMARY, FETCH_ZUNPULSEOPS_SALES_SUMMARY_SUBORDER, FETCH_ZUNPULSEOPS_SALES_SUMMARY_UNIT, FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_SUBORDER, FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_UNIT, FETCH_ZUNPULSEOPS_ORDER_VALUE_VIEW,
    FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_SUBORDER, FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_UNIT, FETCH_ZUNPULSEOPS_MAPPING, FETCH_ZUNPULSEOPS_CATEGORY_SUMMARY, FETCH_ZUNPULSEOPS_STATE_SUMMARY, FETCH_ZUNPULSEOPS_MAPDATA, FETCH_ZUNPULSEOPS_ORDER_SUMMARY, FETCH_ZUNPULSEOPS_SALES_SUMMARY_ORDER, FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_ORDER, FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_ORDER } from './actiontypes';

export function fetchZunpulseDashboard() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/orders/fetch/dashboard`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let ordersData = response.data;
            let lastEdited = response.lastEdited
            // console.log(ordersData)
            let salesSummary = salesSummaryData(ordersData, {});
            let orderValue = orderValueData(ordersData, {});
            let shippingSummary = shippingSummaryData(ordersData, {});
            let lastTenDaysSummary = lastTenDaysSummaryData(ordersData, {});
            dispatch({ type: FETCH_ZUNPULSEOPS_ORDERS_DATA, payload: ordersData || [] });
            dispatch({ type: FETCH_ZUNPULSEOPS_SALES_SUMMARY, payload: salesSummary || [] });
            dispatch({ type: FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY, payload: shippingSummary || [] });
            dispatch({ type: FETCH_ZUNPULSEOPS_ORDER_VALUE, payload: orderValue || [] });
            dispatch({ type: FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY, payload: lastTenDaysSummary || [] });
            dispatch({ type: FETCH_LATEST_EDITED_ZUNPULSE_ORDER, payload: lastEdited });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMetricsSummary() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchmetricssummary`);
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { metricsSummary = {} } = response;
            dispatch({ type: FETCH_METRICS_SUMMARY, payload: metricsSummary || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSalesSummaryOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchordersummary`, {
                dateFormatType: 'month',
                orderByKey: 'source',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_SALES_SUMMARY_ORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSalesSummarySubOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchsummary`, {
                tableType: 'SKU',
                dateFormatType: 'month',
                orderByKey: 'source',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_SALES_SUMMARY_SUBORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSalesSummaryUnitView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchsummary`, {
                tableType: 'Unit',
                dateFormatType: 'month',
                orderByKey: 'source',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_SALES_SUMMARY_UNIT, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchLastTenDaysSummaryOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchordersummary`, {
                dateFormatType: 'date',
                orderByKey: 'source',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_ORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchLastTenDaysSummarySubOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchsummary`, {
                tableType: 'SKU',
                dateFormatType: 'date',
                orderByKey: 'source',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_SUBORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchLastTenDaysSummaryUnitView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchsummary`, {
                tableType: 'Unit',
                dateFormatType: 'date',
                orderByKey: 'source',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_UNIT, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchShippingSummaryOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchordersummary`, {
                dateFormatType: 'month',
                orderByKey: 'status',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_ORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchShippingSummarySubOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchsummary`, {
                tableType: 'SKU',
                dateFormatType: 'month',
                orderByKey: 'status',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_SUBORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchShippingSummaryUnitView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchsummary`, {
                tableType: 'Unit',
                dateFormatType: 'month',
                orderByKey: 'status',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_UNIT, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchOrderValueView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchordervalue`, {
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_ORDER_VALUE_VIEW, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchCategorySummary(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchcategorysummary`, {
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_CATEGORY_SUMMARY, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchStateSummary(selectedCategory, filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchstatesummary`, {
                filters,
                selectedCategory
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_STATE_SUMMARY, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMapData(mapViewType = '', filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchmapview`, {
                filters,
                mapViewType
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            dispatch({ type: FETCH_ZUNPULSEOPS_MAPDATA, payload: response || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMapping() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchmapping`);
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            dispatch({ type: FETCH_ZUNPULSEOPS_MAPPING, payload: response });
        }
        catch(e) {

        }
    }
}

export function fetchSummaryOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/opsdashboard/fetchordersummary`, {
                dateFormatType: 'month',
                orderByKey: 'source',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNPULSEOPS_ORDER_SUMMARY, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

const getColumnFields = (columns) => {
    let columnFields = [];
    columns.forEach(field => {
        if (!field) return;
        let fieldConfig = {
            Header: field,
            accessor: field
        };
        if(field == 'average') {
            fieldConfig = {
                Header: field,
                accessor: field
            };
        }
        else if (field == 'month' || field == 'date' || field == 'state') {
            fieldConfig.Header = row => {
                let { data } = row;
                return `${field} (${data.length})`;
            }
        }
        else {
            fieldConfig.Header = row => {
                let { data } = row;
                let total = data.reduce((a, b) => a + (!isNaN(b[field]) ? parseInt(b[field]) : 0), 0);
                return `${field} (${total.toLocaleString("en-IN")})`;
            }
            fieldConfig.Cell = row => (parseInt(row.value || 0)).toLocaleString("en-IN")
        }
        columnFields.push(fieldConfig);
    })
    return columnFields;
}