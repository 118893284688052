import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';


class AddPayment extends Component {

    constructor() {
        super();
        this.myRef=React.createRef();
        this.state = {
            paymentData: {}
        };
    }

    componentWillReceiveProps({ isPaymentAdded }) {
        console.log('working');
        const form=this.myRef.current;
        if (isPaymentAdded){
            setTimeout(()=>{
                form.reset()
            },200);
            this.setState({paymentData:{}})
        }
    }

    AddPaymentInput = ({ label, name, type, required = true, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <input className="form-control customInput" type={type} name={name} placeholder={label} onChange={(e) => this.handleAddPaymentInputChange(e)} {...inputParams} required={required} />
                    </div>
                </div>
            </div>
        )
    }


    handleAddPaymentInputChange = (e) => {
        let { name, value } = e.target;
        let { paymentData = {} } = this.state;
        paymentData[name] = value;
        this.setState({
            paymentData
        })
    }



    handleSubmitVendorForm = (e) => {
        e.preventDefault();
        let { paymentData = {} } = this.state;
        paymentData.status = 'portalPayment';
        this.props.addPayment(paymentData);
        console.log("Submit", paymentData);
    }

    render() {

        let { AddPaymentInput, handleSubmitVendorForm } = this;

        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Add Payment</h4>
                <form onSubmit={handleSubmitVendorForm} ref={this.myRef}>
                    <div className="addVendorForm">
                        <div className="row">
                            <AddPaymentInput
                                label="Reference Id"
                                name="referenceId"
                                type="text"
                                required={true}
                            />
                            <AddPaymentInput
                                label="Phone"
                                name="phoneNumber"
                                type="number"
                                required={true}
                                inputParams={{
                                    pattern: "[0-9]{10}",
                                    minLength: 10,
                                    maxLength: 10
                                }}
                            />
                            <AddPaymentInput
                                label="Payment Date"
                                name="date"
                                type="date"
                                inputParams={{
                                    max: moment().format("YYYY-MM-DD"),
                                    onKeyDown:function(e){
                                        e.preventDefault()
                                    }
                                }}
                                required={true}
                            />
                            <AddPaymentInput
                                label="Payment Amount"
                                name="amount"
                                type="number"
                                required={true}
                            />
                            
                        </div>
                        <button type="submit" className="btn btn-outline-success" style={{ float: 'right' }}>Submit</button>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </form>
            </div>
        )   
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);