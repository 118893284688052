import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_KYC_DETAILS, FETCH_AFFILIATE_DETAILS, FETCH_CONSOLIDATED_DETAILS , FETCH_DETAILED_DETAILS, START_ADD_KYC, ADD_KYC_SUCCESS, START_ADD_AFFILIATE, ADD_AFFILIATE_SUCCESS, START_ADD_PAYMENT, ADD_PAYMENT_SUCCESS  } from './actiontypes';

export function addKYC(vendorData = {}) {
    return async (dispatch) => {
        try {
            console.log(vendorData);
            let formData = new FormData();
            Object.keys(vendorData).forEach(key => {
                let value = vendorData[key];
                console.log(key, value);
                formData.append(key, value);
            });
            dispatch({type: START_ADD_KYC});
            let response = await axios.post(`${url.pathBackend}zunsolar/kyc/addData`, formData);
            response = response.data;
            if (response.e || response.error) {
                let errorType=response.error.split(".")[1];
                let message = `You have entered duplicate ${errorType=="PRIMARY'"?"Phone Number":errorType}`;
                swal(message);
                return;
            }
            swal(response.messsage).then(res=>{
                dispatch({type : ADD_KYC_SUCCESS})
            });
        }
        catch (e) {
            console.log(e);
            swal('Error while submitting form');
        }
    }
}

export function fetchKYCList() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/kyc/getData`);
            response = response.data;
            console.log(response);
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_KYC_DETAILS, payload: response.data });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function updateKYCStatus(data) {
    return async (dispatch) => {
        try {
            console.log(data);
            let response = await axios.post(`${url.pathBackend}zunsolar/kyc/updateStatus`,data)
            response = response.data;
            console.log(response);
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            swal("KYC Approved");
        }catch(e){
            console.log(e);
        }
    }
}


export function addAffliate(affliateData={}) {
    return async (dispatch) => {
        try {
            console.log(affliateData);
            let formData = new FormData();
            Object.keys(affliateData).forEach(key => {
                let value = affliateData[key];
                console.log(key, value);
                formData.append(key, value);
            });
            dispatch({type: START_ADD_AFFILIATE});
            let response = await axios.post(`${url.pathBackend}zunsolar/affiliate/addNew`, formData);
            response = response.data;
            console.log(response);
            if (response.e || response.error) {
                console.log(response);
                swal(response.error);
                return;
            }
            swal(response.message).then((res)=>{
                dispatch({type: ADD_AFFILIATE_SUCCESS});
            });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchAffiliateList() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/affiliate/getAll`);
            response = response.data;
            console.log(response,'line no 104');
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_AFFILIATE_DETAILS, payload: response.data });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchConsolidatedList() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/ledger`);
            response = response.data;
            console.log(response,'line no 104');
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_CONSOLIDATED_DETAILS, payload: response.ledgerData });
        }
        catch (err) {
            console.log(err);
        }
    }
}

export function fetchDetailedList() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/ledger/detail`);
            response = response.data;
            console.log(response,'line no 104');
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_DETAILED_DETAILS, payload: response.data });
        }
        catch (e) {
            console.log(e);
        }
    }
}



export function addPayment(paymentData={}) {
    return async (dispatch) => {
        try {
            console.log(paymentData);
            let formData = new FormData();
            Object.keys(paymentData).forEach(key => {
                let value = paymentData[key];
                if(key==="date"){
                    let dateFormat = moment(paymentData[key]).format("MMMM,Do YYYY")
                    value=dateFormat;
                }
                console.log(key, value);
                formData.append(key, value);
            });
            dispatch({type:START_ADD_PAYMENT});
            let response = await axios.post(`${url.pathBackend}zunsolar/ledger/addTransaction`, formData);
            response = response.data;
            console.log(response);
            if (response.e || response.error) {
                console.log(response);
                swal(response.error);
                return;
            }
            swal(response.message).then((res)=>{
                dispatch({type:ADD_PAYMENT_SUCCESS});
            });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function downloadFile(filename) {
    return async (dispatch) => {
        try {
            console.log(filename);
            let response = await axios.post(`${url.pathBackend}zunsolar/kyc/download`, { filename });
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let fileData = response.fileData;
            let { Body = {}, ContentType } = fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        catch(e) {
            console.log("Some error occured", e);
            return;
        }
    }
}