import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/leadsView.css';
import ReactTable from "react-table";
import moment from "moment";
import swal from 'sweetalert';
import { Loader } from '../loader';
import Select from 'react-select'
import Modal from 'react-responsive-modal';


class ZunpulseSingleLeadViewComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      newComment: '',
      leadData: {},
      newLeadData: {},
      username: '',
      comments: [],
      mappingData: {},
      loading: true,
      recieved: false,
      showUtmVariables: false,
      mappingOptions:{},
      productMap:{},
      customerRequestedData:{},
      customerRecommendedData :{},
      customerRequestQuotationData :[],
      customerRecommendedQuotationData:[],
      showModal:false,
      showSingleQuotation: false,
      requestedDataForm :[{category:'', productId:'', variant:'', quantity:'', price:''}],
      recommendedDataForm :[{category:'', productId:'', variant:'', quantity:'', price:''}],
      sellingPriceMapping:{},
      quotationDiscount:'',
      activeQuotationId:'',
      quotationData:[],
      stateCityMap:{}
    }

    this.commentColumns = [
      {
        Header: 'Comment By',
        accessor: 'commentedBy'
      },
      {
        Header: 'Time',
        accessor: 'timestamp',
        Cell: row => {
          return new Date(parseInt(row.original.timestamp)).toLocaleDateString();
        }
      },
      {
        Header: 'Comment',
        accessor: 'commentBody',
        style: { 'whiteSpace': 'unset' }
      }
    ];
    this.historyColumns = [
      {
        Header: 'Time',
        accessor: 'timestamp',
        Cell: row => {

          return moment(+row.original.timestamp).format('DD/MM/YYYY hh:mm a');
        }
      },
      {
        Header: 'Updated By',
        accessor: 'updatedBy',
        Cell: row => {

          return row.original.updatedBy.split('@')[0].split('.')[0] + ' ' + row.original.updatedBy.split('@')[0].split('.')[1]
        }
      },
      {
        Header: 'Field Name',
        accessor: 'updatedField'
      },
      {
        Header: 'Old Value',
        accessor: 'updatedFrom'
      },
      {
        Header: 'New Value',
        accessor: 'updatedTo'
      }
    ];
  }

  componentWillMount() {
    if (localStorage.getItem("userObj")) {
      let userObj = JSON.parse(localStorage.getItem("userObj"));
      let username = userObj.username;
      this.setState({
        username: username
      })
    }
  }

  componentWillReceiveProps(props) {
    let leadDetails = props.leadDetails;
    let customerQuotationData = leadDetails.zunpulseCustomerQuotation || []
    let quotationProducts = leadDetails.zunpulseQuotationProducts || []
    let mappingData = props.allMapping ? props.allMapping : {};
    let unicommercMapping = props.unicommercMapping;
    let sellingPriceMapping = props.sellingPriceMapping
    let category = []
    let productMap ={};
    let productOptions = {};
    let categoryOptions = []
    let mappingOptions = {}
    let customerRecommendedQuotationData =[]
    let customerRequestQuotationData = [];
    quotationProducts.forEach((quotation)=>{
      if(quotation.quotationType == "request"){
        customerRequestQuotationData.push(quotation);
  
      }
      else{
        customerRecommendedQuotationData.push(quotation);
      }
    })
    unicommercMapping.forEach((product)=>{
      productMap[product.pID] = product;
      category.push(product.category);
      if(!productOptions[product.category]){
        productOptions[product.category]= []
      }
      productOptions[product.category].push({label:`${product.productName} ${product.variantType && product.variantType.toLowerCase()!="null"?product.variantType:''}`,value:`${product.pID} ${product.variantType && product.variantType!="null"?product.variantType:''}`})
    

    })
    category = [...new Set(category)];
    categoryOptions = category.map((data)=>{
      return {label:data, value:data}
      
  })
 
  mappingOptions.categoryOptions = categoryOptions;
  mappingOptions.productOptions = productOptions;
  mappingOptions.productMap = productMap

    if (leadDetails.data) {
      this.setState({
        leadData: (leadDetails && leadDetails.data) ? leadDetails.data : {},
        mappingData: mappingData,
        loading: false,
        recieved: true,
        mappingOptions:mappingOptions,
        customerQuotationData:customerQuotationData,
        customerRecommendedQuotationData:customerRecommendedQuotationData,
        customerRequestQuotationData: customerRequestQuotationData,
        sellingPriceMapping,
        quotationData: customerQuotationData,
        stateCityMap : mappingData && mappingData.stateCityMap || {}
      })
    }

  }

  handleEdit = () => {
    this.editDetails = {};
    if (this.state.edit) {
      this.setState({
        edit: false
      })
    } else {
      this.setState({
        mappingData: { ...this.props.allMapping },
        newLeadData: { ...this.state.leadData },
        edit: true
      })
    }
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    value = this.checkSelectString(value);
    if (name == "establishment_type") {
      let establishment_question = (this.state.mappingData.establishments[value] || {}).title
      this.setState({
        newLeadData: {
          ...this.state.leadData,
          [name]: value,
          establishment_question
        }
      })
    }
    else {
      this.setState({
        // mappingData :{
        //     ...this.state.mappingData,
        //     [name] : value,
        // },
        newLeadData: {
          ...this.state.newLeadData,
          [name]: value,
        }
      })
    }
  }

  handlePageRefresh = () => {
    this.props.fetchZunpulseLead(this.state.leadData.leadId);
  }

  handleUtmDivToggle = () => {
    this.setState({
      showUtmVariables: !this.state.showUtmVariables
    })
  }

  onClickCancel = () => {
    this.setState({
      edit: false
    })
  }

  onCommentChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      newComment: value,
    })
  }

  commentSubmit = (e) => {
    let comments = [...this.state.leadData.comments];
    let newComment = this.state.newComment;
    newComment = newComment.trim();
    if (newComment.length > 0) {
      let newCommentData = {
        leadId: this.state.leadData.leadId,
        commentedBy: this.state.username,
        commentBody: newComment,
        timestamp: new Date().getTime()
      };
      comments.push(newCommentData);
      this.props.addComments(newCommentData, 'zunpulse');
      this.setState({
        newLeadData: {
          ...this.state.newLeadData,
          comments: comments,
        },

        leadData: {
          ...this.state.leadData,
          comments: comments,
        },
        newComment: ''
      })
    }

  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }

  onNewLeadDataSubmit = (e) => {
    let leadId = this.state.leadData.leadId;
    let oldData = this.state.leadData;
    let newData = this.state.newLeadData
    this.props.editLead(newData, oldData, leadId)
    this.setState({
      edit: false,
      leadData: { ...this.state.newLeadData },
    })
  }


  handleOnChangeDiscount = (e)=>{
    let {value } = e;
    this.setState({
      quotationDiscount:value
    })
  }

  handleOnChangeCustomerRequest = (e,name,index)=>{
    let {value} = e;
    let pid='';
    let variant='';
    let {requestedDataForm, sellingPriceMapping} = this.state;
    if(name=="productId"){
      let productData = value.split(" ")
     pid = productData[0];
      variant = productData[1] && productData[1].toLowerCase()!="null"? productData[1]: '';
      value = pid;
      requestedDataForm[index].variant=variant;
      requestedDataForm[index][name] =value;
      requestedDataForm[index].price = sellingPriceMapping[value] || 0
    }
    else{

      requestedDataForm[index][name]=value
  }
  this.setState({
    requestedDataForm:requestedDataForm
  })

  }

  handleAddRequestRow = ()=>{
    let {requestedDataForm} = this.state;
    requestedDataForm = [...requestedDataForm,{category:'', productName:'', variant:'', quantity:''}]
    this.setState({
      requestedDataForm
    })

  }


  handleAddRecommendedRow = ()=>{
    let {recommendedDataForm} = this.state;
    recommendedDataForm = [...recommendedDataForm,{category:'', productName:'', variant:'', quantity:''}]
    this.setState({
      recommendedDataForm
    })

  }

  handleSubmitQuotation = ()=>{
    let {customerRequestedData,mappingOptions, username, leadData, requestedDataForm, sellingPriceMapping, recommendedDataForm, quotationDiscount} = this.state;
    for(let i=0;i<requestedDataForm.length;i++){
      let {category, productId, quantity,price} = requestedDataForm[i];
      if(!category || !productId || !quantity || !price){
        alert("Required Fields Missing");
        return;
      }
    }
    for(let i=0;i<recommendedDataForm.length;i++){
      let {category, productId, quantity,price} = recommendedDataForm[i];
      if(!category || !productId || !quantity || !price){
        alert("Required Fields Missing");
        return;
      }
    }

   let quotationId = leadData.leadId+"_"+ Date.now();
    let quotationProductData = [];
    requestedDataForm.forEach((data)=>{
      let {category, productId, quantity,variant, price} = data
      let productData = mappingOptions.productMap[productId];
       let requestData = {
      category: category,
      subCategory: productData.subCategory,
      productName: productData.productName,
      pid: productId,
      variant: variant || "",
      quantity: quantity ,
      quotationType: "request",
      price:price,
      quotationId:quotationId
    }
    quotationProductData.push(requestData);

    })
    recommendedDataForm.forEach((data)=>{
      let {category, productId, quantity,variant, price} = data
      let productData = mappingOptions.productMap[productId];
       let requestData = {
      category: category,
      subCategory: productData.subCategory,
      productName: productData.productName,
      pid: productId,
      variant: variant || "",
      quantity: quantity ,
      quotationType: "recommended",
      price:price,
      quotationId:quotationId
    }
    quotationProductData.push(requestData);

    })
    let quotationData = {
      leadId:leadData.leadId,
      discount: quotationDiscount || 0,
      createdBy: username,
      quotationId:quotationId

    }
    let apiData= {
      quotationProductsData : quotationProductData,
      quotationData: quotationData
    }
    this.props.addZunpulseCustomerQuotation(apiData)

  }


  handleOnChangeCustomerRecommended = (e,name,index)=>{
    let { value } = e;
    let pid = '';
    let variant = '';
    let { recommendedDataForm, sellingPriceMapping } = this.state;
    if (name == "productId") {
      let productData = value.split(" ")
      pid = productData[0];
      variant = productData[1] && productData[1].toLowerCase()!="null"? productData[1]: '';
      value = pid;
      recommendedDataForm[index].variant = variant;
      recommendedDataForm[index][name] = value;
      recommendedDataForm[index].price = sellingPriceMapping[value] || 0;
    }
    else {
      recommendedDataForm[index][name] = value
    }
    this.setState({
      recommendedDataForm: recommendedDataForm
    })

  }
  selectType = (name, value, options) => {
    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }
  handleCloseModal = ()=>{
    this.setState({
      showModal:false
    })
  }
  handleOpenModal = ()=>{
    this.setState({
      showModal:true
    })
  }

  handleShowSingleQuotation = ()=>{
    this.setState({
      showSingleQuotation:true
    })

  }

  handleCloseSingleQuotation = ()=>{
    this.setState({
      showSingleQuotation:false
    })
  }

  handleShowSingleQuotationModal = (row)=>{
    let quotationId = row.original.quotationId;
    this.setState({
      showSingleQuotation:true,
      customerRecommendedQuotationData:[],
      customerRecommendedQuotationData:[]

    },()=>{
      this.props.getZunpulseQuotationProducts(quotationId)
    })

  }
  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  inputType = (name, value, type, placeholder) => {
    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
  }

  render() {

    const modalStyles = {
      modal: {
          width: '100%',
          maxWidth: '100%',
          padding: '40px',
          background: '#f7f7f7',
          textAlign: 'center'
      }
  }
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }

    const quotationColumns = [
     
      {
        Header: 'lead Id',
        accessor: 'leadId',
      },
      {
        Header: 'Quotation Id',
        accessor: 'quotationId',
        Cell: row => (<div style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => this.handleShowSingleQuotationModal(row)}>{row.value}</div>)

      },
      {
        Header: 'Discount',
        accessor: 'discount',
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },

      {
        Header: 'Generated By',
        accessor: 'generatedBy',
      },
      {
        Header: 'Created On',
        accessor: 'createdOn',
      }
    ]

    const customerRequestColumns = [
     
      {
        Header: 'Category',
        accessor: 'category'
      },
      {
        Header: 'Sub Category',
        accessor: 'subCategory',
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
      },
      {
        Header: 'Product Id',
        accessor: 'pid',
      },

      {
        Header: 'Variant',
        accessor: 'variant',
      },
       {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Price',
        accessor: 'price',
      }
    ]


    const customerRecommendedColumns = [

      {
        Header: 'Category',
        accessor: 'category'
      },
      {
        Header: 'Sub Category',
        accessor: 'subCategory',
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
      },
      {
        Header: 'Product Id',
        accessor: 'pid',
      },

      {
        Header: 'Variant',
        accessor: 'variant',
      },
       {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Price',
        accessor: 'price',
      }
    ]
    if (this.state.recieved) {
      return (
        <div className="container-fluid" style={{ marginTop: "80px" }}>


          <div className="topbar-section" >
            <div className="row">
              <div className="col-md-9 col-xs-12 topbar-left">
                <div className="col-md-12">
                  <strong>Lead</strong>
                  <strong style={{ marginRight: "5px" }}> # {(this.state.leadData.leadId ? this.state.leadData.leadId : '')}</strong>
                  <strong style={this.state.edit ? style.hide : style.show}>{this.state.leadData.name ? this.state.leadData.name.replace(/(^"|"$)/g, '') : ''}</strong>
                  <span style={this.state.edit ? style.show : style.hide} className="input-group titleInput" >
                    {this.inputType("name", this.state.newLeadData.name, "text", "Lead Name")}
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 topbar-right">
                <div className="col-md-12 col-xs-12">
                  <span style={this.state.edit ? style.hide : style.show}><i onClick={this.handleEdit} style={{ cursor: "pointer", float: "right", marginTop: "8px" }} className="fas fa-pen"></i></span>
                  <button style={this.state.edit ? style.show : style.hide} className="button-edit" onClick={(e) => this.onNewLeadDataSubmit(e)}>Save</button>
                  <button style={this.state.edit ? style.show : style.hide} className="button-cancel" onClick={(e) => this.onClickCancel(e)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>

          <Modal open={this.state.showModal} styles={modalStyles} onClose={this.handleCloseModal}>
            <div>
              <div className='topbar-section'>
                <div>
                  <h4><b>Quotation Form</b></h4>
                </div>
                <hr></hr>
                <div className='mb-3'>
            <div className="">
              <div className="">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-left"></span>
                    <h4><b> Requested Products</b></h4>
                    <hr></hr>
                  </div>
                  <div className="mb-4" style = {{textAlign: 'right'}}>
                    <button  type="button" className="btn btn-success" onClick={() => this.handleAddRequestRow()}>Add Row</button>
                  </div>

                  {this.state.requestedDataForm.map((data, index)=>(
              <div className = "row mb-3" key={index}>
              <div className=" form-group col-3">
                  <Select
                  required={true}
                  placeholder = {"select category"}
                    onChange={(e) => { this.handleOnChangeCustomerRequest(e,"category", index) }}
                    options = {this.state.mappingOptions.categoryOptions}
                  />

                  </div>
                  <div className="col-3">
                  <Select
                  placeholder = {"select product"}
                  required={true}
                    onChange={(e) => { this.handleOnChangeCustomerRequest(e,"productId",index) }}
                    options = {this.state.mappingOptions.productOptions[this.state.requestedDataForm[index].category]}
                  />
                  </div>
                  <div className="col-2">
                    <input type="text" placeholder = "variant" className="form-control" name={"variant"} value={this.state.requestedDataForm[index].variant} onChange={(e) => this.handleOnChangeCustomerRequest(e.target,"variant",index)} />
                  </div>
                  <div className="col-2">
                    <input type="number" placeholder = "quantity" required={true} className="form-control" name={"quantity"} value={this.state.requestedDataForm[index].quantity} onChange={(e) => this.handleOnChangeCustomerRequest(e.target,"quantity",index)} />
                  </div>
                  <div className="col-2">
                    <input type="price" readOnly={this.state.sellingPriceMapping[this.state.requestedDataForm[index].productId]} placeholder = "price" required={true} className="form-control" name={"price"} value={this.state.requestedDataForm[index].price} onChange={(e) => this.handleOnChangeCustomerRequest(e.target,"price",index)} />
                  </div>
                
              </div>
                  ))}
               
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <div className="">
              <div className="">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"></span>
                    <h4><b> Recommended Products</b></h4>
                    <hr></hr>
                  </div>
                  <div className="mb-4" style = {{textAlign: 'right'}}>
                  <button type="button" className="btn btn-success" onClick={() => this.handleAddRecommendedRow()}>Add Row</button>
                </div>
                  {this.state.recommendedDataForm.map((data,index)=>(
            <div className = "row mb-3" key={index}>
            <div className=" form-group col-3">
                <Select
                required={true}
                placeholder = {"select category"}
                  onChange={(e) => { this.handleOnChangeCustomerRecommended(e,"category", index) }}
                  options = {this.state.mappingOptions.categoryOptions}
                />

                </div>
                <div className="col-3">
                <Select
                placeholder = {"select product"}
                required={true}
                  onChange={(e) => { this.handleOnChangeCustomerRecommended(e,"productId",index) }}
                  options = {this.state.mappingOptions.productOptions[this.state.recommendedDataForm[index].category]}
                />
                </div>
                <div className="col-2">
                  <input type="text" placeholder = "variant" className="form-control" name={"variant"} value={this.state.recommendedDataForm[index].variant} onChange={(e) => this.handleOnChangeCustomerRecommended(e.target,"variant", index)} />
                </div>
                <div className="col-2">
                  <input type="number" placeholder = "quantity" required={true} className="form-control" name={"quantity"} value={this.state.recommendedDataForm[index].quantity} onChange={(e) => this.handleOnChangeCustomerRecommended(e.target,"quantity",index)} />
                </div>
                <div className="col-2">
                    <input type="price" readOnly={this.state.sellingPriceMapping[this.state.recommendedDataForm[index].productId]} placeholder = "price" required={true} className="form-control" name={"price"} value={this.state.recommendedDataForm[index].price} onChange={(e) => this.handleOnChangeCustomerRecommended(e.target,"price",index)} />
                </div>
              
            </div>
                  ))}
              <hr></hr>
              <div className="col-2">
                    <input type="discount"  placeholder = "discount" required={false} className="form-control" name={"discount"} value={this.state.quotationDiscount} onChange={(e) => this.handleOnChangeDiscount(e.target)} />
                </div>
                </div>
              </div>
            </div>
          </div>

              </div>
              <div className="mr-2 mb-4" style = {{textAlign: 'right'}}>
                  <button type="button" className="btn btn-success" onClick={() => this.handleSubmitQuotation()}>Submit</button>
                </div>

            </div>

          </Modal>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-md-1" >
              <h5> <b>Stage :</b> </h5>
            </div>
            <div className="col-md-3">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.stage}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectType("stage", this.state.newLeadData.stage, this.state.mappingData.stage)}
                </span>
              </h5>
            </div>
            <div className="col-md-1">
              <h5><b>Active :</b></h5>
            </div>
            <div className="col-md-3">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.active}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectType("active", this.state.newLeadData.active, this.state.mappingData.active)}
                </span>
              </h5>
            </div>
            <div className="col-md-1">
              <h5><b>High Priority</b></h5>
            </div>
            <div className="col-md-3">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.isHighPriority}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectType("isHighPriority", this.state.newLeadData.isHighPriority, this.state.mappingData.isHighPriority)}
                </span>
              </h5>
            </div>
          </div>

          <div className="bottom-section">
            <div className="row">
              <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                <table>
                <tr>
                    <td>Quote Id</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.quoteId}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("quoteId", this.state.newLeadData.quoteId, "text")}
                      </span>
                    </td>

                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.phoneNumber}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("phoneNumber", this.state.newLeadData.phoneNumber, "number")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Alternate Phone</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.alternate_number}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("alternate_number", this.state.newLeadData.alternate_number, "number")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.email}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("email", this.state.newLeadData.email, "email")}
                      </span>
                    </td>

                  </tr>
                  <tr>
                      <td>GST Number</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.gst_number}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("gst_number", this.state.newLeadData.gst_number, "text")}
                        </span>
                      </td>
                    </tr>

                  <tr>
                    <td>Address</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.address}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("address", this.state.newLeadData.address, "text")}
                      </span>
                    </td>

                  </tr>
                  <tr>
                    <td>State</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.state}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.selectType("state", this.state.newLeadData.state,  Object.keys(this.state.stateCityMap))}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>City</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.city}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.selectType("city", this.state.newLeadData.city ? this.state.newLeadData.city : "", this.state.newLeadData.state ? ((this.state.stateCityMap || {})[this.state.newLeadData.state] || []) : [])}
                      </span>

                    </td>
                  </tr>
                  {/* <tr>
                    <td>City</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.city}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.selectType("city", this.state.newLeadData.city || '', this.state.mappingData.city)}
                      </span>

                    </td>
                  </tr> */}

                  <tr>
                    <td>Language</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.language}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.selectType("language", this.state.newLeadData.language, this.state.mappingData.language)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Pincode</td>
                    <td><span>{this.state.leadData.pincode}</span>
                     
                    </td>
                  </tr>
                </table>
              </div>


              <div className="col-md-8 table-wrapper">
                <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
                  <table className="customer-info-left">

                    <tr>
                      <td>Lead Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.leadType}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("leadType", this.state.newLeadData.leadType, this.state.mappingData.type)}
                        </span>
                      </td>
                    </tr>

                    {/* <tr>
                      <td>POC Name</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.pocName}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("pocName", this.state.newLeadData.pocName, "text")}
                        </span>
                      </td>
                    </tr> */}
                    <tr>
                      <td>Next Action Date</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.nextActionDate ? moment(this.state.leadData.nextActionDate).format("DD MMM, YYYY") : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("nextActionDate", this.state.newLeadData.nextActionDate ? moment(this.state.newLeadData.nextActionDate, 'YYYYMMDD').format('YYYY-MM-DD') : '', "date")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Product Order Size</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.projected_order_size}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("projected_order_size", this.state.newLeadData.projected_order_size, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Closed Order Size</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.closed_order_size}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("closed_order_size", this.state.newLeadData.closed_order_size, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Added On</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.ADDED_ON ? moment(this.state.leadData.ADDED_ON).format('DD, MMM YYYY') : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("ADDED_ON", this.state.newLeadData.ADDED_ON ? moment(this.state.newLeadData.ADDED_ON, 'YYYYMMDD').format('YYYY-MM-DD') : '', "date")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Billing Potential</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.totalBillingPotential}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("totalBillingPotential", this.state.newLeadData.totalBillingPotential, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>User Feedback</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.userFeedback}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("userFeedback", this.state.newLeadData.userFeedback, "text")}
                      </span>
                    </td>
                    </tr>
                    <tr>
                    <td>Wifi Connection</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.wifiConnection}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("wifiConnection", this.state.newLeadData.wifiConnection, "text")}
                      </span>
                    </td>
                    </tr>
                    <tr>
                    <td>BHK</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.bhk}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("bhk", this.state.newLeadData.bhk, "text")}
                      </span>
                    </td>
                    </tr>
                    <tr>
                    <td>Electricity Bill</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.electricityBill}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("electricityBill", this.state.newLeadData.electricityBill, "text")}
                      </span>
                    </td>
                    </tr>
                  </table>
                </div>
                <div className="col-md-6 customer-info">
                  <table className="customer-info-right">
                    <tr>
                      <td>Responsible Person</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.responsible_person}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("responsible_person", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.newLeadData.responsible_person)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Co Manager</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.co_responsible_person}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("co_responsible_person", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.newLeadData.co_responsible_person)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Point of Contact</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.companyName}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("companyName", this.state.newLeadData.companyName, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.designation}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("designation", this.state.newLeadData.designation, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Campaign</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.UTM_CAMPAIGN}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("UTM_CAMPAIGN", this.state.newLeadData.UTM_CAMPAIGN, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Source</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.source}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("source", this.state.newLeadData.source, this.state.mappingData.source)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Establishment Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.establishment_type}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("establishment_type", this.state.newLeadData.establishment_type, Object.keys(this.state.mappingData.establishments || {}))}
                        </span>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>{this.state.edit ? (this.state.newLeadData.establishment_question || "Select Establishment") : (this.state.leadData.establishment_question || "Select Establishment")}</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.establishment_answer}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("establishment_answer", this.state.newLeadData.establishment_answer, this.state.mappingData.establishments && this.state.mappingData.establishments[this.state.newLeadData.establishment_type] ? this.state.mappingData.establishments[this.state.newLeadData.establishment_type].options : [])}
                        </span>
                      </td>
                    </tr> */}
                    <tr>
                      <td>Potential</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.potential}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("potential", this.state.newLeadData.potential, this.state.mappingData.potential)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Number</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.totalNumberOfSpace}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("totalNumberOfSpace", this.state.newLeadData.totalNumberOfSpace, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>UTM Source</td>
                      <td><span>{this.state.leadData.UTM_SOURCE}</span>
                      </td>
                    </tr>
                  



                  </table>
                </div>
              </div>
            </div>

            <div className='mb-3'>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"></span>
                    <h4><b>Quotation List</b></h4>
                    <hr></hr>
                  </div>
                  <div className = "row mb-3">
                 
                      <div className="col-2" style = {{textAlign: 'left'}}>
                        <button className="btn btn-success" onClick={() => this.handleOpenModal()}>Add Quotation</button>
                      </div>
                  </div>
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.state.quotationData || []}
                    columns={quotationColumns}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal open={this.state.showSingleQuotation} styles={modalStyles} onClose={this.handleCloseSingleQuotation}>
          <div>
            <div className='mb-3'>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"></span>
                    <h4><b> Requested Products</b></h4>
                    <hr></hr>
                  </div>
                  
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.state.customerRequestQuotationData || []}
                    columns={customerRequestColumns}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"></span>
                    <h4><b> Recommended Products</b></h4>
                    <hr></hr>
                  </div>
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.state.customerRecommendedQuotationData || []}
                    columns={customerRecommendedColumns}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
          </Modal>

            <div className="row" style={{ padding: "0px" }}>
              <div className="topbar-section col-9">
                <div className="container">
                  <div>
                    <h4><b>Comments</b></h4>
                  </div>
                  <hr></hr>
                  <div>
                    <div>
                      {(this.state.leadData.comments || []).map(comment => {
                        return <div><b>{comment.commentedBy.split('@')[0].split('.')[0] + ' ' + comment.commentedBy.split('@')[0].split('.')[1]} - {moment(+(comment.timestamp)).format('DD/MM/YYYY hh:mm a')}</b> - {comment.commentBody}</div>
                      })}
                    </div>
                    <div className="row" style={{ width: "80%", margin: "0px", padding: "0px" }}>
                      <div className="col-md-10 form-group"  >
                        <label for="newComment"></label>
                        <textarea placeholder="Add New Comment" className="form-control" rows="2" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
                      </div>
                      <div style={{ marginTop: "46px", marginLeft: '0px' }} className="col-md-1 form-group">
                        <button className="btn btn-success" onClick={(e) => this.commentSubmit(e)}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="scrap-variables">
            <h6 style={{ paddingLeft: "10px" }}><b>UTM Variables | {this.state.showUtmVariables ? <i className="fa fa-eye-slash" style={{ cursor: "pointer" }} onClick={this.handleUtmDivToggle}></i> : <i className="fa fa-eye" onClick={this.handleUtmDivToggle} style={{ cursor: "pointer" }}></i>}</b></h6>
            <hr style={this.state.showUtmVariables ? style.show : style.hide}></hr>

            <div className="row utmVariables" style={this.state.showUtmVariables ? style.show : style.hide}>
              <div className="col-md-1">
                <p> Source</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_SOURCE}
              </div>
              <div className="col-md-1">
                <p>Campaign  </p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_CAMPAIGN}
              </div>
              <div className="col-md-1">
                <p> Content</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_CONTENT}
              </div>
              <div className="col-md-1">
                <p> Medium </p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_MEDIUM}
              </div>
              <div className="col-md-1">
                <p> Term</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_TERM}
              </div>
              <div className="col-md-1">
                <p>Adposition</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.adposition}
              </div>
              <div className="col-md-1">
                <p>Placement</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.placement}
              </div>
              <div className="col-md-1">
                <p>Loc physical ms</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.loc_physical_ms}
              </div>
              <div className="col-md-1">
                <p>Loc interest ms</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.loc_interest_ms}
              </div>
              <div className="col-md-1">
                <p>Device</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.device}
              </div>
              <div className="col-md-1">
                <p>Keyword</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.keyword}
              </div>
            </div>
          </div>

          <div>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"><button><i className="fa fa-refresh" onClick={this.handlePageRefresh}></i></button></span>
                    <h4><b>History</b></h4>
                    <hr></hr>
                  </div>
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.state.leadData.history}
                    columns={this.historyColumns}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      )
    }

    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }

  }
}

export default ZunpulseSingleLeadViewComponent

