import {FETCH_BITRIXID_SUCCESS,FETCH_BITRIXID_START, FETCH_ZUNSOLAR_DEALER_INVENTORY_SUCCESS, FETCH_ZUNPULSE_COUPON_DETAILS, FETCH_ZUNPULSE_CUSTOMER_QUOTATION, FETCH_ZUNPULSE_CUSTOMER_QUOTATION_PRODUCTS, FETCH_DEALER_VISIT_DATA, FETCH_DISTRIBUTOR_KYC_DATA, FETCH_RETAILERS_COUNT_FOR_DISTRIBUTOR, FETCH_DISTRIBUTOR_LEDGER_DATA} from '../actions/actiontypes';

export default function(state = {}, action) {
	switch(action.type){
		case FETCH_BITRIXID_START:{
			return {};
		}
		case FETCH_BITRIXID_SUCCESS:{
			return {...state, ...action.payload};
		}
		case FETCH_ZUNSOLAR_DEALER_INVENTORY_SUCCESS: {
			return {...state, zunsolarInventoryDealerData: action.payload };
		}
		case FETCH_ZUNPULSE_CUSTOMER_QUOTATION:{
			return {...state, zunpulseCustomerQuotation:action.payload};
		}
		case FETCH_ZUNPULSE_CUSTOMER_QUOTATION_PRODUCTS:{
			return {...state, zunpulseQuotationProducts: action.payload}
		}
		case FETCH_DEALER_VISIT_DATA:{
			return {...state, dealerVisitData:action.payload}
		}
		case FETCH_DISTRIBUTOR_KYC_DATA:{
			return {...state, distributorKYCData:action.payload}
		}
		case FETCH_RETAILERS_COUNT_FOR_DISTRIBUTOR:{
            return {...state, retailersCount:action.payload || 0}
        }
		case FETCH_DISTRIBUTOR_LEDGER_DATA:{
			return {...state, distributorLedgerData:action.payload}
		}
		default:
			return state;
	}
}