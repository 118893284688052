import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchMetricsSummary, fetchSalesSummarySubOrderView, fetchSalesSummaryUnitView, fetchOrderValueView, fetchShippingSummarySubOrderView, fetchShippingSummaryUnitView, fetchLastTenDaysSummarySubOrderView, fetchLastTenDaysSummaryUnitView, fetchMapping, fetchCategorySummary, fetchStateSummary, fetchMapData, fetchSummaryOrderView, fetchSalesSummaryOrderView, fetchLastTenDaysSummaryOrderView, fetchShippingSummaryOrderView } from '../../actions/zunpulseOpsDashboardActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { SalesSummaryComponent, ShippingSummaryComponent, OrderValueViewComponent, LastTenDaysSummaryComponent, MetricsSummaryComponentNew, CategorySummaryComponent, StateSummaryComponent, MapViewComponent, SalesSummaryOrderViewComponent } from '../../components/zunpulseComponents/ZunpulseOpsDashboardComponent';
import '../../styles/pulseOpsDashboardStyles.css';
import Select from 'react-select';

const mapViewTypeOptions = [
    { label: 'Order', value: 'Order' },
    { label: 'Unit', value: 'Unit' }
]

class ZunpulseOpsDashboardNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableType: 'Order',
            orderDateFilters: {
                startDate: '',
                endDate: ''
            },
            stateTableSelectedCategory: 'lighting',
            mapViewType: 'Order',
            productsFilterMapView: []
        };
    }

    componentWillMount() {
        this.props.fetchMetricsSummary();
        this.props.fetchSalesSummaryUnitView();
        this.props.fetchOrderValueView();
        this.props.fetchShippingSummaryUnitView();
        this.props.fetchLastTenDaysSummaryUnitView();
        this.props.fetchMapping();
        this.props.fetchCategorySummary();
        this.props.fetchStateSummary(this.state.stateTableSelectedCategory, {});
        this.props.fetchMapData(this.state.mapViewType, {});
        this.props.fetchSummaryOrderView();
        this.props.fetchSalesSummaryOrderView();
        this.props.fetchLastTenDaysSummaryOrderView();
        this.props.fetchShippingSummaryOrderView();
        // this.props.fetchStateSummary();
        // this.props.fetchLastTenDaysSummarySubOrderView();
        // this.props.fetchShippingSummarySubOrderView();
        // this.props.fetchSalesSummarySubOrderView();
    }

    componentWillReceiveProps(props) {
        let { mapping = {}, salesSummarySubOrder, salesSummarySubOrderColumns, salesSummarySubOrderReceived, salesSummaryUnit, salesSummaryUnitColumns, salesSummaryUnitReceived, shippingSummarySubOrder, shippingSummarySubOrderColumns, shippingSummarySubOrderReceived, shippingSummaryUnit, shippingSummaryUnitColumns, shippingSummaryUnitReceived, lastTenDaysSummarySubOrder, lastTenDaysSummarySubOrderColumns, lastTenDaysSummarySubOrderReceived, lastTenDaysSummaryUnit, lastTenDaysSummaryUnitColumns, lastTenDaysSummaryUnitReceived, orderValueView, orderValueViewColumns, orderValueViewReceived, metricsSummary, categorySummary = [], categorySummaryReceived = false, categorySummaryColumns = [], stateSummary = [], stateSummaryColumns = [], stateSummaryReceived = false, mapData = [], mapDataReceived = false, salesSummaryOrder = [], salesSummaryOrderColumns = [], salesSummaryOrderReceived = false, lastTenDaysSummaryOrder = [], lastTenDaysSummaryOrderColumns = [], lastTenDaysSummaryOrderReceived = false, shippingSummaryOrder = [], shippingSummaryOrderColumns = [], shippingSummaryOrderReceived = false } = props;
        let sourceOptions = (mapping.source || []).map(option => ({ label: option, value: option }))
        let statusOptions = (mapping.status || []).map((status, index) => ({ label: status, value: index }));
        let stateOptions = Object.keys(mapping.stateCityMapping || {}).map(state => ({ label: state, value: state }))
        let comboOptions = (mapping.comboProducts || []).map(row => ({ label: row.product, value: row.productId }));
        let singleProductOptions = (mapping.singleProducts || []).map(row => ({ label: row.product, value: row.productId }));
        let categoryOptions = (mapping.categoryList || []).map(val => ({ label: val, value: val }))
        this.setState({
            salesSummaryUnit,
            salesSummaryUnitColumns,
            salesSummaryUnitReceived,
            shippingSummaryUnit,
            shippingSummaryUnitColumns,
            shippingSummaryUnitReceived,
            lastTenDaysSummaryUnit,
            lastTenDaysSummaryUnitColumns,
            lastTenDaysSummaryUnitReceived,
            orderValueView,
            orderValueViewColumns,
            orderValueViewReceived,
            metricsSummary,
            mapping,
            categorySummary,
            categorySummaryColumns,
            categorySummaryReceived,
            stateSummary,
            stateSummaryColumns,
            stateSummaryReceived,
            mapData,
            mapDataReceived,
            salesSummaryOrder,
            salesSummaryOrderColumns,
            salesSummaryOrderReceived,
            lastTenDaysSummaryOrder,
            lastTenDaysSummaryOrderColumns,
            lastTenDaysSummaryOrderReceived,
            shippingSummaryOrder,
            shippingSummaryOrderColumns,
            shippingSummaryOrderReceived,
            sourceOptions,
            statusOptions,
            stateOptions,
            comboOptions,
            singleProductOptions,
            categoryOptions
            // salesSummarySubOrder,
            // salesSummarySubOrderColumns,
            // salesSummarySubOrderReceived,
            // lastTenDaysSummarySubOrder,
            // lastTenDaysSummarySubOrderColumns,
            // lastTenDaysSummarySubOrderReceived,
            // shippingSummarySubOrder,
            // shippingSummarySubOrderColumns,
            // shippingSummarySubOrderReceived,
        })
    }

    handleFilterChange = (type, name, value) => {
        let appliedFilters = (value || []).map(option => option.value);
        this.setState({
            [type]: {
                ...this.state[type],
                [name]: appliedFilters
            }
        })
    }

    handleDateFilterChange = (e) => {
        let { name, value } = e.target;
        let val;
        if(name == "startDate") {
            val = value ? moment(value).startOf('day').valueOf() : "";
        }
        else {
            val = value ? moment(value).endOf('day').valueOf() : "";
        }
        this.setState({
            orderDateFilters: {
                ...this.state.orderDateFilters,
                [name]: val
            }
        })
    }

    applyFilter = (type) => {
        let filters = this.state[type] || {};
        let { orderDateFilters } = this.state;
        filters = { ...filters, customerOrderDate: orderDateFilters };
        switch (type) {
            case "salesSummarySubOrderFilters":
                this.props.fetchSalesSummarySubOrderView(filters);
                this.setState({ salesSummarySubOrderReceived: false })
                break;
            case "salesSummaryUnitFilters":
                this.props.fetchSalesSummaryUnitView(filters);
                this.setState({ salesSummaryUnitReceived: false })
                break;
            case "lastTenDaysSummarySubOrderFilters":
                this.props.fetchLastTenDaysSummarySubOrderView(filters);
                this.setState({ lastTenDaysSummarySubOrderReceived: false })
                break;
            case "lastTenDaysSummaryUnitFilters":
                this.props.fetchLastTenDaysSummaryUnitView(filters);
                this.setState({ lastTenDaysSummaryUnitReceived: false })
                break;
            case "shippingSummarySubOrderFilters":
                this.props.fetchShippingSummarySubOrderView(filters);
                this.setState({ shippingSummarySubOrderReceived: false })
                break;
            case "shippingSummaryUnitFilters":
                this.props.fetchShippingSummaryUnitView(filters);
                this.setState({ shippingSummaryUnitReceived: false })
                break;
            case "orderValueFilters":
                this.props.fetchOrderValueView(filters);
                this.setState({ orderValueViewReceived: false })
                break;
            case "stateSummaryFilters":
                this.props.fetchStateSummary(this.state.stateTableSelectedCategory, filters);
                this.setState({ stateSummaryReceived: false })
                break;
            case "salesSummaryOrderFilters":
                this.props.fetchSalesSummaryOrderView(filters);
                this.setState({ salesSummaryOrderReceived: false })
                break;
            case "shippingSummaryOrderFilters":
                this.props.fetchShippingSummaryOrderView(filters);
                this.setState({ shippingSummaryOrderReceived: false })
                break;
            case "lastTenDaysSummaryOrderFilters":
                this.props.fetchLastTenDaysSummaryOrderView(filters);
                this.setState({ lastTenDaysSummaryOrderReceived: false })
                break;
            default:
                break;
        }
    }

    onApplyDateFilter = () => {
        if(this.state.tableType == "Map") {
            let { orderDateFilters = {}, mapViewType, productsFilterMapView = [] } = this.state;
            let mapViewFilters = {
                customerOrderDate: orderDateFilters,
                productId: (productsFilterMapView || []).map(row => row.value)
            }
            this.props.fetchMapData(mapViewType, mapViewFilters);
        }
        else {
            let { orderDateFilters = {}, salesSummarySubOrderFilters = {}, salesSummaryUnitFilters = {}, lastTenDaysSummarySubOrderFilters = {}, lastTenDaysSummaryUnitFilters = {}, shippingSummarySubOrderFilters = {}, shippingSummaryUnitFilters = {}, orderValueFilters = {}, stateSummaryFilters = {}, salesSummaryOrderFilters = {}, shippingSummaryOrderFilters = {}, lastTenDaysSummaryOrderFilters = {} } = this.state;
            this.props.fetchSalesSummaryUnitView({ ...salesSummaryUnitFilters, customerOrderDate: orderDateFilters });
            this.props.fetchLastTenDaysSummaryUnitView({ ...lastTenDaysSummaryUnitFilters, customerOrderDate: orderDateFilters });
            this.props.fetchShippingSummaryUnitView({ ...shippingSummaryUnitFilters, customerOrderDate: orderDateFilters });
            this.props.fetchOrderValueView({ ...orderValueFilters, customerOrderDate: orderDateFilters });
            this.props.fetchSalesSummaryOrderView({ ...salesSummaryOrderFilters, customerOrderDate: orderDateFilters });
            this.props.fetchCategorySummary({ customerOrderDate: orderDateFilters });
            this.props.fetchStateSummary(this.state.stateTableSelectedCategory, { ...stateSummaryFilters, customerOrderDate: orderDateFilters });
            this.props.fetchShippingSummaryOrderView({ ...shippingSummaryOrderFilters, customerOrderDate: orderDateFilters });
            this.props.fetchLastTenDaysSummaryOrderView({ ...lastTenDaysSummaryOrderFilters, customerOrderDate: orderDateFilters });
            // this.props.fetchShippingSummarySubOrderView({ ...shippingSummarySubOrderFilters, customerOrderDate: orderDateFilters });
            // this.props.fetchLastTenDaysSummarySubOrderView({ ...lastTenDaysSummarySubOrderFilters, customerOrderDate: orderDateFilters });
            // this.props.fetchSalesSummarySubOrderView({ ...salesSummarySubOrderFilters, customerOrderDate: orderDateFilters });
            this.setState({
                salesSummaryUnitReceived: false,
                lastTenDaysSummaryUnitReceived: false,
                shippingSummaryUnitReceived: false,
                orderValueViewReceived: false,
                categorySummaryReceived: false,
                stateSummaryReceived: false,
                salesSummaryOrderReceived: false,
                shippingSummaryOrderReceived: false,
                lastTenDaysSummaryOrderReceived: false
                // salesSummarySubOrderReceived: false,
                // lastTenDaysSummarySubOrderReceived: false,
                // shippingSummarySubOrderReceived: false,
            })
        }
    }

    handleOnViewChange = (e) => {
        let { name = '' } = e.target;
        this.setState({ tableType: name });
    }

    TabsComponent = ({ name, label }) => {
        let { tableType = '' } = this.state;
        let { handleOnViewChange } = this;
        return <button className={`btn ${tableType === name ? "btn-info" : "btn-outline-info"}`} name={name} onClick={handleOnViewChange} style={{ margin: '10px 0 0 16px' }}>{label}</button>;
    }

    handleStateCategoryChange = (e) => {
        let value = e.value;
        this.setState({
            stateTableSelectedCategory: value
        })
    }

    handleMapViewFiltersChange = (name, value) => {
        if(name == "mapViewType") {
            this.setState({
                mapViewType: value.value,
                productsFilterMapView: []
            })
        }
        else {
            this.setState({ [name]: value })
        }
    }

    render() {
        let { mapping = {}, salesSummarySubOrder = [], salesSummarySubOrderColumns = [], salesSummarySubOrderReceived = false, salesSummaryUnit = [], salesSummaryUnitColumns = [], salesSummaryUnitReceived = false, shippingSummarySubOrder = [], shippingSummarySubOrderColumns = [], shippingSummarySubOrderReceived = false, shippingSummaryUnit = [], shippingSummaryUnitColumns = [], shippingSummaryUnitReceived = false, lastTenDaysSummarySubOrder = [], lastTenDaysSummarySubOrderColumns = [], lastTenDaysSummarySubOrderReceived = false, lastTenDaysSummaryUnit = [], lastTenDaysSummaryUnitColumns = [], lastTenDaysSummaryUnitReceived = false, orderValueView = [], orderValueViewColumns = [], orderValueViewReceived = false, metricsSummary = {}, tableType = '', categorySummary = [], categorySummaryReceived = false, categorySummaryColumns = [], stateSummary = [], stateSummaryColumns = [], stateSummaryReceived = false, stateTableSelectedCategory, mapData = [], mapDataReceived = false, mapViewType, salesSummaryOrder = [], salesSummaryOrderColumns = [], salesSummaryOrderReceived = false, lastTenDaysSummaryOrder = [], lastTenDaysSummaryOrderColumns = [], lastTenDaysSummaryOrderReceived = false, shippingSummaryOrder = [], shippingSummaryOrderColumns = [], shippingSummaryOrderReceived = false, sourceOptions = [], statusOptions = [], stateOptions = [], comboOptions = [], singleProductOptions = [], categoryOptions = [] } = this.state;
        let { handleFilterChange, handleDateFilterChange, applyFilter, onApplyDateFilter, TabsComponent, handleStateCategoryChange, handleMapViewFiltersChange } = this;
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <div style={{ "marginTop": 80 }} className="container-fluid zunpulseOpsDashboardMainDiv">
                    <b>Last Edited - {metricsSummary.lastUpdatedOn ? moment(metricsSummary.lastUpdatedOn).format('MMMM Do YYYY, h:mm:ss a') : ""}</b>
                    <hr />
                    <MetricsSummaryComponentNew
                        data={metricsSummary}
                        productIdNameMapping={mapping.productIdNameMapping || {}}
                    />
                    <div className="mb-3 mt-3">
                        <div style={{ float: 'left' }}>
                            <div className="row">
                                <div className="col-md">
                                    <div className="form-group">
                                        <label>{"Start Date"}</label>
                                        <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateFilterChange(e)} />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                        <label>{"End Date"}</label>
                                        <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateFilterChange(e)} />
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button className="form-control btn btn-info btn-sm" onClick={onApplyDateFilter}>Apply Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ float: 'right' }}>
                            <TabsComponent name="Order" label="Order" />
                            {/* <TabsComponent name="SKU" label="SKU" /> */}
                            <TabsComponent name="Unit" label="Unit" />
                            <TabsComponent name="Map" label="Map" />
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    {
                        tableType == "Map" ? <div className="row">
                            <div className="col-md-2">
                                <Select
                                    isSearchable
                                    onChange={(e) => handleMapViewFiltersChange("mapViewType", e)}
                                    placeholder={"Choose Type"}
                                    name={"mapViewType"}
                                    options={mapViewTypeOptions}
                                    defaultValue={{ label: mapViewType, value: mapViewType }}
                                />
                            </div>
                            { mapViewType != "Order" ? <div className="col-md-2">
                                <Select
                                    isSearchable
                                    isMulti
                                    onChange={(e) => handleMapViewFiltersChange("productsFilterMapView", e)}
                                    placeholder={"Choose Products"}
                                    name={"productsFilterMapView"}
                                    options={mapViewType == "SKU" ? [...singleProductOptions, ...comboOptions] : singleProductOptions}
                                />
                            </div> : "" }
                        </div> : ""
                    }
                    {tableType == "Order" ? <SalesSummaryComponent
                        data={salesSummaryOrder}
                        columns={salesSummaryOrderColumns}
                        loading={!salesSummaryOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummaryOrderFilters"}
                        applyFilter={applyFilter}
                        tableType={tableType}
                    /> : ""}
                    {tableType == "SKU" ? <SalesSummaryComponent
                        data={salesSummarySubOrder}
                        columns={salesSummarySubOrderColumns}
                        loading={!salesSummarySubOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={[ ...singleProductOptions, ...comboOptions ]}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummarySubOrderFilters"}
                        applyFilter={applyFilter}
                    /> : ""}
                    {tableType == "Unit" ? <SalesSummaryComponent
                        data={salesSummaryUnit}
                        columns={salesSummaryUnitColumns}
                        loading={!salesSummaryUnitReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={singleProductOptions}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummaryUnitFilters"}
                        applyFilter={applyFilter}
                    /> : ""}
                    {tableType == "Order" ? <ShippingSummaryComponent
                        data={shippingSummaryOrder}
                        columns={shippingSummaryOrderColumns}
                        loading={!shippingSummaryOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        sourceOptions={sourceOptions}
                        type={"shippingSummaryOrderFilters"}
                        applyFilter={applyFilter}
                        tableType={tableType}
                    /> : ""}
                    {tableType == "SKU" ? <ShippingSummaryComponent
                        data={shippingSummarySubOrder}
                        columns={shippingSummarySubOrderColumns}
                        loading={!shippingSummarySubOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={[ ...singleProductOptions, ...comboOptions ]}
                        sourceOptions={sourceOptions}
                        type={"shippingSummarySubOrderFilters"}
                        applyFilter={applyFilter}
                    /> : ""}
                    {tableType == "Unit" ? <ShippingSummaryComponent
                        data={shippingSummaryUnit}
                        columns={shippingSummaryUnitColumns}
                        loading={!shippingSummaryUnitReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={singleProductOptions}
                        sourceOptions={sourceOptions}
                        type={"shippingSummaryUnitFilters"}
                        applyFilter={applyFilter}
                    /> : ""}
                    {/* {tableType != "Map" ? <SalesSummaryOrderViewComponent
                        data={salesSummaryOrder}
                        columns={salesSummaryOrderColumns}
                        loading={!salesSummaryOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        sourceOptions={sourceOptions}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummaryOrderFilters"}
                        applyFilter={applyFilter}
                    /> : ""} */}
                    {tableType != "Map" ? <OrderValueViewComponent
                        data={orderValueView}
                        columns={orderValueViewColumns}
                        loading={!orderValueViewReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={[ ...singleProductOptions, ...comboOptions ]}
                        sourceOptions={sourceOptions}
                        shippingStatusOptions={statusOptions}
                        type={"orderValueFilters"}
                        applyFilter={applyFilter}
                    /> : ""}
                    {tableType == "Order" ? <LastTenDaysSummaryComponent
                        data={lastTenDaysSummaryOrder}
                        columns={lastTenDaysSummaryOrderColumns}
                        loading={!lastTenDaysSummaryOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        shippingStatusOptions={statusOptions}
                        type={"lastTenDaysSummaryOrderFilters"}
                        applyFilter={applyFilter}
                        tableType={tableType}
                    /> : ""}
                    {tableType == "SKU" ? <LastTenDaysSummaryComponent
                        data={lastTenDaysSummarySubOrder}
                        columns={lastTenDaysSummarySubOrderColumns}
                        loading={!lastTenDaysSummarySubOrderReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={[ ...singleProductOptions, ...comboOptions ]}
                        shippingStatusOptions={statusOptions}
                        type={"lastTenDaysSummarySubOrderFilters"}
                        applyFilter={applyFilter}
                    /> : ""}
                    {tableType == "Unit" ? <LastTenDaysSummaryComponent
                        data={lastTenDaysSummaryUnit}
                        columns={lastTenDaysSummaryUnitColumns}
                        loading={!lastTenDaysSummaryUnitReceived}
                        handleSelectChange={handleFilterChange}
                        stateOptions={stateOptions}
                        productOptions={singleProductOptions}
                        shippingStatusOptions={statusOptions}
                        type={"lastTenDaysSummaryUnitFilters"}
                        applyFilter={applyFilter}
                    /> : ""}
                    { tableType != "Map" ? <CategorySummaryComponent
                        data={categorySummary}
                        columns={categorySummaryColumns}
                        loading={!categorySummaryReceived}
                        type={"categorySummaryFilters"}
                    /> : "" }
                    { tableType != "Map" ? <StateSummaryComponent
                        data={stateSummary}
                        columns={stateSummaryColumns}
                        loading={!stateSummaryReceived}
                        type={"stateSummaryFilters"}
                        stateOptions={stateOptions}
                        selectedCategory={stateTableSelectedCategory}
                        categoryOptions={categoryOptions}
                        handleCategoryChange={handleStateCategoryChange}
                        handleSelectChange={handleFilterChange}
                        applyFilter={applyFilter}
                    /> : "" }
                    { tableType == "Map" && mapData.length > 1 ? <MapViewComponent 
                        data={mapData}
                    /> : "" }
                </div>
            </div>
        );


    }
}

function mapStateToProps({ zunPulseOpsDashboard }) {
    let { salesSummarySubOrder = [], salesSummarySubOrderColumns = [], salesSummarySubOrderReceived = false, salesSummaryUnit = [], salesSummaryUnitColumns = [], salesSummaryUnitReceived = false, shippingSummarySubOrder = [], shippingSummarySubOrderColumns = [], shippingSummarySubOrderReceived = false, shippingSummaryUnit = [], shippingSummaryUnitColumns = [], shippingSummaryUnitReceived = false, lastTenDaysSummarySubOrder = [], lastTenDaysSummarySubOrderColumns = [], lastTenDaysSummarySubOrderReceived = false, lastTenDaysSummaryUnit = [], lastTenDaysSummaryUnitColumns = [], lastTenDaysSummaryUnitReceived = false, orderValueView = [], orderValueViewColumns = [], orderValueViewReceived = false, metricsSummary = {}, mapping = {}, categorySummary = [], categorySummaryReceived = false, categorySummaryColumns = [], stateSummary = [], stateSummaryColumns = [], stateSummaryReceived = false, mapData = [], mapDataReceived = false, salesSummaryOrder = [], salesSummaryOrderColumns = [], salesSummaryOrderReceived = false, lastTenDaysSummaryOrder = [], lastTenDaysSummaryOrderColumns = [], lastTenDaysSummaryOrderReceived = false, shippingSummaryOrder = [], shippingSummaryOrderColumns = [], shippingSummaryOrderReceived = false } = zunPulseOpsDashboard;
    return {
        salesSummarySubOrder,
        salesSummarySubOrderColumns,
        salesSummarySubOrderReceived,
        salesSummaryUnit,
        salesSummaryUnitColumns,
        salesSummaryUnitReceived,
        shippingSummarySubOrder,
        shippingSummarySubOrderColumns,
        shippingSummarySubOrderReceived,
        shippingSummaryUnit,
        shippingSummaryUnitColumns,
        shippingSummaryUnitReceived,
        lastTenDaysSummarySubOrder,
        lastTenDaysSummarySubOrderColumns,
        lastTenDaysSummarySubOrderReceived,
        lastTenDaysSummaryUnit,
        lastTenDaysSummaryUnitColumns,
        lastTenDaysSummaryUnitReceived,
        orderValueView,
        orderValueViewColumns,
        orderValueViewReceived,
        metricsSummary,
        mapping,
        categorySummary,
        categorySummaryColumns,
        categorySummaryReceived,
        stateSummary,
        stateSummaryColumns,
        stateSummaryReceived,
        mapData,
        mapDataReceived,
        salesSummaryOrder,
        salesSummaryOrderColumns,
        salesSummaryOrderReceived,
        lastTenDaysSummaryOrder,
        lastTenDaysSummaryOrderColumns,
        lastTenDaysSummaryOrderReceived,
        shippingSummaryOrder,
        shippingSummaryOrderColumns,
        shippingSummaryOrderReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, fetchMetricsSummary, fetchSalesSummarySubOrderView, fetchSalesSummaryUnitView, fetchOrderValueView, fetchShippingSummarySubOrderView, fetchShippingSummaryUnitView, fetchLastTenDaysSummarySubOrderView, fetchLastTenDaysSummaryUnitView, fetchMapping, fetchCategorySummary, fetchStateSummary, fetchMapData, fetchSummaryOrderView, fetchSalesSummaryOrderView, fetchLastTenDaysSummaryOrderView, fetchShippingSummaryOrderView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseOpsDashboardNew);