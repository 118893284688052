import React, { Component, useState } from "react";
import Header from '../HeaderComponent';
import url from '../../config';
import axios from 'axios';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { Loader } from '../loader';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';

class VendorLocationMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mapData: [],
      loading: true,
      recieved: false,
    }
  }

  componentDidMount() {
    console.log("componentDidMount");
    let user = JSON.parse(localStorage.getItem('userObj'))
    user = user.access
    console.log(user)
    let Data = {}
    axios.post(`${url.pathBackend}api/zunsolar/vendorlocation`, {key : "8ALS!Fcm#iWX_IgN"}).then((response) => {
      let Data = response.data.data;
      if ((Data.length) > 0) {
        this.setState({
          mapData: Data,
          loading: false,
          recieved: true,
          userAccess: user
        })
      }
    })
  }

  render() {
    const Map = withScriptjs(withGoogleMap((data) => {
      console.log("naman", this.state.mapData)
      const [selectedVendor, setSelectedVendor] = useState(null);
      return (
        <GoogleMap
          defaultZoom={5}
          defaultCenter={{ lat: 22.9734, lng: 78.6569 }}
        >
          <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={60}>
            {this.state.mapData.map(info => (
              < Marker
                key={info.solarId}
                position={{
                  lat: +info.latitude,
                  lng: +info.longitude,
                }}
                onClick={() => {
                  setSelectedVendor(info);
                }}
              />
            ))}
          </MarkerClusterer>
          {selectedVendor && (
            <InfoWindow
              onCloseClick={() => {
                setSelectedVendor(null);
              }}
              position={{
                lat: +selectedVendor.latitude,
                lng: +selectedVendor.longitude
              }}
            >
              <div>
                <h6>{selectedVendor.companyName}</h6>
                <p>Dealer Id - {selectedVendor.solarId}</p>
                <p>{selectedVendor.address}</p>

              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )
    }))
    if (this.state.userAccess && !this.state.userAccess.includes('Zunsolar')) {
      return (
        <div>
          <Header />
          <h5 style={{ marginTop: "80px", color: "salmonred" }}>You dont have access to this page</h5>
        </div>
      )
    }
    if (this.state.userAccess && this.state.userAccess.includes('Zunsolar') && this.state.recieved) {
      return (
        <div >
          <Header />
          <div class="container-fluid" style={{ marginTop: "80px", width: "100vw", height: "90vh", backgroundColor: "#f7f7f7" }}>
            <Map
              data={this.state.mapData}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${url.mapsApiKey}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
      );
    }
    if (this.state.loading) {
      return (
        <div>
          <Header />
          <Loader />
        </div>
      )
    }
  }
}

export default VendorLocationMap;