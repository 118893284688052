import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/createLead.css';
import swal from 'sweetalert';
import { Loader } from '../components/loader';

export default class CreateLeadComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leadData: {},
      mappingData: {},
      propertyType: ["Residential", "Commercial", "Government", "Trust Run Institution", "SME", "Society"],
      active: [1, 0],
      MPP: ["yes", "no"],
      product: ["Grid Tied", "Off Grid", "Hybrid", "Inverter Backup", "Street Light", "Water Pump", "Affiliate", "PV Panel", "Diesel Generator", "Solar AC", "Water Heater", "RESCO", "Other"],
      saType: ["prepaid", "postpaid"],
      source: ["Inbound (Online)", "SMS_Inbound", "Email_Inbound", "Google_WhatsApp", "Flyer_Inbound", "Google", "Facebook", "Adjust", "Direct", "Referral", "BD", "Facebook_Chat", "Google_Chat", "Inmobi", "Direct_Chat", "Direct_Blog", "PR", "SMS", "SMS_Chat", "Email", "Email_Chat", "LinkedIn", "YouTube", "Twitter", "APP_Install", "APP_Referral", "Maint_Referral", "MNRE", "Facebook/Google", "Offline", "Google Display", "Taboola", "WhatsAPP", "Quora", "zunprime", "Paytm", "Client Happiness","B2b"],
      success: '',
      loading: true,
      recieved: false,
      pincodeRegionMap: [],
      regions: []
    }
  }


  componentWillReceiveProps(props) {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let username = userObj.username
    let mapping = props.allMapping ? props.allMapping : {};
    if (Object.keys(mapping).length > 0) {
      let pincodeRegionMap = props.allMapping.pincodes;
      let defaultResponsible = props.allMapping.employees ? props.allMapping.employees.find((user) => user.e_email == username) : "";
      let regions = Object.values(props.allMapping.stateregion);
      regions = [].concat(...regions)
      regions = this.titleCase(regions);
      // console.log("regions", regions)
      this.setState({
        mappingData: props.allMapping,
        recieved: true,
        loading: false,
        pincodeRegionMap: pincodeRegionMap,
        regions: regions,
        leadData: {
          SOURCE_ID: "Inbound (Online)",
          ACTIVE: 1,
          N_RESPONSIBLE: defaultResponsible ? defaultResponsible.e_name : "Vivek Panwar",
          ...this.state.leadData,
        }
      })
    }
    if (props.phoneNumbersList.length > 0) {
      // console.log(props.phoneNumbersList, "rsghfjhgjkdfffffffff")
      let phones = props.phoneNumbersList;
      // console.log("phones", phones)
      let content = ""
      phones.forEach((phone) => {
        content += `<ul>
                    <li style="margin-right : 30px">
                     <a target="_blank" href= "${window.location.origin}/lead/details/${phone}"> ${phone} </a>
                    </li>
                  </ul>`
      })
      // console.log("content", content);
      const wrapper = document.createElement('div');
      wrapper.innerHTML = content
      swal({
        text: "Existing Bitrix Ids",
        buttons: {
          cancel: "Close",
        },
        content: wrapper
      })
    }
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    value = this.checkSelectString(value);
    this.setState({
      leadData: {
        ...this.state.leadData,
        [name]: value,
      }
    }, () => {
      // console.log(this.state.leadData)
    })
  }

  handleStateChange = (e) => {
    // console.log("this is called", e.target.value);

    let { name, value } = e.target;
    this.setState({
      regions: this.state.mappingData.stateregion[value],
      leadData: {
        ...this.state.leadData,
        [name]: value,
      }
    })
  }

  handlePhoneInputChange = (e) => {
    // console.log("eeeeeeeee", e.target.value)
    // let phone = e.target.value
    let { name, value } = e.target;

    if (value.length == 10) {
      setTimeout(() => {
        this.props.checkExistingPhone(value)
      }, 1000);
    }
    this.setState({
      leadData: {
        ...this.state.leadData,
        [name]: value,
      }
    }, () => {
      // console.log("this.state.leadData", this.state.leadData)
    })

  }


  handleZipcodeInputChange = (e) => {
    // console.log(e.target.value);
    let { name, value } = e.target;
    // console.log(value)
    let arrValue = value.split('');
    if (arrValue[0] == "9" || arrValue.length > 6) {
      swal({
        text: "Invalid Zipcode",
        icon: "warning",
      })
    } else if (arrValue.length == 6) {
      // console.log("valueeeee", value)
      let pincodeRegionMap = [...this.state.pincodeRegionMap];
      let regions = pincodeRegionMap.map((obj) => {
        // console.log("obj", obj)
        if (obj.PINCODE == value) {
          // console.log("hello", obj.REGION)
          return obj.REGION
        }
      });
      let region = regions.filter((reg) => {
        return reg != undefined;
      })
      region = this.titleCase(region)
      // console.log("region from map", region)
      this.setState({
        leadData: {
          ...this.state.leadData,
          [name]: value,
          REGION: region,
        },
        // regions: region
      }, () => {
        // console.log("1", this.state.leadData)
      })
    } else {
      this.setState({
        leadData: {
          ...this.state.leadData,
          [name]: value,
          REGION: []
        }
      })
    }
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }

  removingNullKeys = (obj) => {

    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  onNewLeadDataSubmit = (e) => {
    let newData = { ...this.state.leadData };
    newData = this.removingNullKeys(newData);
    if (newData.PHONE_VALUE && /^\d{10}$/.test(newData.PHONE_VALUE) && newData.N_RESPONSIBLE && newData.SOURCE_ID) {
      {
        // console.log("oldData", newData);
        this.props.newLead(newData)
        this.setState({
          leadData: { ...this.state.leadData }
        })
      }
    } else {
      swal({
        title: "Required Fields Missing!",
        text: "Phone Number Required",
        icon: "warning"
      })
    }
  }

  titleCase = (arr) => {
    let titleCaseArr = arr.map((str) => {
      // console.log("1", str)
      str = str.trim();
      str = str.replace('-', ' ')
      let splitStr = str.toLowerCase().split(' ');
      // console.log("splitStr", splitStr)
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ')
    })
    return titleCaseArr
  }


  inputType = (name, value, type) => {
    if (name == "PHONE_VALUE") {
      return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handlePhoneInputChange(e)} />
    }
    if (name == "ZIP_CODE") {
      return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleZipcodeInputChange(e)} />
    }
    return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
  }

  selectType = (name, value, options) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }
  render() {

    if (this.state.recieved) {
      return (
        <div className="container-fluid" style={{ marginTop: "60px" }}>
          <div className="topbar-section" >
            <div className="row">
              <div className="col-md-10 col-xs-12 topbar-left">
                <div className="col-md-12">
                  <strong>Create New Lead</strong>
                </div>
              </div>
              <div className="col-md-2 col-xs-12 topbar-right">
                <div className="col-md-12 col-xs-12">
                  <button className="button-save" onClick={(e) => this.onNewLeadDataSubmit(e)}>Save</button>

                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4" style={{ marginTop: "25px" }}>
              <h5><b>Stage :</b>
                <span className="input-group" style={{ marginTop: "2%", width: "40%" }}>
                  {this.selectNewType("STATUS_ID", this.state.mappingData.stages ? this.state.mappingData.stages : [], "STATUS_NAME", this.state.leadData.STATUS_ID)}
                </span>
              </h5>

            </div>
            <div className="col-md-4" style={{ marginTop: "25px" }}>
              <h5 ><b>Active :</b>
                <span className="input-group" style={{ marginTop: "2%", width: "30%" }}>
                  {this.selectType("ACTIVE", this.state.leadData.ACTIVE, this.state.active)}
                </span>
              </h5>
            </div>
            <div className="col-md-4" style={{ marginTop: "25px" }}>
              <h5><b>High Value :</b>
                <span className="input-group" style={{ marginTop: "2%", width: "30%" }}>
                  {this.selectType("HIGH_VALUE", this.state.leadData.HIGH_VALUE, this.state.active)}
                </span>
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
              <table>

                <tr>
                  <td>Name</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("TITLE", this.state.leadData.TITLE, "text")}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Phone</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("PHONE_VALUE", this.state.leadData.PHONE_VALUE, "number")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Alternate Phone</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("alternate_number", this.state.leadData.alternate_number, "number")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("EMAIL_VALUE", this.state.leadData.EMAIL_VALUE, "email")}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Address</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("ADDRESS", this.state.leadData.ADDRESS, "email")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>ZIP CODE</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("ZIP_CODE", this.state.leadData.ZIP_CODE, "text")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Region</td>
                  <td>
                    <span>
                      {this.selectType("REGION", this.state.leadData.REGION ? this.state.leadData.REGION : '', this.titleCase(this.state.regions || []))}
                      {/* {this.selectNewType("STAGE",this.state.mappingData.stages?this.state.mappingData.stages:[],"STATUS_NAME")} */}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>City</td>

                  <td>
                    <span className="input-group">
                      {/* {this.selectType("ADDRESS_CITY", this.state.leadData.ADDRESS_CITY ? this.state.leadData.ADDRESS_CITY : '', this.state.cities)} */}
                      {this.selectType("ADDRESS_CITY", this.state.leadData.ADDRESS_CITY ? this.state.leadData.ADDRESS_CITY : '', this.state.regions)}
                    </span>

                  </td>
                </tr>
                <tr>
                  <td>State</td>

                  <td>
                    <span className="input-group">
                      <div className="form-group" style={{ width: '100%' }} >
                        <select
                          onChange={(e) => { this.handleStateChange(e) }}
                          className="form-control" id="sel1" name={"STATE"} value={this.state.leadData.STATE}
                        >
                          <option>not selected</option>
                          {(this.state.mappingData.stateregion ? Object.keys(this.state.mappingData.stateregion) : []).map((e) => <option key={e}>{e}</option>)}
                        </select>
                      </div>
                    </span>
                  </td>
                </tr>
                <tr>
                    <td>Responsible</td>
                    <td>
                      <span className="input-group">
                        {this.selectNewType("N_RESPONSIBLE", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.leadData.N_RESPONSIBLE)}
                      </span>
                    </td>

                  </tr>

              </table>
            </div>

            <div className="col-md-8 table-wrapper">
              <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
                <table className="customer-info-left">
                <tr>
                    <td>Language</td>
                    <td>
                      <span className="input-group">
                        {this.selectNewType("LANGUAGE", this.state.mappingData.languages, "language_name", this.state.leadData.LANGUAGE)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Bill</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("MONTHLY_BILL_FORM", this.state.leadData.MONTHLY_BILL_FORM, "number")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Next Action Date</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("NEXT_ACTION_DATE", this.state.leadData.NEXT_ACTION_DATE, "date")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Property Type</td>
                    <td>
                      <span className="input-group">
                        {this.selectType("PROPERTY_TYPE", this.state.leadData.PROPERTY_TYPE, this.state.propertyType)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Roof Ownership</td>
                    <td>
                      <span className="input-group">
                        {this.selectType("ROOF_OWNERSHIP", this.state.leadData.ROOF_OWNERSHIP, this.state.MPP)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Campaign</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("UTM_CAMPAIGN", this.state.leadData.UTM_CAMPAIGN, "text")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Source</td>
                    <td>
                      <span className="input-group">
                        {this.selectNewType("SOURCE_ID", this.state.mappingData.source, "source_name", this.state.leadData.SOURCE_ID)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Referred By Name</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("referredByName", this.state.leadData.referredByName, "text")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Referred By Number</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("referredByNumber", this.state.leadData.referredByNumber, "text")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Referral Amount</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("referralAmount", this.state.leadData.referralAmount, "text")}
                      </span>
                    </td>
                  </tr>

                </table>
              </div>
              <div className="col-md-6 customer-info">
                <table className="customer-info-right">
                <tr>
                    <td>SA Date</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("SITE_ASSESSMENT_DATE", this.state.leadData.SITE_ASSESSMENT_DATE, "date")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>SA Slot</td>
                    <td>
                      <span className="input-group">
                        {this.selectNewType("SA_SLOT", this.state.mappingData.saslots ? this.state.mappingData.saslots : [], "SLOT_VALUE", this.state.leadData.SA_SLOT)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>SA Type</td>
                    <td>
                      <span className="input-group">
                        {this.selectType("SA_TYPE", this.state.leadData.SA_TYPE, this.state.saType)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>SA Paid</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("SITE_ASSESSMENT_PAID", this.state.leadData.SITE_ASSESSMENT_PAID, "text")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Quote Id</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("QUOTE_ID", this.state.leadData.QUOTE_ID, "text")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>MPP</td>
                    <td>
                      <span className="input-group">
                        {this.selectType("MPP", this.state.leadData.MPP, this.state.MPP)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Inside Sales Opinion</td>
                    <td>
                      <span className="input-group">
                        {this.selectType("INSIDE_SALES_OPINION", this.state.leadData.INSIDE_SALES_OPINION, this.state.MPP)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Reason For Verified</td>
                    <td>
                      <span className="input-group">
                        {this.selectType("REASON_FOR_VERIFIED", this.state.leadData.REASON_FOR_VERIFIED, this.state.mappingData.reasonforverified)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Product</td>
                    <td>
                      <span className="input-group">
                        {this.selectType("PRODUCT", this.state.leadData.PRODUCT, this.state.product)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Sanctioned Load</td>
                    <td>
                      <span className="input-group">
                        {this.inputType("SANCTIONED_LOAD", this.state.leadData.SANCTIONED_LOAD, "number")}
                      </span>
                    </td>
                  </tr>

                </table>
              </div>
            </div>


          </div>

        </div>
      )
    }
    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )

    }

  }
}
