import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../components/HeaderComponent';
import { roofTrackerEmployeeColumns, roofTrackerRegionColumns } from '../config/column_fields';
import ReactTable from "react-table";
import axios from 'axios';
import url from '../config';

class RoofTracker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            regionData: [],
            employeeData: [],
            received: false
        };
    }

    componentWillMount() {
        this.fetchData();
    }

    handleDateChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    fetchData = async () => {
        this.setState({ received: false })
        let { startDate, endDate } = this.state;
        let [ regionData = {}, employeeData = {} ] = await Promise.all([
            axios.post(`${url.pathBackend}rooftracker/fetchdata`, { startDate, endDate, groupByKey: 'REGION' }),
            axios.post(`${url.pathBackend}rooftracker/fetchdata`, { startDate, endDate, groupByKey: 'N_RESPONSIBLE' }), 
        ])
        regionData = regionData.data || {};
        employeeData = employeeData.data || {};
        this.setState({
            regionData: regionData.data || [],
            employeeData: employeeData.data || [],
            received: true
        })
    }

    render() {

        let { handleDateChange, fetchData } = this;
        let { employeeData = [], regionData = [], received } = this.state;
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <div style={{ "marginTop": 60 }} className="container-fluid">
                    <div className="row mt-3">
                        <div className="col-md-2">
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                    </div>
                                    <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                    </div>
                                    <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <button onClick={(e) => fetchData()} className="btn btn-success btn-sm">Apply Filter</button>
                        </div>
                    </div>
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="card-title mb-5">
                                <div style={{ float: 'left', fontWeight: "bold" }}>Region Wise</div>
                            </div>
                            <ReactTable
                                filterable={true}
                                showPagination={true}
                                sortable={true}
                                data={regionData}
                                columns={roofTrackerRegionColumns}
                                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                loading={!received}
                                defaultPageSize={5}
                            />
                        </div>
                    </div>
                    <div className="card shadow mt-3">
                        <div className="card-body">
                            <div className="card-title mb-5">
                                <div style={{ float: 'left', fontWeight: "bold" }}>Employee Wise</div>
                            </div>
                            <ReactTable
                                filterable={true}
                                showPagination={true}
                                sortable={true}
                                data={employeeData}
                                columns={roofTrackerEmployeeColumns}
                                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                loading={!received}
                                defaultPageSize={5}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );


    }
}

function mapStateToProps({ }) {
    return {

    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RoofTracker);