import axios from "axios";
import url from "../config";
import {
	FETCH_ZUNPULSE_BURN_DASHBOARD_PRODUCT_SUMMARY,
	FETCH_ZUNPULSE_BURN_DASHBOARD_MARKETING_SUMMARY,
	FETCH_ZUNPULSE_BURN_DASHBOARD_PLATFORM_SUMMARY,
	FETCH_ZUNPULSE_BURN_DASHBOARD_ORDERWISE_MARKETING_SUMMARY,
	FETCH_ZUNPULSE_BURN_DASHBOARD_DEVICE_MARKETING_SUMMARY,
	FETCH_ZUNPULSE_MONTHLY_MARKETING_SPENDING,
	FETCH_ZUNPULSE_DAILY_MARKETING_SPENDING,
	FETCH_ZUNPULSE_COUPON_DETAILS,
} from "./actiontypes";
import swal from "sweetalert";

const getColumnFields = (columns) => {
	let columnFields = [];
	columns.forEach((field) => {
		if (!field) return;
		let fieldConfig = {
			Header: field,
			accessor: field,
		};
		if (
			field == "average" ||
			field == "productId" ||
			field == "prefix" ||
			field == "platform" ||
			field == "couponType" ||
			field == "couponName"
		) {
		} else if (field == "month" || field == "date" || field == "state") {
			fieldConfig.Header = (row) => {
				let { data } = row;
				return `${field} (${data.length})`;
			};
		} else {
			fieldConfig.Header = (row) => {
				let { data } = row;
				let total = data.reduce(
					(a, b) => a + (!isNaN(b[field]) || b[field] != null ? +b[field] : 0),
					0
				);
				return `${field} (${total.toLocaleString("en-IN")})`;
			};
			fieldConfig.Cell = (row) =>
				parseInt(row.value || 0).toLocaleString("en-IN");
		}
		columnFields.push(fieldConfig);
	});
	return columnFields;
};

export function fetchProductSummary() {
	return async (dispatch) => {
		try {
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/productsummary`
			);
			response = response.data;
			if (response.error) {
				alert("Some error occured");
				return;
			}
			let { data = [], columns = [] } = response;
			dispatch({
				type: FETCH_ZUNPULSE_BURN_DASHBOARD_PRODUCT_SUMMARY,
				payload: { data, columns: getColumnFields(columns) },
			});
		} catch (e) {
			console.log(e);
		}
	};
}

export function fetchMarketingSummary() {
	return async (dispatch) => {
		try {
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/marketingsummary`
			);
			response = response.data;
			if (response.error) {
				alert("Some error occured");
				return;
			}
			let { data = [], columns = [] } = response;
			dispatch({
				type: FETCH_ZUNPULSE_BURN_DASHBOARD_MARKETING_SUMMARY,
				payload: { data, columns: getColumnFields(columns) },
			});
		} catch (e) {
			console.log(e);
		}
	};
}

export function fetchPlatformSummary() {
	return async (dispatch) => {
		try {
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/platformsummary`
			);
			response = response.data;
			if (response.error) {
				alert("Some error occured");
				return;
			}
			let { data = [], columns = [] } = response;
			dispatch({
				type: FETCH_ZUNPULSE_BURN_DASHBOARD_PLATFORM_SUMMARY,
				payload: { data, columns: getColumnFields(columns) },
			});
		} catch (e) {
			console.log(e);
		}
	};
}

export function fetchOrderWiseMaketingSummary() {
	return async (dispatch) => {
		try {
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/orderwisemarketingsummary`
			);
			response = response.data;
			if (response.error) {
				alert("Some error occured");
				return;
			}
			let { data = [], columns = [] } = response;
			dispatch({
				type: FETCH_ZUNPULSE_BURN_DASHBOARD_ORDERWISE_MARKETING_SUMMARY,
				payload: { data, columns: getColumnFields(columns) },
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function fetchDeviceWiseMaketingSummary() {
	return async (dispatch) => {
		try {
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/devicewisemarketingsummary`
			);
			response = response.data;
			if (response.error) {
				alert("Some error occured");
				return;
			}
			let { data = [], columns = [] } = response;
			dispatch({
				type: FETCH_ZUNPULSE_BURN_DASHBOARD_DEVICE_MARKETING_SUMMARY,
				payload: { data, columns: getColumnFields(columns) },
			});
		} catch (error) {
			console.log(error);
		}
	};
}

export function deleteMarketingSpending(formData, formType) {
	return async (dispatch) => {
		try {
			if (!formType) {
				throw "Some Error Occured";
			}
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/deletezunpulsespending`,
				{ ...formData, formType }
			);
			response = response.data || {};
			if (response.error) {
				throw "Some Error Occured";
			}
			swal(response.message || "Some Error Occured");
			return;
		} catch (error) {
			alert(error);
			console.log(error);
		}
	};
}

export function CreateMarketingSpending(formData, formType) {
	return async (dispatch) => {
		try {
			if (!formType) {
				throw "Some Error Occured";
			}
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/createzunpulsespending`,
				{ ...formData, formType }
			);
			response = response.data || {};
			if (response.error) {
				throw "Some Error Occured";
			}
			swal(response.message || "Some Error Occured");
			return;
		} catch (error) {
			alert(error);
			console.log(error);
		}
	};
}

export function getMarketingSpending(formType) {
	return async (dispatch) => {
		try {
			if (!formType) {
				throw "Some Error Occured";
			}
			let response;
			if (formType == "monthlyspending") {
				response = await axios.post(
					`${url.pathBackend}zunpulse/burndashboard/getzunpulsespending`,
					{ formType }
				);
			} else {
				response = await axios.post(
					`${url.pathBackend}zunpulse/burndashboard/getzunpulsespending`,
					{ formType }
				);
			}
			response = response.data || {};
			if (response.error) {
				alert("Some error occured");
				return;
			}
			let { data = [], columns = [] } = response;
			if (formType == "monthlyspending") {
				dispatch({
					type: FETCH_ZUNPULSE_MONTHLY_MARKETING_SPENDING,
					payload: {
						data,
						columns: columns.map((field) => {
							return {
								Header: field,
								accessor: field,
							};
						}),
					},
				});
			} else {
				dispatch({
					type: FETCH_ZUNPULSE_DAILY_MARKETING_SPENDING,
					payload: {
						data,
						columns: columns.map((field) => {
							return {
								Header: field,
								accessor: field,
							};
						}),
					},
				});
			}
		} catch (error) {
			alert(error);
			console.log(error);
		}
	};
}

export function getCouponDetails() {
	return async (dispatch) => {
		try {
			let response = await axios.get(
				`${url.pathBackend}zunpulse/burndashboard/getzunpulsecoupondetails`
			);
			response = response.data || {};
			if (response.error) {
				alert("Some error occured");
				return;
			}
			let { data = [], columns = [] } = response;
			dispatch({
				type: FETCH_ZUNPULSE_COUPON_DETAILS,
				payload: {
					data,
					columns: columns.map((field) => {
						return {
							Header: field,
							accessor: field,
						};
					}),
				},
			});
		} catch (error) {
			alert(error);
			console.log(error);
		}
	};
}

export function CreateCouponDetails(formData) {
	return async (dispatch) => {
		try {
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/createzunpulsecoupondetails`,
				formData
			);
			response = response.data || {};
			if (response.error) {
				throw "Some Error Occured";
			}
			swal(response.message || "Some Error Occured");
			return;
		} catch (error) {
			alert(error);
			console.log(error);
		}
	};
}

export function deleteCouponDetails(formData) {
	return async (dispatch) => {
		try {
			let response = await axios.post(
				`${url.pathBackend}zunpulse/burndashboard/deletezunpulsecoupondetails`,
				formData
			);
			response = response.data || {};
			if (response.error) {
				throw "Some Error Occured";
			}
			swal(response.message || "Some Error Occured");
			return;
		} catch (error) {
			alert(error);
			console.log(error);
		}
	};
}
