import moment from 'moment';

export function salesSummaryData(data = [], filters = {}) {
    try {
        let salesSummary = {};
        let { date = {}, state = [], product = [], status = [] } = filters;
        data.filter(order => {
            return (
                (!date.startDate || order.date >= moment(date.startDate, "YYYY-MM-DD").format('x')) &&
                (!date.endDate || order.date <= moment(date.endDate, "YYYY-MM-DD").endOf('day').format('x')) &&
                (!state.length || state.includes(order.state)) &&
                (!product.length || product.includes(order.product)) &&
                (!status.length || status.includes(+order.status)) && 
                order.status != 9
            )
        }).forEach(order => {
            let month = moment(+order.date).format("YYYYMM");
            if (!salesSummary[month]) {
                salesSummary[month] = {
                    month: month,
                    total: 0
                }
            }
            let { source = "" } = order;
            salesSummary[month][source] = (salesSummary[month][source] || 0) + 1;
            salesSummary[month].total += 1;
        })
        // console.log("sales summary data", Object.values(salesSummary));
        return Object.values(salesSummary);
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

export function shippingSummaryData(data = [], filters = {}) {
    try {
        let shippingSummary = {};
        let { date = {}, state = [], product = [], source = [] } = filters;
        data.filter(order => {
            return (
                (!date.startDate || order.date >= moment(date.startDate, "YYYY-MM-DD").format('x')) &&
                (!date.endDate || order.date <= moment(date.endDate, "YYYY-MM-DD").endOf('day').format('x')) &&
                (!state.length || state.includes(order.state)) &&
                (!product.length || product.includes(order.product)) &&
                (!source.length || source.includes(order.source)) && 
                order.status != 9
            )
        }).forEach(order => {
            let month = moment(+order.date).format("YYYYMM");
            if (!shippingSummary[month]) {
                shippingSummary[month] = {
                    month: month,
                    total: 0
                }
            }
            let { status = "" } = order;
            shippingSummary[month][status] = (shippingSummary[month][status] || 0) + 1;
            shippingSummary[month].total += 1;
        })
        // console.log("shipping summary data", Object.values(shippingSummary));
        return Object.values(shippingSummary);
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

export function orderValueData(data = [], filters = {}) {
    try {
        let orderValue = {};
        let { date = {}, state = [], product = [], status = [], source = [] } = filters;
        data.filter(order => {
            return (
                (!date.startDate || order.date >= moment(date.startDate, "YYYY-MM-DD").format('x')) &&
                (!date.endDate || order.date <= moment(date.endDate, "YYYY-MM-DD").endOf('day').format('x')) &&
                (!state.length || state.includes(order.state)) &&
                (!product.length || product.includes(order.product)) &&
                (!status.length || status.includes(+order.status)) &&
                (!source.length || source.includes(order.source)) && 
                order.status != 9
            )
        }).forEach(order => {
            let month = moment(+order.date).format("YYYYMM");
            if (!orderValue[month]) {
                orderValue[month] = {
                    month: month,
                    count: 0,
                    sum: 0,
                    totalProducts: 0
                }
            }
            let { price = "", totalProducts = 1 } = order;
            orderValue[month].sum += (price || 0);
            orderValue[month].sum = +(orderValue[month].sum).toFixed(2);
            orderValue[month].count += 1;
            orderValue[month].totalProducts += (totalProducts || 1);
            orderValue[month].average = +parseFloat(orderValue[month].sum / orderValue[month].count).toFixed(2);
        })
        // console.log("sales summary data", Object.values(orderValue));
        return Object.values(orderValue);
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

export function lastTenDaysSummaryData(data = [], filters = {}) {
    try {
        let salesSummary = {};
        let modifiedFilters = {
            ...filters,
            date: {
                startDate: moment().subtract(9, 'd').startOf('day').format('x'),
                endDate: moment().endOf('day').format('x')
            }
        }
        let { date = {}, state = [], product = [], status = [] } = modifiedFilters;
        data.filter(order => {
            return (
                (!date.startDate || order.date >= date.startDate) &&
                (!date.endDate || order.date <= date.endDate) &&
                (!state.length || state.includes(order.state)) &&
                (!product.length || product.includes(order.product)) &&
                (!status.length || status.includes(+order.status)) && 
                order.status != 9
            )
        }).forEach(order => {
            let orderDate = moment(+order.date).startOf('day').format("x");
            if (!salesSummary[orderDate]) {
                salesSummary[orderDate] = {
                    orderDate: orderDate,
                    total: 0
                }
            }
            let { source = "" } = order;
            salesSummary[orderDate][source] = (salesSummary[orderDate][source] || 0) + 1;
            salesSummary[orderDate].total += 1;
        })
        // console.log("sales summary data", Object.values(salesSummary));
        return Object.values(salesSummary);
    }
    catch (e) {
        console.log(e);
        return [];
    }
}