import { FETCH_ZUNSOLAROPS_LAST_EDIT, FETCH_ZUNSOLAROPS_SALES_SUMMARY, FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY, FETCH_ZUNSOLAROPS_ORDER_VALUE, FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE, FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY, FETCH_WEEKLY_ORDERS, FETCH_WEEKLY_DISPATCH_STATUS, FETCH_WEEKLY_DELIVERED_ORDER_STATUS, FETCH_RETURNED_RECEIVED_ORDER_STATUS, FETCH_RETURNING_ORDER_STATUS, FETCH_STATE_SUMMARY_DATA, FETCH_METRICS_SUMMARY_ZUNSOLAR, FETCH_ZUNSOLAROPS_SALES_SUMMARY_ORDER, FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_ORDER, FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE_VIEW_NEW, FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_ORDER, FETCH_ZUNSOLAROPS_CATEGORY_SUMMARY, FETCH_ZUNSOLAROPS_STATE_SUMMARY, FETCH_ZUNSOLAROPS_SALES_SUMMARY_UNIT, FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_UNIT, FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_UNIT, FETCH_ZUNSOLAROPS_MAPDATA, FETCH_ZUNSOLAROPS_SALES_SUMMARY_DISPLAY_ORDER_Id, FETCH_ORDER_VALUE_NEW } from '../actions/actiontypes';
export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ZUNSOLAROPS_LAST_EDIT: {
            return { ...state, lastEditTime: action.payload };
        }
        case FETCH_ZUNSOLAROPS_SALES_SUMMARY: {
            return { ...state, salesSummary: [...action.payload], salesSummaryReceived: true };
        }
        case FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY: {
            return { ...state, shippingSummary: [...action.payload], shippingSummaryReceived: true };
        }
        case FETCH_ZUNSOLAROPS_ORDER_VALUE: {
            return { ...state, orderValue: [...action.payload], orderValueReceived: true };
        }
        case FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE: {
            return { ...state, subOrderValue: [...action.payload], subOrderValueReceived: true };
        }
        case FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY: {
            return { ...state, lastTenDaysSummary: [...action.payload], lastTenDaysSummaryReceived: true };
        }
        case FETCH_STATE_SUMMARY_DATA: {
            return { ...state, stateSummary: [...(action.payload.data || [])], stateSummaryFields: [ ...(action.payload.fields || []) ], stateSummaryReceived: true };
        }
        case FETCH_WEEKLY_ORDERS: {
            return { ...state, weeklyOrders: [...action.payload], weeklyOrdersReceived: true };
        }
        case FETCH_WEEKLY_DISPATCH_STATUS: {
            return { ...state, weeklyDispatchStatus: [...action.payload], weeklyDispatchStatusReceived: true };
        }
        case FETCH_WEEKLY_DELIVERED_ORDER_STATUS: {
            return { ...state, weeklyDeliveredOrderStatus: [...action.payload], weeklyDeliveredOrderStatusReceived: true };
        }
        case FETCH_RETURNED_RECEIVED_ORDER_STATUS: {
            return { ...state, returnedReceivedOrderStatus: [...action.payload], returnedReceivedOrderStatusReceived: true };
        }
        case FETCH_RETURNING_ORDER_STATUS: {
            return { ...state, returningOrderStatus: [...action.payload], returningOrderStatusReceived: true };
        }
        case FETCH_METRICS_SUMMARY_ZUNSOLAR: {
            return { ...state, metricsSummary: action.payload, metricsSummaryReceived: true  }
        }
        case FETCH_ZUNSOLAROPS_SALES_SUMMARY_ORDER: {
            return { ...state, salesSummaryOrder: [ ...(action.payload.data || []) ], salesSummaryOrderColumns: [ ...(action.payload.columns || []) ], salesSummaryOrderReceived: true  }
        }
        case FETCH_ZUNSOLAROPS_SALES_SUMMARY_DISPLAY_ORDER_Id: {
            return { ...state, salesSummaryDisplayOrderId: [ ...(action.payload.data || []) ], salesSummaryDisplayOrderIdColumns: [ ...(action.payload.columns || []) ], salesSummaryDisplatOrderIdReceived: true  }
        }
        case FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_ORDER: {
            return { ...state, shippingSummarySubOrder: [ ...(action.payload.data || []) ], shippingSummarySubOrderColumns: [ ...(action.payload.columns || []) ], shippingSummarySubOrderReceived: true  }
        }
        case FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE_VIEW_NEW: {
            return { ...state, subOrderValueView: [ ...(action.payload.data || []) ], subOrderValueViewColumns: [ ...(action.payload.columns || []) ], subOrderValueViewReceived: true }
        }
        case FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_ORDER: {
            return { ...state, lastTenDaysSummaryOrder: [ ...(action.payload.data || []) ], lastTenDaysSummaryOrderColumns: [ ...(action.payload.columns || []) ], lastTenDaysSummaryOrderReceived: true  }
        }
        case FETCH_ZUNSOLAROPS_CATEGORY_SUMMARY: {
            return { ...state, categorySummary: [ ...(action.payload.data || []) ], categorySummaryColumns: [ ...(action.payload.columns || []) ], categorySummaryReceived: true  }
        }
        case FETCH_ZUNSOLAROPS_STATE_SUMMARY: {
            return { ...state, stateSummary: [ ...(action.payload.data || []) ], stateSummaryColumns: [ ...(action.payload.columns || []) ], stateSummaryReceived: true  }
        }
        case FETCH_ZUNSOLAROPS_SALES_SUMMARY_UNIT: {
            return { ...state, salesSummaryUnit: [ ...(action.payload.data || []) ], salesSummaryUnitColumns: [ ...(action.payload.columns || []) ], salesSummaryUnitReceived: true }
        }
        case FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_UNIT: {
            return { ...state, shippingSummaryUnit: [ ...(action.payload.data || []) ], shippingSummaryUnitColumns: [ ...(action.payload.columns || []) ], shippingSummaryUnitReceived: true }
        }
        case FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_UNIT: {
            return { ...state, lastTenDaysSummaryUnit: [ ...(action.payload.data || []) ], lastTenDaysSummaryUnitColumns: [ ...(action.payload.columns || []) ], lastTenDaysSummaryUnitReceived: true }
        }
        case FETCH_ZUNSOLAROPS_MAPDATA: {
            return { ...state, mapData: [ ...action.payload ], mapDataReceived: true }
        }
        case FETCH_ORDER_VALUE_NEW: {
            return { ...state, orderValueNew: [ ...(action.payload.data || []) ], orderValueNewReceived: true, orderValueNewColumns: [ ...(action.payload.columns || []) ] };
        }
        default:
            return state;
    }
}
