import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import Select from 'react-select';

class PIInvoiceAll extends Component {

    constructor() {
        super();
        this.state = {
            allInvoices: [],
            showFilter: false,
            filters: {},
            tableEditFlag: {},
            approvalStatusMapping: [],
            invoiceTypeMapping: [],
            userObj: {}
        };
    }

    componentWillMount() {
        this.props.getAllPIInvoiceOrders()
    }

    componentWillReceiveProps(props) {
        let {allInvoices, approvalStatusMapping, invoiceTypeMapping, userObj} = props
        this.setState({
            allInvoices: allInvoices || [],
            approvalStatusMapping,
            invoiceTypeMapping,
            userObj
        })
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = (e) => {
        let { filters } = this.state
        this.props.getAllPIInvoiceOrders(filters)
    }

    AddSelect = ({ isMulti, label, name, options = [], onChange, required = false, value, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required ? '*' : ''}`}</label>
                        <Select
                            isSearchable={true}
                            placeholder={label}
                            name={name}
                            options={options}
                            onChange={(e) => onChange(e, name)}
                            isMulti={isMulti}
                            value={{ value: value, label: value }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    tableSelect = (name, value, options, row, onChange) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { onChange(e, row) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    tableInput = (name, value, type, row, onChange) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => onChange(e, row)} />
    }

    renderInputCell = (row) => {
        let { id, type } = row.column;
        let { tableEditFlag, editedTableData } = this.state;
        return (!tableEditFlag[row.index] ? row.value : this.tableInput(id, editedTableData[row.index][id], type, row, this.handleTableInputChange))
    }

    renderInputCellStirct = (row) => {
        let { id, type } = row.column;
        let { tableEditFlag, editedTableData } = this.state;
        return (!tableEditFlag[row.index] || row.value ? row.value : this.tableInput(id, editedTableData[row.index][id], type, row, this.handleTableInputChange))
    }

    multiSelectType = (name, options, placeholder, onChange) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }

    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        if (name == 'category') {
            let category = this.props.zunsolarCategoryMapping || {}
            let subCategoryOptions = [];
            value.map(val => {
                let options = (category[val] || []).map((subCategory) => { return { label: subCategory, value: subCategory } });
                subCategoryOptions = [...subCategoryOptions, ...(options || [])]
            })
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value,
                },
                subCategoryOptions
            })
        }
        else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value
                }
            })
        }
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[type] = filters[type] || {};
        filters[type][name] = value;
        this.setState({
            filters
        })
    }

    handleTableSelectChange = (e,row) => {
        let {name, value} = e.target
        let { index } = row;
        let { editedTableData } = this.state;
        editedTableData = JSON.parse(JSON.stringify(editedTableData))
        editedTableData[index][name] = value;
        this.setState({
            editedTableData
        })
    }

    handleTableInputChange = (e,row) => {
        let { name, value } = e.target;
        let { index } = row;
        let { editedTableData } = this.state;
        editedTableData = JSON.parse(JSON.stringify(editedTableData))
        editedTableData[index][name] = value;
        this.setState({
            editedTableData
        })
    }

    handleSaveTableClick = (row) => {
        let { index } = row;
        let {editedTableData, allInvoices} = this.state
        let newData = editedTableData[index] || {}
        let oldData = allInvoices[index] || {}
        let modifiedData = {};
        Object.keys(newData).map(field => {
            if (newData[field] != oldData[field]) {
                modifiedData[field] = newData[field]
            }
        });
        if(Object.keys(modifiedData).length > 0){
            if(modifiedData.approvalStatus == 'cancelled' && !modifiedData.comment){
                alert('comment is required for marking cancelled status')
                return
            }
            modifiedData['id'] = oldData.id
            this.props.updatePIInvoice(index,modifiedData,allInvoices,newData)
        }
        this.setState({
            tableEditFlag: {
                ...this.state.tableEditFlag,
                [index]: false
            }
        })
    }

    handleCancelTableClick = (row) => {
        let { index } = row;
        this.setState({
            tableEditFlag: {
                ...this.state.tableEditFlag,
                [index]: false
            }
        })
    }



    handleEditTableClick = (row) => {
        let { index } = row;
        this.setState({
            tableEditFlag: {
                ...this.state.tableEditFlag,
                [index]: true
            },
            editedTableData: {
                ...this.state.editedTableData,
                [index]: row.original
            }
        })
    }

    handleDownloadFileClick = (fileName) => {
        this.props.downloadFile(fileName)
    }

    render() {
        console.log(this.state)
        let { state, props, handleSaveTableClick, handleCancelTableClick, handleEditTableClick, handleDownloadFileClick, handleTableSelectChange, tableSelect, tableInput, handleTableInputChange, renderInputCell, renderInputCellStirct } = this
        let { allInvoices, tableEditFlag, editedTableData, approvalStatusMapping, invoiceTypeMapping, userObj } = state;
        let transactionsColumn = [
            {
                Header: 'S No.',
                accessor: 'id'
            },
            {
                Header: 'Order Id',
                accessor: 'orderId'
            },
            {
                Header: 'Dealer Id',
                accessor: 'dealerId'
            },
            {
                Header: 'Dealer Name',
                accessor: 'dealerName'
            },
            {
                Header: 'Dealer Address',
                accessor: 'dealerAddress'
            },
            {
                Header: 'Discount',
                accessor: 'discount'
            },
            {
                Header: 'Freight',
                accessor: 'freight'
            },
            {
                Header: 'Status',
                accessor: 'approvalStatus',
                Cell: row => {
                    return ((tableEditFlag[row.index] && ((userObj || {}).access || []).includes("Zunsolar-Admin")) ? ((['truncated','cancelled', 'approved']).includes(row.value)?row.value:tableSelect('approvalStatus', editedTableData[row.index]['approvalStatus'], approvalStatusMapping,row,handleTableSelectChange)):row.value)
                }
            },
            {
                Header: 'Apporve/Rejected By',
                accessor: 'actionPerformedBy'
            },
            {
                Header: 'Invoice Value',
                accessor: 'grandTotal'
            },
            {
                Header: 'Invoice Type',
                accessor: 'invoiceType',
                Cell: row => {
                    return (tableEditFlag[row.index]?tableSelect('invoiceType', editedTableData[row.index]['invoiceType'], invoiceTypeMapping,row,handleTableSelectChange):row.value)
                }
            },
            {
                Header: 'Invoice Date',
                accessor: 'createdOn'
            },
            {
                Header: 'Invoice Requested By',
                accessor: 'createdBy'
            },
            {
                Header: 'Invoice Number',
                accessor: 'invoiceNumber',
                Cell: renderInputCellStirct
            },
            {
                Header: 'Comment',
                accessor: 'comment',
                Cell: renderInputCell
            },
            {
                Header: "Buttons",
                Cell: row => {
                    return (
                        tableEditFlag[row.index] ? <div className="row text-center" style={{ width: 'fit-content' }}><button className="btn btn-success btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleSaveTableClick(row)}>Save</button><button className="btn btn-light btn-sm" onClick={(e) => handleCancelTableClick(row)}>Cancel</button></div> : <div className="row text-center" style={{ width: 'fit-content' }}><button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleEditTableClick(row)}>Edit</button>{row.original['invoiceDraftFile'] ? <button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleDownloadFileClick(row.original['invoiceDraftFile'])}>Draft</button> : ''}{row.original['invoiceApprovedFile'] ? <button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleDownloadFileClick(row.original['invoiceApprovedFile'])}>Invoice</button> : ''}</div>
                    )
                },
                minWidth: 200
            }
        ]

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Invoices</h4>
                <div className="topbar-section allLeadViewFilters">
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye"></i>} </b></div>
                    <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                    <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                        <div className="col-md-3">
                            {this.inputType("startDate", "Start Date", 'date', this.handleInputDateChangeFilters, 'createdOn')}
                        </div>
                        <div className="col-md-3">
                            {this.inputType("endDate", "End Date", 'date', this.handleInputDateChangeFilters, 'createdOn')}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("orderId", ((allInvoices || []).map((element) => { if (element.orderId) return { label: element.orderId, value: element.orderId }})), "Order Id", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("dealerId", ((allInvoices || []).map((element) => { if (element.dealerId) return { label: element.dealerId, value: element.dealerId }})), "Dealer Id", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={allInvoices}
                    columns={transactionsColumn}
                    defaultSorted={[{ id: "id", desc: true }]}
                />
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PIInvoiceAll);