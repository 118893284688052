import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { comboColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchMapping, addOrder, fetchInventory, fetchCombo } from '../../actions/zunsolarOrderTrackerActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader';
import swal from '@sweetalert/with-react'

class ZunsolarCreateOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openLotModal: false,
            orderData: {
                subOrders: [{}]
            },
            mapping: {},
            subCategoryOptions: [[]],
            // lotNoOptions: [[]],
            // comboLotData:[]
        };
    }

    componentWillMount() {
        this.props.fetchMapping();
        // this.props.fetchInventory();
        this.props.fetchCombo();
    }

    componentWillReceiveProps(props) {
        let { mapping, inventoryData, comboList } = props;
        console.log("props", props)
        this.setState({
            loading: false,
            received: true,
            mapping: mapping,
            inventoryData,
            comboList
        })
    }

    inputType = (name, value, type) => {

        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    selectType = (name, value, options) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleInputChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    subOrderInput = (name, value, type, index) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleSubOrderChange(e, index)} />
    }

    subOrderSelect = (name, value, options, index) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleSubOrderChange(e, index) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    subOrderSelectProductId = (name, value, options, index) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleSubOrderChange(e, index) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e.key} value={e.value}>{e.key}</option>)}
                </select>
            </div>
        )
    }

    subOrderSelectProductIdNew = (name, value, options = [], index) => {
        let selectValue = '';
        let selectOptions = options.map((uniProductId) => {
            let variantType = uniProductId.variantType;
            let unicommerceId = uniProductId.unicommerceId;
            if(variantType == "NULL") variantType = "";
            if(uniProductId.pID == value) {
                selectValue = {
                    label: `${uniProductId.pID} ${uniProductId.productName} ${variantType} ${unicommerceId}`, 
                    value: uniProductId.pID,
                    details: uniProductId
                }
            }
            return {
                label: `${uniProductId.pID} ${uniProductId.productName} ${variantType} ${unicommerceId}`, 
                value: uniProductId.pID,
                details: uniProductId
            }
        });
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isSearchable
                    onChange={(e) => { this.onChangeProductId(index, e, name) }}
                    placeholder={"Select product id"}
                    name={name}
                    options={selectOptions}
                    value={selectValue}
                />
            </div>
        )
    }

    onChangeProductId = (index, e, name) => {
        let { value, details } = e;
        let { orderData, mapping } = this.state;
        let { subOrders } = orderData;
        console.log(subOrders[index])
        if(value) {
            subOrders[index][name] = value;
            subOrders[index]['category'] = details.category;
            subOrders[index]['subCategory'] = details.subCategory;
            subOrders[index]['productName'] = details.productName;
            subOrders[index]['onlinePrice'] = details.sellingPrice;
            subOrders[index]["unicomerceItemSkuCode"] = details.unicommerceId;
            if(details.variantType){
                subOrders[index]['variantType'] = details.variantType;
            }

            this.setState({
                orderData: {
                    ...this.state.orderData,
                    subOrders
                }
            })
        }
    }

    createOrderSubmit = () => {
        let { orderData } = this.state;
        let orderInvoice = orderData.orderInvoice
        // let { subOrders } = orderData;
        // console.log("orderData before deepclone", orderData)
        let orderDataCopy = JSON.parse(JSON.stringify(orderData));
        // console.log("orderData after deep clone", orderDataCopy);
        let { subOrders } = orderDataCopy;
        console.log(orderData);
        console.log(subOrders);

        if (!orderData.platform) {
            alert("Please Fill Required Field - Platform")
            return;
        }
        if (!orderData.orderId) {
            alert("Please Fill Required Field - Order Id")
            return;
        }
        if (!orderData.date) {
            alert("Please Fill Required Field - Date")
            return;
        }
        if (!orderData.orderType){
            alert("Please Fill Required Field - Order Type")
            return;
        }
        if(orderData.platform == "Consultative Sales" && !orderData.installationCharges) {
            alert("Please fill Installation Charges");
            return;
        }
        if (!subOrders.length || !this.validateSubOrders(subOrders, orderData)) {
            alert("Please Check Products List");
            return;
        }
        for(let suborder of subOrders){
            if (suborder.status === 'In Transit' && !suborder.trackingNumber){
                alert("Please Fill Required Fied - Tracking ID");
                return;
            }
            if (suborder.status === 'Delivered' && !suborder.deliveryDate) {
                alert("Please Fill the Required Field - Delivery Date");
                return;
            }
            if(["Cancelled", "Returned (Before Delivery)", "Returned (After Delivery)", "Returning (Before Delivery)", "Returning (After Delivery)"].includes(suborder.status) && !suborder.returnReason) {
                alert("Please Fill the Required Field - Return Reason");
                return;
            }
            if (suborder.shippingFees > orderData.invoiceValue*0.15) {
                let answer=window.confirm("Shipping charges are too high. Kindly confirm")
                if (!answer) {
                    return;
                }
                // console.log(isCheck);
                // if (!isCheck){
                //     return;
                // }
            }
        }

        let subOrderNew = [];

        subOrders.map((suborder) => {
            let quantity = suborder.quantity;
            suborder = JSON.parse(JSON.stringify(suborder));
            delete suborder.quantity
            subOrderNew.push(suborder);

            if (quantity) {

                // console.log("inside quantity")
                for (let i = 1; i < quantity; i++) {
                    let objectToPush = {};
                    let deepCloneSuborder = JSON.parse(JSON.stringify(suborder));
                    objectToPush.subOrderId = `${deepCloneSuborder.subOrderId}-${i}`;
                    delete deepCloneSuborder.subOrderId;
                    objectToPush = { ...objectToPush, ...deepCloneSuborder };
                    subOrderNew.push(objectToPush);
                }
            }
        });

        orderDataCopy.subOrders = subOrderNew;
        orderDataCopy.orderInvoice = orderInvoice ? orderInvoice : '';
        // console.log("Create order", orderDataCopy);


        this.props.addOrder(orderDataCopy);
    }

    validateSubOrders = (subOrders = [], orderData = {}) => {
        let isValid = true;
        subOrders.map(subOrder => {
            if(orderData.orderType == "Return Pickup" || orderData.orderType == "Replacement"){
                if (!subOrder.returnReason) {
                    isValid = false;
                }
            }
            if (!subOrder.subOrderId || !subOrder.category || !subOrder.subCategory || !subOrder.status || !subOrder.productName) {
                isValid = false;
            }
        })
        return isValid;
    }

    handleSubOrderChange = (e, index) => {
        let { name, value } = e.target;
        let { orderData, mapping, subCategoryOptions, lotNoOptions } = this.state;
        let { subOrders } = orderData;
        console.log(subOrders[index])
        if (name == 'category' && value) {
            subOrders[index][name] = value;
            if (value == 'Combos') {
                subCategoryOptions[index] = this.state.comboList.map(row => row.comboId);
            }
            else {
                subCategoryOptions[index] = mapping.category[value]
            }
            this.setState({
                orderData: {
                    ...this.state.orderData,
                    subOrders
                },
                subCategoryOptions
            })
        } else if (name == 'pID' && value) {
            subOrders[index][name] = value;
            subOrders[index]['category'] = mapping.uniProductIds[value].category;
            subOrders[index]['subCategory'] = mapping.uniProductIds[value].subCategory;
            subOrders[index]['onlinePrice'] = mapping.uniProductIds[value].sellingPrice;
            subOrders[index]['productName'] = mapping.uniProductIds[value].productName;
            if(mapping.uniProductIds[value].variantType){
                subOrders[index]['variantType'] = mapping.uniProductIds[value].variantType;
            }

            this.setState({
                orderData: {
                    ...this.state.orderData,
                    subOrders
                }
            })
        }
        // else if (name == 'subCategory' && value) {
        //     let { category, subCategory } = subOrders[index];
        //     subOrders[index][name] = value;
        //     if (category != "Combos") {
        //         lotNoOptions[index] = this.state.inventoryData.filter(row => {
        //             return (row.category == category && row.subCategory == value && row.inStock > 0)
        //         }).map(val => val.lotNo);
        //         subOrders[index]["lotNo"] = lotNoOptions[index][0];
        //     }
        //     else {
        //         let { comboList, inventoryData } = this.state;
        //         let comboDetails = comboList.find(val => val.comboId == value);
        //         let comboLots = [];
        //         for (let i = 1; i <= 8; i++) {
        //             if (comboDetails[`product${i}Category`] && comboDetails[`product${i}SubCategory`] && comboDetails[`product${i}Qty`]) {
        //                 for (let item = 0; item < comboDetails[`product${i}Qty`]; item++) {
        //                     let lotOptions = inventoryData.filter(row => {
        //                         return (
        //                             row.category == comboDetails[`product${i}Category`] &&
        //                             row.subCategory == comboDetails[`product${i}SubCategory`] &&
        //                             row.inStock > 0
        //                         )
        //                     }).map(val => val.lotNo);
        //                     comboLots.push({
        //                         category: comboDetails[`product${i}Category`],
        //                         subCategory: comboDetails[`product${i}SubCategory`],
        //                         productId: `product${i}`,
        //                         item: item,
        //                         lotOptions: lotOptions,
        //                         lotNo: lotOptions[0] || ""
        //                     })
        //                 }
        //             }
        //         }
        //         subOrders[index]["comboLots"] = comboLots
        //     }
        //     this.setState({
        //         orderData: {
        //             ...this.state.orderData,
        //             subOrders
        //         },
        //         lotNoOptions
        //     })
        // }
        else {
            subOrders[index][name] = value;
            this.setState({
                orderData: {
                    ...this.state.orderData,
                    subOrders
                }
            })
        }
    }

    addSuborder = () => {
        let { orderData } = this.state;
        let { subOrders } = orderData;
        let addedSubOrder = {}
        if(orderData.orderType){
            if(orderData.orderType == "Return Pickup"){
                addedSubOrder.subOrderId = `RPU-${moment().format('x')}-SO-${subOrders.length}`;
                addedSubOrder.status = "Not Shipped"
            } else if(orderData.orderType == "Replacement"){
                addedSubOrder.subOrderId = `RP-${moment().format('x')}-SO-${subOrders.length}`;
                addedSubOrder.status = "Not Shipped"
            } else {
                addedSubOrder.subOrderId = ""
                addedSubOrder.status = ""
            }
        }
        subOrders = [...subOrders, addedSubOrder];
        this.setState({
            orderData: {
                ...this.state.orderData,
                subOrders: subOrders
            }
        })
    }

    addWithPrefilledValues = (index) => {
        console.log(index)
        let { orderData } = this.state;
        let { subOrders } = orderData;

        if (Object.keys(subOrders[index]).length) {

            let subOrderCopy = JSON.parse(JSON.stringify(subOrders[index]));
            let valuesToDelete = ["onlinePrice", "subOrderId", "category", "subCategory", "quantity", "productName"];
            valuesToDelete.map((val) => {
                delete subOrderCopy[val]
            });

            subOrders = [...subOrders, { ...subOrderCopy }];

            this.setState({
                orderData: {
                    ...this.state.orderData,
                    subOrders: subOrders
                }
            })
        }
    }

    handleInputChange = (e) => {

        let { name, value } = e.target;
        let { orderData } = this.state
        if(name == "orderType"){
            if(value == "Return Pickup"){
                orderData.orderId = `RPU-${moment().format('x')}`
                orderData.subOrders.forEach((subOrder,i) => {
                    subOrder.subOrderId = `RPU-${moment().format('x')}-SO-${i}`
                    subOrder.status = "Not Shipped"
                })
            } else if(value == "Replacement"){
                orderData.orderId = `RP-${moment().format('x')}`
                orderData.subOrders.forEach((subOrder,i) => {
                    subOrder.subOrderId = `RP-${moment().format('x')}-SO-${i}`
                    subOrder.status = "Not Shipped"
                })
            } else {
                orderData.orderId = ""
                orderData.subOrders.forEach((subOrder) => {
                    subOrder.subOrderId = ""
                    subOrder.status = ""
                })
            }
        }
        this.setState({
            orderData: {
                ...orderData,
                [name]: value
            }
        })
    }

    handleFileUpload = (e) => {
        let { name, files } = e.target;
        console.log(files)
        console.log("object")
        this.setState({
            orderData: {
                ...this.state.orderData,
                [name]: files[0]
            }
        })
    }

    // viewComboLots = (index) => {
    //     console.log("index", index)
    //     let { orderData } = this.state;
    //     let { subOrders } = orderData;
    //     let comboLotData = subOrders[index].comboLots;
    //     this.setState({
    //         openLotModal:true,
    //         index,
    //         comboLotData
    //     })
    // }

    // closeLotModal = () =>{
    //     this.setState({
    //         openLotModal: false
    //     })
    // }

    // comboLotSelect = (name, value, options, index) => {
    //     return (
    //         <div style={{ width: '100%' }}>
    //             <select
    //                 onChange={(e) => { this.handleComboLotChange(e, index) }}
    //                 className="form-control" name={name} value={value}
    //             >
    //                 <option key="-1" value="">Select</option>
    //                 {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
    //             </select>
    //         </div>
    //     )
    // }

    // handleComboLotChange = (e, index) => {
    //     let { name, value } = e.target;
    //     let { orderData } = this.state;
    //     let { subOrders } = orderData;
    //     let selectedIndex = this.state.index;
    //     subOrders[selectedIndex]["comboLots"][index].lotNo = value;
    //     this.setState({
    //         orderData:{
    //             ...this.state.orderData,
    //             subOrders
    //         }
    //     })
    // }

    handleQuantityChange = (e, index) => {
        let { value, name } = e.target;
        let { orderData } = this.state;
        let { subOrders } = orderData;
        console.log(subOrders, index);

        if (value) {
            let subOrderWithQuantity = subOrders[index];
            console.log("suborderWithQuantiy", subOrderWithQuantity);
        }
        this.setState({
            [name]: value
        })
    }

    render() {
        let { viewComboLots } = this;
        let { mapping, orderData, subCategoryOptions, lotNoOptions, comboLotData = [] } = this.state;
        let { subOrders } = orderData;
        console.log("order data", orderData);
        let brandMapping = mapping.brand || {};
        const modalStyles = {
            modal: {
                width: '80%'
            }
        }
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <Modal styles={modalStyles} open={this.state.openLotModal} onClose={this.closeLotModal} center>
                    <div style={{ textAlign: "center" }}>
                        <b>Select Lot No</b>
                        {
                            comboLotData.map((product, id) => {
                                return (
                                    <div className="row" style={{ margin: "20px" }}>
                                        <div className="col-3">{product.category}</div>
                                        <div className="col-3">{product.subCategory}</div>
                                        <div className="col-6">{this.comboLotSelect("lotNo", product.lotNo, product.lotOptions || [], id)}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal>
                {this.state.received ? <div style={{ "marginTop": 60 }} className="container-fluid">
                    <div className="topbar-section" >
                        <div className="row">
                            <div className="col-md-10 col-xs-12 topbar-left">
                                <div className="col-md-12">
                                    <strong>Create Order</strong>
                                </div>
                            </div>
                            <div className="col-md-2 col-xs-12 topbar-right">
                                <div className="col-md-12 col-xs-12">
                                    <button className="button-save" onClick={(e) => this.createOrderSubmit(e)}>Save</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table>
                                <tr>
                                    <td>Date</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("date", this.state.orderData.date, "date")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("orderType", this.state.orderData.orderType, mapping.orderType)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Order Id</td>
                                    <td>
                                        <span className="input-group">
                                            {orderData.orderType && (orderData.orderType == "Return Pickup" || orderData.orderType == "Replacement") ? this.state.orderData.orderId : this.inputType("orderId", this.state.orderData.orderId, "text")}
                                        </span>
                                    </td>
                                </tr>
                                {orderData.orderType && (orderData.orderType == "Return Pickup" || orderData.orderType == "Replacement")?
                                    (<tr>
                                        <td>Parent Order Id</td>
                                        <td>
                                            <span className="input-group">
                                            {this.inputType("parentOrderId", this.state.orderData.parentOrderId, "text")}
                                            </span>
                                        </td>
                                    </tr>)
                                :""}
                                <tr>
                                    <td>Platform</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("platform", this.state.orderData.platform, mapping.platform)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("phone", this.state.orderData.phone, "number")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Partner Id</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("partnerId", this.state.orderData.partnerId, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Partner GST</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("partnerGst", this.state.orderData.partnerGst, "text")}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table className="customer-info-left">
                                <tr>
                                    <td>Payment Mode</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("paymentMode", this.state.orderData.paymentMode, mapping.paymentMode)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice</td>
                                    <td>
                                        <span className="input-group">
                                            <input type="file" name="orderInvoice" onChange={(e) => this.handleFileUpload(e)}></input>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice Value</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("invoiceValue", this.state.orderData.invoiceValue, "number")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice Number</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("invoiceNumber", this.state.orderData.invoiceNumber, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("invoiceDate", this.state.orderData.invoiceDate, "date")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Advanced Recieved</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("advancedRecieved", this.state.orderData.advancedRecieved, "number")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Installation Charges</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("installationCharges", this.state.orderData.installationCharges, "number")}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info">
                            <table className="customer-info-right">
                                <tr>
                                    <td>Name</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("name", this.state.orderData.name, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("address", this.state.orderData.address, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pincode</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("pincode", this.state.orderData.pincode, "number")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("state", this.state.orderData.state, mapping.states || [])}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("city", this.state.orderData.city, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transaction Id</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("transactionId", this.state.orderData.transactionId, "text")}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    {
                        this.state.orderData.isCombo != "Yes" ? <div>
                            <div className="topbar-section" >
                                <div className="subOrderFormTopbar">
                                    <div>
                                        <strong>Products</strong>
                                    </div>
                                    <div>
                                        <button style={{ marginLeft: "18px" }} className="button-save" onClick={(e) => this.addSuborder(e)}>Add</button>
                                    </div>
                                </div>
                            </div>
                            {subOrders.map((row, i) => {
                                return (
                                    <div className="row">
                                        <div className="col-md-3 customer-info" style={{ borderRight: '0px' }}>
                                            <table>
                                                <tr>
                                                    <td>Sub Order Id</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {orderData.orderType && (orderData.orderType == "Return Pickup" || orderData.orderType == "Replacement")?row.subOrderId:this.subOrderInput("subOrderId", row.subOrderId, "text", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {orderData.orderType && (orderData.orderType == "Return Pickup" || orderData.orderType == "Replacement")?
                                                    (<tr>
                                                        <td>Parent Sub Order Id</td>
                                                        <td>
                                                            <span className="input-group">
                                                            {this.subOrderInput("parentSubOrderId", row.parentSubOrderId, "text", i)}
                                                            </span>
                                                        </td>
                                                    </tr>)
                                                :""}
                                                <tr>
                                                    <td>Shipping Partner</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderSelect("shippingPartner", row.shippingPartner, mapping.shippingPartner || [], i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Cancellation Date</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("cancellationDate", row.cancellationDate, "date", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Tracking ID</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("trackingNumber", row.trackingNumber, "text", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Wattage</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderSelect("wattage", row.wattage, mapping.wattage || [], i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="col-md-3 customer-info" style={{ borderRight: '0px' }}>
                                            <table>
                                                <tr>
                                                    <td>productId</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderSelectProductIdNew("pID", row.pID, Object.values(mapping.uniProductIds || {}), i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {row.category}
                                                            {/* {this.subOrderSelect("category", row.category, Object.keys(mapping.category || {}), i)} */}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>variantType</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {row.variantType}
                                                            {/* {this.subOrderSelect("category", row.category, Object.keys(mapping.category || {}), i)} */}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Unicommerce SKU ID</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {row.unicomerceItemSkuCode}
                                                            {/* {this.subOrderSelect("category", row.category, Object.keys(mapping.category || {}), i)} */}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td>Tracking Number</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("trackingNumber", row.trackingNumber, "text", i)}
                                                        </span>
                                                    </td>
                                                </tr> */}
                                                <tr>
                                                    <td>Shipping Fees</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("shippingFees", row.shippingFees, "number", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Online Price</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("onlinePrice", row.onlinePrice, "number", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Invoice Value</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("invoiceValue", row.invoiceValue, "number", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {/* { row.category && brandMapping[row.category] ? <tr>
                                                    <td>Brand</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderSelect("brand", row.brand, brandMapping[row.category] || [], i)}
                                                        </span>
                                                    </td>
                                                </tr> : "" } */}
                                                {/* <tr>
                                                    <td>Invoice Number</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("invoiceNumber", row.invoiceNumber, "text", i)}
                                                        </span>
                                                    </td>
                                                </tr> */}
                                            </table>
                                        </div>
                                        <div className="col-md-3 customer-info" style={{ borderRight: '0px' }}>
                                            <table>
                                                <tr>
                                                    <td>Sub Category</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {/* {this.subOrderSelect("subCategory", row.subCategory, subCategoryOptions[i] || [], i)} */}
                                                            {row.subCategory}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Product Name</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {/* {this.subOrderSelect("subCategory", row.subCategory, subCategoryOptions[i] || [], i)} */}
                                                            {row.productName}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pick Up Date</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("pickUpDate", row.pickUpDate, "date", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Platform Fees</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("platformFees", row.platformFees, "number", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td>Lot No</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {row.category != "Combos" ? this.subOrderSelect("lotNo", row.lotNo, lotNoOptions[i] || [], i) : <button className="btn btn-link" onClick={() => viewComboLots(i)}>View</button>}
                                                        </span>
                                                    </td>
                                                </tr> */}
                                                <tr>
                                                    <td>Delivery Date</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("deliveryDate", row.deliveryDate, "date", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Return Reason</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderSelect("returnReason", row.returnReason, mapping.returnReason || [], i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="col-md-3 customer-info">
                                            <table>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {orderData.orderType && (orderData.orderType == "Return Pickup" || orderData.orderType == "Replacement")?row.status:this.subOrderSelect("status", row.status, mapping.status || [], i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Loss/Damage Status</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderSelect("lossdamageStatus", row.lossdamageStatus, mapping.lossdamageStatus || [], i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Warehouse charges</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("warehouseCharges", row.warehouseCharges, "text", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Packaging charges</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("packagingCharges", row.packagingCharges, "text", i)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {orderData.orderType != "Return Pickup" && orderData.orderType != "Replacement"?(<tr>
                                                    <td>Quantity</td>
                                                    <td>
                                                        {this.subOrderInput("quantity", row.quantity, "number", i)}
                                                    </td>
                                                </tr>):""}
                                                
                                                {/* <tr>
                                                    <td>Shipping Fees</td>
                                                    <td>
                                                        <span className="input-group">
                                                            {this.subOrderInput("shippingFees", row.shippingFees, "number", i)}
                                                        </span>
                                                    </td>
                                                </tr> */}

                                            </table>
                                            <div className="prefilledSuborderButton">
                                                {orderData.orderType != "Return Pickup" && orderData.orderType != "Replacement"?<button className="btn btn-light" onClick={(e) => this.addWithPrefilledValues(i)}>New Suborder with Pre filled Values</button>:""}
                                            </div>
                                        </div >
                                    </div >
                                )
                            })
                            }
                        </div > : ""
                    }
                </div > : <Loader />}
            </div >
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps({ zunSolarOrderTracker }) {
    let { mapping = {}, inventoryData = [], comboList = [] } = zunSolarOrderTracker || {};
    return {
        mapping,
        inventoryData,
        comboList
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMapping, fetchInventory, addOrder, fetchCombo, getSearchResult }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarCreateOrder);