import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import url from '../config';
import moment from "moment";
import { CREATE_LEAD_START, CREATE_LEAD_SUCCESS, CREATE_LEAD_ERROR, CHECK_EXISTING_PHONE_START, CHECK_EXISTING_PHONE_SUCCESS, CREATE_LEAD_DEALERID, MT_LEAD_DATA } from './actiontypes';
import swal from '@sweetalert/with-react'
import { object } from 'prop-types';

export function createLead(leadData, view = "solar",mtView=false) {
  // console.log("newLead",lead)
  return (dispatch) => {
    let lead = { ...leadData };
    let dateFormats = {
      SITE_ASSESSMENT_DATE: 'YYYYMMDD',
      ADDED_ON: 'YYYYMMDD',
      NEXT_ACTION_DATE: 'YYYYMMDD', 
      nextActionDate: 'YYYYMMDD',
      partner_onboarding_date: 'YYYYMMDD',
      dateOfBirth: 'YYYYMMDD',
      dateOfAnniversary: 'YYYYMMDD'
    }
    // let route = (view == 'zunpulse') ? `zunpulse/lead/create` : `lead/create`;
    // let swalRoute = (view == 'zunpulse') ? `/zunpulse/details/` : `/lead/details/`
    let route, swalRoute;
    switch (view) {
      case 'solar':
        route = `lead/create`;
        swalRoute = `/lead/details/`;
        break;
      case 'zunpulse':
        route = `zunpulse/lead/create`;
        swalRoute = `/zunpulse/details/`;
        break;
      case 'zunsolar':
        route = `zunsolar/lead/create`;
        swalRoute = `/zunsolar/details/`;
        break;
      default:
        route = `lead/create`;
        swalRoute = `/lead/details/`;
    }
    Object.keys(dateFormats).map((key) => {
      if (lead[key]) {
        lead[key] = moment(lead[key]).format(dateFormats[key])
      }
    })
    let newLeadObj = { lead: lead }

    axios.post(url.pathBackend + route, newLeadObj).then((response) => {
      if (Object.keys(response.data.data).length > 0) {
        let bitrixId = response.data.data.leadExist ? response.data.data.existingLeadId : response.data.data.insertId;
        let alertText = response.data.data.leadExist ? "Lead Already Exists" : "Lead Created Successfully";
        let icon = response.data.data.leadExist ? "warning" : "success";
        let button = response.data.data.leadExist ? { cancel: "Close", duplicateLeadTrue: { text: "Create Lead Anyway", value: "duplicateLeadTrue" } } : { cancel: "Close" };
        // window.location.href = url.pathFrontend
        dispatch({ type: CREATE_LEAD_SUCCESS, payload: "successfully created lead" })
        dispatch({type:CREATE_LEAD_DEALERID,payload:bitrixId})
        dispatch({type:MT_LEAD_DATA,payload:leadData})

        if(mtView && !response.data.data.leadExist){
           window.open(window.location.origin+"/mt/createUpdateFirst/"+bitrixId,"_self")
           return
        }
        // swal("creted",`new bitrix id ${response.data.data.insertId}`,"success")
        swal({
          text: alertText,
          buttons: button,
          icon: icon,
          content: (
            <div>
             {mtView && <p>Bitirx id <span><a style={{ cursor: "pointer", color: "blue" }} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/mt/dealerLeadDetails/" + bitrixId,"_self"); }}>{bitrixId}</a></span></p>}
             {!mtView && <p>Bitirx id <span><a style={{ cursor: "pointer", color: "blue" }} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + swalRoute + bitrixId); }}>{bitrixId}</a></span></p>}
            </div>
          )
        }).then((value) => {
          switch (value) {
            case "duplicateLeadTrue":
              lead.duplicateLeadFlag = true;
              axios.post(url.pathBackend + route, newLeadObj).then((duplicateLeadResponse) => {
                if (duplicateLeadResponse.data.data) {
                  let bitrixId = duplicateLeadResponse.data.data.insertId
                  if(bitrixId && mtView){
                    window.open(window.location.origin+"/mt/createUpdateFirst/"+bitrixId,"_self")
                    return;
                  }
                  swal({
                    text: "Lead Created Successfully",
                    buttons: {
                      cancel: "Close"
                    },
                    icon: "success",
                    content: (
                      <div>
                        <p>Bitirx id <span><a style={{ cursor: "pointer", color: "blue" }} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + swalRoute + bitrixId); }}>{bitrixId}</a></span></p>
                      </div>
                    )
                  })
                }
                if (duplicateLeadResponse.data.error) {
                  swal(duplicateLeadResponse.data.error)
                }
              })
          }
        })
      }
      if (response.data.error) {
        swal(response.data.error)
      }
    }).catch((error) => {
      console.log(error)
      swal("some error occured")
    })
  }
}

export function checkExistingPhone(phoneNumber) {
  return (dispatch) => {
    let phoneStr = phoneNumber.toString();
    dispatch({ type: CHECK_EXISTING_PHONE_START, payload: [] });
    let obj = {
      phoneNumber: phoneStr
    }
    axios.post(url.pathBackend + 'lead/phoneNumber', obj).then((response) => {
      if (response.data.data) {
        dispatch({ type: CHECK_EXISTING_PHONE_SUCCESS, payload: response.data.data });
      }
    }).catch((error) => {
      console.log(error)
    })
  }
}

export function getStateCity(pincode){
  return async (dispatch)=>{
      pincode = pincode+"";
  try {
    console.log('pincode',pincode);
    let res = await axios.get(`https://www.zunroof.com/websiteapi/php_ajax_zip.php?q=${pincode}`,{responseType: 'text'}); 
      console.log('resullt', res);
      return;
      let data = res.data;
    data = data.split('</br>');
    const firstElementArray = data[0].split('|');
    const state = firstElementArray[2] && firstElementArray[2].toLowerCase() || 'Other';
    const city = (firstElementArray[2] && firstElementArray[1]) ? firstElementArray[1].toLowerCase() : 'Other';
    let pincodeData = {
      state:state,
      city:city
    }
    return pincodeData;
    
    
    
  } catch (error) {
    console.log("error", error)
    return {}
    
   }
 }
}