import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { zunpulseNewOrderColumns as zunpulseOrderColumns, History } from '../../config/column_fields';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { fetchOrderNew, updateOrderStatusNew, fetchOrdersMapping, addZunpulseOrderCommentNew , generateInvoice, downloadInvoicePdf } from '../../actions/zunpulseOrderTrackingActions.js';
import { fetchAllMapping } from '../../actions/fetchAllMapping';
import moment from 'moment';
import { fetchMapping as fetchZunpulseOpsMapping } from '../../actions/zunpulseOpsDashboardActions';


class ZunpulseSingleOrderViewNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      order: {},
      payment: {},
      products: [],
      newData: {},
      tableColumns: [],
      editStatus: {},
      editedOrderStatus: {},
      newOrderData: {},
      mapping: {},
      newComment: "",
      comments: [],
      username: "",
      otherOrders: {},
      employeeDetails: {},
      employees: [],
      showUtmVariables: false,
    };
    this.statusMap = ["Not Procured", "Procured", "Shipped", "Delivered", "Cancelled", "Returning (Before Delivery)", "Returning (After Delivery)", "Returned", "Replacement", "Truncated"];
    this.sourceMap = ["web", "app", "manual", "Flipkart", "Snapdeal", "Amazon", "Cred", "City sales", "B2B","PEPPERFRY", "TATA CLIQ","Meesho","Livspace","NobrookerHood","Shopee"];
    this.paymentModeMap = ["Prepaid", "COD", "NA"];
    this.stageMap = ["New", "Warm", "Hot", "Cold", "Duplicate", "Bought"];
    this.invoiceType = ["Tax Invoice","Cancelled"];
    this.orderType = ["Normal", "Return Pickup", "Replacement"];
    this.productsColumns = [
      {
        Header: 'Product Name',
        accessor: 'deviceName'
      },
      {
        Header: 'Quantity',
        accessor: 'quantity'
      },
      {
        Header: 'Selling Price',
        accessor: 'sellingPrice'
      },
      {
        Header: 'MRP',
        accessor: 'totalPrice'
      },
      // {
      //   Header: 'Is Pre Booked',
      //   accessor: 'isPreBooked'
      // },
      // {
      //   Header: 'Unique Product Id',
      //   accessor: 'uniqueProductId',
      //   Cell: row => {
      //     return (this.state.editStatus[`uniqueProductId_${row.original.id}`] ? <span><input type="text" className="form-control" name={`uniqueProductId_${row.original.id}`} value={this.state.newData[`uniqueProductId_${row.original.id}`]} onKeyUp={(e) => { this.handleOnKeyPress(e, row) }} onChange={(e) => { this.handleInputChange(e) }}></input></span> : <div onDoubleClick={() => { this.handleOnUniqueProductIdClick(row) }}>{row.value || 'N/A'}</div>);
      //   }
      // },
      {
        Header: 'Tracking Id',
        accessor: 'trackingId',
        // Cell: row => {
        //   return (this.state.editStatus[`trackingId_${row.original.id}`] ? <span><input type="text" className="form-control" name={`trackingId_${row.original.id}`} value={this.state.newData[`trackingId_${row.original.id}`]} onKeyUp={(e) => { this.handleOnKeyPress(e, row) }} onChange={(e) => { this.handleInputChange(e) }}></input></span> : <div onDoubleClick={() => { this.handleOnTrackingIdClick(row) }}>{row.value || 'N/A'}</div>);
        // }
      },
      {
        Header: 'Order Date',
        accessor: 'orderDate',
        Cell: row => {
          return ((row.original.status == "0") ? "N/A" : row.original.orderDate ? moment(+row.original.orderDate).format('ll') : '')
        }
      },
      {
        Header: 'Shiping Date',
        accessor: 'shippingDate',
        Cell: row => {
          return ((row.original.status == "0" || row.original.status == "1") ? "N/A" : row.original.shippingDate ? moment(+row.original.shippingDate).format('ll') : '');
        }
      },
      {
        Header: 'Delivery Date',
        accessor: 'deliveryDate',
        Cell: row => {
          return ((row.original.status == "0" || row.original.status == "1" || row.original.status == "2") ? "N/A" : row.original.deliveryDate ?
            moment(+row.original.deliveryDate).format('ll') : '');
        }
      },
      {
        Header: 'Delivery Status',
        accessor: 'status',
        Cell: row => {
          return this.statusMap[row.original.status] || '';
        }
      },
    ];
  }

  componentWillMount() {
    let viewList = ["employees"]
    this.props.fetchAllMapping(viewList);
    this.props.fetchOrdersMapping()
    this.props.fetchOrderNew(this.props.match.params.id);
    this.props.fetchZunpulseOpsMapping();
    let tableColumns = [];
    zunpulseOrderColumns.forEach(val => {
      if (val.accessor == 'status') {
        val.Cell = row => {
          return (this.state.editStatus[row.original.id] ? this.selectInputDiv(row, this.statusMap) : <div onDoubleClick={() => { this.handleOnTableCellClick(row) }}>{this.statusMap[row.value]}</div>)
        }
      }
      if(val.accessor == 'checkbox') {
        return;
      }
      tableColumns.push(val);
    })
    this.setState({
      tableColumns
    })
  }

  componentWillReceiveProps(props) {
    let username = JSON.parse(localStorage.getItem("userObj")).email;
    let employeeDetails = JSON.parse(localStorage.getItem("userObj"))
    console.log(employeeDetails);
    // let username = JSON.parse(userObj).email;
    // console.log("user", username);
    let { zunpulseOrders = {}, mapping = {} } = props;
    let state = mapping ? Object.keys(mapping).map((val) => ({ value: val, label: val })) : [];
    let employees = this.props.allMapping.employees ? Object.keys(this.props.allMapping.employees).map((val) => this.props.allMapping.employees[val].e_name) : [] ;
    this.setState({
      order: zunpulseOrders.order || {},
      payment: zunpulseOrders.payment || {},
      products: zunpulseOrders.products || {},
      mapping: mapping,
      username: username,
      employeeDetails: employeeDetails,
      comments: zunpulseOrders.comments || [],
      employees: employees,
      otherOrders: zunpulseOrders.otherOrders || {},
      orderHistory: zunpulseOrders.orderHistory || []
    })
  }

  handleOnKeyPress = (e, row) => {
    let { name } = e.target;
    let { products } = this.state;
    let key = name.split('_')[0]
    let value = this.state.newData[name]
    let newData = {
      [key]: value
    }
    if (e.keyCode === 13 && !e.shiftKey) {
      products[row.index][key] = value;
      this.props.updateOrderStatus(undefined, this.state.order.id, undefined, newData, this.state.employeeDetails.username)
      this.setState({
        editStatus: {
          ...this.state.editStatus,
          [name]: false
        },
        products: products
      })
      // console.log('pro', this.state.products)
    }
    if (e.keyCode === 27) {
      this.setState({
        editStatus: {
          ...this.state.editStatus,
          [name]: false
        }
      })
    }
  }

  handleOnUniqueProductIdClick = (row) => {
    let { uniqueProductId } = row.original;
    this.setState({
      editStatus: {
        ...this.state.editStatus,
        [`uniqueProductId_${row.original.id}`]: true
      },
      newData: {
        ...this.state.newData,
        [`uniqueProductId_${row.original.id}`]: uniqueProductId
      }
    })
  }

  handleOnTrackingIdClick = (row) => {
    let { trackingId } = row.original;
    // console.log(trackingId)
    this.setState({
      editStatus: {
        ...this.state.editStatus,
        [`trackingId_${row.original.id}`]: true
      },
      newData: {
        ...this.state.newData,
        [`trackingId_${row.original.id}`]: trackingId
      }
    })
  }

  handleEdit = () => {
    if (this.state.edit) {
      this.setState({
        edit: false,
        newData: {},
        newOrderData: {}
      })
    } else {
      this.setState({
        edit: true,
        newOrderData: {
          ...this.state.order
        },
        city: Object.keys(this.state.mapping).length > 0 ? this.state.mapping[this.state.order.state] : [],
        newData: {
          uniqueProductId: this.state.order.uniqueProductId,
          trackingId: this.state.order.trackingId
        }
      })
    }
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      newData: {
        ...this.state.newData,
        [name]: value,
      }
    })
  }

  handleOnSubmit = (e) => {
    this.props.updateOrderStatus(undefined, this.state.order.id, undefined, this.state.newData, this.state.employeeDetails.username)
    this.setState({
      edit: false,
      order: { ...this.state.order, ...this.state.newData },
    })
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }


  //============html inputs and select type functions ==========//

  inputType = (name, value, type, placeholder) => {
    let val = value;
    if(type == "date" && value) {
      val = val ? moment(+val).format("YYYY-MM-DD") : "";
    }
    console.log(name, value, val);
    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={val} onChange={(e) => this.handleChange(e)} />
  }

  selectType = (name, value, options) => {
    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  handleChange = (e) => {
    let { name, value, type } = e.target;
    value = this.checkSelectString(value);
    if (name == "state") {
      this.setState({
        city: [...this.state.mapping.stateCityMapping[value]],
        newOrderData: {
          ...this.state.newOrderData,
          [name]: value
        }
      })
    }
    else if (name == "status") {
      if (!value) {
        return
      }
      let statusIndex = this.statusMap.indexOf(value);
      this.setState({
        newOrderData: {
          ...this.state.newOrderData,
          [name]: statusIndex
        }
      }, () => { console.log(this.state.newOrderData) })
    } 
    else if(type == "date") {
      let dateValue = value ? moment(value).startOf('day').valueOf() : "";
      this.setState({
        newOrderData: {
          ...this.state.newOrderData,
          [name]: dateValue,
        }
      }, () => { console.log(this.state.newOrderData) })
    }
    else {
      this.setState({
        newOrderData: {
          ...this.state.newOrderData,
          [name]: value,
        }
      }, () => { console.log(this.state.newOrderData) })
    }
  }

  //============================================//

  //====================update order data =============//

  handleSubmit = () => {
    // console.log(this.state.newOrderData);
    let newOrderData = { ...this.state.newOrderData };
    let order = this.state.order;
    let shipmentLocationMap = this.props.orderTrackerMapping.shipmentLocation;
    let shipmentLocation = this.state.order.shipmentLocation || (this.state.order.state ? (shipmentLocationMap[this.state.order.state.toUpperCase()]) : this.state.newOrderData.shipmentLocation || (this.state.newOrderData.state ? (shipmentLocationMap[this.state.newOrderData.state.toUpperCase()] || "") : ""));
    if (!this.state.newOrderData.invoiceType && this.state.employeeDetails.access.includes('Zunpulse-Admin')) {
      alert("Please select the invoice type");
      return;
    } 

    // if ( this.state.order.invoiceType != newOrderData.invoiceType && newOrderData.invoiceType == "Cancelled" && this.state.employeeDetails.access.includes('Zunpulse-Admin')) {
    //   console.log('working');
    //   this.props.generateInvoice(this.state.order.orderId,newOrderData.status.toString(),'0',shipmentLocation);
    // }
    if (!newOrderData.customerOrderDate) {
      alert("Required missing - Date");
      return;
    }
    if (newOrderData.orderType == 'Replacement' && !(newOrderData.parentOrderId || order.parentOrderId)){
      alert('Required Field ParentOrderId is missing');
      return;
    }
    console.log(order)
    this.props.updateOrderStatusNew(order, order.orderId, undefined, newOrderData, this.state.employeeDetails.username);
    this.setState({
      edit: false,
      order: { ...this.state.newOrderData }
    })
  }

  //================comment=================//

  onCommentChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      newComment: value,
    })
  }

  commentSubmit = (e) => {
    let comments = [...this.state.comments];
    let orderId = this.state.order.orderId
    let newComment = this.state.newComment;
    newComment = newComment.trim();
    if (newComment.length > 0) {
      let newCommentData = {
        orderId: orderId,
        commentedBy: this.state.username,
        commentBody: newComment,
        epoch: new Date().getTime()
      };
      comments.push(newCommentData);
      console.log(comments);
      this.props.addZunpulseOrderCommentNew(newCommentData);
      this.setState({
        comments: comments,
        newComment: ''
      })
    }
  }

  //======================================//

  handleUtmDivToggle = () => {
    this.setState({
      showUtmVariables: !this.state.showUtmVariables
    })
  }

  // ============ Generate Invoice ============//

  generateInvoice = () => {
    console.log(this.props);
    if (!this.state.order.invoiceType && this.state.employeeDetails.access.includes('Zunpulse-Admin')) {
      alert("Please Select the invoice Type");
      return;
    }
    let shipmentLocationMap = this.props.orderTrackerMapping.shipmentLocation;
    let shipmentLocation = this.state.order.shipmentLocation || (this.state.order.state ? (shipmentLocationMap[this.state.order.state.toUpperCase()]) : this.state.newOrderData.shipmentLocation || (this.state.newOrderData.state ? (shipmentLocationMap[this.state.newOrderData.state.toUpperCase()] || "") : ""));
    if (parseInt(this.state.order.status) <= 3 && this.state.order.invoiceType != "Cancelled" && this.state.employeeDetails.access.includes('Zunpulse-Admin') ) {
      this.props.generateInvoice(this.state.order.orderId,this.state.order.status.toString(),1,shipmentLocation);
    }
  }

  // ============ Generate Invoice ============//

  downloadFile = () => {
    this.props.downloadInvoicePdf(this.state.order.orderId,this.state.order.invoiceType);
  }


  render() {
    let { order, payment, comments } = this.state;
    let { orderTrackerMapping = {} } = this.props;
    let { shipmentLocation: shipmentLocationMap = {} } = orderTrackerMapping;
    let invoiceType = ['Tax','Cancelled']
    console.log(payment)
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }
    console.log("this.state", this.state)
    // console.log('naman', this.props.zunpulseOrders)
    if (Object.keys(order).length > 0) {
      return (
        <div>
          <Header getSearchResult={this.props.getSearchResult} />
          <div style={{ "marginTop": 60 }} className="container-fluid">
            <div className="topbar-section" >
              <div className="row">
                <div className="col-md-9 col-xs-12 topbar-left">
                  <div className="col-md-6">
                    <strong>Order Description</strong>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12 topbar-right">
                  <div className="col-md-12 col-xs-12">
                    {

                  this.state.employeeDetails.access.includes('Zunpulse-Admin') ? (
                      (this.state.order.successStatus).toLowerCase() !== "success" ||  parseInt(this.state.order.status) >3 ? 
                      <>
                      <button className="btn btn-disabled" style={{marginLeft:"15px"}} disabled={true} >Generate Invoice</button>
                    <button  className="btn btn-disabled" style={{marginLeft:"15px"}} disabled={true}>Download Invoice <i className="fa fa-download"></i></button>
                      </>
                      :
                      <>
                      <button className="btn btn-primary" style={{marginLeft:"15px"}} onClick={(e) => this.generateInvoice(e)}> Generate Invoice</button>
                    <button onClick={() => this.downloadFile()} className="btn btn-success" style={{marginLeft:"15px"}}>Download Invoice <i className="fa fa-download"></i></button>
                      </>
                  ) :""
                    }
                    <span style={this.state.edit ? style.hide : style.show}><i onClick={this.handleEdit} style={{ cursor: "pointer", float: "right", marginTop: "8px" }} className="fas fa-pen"></i></span>
                    <button style={this.state.edit ? style.show : style.hide} className="button-edit" onClick={(e) => this.handleSubmit(e)}>Save</button>
                    <button style={this.state.edit ? style.show : style.hide} className="button-cancel" onClick={(e) => this.handleEdit(e)}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-section">
              <div className="row">
                <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                  <table>
                    <tr>
                      <td>Date</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{moment(+this.state.order.customerOrderDate).format('DD MMM, YYYY HH:mm:ss')}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("customerOrderDate", this.state.newOrderData.customerOrderDate, "date")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Order Id</td>
                      <td><span>{this.state.order.orderId}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Platform Order Id</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.platformOrderId}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("platformOrderId", this.state.newOrderData.platformOrderId, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                    <td>Type</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.orderType}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("orderType", this.state.newOrderData.orderType,(this.orderType || []) )}
                        </span>
                    </td>
                    </tr>
                    {
                      this.state.order && ["Replacement"].includes(this.state.newOrderData.orderType || this.state.order.orderType) ? 
                      <tr>
                        <td>Parent Order Id</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.parentOrderId}</span>
                          <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("parentOrderId", this.state.newOrderData.parentOrderId, "text")}
                          </span>
                      </td>
                      </tr> :
                      ""
                    }
                    <tr>
                      <td>Name</td>
                      <td><span>{this.state.order.customerName}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td><span>{this.state.order.email}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td><span>{this.state.order.phoneNumber}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Zipcode</td>
                      <td><span>{this.state.order.pincode}</span>
                      </td>
                    </tr>

                    <tr>
                      <td>Address</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.address}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("address", this.state.newOrderData.address || '', "text")}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Shipment Location</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.shipmentLocation || (this.state.order.state ? (shipmentLocationMap[this.state.order.state.toUpperCase()] || "") : "")}</span>
                        <span style={this.state.edit ? style.show : style.hide}>
                          {this.selectType("shipmentLocation", this.state.newOrderData.shipmentLocation || (this.state.newOrderData.state ? (shipmentLocationMap[this.state.newOrderData.state.toUpperCase()] || "") : ""), [ ...(new Set([ ...Object.values(shipmentLocationMap || {}) ])) ])}
                        </span>
                      </td>
                    </tr>
                    {this.state.employeeDetails.access.includes('Zunpulse-Admin') ?
                    (
                    <tr>
                      <td>Invoice Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.invoiceType}</span>
                        <span style={this.state.edit ? style.show : style.hide}>
                          {this.selectType("invoiceType", this.state.newOrderData.invoiceType  || "", (invoiceType || []))}
                        </span>
                      </td>
                    </tr>
                    ) : null
                  
                  }
                    

                  </table>
                </div>

                <div className="col-md-8 table-wrapper">
                  <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
                    <table className="customer-info-left">

                      <tr>
                        <td>State</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.state}</span>
                          <span style={this.state.edit ? style.show : style.hide}>
                            {this.selectType("state", this.state.newOrderData.state || "", (Object.keys(this.state.mapping.stateCityMapping) || []))}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>City</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.city}</span>
                          <span style={this.state.edit ? style.show : style.hide}>
                            {this.selectType("city", this.state.newOrderData.city || "", (this.state.city || []))}
                          </span>

                        </td>
                      </tr>
                      <tr>
                        <td>Next Action Date</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.nextActionDate ? moment(+this.state.order.nextActionDate).format("DD MMM, YYYY") : ''}</span>
                          <span style={this.state.edit ? style.show : style.hide} className="input-group">
                            {this.inputType("nextActionDate", this.state.newOrderData.nextActionDate, "date")}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Order Date</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.orderDate ? moment(+this.state.order.orderDate).format("DD MMM, YYYY") : ''}</span>
                          <span style={this.state.edit ? style.show : style.hide} className="input-group">
                            {this.inputType("orderDate", this.state.newOrderData.orderDate, "date")}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping Date</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.shippingDate ? moment(+this.state.order.shippingDate).format("DD MMM, YYYY") : ""}</span>
                          <span style={this.state.edit ? style.show : style.hide} className="input-group">
                            {this.inputType("shippingDate", this.state.newOrderData.shippingDate, "date")}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Delivery Date</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.deliveryDate ? moment(+this.state.order.deliveryDate).format("DD MMM, YYYY") : ""}</span>
                          <span style={this.state.edit ? style.show : style.hide} className="input-group">
                            {this.inputType("deliveryDate", this.state.newOrderData.deliveryDate, "date")}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Return Date</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.returnDate ? moment(+this.state.order.returnDate).format("DD MMM, YYYY") : ""}</span>
                          <span style={this.state.edit ? style.show : style.hide} className="input-group">
                            {this.inputType("returnDate", this.state.newOrderData.returnDate, "date")}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Delivery Status</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.statusMap[this.state.order.status]}</span>
                          <span style={this.state.edit ? style.show : style.hide}>
                            {this.selectType("status", this.statusMap[this.state.newOrderData.status], (this.statusMap))}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>High Value</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.highValue || ''}</span>
                          <span style={this.state.edit ? style.show : style.hide}>
                            {this.selectType("highValue", this.state.newOrderData.highValue, ["Yes","No"])}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Modified On</td>
                        <td><span>{this.state.order.lastUpdatedOn ? moment(this.state.order.lastUpdatedOn).format("DD MMM, YYYY") : ''}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6 customer-info">
                    <table className="customer-info-right">
                      <tr>
                        <td>Referral Code</td>
                        <td><span>{this.state.order.firstReferralCode }</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Coupon Code</td>
                        <td><span>{this.state.order.couponName }</span>
                        </td>
                      </tr>
                      <tr>
                        <td>MRP</td>
                        <td><span>{this.state.order.totalPrice || ""}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Amount Paid</td>
                        <td><span>{this.state.order.paidPrice || ""}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Payment Success</td>
                        <td><span>{this.state.order.successStatus || ''}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Payment Mode</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.paymentMode}</span>
                          <span style={this.state.edit ? style.show : style.hide}>
                            {this.selectType("paymentMode", this.state.newOrderData.paymentMode, (this.paymentModeMap))}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Source</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.source}</span>
                          <span style={this.state.edit ? style.show : style.hide}>
                            {this.selectType("source", this.state.newOrderData.source, (this.sourceMap))}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Tracking Id </td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.trackingId}</span>
                          <span style={this.state.edit ? style.show : style.hide} className="input-group">
                            {this.inputType("trackingId", this.state.newOrderData.trackingId, "text")}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Responsible</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.responsible || ''}</span>
                          <span style={this.state.edit ? style.show : style.hide}>
                            {this.selectType("responsible", this.state.newOrderData.responsible, this.state.employees || [])}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Stage</td>
                        <td><span style={this.state.edit ? style.hide : style.show}>{this.state.order.stage || ''}</span>
                          <span style={this.state.edit ? style.show : style.hide}>
                            {this.selectType("stage", this.state.newOrderData.stage, this.stageMap || [])}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Modified By</td>
                        <td><span>{this.state.order.lastUpdatedBy || ''}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>


            <div className="row" style={{ padding: "0px" }}>
              <div className="topbar-section col-9">
                <div className="container">
                  <div>
                    <h4><b>Comments</b></h4>
                  </div>
                  <hr></hr>
                  <div>
                    <div>
                      {(comments || []).map(comment => {
                        return <div><b>{comment.commentedBy.split('@')[0].split('.')[0] + ' ' + comment.commentedBy.split('@')[0].split('.')[1]} - {moment(+(comment.epoch)).format('DD/MM/YYYY hh:mm a')}</b> - {comment.commentBody}</div>
                      })}
                    </div>
                    <div className="row" style={{ width: "80%", margin: "0px", padding: "0px" }}>
                      <div className="col-md-10 form-group"  >
                        <label for="newComment"></label>
                        <textarea placeholder="Add New Comment" className="form-control" rows="2" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
                      </div>
                      <div style={{ marginTop: "46px", marginLeft: '0px' }} className="col-md-1 form-group">
                        <button className="btn btn-success" onClick={(e) => this.commentSubmit(e)}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card shadow">
              <div className="card-body">
                <div className="card-title">
                  <h4><b>Other Orders</b></h4>
                  <hr></hr>
                </div>
                <ReactTable
                  filterable={true}
                  showPagination={true}
                  data={Object.values(this.state.otherOrders)}
                  columns={this.state.tableColumns}
                  defaultPageSize={5}
                />
              </div>
            </div>

            <div className="scrap-variables">
              <h6 style={{ paddingLeft: "10px" }}><b>UTM Variables | {this.state.showUtmVariables ? <i className="fa fa-eye-slash" style={{ cursor: "pointer" }} onClick={this.handleUtmDivToggle}></i> : <i className="fa fa-eye" onClick={this.handleUtmDivToggle} style={{ cursor: "pointer" }}></i>}</b></h6>
              <hr style={this.state.showUtmVariables ? style.show : style.hide}></hr>

              <div className="row utmVariables" style={this.state.showUtmVariables ? style.show : style.hide}>
                <div className="col-md-1">
                  <p> Source </p>
                </div>
                <div className="col-md-2">
                  {payment.utm ? payment.utm.source : ""}
                </div>
                <div className="col-md-1">
                  <p>Campaign </p>
                </div>
                <div className="col-md-2">
                  {payment.utm ? payment.utm.campaign : ""}
                </div>
                {/* <div className="col-md-1">
                  <p> Content </p>
                </div>
                <div className="col-md-2">
                  {payment.utm.UTM_CONTENT}
                </div> */}
                <div className="col-md-1">
                  <p> Medium </p>
                </div>
                <div className="col-md-2">
                  {payment.utm ? payment.utm.medium : ""}
                </div>
                {/* <div className="col-md-1">
                  <p> Term</p>
                </div>
                <div className="col-md-2">
                  {payment.utm.UTM_TERM}
                </div> */}
                <div className="col-md-1">
                  <p>Adposition</p>
                </div>
                <div className="col-md-2">
                  {payment.utm ? payment.utm.adposition : ""}
                </div>
                <div className="col-md-1">
                  <p>Placement</p>
                </div>
                <div className="col-md-2">
                  <p>{payment.utm ? payment.utm.placement : ""}</p>
                </div>
                <div className="col-md-1">
                  <p>Loc physical ms</p>
                </div>
                <div className="col-md-2">
                  {payment.utm ? payment.utm.loc_physical_ms : ""}
                </div>
                <div className="col-md-1">
                  <p>Loc interest ms</p>
                </div>
                <div className="col-md-2">
                  <p>{payment.utm ? payment.utm.loc_interest_ms : ""}</p>
                </div>
                <div className="col-md-1">
                  <p>Device</p>
                </div>
                <div className="col-md-2">
                  <p>{payment.utm ? payment.utm.device : ""}</p>
                </div>
                <div className="col-md-1">
                  <p>Keyword</p>
                </div>
                <div className="col-md-2">
                  <p>{payment.utm ? payment.utm.keyword : ""}</p>
                </div>
              </div>
            </div>

            <div className="card shadow">
              <div className="card-body">
                <div className="card-title">
                  <h4><b>Products</b></h4>
                  <hr></hr>
                </div>
                <ReactTable
                  filterable={true}
                  showPagination={false}
                  data={this.state.products}
                  columns={this.productsColumns}
                  ref={this.reactTable}
                  // defaultPageSize={5}
                  pageSize={this.state.products.length}
                />
              </div>
            </div>

            <div className="card shadow">
              <div className="card-body">
                <div className="card-title">
                  <h4><b>History</b></h4>
                  <hr></hr>
                </div>
                <ReactTable
                  filterable={true}
                  showPagination={true}
                  data={this.state.orderHistory || []}
                  columns={History}
                  ref={this.reactTable}
                  defaultPageSize={5}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div><Header getSearchResult={this.props.getSearchResult} /></div>
      )
    }
  }
}

function mapStateToProps(state) {
  // console.log("state", state)
  let { zunpulseOrders, zunPulseOpsDashboard = {} } = state;
  return {
    zunpulseOrders: zunpulseOrders.orders,
    mapping: zunpulseOrders.mapping,
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    orderTrackerMapping: zunPulseOpsDashboard.mapping || {}
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchOrderNew, updateOrderStatusNew, fetchOrdersMapping, addZunpulseOrderCommentNew, fetchAllMapping, fetchZunpulseOpsMapping, generateInvoice, downloadInvoicePdf }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseSingleOrderViewNew);
