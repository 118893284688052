import React from 'react';
import { fetchAllLeads } from '../actions/fetchAllLeads';
import { fetchAllMapping } from '../actions/fetchAllMapping';
import { getSearchResult } from '../actions/searchAction';
import { getUserInfo } from '../actions/user';
import { editBulkLeads } from '../actions/editMultipleLeadsAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../components/HeaderComponent';
import { connect } from 'react-redux';
import TableView from '../components/allLeadsView';
import PropTypes from 'prop-types';
import { ErrorComponent } from '../components/ErrorComponent';
import { Loader } from '../components/loader'

class fetchAllLead extends Component {
  componentWillMount() {
    let viewList = ["stages", "employees", "saslots", "regions", "source", "stateregion", "languages", "reasonForVerified"]
    this.props.fetchAllLeads(100, 1, []);
    this.props.fetchAllMapping(viewList);
    // this.props.getSearchResult()
  }

  render() {
    if (this.props.error) {
      return (
        <div>
          <ErrorComponent error={this.props.error} />
        </div>
      )
    }

    return (
      <div>
        <Header />
        <TableView allLeads={this.props.allLeads} allMapping={this.props.allMapping} fetchAllLeads={this.props.fetchAllLeads} searchData={this.props.searchData} editBulkLeads={this.props.editBulkLeads} />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    allLeads: state.allFetchLead ? state.allFetchLead : {},
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    searchData: [],
    error: state.allFetchLead.error ? state.allFetchLead.error : undefined,

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAllLeads, fetchAllMapping, getSearchResult, getUserInfo, editBulkLeads }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(fetchAllLead);