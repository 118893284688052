import React from 'react';
import axios from 'axios';
import url from '../config';
import moment from 'moment';
import { FETCH_ALL_LEADS_START, FETCH_ALL_LEADS_SUCCESS, FETCH_ALL_LEADS_ERROR, FETCH_COMMENTS_START, FETCH_COMMENTS_SUCCESS } from './actiontypes';
import swal from 'sweetalert';

export function fetchAllLeads(limit, page, currentData = [], filterObj, view = 'solar', distributorOnly = false, distributorId) {
  return (dispatch) => {
    console.log("filterObj", filterObj);
    let newFilterObj = { ...filterObj };
    if (Object.keys(newFilterObj).includes("STATE")) {
      delete newFilterObj["STATE"]
    }
    let dateFormats = {
      SITE_ASSESSMENT_DATE: 'YYYYMMDD',
      ADDED_ON: 'YYYYMMDD',
      NEXT_ACTION_DATE: 'YYYYMMDD',
      nextActionDate: 'YYYYMMDD',
      modifiedOn: 'YYYYMMDD',
      partner_onboarding_date: 'YYYYMMDD',
      lastReachOut: 'YYYYMMDD'
    }
    dispatch({ type: FETCH_ALL_LEADS_START, payload: {} });

    let route = url.pathBackend;

    let conditions = undefined;
    switch (view) {
      case 'solar':
        route += `lead/all`;
        conditions = newFilterObj && Object.keys(newFilterObj).length > 0 ? newFilterObj : { ACTIVE: [1] };
        break;
      case 'zunpulse':
        route += `zunpulse/lead/all`;
        conditions = filterObj && Object.keys(filterObj).length > 0 ? filterObj : undefined;
        break;
      case 'zunsolar':
        route += `zunsolar/lead/all`;
        conditions = filterObj && Object.keys(filterObj).length > 0 ? filterObj : undefined;
        break;
      case 'whatsApp':
        route = `https://zuntalks.in:3000/whatsapp/dashboard/all`
        conditions = filterObj && Object.keys(filterObj).length > 0 ? filterObj : {};
        break;
      default:
        route += `lead/all`;
        conditions = newFilterObj && Object.keys(newFilterObj).length > 0 ? newFilterObj : { ACTIVE: [1] };
    }
    if (conditions) {
      Object.keys(dateFormats).map((key) => {
        // console.log("conditions[key]", conditions[key])
        if ((conditions[key] || {}).startDate) {
          conditions[key].startDate = moment(conditions[key].startDate).format(dateFormats[key])
        }
        if ((conditions[key] || {}).endDate)
          conditions[key].endDate = moment(conditions[key].endDate).format(dateFormats[key])
      })
    }
    axios.post(route, { key : "4VhfN1xiAD", limit, page, conditions, distributorOnly,distributorId }).then((response) => {
      if (response.data.error) {
        dispatch({ type: FETCH_ALL_LEADS_ERROR, payload: response.data.error });
      }
      if (response.data.data) {
        if ((response.data.data).length <= 0) {
          swal("OOPS!! No Data found.");
        }
        dispatch({ type: FETCH_ALL_LEADS_SUCCESS, payload: { data: [...currentData, ...response.data.data], count: response.data.count[0] ? response.data.count[0]['COUNT(*)'] : (response.data.count || undefined) } });
      }
    })
  }
}

export function getComments(id) {
  return async (dispatch) => {
    dispatch({ type: FETCH_COMMENTS_START, payload: [] });

    let response = await axios.post(url.pathBackend + "zunpulse/lead/comments/" + id);
    console.log("reponse from comment api", response.data);
    if (response.data.error) {
      swal(response.data.error)
    }
    if (response.data) {
      dispatch({ type: FETCH_COMMENTS_SUCCESS, payload: response.data })
    }
  }
}

export function downloadFile() {
  return (dispatch) => {
    axios.get(url.pathBackend + 'zunpulse/csv/download').then((response) => {
      const blob = new Blob([response.data], { type: 'application/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'pulseData.csv');
      document.body.appendChild(link);
      link.click();
    })
  }
}
