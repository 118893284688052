import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import Select from 'react-select';
import { zunpulseNewOrderColumns as zunpulseOrderColumns, zunpulseNewOrdersCsvColumns as zunpulseOrdersCsvColumns } from '../../config/column_fields';
import { fetchMapping } from '../../actions/zunpulseOpsDashboardActions';
import Header from '../../components/HeaderComponent';
import { fetchAllOrdersNew, updateOrderStatusNew, editBulkLeadsNew } from '../../actions/zunpulseOrderTrackingActions.js';
import { fetchAllMapping } from '../../actions/fetchAllMapping';
import moment from 'moment';
import axios from 'axios';
import url from '../../config';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

class ZunpulseNewOrders extends Component {

    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            ordersData: [],
            ordersDataCopy: [],
            tableColumns: [],
            editStatus: {},
            editedOrderStatus: {},
            mapping: {},
            stateChosen: [],
            cityChosen: [],
            paymentStatusChosen: [],
            paymentStatusArr: [],
            deliveryStatusChosen: [],
            sourceChosen: [],
            responsibleChosen: [],
            modifiedByChosen: [],
            editDate: {},
            selectedOrderIds: {},
            bulkAssignObj: {},
            employeeDetails: {},
            responsibleOptions: [],
            modifiedByOptions: [],
            highValueChosen: [],
            stageChosen: [],
            filters: {},
            differenceDays:0,
            page: 0,
            limit: 500,
            limitoption: [
                { value: 500, label: '500' },
                { value: 1000, label: '1000' },
                { value: 5000, label: '5000' }
            ],
            orderTrackerMapping: {},
            searchText: '',
            uploadCsvModal: false,
            uploadFile: undefined,
        };
        this.statusMap = ["Not Procured", "Procured", "Shipped", "Delivered", "Cancelled", "Returning (Before Delivery)", "Returning (After Delivery)", "Returned", "Replacement", "Truncated"];
    }

    componentWillMount() {
        let viewList = ["employees"]
        this.props.fetchAllMapping(viewList);
        this.props.fetchAllOrdersNew();
        this.props.fetchMapping();
        let tableColumns = zunpulseOrderColumns.map(val => {
            if (val.accessor == 'status') {
                val.Cell = row => {
                    return (<div>{this.statusMap[row.value]}</div>)
                }
            }
            if (val.accessor == 'orderDate') {
                val.Cell = row => {
                    return ((row.original.status == "0" || row.original.status == "4" || row.value == 0 ? "N/A" : moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : ""))
                }
            }
            if (val.accessor == 'shippingDate') {
                val.Cell = row => {
                    return ((row.original.status == "0" || row.original.status == "4" || row.original.status == "1" || row.value == 0) ? "N/A" : moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "")
                }
            }
            if (val.accessor == "deliveryDate") {
                val.Cell = row => {
                    return ((row.original.status == "0" || row.original.status == "4" || row.original.status == "1" || row.original.status == "2" || row.value == 0) ? "N/A" : moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "")
                }
            }
            if (val.accessor === "checkbox") {
                val.Header = row => {
                    return (
                        <div>
                            <input type="checkbox" onChange={(e) => { this.handleAllCheckbox(e, row) }} checked={this.state.selectAll ? 'checked' : ''} ></input>
                        </div>
                    )
                };
                val.Cell = row => (
                    <div>
                        <input type="checkbox" onChange={(e) => { this.handleCheckbox(e, row.original.orderId, row.original, row.index) }} checked={this.state.selectedOrderIds[row.original.orderId] ? 'checked' : ''} ></input>
                    </div>
                );
                val.Filter = () => {
                    let selectedCheckboxCount = Object.keys(this.state.selectedOrderIds || {}).filter((val) => this.state.selectedOrderIds[val] == 1);
                    return (
                        <div>{selectedCheckboxCount.length}</div>
                    )
                }
            };
            return val;
        });
        this.setState({
            tableColumns
        })
    }

    componentWillReceiveProps(props) {
        let { zunpulseOrders = {}, mapping = {}, allMapping = {}, orderTrackerMapping = {}, ordersDataReceived = false } = props;
        let { shipmentLocation: shipmentLocationMap = {} } = orderTrackerMapping;
        let tableData = Object.values(zunpulseOrders || {});

        tableData.forEach((order) => {
            order.viewOrderDate = order.orderDate ? moment(order.orderDate).format('YYYY-MM-DD') : "NA";
            order.viewShippingDate = order.shippingDate ? moment(order.shippingDate).format('YYYY-MM-DD') : "NA";
            order.viewDeliveryDate = order.deliveryDate ? moment(order.deliveryDate).format('YYYY-MM-DD') : "NA";
            order.viewStatus = this.statusMap[order.status];
            order.viewCustomerOrderDate = order.customerOrderDate ? moment(order.customerOrderDate).format('YYYY-MM-DD') : "NA";
            order.viewModifiedDate = order.lastUpdatedOn ? moment(order.lastUpdatedOn).format('YYYY-MM-DD HH:mm:ss') : "NA";
            order.shipmentLocationView = order.shipmentLocation || (order.state ? (shipmentLocationMap[order.state.toUpperCase()] || "") : "");
        });
        console.log(tableData);
        if (tableData.length > 0){
            let endDate = moment(tableData[0].viewCustomerOrderDate);
            let startDate = moment(tableData[tableData.length -1].viewCustomerOrderDate);
            let differenceDays = endDate.diff(startDate,'days');
            this.setState({differenceDays:differenceDays});
            console.log(differenceDays);
        }
        console.log(tableData);
        let employeeDetails = JSON.parse(localStorage.getItem("userObj"))
        let responsibleOptions = allMapping.employees ? allMapping.employees.map((val) => ({value: val.e_name , label: val.e_name})) : [];
        let modifiedByOptions = allMapping.employees ? allMapping.employees.map((val) => ({value: val.e_email.replace(/(^"|"$)/g, '') , label: val.e_name})) : [];
        this.setState({
            ordersData: tableData,
            mapping: mapping,
            ordersDataCopy: tableData,
            employeeDetails: employeeDetails,
            responsibleOptions: responsibleOptions,
            modifiedByOptions: modifiedByOptions,
            orderTrackerMapping,
            ordersDataReceived
        })
    }

    fetchLeads = (e) => {
        let { page, limit, ordersData, filters, searchText = '' } = this.state;
        this.props.fetchAllOrdersNew(page + 1,limit,ordersData,filters,searchText);
        this.setState({
          page: page + 1,
          ordersDataReceived: false
        })
      }

    selectInputDiv = (row, options) => {
        return (
            <div>
                <select
                    onKeyUp={(e) => { this.handleOnKeyPress(e, row) }}
                    onChange={(e) => { this.handleOnSelectChange(e, row) }}
                    className="form-control" name={`${row.original.key}`}
                    defaultValue={row.value ? row.value : ""}>
                    {Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)}
                </select>
            </div>
        )
    }

    handleOnSelectChange = (e, row) => {
        let { id } = row.original;
        let { value } = e.target;
        console.log("handleOnSelectChange", id, value)
        this.setState({
            editedOrderStatus: {
                ...this.state.editedOrderStatus,
                [id]: value
            }
        }, () => {
            console.log("state editedOrder", this.state.editedOrderStatus)
        })
        // console.log(e.target.value, row.original.id);
    }

    handleOnTableCellClick = (row) => {
        let { id, status } = row.original;
        this.setState({
            editStatus: {
                ...this.state.editStatus,
                [id]: true
            },
            editedOrderStatus: {
                ...this.state.editedOrderStatus,
                [id]: status
            }
        }, () => { console.log("editStatus", this.state.editStatus, this.state.editedOrderStatus) })
    }

    inputType = (name, value, type, placeholder) => {
        return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    selectType = (options, name, placeholder, value) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { this.handleMultiSelectChange(e, name) }}
                    name={name}
                    options={options}
                    value={value}
                    placeholder={placeholder}
                />
            </div>
        )
    }

    handleMultiSelectChange = (selectedOption, name) => {
        if (name == "stateChosen") {
            let cityList = [];
            let { mapping = {} } = this.props;
            (selectedOption || []).map(obj => {
                cityList = [...cityList, ...(mapping[obj.value] || [])];
            })
            this.setState({
                [name]: selectedOption,
                city: cityList.map(city => ({ label: city, value: city }))
            })
            return;
        }
        this.setState({
            [name]: selectedOption
        });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        value = this.checkSelectString(value);
        value = this.removingNullKeys(value);
        this.setState({
            [name]: value
        })
    }

    onApplyFilter = () => {
        let {limit, filters, searchText = ''} = this.state
        this.removingNullKeys(filters);
        this.props.fetchAllOrdersNew(1,limit,[],filters,searchText);
        this.setState({
            page: 1,
            ordersDataReceived: false
        })
    }

    checkSelectString = (str) => {
        if (str == "not selected") {
            return str = ""
        }
        else {
            return str
        }
    }

    removingNullKeys = (obj) => {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || Object.keys(obj[propName]).length == 0) {
                delete obj[propName];
            }
        }
        return obj
    }

      //============csv upload====================//
    openUploadCsvModal = () => {
        this.setState({
        uploadCsvModal: true,

        })
    }

    closeUploadCsvModal = () => {
        this.setState({
          uploadCsvModal: false,
          successMessage: undefined,
          errorMessage: undefined
        })
      }
    
    uploadFile = (e) => {
        let files = e.target.files
        this.setState({
            uploadFile: files[0]
        })
    }

    submitFileUpload = async () => {
        if (this.state.uploadFile) {
        //   if (this.state.uploadFile.type == "text/csv") {
            let csv = this.state.uploadFile;
            let formData = new FormData();
            formData.append("file", csv);
            this.setState({
              errorMessage: undefined,
              successMessage: undefined,
              uploadMessage: 'Uploading...'
            })
            let response = await axios.post(url.pathBackend + 'zunpulse/newOrders/upload', formData);
            // console.log("1", response)
            console.log("1",response);
            if (response.data.error) {
              this.setState({
                errorMessage: response.data.error,
                successMessage: undefined,
                uploadMessage: undefined,
              })
            }
            if (!response.data.error && response.data.message == "Orders Added Successfully") {
              this.setState({
                successMessage: response.data.message,
                errorMessage: undefined,
                uploadMessage: undefined,
              })
              setTimeout(()=>{
                this.closeUploadCsvModal();
              },800);
            } else if (!response.data.error && response.data.message.message !== "Orders Added Successfully") {

                this.setState({
                    successMessage: undefined,
                    errorMessage: response.data.message.message + " : - " + response.data.message.order,
                    uploadMessage: undefined,
                })
            }
        //   } 
        //   else {
        //     this.setState({
        //       errorMessage: "Unsupported File Format!!"
        //     })
        //   }
        }
      };
    //==========Bulk edit functions========//

    handleAllCheckbox = (e, row) => {
        let page = this.reactTable.current.state.page;
        let pageSize = this.reactTable.current.state.pageSize;
        let start = page * pageSize;
        let end = (page * pageSize + pageSize);
        let actualEnd = (row.data.length > end) ? end : row.data.length;
        let data = row.data;
        let selectedOrderIds = {};
        if (!this.state.selectAll) {
            for (let i = start; i <= (actualEnd - 1); i++) {
                selectedOrderIds[data[i].orderId] = 1
            }
        }
        this.setState({
            selectedOrderIds: { ...selectedOrderIds },
            selectAll: !(this.state.selectAll)
        })
    };

    handleCheckbox = (e, key, row, index) => {
        if (e.nativeEvent.shiftKey == true) {
            let selectedOrderIds = { ...this.state.selectedOrderIds }
            let sortedArray = this.reactTable.current.state.sortedData;
            let lastSelectedIndex = -1;
            console.log(sortedArray);
            sortedArray.map((lead, index) => {
                if (selectedOrderIds[lead.orderId]) {
                    lastSelectedIndex = index;
                }
            })
            if (lastSelectedIndex == -1) {
                selectedOrderIds[key] = 1
            } else {
                let currentIndex = sortedArray.findIndex(row => row.orderId == key);
                let [start, end] = currentIndex > lastSelectedIndex ? [lastSelectedIndex, currentIndex] : [currentIndex, lastSelectedIndex];
                for (let i = start; i <= end; i++) {
                    selectedOrderIds[sortedArray[i].orderId] = 1;
                }
            }
            this.setState({
                selectedOrderIds: selectedOrderIds
            })
        } else {
            let status = this.state.selectedOrderIds[key];
            let selectedIdCopy = JSON.parse(JSON.stringify(this.state.selectedOrderIds))
            let selectedOrderIds = { ...selectedIdCopy, [key]: status ? 0 : 1, };
            this.setState({
                selectedOrderIds: selectedOrderIds,
                selectAll: this.state.selectAll && status ? false : this.state.selectAll
                // selectedOrderIds: [...selectedOrderIds]
            }, () => {
                console.log(selectedOrderIds)
            })
        }
    }

    selectNewType = (name, options, value) => {
        return (
            <div style={{ width: '100%' }} >
                <select style={{ height: '35px' }}
                    onChange={(e) => { this.handleSelectChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option>not selected</option>
                    {(options ? options : []).map((e) => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    newInputType = (name, value, type, placeholder) => {
        return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleSelectChange(e)} />
    }

    handleSelectChange = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
        value = this.checkSelectString(value);
        if (name == "status") {
            value = this.statusMap.indexOf(value)
        }
        this.setState({
            bulkAssignObj: {
                ...this.state.bulkAssignObj,
                [name]: value
            }
        }, () => {
            console.log("bulk", this.state.bulkAssignObj)
        })
    }

    handleBulkAssign = () => {
        let { ordersDataCopy, selectedOrderIds, bulkAssignObj } = this.state;
        let bulkAssignObjCopy = JSON.parse(JSON.stringify(bulkAssignObj));
        // console.log("selectedorderids", selectedOrderIds, "bulkAssignObj", bulkAssignObjCopy, "ordersDataCopy", ordersDataCopy);
        this.props.editBulkLeadsNew(ordersDataCopy, selectedOrderIds, bulkAssignObjCopy, this.state.employeeDetails.username);
    }


    filtersInputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }
    
    multiSelectType = (name, options, placeholder, onChange) => {
      return (
          <div className="form-group" style={{ width: '100%' }}>
              <Select
                  isMulti
                  isSearchable
                  onChange={(e) => { onChange(e, name) }}
                  placeholder={placeholder}
                  name={name}
                  options={options}
              />
          </div>
      )
    }
    
    handleInputDateChangeFilters = (e, type) => {
      let { filters } = this.state;
      let { name, value } = e.target;
      filters = JSON.parse(JSON.stringify(filters));
      filters[type] = filters[type] || {};
      if(name == "startDate"){
        filters[type][name] = +(moment(value).startOf('day').format('x'));
      } else if(name == "endDate"){
        filters[type][name] = +(moment(value).endOf('day').format('x'));
      } else {
        filters[type][name] = +(moment(value).format('x'));
      }
      if (isNaN(filters[type][name])){
          delete filters[type][name]
      }
      this.setState({
          filters
      })
    }
    
    handleSelectChangeFilters = (e, name) => {
      let value = (e || []).map(option => option.value);
      if (name == 'state') {
        let { orderTrackerMapping = {} } = this.state;
        let cityList = [];
        let mapping = orderTrackerMapping.stateCityMapping || {};
        (value || []).map(obj => {
            cityList = [...cityList, ...(mapping[obj] || [])];
        })
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value,
            },
            city: cityList.map(city => ({ label: city, value: city }))
        })
      }
      else {
          this.setState({
              filters: {
                  ...this.state.filters,
                  [name]: value
              }
          })
      }
    }

    selectFetchLeads = (lim, e) => {
        this.setState({
          page: 1,
          limit: lim,
          ordersData: [],
          ordersDataReceived: false
        },() => {
            let { limit, page, filters, searchText = '' } = this.state;
            this.props.fetchAllOrdersNew(page,limit,[],filters, searchText);
        })
      }

    handleSearchInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    //==========================//

    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        };
        const modalStyles = {
            modal: {
              width: '40%',
              maxWidth: '40%',
              padding: '40px',
              background: '#f7f7f7',
              textAlign: 'center'
            }
        };
        let { tableColumns, orderTrackerMapping = {}, ordersDataReceived = false } = this.state;
        const stateMapping = Object.keys(orderTrackerMapping.stateCityMapping || {}).map(val => ({ label: val, value: val }));
        const paymentStatusMapping = (orderTrackerMapping.paymentStatus || []).map(val => ({ label: val, value: val }));
        const sourceMapping = (orderTrackerMapping.source || []).map(val => ({ label: val, value: val }));
        const stageMapping = (orderTrackerMapping.stage || []).map(val => ({ label: val, value: val }));
        const statusMapping = (orderTrackerMapping.status || []).map((val, index) => ({ label: val, value: index }));
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <div style={{ marginTop: "80px", backgroundColor: "#f7f7f7" }} className="container-fluid">
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontWeight: "bold" }}>Order View</h2>
                    </div>
                    <div className="row leadtransferTopbarSection">
                        <div className="col-md-2">
                        <label>Added On Start</label>
                        {this.filtersInputType("startDate", "Start Date", 'date', this.handleInputDateChangeFilters, 'customerOrderDate')}
                        </div>
                        <div className="col-md-2">
                            <label>Added On End</label>
                            {this.filtersInputType("endDate", "End Date", 'date', this.handleInputDateChangeFilters, 'customerOrderDate')}
                        </div>
                        <div className="col-md-2">
                            <label>State</label>
                            {this.multiSelectType("state", stateMapping, "State", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>City</label>
                            {this.multiSelectType("city", this.state.city, "City", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>Payment Status</label>
                            {this.multiSelectType("successStatus", paymentStatusMapping, "Payment Status", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>Delivery Status</label>
                            {this.multiSelectType("status", statusMapping, "Delivery Status", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>Source</label>
                            {this.multiSelectType("source", sourceMapping, "Source", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>Responsible</label>
                            {this.multiSelectType("responsible", this.state.responsibleOptions, "Responsible", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>Modified By</label>
                            {this.multiSelectType("lastUpdatedBy", this.state.modifiedByOptions, "Modified By", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>Next Action Date</label>
                            {this.filtersInputType("startDate", "Start Date", 'date', this.handleInputDateChangeFilters, 'nextActionDate')}
                        </div>
                        <div className="col-md-2">
                            <label>Next Action Date</label>
                            {this.filtersInputType("endDate", "End Date", 'date', this.handleInputDateChangeFilters, 'nextActionDate')}
                        </div>
                        <div className="col-md-2">
                            <label>Delivery Date</label>
                            {this.filtersInputType("startDate", "Start Date", 'date', this.handleInputDateChangeFilters, 'deliveryDate')}
                        </div>
                        <div className="col-md-2">
                            <label>Delivery Date</label>
                            {this.filtersInputType("endDate", "End Date", 'date', this.handleInputDateChangeFilters, 'deliveryDate')}
                        </div>
                        <div className="col-md-2">
                            <label>Stage</label>
                            {this.multiSelectType("stage", stageMapping, "Stage", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>High Value</label>
                            {this.multiSelectType("highValue", ["Yes","No"].map((val) => ({ value: val, label: val })), "High Value", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-2">
                            <label>Search</label>
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <input className="form-control customInput" type="text" name="searchText" placeholder="Search" onChange={(e) => this.handleSearchInputChange(e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-success btn-sm leadTransferSubmitButton" onClick={(e) => { this.onApplyFilter() }}>Apply Filter</button>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div style={{ display: 'inline-block', width: '150px', margin: '0 10px'}}><Select placeholder="No. of Leads" options={this.state.limitoption} onChange={e => this.selectFetchLeads(e.value, e)} /></div>
                        <button className="btn btn-primary" style={{ margin: '0 10px'}} onClick={e => this.fetchLeads()}>More Leads</button>
                        <Link style={{ paddingRight: "30px", float: "right" }} to="/zunpulse/createOrder" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> New Order</button></Link>
                        <CSVLink style={{ paddingRight: "30px", float: "right" }} filename={"paymentData.csv"} data={this.state.ordersDataCopy ? this.state.ordersDataCopy : []} headers={[ ...zunpulseOrdersCsvColumns, ...((this.state.differenceDays<= 3 && this.state.employeeDetails.hasOwnProperty('access') ? this.state.employeeDetails.access.includes('Zunpulse-Admin') :false )?[{label:"Phone",key:"phoneNumber"}]:[]) ]} type="button" className="btn btn-light btn-lg">
                            Download CSV <i className="fa fa-download"></i>
                        </CSVLink>
                        <span className="csvSpan" onClick={this.openUploadCsvModal}>Working with CSV ? </span>
                    </div>

                    <Modal open={this.state.uploadCsvModal} onClose={this.closeUploadCsvModal} styles={modalStyles} center>
                        <div style={{ border: "1px solid #92b0b3", padding: "5px" }}>
                        <div className=" container-fluid uploadBox">
                        <input style={{ width: "50 %", height: '42px', fontWeight: "bold" }} type="file" onChange={(e) => { this.uploadFile(e) }} accept=".csv"></input>
                        <button style={{ height: '38px', fontWeight: "bold" }} className="btn btn-info btn-sm" onClick={this.submitFileUpload}>Upload <i className="fa fa-upload"></i></button>
                        <br></br>
                        <div style={{ margin: "20px" }} >
                            <span style={{ color: "red" }}>{this.state.errorMessage ? this.state.errorMessage : ""}</span>
                            <span style={{ color: "green" }}>{this.state.successMessage ? this.state.successMessage : ""}</span>
                            <span style={{ color: "green" }}>{this.state.uploadMessage ? this.state.uploadMessage : ""}</span>
                        </div>


                        </div>
                        </div>
                    </Modal>

                    <div className="row" style={{ marginTop: "25px" }} style={Object.values(this.state.selectedOrderIds).includes(1) ? style.show : style.hide}>
                        <div className="col-md-2">
                            <label>Order Status</label>
                            {this.selectNewType("status", this.statusMap, this.state.bulkAssignObj.status ? this.statusMap[this.state.bulkAssignObj.status] : "")}
                        </div>
                        <div className="col-md-2">
                            <label>Order Date</label>
                            {this.newInputType("orderDate", this.state.bulkAssignObj.orderDate, "date", "Order Date")}
                        </div>
                        <div className="col-md-2">
                            <label>Shipping Date</label>
                            {this.newInputType("shippingDate", this.state.bulkAssignObj.shippingDate, "date", "Shipping Date")}
                        </div>
                        <div className="col-md-2">
                            <label>Delivery Date</label>
                            {this.newInputType("deliveryDate", this.state.bulkAssignObj.deliveryDate, "date", "Delivery Date")}
                        </div>
                        <div className="col-md-2">
                            <label>Return Date</label>
                            {this.newInputType("returnDate", this.state.bulkAssignObj.returnDate, "date", "Return Date")}
                        </div>
                        <div className="col-md-1" style={{ marginTop: "32px" }}>
                            <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkAssign(e) }}>Submit</button>
                        </div>
                    </div>
                    <div class="saview-table-section">
                        <ReactTable
                            ref={this.reactTable}
                            filterable={true}
                            showPagination={true}
                            sortable={true}
                            data={(this.state.ordersDataCopy)}
                            columns={tableColumns}
                            style={{ height: "90vh", textAlign: 'center' }}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                            loading={!ordersDataReceived}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    // console.log("state", state)
    let { zunpulseOrders, zunPulseOpsDashboard = {} } = state;
    return {
        zunpulseOrders: zunpulseOrders.orders,
        ordersDataReceived: zunpulseOrders.ordersDataReceived || false,
        mapping: zunpulseOrders.mapping,
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
        orderTrackerMapping: zunPulseOpsDashboard.mapping || {}
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllOrdersNew, updateOrderStatusNew, editBulkLeadsNew, fetchAllMapping, fetchMapping }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseNewOrders);
