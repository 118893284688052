import React from 'react';
import axios from 'axios';
import url from '../config';
import { FETCH_FOLLOWUP_LEADS_SUCCESS, FETCH_FOLLOWUP_MAPPING } from './actiontypes';

export function fetchAllLeads() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}sales/followup/getFollowupLeads`);
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            let leadsData = response.data;
            dispatch({ type: FETCH_FOLLOWUP_LEADS_SUCCESS, payload: leadsData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMapping() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}sales/followup/mapping`);
            response = response.data || {};
            dispatch({ type: FETCH_FOLLOWUP_MAPPING, payload: response || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateLeadData(leadData, updatedLeadData) {
    return async (dispatch) => {
        try {
            let updateData = {};
            Object.keys(updatedLeadData).forEach(key => {
                if(updatedLeadData[key] != leadData[key]) {
                    updateData[key] = updatedLeadData[key];
                }
            })
            if(!Object.keys(updateData).length) {
                console.log("no data to update");
                return;
            } 
            console.log({
                leadId: leadData.BITRIX_ID,
                updateData: updateData,
                QUOTE_ID: updatedLeadData.QUOTE_ID
            })
            // return;
            let response = await axios.post(`${url.pathBackend}sales/followup/updateLead`, {
                leadId: leadData.BITRIX_ID,
                updateData: updateData,
                quoteId: updatedLeadData.QUOTE_ID
            });
            response = response.data || {};
            console.log(response);
            if(response.success) {
                alert("Updated");
                return;
            }
            else {
                alert("some error occured");
                return;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}
