import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { getUserList, changeRole, changeActive, changeAdmin, deleteUser, changeAccess,addUserManager } from '../actions/userRoleAction'
import { connect } from 'react-redux';
import { getSearchResult } from '../actions/searchAction';
import HeaderComponent from '../components/HeaderComponent';
import UserRoleComponent from '../components/UserRolesComponent';
import { ErrorComponent } from '../components/ErrorComponent'

class UserRole extends Component {
  componentWillMount() {
    this.props.getUserList()
  }
  render() {
    if (this.props.error) {
      return (
        <div>
          <HeaderComponent getSearchResult={this.props.getSearchResult} />
          <ErrorComponent error={this.props.error} />
        </div>
      )
    }
    return (
      <div>
        <HeaderComponent getSearchResult={this.props.getSearchResult} />
        <UserRoleComponent usersList={this.props.usersList} changeRole={this.props.changeRole} changeAdmin={this.props.changeAdmin} changeActive={this.props.changeActive} deleteUser={this.props.deleteUser} changeAccess={this.props.changeAccess} addUserManager = {this.props.addUserManager}/>
      </div>
    )
  }
}


function mapStateToProps(state) {
  // console.log("state.fetchusers", state.fetchUsers)
  return {
    usersList: state.fetchUsers ? state.fetchUsers : {},
    error: state.fetchUsers.error ? state.fetchUsers.error : undefined,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSearchResult, changeRole, changeActive, changeAdmin, deleteUser, changeAccess, getUserList,addUserManager }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRole);