import { render } from "@testing-library/react"
import { Component } from "react"
import React from "react";
import { bindActionCreators } from "redux";
import { fetchAllMapping, fetchMappingZunsolar } from "../../actions/fetchAllMapping";
import { connect } from "react-redux";
import '../styles/mtCreateLead.css'
import HeaderComponent from "../HeaderComponent";
import { createLead } from "../../actions/createLeadAction";
import { editMTLeadProfile } from "../../actions/fetchBitrixId";
import { Link } from "react-router-dom";

class MtCreateLeadSecond extends Component {

    componentWillMount() {
        let viewList = ["employees"];
        this.props.fetchAllMapping(viewList);
        this.props.fetchMappingZunsolar();
        let solarId = this.props.match.params.id
        
        
    }

    constructor(props) {
        super(props)

        this.state = {
            leadData: {},
            newLeadData: {},
            mappingData: {},
            options: ['Yes', 'No'],
            employeesArr: [],
            // files:null
        }
    }
    componentWillReceiveProps(props) {

        // let solarId = this.props.match.params.id

        // if(!solarId){
        //     window.open(window.location.origin + "/mt/home", "_self")
        //     return
        //  }
        let allEmployees = props.allMapping && props.allMapping.employees ? props.allMapping.employees : []
        let employeesName = []

        allEmployees.forEach((emp) => {
            employeesName.push(emp.e_name)
        })

        this.setState({
            leadData: {
                has_gst: "NO",
            },
            mappingData: (props.allMapping && props.allMapping.mapping) ? props.allMapping.mapping : {},
            stateCityMap: props.allMapping ? props.allMapping.stateCityMap : {},
            employeesArr: employeesName
        })

    }

    onNewLeadDataSubmitEditData = (e) => {
        let {newLeadData } = this.state;

        let solarId = this.props.match.params.id

        const mtLeadData = this.props.mtLeadData // old data
        let apiFormData = new FormData();
        for(let key in newLeadData){
            if(key =="profilePic"){
                 apiFormData.append(key, newLeadData[key]);
            }
        }
        Object.keys(newLeadData).forEach((key)=>{
            if(!mtLeadData.hasOwnProperty(key)){
                mtLeadData[key]=''
            }
        })
        
        this.props.editMTLeadProfile(newLeadData, mtLeadData, solarId);
        // window.open(window.location.origin + `/mt/createUpdateFinal/${solarId}`,"_self")
        // window.open(window.location.origin + "/mt/createUpdateFinal", "_self")
    }

    checkSelectString = (str) => {
        if (str == "not selected") {
            return str = ""
        }
        else {
            return str
        }
    }
    handleFileChange = (e) => {
        let files = e.target.files[0]

        let { name, value } = e.target;
        console.log('files', files, name);
       this.setState({
        newLeadData:{
        ...this.state.newLeadData,
        [name]: files
    }
       })
    }

    FileTypeInput = (name, type, required ) => {
        return (
            <>
            <input type={type} className="form-control"  name={name} accept="image/jpeg,image/png" onChange={(e) => this.handleFileChange(e)} required={required}></input>
            </>
        )
    }
    handleInputChange = (e) => {

        // this.setState({
        //     files:e.target && e.target.files ? e.target.files[0] :null
        // })

        // console.log("file88",e.target && e.target.files ? e.target.files[0] :null)
        let { name, value } = e.target;
        value = this.checkSelectString(value);

        this.setState({

            newLeadData: {
                ...this.state.newLeadData,
                [name]: value,
            }
        })
    }

    inputType = (name, value, type, placeholder, disabled = false) => {
        return <input type={type} name={name} value={value} disabled={disabled} onChange={(e) => this.handleInputChange(e)} placeholder={placeholder} />
    }

    selectType = (name, value, options, placeholder) => {

        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleInputChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option>{placeholder}</option>
                    {(options ? options : []).map(e => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    render() {
        return (
            <>
                <HeaderComponent />

                <div className="createDealerContainer">

                    <div className="dlr-sec-id">
                        <h3 className="dealerId-cnt">Dealer Id   <p>{this.props.match.params.id}</p> </h3>
                    </div>


                    <div className="mtFirstContainer">
                        <div className="form-ctn">
                            {this.inputType("companyName", this.state.leadData.companyname, "text", "Shop Name")}
                        </div>

                        <div className="form-select">
                            {this.selectType("has_gst", this.state.leadData.has_gst, ['Yes', 'No'], "Has GST")}
                        </div>

                        <div className="form-select">
                            {this.selectType("turnover", this.state.leadData.turnover, this.state.mappingData.turnover, "Annual Sales")}
                        </div>
                        <div className="form-ctn">
                        {this.FileTypeInput("profilePic", "file")}
                        </div>

                        {/* <div className="form-ctn">
                            <input type="file" onChange={(e) => this.handleInputChange(e)} />
                        </div> */}
                    </div>

                    <div className="save-next-container">
                      <button onClick={(e) => this.onNewLeadDataSubmitEditData(e)}>Save and Next</button>
                        {/* <button onClick={() => this.onNewLeadDataSubmitEditData()}>Save and Next</button> */}
                    </div>

                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
        createLeadSuccess: state.createLead ? state.createLead.success : {},
        mtLeadData:state.createLead ? state.createLead.mtLeadData:{}
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllMapping, fetchMappingZunsolar, editMTLeadProfile }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MtCreateLeadSecond);