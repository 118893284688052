import React from 'react';
import { fetchAllLeads, getComments } from '../../actions/fetchAllLeads';
import { getSearchResult } from '../../actions/searchAction';
import { fetchMappingZunpulse, fetchAllMapping } from '../../actions/fetchAllMapping';
import { editBulkLeads } from '../../actions/editMultipleLeadsAction'
import { getUserInfo } from '../../actions/user';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import TableView from '../../components/zunpulseComponents/ZunpulseAllLeadsViewComponent';
import PropTypes from 'prop-types';


class ZunpulseSearch extends Component {
  componentWillMount() {
    let viewList = ["employees"]
    this.props.fetchAllLeads(100, 1, [], undefined, 'zunpulse');
    this.props.fetchMappingZunpulse();
    this.props.fetchAllMapping(viewList)
    console.log("from search container", this.props.match.params.id);

    this.props.getSearchResult(this.props.match.params.id, "zunpulse")
  }

  render() {
    return (
      <div>
        <Header />
        <TableView allLeads={this.props.allLeads} allMapping={this.props.allMapping} fetchAllLeads={this.props.fetchAllLeads} searchData={this.props.searchData} editBulkLeads={this.props.editBulkLeads} getComments={this.props.getComments} comments={this.props.comments} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log("searchData", state)

  return {
    allLeads: [],
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    searchData: state.searchResults.tableData ? state.searchResults.tableData : [],
    comments: state.comments.data ? state.comments.data : [],
    error: state.allFetchLead.error ? state.allFetchLead.error : undefined,

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAllLeads, editBulkLeads, fetchAllMapping, fetchMappingZunpulse, getSearchResult, getUserInfo, getComments }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseSearch);
