import React from 'react';
import Select from 'react-select';
import ReactTable from "react-table";

export const GeneralTableView = ({ columns, data, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>{type}</div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}