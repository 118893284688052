import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
// import { comboColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchCombo, fetchMapping, updateComboData } from '../../actions/zunsolarOrderTrackerActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader'
import { number } from 'prop-types';

class ZunsolarCombo extends Component {


    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            allData: [],
            viewData: [],
            loading: true,
            received: false,
            mapping: {},
            comboEditFlag: {},
            editedComboData: {},
            subCategoryOptions: []
        };
    }
    componentWillMount() {
        this.props.fetchCombo();
        this.props.fetchMapping();
    }

    componentWillReceiveProps(props) {
        let { comboList, mapping ={} } = props;
        this.setState({
            loading: false,
            received: true,
            viewData: comboList,
            mapping
        })
    }

    handleEditComboClick = (row) => {
        let { id } = row;
        this.setState({
            comboEditFlag:{
                ...this.state.comboEditFlag,
                [id] : true
            },
            editedComboData: {
                ...this.state.editedComboData,
                [id]: row
            }
        })
    }

    handleSaveComboClick = (row) => {
        let { id } = row.original;
        let { editedComboData } = this.state;
        let { comboList } = this.props;
        let newData = editedComboData[id]||{};
        console.log("oldData", row.original)
        console.log("newData", newData)
        let { comboId, active, product1Category, product1SubCategory, product1Qty } = newData;
        if(!comboId || !(active==0||active==1) || !product1Category || !product1SubCategory || !product1Qty){
            alert("Please check all the fields");
            return;
        }
        let isValid = true;
        for(let i=1;i<=8;i++){
            if((newData[`product${i}Category`]||newData[`product${i}SubCategory`]||newData[`product${i}Qty`]) && !(newData[`product${i}Category`]&&newData[`product${i}SubCategory`]&&newData[`product${i}Qty`])){
                isValid = false;
            }
        }
        if(!isValid){
            alert("Please check all the fields");
            return;
        }
        this.props.updateComboData(id, row.original, newData, comboList);
        this.setState({
            comboEditFlag:{
                ...this.state.comboEditFlag,
                [id] : false
            }
        })
    }

    handleCancelComboClick = (row) => {
        let { id } = row.original;
        this.setState({
            comboEditFlag:{
                ...this.state.comboEditFlag,
                [id] : false
            }
        })
    } 

    comboSelect = (name, value, options, row) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleComboChange(e, row) }}
                    className="form-control" name={name} value={value||""}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    comboInput = (name, value, type, row) => {
        return <input type={type} className="form-control" name={name} value={value||""} onChange={(e) => this.handleComboChange(e, row)} />
    }

    handleComboChange = (e, row) => {
        let { name, value } = e.target;
        let { id } = row.original;
        let { editedComboData } = this.state;
        editedComboData = JSON.parse(JSON.stringify(editedComboData))
        editedComboData[id][name] = value;
        this.setState({
            editedComboData
        })
    }

    renderInputCell = (row) => {
        let { id, type } = row.column;
        let { comboEditFlag, editedComboData } = this.state;
        return(!comboEditFlag[row.original.id]?row.value:this.comboInput(id, editedComboData[row.original.id][id], type, row))
    }
    
    render() {
        let { handleSaveComboClick, handleCancelComboClick, handleEditComboClick, renderInputCell } = this;
        let { viewData, comboEditFlag, editedComboData, mapping = {}, subCategoryOptions = [] } = this.state;
        let categoryOptions = Object.keys(mapping.category||{});
        let category = mapping.category || {};
        const comboColumns = [
            {
                Header:"Edit",
                Cell: row => {
                    return(
                        comboEditFlag[row.original.id]?<div className="row"><button className="btn btn-success btn-sm col-md-6" onClick={(e) => handleSaveComboClick(row)}>Save</button><button className="btn btn-light btn-sm col-md-6" onClick={(e) => handleCancelComboClick(row)}>Cancel</button></div>:<button className="btn btn-info btn-sm" onClick={(e) => handleEditComboClick(row.original)}>Edit</button>
                    )
                }
            },
            {
                Header: "Combo Id",
                accessor: "comboId",
                type: "text",
                Cell: renderInputCell
            },
            {
                Header: "Active",
                accessor: "active",
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect("active", editedComboData[row.original.id]["active"], [ 1, 0 ], row)
                    )
                }
            },
            {
                Header: "Product 1 C",
                accessor: "product1Category",
                productIndex: 1,
                categoryColumn: true,
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], categoryOptions, row)
                    )
                }
            },
            {
                Header: "Product 1 SC",
                accessor: "product1SubCategory",
                categoryKey: "product1Category",
                productIndex: 1,
                Cell: row => {
                    let selectedCategory = editedComboData[row.original.id]?editedComboData[row.original.id][row.column.categoryKey]:"";
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], selectedCategory && category[selectedCategory] ? category[selectedCategory]:[], row)
                    )
                }
            },
            {
                Header: "Product 1 Qty",
                accessor: "product1Qty",
                type: 'number',
                Cell: renderInputCell
            },
            {
                Header: "Product 2 C",
                accessor: "product2Category",
                productIndex: 2,
                categoryColumn: true,
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], categoryOptions, row)
                    )
                }
            },
            {
                Header: "Product 2 SC",
                accessor: "product2SubCategory",
                categoryKey: "product2Category",
                productIndex: 2,
                Cell: row => {
                    let selectedCategory = editedComboData[row.original.id]?editedComboData[row.original.id][row.column.categoryKey]:"";
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], selectedCategory && category[selectedCategory] ? category[selectedCategory]:[], row)
                    )
                }
            },
            {
                Header: "Product 2 Qty",
                accessor: "product2Qty",
                type: 'number',
                Cell: renderInputCell
            },
            {
                Header: "Product 3 C",
                accessor: "product3Category",
                productIndex: 3,
                categoryColumn: true,
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], categoryOptions, row)
                    )
                }
            },
            {
                Header: "Product 3 SC",
                accessor: "product3SubCategory",
                categoryKey: "product3Category",
                productIndex: 3,
                Cell: row => {
                    let selectedCategory = editedComboData[row.original.id]?editedComboData[row.original.id][row.column.categoryKey]:"";
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], selectedCategory && category[selectedCategory] ? category[selectedCategory]:[], row)
                    )
                }
            },
            {
                Header: "Product 3 Qty",
                accessor: "product3Qty",
                type: 'number',
                Cell: renderInputCell
            },
            {
                Header: "Product 4 C",
                accessor: "product4Category",
                productIndex: 4,
                categoryColumn: true,
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], categoryOptions, row)
                    )
                }
            },
            {
                Header: "Product 4 SC",
                accessor: "product4SubCategory",
                categoryKey: "product4Category",
                productIndex: 4,
                Cell: row => {
                    let selectedCategory = editedComboData[row.original.id]?editedComboData[row.original.id][row.column.categoryKey]:"";
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], selectedCategory && category[selectedCategory] ? category[selectedCategory]:[], row)
                    )
                }
            },
            {
                Header: "Product 4 Qty",
                accessor: "product4Qty",
                type: 'number',
                Cell: renderInputCell
            },
            {
                Header: "Product 5 C",
                accessor: "product5Category",
                productIndex: 5,
                categoryColumn: true,
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], categoryOptions, row)
                    )
                }
            },
            {
                Header: "Product 5 SC",
                accessor: "product5SubCategory",
                categoryKey: "product5Category",
                productIndex: 5,
                Cell: row => {
                    let selectedCategory = editedComboData[row.original.id]?editedComboData[row.original.id][row.column.categoryKey]:"";
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], selectedCategory && category[selectedCategory] ? category[selectedCategory]:[], row)
                    )
                }
            },
            {
                Header: "Product 5 Qty",
                accessor: "product5Qty",
                type: 'number',
                Cell: renderInputCell
            },
            {
                Header: "Product 6 C",
                accessor: "product6Category",
                productIndex: 6,
                categoryColumn: true,
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], categoryOptions, row)
                    )
                }
            },
            {
                Header: "Product 6 SC",
                accessor: "product6SubCategory",
                categoryKey: "product6Category",
                productIndex: 6,
                Cell: row => {
                    let selectedCategory = editedComboData[row.original.id]?editedComboData[row.original.id][row.column.categoryKey]:"";
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], selectedCategory && category[selectedCategory] ? category[selectedCategory]:[], row)
                    )
                }
            },
            {
                Header: "Product 6 Qty",
                accessor: "product6Qty",
                type: 'number',
                Cell: renderInputCell
            },
            {
                Header: "Product 7 C",
                accessor: "product7Category",
                productIndex: 7,
                categoryColumn: true,
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], categoryOptions, row)
                    )
                }
            },
            {
                Header: "Product 7 SC",
                accessor: "product7SubCategory",
                categoryKey: "product7Category",
                productIndex: 7,
                Cell: row => {
                    let selectedCategory = editedComboData[row.original.id]?editedComboData[row.original.id][row.column.categoryKey]:"";
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], selectedCategory && category[selectedCategory] ? category[selectedCategory]:[], row)
                    )
                }
            },
            {
                Header: "Product 7 Qty",
                accessor: "product7Qty",
                type: 'number',
                Cell: renderInputCell
            },
            {
                Header: "Product 8 C",
                accessor: "product8Category",
                productIndex: 8,
                categoryColumn: true,
                Cell: row => {
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], categoryOptions, row)
                    )
                }
            },
            {
                Header: "Product 8 SC",
                accessor: "product8SubCategory",
                categoryKey: "product8Category",
                productIndex: 8,
                Cell: row => {
                    let selectedCategory = editedComboData[row.original.id]?editedComboData[row.original.id][row.column.categoryKey]:"";
                    return(
                        !comboEditFlag[row.original.id]?row.value:this.comboSelect(row.column.id, editedComboData[row.original.id][row.column.id], selectedCategory && category[selectedCategory] ? category[selectedCategory]:[], row)
                    )
                }
            },
            {
                Header: "Product 8 Qty",
                accessor: "product8Qty",
                type: 'number',
                Cell: renderInputCell
            }
        ]
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <div className="row" style={{ margin: '20px 0px' }}>
                        <div className="col-md-12" style={{ textAlign: "right" }}>
                            <Link to="/zunsolar/addcombo" target="_blank"><button className="createLeadBtn"><i className="fa fa-plus"></i> New Combo</button></Link>
                        </div>
                    </div>
                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={viewData}
                        columns={comboColumns}
                        defaultSorted={[{ id: "date", desc: true }]}
                        style={{ height: "90vh", textAlign: 'center' }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        loading={this.state.loading}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps({ zunSolarOrderTracker }) {
    let { comboList = [], mapping = {} } = zunSolarOrderTracker || {};
    return {
        comboList,
        mapping
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchCombo, fetchMapping, updateComboData, getSearchResult }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarCombo);