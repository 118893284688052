import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_ZUNSOLAR_INVENTORY_SUCCESS, FETCH_ZUNSOLAR_ORDERTRACKER_MAPPING_SUCCESS, FETCH_ZUNSOLAR_COMBO_SUCCESS, FETCH_ZUNSOLAR_ORDERS_SUCCESS, FETCH_ZUNSOLAR_ORDERDATA_SUCCESS, FETCH_ZUNSOLAR_SUBORDERS_SUCCESS, FETCH_ZUNSOLAR_ORDER_COMMENTS_SUCCESS, FETCH_ZUNSOLAR_SUBORDERDATA_SUCCESS, FETCH_ZUNSOLAR_COMBOLOTS_SUCCESS,FETCH_ZUNSOLAR_ORDERS_INVOICES_SUCCESS, FETCH_ZUNSOLAR_ORDER_HISTORY_SUCCESS, FETCH_ALL_RETURN_ORDERS, FETCH_SUB_ORDER_DATA_FOR_RETURN } from './actiontypes';
import reactSelect from 'react-select';

export function fetchInventory() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchinventory`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let inventoryData = response.data;
            console.log(inventoryData)
            dispatch({ type: FETCH_ZUNSOLAR_INVENTORY_SUCCESS, payload: inventoryData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMapping(functionToRunAfterFetchingMapping = []) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchmapping`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let mapping = response.data;
            console.log(mapping)
            functionToRunAfterFetchingMapping.forEach((func) => {
                func(mapping);
            })
            dispatch({ type: FETCH_ZUNSOLAR_ORDERTRACKER_MAPPING_SUCCESS, payload: mapping || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchCombo() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchcombos`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let comboList = response.data;
            dispatch({ type: FETCH_ZUNSOLAR_COMBO_SUCCESS, payload: comboList || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function addInventory(data) {
    return async (dispatch) => {
        try {
            let formData = new FormData()
            Object.keys(data).map(key => {
                formData.append(key, data[key]);
            })
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/addinventory`, formData);
            console.log("Res", response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            alert("Added");
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchOrders(page = 0,limit = 500,oldData = [],filters,searchText="") {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchorders`,{page: page, pageLimit: limit, filters: filters,searchText});
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let ordersData = response.data;
            dispatch({ type: FETCH_ZUNSOLAR_ORDERS_SUCCESS, payload: ordersData.concat(oldData) || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function addOrder(data) {
    return async (dispatch) => {
        try {
            // console.log("jfhj", data)
            let formData = new FormData();
            Object.keys(data).map(key => {
                if (key == "subOrders") {
                    formData.append(key, JSON.stringify(data[key]))
                } else {
                    formData.append(key, data[key]);
                }
            });
            formData.append("paymentStatus", "success");
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/createorder`, formData);
            // console.log("Res", response);
            response = response.data;
            if (response.error) {
                alert(response.error);
                return;
            }
            alert("Order Created");
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function generateInvoice(orderId,status,invoiceType) {
    return async(dispatch) => {
        try {
            // console.log("invoice generation working");
            let result = await axios.post(`${url.pathBackend}zunsolar/invoice/generateinvoice`,{orderId,status,invoiceType});
            // console.log(result);
            if (result.error) {
                swal(result.error);
            }
            swal(result.data.message);
        } 
        catch (e) {
            console.log(e);
        }
    }
}

export function downloadInvoicePdf (orderId,invoiceType) {
    return async(dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoice/downloadFile`,{orderId,invoiceType});
            console.log(response);
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            console.log(orderId,invoiceType);
            response = response.data;
            let fileData = response.fileData;
            let { Body = {}, ContentType } = fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'zunsolar_invoice.pdf');
            document.body.appendChild(link);
            link.click();
            console.log("download invoice pdf working");
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchOrdersInvoices(page = 0,limit = 500,oldData = [],filters = undefined) {
    return async (dispatch) => {
        try {
            if(Object.keys(filters || {}).length < 1){
                filters = undefined
            }
            let zunsolarOrdersInvoices = await axios.post(`${url.pathBackend}zunsolar/invoice/data`,{page, pageLimit:limit, filters});

            zunsolarOrdersInvoices = zunsolarOrdersInvoices.data.data;
            dispatch({ type: FETCH_ZUNSOLAR_ORDERS_INVOICES_SUCCESS, payload: (oldData).concat(zunsolarOrdersInvoices || []) || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchOrderData(orderId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchorderdata`, { orderId });
            // console.log("res", response)
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let orderData = response.data;
            // console.log(orderData)
            dispatch({ type: FETCH_ZUNSOLAR_ORDERDATA_SUCCESS, payload: orderData || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSubOrders(orderId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchsuborders`, { orderId });
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let subOrders = response.data;
            // console.log(subOrders,'suborders');
            dispatch({ type: FETCH_ZUNSOLAR_SUBORDERS_SUCCESS, payload: subOrders || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateOrderData(orderId, oldData, newData) {
    return async (dispatch) => {
        try {
            let modifiedData = {};
            Object.keys(newData).map(field => {
                if (newData[field] != oldData[field]) {
                    modifiedData[field] = newData[field]
                }
            });


            let updatedData = new FormData();

            updatedData.append("orderId", orderId)
            Object.keys(modifiedData).map((key) => {
                updatedData.append(key, modifiedData[key]);
            });


            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/updateorder`, updatedData);
            // console.log(response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_ZUNSOLAR_ORDERDATA_SUCCESS, payload: newData || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function addComment(comment, commentsArray) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/addcomment`, comment);
            console.log(response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_ZUNSOLAR_ORDER_COMMENTS_SUCCESS, payload: commentsArray || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchComments(orderId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchcomments`, { orderId });
            console.log("res", response)
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let comments = response.data;
            // console.log(comments)
            dispatch({ type: FETCH_ZUNSOLAR_ORDER_COMMENTS_SUCCESS, payload: comments || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchUpdateHistory(orderId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchhistory`, { orderId });
            console.log("res", response)
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let historyData = response.data;
            // console.log(history)
            dispatch({ type: FETCH_ZUNSOLAR_ORDER_HISTORY_SUCCESS, payload: historyData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSubOrderData(subOrderId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchsuborderdata`, { subOrderId });
            console.log("res", response)
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let subOrderData = response.data;
            console.log(subOrderData)
            dispatch({ type: FETCH_ZUNSOLAR_SUBORDERDATA_SUCCESS, payload: subOrderData || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function updateSubOrderData(orderId, subOrderId, oldData, newData, allData) {
    return async (dispatch) => {
        try {
            let updatedData = {};
            Object.keys(newData).map(field => {
                if (newData[field] != oldData[field]) {
                    updatedData[field] = newData[field]
                }
            })
            if (!Object.keys(updatedData).length) {
                return;
            }
            console.log("updatedData", updatedData)
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/updatesuborder`, { orderId, subOrderId, updatedData });
            console.log(response);
            response = response.data;
            if (response.data.message) {
                swal(response.data.message);
            }
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let index = allData.findIndex(row => row.subOrderId == subOrderId);
            allData = JSON.parse(JSON.stringify(allData));
            allData[index] = newData;
            dispatch({ type: FETCH_ZUNSOLAR_SUBORDERS_SUCCESS, payload: allData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function addCombo(data) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/createcombo`, data);
            console.log("Res", response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            alert("Added");
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchComboLots(subOrderId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchcombolots`, { subOrderId });
            console.log("res", response)
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let comboLots = response.data;
            console.log(comboLots)
            dispatch({ type: FETCH_ZUNSOLAR_COMBOLOTS_SUCCESS, payload: comboLots || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateComboLots(id, lotNo) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/updatecombolots`, { id, lotNo });
            console.log(response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateInventoryData(id, oldData, newData, allData) {
    return async (dispatch) => {
        try {
            let updatedData = {};
            Object.keys(newData).map(field => {
                if (newData[field] != oldData[field]) {
                    updatedData[field] = newData[field]
                }
            })
            if (!Object.keys(updatedData).length) {
                return;
            }
            console.log("updatedData", updatedData)
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/updateinventory`, { id, updatedData });
            console.log(response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let index = allData.findIndex(row => row.id == id);
            allData = JSON.parse(JSON.stringify(allData));
            allData[index] = response.newData;
            dispatch({ type: FETCH_ZUNSOLAR_INVENTORY_SUCCESS, payload: allData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function downloadInventoryFile(type ,filename) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/downloadinventoryfile`, { type, filename });
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let fileData = response.fileData;
            let { Body = {}, ContentType } = fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function updateComboData(id, oldData, newData, allData) {
    return async (dispatch) => {
        try {
            let updatedData = {};
            Object.keys(newData).map(field => {
                if (newData[field] != oldData[field]) {
                    updatedData[field] = newData[field]
                }
            })
            if (!Object.keys(updatedData).length) {
                return;
            }
            console.log("updatedData", updatedData)
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/updatecomboinfo`, { id, updatedData });
            console.log(response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let index = allData.findIndex(row => row.id == id);
            allData = JSON.parse(JSON.stringify(allData));
            allData[index] = newData;
            dispatch({ type: FETCH_ZUNSOLAR_COMBO_SUCCESS, payload: allData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function deleteOrder(id) {
    return async (dispatch) => {
        try {
            console.log(id)
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/deleteOrder`, { id });
            console.log("delete response", response);
        }
        catch (e) {
            console.log(e)
        }
    }
}

export function editBulkSubOrders(orderId, selectedSubOrderIds, subOrders, updatedData) {
    return async (dispatch) => {
        try {
            let subOrderId = [];
            Object.keys(selectedSubOrderIds).map((val) => {
                if (selectedSubOrderIds[val] == 1) {
                    subOrderId.push(val)
                }
            })
            console.log(orderId, subOrderId, subOrders, updatedData);

            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/updatesuborder`, { orderId, subOrderId, updatedData });
            console.log(response);
            response = response.data;
            if (response.data.message) {
                swal(response.data.message);
            }
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            console.log("suborders", subOrders)
            subOrderId.map((subOrderId) => {

                let index = subOrders.findIndex((val) => (val.subOrderId) == subOrderId);
                // console.log("index", index)
                Object.keys(updatedData).map((field) => {
                    subOrders[index][field] = updatedData[field];
                })
            })
            console.log("updatedSuborders", subOrders);
            dispatch({ type: FETCH_ZUNSOLAR_SUBORDERS_SUCCESS, payload: subOrders || [] });

        } catch (e) {
            console.log(e)
        }
    }

}

export function deleteInventoryItem(id) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/deleteInventory`, { id });
            console.log(response);
            if (response.data.data.error) {
                alert(`${response.data.data.error}`)
            }
            if (response.data.data.success) {
                alert("Order Deleted");
                window.location.reload()
            }
        } catch (e) {
            console.log(e)
        }
    }

}

export function createReturnOrder(data){
try {
    return async(dispatch)=>{
    let apiRes = await axios.post(`${url.pathBackend}zunsolar/ordertracker/createReturnOrder`, data);
    apiRes = apiRes.data
    if(apiRes.status=="success"){
        window.location.reload(true)
        alert("Added Successfully");
        return

    }
    alert("something went wrong")
}
} catch (error) {
    alert("something went wrong")

    
}
    
}

export function fetchReturnOrder(page = 0, limit= 500 , oldData = [], filters){
    try {
        return async(dispatch)=>{
            let apiData = {
                page: page,
                pageLimit: limit,
                filters: filters
            }
        let apiRes = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchReturnOrders`, apiData);
        apiRes = apiRes.data
        if(apiRes.status=="success"){
            apiRes = apiRes && apiRes.data || []
            console.log('apiRes', apiRes);
            if(limit=="all"){
            dispatch({type:FETCH_ALL_RETURN_ORDERS, payload:  apiRes || []})
            }
            else{
                dispatch({type:FETCH_ALL_RETURN_ORDERS, payload:  apiRes.concat(oldData) || []})

            }
            
        }
      
    }
    } catch (error) {
        alert("something went wrong")
    
        
    }
        
    }


    export function updateRetunOrderData(uniqueRowId, newDataToSend,allData){
        return async(dispatch) =>{
            let apiData  = {
                uniqueRowId : uniqueRowId,
                newData: newDataToSend
            }
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/updateReturnOrderData`,apiData);
            response = response.data;
            if(response && response.status =="success"){
                let index = allData.findIndex(row => row.uniqueRowId == uniqueRowId);
                allData = JSON.parse(JSON.stringify(allData));
                Object.keys(newDataToSend).map((key) => {
                    allData[index][key] = newDataToSend[key]
                })
              dispatch({type:FETCH_ALL_RETURN_ORDERS, payload:  allData || []})
              alert("Data Updated Successfully")
              return;
            }
            alert("something went wrong")



        try {
            
        } catch (error) {
            alert("something went wrong")
            
        }
        }
    }

    export function bulkUpdateReturnDataV2(uniqueRowId, newDataToSend){
        return async(dispatch) =>{
            let apiData  = {
                uniqueRowId : uniqueRowId,
                newData: newDataToSend
            }
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/bulkupdatereturndatav2`,apiData);
            response = response.data;
            console.log(response);
            if(response && response.status =="success"){
              alert("Data Updated Successfully")
              return;
            }
            alert("something went wrong")



        try {
            
        } catch (error) {
            alert("something went wrong")
            
        }
        }
    }

    export function fetchSubOrderDataForReturn(orderId, ticketFlag=false){
        try {
            return async(dispatch)=>{
                let apiData = {
                    orderId: orderId,
                    ticketFlag:ticketFlag
                }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ordertracker/getSubOrderDetails`, apiData);
            apiRes = apiRes.data
            if(apiRes.status=="success"){
                apiRes = apiRes && apiRes.data || []
                dispatch({type:FETCH_SUB_ORDER_DATA_FOR_RETURN, payload:  apiRes ||  {}})
                
            }
          
        }
        } catch (error) {
            alert("something went wrong")
        
            
        }
            
        }