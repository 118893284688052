import axios from 'axios';
import  url  from '../config';
import { FETCH_REPEAT_SALES_BANNER_DATA,FETCH_REPEAT_SALES_OFFER_DATA, FETCH_REPEAT_SALES_VERSION_DATA } from './actiontypes';


export function fetchRepeatSalesBannerData(){
    return async(dispatch)=>{
        try {
            let email = "devteam@zunroof.com";
            let key = url.apiKey
            if (localStorage.getItem("userObj")) {
                let userObj = JSON.parse(localStorage.getItem("userObj"));
                console.log(userObj);
                email = userObj.email
            }
            const apiData = {
                key:key,
                email:email
            }
            console.log('call Fetch repeatSales banner ');
            let bannerRes = await  axios.post(`${url.pathBackend}zunpulse/repeatSales/getUploadedBannerImages`,apiData);
            bannerRes = bannerRes.data;            
            console.log('bannerRes', bannerRes);
            if(bannerRes.status!=='success'){
                alert("some error occured");
                return;
            }
            dispatch({type: FETCH_REPEAT_SALES_BANNER_DATA, payload: bannerRes.data || []})
        } catch (error) {
            console.log("error", error)
            alert(error.message|| "some error occured");
        }

       
        
    }
}

export function fetchRepeatSalesOffersData(){
    return async(dispatch)=>{
        try {
            let email = "devteam@zunroof.com";
            let key = url.apiKey
            if (localStorage.getItem("userObj")) {
                let userObj = JSON.parse(localStorage.getItem("userObj"));
                console.log(userObj);
                email = userObj.email
            }
            const apiData = {
                key:key,
                email:email
            }
            console.log('call Fetch repeatSales banner ');
            let offerRes = await  axios.post(`${url.pathBackend}zunpulse/repeatSales/getRepeatSalesOffer`,apiData);
            offerRes = offerRes.data;
            
            console.log('offerRes', offerRes);
            if(offerRes.status!=='success'){
                alert("some error occured");
                return;
            }
            offerRes = Object.values(offerRes.data);
            console.log('offerRes2', offerRes);
            
            dispatch({type: FETCH_REPEAT_SALES_OFFER_DATA, payload: offerRes || []})
        } catch (error) {
            console.log("error", error)
            alert(error.message|| "some error occured");
        }

       
        
    }
}

export function fetchRepeatSalesVersionData(){
    return async(dispatch)=>{
        try {
            let email = "devteam@zunroof.com";
            let key = url.apiKey
            if (localStorage.getItem("userObj")) {
                let userObj = JSON.parse(localStorage.getItem("userObj"));
                console.log(userObj);
                email = userObj.email
            }
            const apiData = {
                key:key,
                email:email
            }
            console.log('call Fetch repeatSales banner ');
            let versionRes = await  axios.post(`${url.pathBackend}zunpulse/repeatSales/getVersions`,apiData);
            versionRes = versionRes.data;
            
            console.log('versionRes', versionRes);
            if(versionRes.status!=='success'){
                alert("some error occured");
                return;
            }
            versionRes = versionRes.data;
            console.log('versionRes2', versionRes);
            
            dispatch({type: FETCH_REPEAT_SALES_VERSION_DATA, payload: versionRes || []})
        } catch (error) {
            console.log("error", error)
            alert(error.message|| "some error occured");
        }

       
        
    }
}

