import React from 'react';
import axios from 'axios';
import url from '../config';
import { REGISTER_START, REGISTER_SUCCESS, REGISTER_UNSUCCESSFUL } from './actiontypes';

export function register(newUser) {
  // console.log("newUser",newUser)
  return (dispatch) => {

    axios.post(url.pathBackend + "user/register", newUser).then((response) => {
      // console.log("response",response.data.error);
      if (response.data.error) {
        dispatch({ type: REGISTER_UNSUCCESSFUL, payload: response.data.error })
      }
      if (response.data.data) {
        window.location.href = url.pathFrontend
        dispatch({ type: REGISTER_SUCCESS, payload: response.data.data })
      }
    })
  }
}
