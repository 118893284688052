import react from 'react';
import axios from 'axios';
import url from '../config';
import { FETCH_USER_START, FETCH_USER_SUCCESS } from './actiontypes'

export function getUserInfo(userObj) {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_START, payload: {} })

    axios.post(url.pathBackend + 'user/getUserInfo', userObj).then((res) => {
      // console.log("reponse for user click",res.data.user);
      dispatch({ type: FETCH_USER_SUCCESS, payload: res.data.user })
    })
  }
}