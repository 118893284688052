import { FETCH_ZUNPULSE_BURN_DASHBOARD_PRODUCT_SUMMARY, FETCH_ZUNPULSE_BURN_DASHBOARD_MARKETING_SUMMARY, FETCH_ZUNPULSE_BURN_DASHBOARD_PLATFORM_SUMMARY, FETCH_ZUNPULSE_BURN_DASHBOARD_ORDERWISE_MARKETING_SUMMARY, FETCH_ZUNPULSE_BURN_DASHBOARD_DEVICE_MARKETING_SUMMARY, FETCH_ZUNPULSE_MONTHLY_MARKETING_SPENDING, FETCH_ZUNPULSE_DAILY_MARKETING_SPENDING, FETCH_ZUNPULSE_COUPON_DETAILS } from '../actions/actiontypes';


export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ZUNPULSE_BURN_DASHBOARD_PRODUCT_SUMMARY: {
            return { ...state, productData: [ ...(action.payload.data || []) ], productColumns: [ ...(action.payload.columns || []) ], productDataReceived: true };
        }
        case FETCH_ZUNPULSE_BURN_DASHBOARD_MARKETING_SUMMARY: {
            return { ...state, marketingData: [ ...(action.payload.data || []) ], marketingColumns: [ ...(action.payload.columns || []) ], marketignDataReceived: true };
        }
        case FETCH_ZUNPULSE_BURN_DASHBOARD_PLATFORM_SUMMARY: {
            return { ...state, platformData: [ ...(action.payload.data || []) ], platformColumns: [ ...(action.payload.columns || []) ], platformDataReceived: true };
        }
        case FETCH_ZUNPULSE_BURN_DASHBOARD_ORDERWISE_MARKETING_SUMMARY: {
            return { ...state, orderwiseMarketingData: [ ...(action.payload.data || []) ], orderwiseMarketingColumns: [ ...(action.payload.columns || []) ], orderwiseMarketingReceived: true };
        }
        case FETCH_ZUNPULSE_BURN_DASHBOARD_DEVICE_MARKETING_SUMMARY: {
            return { ...state, devicewiseMarketingData: [ ...(action.payload.data || []) ], devicewiseMarketingColumns: [ ...(action.payload.columns || []) ], devicewiseMarketingReceived: true };
        }
        case FETCH_ZUNPULSE_MONTHLY_MARKETING_SPENDING: {
            return { ...state, monthlyMarketingSpendingData: [ ...(action.payload.data || []) ], monthlyMarketingSpendingColumns: [ ...(action.payload.columns || []) ], monthlyMarketingSpendingReceived: true };
        }
        case FETCH_ZUNPULSE_DAILY_MARKETING_SPENDING: {
            return { ...state, dailyMarketingSpendingData: [ ...(action.payload.data || []) ], dailyMarketingSpendingColumns: [ ...(action.payload.columns || []) ], dailyMarketingSpendingReceived: true };
        }
        case FETCH_ZUNPULSE_COUPON_DETAILS: {
            return { ...state, zunpulseCouponData: [ ...(action.payload.data || []) ], zunpulseCouponColumns: [ ...(action.payload.columns || []) ], zunpulseCouponReceived: true };
        }
        default:
            return state;
    }
}