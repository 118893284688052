import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
// import '../styles/loginPageStyles.css';
import '../styles/register.css'
import url from '../config';
import {Link} from 'react-router-dom'


export class RegisterComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      submitted: false,
      passHidden: true,
      error: "",
      success: "",
      // passwordErr: false

    }
  }

  componentWillReceiveProps(props) {
    console.log("props.error", props.error);
    this.setState({
      error: props.error,
      success: props.success
    })
  }

  handleToggle = (e) => {
    console.log(e)
    this.setState({
      passHidden: !this.state.passHidden
    })
  }

  handleChange = (e) => {
    // console.log("eeeeeeeeeeeeeeee", e)
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state)
    // if (this.checkPassword(this.state.password)) {
    let newUser = {
      firstName: this.state.firstname.trim(),
      lastName: this.state.lastname.trim(),
      email: this.state.email.trim(),
      password: this.state.password.trim(),
    };
    console.log(newUser)
    this.props.register(newUser);
    this.setState({
      submitted: true
    }, () => [
      console.log(this.state)
    ])
    
  }

  iconType = () => {
    return (
      <i onClick={(e) => { this.handleToggle(e) }} style={{padding:"0px"}} class="fa fa-eye icon" aria-hidden="true"></i>
    )
  }
  blindIconType = () => {
    return (
      <i onClick={(e) => { this.handleToggle(e) }} style={{padding:"0px"}} class="fa fa-eye-slash icon" aria-hidden="true"></i>
    )
  }

  render() {
    const style = {
      error :{
        fontSize: "0.938vw",
        color: "#e34845",
        marginTop:"-0.781vw"
      },
      success: {
        color: 'green'
      }
    }
    return (

      <div className="row no-gutters">
        <div className="col-md-6 right">
          
          <h1>Register</h1>
          
          <form onSubmit={this.handleSubmit} name="form" control="">
            <div className="form-group">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <input type="text" name="firstname" id="firstname" value={this.state.firstname} onChange={this.handleChange} required className="form-control" placeholder="First name" />
                </div>
                <div className="col-md-1">

                </div>
                <div className="col-md-5">
                  <input type="text" name="lastname" id="lastname" value={this.state.lastname} onChange={this.handleChange} required className="form-control" placeholder="Last name" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <input type="email" name="email" id="email" value={this.state.email} onChange={this.handleChange} required className="form-control" placeholder="Email Id" />
            </div>
            <div className="form-group" style={{display:"flex"}}>
                <input type={this.state.passHidden ? "password" : "text"} name="password" id="password" value={this.state.password} onChange={this.handleChange} required className="form-control" placeholder="Password" />
                {this.state.password.length > 0 ? (this.state.passHidden ? this.blindIconType() : this.iconType()) : ''}  
            </div>
            <p style={style.error}>{this.state.error}</p>
            <p style={style.success}>{this.state.success}</p>
            <button type="submit" value="Submit" className="loginSubmit">Register</button>
          </form>
          
          <div className="register">
            <h2>Already have an account? </h2>
            <Link to="/"><p>Login Here</p></Link>
          </div>
        </div>

        <div className="col-md-6 left">
          <span className="company__logo">
            <img src={require('../assets/zrm.png')} width="110px" />
          </span>
          <h5>Powered by Zunroof</h5>
        </div>
      </div>
      // <div className="container-fluid">
      //   <div className="row main-content bg-success text-center">
      //     <div className="col-md-4 text-center company__info">
      //       <span className="company__logo"><h2><img src="https://zunroof.com/assets/logo.png" width="110px" /></h2></span>
      //       <h4 className="company_title">ZRM</h4>
      //     </div>
      //     <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
      //       <div className="container-fluid">
      //         <div className="row">
      //           <h2 className="loginHead">Register</h2>
      //           <p style={style.error}>{this.state.error}</p>
      //           <p style={style.success}>{this.state.success}</p>
      //         </div>
      //         <div className="row">
      //           <form onSubmit={this.handleSubmit} name="form" control="" className="form-group loginForm">
      //             <div className={"row" + (this.state.submitted && !this.state.firstname ? ' has-error' : '')}>
      //               <input type="text" name="firstName" id="firstname" className="form__input" value={this.state.firstname} onChange={this.handleChange} placeholder="firstname" required />
      //               {/* {this.state.submitted && !this.state.firstname &&
      //                 <div className="help-block">firstname is required</div>
      //               } */}
      //             </div>
      //             <div className={"row" + (this.state.submitted && !this.state.lastname ? ' has-error' : '')}>
      //               <input type="text" name="lastName" id="lastname" value={this.state.lastname} onChange={this.handleChange} className="form__input" placeholder="lastname" required />
      //               {/* {this.state.submitted && !this.state.lastname &&
      //                 <div className="help-block">lastname is required</div>
      //               } */}
      //             </div>
      //             <div className={"row" + (this.state.submitted && !this.state.email ? ' has-error' : '')}>
      //               <input type="email" name="email" id="email" value={this.state.email} onChange={this.handleChange} className="form__input" placeholder="email" required />
      //               {/* {this.state.submitted && !this.state.email &&
      //                 <div className="help-block">email is required</div>
      //               } */}
      //             </div>
      //             <div className={this.state.password.length > 0 ? "pass__show" : "row" + (this.state.submitted && !this.state.firstname ? ' has-error' : '')}>
      //               <input type={this.state.passHidden ? "password" : "text"} title="Must contain atleast one number and one special character, and atleast 8 or more characters" placeholder="Password" name="password" id="password" value={this.state.password} onChange={this.handleChange} className="form__input" required />
      //               {/*pattern="(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}"*/}
      //               {this.state.password.length > 0 ? (this.state.passHidden ? this.blindIconType() : this.iconType()) : ''}
      //               {/* {this.state.submitted && !this.state.password &&
      //                 <div className="help-block">password is required</div>
      //               } */}

      //             </div>
      //             <div className="row">
      //               <input type="submit" value="Submit" className="btn loginSubmit" />
      //             </div>
      //           </form>
      //         </div>
      //         <div className="row">
      //           <p>Already have an Account? <a href="/">Login Here</a></p>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    )
  }
}
