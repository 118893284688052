import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTable from "react-table";
import debounce from 'lodash/debounce';

class PIInvoiceRequestInvoice extends Component {

    constructor() {
        super()
        this.state = {
            invoiceData: {},
            bookedOrderIds: [],
            historyOrders: [],
            zunsolarSubOrders: [],
            dealersData: [],
            piProductDetails: {},
            selectAll: false,
            selectedSubOrderIds: {},
            singlePIDetails: {},
        };
    }

    componentWillMount() {
        this.props.fetchBookedOrderIdsFromZunsolarOrders()
        this.props.fetchPiProductDetails()
    }

    componentWillReceiveProps(props) {
        let { invoiceData } = this.state
        let { bookedOrderIds, allSubOrder, dealersData, fetchDealersListError, piProductDetails, singlePIDetails, invoiceTypeMapping } = props
        let zunsolarSubOrders = allSubOrder.suborders && allSubOrder.suborders.length > 0 ? allSubOrder.suborders.map((subOrder) => {
            let gst = +((this.state.piProductDetails[`${subOrder.category}_${subOrder.subCategory}`] || {}).gstRate) || null
            let productDescription = (this.state.piProductDetails[`${subOrder.category}_${subOrder.subCategory}`] || {}).description || ''
            let quantity = 1
            let gstValue = parseFloat(((+gst) * (+subOrder.onlinePrice)) / 100).toFixed(2);
            let priceWithGst = parseFloat((+subOrder.onlinePrice) + (+gstValue)).toFixed(2);
            let grandTotal = parseFloat((priceWithGst * quantity)).toFixed(2);
            return {...subOrder, productDescription, gst, quantity, grandTotal }
        }) : []
        this.setState({
            invoiceData: {
            ...invoiceData,
            freight: +singlePIDetails.freight || 0,
            discountPercentage: +singlePIDetails.discount || 0,
            dealerAddress: singlePIDetails['dealerAddress'] || '',
            dealerId: singlePIDetails['dealerId'] || '',
            dealerName: singlePIDetails['dealerName'] || ''
            },
            bookedOrderIds,
            historyOrders: allSubOrder.subOrdersPIInvoiceOrders || [],
            zunsolarSubOrders,
            dealersData: dealersData || [],
            fetchDealersListError: fetchDealersListError,
            piProductDetails: piProductDetails || {},
            singlePIDetails,
            invoiceTypeMapping
        })
    }

    AddSelect = ({ isMulti, label, name, options = [], onChange, required = false, value, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required ? '*' : ''}`}</label>
                        <Select
                            isSearchable={true}
                            placeholder={label}
                            name={name}
                            options={options}
                            onChange={(e) => onChange(e, name)}
                            isMulti={isMulti}
                            value={{ value: value, label: value }}
                            required={required}
                        />
                    </div>
                </div>
            </div>
        )
    }

    AddInput = ({ label, name, type, value, onChange, required = false, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required ? '*' : ''}`}</label>
                        <input className="form-control customInput" type={type} name={name} value={value} placeholder={label} onChange={(e) => onChange(e)} required={required} {...inputParams} />
                    </div>
                </div>
            </div>
        )
    }

    handleInvoiceSelectChange = (e, name) => {
        let { value } = e
        switch (name) {
            case 'orderId':
                this.props.fetchSubOrdersFromOrderId(value)
                this.props.fetchZunsolarPiOrders(value)
                break;
        }
        this.setState({
            invoiceData: {
                [name]: value
            }
        })
    }

    handleSelectChange = (e,name) => {
        let { value } = e
        let { invoiceData } = this.state
        this.setState({
            invoiceData: {
                ...invoiceData,
                [name]: value
            }
        })
    }

    handleSelectDealerId = (e) => {
        let { value, name, address } = e;
        this.setState(prevState => ({
            invoiceData: {
                ...prevState.invoiceData,
                dealerId: value,
                dealerName: name,
                dealerAddress: address
            }
        }))
    }

    inputDealerIdChange = (e) => {
        if (e.length <= 3) return;
        this.props.fetchDealersData(e);
        this.setState({
            fetchDealersListError: false
        })
    }

    handleInvoiceInputChange = (e) => {
        let { name, value } = e.target;
        let { invoiceData } = this.state;
        invoiceData[name] = value;
        this.setState({
            invoiceData
        })
    }

    calculateGrandTotal = (e) => {
        if(Object.keys(this.state.selectedSubOrderIds).length > 0){
            let totalPriceWithGst = 0;
            let totalGstPrice = 0;
            let totalPriceWithoutGst = 0;
            let priceAfterDiscount = 0;
            let discount = 0
            Object.keys(this.state.selectedSubOrderIds).forEach((subOrderIdIndex) => {
                if(this.state.selectedSubOrderIds[subOrderIdIndex] == 1){
                    let gst_price = (+this.state.zunsolarSubOrders[subOrderIdIndex].gst * 0.01) * +this.state.zunsolarSubOrders[subOrderIdIndex].onlinePrice
                    totalGstPrice += gst_price;
                    totalPriceWithoutGst += +this.state.zunsolarSubOrders[subOrderIdIndex].onlinePrice
                }
            })
            if (this.state.invoiceData.discountPercentage) {
                discount = (totalPriceWithoutGst * (+this.state.invoiceData.discountPercentage / 100))
                priceAfterDiscount = totalPriceWithoutGst - discount
            }
            totalPriceWithGst = (priceAfterDiscount || totalPriceWithoutGst) + totalGstPrice;
            let priceWithFrieght = (+this.state.invoiceData.freight || 0) + totalPriceWithGst;
            this.setState({
                invoiceData: {
                    ...this.state.invoiceData,
                    grandTotal: priceWithFrieght.toFixed(2),
                    freight: +this.state.invoiceData.freight || 0,
                    discount
                }
            })
        }
    }

    handleCheckbox = (e, subOrderIdIndex,row) => {
        let status = this.state.selectedSubOrderIds[subOrderIdIndex];
        let selectedSubOrderId = JSON.parse(JSON.stringify(this.state.selectedSubOrderIds));
        let selectedSubOrderIds = { ...selectedSubOrderId, [subOrderIdIndex]: status ? 0 : 1 };
        this.setState({
            selectedSubOrderIds: selectedSubOrderIds
        })
    }

    handleAllCheckbox = (e) => {
        let { zunsolarSubOrders, selectAll } = this.state
        let selectedSubOrderIds = {}
        zunsolarSubOrders.forEach((subOrder,index) => { 
            selectedSubOrderIds[index] = !selectAll
            return;
        })
        this.setState({
            selectedSubOrderIds,
            selectAll: !selectAll
        })
    }


    handleSubmitRequestInvoiceForm = (e) => {
        e.preventDefault();
        if(Object.keys(this.state.selectedSubOrderIds).length > 0){
            let formSubOrders = []
            Object.keys(this.state.selectedSubOrderIds).forEach((subOrderIdIndex) => {
                if(this.state.selectedSubOrderIds[subOrderIdIndex] == 1){
                    let product = {}
                    product.subOrderId = this.state.zunsolarSubOrders[subOrderIdIndex].subOrderId || '-'
                    product.category = this.state.zunsolarSubOrders[subOrderIdIndex].category || '-'
                    product.subCategory = this.state.zunsolarSubOrders[subOrderIdIndex].subCategory || '-'
                    product.productDescription = this.state.zunsolarSubOrders[subOrderIdIndex].productDescription || '-'
                    product.quantity = this.state.zunsolarSubOrders[subOrderIdIndex].quantity || 0
                    product.itemPrice = this.state.zunsolarSubOrders[subOrderIdIndex].onlinePrice || 0
                    product.gst = this.state.zunsolarSubOrders[subOrderIdIndex].gst || 0
                    product.grandTotal = this.state.zunsolarSubOrders[subOrderIdIndex].grandTotal || 0
                    formSubOrders.push(product)
                }
            })
            if(formSubOrders.length > 0){
                let formData = {...this.state.invoiceData, productList:formSubOrders}
                delete formData['discountPercentage']
                this.props.requestInvoice(formData)
                this.setState({
                    invoiceData:{},
                    historyOrders: [],
                    zunsolarSubOrders: [],
                    piProductDetails: {},
                    selectAll: false,
                    selectedSubOrderIds: {}
                })
            }
        }
    }

    render() {
        let { AddSelect, handleInvoiceSelectChange, state, handleSelectDealerId, inputDealerIdChange, AddInput, handleInvoiceInputChange, calculateGrandTotal, handleSubmitRequestInvoiceForm, handleSelectChange, handleAllCheckbox } = this;
        let { bookedOrderIds, zunsolarSubOrders, dealersData, fetchDealersListError, invoiceData, historyOrders, invoiceTypeMapping } = state;
        let { dealerId = '', dealerName = '', orderId, dealerAddress = '', discount, discountPercentage, freight, grandTotal, invoiceType, shipToName = '', shipToAddress = '' } = invoiceData;
        const zunsolarSubOrdersColumns = [
            {
                Header: row => {
                    return (
                        <div>
                            <input type="checkbox" onChange={(e) => { handleAllCheckbox(e) }} checked={this.state.selectAll ? 'checked' : ''} ></input>
                        </div>
                    )
                },
                filterable: true,
                sortable: false,
                columns: false,
                Cell: row => {
                    return (
                    <div>
                        <input type="checkbox" onChange={(e) => { this.handleCheckbox(e, row.index,row) }} checked={this.state.selectedSubOrderIds[row.index] ? 'checked' : ''} ></input>
                    </div>
                )}
            },
            {
                Header: 'Sub Order Id',
                accessor: 'subOrderId'
            },
            {
                Header: 'Category',
                accessor: 'category'
            },
            {
                Header: 'Sub Category',
                accessor: 'subCategory'
            },
            {
                Header: 'Product Description',
                accessor: 'productDescription'
            },
            {
                Header: 'Quantity',
                accessor: 'quantity'
            },
            {
                Header: 'Item Price(without GST)',
                accessor: 'onlinePrice'
            },
            {
                Header: 'GST(%)',
                accessor: 'gst'
            },
            {
                Header: 'Grand Total',
                accessor: 'grandTotal'
            }
        ]

        const historySubOrdersColumns = [
            {
                Header: 'Invoice Id',
                accessor: 'invoicePIOrderId'
            },
            {
                Header: 'Sub Order Id',
                accessor: 'subOrderId'
            },
            {
                Header: 'Category',
                accessor: 'category'
            },
            {
                Header: 'Sub Category',
                accessor: 'subCategory'
            },
            {
                Header: 'Product Description',
                accessor: 'productDescription'
            },
            {
                Header: 'Quantity',
                accessor: 'quantity'
            },
            {
                Header: 'Item Price(without GST)',
                accessor: 'onlinePrice'
            },
            {
                Header: 'GST(%)',
                accessor: 'gst'
            },
            {
                Header: 'Grand Total',
                accessor: 'grandTotal'
            },
            {
                Header: 'Invoice Number',
                accessor: 'invoiceNumber'
            },
            {
                Header: 'Invoice Status',
                accessor: 'approvalStatus'
            },
        ]
        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Request Invoice</h4>
                <div className="addVendorForm">
                    <form onSubmit={handleSubmitRequestInvoiceForm}>
                        <div className="row">
                            <AddSelect
                                label="Order Id"
                                name="orderId"
                                isMulti={false}
                                options={bookedOrderIds.map(row => ({ label: row.orderId, value: row.orderId }))}
                                onChange={handleInvoiceSelectChange}
                                required={true}
                                value={orderId}
                            />
                            <div className="col-12 col-md-2" style={{ width: '100%', paddingTop: '1%' }}>
                                <div className="form-group">
                                    <div className="mb-3">
                                        <label>Dealer Id*</label>
                                        <Select
                                            isSearchable
                                            placeholder={"Select Dealer Id"}
                                            name={"dealer"}
                                            options={dealersData.map(val => { return ({ label: `${val.solarId} ${val.name}`, value: val.solarId, name: val.name, address: val.address }) })}
                                            onInputChange={debounce((e) => inputDealerIdChange(e), 3000)}
                                            noOptionsMessage={() => fetchDealersListError ? "No results found" : "Loading..."}
                                            onChange={(e) => handleSelectDealerId(e)}
                                            value={{ label: `${dealerId} ${dealerName}`, value: dealerId }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <AddInput
                                label="Dealer Name"
                                name="dealerName"
                                type="text"
                                value={dealerName}
                                onChange={handleInvoiceInputChange}
                                required={true}
                                inputParams={{disabled:true}}
                            />
                            <AddInput
                                label="Dealer Address"
                                name="dealerAddress"
                                type="text"
                                value={dealerAddress}
                                onChange={handleInvoiceInputChange}
                                required={true}
                            />
                            <AddInput
                                label="Ship To Name"
                                name="shipToName"
                                type="text"
                                value={shipToName}
                                onChange={handleInvoiceInputChange}
                                required={true}
                            />
                            <AddInput
                                label="Ship To Address"
                                name="shipToAddress"
                                type="text"
                                value={shipToAddress}
                                onChange={handleInvoiceInputChange}
                                required={true}
                            />
                            <AddSelect
                                label="Invoice Type"
                                name="invoiceType"
                                isMulti={false}
                                options={(invoiceTypeMapping || []).map(row => ({ label: row, value: row }))}
                                onChange={handleSelectChange}
                                required={true}
                                value={invoiceType || undefined}
                            />
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h5 className="procurementAddVendorHeading">Sub Orders</h5>
                                <ReactTable
                                    filterable={true}
                                    showPagination={true}
                                    sortable={true}
                                    data={zunsolarSubOrders}
                                    columns={zunsolarSubOrdersColumns}
                                    defaultSorted={[{ id: "id", desc: true }]}
                                    defaultPageSize={5}
                                    ref={this.reactTable}
                                ></ReactTable>
                            </div>
                        </div>
                        <div className='row'>
                            <AddInput
                                label="Discount Percentage"
                                name="discountPercentage"
                                type="number"
                                value={discountPercentage}
                                onChange={handleInvoiceInputChange}
                                required={true}
                                inputParams={{'min':0, 'max':100}}
                            />
                            <AddInput
                                label="Discount"
                                name="discount"
                                type="number"
                                value={discount}
                                onChange={handleInvoiceInputChange}
                                required={true}
                                inputParams={{disabled:true, 'min':0}}
                            />
                            <AddInput
                                label="Freight"
                                name="freight"
                                type="number"
                                value={freight}
                                onChange={handleInvoiceInputChange}
                                required={true}
                                inputParams={{'min':0}}
                            />
                            <AddInput
                                label="Grand Total"
                                name="grandTotal"
                                type="number"
                                value={grandTotal}
                                onChange={handleInvoiceInputChange}
                                required={true}
                                inputParams={{'min':0}}
                            />
                        </div>

                        <button type="submit" className="btn btn-outline-success" style={{ float: 'right', marginLeft: '10px'  }}>Submit</button>
                        <button type='button' className="btn btn-primary" style={{ float: 'right' }} onClick={(e) => calculateGrandTotal(e)}>Calculate</button>
                        <div style={{ clear: 'both' }}></div>
                    </form>
                    <div className='row'>
                        <div className='col-12'>
                            <h5 className="procurementAddVendorHeading">History</h5>
                            <ReactTable
                                filterable={true}
                                showPagination={true}
                                sortable={true}
                                data={historyOrders}
                                columns={historySubOrdersColumns}
                                defaultSorted={[{ id: "id", desc: true }]}
                                defaultPageSize={5}
                            ></ReactTable>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps() {
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PIInvoiceRequestInvoice);