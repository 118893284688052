import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import Select from 'react-select';
import { repeatNotificationFieldConfig } from '../../config/column_fields';
import axios from 'axios';
import url, { appBackendUrl, zcommerceUrl } from '../../config';
import { Redirect } from 'react-router-dom';
import {  mainDataMapper } from '../../helper/dataMapper';
import '../../styles/addCoupon.css';
import ZunpulseRepeatUploadImages from './ZunpulseRepeatUploadImages';
import ZunpulseRepeatViewImages from './ZunpulseRepeatViewImages';
import ZunpulseRepeatCreateOffer from './ZunpulseRepeatCreateOffer';
import ZunpulseRepeatViewOffer from './ZunpulseRepeatViewOffer';
import ZunpulseRepeatCreateVersion from './ZunpulseRepeatCreateVersion';
import ZunpulseRepeatLiveVersion from './ZunpulseRepeatLiveVersion';


const repeatSalesSteps = [
    {
        stepName:"Upload App Banner Images",
        redirectUrl :"/imageUpload"
    },
    {
        stepName:"View App Banner Images",
        redirectUrl :"/repeatSales/imageUpload"
    },
    {
        stepName:"Create Repeat Sale Offers",
        redirectUrl :"/repeatSales/createOffer"
    },
    {
        stepName:"View Repeat Sale Offer",
        redirectUrl :"/repeatSales/viewOffer"
    },
    {
        stepName:"Create Repeat Sale New Version",
        redirectUrl :"/repeatSales/createVersion"
    },
    {
        stepName:"Live Repeat Sales ",
        redirectUrl :"/repeatSales/live"
    },
]

class ZunpulseRepeatSalesAPP extends Component {

    constructor() {
        super()
        this.state = {
            
            activeIndex:0,
        };
    }

  

    componentWillMount() {


        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
    }


    handleRepeatSalesRedirect =  (path,index) => {
        console.log('path', path);
        this.setState({
            activeIndex:index
        })

    }  

    
 


    render() {
        let {activeIndex} = this.state


        if(!((this.state.userObj.access || []).includes("Website-Admin"))) {
            return <Redirect to={"/"} />
        }

        return (
            <div>
                <Header />
                <div className="container-fluid" style={{ marginTop: "80px", textAlign: 'center' }}>
                    <h4 style={{ fontWeight: "bold" }}>Repeat Sales App Dashboard </h4>
                    <hr />
                    <div className="row  flex-md-row justify-content-between">
                        {
                            repeatSalesSteps.map((step,index)=>(
                                <div key={index} style={{cursor:"pointer", fontWeight: activeIndex==index ? 'bold':'normal'}} onClick={()=>this.handleRepeatSalesRedirect(step.redirectUrl,index)}>
                                    {step.stepName}
                                
                                </div>
                            ))
                        }

                    </div>
                    {
                        activeIndex ==  0  && <ZunpulseRepeatUploadImages/> ||
                        activeIndex ==  1  && <ZunpulseRepeatViewImages/> ||
                        activeIndex ==  2  && <ZunpulseRepeatCreateOffer/> ||
                        activeIndex ==  3  && <ZunpulseRepeatViewOffer/> ||
                        activeIndex ==  4  && <ZunpulseRepeatCreateVersion/> ||
                        activeIndex == 5 && <ZunpulseRepeatLiveVersion/>
                    }
                   

                   
                </div>
            </div>
        )
    }

}

function mapStateToProps({ }) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseRepeatSalesAPP);