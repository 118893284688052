import React from 'react';
import moment from 'moment';

const RenderHeader = ({ config = [] }) => {
    return (
        <tr>
            {config.map(row => <th scope="col" style={{ textAlign: 'center' }}>{row.Header}</th>)}
        </tr>
    )
}

const RenderCell = ({ rowData = {}, index, row = {}, columnIndex, handleNextActionClick, downloadSAFile }) => {
    if(columnIndex == 0) {
        return <th scope="row"><a className='nameCellFollowup' href={`tel:${rowData.PHONE_VALUE}`}>{rowData[row.accessor]}</a></th>
    }
    else if(row.Key == 'Edit') {
        return <td className='btn-group'><row.component index={index} />{rowData.PHONE_VALUE && rowData.TITLE && rowData.SITE_ASSESSMENT_DATE?<a className='btn' href={`https://wa.me/+91${rowData.PHONE_VALUE.slice(-10)}?text=${encodeURIComponent(`Hi ${rowData.TITLE},\n\nGreetings from Zunroof! It was a pleasure conducting the site assessment at your place on ${moment(rowData.SITE_ASSESSMENT_DATE, "YYYYMMDD").format("DD MMM YYYY")}.\n\nKindly let us know if you want to confirm your order or want us to call you back for any queries.\n\nThanks,\nTeam ZunRoof`)}`} target="_blank"><i className='fa fa-whatsapp'></i></a> : ""}</td>
    }
    else if(row.accessor == 'NEXT_ACTION_DATE') {
        let nextActionDate = rowData[row.accessor] && moment(rowData[row.accessor], "YYYYMMDD", true).isValid() ? moment(rowData[row.accessor], "YYYYMMDD", true).format("DD MMM YYYY") : ""
        return <td onClick={() => handleNextActionClick(rowData)}>{nextActionDate}</td>;
    }
    else if(row.accessor == 'QUOTE_ID') {
        return <td onClick={() => downloadSAFile(rowData.QUOTE_ID)}>{rowData.QUOTE_ID}</td>;
    }
    else {
        return <td>{rowData[row.accessor]}</td>
    }
}

const RenderRow = ({ config = [], rowData = {}, rowIndex, handleNextActionClick, downloadSAFile }) => {
    return (
        <tr>
            {config.map((row, index) => <RenderCell rowData={rowData} row={row} index={rowIndex} columnIndex={index} handleNextActionClick={handleNextActionClick} downloadSAFile={downloadSAFile} /> )}
        </tr>
    )
}

export const CustomTable = ({ config = [], data = [], caption = '', handleNextActionClick, downloadSAFile }) => {
    return (
        <table className="table table-striped table-bordered table-sm">
            <caption>{caption}</caption>
            <thead className="thead-dark">
                <RenderHeader config={config} />
            </thead>
            <tbody>
                {
                    data.map((rowData, index) => <RenderRow
                        config={config}
                        rowData={rowData}
                        rowIndex={index}
                        handleNextActionClick={handleNextActionClick}
                        downloadSAFile={downloadSAFile}
                    />)
                }
            </tbody>
        </table>
    )
}