import React from 'react';
import Select from 'react-select';
import ReactTable from "react-table";
import { Chart } from "react-google-charts";
import url from '../../config';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';


export const SalesSummary = ({ columns, data, filters, stateOptions, categoryOptions, subCategoryOptions, shippingStatusOptions, handleDateChange, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Sales Summary</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "category", e)}
                            placeholder={"Choose Category"}
                            name={"category"}
                            options={categoryOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "subCategory", e)}
                            placeholder={"Choose Sub Category"}
                            name={"subcategory"}
                            options={subCategoryOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}


export const ShippingSummary = ({ columns, data, stateOptions, categoryOptions, subCategoryOptions, platformOptions, handleDateChange, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Shipping Summary</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "category", e)}
                            placeholder={"Choose Category"}
                            name={"category"}
                            options={categoryOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "subCategory", e)}
                            placeholder={"Choose Sub Category"}
                            name={"subcategory"}
                            options={subCategoryOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "platform", e)}
                            placeholder={"Platform"}
                            name={"platform"}
                            options={platformOptions}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const OrderValueView = ({ columns, data, stateOptions, platformOptions, handleDateChange, handleSelectChange, applyFilter, loading, type, filterValues }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Order Value</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(type, "invoiceDate", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(type, "invoiceDate", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "platform", e)}
                            placeholder={"Platform"}
                            name={"platform"}
                            options={platformOptions}
                            value={(filterValues["platform"] || []).map(val => ({label: val, value: val}))}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const SubOrderValueView = ({ columns, data, stateOptions, categoryOptions, subCategoryOptions, shippingStatusOptions, platformOptions, handleDateChange, handleSelectChange, applyFilter, loading, type, filterValues }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}> Sub Order Value</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "category", e)}
                            placeholder={"Choose Category"}
                            name={"category"}
                            options={categoryOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "subCategory", e)}
                            placeholder={"Choose Sub Category"}
                            name={"subcategory"}
                            options={subCategoryOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "platform", e)}
                            placeholder={"Platform"}
                            name={"platform"}
                            options={platformOptions}
                            value={(filterValues["platform"] || []).map(val => ({label: val, value: val}))}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const LastTenDaysSummary = ({ columns, data, stateOptions, categoryOptions, subCategoryOptions, shippingStatusOptions, handleDateChange, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Last 10 Days Summary</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "category", e)}
                            placeholder={"Choose Category"}
                            name={"category"}
                            options={categoryOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "subCategory", e)}
                            placeholder={"Choose Sub Category"}
                            name={"subcategory"}
                            options={subCategoryOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "date", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}


export const StateSummaryView = ({ columns, data, categoryOptions, shippingStatusOptions, platformOptions, handleDateChange, handleSelectChange, applyFilter, loading, type, filterValues, handleStateCategoryChange, handleStateTableTypeChange, stateTableType }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}> State Summary</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            onChange={(e) => handleStateTableTypeChange(e)}
                            placeholder={"Choose Type"}
                            name={"stateTableType"}
                            options={['Qty', 'Revenue', 'Average'].map(val => ({label: val, value: val}))}
                            value={{label: stateTableType, value: stateTableType}}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            onChange={(e) => handleStateCategoryChange(type, "category", e)}
                            placeholder={"Choose Category"}
                            name={"category"}
                            options={categoryOptions}
                            value={filterValues["category"] ? { label: filterValues["category"], value: filterValues["category"] } : ""}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "platform", e)}
                            placeholder={"Platform"}
                            name={"platform"}
                            options={platformOptions}
                            value={(filterValues["platform"] || []).map(val => ({label: val, value: val}))}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    pageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const ReactTableCardComponent = ({ columns, data, cardHeading, loading, defaultSorted, filterable = false, defaultPageSize, showPagination=true, pageSize }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>{cardHeading}</div>
                </div>
                <ReactTable
                    filterable={filterable}
                    sortable={true}
                    showPagination={showPagination}
                    defaultPageSize={defaultPageSize || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                    pageSize={pageSize || 5}
                />
            </div>
        </div>
    )
}

export const MetricsSummaryComponent = ({ data = {} }) => {
    return (
        <div className="metricsSummary mt-3">
            <div style={{ fontWeight: "bold" }}>Today's Metrics Summary</div>
            <div class="row mt-3 summaryCards">
                <div class="col-md-4 col-xl">
                    <div class="card bg-c-blue order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Total Orders</h5>
                            <h2 class="text-right"><i class="fas fa-shopping-cart f-left"></i><span>{data.SKU}</span></h2>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-xl">
                    <div class="card bg-c-green order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Total Units</h5>
                            <h2 class="text-right"><i class="fa fa-truck f-left"></i><span>{data.units}</span></h2>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-xl">
                    <div class="card bg-c-yellow order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Amount</h5>
                            <h2 class="text-right"><i class="fa fa-rupee-sign f-left"></i><span>{data.amount}</span></h2>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-xl">
                    <div class="card bg-c-pink order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Best Seller of the Week</h5>
                            <h5 class="text-right"><i class="fa fa-trophy f-left"></i><span className="ml-3">{(data.bestSeller || []).join(',')}</span></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const SalesSummaryComponent = ({ columns, data, stateOptions, productOptions, shippingStatusOptions, handleSelectChange, applyFilter, loading, type, tableType, handleObjArrtFilterChange, platformTypeOptions, orderIdName = "" }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Sales {orderIdName} Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    { tableType != "Order" ? <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "pID", e)}
                            placeholder={"Choose Product"}
                            name={"pID"}
                            options={productOptions}
                        />
                    </div> : "" }
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleObjArrtFilterChange(type, "platformsByplatType", e)}
                            placeholder={"platform Type"}
                            name={"platformsByplatType"}
                            options={platformTypeOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const ShippingSummaryComponent = ({ columns, data, stateOptions, productOptions, platformOptions, handleSelectChange, applyFilter, loading, type, tableType, platformTypeOptions, handleObjArrtFilterChange }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Shipping Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    { tableType != "Order" ? <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "pID", e)}
                            placeholder={"Choose product"}
                            name={"pID"}
                            options={productOptions}
                        />
                    </div> : "" }
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "platform", e)}
                            placeholder={"platform"}
                            name={"platform"}
                            options={platformOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleObjArrtFilterChange(type, "platformsByplatType", e)}
                            placeholder={"platform Type"}
                            name={"platformsByplatType"}
                            options={platformTypeOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const SubOrderValueViewComponent = ({ columns, data, stateOptions, productOptions, shippingStatusOptions, platformOptions, handleSelectChange, applyFilter, loading, type, handleObjArrtFilterChange, platformTypeOptions }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Sub-Order Value</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    {/* <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "productId", e)}
                            placeholder={"Choose product"}
                            name={"productId"}
                            options={productOptions}
                        />
                    </div> */}
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "platform", e)}
                            placeholder={"Platform"}
                            name={"platform"}
                            options={platformOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleObjArrtFilterChange(type, "platformsByplatType", e)}
                            placeholder={"platform Type"}
                            name={"platformsByplatType"}
                            options={platformTypeOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const LastTenDaysSummaryComponent = ({ columns, data, stateOptions, productOptions, shippingStatusOptions, handleSelectChange, applyFilter, loading, type, tableType, handleObjArrtFilterChange, platformTypeOptions }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Last 10 Days Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    { tableType != "Order" ? <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "pID", e)}
                            placeholder={"Choose Product"}
                            name={"pID"}
                            options={productOptions}
                        />
                    </div> : "" }
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleObjArrtFilterChange(type, "platformsByplatType", e)}
                            placeholder={"platform Type"}
                            name={"platformsByplatType"}
                            options={platformTypeOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "date", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const CategorySummaryComponent = ({ columns, data, loading, type, applyFilter, handleObjArrtFilterChange, platformTypeOptions }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Category Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleObjArrtFilterChange(type, "platformsByplatType", e)}
                            placeholder={"platform Type"}
                            name={"platformsByplatType"}
                            options={platformTypeOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const StateSummaryComponent = ({ columns, data, stateOptions, handleSelectChange, applyFilter, loading, type, selectedCategory, handleCategoryChange, categoryOptions, handleObjArrtFilterChange, platformTypeOptions }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>State Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            onChange={(e) => handleCategoryChange(e)}
                            placeholder={"Choose Category"}
                            name={"stateTableSelectedCategory"}
                            options={categoryOptions}
                            defaultValue={{ label: selectedCategory, value: selectedCategory }}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleObjArrtFilterChange(type, "platformsByplatType", e)}
                            placeholder={"platform Type"}
                            name={"platformsByplatType"}
                            options={platformTypeOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const MapViewComponent = ({ data }) => {
    return (
        <div className="mapView mt-3">
            <Chart
                chartEvents={[
                    {
                        eventName: "select",
                        callback: ({ chartWrapper }) => {
                            const chart = chartWrapper.getChart();
                            const selection = chart.getSelection();
                            if (selection.length === 0) return;
                            const region = data[selection[0].row + 1];
                            console.log("Selected : " + region);
                        }
                    }
                ]}
                chartType="GeoChart"
                width="100%"
                height="700px"
                data={data}
                options={{ region: "IN", resolution: 'provinces', displayMode: 'regions' }}
                mapsApiKey={url.googleMapsKey}
            />
        </div>
    )
}

export const OrderValueViewNewComponent = ({ columns, data, stateOptions, shippingStatusOptions, platformOptions, handleSelectChange, applyFilter, loading, type, handleObjArrtFilterChange, platformTypeOptions }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Order Value</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "platform", e)}
                            placeholder={"Platform"}
                            name={"platform"}
                            options={platformOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleObjArrtFilterChange(type, "platformsByplatType", e)}
                            placeholder={"platform Type"}
                            name={"platformsByplatType"}
                            options={platformTypeOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}