import axios from 'axios';
import url from '../config';

export function downloadData(data) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunpulse/analytics/downloadacdata`, data);
            console.log(response);
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let csvData = response.csvData;
            const blob = new Blob([csvData], { type: 'application/csv' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'AC Data.csv');
            document.body.appendChild(link);
            link.click();
        }
        catch (e) {
            console.log(e);
        }
    }
}