import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import "../../styles/ProcurementPortalStyles.css";
import PIInvoiceRequestInvoice from '../../components/zunsolar/PIInvoiceRequestInvoice';
import PIInvoiceAll from '../../components/zunsolar/PIInvoiceAll';
import PIInvoiceLedger  from '../../components/zunsolar/PIInvoiceLedger';
import { fetchBookedOrderIdsFromZunsolarOrders, fetchSubOrdersFromOrderId, requestInvoice, getAllPIInvoiceOrders, updatePIInvoice, downloadFile, getAllPIInvoiceLedger, downloadFileByInvoiceNumber } from '../../actions/zunsolarPiInvoiceAction';
import { fetchDealersData, fetchPiProductDetails, fetchZunsolarPiOrders } from '../../actions/zunsolarPiPortalActions'


class ZunsolarPIInvoice extends Component {

    constructor() {
        super();
        this.state = {
            selectedTab: "invoices",
            userObj: {},
            approvalStatusMapping: ['approved','cancelled','requested','truncated','created'],
            invoiceTypeMapping: ['Purchase','Credit Note']
        };
    }

    componentWillMount() {
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
    }

    handleOnViewChange = (e) => {
        let { name = '' } = e.target;
        this.setState({ selectedTab: name });
    }

    TabsComponent = ({ name, label }) => {
        let { selectedTab = '' } = this.state;
        let { handleOnViewChange } = this;
        return <button className={`tabButtons btn btn-sm ${selectedTab === name ? "btn-info" : "btn-outline-info"}`} name={name} onClick={handleOnViewChange}>{label}</button>;
    }

    render() {

        let { TabsComponent, state, props } = this;
        let { selectedTab, approvalStatusMapping, invoiceTypeMapping, userObj } = state;
        let { fetchBookedOrderIdsFromZunsolarOrders, bookedOrderIds, fetchSubOrdersFromOrderId, allSubOrder, fetchDealersData, dealersData, fetchDealersListError, piProductDetails, fetchPiProductDetails, fetchZunsolarPiOrders, singlePIDetails, requestInvoice, allInvoices, getAllPIInvoiceOrders, updatePIInvoice, downloadFile, getAllPIInvoiceLedger, allLedger, downloadFileByInvoiceNumber } = props;
        return (
            <div>
                <Header />
                <div className={`container-fluid procurementMainDiv`}>
                    <div className="tabComponent">
                        <TabsComponent name="requestInvoice" label="Request Invoice" />
                        <TabsComponent name="invoices" label="Invoices" />
                        <TabsComponent name="ledger" label="Ledger" />
                    </div>
                    {
                        selectedTab === "requestInvoice" ? <PIInvoiceRequestInvoice
                            fetchBookedOrderIdsFromZunsolarOrders={fetchBookedOrderIdsFromZunsolarOrders}
                            fetchSubOrdersFromOrderId={fetchSubOrdersFromOrderId}
                            bookedOrderIds = {bookedOrderIds}
                            allSubOrder = {allSubOrder}
                            fetchDealersData = {fetchDealersData}
                            dealersData = {dealersData}
                            fetchDealersListError = {fetchDealersListError}
                            fetchPiProductDetails = {fetchPiProductDetails}
                            piProductDetails = {piProductDetails}
                            fetchZunsolarPiOrders = {fetchZunsolarPiOrders}
                            singlePIDetails = {singlePIDetails}
                            requestInvoice = {requestInvoice}
                            invoiceTypeMapping= {invoiceTypeMapping}
                        /> : ""
                    }
                    {
                        selectedTab === "invoices" ? <PIInvoiceAll
                            allInvoices={allInvoices}
                            getAllPIInvoiceOrders={getAllPIInvoiceOrders}
                            approvalStatusMapping={approvalStatusMapping}
                            invoiceTypeMapping = {invoiceTypeMapping}
                            updatePIInvoice = {updatePIInvoice}
                            userObj = {userObj}
                            downloadFile = {downloadFile}
                        /> : ""
                    }
                    {
                        selectedTab === "ledger" ? <PIInvoiceLedger
                            allLedger = {allLedger}
                            getAllPIInvoiceLedger = {getAllPIInvoiceLedger}
                            downloadFileByInvoiceNumber = {downloadFileByInvoiceNumber}
                        /> : ""
                    }
                </div>
            </div>
        )
    }

}

function mapStateToProps({ zunsolarPiInvoice, zunsolarPiPortal }) {
    let { bookedOrderIds = [], allSubOrder = {}, allInvoices = [], allLedger = [] } = zunsolarPiInvoice;
    let { dealersData = [], fetchDealersListError = false, piProductDetails = {}, singlePIDetails = {} } = zunsolarPiPortal
    return {
        bookedOrderIds,
        allSubOrder,
        dealersData,
        fetchDealersListError,
        piProductDetails,
        singlePIDetails,
        allInvoices,
        allLedger,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchBookedOrderIdsFromZunsolarOrders, fetchSubOrdersFromOrderId, fetchDealersData, fetchPiProductDetails, fetchZunsolarPiOrders, requestInvoice, getAllPIInvoiceOrders, updatePIInvoice, downloadFile, getAllPIInvoiceLedger, downloadFileByInvoiceNumber }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarPIInvoice);