import { FETCH_PROCUREMENT_VENDORS, FETCH_PROCUREMENT_LEDGER, FETCH_PROCUREMENT_PAYMENTS, FETCH_PROCUREMENT_PAYMENTS_BY_ID, FETCH_PROCUREMENT_MAPPING, FETCH_SUPPLY_DETAILS } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_PROCUREMENT_VENDORS: {
            return { ...state, vendorList: action.payload || [] }
        }
        case FETCH_PROCUREMENT_LEDGER: {
            return { ...state, ledgerData: action.payload || [] }
        }
        case FETCH_PROCUREMENT_PAYMENTS: {
            return { ...state, paymentsData: action.payload || [] }
        }
        case FETCH_PROCUREMENT_PAYMENTS_BY_ID: {
            return { ...state, paymentsDataByOrderId: action.payload || [] }
        }
        case FETCH_PROCUREMENT_MAPPING: {
            return { ...state, procurementMapping: action.payload || {} }
        }
        case FETCH_SUPPLY_DETAILS: {
            return { ...state, supplyData: action.payload || {} }
        }
        default:
            return state;
    }
}