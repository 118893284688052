import React from 'react';
import { fetchBitrixId, addComments, editLead } from '../actions/fetchBitrixId';
import { fetchAllMapping } from '../actions/fetchAllMapping';
import { getSearchResult } from '../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../components/HeaderComponent';
import { connect } from 'react-redux';
import LeadView from '../components/leadsView';


class fetchLead extends Component {

  componentWillMount() {
    let viewList = ["stages", "employees", "saslots", "regions", "source", "stateregion", "languages", "reasonforverified", "pincodes"]
    this.props.fetchBitrixId(this.props.match.params.id);
    // console.log("this.props.match", this.props.match.params.id);
    this.props.fetchAllMapping(viewList);
  }

  render() {
    if (this.props.leadData) {
      return (
        <div style={{ backgroundColor: "#e6ffe9" }}>
          <Header getSearchResult={this.props.getSearchResult} />
          <LeadView leadDetails={this.props.leadData} addComments={this.props.addComments} editLead={this.props.editLead} allMapping={this.props.allMapping} fetchBitrixId={this.props.fetchBitrixId} />
        </div>
      )
    } else {
      return <p>this is not found</p>
    }
  }
}

function mapStateToProps(state) {
  // console.log("fetchlead", state.fetchlead)
  return {
    leadData: state.fetchlead ? state.fetchlead : '',
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    searchData: state.searchResults ? state.searchResults : []
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchBitrixId, addComments, editLead, fetchAllMapping, getSearchResult }, dispatch)
}

function isLeadData() {
  return (
    <p>this is not found</p>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(fetchLead)