import { FETCH_BOOKED_ORDERIDS_FROM_ZUNSOLAR_ORDERS, FETCH_SUB_ORDER_DATA_FROM_ORDER_ID, FETCH_ALL_INVOICE_ORDERS, FETCH_ALL_INVOICE_LEDGER } from '../actions/actiontypes';
export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_BOOKED_ORDERIDS_FROM_ZUNSOLAR_ORDERS: {
            return { ...state, bookedOrderIds: action.payload };
        }
        case FETCH_SUB_ORDER_DATA_FROM_ORDER_ID:{
            return { ...state, allSubOrder:action.payload };
        }
        case FETCH_ALL_INVOICE_ORDERS:{
            return { ...state, allInvoices:action.payload };
        }
        case FETCH_ALL_INVOICE_LEDGER:{
            return {
                ...state, allLedger:action.payload
            };
        }
        default:
            return state;
    }
}
