import { FETCH_METRICS_DATA } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_METRICS_DATA: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
}
