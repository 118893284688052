import { connect } from "react-redux";
import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { fetchMTPayment, fetchMTSearchVisits } from '../../actions/fetchMTActions'
import { fetchAllMapping ,fetchMappingZunsolar} from "../../actions/fetchAllMapping";
import '../styles/paymentSummary.css'
import Select from 'react-select';
import eyeView from '../../assets/eyeView.png'
import message from '../../assets/message.png'
import {fetchAllLeads} from '../../actions/fetchAllLeads';
import MobileHeaderComponent from "../MobileHeaderComponent";
import HeaderComponent from "../HeaderComponent";


class MTSearchVisitView extends Component {

    constructor(props) {

        super(props)

        this.state = {
            filters: {
                responsible_person:[]
            },

            mtSearchVisitsFilteredData: [],
            mtSearchVisitsOriginalData: [],
            userObj: {},
            username:{},
            limit:100,
            page:1,
            optionsMapping: {
            leadType: [{ value: "Retailer", label: "Retailer" }, { value: "Distributor", label: "Distributor" }, { value: "C&F", label: "C&F" }, { value: "B2G", label: "B2G" }, { value: "Cash & Carry", label: "Cash & Carry" }],

            },
            allLeads:[],
            count:0,
            statusMapping: [{ label: "all", value: "all" }, { label: "cleared", value: "cleared" }, { label: "uncleared", value: "uncleared" }]
        }
    }

    componentWillMount() {
        let userObj = JSON.parse(localStorage.getItem("userObj"))
        let username = userObj.username.split('@')[0].split('.')[0].toLowerCase() + ' ' + userObj.username.split('@')[0].split('.')[1].toLowerCase() || ""
       
        console.log('username',username);
        let {filters, limit,page} = this.state;
        filters.responsible_person = [username]

        this.setState({
            userObj,
            username,
            filters
        })

        this.props.fetchMTSearchVisits(userObj.email)
        let viewList = ["employees"]
        this.props.fetchAllMapping(viewList);
        this.props.fetchAllLeads(limit, page, [], filters, 'zunsolar');
        this.props.fetchMappingZunsolar();

    }

    handleRedirectMTAdd = (solarId)=>{
        window.open(window.location.origin+"/mt/addVisit/"+solarId,"_self")

    }
    handleRedirectMTView = (solarId, leadType)=>{
        console.log('leadType', leadType);
        let distributors = ["Distributor","Distributor ZP","Distributor ZV"]
        if(distributors.includes(leadType)){

            window.open(window.location.origin+"/mt/distributorLeadDetails/"+solarId,"_self")
        }
        
        window.open(window.location.origin+"/mt/dealerLeadDetails/"+solarId,"_self")

    }
    componentWillReceiveProps(props) {
        let { allMapping, mtSearchVisits,allLeads={} } = props
        console.log('allLeads',allLeads);
        let leadData = allLeads.data;
        let count = allLeads.count || 0;
        let {optionsMapping} = this.state
        let employees = this.optionFunction(props.allMapping && props.allMapping.employees ? props.allMapping.employees : [], "e_name")
        let states = this.optionFunction(props.allMapping.stateCityMap && props.allMapping.stateCityMap ? Object.keys(props.allMapping.stateCityMap) : [], "langauge_name");
      console.log('leadData',leadData);
        optionsMapping.employees = employees;
        optionsMapping.states = states

        this.setState({
            // paymentHistoryFilteredData: mtPaymentHistory,
            mtSearchVisitsOriginalData: mtSearchVisits,
            mtSearchVisitsFilteredData: mtSearchVisits,
            optionsMapping,
            allLeads:leadData,
            count:count
        })
    }
    handleMultiSelectChange = (e, name) => {
        // console.log('e',e);
        // return;
        let { value } = e;
        let { filters } = this.state;
        filters = JSON.parse(JSON.stringify(filters))
        filters[name] = value;
        this.setState({
            filters
        })
    }
    handleFilterInputChange = (e) => {
        let { name, value } = e.target;
        let { filters } = this.state;
        filters = JSON.parse(JSON.stringify(filters))
        filters[name] = value;
        this.setState({
            filters
        })
    }

    multiSelectType = (name, options, placeholder) => {

        return (
            <div className="form-group" style={{ width: '70%',paddingLeft:'10px', marginBottom:0 }}>
                <Select
                    isMulti= {false}
                    isSearchable
                    onChange={(e) => { this.handleMultiSelectChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                    id={name}
                />
            </div>
        )
    }
    handleSelectChange = (e, name) => {
        let { value } = e;
        let { filters } = this.state;
        filters = JSON.parse(JSON.stringify(filters))
        filters[name] = value;
        this.setState({
            filters
        })
    }

    inputTypeFilter = (name, value, type, placeholder, disabled= false) => {
        return <input type={type} disabled= {disabled} placeholder={placeholder} style={{ padding: "8px 8px", border: "1px solid #ced4da" }} className="form-control" name={name} value={value} onChange={(e) => this.handleFilterInputChange(e)} />
    }


    optionFunction = (array, value) => {
        let multiSelectOptionsMap = array.map((data) => {
            let obj = {}

            if (data[value]) {
                obj = {
                    label: data[value].replace(/(^"|"$)/g, ''),
                    value: data[value].replace(/(^"|"$)/g, '')
                }
            } else {
                obj = {
                    label: data,
                    value: data
                }
            }
            return obj
        })
        return multiSelectOptionsMap;
    }

    optionFunctionState = (array) => {
        let multiSelectOptionsStateMap = array.map((val) => {
            let obj = {
                label: val.replace(/(^"|"$)/g, ''),
                value: val.replace(/(^"|"$)/g, ''),
            }
            return obj;
        })
        return multiSelectOptionsStateMap
    }
    InputTypeText = (name, value, type) => {
        return (
            <input type={type} name={name} value={value} className="form-control" style={{ border: "1px solid #ced4da" }} onChange={(e) => this.handleFilterInputChange(e)} ></input>
        )
    }
    inputType = (name, placeholder, type) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    {type == "date" && <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>}
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => this.handleFilterInputChange(e)} />
                </div>
            </div>
        )
    }

    handleNextClick = ()=>{
        let {filters, limit,page, allLeads} = this.state;
         this.props.fetchAllLeads(limit, page + 1, allLeads, filters, 'zunsolar')
        
        this.setState({
            page:page+1
        })


    }

    handleLastClick = ()=>{
        let {filters, limit,page, allLeads} = this.state;
        this.props.fetchAllLeads(limit, page -1, allLeads, filters, 'zunsolar')
       
       this.setState({
           page:page-1
       })
    }

    onApplyFilter = () => {
        let { filters,  allLeads, limit, page } = this.state;
        console.log('filters', filters);
        // return
        let state = filters.state;
        let newFilters = {...filters};
        // delete newFilters.responsible_person;
        if(newFilters.solarId){
            newFilters.solarId = [newFilters.solarId]
        }
        if(newFilters.state){
            newFilters.state = [newFilters.state]
        }
        this.props.fetchAllLeads(limit, page, [], newFilters, 'zunsolar');

       
    }


    SelectType = ({ label, name, options, placeholder, disabled, required = false }) => {
        return (
            <div
                className="formgroupContainer"
            >
                <label>{label}</label>
                <div style={{ width: "80%" }}>
                    <Select
                        isSearchable
                        onChange={(e) => { this.handleSelectChange(e, name) }}
                        placeholder={placeholder}
                        name={name}
                        options={options}
                        isDisabled={disabled}
                        required={required}
                        id={name}

                    />

                </div>
            </div>
        )
    }

    render() {
        const { mtSearchVisitsOriginalData, mtSearchVisitsFilteredData, filters, statusMapping,optionsMapping,allLeads } = this.state
        let { SelectType } = this
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        const mystyle = {
            color: "#000",
            backgroundColor: "#e7e9eb",
          };
          
        return (
            <div>
                <HeaderComponent/>
            <div className="mainPaymentHistory">
            <div className="topHeadContainer">
                    <h5>All Leads</h5>

                </div>
                <div className="paymentHistoryFilter">
                    <div className="filter-ctn-modal">

                        <div className="ftn-res-ctn" >
                            <label>Responsible</label>
                            {/* {this.multiSelectType("responsiblePerson", optionsMapping.employees, "Responsible")} */}
                            {this.inputTypeFilter("responsible_person", filters.responsible_person[0], "text", 'responsible_person', true)}

                        </div>



                        <div className="" style={{display:'flex', alignItems:'center', justifyContent:'space-between'}} >
                            <label style={{paddingLeft:'4px'}} >State</label>
                            {this.multiSelectType("state", optionsMapping.states, "State")}
                        </div>
                        <div className="ftn-res-ctn" >
                            <label>Lead Id</label>
                            {/* {this.multiSelectType("responsiblePerson", optionsMapping.employees, "Responsible")} */}
                            {this.inputTypeFilter("solarId", filters.solarId, "text", 'lead Id')}

                        </div>
                        {/* <div className="ftn-res-ctn" >
                            <label>Lead Id</label>
                            {this.inputTypeFilter("solarId", filters.solarId, "text", 'lead Id')}

                        </div> */}

                        {/* <div className="ftn-res-ctn" >
                            <label>Type</label>
                            {this.multiSelectType("leadType", optionsMapping.leadType, "Type")}
                        </div> */}

                        <div style={{display:"flex", justifyContent:'flex-end', marginTop:'2vw'}}>
                        <button style={{width:'40%'}} onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>



                </div>
                <div className="searchVisitContainer">
                {
                   allLeads && allLeads.map((lead, index) => (
                        <div className="searched-detailed" key={index}>

                            <div className="specifiec-ctn" >
                                <p>Dealer Id :</p>
                                <p className="rightValue">{lead.solarId + lead.companyName}</p>
                            </div>

                            <div className="specifiec-ctn" style={mystyle}>
                                <p>Name :</p>
                                <p className="rightValue">{lead.name}</p>
                            </div>

                            <div className="specifiec-ctn">
                                <p>phone :</p>
                                <p className="rightValue">{lead.phoneNumber}</p>
                            </div>

                            <div className="specifiec-ctn" style={mystyle}>
                                <p>Address :</p>
                                <p className="rightValue">{lead.address}</p>
                            </div>

                            <div className="specifiec-ctn">
                                <p>Responsible :</p>
                                <p className="rightValue">{lead.responsible_person}</p>
                            </div>

                            <div className="specifiec-ctn" style={mystyle}>
                                <p>location :</p>
                                <p className="rightValue">{lead.pincode}</p>
                            </div>

                            <div className="visit-view-ctn">

                                <div className="icon-name-ctn" onClick={(e) => { this.handleRedirectMTAdd(lead.solarId ) }}>
                                    <img src={message} alt="mesage-icon" />
                                    <p>visit</p>
                                </div>

                                <div onClick={(e) => { this.handleRedirectMTView(lead.solarId, lead.leadType ) }} className="icon-name-ctn">
                                    <img src={eyeView} alt="view-icon" />
                                    <p>view</p>
                                </div>
                            </div>

                        </div>
                    ))
                }
                </div>
                <div className="paginationVisit">
                    {/* <p onClick={()=>this.handleLastClick()}>Last</p> */}
                    <p onClick={()=>this.handleNextClick()} >Next</p>
                </div>
                <div>

                </div>

            </div>
            </div>
        )

    }
}
function mapStateToProps(state) {
    let { mtPaymentHistory = [], mtSearchVisits=[] } = state.MTVisitReducer;

    return {
        allLeads: state.allFetchLead ? state.allFetchLead : {},
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
        searchData: [],
        error: state.allFetchLead.error ? state.allFetchLead.error : undefined,
        mtPaymentHistory: mtPaymentHistory,
        mtSearchVisits: mtSearchVisits
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMTPayment, fetchAllMapping, fetchMTSearchVisits,fetchAllLeads ,fetchMappingZunsolar}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MTSearchVisitView);