import { FETCH_ZUNSOLAR_INVENTORY_SUCCESS, FETCH_ZUNSOLAR_ORDERTRACKER_MAPPING_SUCCESS, FETCH_ZUNSOLAR_COMBO_SUCCESS, FETCH_ZUNSOLAR_ORDERS_SUCCESS, FETCH_ZUNSOLAR_ORDERDATA_SUCCESS, FETCH_ZUNSOLAR_SUBORDERS_SUCCESS, FETCH_ZUNSOLAR_ORDER_COMMENTS_SUCCESS, FETCH_ZUNSOLAR_SUBORDERDATA_SUCCESS, FETCH_ZUNSOLAR_COMBOLOTS_SUCCESS, FETCH_ZUNSOLAR_WARRANTY_DETAILS_SUCCESS, FETCH_ZUNSOLAR_ORDERS_INVOICES_SUCCESS, FETCH_ZUNSOLAR_ORDER_HISTORY_SUCCESS, FETCH_ALL_RETURN_ORDERS, FETCH_SUB_ORDER_DATA_FOR_RETURN } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ZUNSOLAR_INVENTORY_SUCCESS: {
            return { ...state, inventoryData: [...action.payload], inventoryDataReceived: true };
        }
        case FETCH_ZUNSOLAR_ORDERTRACKER_MAPPING_SUCCESS: {
            return { ...state, mapping: { ...action.payload } };
        }
        case FETCH_ZUNSOLAR_COMBO_SUCCESS: {
            return { ...state, comboList: [...action.payload] };
        }
        case FETCH_ZUNSOLAR_ORDERS_SUCCESS: {
            return { ...state, allOrders: [...action.payload], orderDataReceived: true };
        }
        case FETCH_ZUNSOLAR_ORDERDATA_SUCCESS: {
            return { ...state, orderData: { ...action.payload } }
        }
        case FETCH_ZUNSOLAR_SUBORDERS_SUCCESS: {
            return { ...state, subOrders: [...action.payload] }
        }
        case FETCH_ZUNSOLAR_ORDER_COMMENTS_SUCCESS: {
            return { ...state, comments: [...action.payload] }
        }
        case FETCH_ZUNSOLAR_ORDER_HISTORY_SUCCESS: {
            return { ...state, historyData: [...action.payload] }
        }
        case FETCH_ZUNSOLAR_SUBORDERDATA_SUCCESS: {
            return { ...state, subOrderData: { ...action.payload } }
        }
        case FETCH_ZUNSOLAR_COMBOLOTS_SUCCESS: {
            return { ...state, comboLots: [...action.payload] }
        }
        case FETCH_ZUNSOLAR_WARRANTY_DETAILS_SUCCESS: {
            return { ...state, warrantyDetails: [...action.payload] }
        }
        case FETCH_ZUNSOLAR_ORDERS_INVOICES_SUCCESS: {
            return { ...state, ordersInvoices: action.payload || [], ordersDataReceived:true };
        }
        case FETCH_ALL_RETURN_ORDERS:{
            return {...state, returnOrders: [...action.payload], returnOrdersReceived: true};
        }
        case FETCH_SUB_ORDER_DATA_FOR_RETURN:{
            return {...state, returnSubOrders: action.payload || {}}
        }
        default:
            return state;
    }
}