import {CREATE_NEW_PASSWORD_ERROR,CREATE_NEW_PASSWORD_SUCCESS} from '../actions/actiontypes';

export default function(state='',action){
  switch(action.type){
    case CREATE_NEW_PASSWORD_ERROR : {
      return {...state,error:action.payload,success:undefined};
    }
    case CREATE_NEW_PASSWORD_SUCCESS : {
      return {...state,success:action.payload,error:undefined}
    }
    default:
			return state;
  }
}