import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import ReactTable from "react-table";
import { Loader } from '../../components/loader'
import Modal from 'react-responsive-modal';
import moment from 'moment';
import '../../styles/leadsView.css';
import { Link } from 'react-router-dom';
import { fetchZunsolarPiOrders, changeAproovalStatus, savePIComment, getAllPIComment, downloadOrderFile,uploadInvoice, downloadInvoice, updateLogisticDetail, deletePi } from '../../actions/zunsolarPiPortalActions'
import Select from 'react-select';


class zunsolarPIOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewData: [],
            originalData :[],
            pIOrderEditFlag: {},
            editedPIOrderData: {},
            mapping: {
                status: ['approved', 'rejected'],
                approvedBy:[],
                createdBy:[],
                type:[]
            },
            showSingleView: false,
            singlePIData: {},
            comments: [],
            newComment: '',
            savecommentBeforeRejectModal: {
                showModal: false
            },
            KeysCanBeChanged: ['approval_status','bookingDate'],
            newDataToSend: {},
            invoiceData:{},
            formData:{},
            showFilter: false,
            filters: {},
           
        }
    }

    componentWillMount() {
        this.props.fetchZunsolarPiOrders();
    }
   

    componentWillReceiveProps({ piOrdersData, singlePIDetails, allCommentsSinglePI }) {
        let userObj = localStorage.getItem("userObj")?JSON.parse(localStorage.getItem("userObj")):{}
        let {mapping} = this.state
        let createdBySet = new Set();
        let approvedBySet = new Set();
        let createdBy = [];
        let approvedBy = [];
        let createdByMapping = [];
        let approvedByMapping = [];
        let types = ["Dealer", "Distributor", "Partner","DC Challan","Credit Note","B2B","Consultative Sales"]
        let typeMapping = []
        types.forEach((type)=>{
            let obj = {label:type, value:type};
            typeMapping.push(obj)
        })

        piOrdersData = piOrdersData.filter((piData)=>{
            return piData.isActive==1;
        })
        

        piOrdersData.forEach((data)=>{
            createdBySet.add(data.createdBy);
            approvedBySet.add(data.action_performed_by);
        })
        createdBy = [...createdBySet]
        approvedBy = [...approvedBySet];
        createdBy.forEach((email)=>{
            if(email){
          let labelData  =   email.split('@')[0].split('.')[0] + ' ' + email.split('@')[0].split('.')[1];
          let obj = {label:labelData, value:email};
          createdByMapping.push(obj);
            }

        })
        approvedBy.forEach((email)=>{
            if(email){
            let labelData  =   email.split('@')[0].split('.')[0] + ' ' + email.split('@')[0].split('.')[1];
            let obj = {label:labelData, value:email};
            approvedByMapping.push(obj);
            }
  
          })
  

        //   console.log('mappin', approvedByMapping, createdByMapping);


        this.setState({
            viewData: piOrdersData,
            originalData:piOrdersData,
            singlePIData: singlePIDetails,
            comments: allCommentsSinglePI,
            userObj,
            mapping:{
                ...mapping,
                approvedBy:approvedByMapping,
                createdBy:createdByMapping,
                type:typeMapping
            }
        })
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }
    handleEditPIOrderClick = (row) => {
        let { piNumber } = row;
        this.setState({
            pIOrderEditFlag: {
                ...this.state.pIOrderEditFlag,
                [piNumber]: true
            },
            editedPIOrderData: {
                ...this.state.editedPIOrderData,
                [piNumber]: row
            }
        })
    }

    handleFileChange = (e) => {
        let files = e.target.files[0]
        let { name, value } = e.target;
        console.log('name',name, files);
       this.setState({
        invoiceData:{
        ...this.state.invoiceData,
        [name]: files
    }
       })
    }

   


    InputTypeText = (name, value, type ) => {
        console.log('data',name, type );
        return (
            <input type={type} name ={name} value = {value} className="form-control"  onChange={(e) => this.handleInputChange(e)} ></input>
        )
    }
    handleInputChange = (e) => {
        let { name, value } = e.target;
        console.log('name', name, value);
        let {formData} = this.state;
        // formData = JSON.parse(JSON.stringify(formData));
        // formData[name]=value
        this.setState({
            formData:{
                ...this.state.formData,
                [name]:value
            }
        })
    }
    FileTypeInput = (name, type, required ) => {
        return (
            <input type={type} className="form-control" name={name} accept="application/pdf" onChange={(e) => this.handleFileChange(e)} required={required}></input>
        )
    }

    
    handleCancelPIOrderClick = (row) => {
        let { piNumber } = row.original;
        this.setState({
            pIOrderEditFlag: {
                ...this.state.pIOrderEditFlag,
                [piNumber]: false
            }
        })
    }

    handleSavePIOrderClick = (row) => {
        let { piNumber } = row.original;
        let { editedPIOrderData, viewData, KeysCanBeChanged, invoiceData, formData } = this.state
        let email = this.state.userObj.email;
        if(formData && Object.keys(formData).length ||  invoiceData && Object.keys(invoiceData) && Object.keys(invoiceData).length>0){
            const apiFromData = new FormData();
            for(let key in formData){
                apiFromData.append(key, formData[key])
            }
            for(let key in invoiceData){
                apiFromData.append(key,invoiceData[key])
            }  
            apiFromData.append("piNumber", piNumber)
            apiFromData.append('email', this.state.userObj.email )
            apiFromData.append("approvalStatus", editedPIOrderData[piNumber].approval_status)
            this.props.uploadInvoice(apiFromData);
           
        }
        // if(invoiceData && Object.keys(invoiceData) && Object.keys(invoiceData).length>0){
        //     const formData = new FormData();
        //     if(invoiceData.hasOwnProperty("eInvoice")){
        //         formData.append("fileType", "eInvoice");
        //         formData.append("files", invoiceData["eInvoice"])
        //     }
        //     else if(invoiceData.hasOwnProperty("eWayBill")){
        //         formData.append("fileType", "eWayBill");
        //         formData.append("files", invoiceData["eWayBill"])
        //     }
        //     formData.append("piNumber", piNumber)
        //     formData.append('email', this.state.userObj.email )
        //     console.log(' editedPIOrderData[piNumber]',  editedPIOrderData[piNumber]);
        //     formData.append("approvalStatus", editedPIOrderData[piNumber].approval_status)
        //     this.props.uploadInvoice(formData);

        // }
        
    
        else {
            console.log('inside else');
        let newData = editedPIOrderData[piNumber] || {};
        let newDataToSend = {}
        console.log('KeysCanBeChanged',KeysCanBeChanged);
        KeysCanBeChanged.map((key) => {
            if(newData[key] != row.original[key]){
                newDataToSend[key] = newData[key]
            }
        })
        console.log(newDataToSend,Object.keys(newDataToSend).length > 0)
        if (Object.keys(newDataToSend).length > 0) {
            if(newDataToSend.approval_status && newDataToSend.approval_status == 'rejected'){
                this.setState({
                    savecommentBeforeRejectModal: {
                        'piNumber': piNumber,
                        'showModal': true
                    },
                    comments: [],
                    newComment: '',
                    newDataToSend
                })
                this.props.getAllPIComment(row.original.piNumber)
                return;
            }
            this.props.changeAproovalStatus(piNumber, newDataToSend, viewData)
        }
    }
        this.setState({
            pIOrderEditFlag: {
                ...this.state.pIOrderEditFlag,
                [piNumber]: false
            }
        })
    }

    handleSavePIOrderClickRejected = () => {
        let { savecommentBeforeRejectModal, newComment, viewData, newDataToSend } = this.state
        newDataToSend['comment'] = newComment
        this.props.changeAproovalStatus(savecommentBeforeRejectModal.piNumber, newDataToSend, viewData)
        this.setState({
            savecommentBeforeRejectModal: {
                'piNumber': '',
                'showModal': false
            },
            comments: [],
            newComment: '',
            pIOrderEditFlag: {
                ...this.state.pIOrderEditFlag,
                [savecommentBeforeRejectModal.piNumber]: false
            }
        })
    }

    pIOrderSelect = (name, value, options, row) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handlePIOrderChange(e, row) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    pIOrderInput = (name, value, type, row) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handlePIOrderChange(e, row)} />
    }

    handlePIOrderChange = (e, row) => {
        let { name, value } = e.target;
        let { piNumber } = row.original;
        let { editedPIOrderData } = this.state;
        editedPIOrderData = JSON.parse(JSON.stringify(editedPIOrderData))
        editedPIOrderData[piNumber][name] = value;
        this.setState({
            editedPIOrderData
        })
    }

    downloadFile = (row, fileType=0) => {
        this.props.downloadOrderFile(row.original.piNumber, fileType);
    }

    downloadInvoiceFile = (path, fileType)=>{
        this.props.downloadInvoice(path, fileType)

    }
    handleDeletePI = (piNumber)=>{
        this.props.deletePi(piNumber,this.state.viewData,this.state.userObj.email)

    }
    showSinglePIView = (row) => {
        this.setState({
            showSingleView: true,
            singlePIData: {},
            comments: [],
            newComment: ''
        }, () => {
            this.props.fetchZunsolarPiOrders(row.original.piNumber)
            this.props.getAllPIComment(row.original.piNumber)
        })
    }

    redirectToDealer = (dealerId)=>{
        window.open(`http://zrm.zunroof.com/zunsolar/details/${dealerId}`, "_blank")
    }
    closeSinglePIView = () => {
        this.setState({
            showSingleView: false,
            singlePIData: {},
            comments: [],
            newComment: ''
        })
    }

    saveComment = (piNumber, comment) => {
        let comments = [...this.state.comments];
        let newCommentData = {
            commentedBy: JSON.parse(localStorage.getItem("userObj")).username || '',
            commentBody: comment,
            timestamp: new Date().getTime()
        };
        comments.push(newCommentData);
        this.props.savePIComment(piNumber, comment, comments)
        this.setState({
            newComment: ''
        })
    }

    onCommentChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            newComment: value
        })
    }

    closeSavecommentBeforeRejectModal = (e) => {
        this.setState({
            savecommentBeforeRejectModal: {
                showModal: false
            },
            comments: [],
            newComment: ''
        })
    }
    renderInputCell = (row) => {
        let { id, type } = row.column;
        let {pIOrderEditFlag} = this.state
        return (
                pIOrderEditFlag[row.original.piNumber]  && row.original.approval_status=="approved" && !row.value ? this.InputTypeText( id,  this.state.formData[id] , type, false): row.value
        )
    }
    multiSelectType = (name, options, placeholder, onChange) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }
    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    onApplyFilter = (e) => {
        let { filters } = this.state
        // console.log('filters', filters);
        let {originalData} = this.state
        // console.log("originalData",this.state.originalData)
        let filteredData = [];
        let filtersKey = Object.keys(filters);
        if(!filtersKey || filtersKey.length ==0 ){
            this.setState({
                viewData:originalData
            })
            return;
        }
        
        filteredData = originalData.filter((data)=>{
         return   filtersKey.every((key)=>{
            if(key!="createdBy" & key!="approvedBy"){
                console.log('check key', key, data[key],filters[key]);
                if(filters[key]){
                return data[key] == filters[key];
                }

            }
            else{
                if(key=="createdBy"){
                    return filters[key].includes(data.createdBy);

                }
                else if(key=="approvedBy"){
                    return filters[key].includes(data.action_performed_by);

                }
            }


            })
        })

        // console.log('filteredData', filteredData);
        this.setState({
            viewData:filteredData
        })
    }
    handleInputChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        // filters[type] = filters[type] || {};
        filters[name] = value;

        this.setState({
            filters
        })
    }

    handleSelectChangeFilters = (e, name) => {
        // console.log('data', e, name);
        let value = (e || []).map(option => option.value);
        // console.log('values', value);
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    render() {
        let { props = {}, state = {}, handleEditPIOrderClick, handleCancelPIOrderClick, handleSavePIOrderClick, downloadFile, downloadInvoiceFile, renderInputCell } = this;
        let { viewData, pIOrderEditFlag, editedPIOrderData, mapping, showSingleView } = state;
        let pIOrderColumns = [
            {
                Header: 'Generated On',
                accessor: 'date',
                Cell: row => (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
            },
            {
                Header: 'Approved/Rejected On',
                accessor: 'action_performed_date',
                Cell: row => (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
            },
            {
                Header: row => {
                    let { data } = row;
                    let total = data.reduce((a, b) => a + 1, 0)
                    return (`PI Num (${total})`)
                },
                accessor: 'piNumber',
                Cell: row => (<div style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => this.showSinglePIView(row)}>{row.value}</div>)
            },
            {
                Header: 'Dealer ID',
                accessor: 'dealerId',
                Cell: row =>(<div style={{ color: 'blue', cursor: 'pointer' }} onClick={()=>this.redirectToDealer(row.value)}>{row.value}</div>)
            },
            {
                Header: 'Dealer Name',
                accessor: 'dealerName',
                Cell: row =>(<div style={{ color: 'blue', cursor: 'pointer' }} onClick={()=>this.redirectToDealer(row.original.dealerId)}>{row.value}</div>)

            },
            {
                Header: row => {
                    let { data } = row;
                    let total = data.reduce((a, b) => a + (b.grandTotal && !isNaN(b.grandTotal) ? parseInt(b.grandTotal) : 0), 0);
                    return `Grand Total (${total})`;
                },
                accessor: 'grandTotal'
            },
            {
                Header: 'Ext Comment',
                accessor: 'externalComment'
            },
            {
                Header: 'Int Comment',
                accessor: 'internalComment'
            },
            {
                Header: 'Booking Date',
                accessor: 'bookingDate',
                Cell: row => {
                    return (
                        !pIOrderEditFlag[row.original.piNumber] ? row.value : this.pIOrderInput("bookingDate", editedPIOrderData[row.original.piNumber]["bookingDate"], 'date', row)
                    )
                }
            },
            {
                Header: 'Logistic Partner',
                accessor: 'logisticPartner',
                type:"text",
                Cell: renderInputCell
            },
            {
                Header: 'Vehicle Number',
                accessor: 'vehicleNumber',
                type:"text",
                Cell: renderInputCell
            },
            {
                Header: 'Created By',
                accessor: 'createdBy',
                Cell: row => {
                    return row.value ? row.value.split('@')[0].split('.')[0] + ' ' + row.value.split('@')[0].split('.')[1] : ""
                }
            },
            {
                Header: 'Approval status',
                accessor: 'approval_status',
                Cell: row => {
                    return (
                        !pIOrderEditFlag[row.original.piNumber] || row.value ? row.value : this.pIOrderSelect("approval_status", editedPIOrderData[row.original.piNumber]["approval_status"], mapping.status, row)
                    )
                }
            },
           
            {
                Header: 'Approved By',
                accessor: 'action_performed_by',
                Cell: row => {
                    return row.value ? row.value.split('@')[0].split('.')[0] + ' ' + row.value.split('@')[0].split('.')[1] : ""
                }
            },
            {
                Header: 'Upload E-Invoice',
                accessor: 'approval_status',
                Cell: row => {

                    return (
                        pIOrderEditFlag[row.original.piNumber] ? row.value=="approved" ?  this.FileTypeInput("eInvoice", "file", false) :   "":"")
                },
                minWidth: 180
            },  
            {
                Header: 'Upload E-Way Bill',
                accessor: 'approval_status',
                Cell: row => {
                    return (
                        pIOrderEditFlag[row.original.piNumber] ? row.value=="approved" ?  this.FileTypeInput("eWayBill", "file", false) :   "":"")
                },
                minWidth: 150

            },  
            {
                Header: 'Upload Credit Note',
                accessor: 'approval_status',
                Cell: row => {
                    return (
                        pIOrderEditFlag[row.original.piNumber] ? row.value=="approved" ?  this.FileTypeInput("creditNotePath", "file", false) :   "":"")
                },
                minWidth: 150

            },  
            {
                Header: 'Upload GST Challan',
                accessor: 'approval_status',
                Cell: row => {
                    return (
                        pIOrderEditFlag[row.original.piNumber] ? row.value=="approved" ?  this.FileTypeInput("gstChallan", "file", false) :   "":"")
                },
                minWidth: 150

            },  
            // {
            //     Header: "Buttons",
            //     Cell: row => {
            //         return (
            //         pIOrderEditFlag[row.original.piNumber] ? <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-success btn-sm" style={{marginRight: '3px'}} onClick={(e) => handleSavePIOrderClick(row)}>Save</button><button className="btn btn-light btn-sm" onClick={(e) => handleCancelPIOrderClick(row)}>Cancel</button></div> : <div className="row text-center" style={{width: 'fit-content'}}>{((this.state.userObj || {}).access || []).includes("Zunsolar-Admin")?<button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => handleEditPIOrderClick(row.original)}>Edit</button>:''}<button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => downloadFile(row)}>Download</button><Link to={`/zunsolar/pi/generate?pi_number=${row.original.piNumber}`} target="_blank"><button className="btn btn-info btn-sm">Copy PI</button></Link>{row.original.piCSVPath?<button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => downloadFile(row, 1)}>Download CSV</button>:""}</div>
            //         )
            //     },
            //     minWidth: 150
            // },
            {
                Header: 'Type',
                accessor: 'type'
            },
            {
                Header: "Edit",
                Cell: row => {
                    return (
                    pIOrderEditFlag[row.original.piNumber] ? <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-success btn-sm" style={{marginRight: '3px'}} onClick={(e) => handleSavePIOrderClick(row)}>Save</button><button className="btn btn-light btn-sm" onClick={(e) => handleCancelPIOrderClick(row)}>Cancel</button></div> : <div className="row text-center" style={{width: 'fit-content'}}>{((this.state.userObj || {}).access || []).includes("Zunsolar-Admin")?<button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => handleEditPIOrderClick(row.original)}>Edit</button>:''}</div>
                    )
                },
                minWidth: 150
            },
            {
                Header: "Download",
                Cell: row => {
                    return (
                        <button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => downloadFile(row)}>Download</button>
                    )
                },
                minWidth: 150
            },
            {
                Header: "Copy PI",
                Cell: row => {
                    return (
                        <Link to={`/zunsolar/pi/generate?pi_number=${row.original.piNumber}`} target="_blank"><button className="btn btn-info btn-sm">Copy</button></Link>
                    )
                },
                minWidth: 150
            },
            {
                Header: "Download CSV",
                Cell: row => {
                    return (
                        <div>{row.original.piCSVPath?<button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => downloadFile(row, 1)}>Download CSV</button>:""}</div>
                    )
                },
                minWidth: 150
            },
            {
                Header: "E-Invoice",
                accessor:'eInvoice',
                Cell: row => {
                    return (
                        row.original.eInvoice  &&  <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => downloadInvoiceFile(row.original.eInvoice, 'eInvoice')}>Download</button></div>
                    )
                },
                minWidth: 150
            },
            {
                Header: "E-Way Bill",
                accessor:'eWayBill',
                Cell: row => {
                    return (
                       row.original.eWayBill &&  <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => downloadInvoiceFile(row.original.eWayBill, "eWayBill")}>Download</button></div>
                    )
                },
                minWidth: 150
            },
            {
                Header: "Download Credit Note",
                accessor:'creditNotePath',
                Cell: row => {
                    return (
                        row.original.creditNotePath  &&  <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => downloadInvoiceFile(row.original.creditNotePath, 'creditNotePath')}>Download</button></div>
                    )
                },
                minWidth: 150
            },
            {
                Header: "Download GST Challan",
                accessor:'gstChallan',
                Cell: row => {
                    return (
                        row.original.gstChallan  &&  <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => downloadInvoiceFile(row.original.gstChallan, 'gstChallan')}>Download</button></div>
                    )
                },
                minWidth: 150
            },
            {
                Header: "Delete PI",
                accessor:'piNumber',
                Cell: row => {
                    return (
                        row.original.approval_status != "approved"  &&  <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => this.handleDeletePI(row.original.piNumber)}>Delete PI</button></div>
                    )
                },
                minWidth: 150
            }
        ]

        let SinglePIOrderProductsColumns = [
            {
                Header: 'Product category',
                accessor: 'category'
            },
            {
                Header: 'Sub category',
                accessor: 'subcategory'
            },
            {
                Header: 'Product Name',
                accessor: 'productName'
            },
            {
                Header: 'Variant',
                accessor: 'variantType'
            },
            {
                Header: 'Description',
                accessor: 'description'
            },
            {
                Header: 'Warranty',
                accessor: 'warranty'
            },
            {
                Header: 'Price (Without GST Without Discount)',
                accessor: 'price'
            },
            {
                Header: 'GST Rate',
                accessor: 'gstRate'
            },
            {
                Header: 'Discount',
                accessor: 'discount'
            },
            {
                Header: 'Price (Without GST After Discount)',
                accessor: 'priceAfterDiscount'
            },
            {
                Header: 'GST Value',
                accessor: 'gstValue'
            },
            {
                Header: 'Price (With GST After Discount)',
                accessor: 'priceAfterDiscountWithGST'
            },
            {
                Header: 'Quantity',
                accessor: 'quantity'
            },
            {
                Header: 'Total GST Value',
                accessor: 'totalGstValue'
            },
            {
                Header: 'Total Price (Without GST After Discount)',
                accessor: 'totalPriceAfterDiscount'
            },
            {
                Header: 'Total Price (With GST After Discount)',
                accessor: 'totalPriceAfterDiscountWithGST'
            }
        ]

        const modalStyles = {
            modal: {
                width: '100%',
                maxWidth: '100%',
                padding: '40px',
                background: '#f7f7f7',
                textAlign: 'center'
            }
        }

        const rejectedModalStyles = {
            modal: {
                width: '50%',
                maxWidth: '50%',
                padding: '40px',
                background: '#f7f7f7',
                textAlign: 'center'
            }
        }
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        

        return (
            <div>
                <Header />
                <Modal open={this.state.savecommentBeforeRejectModal['showModal']} onClose={this.closeSavecommentBeforeRejectModal} styles={rejectedModalStyles} center>
                    <div className="row" style={{ padding: "0px" }}>
                        <div className="topbar-section">
                            <div className="container">
                                <div>
                                    <h4><b>Comments - {this.state.savecommentBeforeRejectModal['piNumber'] || ''}</b></h4>
                                </div>
                                <hr></hr>
                                <div>
                                    <div style={{ textAlign: 'left' }}>
                                        {(this.state.comments || []).map(comment => {
                                            return <div><b>{comment.commentedBy.split('@')[0].split('.')[0] + ' ' + comment.commentedBy.split('@')[0].split('.')[1]} - {moment(+(comment.timestamp)).format('DD/MM/YYYY hh:mm a')}</b> - {comment.commentBody}</div>
                                        })}
                                    </div>
                                    <div className="row" style={{ width: "80%", margin: "0px", padding: "0px" }}>
                                        <div className="col-md-10 form-group"  >
                                            <label for="newComment"></label>
                                            <textarea placeholder="Add New Comment" className="form-control" rows="2" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
                                        </div>
                                        <div style={{ marginTop: "46px", marginLeft: '0px' }} className="col-md-1 form-group">
                                            <button className="btn btn-success" onClick={(e) => this.handleSavePIOrderClickRejected(e)}>Reject</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal open={showSingleView} onClose={this.closeSinglePIView} styles={modalStyles}>
                    <div>
                        <div className="topbar-section">
                            <div>
                                <h4><b>PI</b></h4>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-md-4 customer-info" style={{ borderRight: '0px', textAlign: 'left' }}>
                                    <table>
                                        <tr>
                                            <td>Dealer Id</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.dealerId || ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Dealer Name</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.dealerName || ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Dealer Address</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.dealerAddress || ''}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-md-4 customer-info" style={{ borderRight: '0px', textAlign: 'left' }}>
                                    <table className="customer-info-left">
                                        <tr>
                                            <td>PI Number</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.piNumber || ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PI generated on</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.date && moment(this.state.singlePIData.date).isValid() ? moment(this.state.singlePIData.date).format('DD MMM, YYYY') : ""}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PI approved on</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.approval_status && this.state.singlePIData.approval_status != 'rejected' && this.state.singlePIData.action_performed_date && moment(this.state.singlePIData.action_performed_date).isValid() ? moment(this.state.singlePIData.action_performed_date).format('DD MMM, YYYY') : ""}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-md-4 customer-info">
                                    <table className="customer-info-right" style={{ textAlign: 'left' }}>
                                        <tr>
                                            <td>Approval status</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.approval_status || ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total Price after discount (without GST)</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.totalpriceAfterDiscount || ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Freight</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.freight || ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>GST on Freight(INR)</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.gstFreight || ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total GST(without GST on Freight)</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.totalGst || ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Grand Total (INR) Including GST and Freight After Discount</td>
                                            <td>
                                                <span className="input-group">
                                                    {this.state.singlePIData.grandTotal || ''}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: "0px" }}>
                            <div className="topbar-section">
                                <div>
                                    <h4><b>Product Details</b></h4>
                                </div>
                                <hr></hr>
                                <div>
                                    <ReactTable
                                        filterable={true}
                                        showPagination={true}
                                        data={this.state.singlePIData.products || []}
                                        columns={SinglePIOrderProductsColumns}
                                        ref={this.reactTable}
                                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                        defaultPageSize={5}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="topbar-section col-8">
                                <div className="container">
                                    <div>
                                        <h4><b>Comments</b></h4>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <div style={{ textAlign: 'left' }}>
                                            {(this.state.comments || []).map(comment => {
                                                return <div><b>{comment.commentedBy.split('@')[0].split('.')[0] + ' ' + comment.commentedBy.split('@')[0].split('.')[1]} - {moment(+(comment.timestamp)).format('DD/MM/YYYY hh:mm a')}</b> - {comment.commentBody}</div>
                                            })}
                                        </div>
                                        <div className="row" style={{ width: "80%", margin: "0px", padding: "0px" }}>
                                            <div className="form-group col-md-8" style={{ paddingLeft: "0px" }}>
                                                <label for="newComment"></label>
                                                <textarea placeholder="Add New Comment" className="form-control" rows="2" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
                                            </div>
                                            <div style={{ marginTop: "46px", marginLeft: '0px' }} className="col-md-1 form-group">
                                                <button className="btn btn-success" onClick={(e) => this.saveComment(this.state.singlePIData.piNumber, this.state.newComment)}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                
                <div style={{ "marginTop": 80 }} className="container-fluid">
                <div className="topbar-section allLeadViewFilters">
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye"></i>} </b></div>
                    <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                    <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                        <div className="col-md-3">
                            {this.inputType("piNumber", "PI Number", 'text', this.handleInputChangeFilters, 'piNumber')}
                        </div>
                        <div className="col-md-3">
                            {this.inputType("dealerId", "Dealer Id", 'text', this.handleInputChangeFilters, 'dealerId')}
                        </div>
                        <div className="col-md-3">
                            {this.inputType("dealerName", "Dealer Name", 'text', this.handleInputChangeFilters, 'dealerName')}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("createdBy", this.state.mapping.createdBy, "Created By", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("approvedBy", this.state.mapping.approvedBy, "Approved By", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("type", this.state.mapping.type, "Type", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>
                </div>
                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        data={viewData}
                        columns={pIOrderColumns}
                        ref={this.reactTable}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps({ zunsolarPiPortal }) {
    let { piOrdersData = [], singlePIDetails = {}, allCommentsSinglePI = [] } = zunsolarPiPortal
    return {
        piOrdersData, singlePIDetails, allCommentsSinglePI
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchZunsolarPiOrders, changeAproovalStatus, savePIComment, getAllPIComment, downloadOrderFile, uploadInvoice, downloadInvoice,updateLogisticDetail,deletePi }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(zunsolarPIOrders);