import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from "react";
import zunpulseLogo from '../../assets/zunpulseLogo.svg';
import '../styles/loginPageMobile.css';
// import '../styles/header.css'

export class LoginComponentMobile extends Component {

    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            submitted: '',
            passHidden: true,
            success: '',
            error: ''
        }
    }

    componentWillReceiveProps(props) {
        console.log("insidee22", props.error);
        this.setState({
            error: props.error,
            success: props.success
        })
    }

    handleToggle = (e) => {
        this.setState({
            passHidden: !this.state.passHidden
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.username && this.state.password) {
            let user = {
                username: this.state.username.trim(),
                password: this.state.password.trim()
            }
            console.log(user);
            this.props.login(user)
        }

        this.setState({
            submitted: true
        })
    }

    iconType = () => {
        return (
            <i onClick={(e) => { this.handleToggle() }} style={{ padding: "0px", marginTop: "13px", cursor: "pointer" }} class="fa fa-eye icon" aria-hidden="true"></i>
        )
    }
    blindIconType = () => {
        return (
            <i onClick={(e) => { this.handleToggle() }} style={{ padding: "0px", marginTop: "13px", cursor: "pointer" }} class="fa fa-eye-slash icon" aria-hidden="true"></i>
        )
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    render() {
        const style = {
            error: {
                fontSize: "4vw",
                color: "#e34845",
                marginTop: "1vw "
            },
            success: {
                color: 'green',
                marginTop: '20px'

            }
        }
        return (
            <div className="mainContainer">

                <nav className='navbar navbar-expand-lg navbar-light'>
                    <a className="navbar-brand">
                        <img alt="Zunroof" src={zunpulseLogo} width="60%" />
                    </a>
                </nav>

                <div className='WelcomeContainer'>
                    <h1>Welcome</h1>
                    <p>Hi, please enter your email id as username</p>
                </div>

                {/* <img src={zunpulseLogo} alt="zunpulseLogo" /> */}

                <div className="loginFormContainer">
                    <form onSubmit={this.handleSubmit} name="form" control="">
                        <div className="form-group">
                            <input type="text" name="username" id="username" value={this.state.username} onChange={this.handleChange} className="form-control" placeholder="User name" />
                        </div>

                        <div className="form-group" style={{ display: "flex" }}>
                            <input type={this.state.passHidden ? "password" : "text"} name="password" id="password" value={this.state.password} onChange={this.handleChange} className="form-control" placeholder="Password" />
                            {this.state.password.length > 0 ? (this.state.passHidden ? this.blindIconType() : this.iconType()) : ''}
                        </div>

                        <p style={style.error}>{this.state.error}</p>
                        <p style={style.success}>{this.state.success}</p>
                        <button type="submit" value="Submit" className="loginSubmit">Login</button>
                    </form>
                </div>


            </div>
        )
    }
}