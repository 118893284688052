import React, { Component } from 'react';
import '../styles/createNewPassword.css';
import swal from 'sweetalert';
import {Link} from 'react-router-dom'

export class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passHiddenCurr: true,
      passHiddenNew: true,
      passHiddenConfirm: true,
      oldPass: '',
      newPass: '',
      confirmPass: '',
      error: '',
      success: ''
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      success: props.success,
      error: props.error
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleToggle = (e) => {
    console.log(this.state.passHiddenCurr, e)
    if (e === "new") {
      this.setState({
        passHiddenNew: !this.state.passHiddenNew
      })
    }
    if (e === "confirm") {
      this.setState({
        passHiddenConfirm: !this.state.passHiddenConfirm
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let val1 = this.state.newPass;
    let val2 = this.state.confirmPass
    if (val1 != val2) {
      swal("Confirm password does not match", "Please enter same passwords", "error")
    } else {
      let obj = {
        username: this.state.username,
        password: this.state.newPass,
        resetToken: this.props.token
      }
      console.log(obj)
      this.props.resetPassword(obj)
    }
  }

  iconType = (name) => {
    return (
      <i onClick={(e) => { this.handleToggle(name) }} style={{ padding: "0px", marginTop: "13px", cursor: "pointer" }} class="fa fa-eye icon" aria-hidden="true"></i>
    )
  }
  blindIconType = (name) => {
    return (
      <i onClick={(e) => { this.handleToggle(name) }} style={{ padding: "0px", marginTop: "13px", cursor: "pointer" }} class="fa fa-eye-slash icon" aria-hidden="true"></i>
    )
  }

  render() {
    const style = {
      hide: {
        display: "none"
      },
      show: {
        display: ''
      },
      error: {
        fontSize: "0.938vw",
        color: "#e34845",
        marginTop: "-0.781vw"
      },
      success: {
        fontSize: "0.938vw",
        color: 'green',
        marginTop: "-0.781vw"
      }
    }
    return (
      <div className="row no-gutters">
        <div className="col-md-6 right">
          <h1>Create New Password!</h1>
          <form onSubmit={this.handleSubmit} name="form" control="">
            <div className="form-group">
              <input type="text" name="username" id="username" value={this.state.username} onChange={this.handleChange} className="form-control" placeholder="User name" required />
            </div>
            <div className="form-group" style={{ display: "flex" }}>
              <input type={this.state.passHiddenNew ? "password" : "text"} value={this.state.newPass} id="newPass" onChange={this.handleChange} className="form-control" placeholder="New Password" required />
              {this.state.newPass ? (this.state.passHiddenNew ? this.blindIconType("new") : this.iconType("new")) : ''}
            </div>
            <div className="form-group" style={{ display: "flex" }}>
              <input type={this.state.passHiddenConfirm ? "password" : "text"} value={this.state.confirmPass} title="Password doesnt match" id="confirmPass" onChange={this.handleChange} class="form-control" placeholder="Confirm Password" required />
              {this.state.x ? this.title : ''}
              {this.state.confirmPass ? (this.state.passHiddenConfirm ? this.blindIconType("confirm") : this.iconType("confirm")) : ''}
            </div>
            <p style={style.error}>{this.state.error}</p>
            <p style={style.success}>{this.state.success}<Link style={this.state.success ? style.show : style.hide} to='/login'>Login here</Link></p>

            <button type="submit" value="Submit" className="loginSubmit">Submit</button>
          </form>
        </div>

        <div className="col-md-6 left">
          <span className="company__logo">
            <img alt="zrm" src={require('../assets/zrm.png')} />
          </span>
          <h5>Powered by Zunroof</h5>
        </div>
      </div>
    )
  }
}
