import {FETCH_UNICOMMERCE_MAPPING, FETCH_ZUNPULSE_SELLING_PRICE, FETCH_ZUNSOLAR_PI_ALL_INVOICES , FETCH_ZUNSOLAR_PI_LEDGER} from '../actions/actiontypes';
export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ZUNSOLAR_PI_ALL_INVOICES: {
            return { ...state, allPiInvoices: action.payload };
        }
        case FETCH_ZUNSOLAR_PI_LEDGER:{
            return { ...state, allPiLedgerData:action.payload };
        }
        case FETCH_UNICOMMERCE_MAPPING:{
            return{...state,unicommerceMapping: action.payload};
        }
        case FETCH_ZUNPULSE_SELLING_PRICE:{
            return {...state,sellingPriceMapping: action.payload};
        }
       
        default:
            return state;
    }
}
