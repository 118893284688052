import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import Select from 'react-select';
import { repeatSalesImageConfig, productsMapping } from '../../config/column_fields';
import axios from 'axios';
import url, { appBackendUrl, zcommerceUrl } from '../../config';
import { Redirect } from 'react-router-dom';
import { couponDataMapper, mainDataMapper, productDataMapper } from '../../helper/dataMapper';
import '../../styles/addCoupon.css';

class ZunpulseRepeatUploadImages extends Component {


    constructor() {
        super()
        this.state = {
            couponType: '',
            formData: {},
            productDataMapping: [],
            mapping: { products: [] },
            userObj: {},
            productsName:[],
            username:''
        };
    }


    async componentDidMount() {
        try {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            if (Object.keys(userObj).length > 0) {
              this.setState({
                userObj: userObj,
                username: userObj.username
              })
            }
          
            const res = await axios.get(`${zcommerceUrl.productPathBackend}product/getProductMapping`);
            const productDataMapping = res?.data?.data;
            const productMapperData = productDataMapper(productDataMapping);
            const obj = { products: productMapperData };
            this.setState({
                mapping: {
                    ...this.state.mapping,
                    ...obj

                }

            })



        } catch (error) {
            console.log('error', error)

        }


    }


    handleSubmit = async (e) => {
        e.preventDefault();
        console.log("form Data", this.state.formData);
        let pids = this.state.formData.products;
        let allProductMapping = this.state.mapping.products;
        let file  = this.state.formData.files;

      let productsName =  []
      if(pids && pids.length){
       allProductMapping.map((productData,index)=>{
              if(pids.includes(productData.value)){
               productsName.push(productData.label);
              }
        })
    }


        // return;
        try {
            const formData = new FormData();

          
            formData.append("pids", pids && pids.join(',') || '');
            formData.append("products", productsName && productsName.join(',')|| '');
            formData.append("file", file);
            formData.append("key",  url.apiKey);
            formData.append("email", this.state.username);

            let apiResponse = await axios.post(`${url.pathBackend}zunpulse/repeatSales/uploadBannerImages`, formData)
            apiResponse = apiResponse.data;
            console.log('apiResponse', apiResponse);
            if(apiResponse && apiResponse.status === "success"){
                this.setState({ formData: {} })
                alert("Banner uploaded Successfully");
                return;
            }
            alert("failed");
                return;
            // // return;
            // let response = await axios.post(`${zcommerceUrl.couponPathBackend}createCouponV2`, apiData);
            // this.setState({
            //     formData: {},
            //     freeProducts:[]
            // })
            // console.log("res", response);
            // response = response.data || {};
            // console.log(response);

            // if (response && response.status === 'success') {
            //     window.location.reload(false);
            //     alert("Coupon Added Successfully");
            //     return;
            // }


        } catch (error) {
            console.log("errr", error)
            this.setState({ formData: {} })
            alert("Failed");
            return;


        }


    }

    handleSelectChange = (e, name) => {
        if (!e) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: "",
                   
                },
            });
            return;
        }
        let value = '';
        console.log('event', e, name);
        if (Array.isArray(e)) {
            value = e.map(row => row.value);
        }
        else {
            value = e.value;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }

        })
    }

    handleInputChange = (e) => {
        console.log('event', e);
        let files = e.target.files[0]
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: files
            }
        })
    }




    ProductTypeSelect = ({ name, optionsName, isMulti }) => {
        let options = this.state.mapping[optionsName] || [];
        return (
            <Select
                isSearchable
                isMulti={isMulti}
                onChange={(e) => { this.handleSelectChange(e, name) }}
                options={options}
            />
        )
    }


    FileTypeInput = ({ name, type, required, min }) => {
        return (
            <input type={type} className="form-control" name={name} onChange={(e) => this.handleInputChange(e)} required={required}></input>
        )
    }

    InputField = ({ label, name, type, required, optionsName, isMulti }) => {
        let { FileTypeInput, ProductTypeSelect } = this;
        return (
            <div className="row">
                <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                    {label}
                </label>
                <div className=" col-md-8">
                    {
                        type == 'select' ? <ProductTypeSelect
                            name={name}
                            optionsName={optionsName}
                            isMulti={isMulti}
                        /> : <FileTypeInput
                            name={name}
                            type={type}
                            required={required}
                        />
                    }
                </div>
            </div>
        )
    }


    render() {
        let { InputField } = this;

        return (
            <div className='mt-5 mx-auto p-5' style={{border:'1px solid #000'}}>
                <h4 style={{ fontSize: '20px', fontWeight: 'bold' }}>Upload Banner Images</h4>
                <form className='mt-4' onSubmit={(e) => this.handleSubmit(e)}>
                    {
                        repeatSalesImageConfig.map((config, index) => <InputField key={index} {...config} />)
                    }


                    <button className="btn btn-success float-right mt-1" type="submit">Submit</button>

                </form>


            </div>
        )
    }
}

function mapStateToProps({ }) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseRepeatUploadImages);

