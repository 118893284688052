import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_LOGISTICS_DATA_SUCCESS, FETCH_LOGISTICS_MAPPING_SUCCESS } from './actiontypes';
import reactSelect from 'react-select';

export function fetchLogisticsData() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}logistics/fetch`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let logisticsData = response.data;
            console.log(logisticsData)
            dispatch({ type: FETCH_LOGISTICS_DATA_SUCCESS, payload: logisticsData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMapping() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}logistics/fetchMapping`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let mapping = response.mapping;
            console.log(mapping)
            dispatch({ type: FETCH_LOGISTICS_MAPPING_SUCCESS, payload: mapping || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}



export function updateLogisticsData(id, oldData, newData, allData, files) {
    return async (dispatch) => {
        try {
            let updatedData = {};
            Object.keys(newData).map(field => {
                if (newData[field] != oldData[field]) {
                    updatedData[field] = newData[field]
                }
            })
            if (!Object.keys(updatedData).length && !Object.keys(files).length) {
                return;
            }
            console.log("updatedData", updatedData, files);
            let formData = new FormData();
            formData.append("id", oldData.id);
            Object.keys({ ...updatedData, ...files }).forEach(key => {
                formData.append(key, updatedData[key] || files[key]);
            })
            let response = await axios.post(`${url.pathBackend}logistics/update`, formData);
            console.log(response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let index = allData.findIndex(row => row.id == id);
            allData = JSON.parse(JSON.stringify(allData));
            allData[index] = response.newData;
            dispatch({ type: FETCH_LOGISTICS_DATA_SUCCESS, payload: allData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function downloadFile(fileType , fileName) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}logistics/file-download`, { fileType , fileName });
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let { Body = {}, ContentType } = response;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function insertManualEntry(data) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}logistics/create`, data);
            response = response.data;
            if (response.error) {
                alert(response.error);
                return;
            }
            alert("Success");
            return;
        }
        catch (e) {
            console.log(e);
        }
    }
}