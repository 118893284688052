import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import Select from 'react-select';
import { fetchWalletTrasactions } from '../../actions/walletActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader';
import { CSVLink } from 'react-csv';
import { walletTransactionsConfig } from '../../config/column_fields'

class WalletTransactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            allData: [],
            viewData: [],
            filters: {
                startDate: 0,
                endDate: 0,
                phone: ""
            },
            loading: true,
            received: false,
            mapping: {}
        };
    }

    componentWillMount() {
        this.props.fetchWalletTrasactions();
    }

    componentWillReceiveProps(props) {
        let { walletTransactions = [], walletTransactionsReceived = false } = props;
        this.setState({
            loading: false,
            received: true,
            viewData: walletTransactions,
            allData: walletTransactions,
            walletTransactionsReceived
        })
    }

    handleInputChangeFilters = (e) => {
        let { name, value } = e.target;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    inputType = (name, placeholder, type, onChange, value) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e)} />
                </div>
            </div>
        )
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = () => {
        let { filters, allData } = this.state;
        let { startDate = "", endDate = "", phone = "" } = filters;
        let startDateFormat = startDate ? moment(startDate).startOf('day').valueOf() : "";
        let endDateFormat = endDate ? moment(endDate).endOf('day').valueOf() : "";
        let viewData = allData.filter(row => {
            return (
                (!startDateFormat ? true : moment(row.createdAt).valueOf() >= startDateFormat) &&
                (!endDateFormat ? true : moment(row.createdAt).valueOf() <= endDateFormat) &&
                (!phone ? true : phone == row.user_id)
            )
        })
        this.setState({
            viewData
        })
    }

    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        let { viewData = [], walletTransactionsReceived = false } = this.state;
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <div className="topbar-section allLeadViewFilters">
                        <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                        <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                        <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                            <div className="col-md-2">
                                {this.inputType("startDate", "Added On", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("endDate", "Added On", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("phone", "Phone", "number", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                            </div>
                        </div>
                    </div>
                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={viewData}
                        columns={walletTransactionsConfig}
                        defaultSorted={[{ id: "createdAt", desc: true }]}
                        style={{ height: "90vh", textAlign: 'center' }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        loading={!walletTransactionsReceived}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}


function mapStateToProps({ wallet }) {
    let { walletTransactions = [], walletTransactionsReceived = false } = wallet;
    return {
        walletTransactions,
        walletTransactionsReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchWalletTrasactions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletTransactions);
