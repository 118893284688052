import { FETCH_DEALERS_DATA_SEARCH, FETCH_ZUNSOLAR_PI_ORDERS_ALL, FETCH_ZUNSOLAR_PI_ORDERS_SINGLE, FETCH_ZUNSOLAR_PI_ORDERS_COMMENTS, FETCH_PI_PRODUCT_DETAILS } from '../actions/actiontypes';
export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_DEALERS_DATA_SEARCH: {
            return { ...state, dealersData: action.payload, fetchDealersListError: (action.payload && action.payload.length)?false:true };
        }
        case FETCH_ZUNSOLAR_PI_ORDERS_ALL: {
            return { ...state, piOrdersData: action.payload }
        }
        case FETCH_ZUNSOLAR_PI_ORDERS_SINGLE: {
            return { ...state, singlePIDetails: action.payload}
        }
        case FETCH_ZUNSOLAR_PI_ORDERS_COMMENTS: {
            return {...state, allCommentsSinglePI: action.payload}
        }
        case FETCH_PI_PRODUCT_DETAILS: {
            return {...state, piProductDetails: action.payload}
        }
        default:
            return state;
    }
}
