import { FETCH_ALL_LEADS_START, FETCH_ALL_LEADS_SUCCESS, FETCH_ALL_LEADS_ERROR } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ALL_LEADS_START: {
            return {};
        }
        case FETCH_ALL_LEADS_ERROR: {
            return { error: action.payload }
        }
        case FETCH_ALL_LEADS_SUCCESS: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
}