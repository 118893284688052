import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
// import { projectSalesLeadColumns } from '../config/column_fields';
import { fetchAllLeads, fetchMapping, deleteLead, updateLeadDataBulk, updateSingleCellData } from '../actions/projectSalesLeadActions';
import moment from 'moment';
import Header from '../components/HeaderComponent';
import { getSearchResult } from '../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../components/loader'
import { CSVLink } from 'react-csv';
import url from '../config';
import axios from 'axios';

const phoneFields = [ "poc1Phone", "poc2Phone", "poc3Phone" ];
const emailFields = [ "poc1Email", "poc2Email", "poc3Email" ];

class ProjectSalesAllLeads extends Component {

    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            showFilter: false,
            allData: [],
            viewData: [],
            filters: {
                projectName: '',
                developer: '',
                projectStage: [],
                status: [],
                nextActionDate: {
                    start: '',
                    end: ''
                },
                lastVisitDate: {
                    start: '',
                    end: ''
                },
                city: '',
                responsible: ''
            },
            loading: true,
            received: false,
            mapping: {},
            leadDataReceived: false,
            page: 1,
            limit: 500,
            limitoption: [
                { value: 500, label: '500' },
                { value: 1000, label: '1000' },
                { value: 5000, label: '5000' }
            ],
            employeeDetails: {},
            selectedOrders: {},
            selectAllEnabled: false,
            bulkUpdateValues: {},
            cellEditStatus: {},
            cellUpdatedValues: {}
        };
    }

    componentWillMount() {
        let { page, limit, filters = {} } = this.state;
        this.props.fetchAllLeads(page, limit, [], filters, '');
        this.props.fetchMapping();
        let employeeDetails = JSON.parse(localStorage.getItem("userObj"));
        // let updatedColumns = projectSalesLeadColumns;
        // if(employeeDetails.role == 'Super Admin') {
        //     updatedColumns = [{
        //         Header: "Delete",
        //         Cell: row => {
        //             return <button className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleDeleteClick(row)}>Delete</button>
        //         }
        //     }, ...updatedColumns];
        // }
        this.setState({
            employeeDetails: employeeDetails
        })
    }

    handleDeleteClick = (row) => {
        let { allData = [] } = this.state;
        let { leadId } = row.original;
        if(!allData.length || !leadId) return;
        this.props.deleteLead(leadId, allData);
    }

    fetchLeads = (e) => {
        let { page, limit, allData, filters, searchText = "" } = this.state;
        this.props.fetchAllLeads(page + 1, limit, allData, filters, searchText);
        this.setState({
            page: page + 1,
            leadDataReceived: false
        })
    }

    componentWillReceiveProps(props) {
        let { leads, mapping, leadDataReceived = false } = props;
        this.setState({
            loading: false,
            received: true,
            viewData: leads,
            allData: leads,
            mapping,
            leadDataReceived
        })
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[type] = filters[type] || {};
        filters[type][name] = value;
        this.setState({
            filters
        })
    }

    handleInputTextChangeFilters = (e) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[name] = value;
        this.setState({
            filters
        })
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    selectFetchLeads = (lim, e) => {
        this.setState({
            page: 1,
            limit: lim,
            allData: [],
            leadDataReceived: false
        }, () => {
            let { limit, page, filters, searchText = "" } = this.state;
            this.props.fetchAllLeads(page, limit, [], filters, searchText);
        })
    }

    multiSelectType = (name, options, placeholder, onChange, value) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }


    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={"End Date"} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    inputTypeText = (name, placeholder, type, onChange) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e)} />
                </div>
            </div>
        )
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = () => {
        let { limit, filters, searchText = "" } = this.state;
        this.props.fetchAllLeads(1, limit, [], filters, searchText);
        this.setState({
            page: 1,
            leadDataReceived: false
        })
    }

    handleSearchInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    handleCheckBoxChange = (e, id) => {
        let { checked } = e.target;
        if(!checked) {
            if(id == -1) {
                this.setState({
                    selectedOrders: {},
                    selectAllEnabled: false
                })
            } else {
                let selectedOrders = this.state.selectedOrders;
                delete selectedOrders[id];
                this.setState({
                    selectedOrders,
                    selectAllEnabled: false
                })
            }
        }
        else {
            let sortedData = this.reactTable.current.state.sortedData || [];
            if(id == -1) {
                let selectedIds = sortedData.map(row => row._original.leadId) || [];
                let selectedOrders = {};
                selectedIds.forEach(selectedId => {
                    selectedOrders[selectedId] = true;
                });
                this.setState({
                    selectedOrders,
                    selectAllEnabled: true
                })
            } else {
                let selectedOrders = {
                    ...this.state.selectedOrders,
                    [id]: true
                };
                let selectAllEnabled = Object.keys(selectedOrders).length == sortedData.length;
                this.setState({
                    selectedOrders: selectedOrders,
                    selectAllEnabled
                })
            }
        }
    }

    bulkUpdateSelectInput = (name, options, value) => {
        return (
            <div style={{ width: '100%' }} >
                <select style={{ height: '35px' }}
                    onChange={(e) => { this.handleSelectChangeBulkUpdate(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option value="">not selected</option>
                    {(options ? options : []).map((e) => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    BulkUpateInput = (name, value, type) => {
        return (
            <input type={type} name ={name} value = {value} className="form-control"  onChange={(e) => this.handleSelectChangeBulkUpdate(e)} ></input>
        )
    }

    handleSelectChangeBulkUpdate = (e) => {
        let { name, value } = e.target;
        this.setState({
            bulkUpdateValues: {
                ...this.state.bulkUpdateValues,
                [name]: value
            }
        })
    }

    handleBulkUpdateSubmit = () => {
        let { selectedOrders, bulkUpdateValues } = this.state;
        let selectedLeadIds = Object.keys(selectedOrders).filter(leadId => selectedOrders[leadId] == true);
        if(!selectedLeadIds.length || !Object.keys(bulkUpdateValues).length) return;
        if(!bulkUpdateValues.nextActionDate && !bulkUpdateValues.responsible) return;
        this.props.updateLeadDataBulk(selectedLeadIds, bulkUpdateValues);
    }

    renderInputCell = (row) => {
        let { id, type } = row.column;
        let leadId = row.original.leadId;
        let { cellEditStatus = {}, cellUpdatedValues = {} } = this.state;
        if(cellEditStatus[leadId] && cellEditStatus[leadId][id]) {
            return <input name={id} type={type} value={cellUpdatedValues[leadId][id]} onChange={(e) => this.onCellUpdate(e, leadId)} onKeyUp={(e) => this.handleKeyPress(e, leadId, row.value)}/>
        }
        return <div onDoubleClick={(e) => this.handleCellDoubleClick(row, id)} style={{width: '100%', height: '100%'}}>{row.value || "-"}</div>;
    }

    handleKeyPress = (e, leadId, previousValue) => {
        if(e.keyCode !== 13) return;
        let { name, value } = e.target;
        let cellEditStatus = JSON.parse(JSON.stringify(this.state.cellEditStatus));
        if(value == previousValue) {
            cellEditStatus[leadId][name] = false;
            this.setState({
                cellEditStatus
            });
            return;
        }
        if(phoneFields.includes(name) && !this.ValidatePhone(value)) {
            alert("Invalid phone number");
            return;
        }
        if(emailFields.includes(name) && !this.ValidateEmail(value)) {
            alert("Invalid email");
            return;
        }
        this.props.updateSingleCellData(leadId, name, value, JSON.parse(JSON.stringify(this.state.allData)));
        cellEditStatus[leadId][name] = false;
        this.setState({
            cellEditStatus
        });
        return;
    }

    ValidateEmail = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    }

    ValidatePhone = (phone) => {
        if (/^[0-9]{10}$/.test(phone)) {
            return true;
        }
        return false;
    }

    renderSelectCell = (row) => {
        let { id, options } = row.column;
        let leadId = row.original.leadId;
        let { cellEditStatus = {}, cellUpdatedValues = {} } = this.state;
        if (cellEditStatus[leadId] && cellEditStatus[leadId][id]) {
            return <div style={{ width: '100%' }} >
                <select
                    onChange={(e) => { this.onCellUpdate(e, leadId) }}
                    onKeyUp={(e) => this.handleKeyPress(e, leadId, row.value)}
                    name={id} value={cellUpdatedValues[leadId][id]}
                >
                    {(options ? options : []).map((e) => <option key={e}>{e}</option>)}
                </select>
            </div>
        }
        return <div onDoubleClick={(e) => this.handleCellDoubleClick(row, id)} style={{ width: '100%', height: '100%' }}>{row.value || "-"}</div>;
    }

    onCellUpdate = (e, leadId) => {
        let { name, value } = e.target;
        let cellUpdatedValues = JSON.parse(JSON.stringify(this.state.cellUpdatedValues));
        if(!cellUpdatedValues[leadId]) cellUpdatedValues[leadId] = {};
        cellUpdatedValues[leadId][name] = value;
        this.setState({
            cellUpdatedValues
        })
    }

    handleCellDoubleClick = (row, id) => {
        let leadId = row.original.leadId;
        let cellUpdatedValues = JSON.parse(JSON.stringify(this.state.cellUpdatedValues));
        let cellEditStatus = JSON.parse(JSON.stringify(this.state.cellEditStatus));
        if(!cellEditStatus[leadId]) cellEditStatus[leadId] = {};
        cellEditStatus[leadId][id] = true;
        if(!cellUpdatedValues[leadId]) cellUpdatedValues[leadId] = {};
        cellUpdatedValues[leadId][id] = row.value;
        this.setState({
            cellUpdatedValues,
            cellEditStatus
        })
    }

    render() {

        let { renderInputCell, renderSelectCell } = this;
        let { leadDataReceived, viewData, mapping, employeeDetails = {}, bulkUpdateValues = {} } = this.state;
        const projectStageOptions = (mapping.projectStage || []).map(value => ({ label: value, value: value }));
        const leadStatusOptions = (mapping.status || []).map(value => ({ label: value, value: value }));
        const responsibleOptions = (mapping.responsible || []).map(value => ({ label: value, value: value }));
        const cityOptions = (mapping.city || []).map(value => ({ label: value, value: value }));
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        };

        let selectedOrdersCount = Object.keys(this.state.selectedOrders).length;

        let projectSalesLeadColumns = [
            {
                Header: 'Lead Id',
                accessor: 'leadId',
                Cell: row => {
                    let { leadId } = row.original;
                    return (<div><a href={`/projectsales/lead/${leadId}`} target={`_blank`}>{leadId}</a></div>)
                }
            },
            {
                Header: 'Project Name',
                accessor: 'projectName',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'Developer',
                accessor: 'developer',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'Project Stage',
                accessor: 'projectStage',
                Cell: renderSelectCell,
                options: mapping.projectStage
            },
            {
                Header: 'Lead Status',
                accessor: 'status',
                Cell: renderSelectCell,
                options: mapping.status
            },
            {
                Header: 'City',
                accessor: 'city',
                Cell: renderSelectCell,
                options: mapping.city
            },
            {
                Header: 'Next Action Date',
                accessor: 'nextActionDate',
                Cell: renderInputCell,
                type: 'date'
            },
            {
                Header: 'Launch Date',
                accessor: 'launchDate',
                Cell: renderInputCell,
                type: 'date'
            },
            {
                Header: 'Responsible',
                accessor: 'responsible',
                Cell: renderSelectCell,
                options: mapping.responsible
            },
            {
                Header: 'Address',
                accessor: 'address',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'Possession Date',
                accessor: 'possessionDate',
                Cell: renderInputCell,
                type: 'date'
            },
            {
                Header: 'Smart Provision',
                accessor: 'smartProvision',
                Cell: renderSelectCell,
                options: mapping.smartProvision
            },
            {
                Header: 'Project Type',
                accessor: 'projectType',
                Cell: renderSelectCell,
                options: mapping.projectType
            },
            {
                Header: 'Last Visit Date',
                accessor: 'lastVisitDate',
                Cell: renderInputCell,
                type: 'date'
            },
            {
                Header: 'POC 1 Name',
                accessor: 'poc1Name',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'POC 1 Team',
                accessor: 'poc1Team',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'POC 1 Phone',
                accessor: 'poc1Phone',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'POC 2 Name',
                accessor: 'poc2Name',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'POC 2 Team',
                accessor: 'poc2Team',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'POC 2 Phone',
                accessor: 'poc2Phone',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'POC 3 Name',
                accessor: 'poc3Name',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'POC 3 Team',
                accessor: 'poc3Team',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'POC 3 Phone',
                accessor: 'poc3Phone',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'Last Updated By',
                accessor: 'updatedBy'
            },
            {
                Header: 'Last Updated On',
                accessor: 'updatedAt'
            }
        ];

        let projectSalesLeadColumnsUpdated = projectSalesLeadColumns;

        if(employeeDetails.role == 'Super Admin') {
            projectSalesLeadColumnsUpdated = [{
                Header: "Delete",
                Cell: row => {
                    return <button className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleDeleteClick(row)}>Delete</button>
                }
            }, ...projectSalesLeadColumnsUpdated];
        }

        if(['Super Admin', 'Admin'].includes(employeeDetails.role)) {
            projectSalesLeadColumnsUpdated = [{
                Header: row => {
                    return (
                        <div>
                            <input type="checkbox" onChange={(e) => { this.handleCheckBoxChange(e, '-1') }} checked={this.state.selectAllEnabled ? 'checked' : ''} ></input>
                        </div>
                    )
                },
                Cell: row => (
                    <div>
                        <input type="checkbox" onChange={(e) => { this.handleCheckBoxChange(e, row.original.leadId) }} checked={this.state.selectedOrders[row.original.leadId] ? 'checked' : ''} ></input>
                    </div>
                ),
                filterable: true,
                sortable: false,
                columns: false,
                Filter: () => {
                    return (
                        <div>{selectedOrdersCount}</div>
                    )
                }
            }, ...projectSalesLeadColumnsUpdated];
        }

        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 60 }} className="container-fluid">
                    <div className="topbar-section allLeadViewFilters">
                        <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                        <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                        <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                            <div className="col-md-3">
                                {this.inputTypeText("projectName", "Project Name", "text", this.handleInputTextChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.inputTypeText("developer", "Developer", "text", this.handleInputTextChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("projectStage", projectStageOptions, "Project Stage", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("status", leadStatusOptions, "Lead Status", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("start", "Next Action Start Date", "date", this.handleInputDateChangeFilters, "nextActionDate")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("end", "Next Action End Date", "date", this.handleInputDateChangeFilters, "nextActionDate")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("start", "Last Visit Start Date", "date", this.handleInputDateChangeFilters, "lastVisitDate")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("end", "Last Visit End Date", "date", this.handleInputDateChangeFilters, "lastVisitDate")}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("responsible", responsibleOptions, "Responsible", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("city", cityOptions, "City", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input className="form-control customInput" type="text" name="searchText" placeholder="Search" onChange={(e) => this.handleSearchInputChange(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                            </div>
                        </div>
                    </div>
                    { selectedOrdersCount == 0 ? <div style={{ margin: '20px 0px' }}>
                        <div style={{ float: "left" }}>
                            <div style={{ display: 'inline-block', width: '150px', margin: '0 10px' }}><Select placeholder="No. of Leads" options={this.state.limitoption} onChange={e => this.selectFetchLeads(e.value, e)} /></div>
                            <button className="btn btn-light" style={{ margin: '0 10px' }} onClick={e => this.fetchLeads()}>More Leads</button>
                            { employeeDetails.role == 'Super Admin' ? <CSVLink filename={"Leads Data.csv"} data={viewData} headers={projectSalesLeadColumns.map(val => ({ label: val.csvHeader || val.Header, key: val.accessor }))} type="button" className="btn btn-light">
                                Download <i className="fa fa-download"></i>
                            </CSVLink> : "" }
                        </div>
                        <div style={{ float: "right" }}>
                            <Link to="/projectsales/createlead" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> Add New</button></Link>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div> : "" }
                    {selectedOrdersCount > 0 ? <div className="mb-4">
                        <div className="row">
                            <div className="col-md-2">
                                <label>Responsible</label>
                                {this.bulkUpdateSelectInput("responsible", mapping.responsible || [], bulkUpdateValues.responsible)}
                            </div>
                            <div className="col-md-2">
                                <label>Next Action Date</label>
                                {this.BulkUpateInput("nextActionDate", bulkUpdateValues.nextActionDate, "date")}
                            </div>
                            <div className="col-md-2" style={{ marginTop: "32px" }}>
                                <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkUpdateSubmit() }}>Submit</button>
                            </div>
                        </div>
                    </div> : ""}
                    <ReactTable
                        ref={this.reactTable}
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={viewData}
                        columns={projectSalesLeadColumnsUpdated}
                        defaultSorted={[{ id: "leadId", desc: true }]}
                        style={{ height: "90vh", textAlign: 'center' }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        loading={!leadDataReceived}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps({ projectSales }) {
    let { leads = [], mapping = {}, leadDataReceived = false } = projectSales || {};
    return {
        leads,
        mapping,
        leadDataReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllLeads, fetchMapping, deleteLead, updateLeadDataBulk, updateSingleCellData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSalesAllLeads);