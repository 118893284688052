import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { comboColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchMapping, addCombo } from '../../actions/zunsolarOrderTrackerActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader'

class ZunsolarAddCombo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            received: false,
            mapping: {},
            products: [{}],
            subCategoryOptions: [[]]
        };
    }

    handleInputChange = (e) => {
        console.log(e);
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    componentWillMount() {
        this.props.fetchMapping();
    }

    componentWillReceiveProps(props) {
        let { mapping } = props;
        this.setState({
            loading: false,
            received: true,
            mapping: mapping
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { products, formData } = this.state;
        if (!this.validateSubOrders(products)) {
            alert("Please Check ALL FIELDS");
            return;
        }
        let comboDetails = {
            comboId: formData.comboId
        };
        for (let i = 0; i < products.length; i++) {
            let product = products[i];
            let categoryName = `product${i + 1}Category`
            let subCategoryName = `product${i + 1}SubCategory`
            let quantityName = `product${i + 1}Qty`
            comboDetails = {
                ...comboDetails,
                [categoryName]: product.category,
                [subCategoryName]: product.subCategory,
                [quantityName]: +product.quantity
            }
        }
        this.props.addCombo(comboDetails);
        console.log(comboDetails);
    }

    validateSubOrders = (subOrders = []) => {
        let isValid = true;
        subOrders.map(subOrder => {
            if (!subOrder.category || !subOrder.subCategory || isNaN(subOrder.quantity) || !((+subOrder.quantity) >= 1)) {
                isValid = false;
            }
        })
        return isValid;
    }

    handleSubOrderChange = (e, index) => {
        let { name, value } = e.target;
        let { mapping, subCategoryOptions, products } = this.state;
        if (name == 'category' && value) {
            products[index][name] = value;
            subCategoryOptions[index] = mapping.category[value]
            this.setState({
                products: products,
                subCategoryOptions
            })
        }
        else {
            products[index][name] = value;
            this.setState({
                products
            })
        }
    }

    addSuborder = () => {
        let { products } = this.state;
        if (products.length > 7) {
            return;
        }
        products = [...products, {}];
        this.setState({
            products
        })
    }

    render() {
        let { mapping, subCategoryOptions, products } = this.state;
        console.log(subCategoryOptions)
        return (
            <div className="analyseAc">
                <Header />
                <div class="container">
                    <div className="row">
                        <h4 className="col-10" style={{ fontWeight: "bold" }}>Add Combo</h4>
                        <button className="button-save col-1" onClick={(e) => this.addSuborder(e)}>Add</button>
                    </div>
                    <hr className="analyseAcHr"></hr>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                            <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Combo Id
                            </label>
                            <div class="col-md-8">
                                <input type="text" className="form-control" name="comboId" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                                </div>
                        {
                            products.map((row, index) => {
                                return (
                                    <div className="row">
                                        <div className="col-md-4">
                                            <select className="form-control" name="category" onChange={(e) => this.handleSubOrderChange(e, index)} required>
                                                <option key="-1" value="">Select Category</option>
                                                {
                                                    Object.keys(mapping.category || {}).map((category, i) => {
                                                        return (
                                                            <option key={i} value={category}>{category}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <select type="select" className="form-control" name="subCategory" onChange={(e) => this.handleSubOrderChange(e, index)} required>
                                                <option key="-1" value="">Select Sub Category</option>
                                                {
                                                    (subCategoryOptions[index] || []).map((subCategory, i) => {
                                                        return (
                                                            <option key={i} value={subCategory}>{subCategory}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <input type="number" className="form-control" name="quantity" onChange={(e) => this.handleSubOrderChange(e, index)} placeholder="Quantity" required></input>
                                        </div>
                                        <br /><br />
                                    </div>
                                )
                            })
                        }
                        <br /><br />
                        <div className="callCountSubmit">
                            <button className="btn btn-success" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ zunSolarOrderTracker }) {
    let { mapping = [] } = zunSolarOrderTracker || {};
    return {
        mapping
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMapping, getSearchResult, addCombo }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarAddCombo);