import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { fetchAllMapping } from '../actions/fetchAllMapping';
import { saViewGet, saveRemarks } from '../actions/saViewAction';
import { render } from '@testing-library/react';
import Header from '../components/HeaderComponent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SAViewComponent from '../components/SAViewComponent';

class SAView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      recieved: false
    }
  }
  componentWillMount() {
    let viewList = ["stateregion"]
    this.props.fetchAllMapping(viewList);
    this.props.saViewGet()
  }

  render() {
    return (
      <div>
        <Header />
        <SAViewComponent allMapping={this.props.allMapping} saViewData={this.props.saViewData} saViewGet={this.props.saViewGet} saveRemarks={this.props.saveRemarks} />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    saViewData: state.saViewData ? state.saViewData : {}
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAllMapping, saViewGet, saveRemarks }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SAView);