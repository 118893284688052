import {CREATE_LEAD_DEALERID, CREATE_LEAD_ERROR,CREATE_LEAD_START,CREATE_LEAD_SUCCESS, MT_LEAD_DATA} from '../actions/actiontypes';

export default function(state = '', action) {
	switch(action.type){
		case CREATE_LEAD_ERROR:{
			return {...state,error:action.payload};
		}
		case CREATE_LEAD_SUCCESS:{
			return {...state,success:action.payload};
		}
		case CREATE_LEAD_DEALERID:{
			return {...state,bitrixId:action.payload};
		}
		case MT_LEAD_DATA:{
			return {...state,mtLeadData:action.payload};
		}
		
		default:
			return state;
	}
}