import axios from "axios";
import { FETCH_MT_PAYMENT_HISTORY, FETCH_MT_SEARCH_VISITS, FETCH_MT_VISIT_DETAILS } from "./actiontypes";
import url from '../config';


export function fetchMTVisitData(email){
    try {
      return async(dispatch)=>{
          let apiData = {
            email: email
          }
      let apiRes = await axios.post(`${url.pathBackend}zunsolar/lead/mtVisitDetails`, apiData);
      apiRes = apiRes.data
      if(apiRes.status=="success"){
        console.log('apiRes',apiRes);
          apiRes = apiRes && apiRes.data || {}
          dispatch({type:FETCH_MT_VISIT_DETAILS, payload:  apiRes ||  {}})
          
      }
    
  }
  } catch (error) {
      alert("something went wrong")
  
      
  }
  }

  export function createMTPayment(paymentData){
    try {
      return async(dispatch)=>{
         
          let apiData = {
            key: "bSFx2wcJ2MUnPVf",
            user: paymentData.userEmail,
            data:{
                  amount: paymentData.amount,
                  category: "Offline", 
                  mode: paymentData.paymentMode, 
                  name: paymentData.customerName,
                  referenceNumber: paymentData.referenceNumber,
                  status: "Deposited",
                  subCategory: paymentData.subcategory,
                  dealerCode: paymentData.dealerId || '',
                  bank: paymentData.bank
                  
            }
            
          }
          let endpoint = "https://operations.zunroof.com/api/payment/createPaymentExternal";
          let paymentRes = await axios.post(endpoint, apiData);
          paymentRes = paymentRes.data;
          if(paymentRes && paymentRes.data){
            alert("payment created successfully")
            return;
          }
      alert("something went wrong")

          console.log('paymentRes',paymentRes);
     
    
  }
  } catch (error) {
      alert("something went wrong")
  
      
  }
  }

  
  export function fetchMTPayment(userEmail){
    try {
      return async(dispatch)=>{
         
          let apiData = {
            key: "bSFx2wcJ2MUnPVf",
            user: userEmail,
            createdBy: userEmail
            
            
          }
          let endpoint = "https://operations.zunroof.com/api/payment/fetchpaymentbydealer";
          let paymentRes = await axios.post(endpoint, apiData);
          paymentRes = paymentRes.data;
          console.log('paymentRes',paymentRes);
          if(paymentRes && paymentRes.data){
          dispatch({type:FETCH_MT_PAYMENT_HISTORY, payload:  paymentRes.data ||  []})
            
          }

     
    
  }
        } catch (error) {
            console.log('error', error);
            
        }
  }

  export function fetchMTSearchVisits(email){
    try {
      return async(dispatch)=>{
          let apiData = {
            email: email
          }
      let apiRes = await axios.post(`${url.pathBackend}zunsolar/lead/fetchVisitedDealers`, apiData);
      apiRes = apiRes.data
      if(apiRes.status=="success"){
        console.log('apiRes',apiRes);
          apiRes = apiRes && apiRes.data || {}
          dispatch({type:FETCH_MT_SEARCH_VISITS, payload:  apiRes ||  []})
          
      }
    
  }
  } catch (error) {
      alert("something went wrong")
  
      
  }
  }