import { FETCH_WALLET_TRANSACTIONS, FETCH_REDEEMED_VOUCHERS } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_WALLET_TRANSACTIONS: {
            return { ...state, walletTransactions: [...action.payload], walletTransactionsReceived: true };
        }
        case FETCH_REDEEMED_VOUCHERS: {
            return { ...state, redeemedVouchersData: [...action.payload], redeemedVouchersDataReceived: true };
        }
        default:
            return state;
    }
}