import React, { useState } from 'react';
import { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/header.css'
import url from '../config';
import axios from 'axios'
import { Link, NavLink } from 'react-router-dom';
import { UserProfileComponent } from './UserProfileComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearchResult } from '../actions/searchAction';
import { getUserInfo } from '../actions/user';
import zunpulseLogo from '../assets/zunpulseLogo.svg'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export class MobileHeaderComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchContent: '',
      username: '',
      userObj: {},
      userInfo: false,
      firstname: 'Shivam',
      lastname: 'Dubey',
      email: 'shivam.dubey@zunroof.com',
      access: 'bitrix',
      role: 'Super Admin',
      active: false,
      dropDownValue: 'Select action',
      dropdownOpen: false,
      solarDropDown: false,
      roofDropdown: false,
      offlineDropDown:false
    }

  }

  componentDidMount() {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let username = userObj.username.split('@')[0].split('.')[0] + ' ' + userObj.username.split('@')[0].split('.')[1] || ""

    if (Object.keys(userObj).length > 0) {
      this.setState({
        userObj: userObj,
        username: username
      })
    }
  }

  toggle = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  }

  handleLogout = (e) => {

    localStorage.clear()
    window.location.href = url.pathFrontend
    // }
  }

  handleSearch = (e) => {
    // e.preventDefault();
    if (e.target.value && e.key === 'Enter') {
      let { value } = e.target

      let searchObj = {
        query: e.target.value
      }
      if ((window.location.href).includes("zunsolar")) {
        window.open(url.pathFrontend + "/zunsolar/search/" + value)
      } else if ((window.location.href).includes("zunpulse")) {
        window.open(url.pathFrontend + "/zunpulse/search/" + value)
      } else {
        window.open(url.pathFrontend + "/search/" + value)
      }
    }
  }
  handleUserInfoModal = (e) => {
    let obj = { username: this.state.username };
    this.props.getUserInfo(obj);
    this.setState({
      userInfo: true
    }, () => {
    })
  }
  handleCloseUserInfo = (e) => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  }
  handleRedirectToPayment = ()=>{
    window.open("https://operations.zunroof.com/paymentview","_blank")
    
  }

  render() {
    const userInfoStyle = {
      hide: {
        right: '-300px',
      },
      show: {
        display: '',
        right: "0px",
      }
    }
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }

    return (

         <nav className='navbar navbar-expand-lg navbar-light'>
                    <a className="navbar-brand">
                        <img className='zunpulseLogo' alt="Zunroof" src={zunpulseLogo} width="60%" />
                    </a>
                    <h3 className='username'>{this.state.username}</h3>
        </nav>
    )
  }
}

function mapStateToProps(state) {
  // console.log("state.createlead", state)
  return {
    userInfo: state.userInfo ? state.userInfo : {}
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSearchResult, getUserInfo }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(MobileHeaderComponent)