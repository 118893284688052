import { CHECK_EXISTING_PHONE_START, CHECK_EXISTING_PHONE_SUCCESS } from '../actions/actiontypes';

export default function (state = [], action) {
  switch (action.type) {
    case CHECK_EXISTING_PHONE_START: {
      return [];
    }
    case CHECK_EXISTING_PHONE_SUCCESS: {
      return [...state, ...action.payload]
    }
    default:
      return state;
  }
}