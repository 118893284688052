import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import {forgotPassword} from '../actions/forgotPasswordAction';
import Header from '../components/HeaderComponent';
import { connect } from 'react-redux';
import { ForgotPasswordComponent } from '../components/ForgotPasswordComponent.js'

class ForgotPassword extends Component {

  render() {
    return (
      <div>
        <ForgotPasswordComponent error={this.props.error} success={this.props.success} forgotPassword={this.props.forgotPassword}/>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    success : state.forgotPassword.success,
    error : state.forgotPassword.error
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({forgotPassword}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);