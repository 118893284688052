import React from 'react';
import axios from 'axios';
import url from '../config';
import { FETCH_ALL_MAPPING_START, FETCH_ALL_MAPPING_SUCCESS, FETCH_DISTRIBUTOR_MAPPING, FETCH_IVR_MAPPING, FETCH_RETURN_ORDERS_MAPPING } from './actiontypes';

// export function fetchStages(){
//     return(dispatch)=>{
//         console.log("fetch stages is called");
//         dispatch({type:FETCH_STAGES_START,payload:[]})

//         axios.post(url.pathBackend+"mapping/stages").then((response)=>{
//             console.log("responseeeeeeeeeee",response.data.data);
//             dispatch({type:FETCH_STAGES_SUCCESS,payload:response.data.data});
//         })
//     }
// }

export function fetchAllMapping(list) {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_MAPPING_START, payload: {} })
    fetchMapping(list).then((data) => {
      //  console.log("responseeeeeee",data)
      dispatch({ type: FETCH_ALL_MAPPING_SUCCESS, payload: data })
    })
  }
}

async function fetchMapping(list) {
  let mappingList = {};
  await Promise.all(list.map(async (e) => {
    let mapping = await axios.post(url.pathBackend + "mapping/" + e);
    // console.log("datatatatataatat",mapping);
    mappingList[e] = mapping.data.data
  }))
  // console.log("mappingList",mappingList);
  return mappingList
}

export function fetchMappingZunpulse() {
  return (dispatch) => {
    axios.get(url.pathBackend + "zunpulse/mapping")
      .then(response => {
        response = response.data;
        if (response.mapping) {
          dispatch({ type: FETCH_ALL_MAPPING_SUCCESS, payload: response.mapping })
        }
        else {
          console.log(response);
        }
      })
      .catch(e => {
        console.log(e);
      })
  }
}

export function fetchMappingZunsolar() {
  return async (dispatch) => {
    let response = await axios.get(url.pathBackend + "zunsolar/lead/mapping");
    response = response.data;
    console.log("response", response)
    dispatch({ type: FETCH_ALL_MAPPING_SUCCESS, payload: response || {} })
  }
}

export function fetchReturnOrderMapping() {
  return async(dispatch) => {
    let apiResponse = await axios.get(`${url.pathBackend}zunsolar/ordertracker/getReturnOrdersMapping`)
    apiResponse = apiResponse.data;
    if(apiResponse && apiResponse.status == "success") {
      let data = apiResponse.returnMapping;
       dispatch({ type: FETCH_RETURN_ORDERS_MAPPING, payload: data })
    }
    else {
      dispatch({ type: FETCH_RETURN_ORDERS_MAPPING, payload: {} })

    }
  }
}
export function fetchIVRMapping() {
  return async(dispatch) => {
    let apiResponse = await axios.get(`${url.pathBackend}zunsolar/ivrTicketing/fetchMapping`)
    apiResponse = apiResponse.data;
    if(apiResponse && apiResponse.status == "success") {
      let data = apiResponse.data && apiResponse.data.ticketMapping;
       dispatch({ type: FETCH_IVR_MAPPING, payload: data })
    }
    else {
      dispatch({ type: FETCH_IVR_MAPPING, payload: {} })

    }
  }
}

export function fetchDistributorIDMapping( ) {
  return async (dispatch) => {
      try {
          let response = await axios.get(`${url.pathBackend}zunsolar/lead/distributorMapping`);
          response = response.data;
          if(response.status="success"){
              response  = response.data || [];

          dispatch({ type: FETCH_DISTRIBUTOR_MAPPING, payload: response});
          return;
          }

          dispatch({ type: FETCH_DISTRIBUTOR_MAPPING, payload: [] });
      }
      catch (e) {
          console.log(e);

      }
  }
}

// async function fetchMapping(list){
//     console.log("fetchmapping is called")
//     let mappingList = {};
//         switch(true){
//             case list.includes("stages"):{
//                 console.log("INSTAGES");
//                 let data = await axios.post(url.pathBackend+"mapping/stages")

//                 mappingList.stages = data.data.data       
//             }
//             case list.includes("employees"):{
//                 let data = await axios.post(url.pathBackend+"mapping/employees")

//                 mappingList.employees = data.data.data  
//             }

//             case list.includes("saslots"):{
//                 let data = await axios.post(url.pathBackend+"mapping/saslots")

//                     mappingList.saslots=data.data.data;   
//             }
//             case list.includes("regions"):{
//                 let data = await axios.post(url.pathBackend+"mapping/regions")
//                 mappingList.regions=data.data.data;
//             }
//             case list.includes("source"):{
//                 let data = await axios.post(url.pathBackend+"mapping/source")

//                 mappingList.source=data.data.data
//             }
//             case list.includes("languages"):{
//                 let data = await axios.post(url.pathBackend+"mapping/languages")

//                 mappingList.languages=data.data.data    
//             }  
//     }
//     console.log("mappingList",mappingList)
//     return mappingList

// }