import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';

const bankOptions = [
    {label: 'IDFC 2', value: 'IDFC 2'},
    {label: 'HDFC', value: 'HDFC'},
    {label: 'RBL', value: 'RBL'},
    {label: 'CITI', value: 'CITI'},
    {label: 'Cashfree', value: 'Cashfree'},
    {label: 'TCS', value: 'TCS'}
];

class ProcurementAddPayout extends Component {

    constructor() {
        super();
        this.state = {
            payoutData: {
                type: 'payment'
            }
        };
    }

    componentWillMount() {
        this.props.fetchVendorList();
    }

    AddPayoutInput = ({ label, name, type, required = true, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <input className="form-control customInput" type={type} name={name} placeholder={label} onChange={(e) => this.handleAddPayoutInputChange(e)} {...inputParams} required={required} />
                    </div>
                </div>
            </div>
        )
    }

    AddPayoutSelect = ({ isMulti, label, name, options = [], required = true }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <Select
                            isSearchable={true}
                            placeholder={label}
                            name={name}
                            options={options}
                            onChange={(e) => this.handleAddPayoutSelectChange(e, name)}
                            isMulti={isMulti}
                        />
                    </div>
                </div>
            </div>
        )
    }

    handleAddPayoutInputChange = (e) => {
        let { name, value } = e.target;
        let { payoutData = {} } = this.state;
        payoutData[name] = value;
        this.setState({
            payoutData
        })
    }

    handleAddPayoutSelectChange = (e, name) => {
        let value = e;
        if (Array.isArray(e)) value = e.map(row => row.value);
        else value = e.value;
        let { payoutData = {} } = this.state;
        payoutData[name] = value;
        this.setState({
            payoutData
        })
    }

    handleSubmitPayoutForm = (e) => {
        e.preventDefault();
        let { payoutData = {} } = this.state;
        if(!payoutData.vendorId || !payoutData.bank) {
            alert("Required fields missing.");
            return;
        }
        this.props.addPayout(payoutData);
    }

    render() {

        let { AddPayoutInput, handleSubmitPayoutForm, AddPayoutSelect, props } = this;

        let { vendorList = [] } = props;

        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Add Payout</h4>
                <form onSubmit={handleSubmitPayoutForm}>
                    <div className="addVendorForm">
                        <div className="row">
                            <AddPayoutSelect
                                label="Vendor"
                                name="vendorId"
                                isMulti={false}
                                options={vendorList.map(row => ({label: `${row.id} ${row.companyName}`, value: row.id}))}
                                required={true}
                            />
                            <AddPayoutInput
                                label="Amount"
                                name="amount"
                                type="number"
                                required={true}
                            />
                            <AddPayoutInput
                                label="Date"
                                name="date"
                                type="date"
                                required={true}
                            />
                            <AddPayoutInput
                                label="Reference"
                                name="reference"
                                type="text"
                                required={true}
                            />
                            <AddPayoutSelect
                                label="Bank"
                                name="bank"
                                isMulti={false}
                                options={bankOptions}
                                required={true}
                            />
                        </div>
                        <button type="submit" className="btn btn-outline-success" style={{ float: 'right' }}>Submit</button>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementAddPayout);