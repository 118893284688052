import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_SINGLE_CUSTOMER, FETCH_METRICS_DATA } from './actiontypes';
import { zunpulseOrderColumns } from '../config/column_fields';
const URL = `https://zuntalks.in:3000/whatsapp/dashboard`
// const URL = `http://localhost:3001/whatsapp/dashboard`

export function fetchCustomer(id, phoneNumber) {
    return async (dispatch) => {
        try {
            let whatsappCustomer = await axios.post(`${URL}/${id}`, {key : "4VhfN1xiAD", phoneNumber : phoneNumber});
            whatsappCustomer = whatsappCustomer.data;
            dispatch({ type: FETCH_SINGLE_CUSTOMER, payload: whatsappCustomer || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchPreSalesMetrics(filter = {}) {
    return async (dispatch) => {
        try {
            let data = await axios.post(`${URL}/report/presales`, {key : "4VhfN1xiAD", ...filter});
            data = data.data;
            dispatch({ type: FETCH_METRICS_DATA, payload: data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchPostSalesMetrics(filter = {}) {
    return async (dispatch) => {
        try {
            let data = await axios.post(`${URL}/report/postsales`, {key : "4VhfN1xiAD", ...filter});
            data = data.data;
            dispatch({ type: FETCH_METRICS_DATA, payload: data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}
