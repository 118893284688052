import React from 'react';
import axios from 'axios';
import url from '../config';
import { FETCH_USERS_START, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR, FETCH_ROLES_START, FETCH_ROLES_SUCCESS, FETCH_ROLES_ERROR } from './actiontypes';

export function getUserList() {
  return (dispatch) => {

    dispatch({ type: FETCH_USERS_START, payload: {} });

    // axios.post(url.pathBackend + "admin/roles").then((res)=>{
    //   console.log("response for userslist",res)
    //   if(res.error){
    //     dispatch({type:FETCH_USERS_ERROR,payload:""})
    //   }
    //   if(res.data.data){
    //     dispatch({type:FETCH_USERS_SUCCESS,payload : [...res.data.data]})
    //   }
    // })

    getRoles(["", "get", "access/get"]).then((data) => {
      console.log("data", data);
      dispatch({ type: FETCH_USERS_SUCCESS, payload: data })
    })
  }
}

async function getRoles(list) {
  let usersList = {};
  await Promise.all(list.map(async (e) => {
    await axios.post(url.pathBackend + "admin/roles/" + e).then((res) => {

      if (res.data.error) {
        usersList["error"] = res.data.error
      } else {
        usersList[e] = res.data.data
      }
    })
  }))
  return usersList
}

export function changeRole(obj) {
  return () => {
    axios.post(url.pathBackend + "admin/roles/edit", obj).then((res) => {
      console.log(res)
    })
  }
}
export function addUserManager(obj) {
  return () => {
    axios.post(url.pathBackend + "admin/roles/adddManager", obj).then((res) => {
      console.log(res)  
    })
  }
}

export function changeAccess(obj) {
  return () => {
    axios.post(url.pathBackend + "admin/roles/access/edit", obj).then((res) => {
      console.log(res)
    })
  }
}

export function changeAdmin(obj) {
  return () => {
    axios.post(url.pathBackend + "admin/roles/admin", obj).then((res) => {
      console.log(res)
    })
  }
}
export function changeActive(obj) {
  return () => {
    axios.post(url.pathBackend + "admin/roles/active", obj).then((res) => {
      console.log(res)
    })
  }
}

export function deleteUser(obj) {
  return () => {
    axios.post(url.pathBackend + "admin/roles/remove", obj).then((res) => {
      console.log(res);
    })
  }
}