import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { downloadData } from '../../actions/downloadAcData';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';

class DownloadAcData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            received: false
        };
    }

    handleInputChange = (e) => {
        console.log(e);
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { formData } = this.state;
        this.props.downloadData(formData);
        console.log(formData);
    }

    render() {
        return (
            <div className="analyseAc">
                <Header />
                <div class="container">
                    <h4 style={{ fontWeight: "bold" }}>Download AC Data</h4>
                    <hr className="analyseAcHr"></hr>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Start Date
                            </label>
                            <div class="col-md-8">
                                <input type="date" className="form-control" name="startDate" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                End Date
                            </label>
                            <div class="col-md-8">
                                <input type="date" className="form-control" name="endDate" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                        </div>
                        <br></br>
                        <div className="callCountSubmit">
                            <button className="btn btn-success" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps() {
    return {

    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, downloadData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAcData);