import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import { fetchComboList } from '../../actions/zunpulseOrderTrackingActions';
import Header from '../../components/HeaderComponent';
import { Link } from 'react-router-dom';


class ZunpulseCombo extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      tableData: [],
      showProductsModal: false,
      comboProductList: [],
      maxProduct: 1
    }
  }

  componentWillMount() {
    this.props.fetchComboList()
  }

  componentWillReceiveProps(props) {
    let {tableData, maxProduct} =  props
    this.setState({
      tableData,
      maxProduct
    })
  }

  render() {
    let { state } = this
    let { tableData, maxProduct } = state;
    let comboColumns = [
      {
        Header: 'Combo Id',
        accessor: "comboId",
        Cell: row => {
          return(<Link to={`/zunpulse/addcombo?comboId=${row.value}`} target="_blank">{row.value}</Link>)
        }
      },
      {
        Header: 'Combo Name',
        accessor: "comboName"
      },
      {
        Header: 'Active',
        accessor: "active"
      }
    ]
    for(let i=1; i<maxProduct; i++){
      comboColumns = [...comboColumns, 
      {
        Header: `Product ${i} Id`,
        accessor: `Product ${i} Id`
      },
      {
        Header: `Product ${i} C`,
        accessor: `Product ${i} C`
      },
      {
        Header: `Product ${i} SC`,
        accessor: `Product ${i} SC`
      },
      {
        Header: `Product ${i} Q`,
        accessor: `Product ${i} Q`
      }]
    }
    
    return (
      <div>
        <Header getSearchResult={this.props.getSearchResult} />
        <div style={{ "marginTop": 80 }} className="container-fluid">
          <div className="row" style={{ margin: '20px 0px' }}>
            <div className="col-md-12" style={{ textAlign: "right" }}>
              <Link to="/zunpulse/addcombo" target="_blank"><button className="createLeadBtn"><i className="fa fa-plus"></i> New Combo</button></Link>
            </div>
          </div>
          <ReactTable
            filterable={true}
            showPagination={true}
            sortable={true}
            data={tableData}
            columns={comboColumns}
            defaultSorted={[{ id: "date", desc: true }]}
            style={{ height: "90vh", textAlign: 'center' }}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
            loading={this.state.loading}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps({ zunpulseOrders }) {
  let { tableData = [], maxProduct = 1 } = zunpulseOrders || {};
  return {
    tableData,
    maxProduct
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchComboList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseCombo)
