import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import Select from 'react-select';
import { CSVLink } from 'react-csv';

class PIInvoiceLedgerV2 extends Component {

    constructor() {
        super();
        this.state = {
            allLedger: [],
            filters: {},
            showFilter: false,
            dealerIdList: {}
        };
    }

    componentWillMount() {
        this.props.getAllPIInvoiceLedger()
    }

    componentWillReceiveProps(props) {
        let { allLedger = [] } = props
        let dealerIdList = {}
        allLedger.forEach(element => {
            dealerIdList[`${element.dealerId}`] = true
        });
        this.setState({
            allLedger,
            dealerIdList: {
                ...this.state.dealerIdList,
                ...dealerIdList
            }
        })
    }

    onApplyFilter = (e) => {
        let { filters } = this.state
        this.props.getAllPIInvoiceLedger(filters)
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[type] = filters[type] || {};
        filters[type][name] = value;
        this.setState({
            filters
        })
    }

    multiSelectType = (name, options, placeholder, onChange) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    handleDownloadFileClickByInvoiceNumber = (invoiceNumber, invoiceFileName) => {
        this.props.downloadFileByInvoiceNumber(invoiceNumber, invoiceFileName)
    }

    render() {
        let { state, handleDownloadFileClickByInvoiceNumber } = this
        let { allLedger, dealerIdList } = state
        let ledgerColumn = [
            {
                Header: 'S No.',
                accessor: 'id'
            },
            {
                Header: 'Dealer Id',
                accessor: 'dealerId'
            },
            {
                Header: 'Date',
                accessor: 'date'
            },
            {
                Header: 'Invoice Number',
                accessor: 'invoiceNumber'
            },
            {
                Header: 'Amount',
                accessor: 'amount'
            },
            {
                Header: 'Invoice Type',
                accessor: 'invoiceType'
            },
            {
                Header: 'Reference',
                accessor: 'reference'
            },
            // {
            //     Headers: 'buttons',
            //     accessor: 'invoiceNumber',
            //     Cell: row => {
            //         return (row.value?<div className="row text-center" style={{ width: 'fit-content' }}><button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleDownloadFileClickByInvoiceNumber(row.value,'invoiceDraftFile')}>Draft</button><button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleDownloadFileClickByInvoiceNumber(row.value,'invoiceApprovedFile')}>Invoice</button></div>:row.value)
            //     }
            // }
        ]
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Ledger</h4>
                <div className="topbar-section allLeadViewFilters">
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye"></i>} </b></div>
                    <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                    <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                        <div className="col-md-3">
                            {this.inputType("startDate", "Start Date", 'date', this.handleInputDateChangeFilters, 'date')}
                        </div>
                        <div className="col-md-3">
                            {this.inputType("endDate", "End Date", 'date', this.handleInputDateChangeFilters, 'date')}
                        </div>
                        <div className="col-md-3">
                            {this.multiSelectType("dealerId", ((Object.keys(dealerIdList)).map((element) => { if (element) return { label: element, value: element } })), "Dealer Id", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>
                </div>
                {/* <div className="allLeadViewFilters">
                    <CSVLink filename={"InvoiceLedgerData.csv"} data={allLedger} headers={ledgerColumn.map(val => ({ label: val.csvHeader || val.Header, key: val.accessor }))} type="button" className="btn btn-light">
                        Download <i className="fa fa-download"></i>
                    </CSVLink>
                </div> */}
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={allLedger}
                    columns={ledgerColumn}
                    defaultSorted={[{ id: "id", desc: false }]}
                />
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PIInvoiceLedgerV2);