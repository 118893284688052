import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { salesSummaryConfig, shippingSummaryConfig, orderValueConfig, subOrderValueConfig, lastTenDaysSummaryConfig } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchMapping, fetchCombo } from '../../actions/zunsolarOrderTrackerActions';
import { fetchLastEditTime, fetchSalesSummary, fetchShippingSummary, fetchOrderValue, fetchSubOrderValue, fetchLastTenDaysSummary, fetchStateSummaryData } from '../../actions/zunsolarOpsDashboardActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader'
import { SalesSummary, ShippingSummary, SubOrderValueView, OrderValueView, LastTenDaysSummary, StateSummaryView } from '../../components/zunsolar/ZunsolarOpsDashboardComponent';

class ZunsolarOpsDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            allData: [],
            viewData: [],
            salesSummaryFilters: {
                date: {
                    startDate: '',
                    end: '',
                },
                state: [],
                category: [],
                subCategory: [],
                status: []
            },
            shippingSummaryFilters: {
                date: {
                    startDate: '',
                    end: '',
                },
                state: [],
                category: [],
                subCategory: [],
                platform: []
            },
            orderValueFilters: {
                invoiceDate: {
                    startDate: '',
                    end: '',
                },
                state: [],
                platform: []
            },
            subOrderValueFilters: {
                date: {
                    startDate: '',
                    end: '',
                },
                state: [],
                category: [],
                subCategory: [],
                status: [],
                platform: []
            },
            lastTenDaysSummaryFilters: {
                state: [],
                category: [],
                subCategory: [],
                status: []
            },
            subCategoryOptions: {},
            loading: true,
            received: false,
            mapping: {},
            stateTableType: 'Qty',
            stateSummaryFilters: {
                date: {
                    startDate: '',
                    end: '',
                },
                category: "Panel",
                status: [],
                platform: []
            }
        };
    }

    componentWillMount() {
        this.props.fetchMapping();
        this.props.fetchLastEditTime();
        this.props.fetchSalesSummary({});
        this.props.fetchShippingSummary({});
        this.props.fetchOrderValue({});
        this.props.fetchSubOrderValue({});
        this.props.fetchLastTenDaysSummary({});
        this.props.fetchStateSummaryData(this.state.stateSummaryFilters, this.state.stateTableType);
        this.props.fetchCombo();
    }

    componentWillReceiveProps(props) {
        let { mapping = {}, lastEditTime, salesSummary, shippingSummary, orderValue, subOrderValue, lastTenDaysSummary, salesSummaryReceived, shippingSummaryReceived, orderValueReceived, subOrderValueReceived, lastTenDaysSummaryReceived, comboList, stateSummary, stateSummaryFields, stateSummaryReceived } = props;
        this.setState({
            loading: false,
            received: true,
            mapping,
            lastEditTime,
            salesSummary,
            shippingSummary,
            orderValue,
            subOrderValue,
            lastTenDaysSummary,
            salesSummaryReceived,
            shippingSummaryReceived,
            orderValueReceived,
            subOrderValueReceived,
            lastTenDaysSummaryReceived,
            comboList,
            stateSummary,
            stateSummaryFields,
            stateSummaryReceived
        })
    }

    handleFilterChange = (type, name, value) => {
        let { comboList = [], mapping } = this.state;
        let { category } = mapping;
        let appliedFilters = (value || []).map(option => option.value);
        if (name == 'category') {
            let subCategoryOptions = [];
            appliedFilters.map(val => {
                let options = (val == "Combos") ? comboList.map(combo => combo.comboId) : category[val];
                subCategoryOptions = [...subCategoryOptions, ...(options || [])]
            })
            this.setState({
                [type]: {
                    ...this.state[type],
                    [name]: appliedFilters
                },
                subCategoryOptions: {
                    ...this.state.subCategoryOptions,
                    [type]: subCategoryOptions
                }
            })
        }
        else if((type == "subOrderValueFilters" || type == "stateSummaryFilters" || type == "orderValueFilters") && name == "platform") {
            let updatedFilterValue = [];
            appliedFilters.forEach(value => {
                if(Array.isArray(value)) {
                    updatedFilterValue = [ ...updatedFilterValue, ...value ];
                }
                else {
                    updatedFilterValue.push(value);
                }
            })
            this.setState({
                [type]: {
                    ...this.state[type],
                    [name]: [ ...new Set(updatedFilterValue) ]
                }
            })
        }
        else {
            this.setState({
                [type]: {
                    ...this.state[type],
                    [name]: appliedFilters
                }
            })
        }
    }

    handleStateCategoryChange = (type, name, e) => {
        let { value = '' } = e;
        this.setState({
            [type]: {
                ...this.state[type],
                [name]: value
            }
        })
    }

    handleStateTableTypeChange = (e) => {
        this.setState({
            stateTableType: e.value
        })
    }

    handleDateFilterChange = (type, dateType, e) => {
        let { name, value } = e.target;
        let filters = JSON.parse(JSON.stringify(this.state[type]));
        filters[dateType][name] = value;
        this.setState({
            [type]: filters
        })
    }

    applyFilter = (type) => {
        let filters = this.state[type] || {};
        switch (type) {
            case "salesSummaryFilters":
                this.props.fetchSalesSummary(filters);
                this.setState({ salesSummaryReceived: false })
                break;
            case "shippingSummaryFilters":
                this.props.fetchShippingSummary(filters);
                this.setState({ shippingSummaryReceived: false })
                break;
            case "orderValueFilters":
                this.props.fetchOrderValue(filters);
                this.setState({ orderValueReceived: false })
                break;
            case "subOrderValueFilters":
                this.props.fetchSubOrderValue(filters);
                this.setState({ subOrderValueReceived: false })
                break;
            case "lastTenDaysSummaryFilters":
                this.props.fetchLastTenDaysSummary(filters);
                this.setState({ lastTenDaysSummaryReceived: false })
                break;
            case "stateSummaryFilters":
                this.props.fetchStateSummaryData(filters, this.state.stateTableType);
                this.setState({ stateSummaryReceived: false })
                break;
            default:
                break;
        }
    }

    render() {

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        let { showColumns, viewData, mapping = {}, filters, lastEditTime, salesSummary, shippingSummary, orderValue, subOrderValue, lastTenDaysSummary, salesSummaryReceived, shippingSummaryReceived, orderValueReceived, subOrderValueReceived, lastTenDaysSummaryReceived, subCategoryOptions, stateSummaryReceived, stateSummary, stateSummaryFields, stateTableType } = this.state;
        let { handleFilterChange, handleDateFilterChange, applyFilter, handleStateCategoryChange, handleStateTableTypeChange, getStateColumn } = this;
        let { platform, states, category, status } = mapping;
        let platformOptions = (platform || []).map(option => ({ label: option, value: option }))
        let categoryOptions = Object.keys(category || {}).map(category => ({ label: category, value: category }));
        let statusOptions = (status || []).map(status => ({ label: status, value: status }));
        let stateOptions = (states || []).map(state => ({ label: state, value: state }))
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <b>Last Edited - {lastEditTime}</b>
                    <SalesSummary
                        data={salesSummary}
                        columns={salesSummaryConfig}
                        loading={!salesSummaryReceived}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        categoryOptions={categoryOptions}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummaryFilters"}
                        subCategoryOptions={(subCategoryOptions["salesSummaryFilters"] || []).map(option => ({ label: option, value: option }))}
                        applyFilter={applyFilter}
                    />
                    <ShippingSummary
                        data={shippingSummary}
                        columns={shippingSummaryConfig}
                        loading={!shippingSummaryReceived}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        categoryOptions={categoryOptions}
                        platformOptions={platformOptions}
                        type={"shippingSummaryFilters"}
                        subCategoryOptions={(subCategoryOptions["shippingSummaryFilters"] || []).map(option => ({ label: option, value: option }))}
                        applyFilter={applyFilter}
                    />
                    <OrderValueView
                        data={orderValue}
                        columns={orderValueConfig}
                        loading={!orderValueReceived}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        categoryOptions={categoryOptions}
                        platformOptions={[ {label: 'Online Sales', value: ["Amazon", "Flipkart", "Snapdeal", "Zunsolar", "app", "Industry Buying", "Moglix"]}, {label: 'Offline Sales', value: ["Dealer", "Distributor"]}, ...(platformOptions || [] ) ]}
                        shippingStatusOptions={statusOptions}
                        type={"orderValueFilters"}
                        subCategoryOptions={(subCategoryOptions["orderValueFilters"] || []).map(option => ({ label: option, value: option }))}
                        applyFilter={applyFilter}
                        filterValues={this.state["orderValueFilters"] || {}}
                    />
                    <SubOrderValueView
                        data={subOrderValue}
                        columns={subOrderValueConfig}
                        loading={!subOrderValueReceived}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        categoryOptions={categoryOptions}
                        platformOptions={[ {label: 'Online Sales', value: ["Amazon", "Flipkart", "Snapdeal", "Zunsolar", "app", "Industry Buying", "Moglix"]}, {label: 'Offline Sales', value: ["Dealer", "Distributor"]}, ...(platformOptions || [] ) ]}
                        shippingStatusOptions={statusOptions}
                        type={"subOrderValueFilters"}
                        subCategoryOptions={(subCategoryOptions["subOrderValueFilters"] || []).map(option => ({ label: option, value: option }))}
                        applyFilter={applyFilter}
                        filterValues={this.state["subOrderValueFilters"] || {}}
                    />
                    <LastTenDaysSummary
                        data={lastTenDaysSummary}
                        columns={lastTenDaysSummaryConfig}
                        loading={!lastTenDaysSummaryReceived}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        categoryOptions={categoryOptions}
                        shippingStatusOptions={statusOptions}
                        type={"lastTenDaysSummaryFilters"}
                        subCategoryOptions={(subCategoryOptions["lastTenDaysSummaryFilters"] || []).map(option => ({ label: option, value: option }))}
                        applyFilter={applyFilter}
                    />
                    <StateSummaryView
                        data={stateSummary}
                        columns={stateSummaryFields}
                        loading={!stateSummaryReceived}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        handleStateCategoryChange={handleStateCategoryChange}
                        categoryOptions={categoryOptions}
                        platformOptions={[ {label: 'Online Sales', value: ["Amazon", "Flipkart", "Snapdeal", "Zunsolar", "app", "Industry Buying", "Moglix"]}, {label: 'Offline Sales', value: ["Dealer", "Distributor"]}, ...(platformOptions || [] ) ]}
                        shippingStatusOptions={statusOptions}
                        type={"stateSummaryFilters"}
                        applyFilter={applyFilter}
                        filterValues={this.state["stateSummaryFilters"] || {}}
                        handleStateTableTypeChange={handleStateTableTypeChange}
                        stateTableType={stateTableType}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps({ zunSolarOrderTracker, zunSolarOpsDashboard = {} }) {
    let { mapping = {}, comboList = [] } = zunSolarOrderTracker || {};
    let { lastEditTime = '', salesSummary = [], shippingSummary = [], orderValue = [], subOrderValue = [], lastTenDaysSummary = [], salesSummaryReceived = false, shippingSummaryReceived = false, orderValueReceived = false, subOrderValueReceived = false, lastTenDaysSummaryReceived = false, stateSummary = [], stateSummaryFields = [], stateSummaryReceived = false } = zunSolarOpsDashboard;
    return {
        mapping,
        lastEditTime,
        salesSummary,
        shippingSummary,
        orderValue,
        subOrderValue,
        lastTenDaysSummary,
        salesSummaryReceived,
        shippingSummaryReceived,
        orderValueReceived,
        subOrderValueReceived,
        lastTenDaysSummaryReceived,
        comboList,
        stateSummary,
        stateSummaryFields,
        stateSummaryReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchLastEditTime, fetchSalesSummary, fetchShippingSummary, fetchMapping, fetchOrderValue, fetchSubOrderValue, fetchLastTenDaysSummary, fetchCombo, fetchStateSummaryData, getSearchResult }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarOpsDashboard);
