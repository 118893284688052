import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import '../styles/reactTable.css'
import '../styles/allLeadsView.css';
import { Link } from 'react-router-dom';
import Select, { NonceProvider } from 'react-select';
import moment from 'moment';
import swal from 'sweetalert';
import 'react-dates/initialize';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Loader } from '../components/loader'
import { element } from 'prop-types';
import { config } from 'react-transition-group';


class tableView extends Component {

  constructor(props) {

    super(props);
    this.reactTable = React.createRef();
    this.state = {
      addedon: { startDate: '', endDate: '' },
      lastReachOut: { startDate: '', endDate: '' },
      recieved: false,
      loading: true,
      stages: [],
      responsible: [],
      source: [],
      regions: [],
      states: [],
      reasonForVerified: [],
      languages: [],
      updatedByOptions: [],
      ACTIVE: [1, 0],
      highValue: [{ value: 0, label: 0 }, { value: 1, label: 1 }],
      active: [{ value: 1, label: 1 }, { value: 0, label: 0 }],
      modified: [{ value: "yes", label: "yes" }, { value: "no", label: "no" }],
      product: [
        { value: "Grid Tied", label: "Grid Tied" },
        { value: "Off Grid", label: "Off Grid" },
        { value: "Hybrid", label: "Hybrid" },
        { value: "Inverter Backup", label: "Inverter Backup" },
        { value: "Street Light", label: "Street Light" },
        { value: "Water Pump", label: "Water Pump" },
        { value: "Affiliate", label: "Affiliate" },
        { value: "PV Panel", label: "PV Panel" },
        { value: "Diesel Generator", label: "Diesel Generator" },
        { value: "Solar AC", label: "Solar AC" },
        { value: "Water Heater", label: "Water Heater" }
      ],
      saType: ["prepaid", "postpaid", "SA ONSITE", "VIDEO ASSESSMENT"],
      propertyType: [
        { value: "Residential", label: "Residential" },
        { value: "Commercial", label: "Commercial" },
        { value: "Government", label: "Government" },
        { value: "Trust Run Institution", label: "Trust Run Institution" },
        { value: "SME", label: "SME" },
        { value: "Society", label: "Society" }
      ],
      // ["not selected", "Residential", "Commercial", "Government", "Trust Run Institution"],
      isOpinion: [{ value: "yes", label: "yes" }, { value: "no", label: "no" }],
      saCount: [1, 2, 3, 4, 5, ">5"],
      allLeadData: [],
      tableData: [],
      mappingData: {},
      limit: 100,
      limitoption: [
        { value: 500, label: '500' },
        { value: 1000, label: '1000' },
        { value: 5000, label: '5000' }
      ],
      page: 1,
      showFilter: false,
      filterMap: {
        stage: true,
        active: true,
        addedOn: true,
        saDate: true,
        responsible: true,
        region: true,
        // created: true,
        source: true,
        highValue: true,
        state: true,
        // modified: true,
        languages: true,
        zipcode: true,
        saPaid: false,
        nextActionDate: true,
        reasonForVerified: false,
        product: false,
        modifiedOn: true,
        updatedBy: true,
        saType: false,
        lastReachOut: true
      },
      tableMap: {
        bitrixId: true,
        name: true,
        stage: true,
        active: true,
        addedOn: true,
        saDate: true,
        responsible: true,
        region: true,
        created: true,
        source: true,
        highValue: true,
        state: true,
        nextActionDate: true,
        quoteId: true,
        sanctioned_load: true,
        whatsapp_sa: true,
        zip_code: true,
        lastReachOut: true
      },
      ZIP_CODE: '',
      filterObj: {},
      filterData: {},
      filterDate: {},
      savedFilters: {},
      selectedBitrixIds: {},
      count: '',
      filterName: '',
      selectAll: false,
      bulkAssign: {},
      // dummy: [
      //   { id: 2, STATUS_ID: "morning" },
      //   { id: 1, STATUS_ID: "evening" },
      // ],
      startDate: '',
      endDate: '',
      // startDate: moment().subtract(29, 'days'),
      // endDate: moment(),
      focusedInput: null,
      // ranges: {
      //   'Today': [moment(), moment()],
      //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      //   'This Month': [moment().startOf('month'), moment().endOf('month')],
      //   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      // },
    }
  }

  componentDidMount() {
    this.setState({
      filterMap: localStorage.getItem("filterMap") ? JSON.parse(localStorage.getItem("filterMap")) : this.state.filterMap,
      tableMap: localStorage.getItem("tableMap") ? JSON.parse(localStorage.getItem("tableMap")) : this.state.tableMap,
      savedFilters: localStorage.getItem("savedFilters") ? JSON.parse(localStorage.getItem("savedFilters")) : this.state.savedFilters,
    })
  }

  componentWillReceiveProps(props) {

    // console.log("searchData", props.allMapping.reasonForVerified)
    let stages = this.optionFunction(props.allMapping.stages ? props.allMapping.stages : [], "STATUS_NAME");
    let source = this.optionFunction(props.allMapping.source ? props.allMapping.source : [], "source_name");
    let responsible = this.optionFunction(props.allMapping.employees ? props.allMapping.employees : [], "e_name");
    let states = this.optionFunctionState(props.allMapping.stateregion ? Object.keys(props.allMapping.stateregion) : []);
    let languages = this.optionFunction(props.allMapping.languages ? props.allMapping.languages : [], "language_name");
    let reasonForVerified = this.optionFunctionState(props.allMapping.reasonForVerified ? props.allMapping.reasonForVerified : []);
    let updatedByOptions = (props.allMapping.employees || []).map(employee => ({ label: employee.e_name, value: employee.e_email }))
    // let propertyType = this.optionFunctionState(this.state.propertyType);
    // let isOpinion = this.optionFunctionState(this.state.isOpinion);
    let mappingData = props.allMapping
    if (props.searchData.length > 0) {
      this.setState({
        allLeadData: props.searchData,
        tableData: props.searchData,
        stages: stages,
        source: source,
        states: states,
        responsible: responsible,
        mappingData: mappingData,
        count: props.searchData.length,
        loading: false,
        recieved: true,
        updatedByOptions
      })
    } if (Object.keys(props.allLeads).length > 0 && Object.keys(props.allMapping).length > 0) {
      this.setState({
        stages: stages,
        source: source,
        states: states,
        reasonForVerified: reasonForVerified,
        // propertyType : propertyType,
        // isOpinion : isOpinion,
        responsible: responsible,
        allLeadData: props.allLeads.data,
        tableData: props.allLeads.data,
        languages: languages,
        mappingData: mappingData,
        count: props.allLeads.count,
        loading: false,
        recieved: true,
        updatedByOptions
      })
    }
  }

  filterChanged = (e) => {
    this.setState({
      selectedBitrixIds: {},
      selectAll: false
    })
  }



  optionFunction = (array, value) => {
    let multiSelectOptionsMap = array.map((data) => {
      let obj = {
        label: data[value].replace(/(^"|"$)/g, ''),
        value: data[value].replace(/(^"|"$)/g, '')
      }
      return obj
    })
    return multiSelectOptionsMap;
  }

  optionFunctionState = (array) => {
    let multiSelectOptionsStateMap = array.map((val) => {
      let obj = {
        label: val.replace(/(^"|"$)/g, ''),
        value: val.replace(/(^"|"$)/g, ''),
      }
      return obj;
    })
    return multiSelectOptionsStateMap
  }

  handleSelectChange = (e) => {
    let { name, value } = e.target;
    // console.log(name, value);
    value = this.checkSelectString(value);
    this.setState({
      bulkAssign: {
        ...this.state.bulkAssign,
        [name]: value
      }
    }, () => {
      console.log("bulk", this.state.bulkAssign)
    })
  }

  handleMultiSelectChange = (e, name) => {
    // console.log("event for multiselect", e)

    let selectedList = (e || []).map((data) => {
      return data.value
    });
    if(name == "SOURCE_ID" && selectedList.includes('*')) {
      selectedList = (this.state.source || []).map(row => row.value);
    }
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: selectedList
      },
      page: 1
    }, () => {
      // console.log("filterData after multiSelect", this.state.filterData)
    })

  }

  handleDateChange = (e, field) => {
    let { name, value } = e.target
    // console.log(name, value)
    this.setState({
      filterData: {
        ...this.state.filterData,
        [field]: {
          ...this.state.filterData[field] ? this.state.filterData[field] : {},
          [name]: value
        }
      },
      page: 1
    }, () => {
      // console.log("filterData", this.state.filterData)
    });
  }

  handleInputChange = (e) => {
    let { name, value } = e.target
    // console.log("handleInpitCHnage", name, value)
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: value,
      }
    })
  }

  handleSingleSelectChange = (e) => {
    // console.log(e.target.value);
    let { name, value } = e.target
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: value
      }
    })
  }

  handleStateChange = (e, name) => {
    // console.log("eeeeeeeee", e)
    // console.log("inside this")
    let regionArr = []
    let selectedState = (e || []).map((data) => {
      regionArr = regionArr.concat(this.props.allMapping.stateregion[data.label])
      return data.label
    });
    this.setState({
      regions: regionArr,
      filterData: {
        ...this.state.filterData,
        [name]: selectedState,
        REGION: regionArr
      }
    })
  }

  multiSelectType = (name, options, placeholder, value) => {
    return (
      <div className="form-group" style={{ width: '100%' }}>
        <Select
          isMulti
          isSearchable
          onChange={(e) => { this.handleMultiSelectChange(e, name) }}
          // placeholder={placeholder}
          name={name}
          options={options}
          value={value}
        />
      </div>
    )
  }

  singleSelectType = (name, options, value) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleSingleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>Select</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }
  selectType = (name, options, value) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {
    return (
      <div style={{ width: '100%' }} >
        <select style={{ height: '35px' }}
          onChange={(e) => { this.handleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  inputTypeText = (name, value, type, placeholder) => {

    return <input name={name} type={type} placeholder={placeholder} className="form-control" value={value} onChange={(e) => this.handleInputChange(e)} />

  }

  inputType = (name, value, type, placeholder) => {

    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleSelectChange(e)} />

  }

  dateType = (name, value, field) => {
    return (
      <div class="form-group">
        <span class="dateSideBar" />
        <input name={name} style={{ width: "100%" }} className="form-control" type="date" onChange={(e) => { this.handleDateChange(e, field) }} value={value}></input>
      </div>
    )
  }

  fetchLeads = (e) => {
    this.setState({
      loading: true
    })
    let { limit, page, allLeadData } = this.state;
    if (Object.keys(this.state.filterData).length > 0) {
      let shallowObj = { ...this.state.filterData };
      let deepObj = JSON.parse(JSON.stringify(shallowObj));
      let filterObj = this.removingNullKeys(deepObj);
      this.props.fetchAllLeads(limit, page + 1, allLeadData, filterObj);
    } else {
      this.props.fetchAllLeads(limit, page + 1, allLeadData)
    }
    this.setState({
      page: page + 1,
      // loading : false
    })
  }
  selectFetchLeads = (lim, e) => {
    this.setState({
      page: 1,
      limit: lim,
      allLeadData: [],
      loading: true
    },
      () => {
        let { limit, page, allLeadData } = this.state;
        this.props.fetchAllLeads(limit, page, allLeadData)
      })
  }

  handleRefresh = (e) => {
    this.props.fetchAllLeads(100, 1, []);
    this.setState({
      loading: true
    })
  }

  handleFilterToggle = (e) => {
    let val = !this.state.showFilter;
    this.setState({
      showFilter: val
    })
  }

  handleShowFilter = (e) => {
    let obj = { ...this.state.filterMap }
    obj[e.target.value] = e.target.checked

    this.setState({
      filterMap: {
        ...obj,
      }
    }, () => {
      localStorage.setItem("filterMap", JSON.stringify(obj))
    })
  }

  handleShowTable = (e) => {
    let obj = { ...this.state.tableMap }
    obj[e.target.value] = e.target.checked
    this.setState({
      tableMap: {
        ...obj
      }
    }, () => {
      localStorage.setItem("tableMap", JSON.stringify(obj))
    })
  }

  //-------------------------------------------Bulk Assign Functions-------------------------------------//

  handleCheckbox = (e, bitrixId, row, index) => {
    if (e.nativeEvent.shiftKey == true) {
      let selectedBitrixIds = { ...this.state.selectedBitrixIds }
      let sortedArray = this.reactTable.current.state.sortedData;
      let selectedCheckboxCount = Object.keys(this.state.selectedBitrixIds || {}).filter((val) => this.state.selectedBitrixIds[val] == 1);
      let indexees = [];
      let lastSelectedIndex = -1;
      sortedArray.map((lead, index) => {
        if (selectedBitrixIds[lead.BITRIX_ID]) {
          lastSelectedIndex = index;
        }
      })
      if (lastSelectedIndex == -1) {
        selectedBitrixIds[bitrixId] = 1
      } else {
        let currentIndex = sortedArray.findIndex(row => row.BITRIX_ID == bitrixId);
        let [start, end] = currentIndex > lastSelectedIndex ? [lastSelectedIndex, currentIndex] : [currentIndex, lastSelectedIndex];
        for (let i = start; i <= end; i++) {
          selectedBitrixIds[sortedArray[i].BITRIX_ID] = 1;
        }
      }
      this.setState({
        selectedBitrixIds: selectedBitrixIds
      })

      //======code below is for multi-select-checkbox in n*n===========//

      // selectedCheckboxCount.map((bitrixId) => {
      //   sortedArray.map((lead) => {
      //     if (lead.BITRIX_ID == bitrixId) {
      //       let index = sortedArray.indexOf(lead);
      //       indexees.push(index)
      //     }
      //   })
      // })
      // console.log("indexees", indexees);
      // let currentIndex = sortedArray.findIndex(row => row.BITRIX_ID == bitrixId);
      // console.log("currentIndex", currentIndex);
      // let previousIndex = Math.max(...indexees);
      // console.log("previousIndex", previousIndex)
      // let difference = [];
      // if (previousIndex < currentIndex) {
      //   difference = sortedArray.slice(previousIndex, currentIndex + 1)
      // } else {
      //   difference = sortedArray.slice(currentIndex, previousIndex)
      // }
      // console.log("difference", difference);

      // difference.map((lead) => {
      //   console.log("lead", lead.BITRIX_ID)
      //   selectedBitrixIds[lead.BITRIX_ID] = 1;
      //   console.log(selectedBitrixIds)
      // })
      // this.setState({
      //   selectedBitrixIds: { ...selectedBitrixIds }
      // }, () => { console.log("1234", this.state.selectedBitrixIds) })

      //==============================================//

    } else {
      let status = this.state.selectedBitrixIds[bitrixId];
      let selectedBitrixId = JSON.parse(JSON.stringify(this.state.selectedBitrixIds))
      let selectedBitrixIds = { ...selectedBitrixId, [bitrixId]: status ? 0 : 1, };
      this.setState({
        selectedBitrixIds: selectedBitrixIds,
        selectAll: this.state.selectAll && status ? false : this.state.selectAll
        // selectedBitrixIds: [...selectedBitrixIds]
      }, () => {
      })
    }
  }

  handleAllCheckbox = (e, row) => {
    let page = this.reactTable.current.state.page;
    let pageSize = this.reactTable.current.state.pageSize;
    let start = page * pageSize;
    let end = (page * pageSize + pageSize);
    let actualEnd = (row.data.length > end) ? end : row.data.length
    let data = row.data;
    let selectedBitrixIds = {};
    // console.log("start", start, end, data);
    if (!this.state.selectAll) {
      for (let i = start; i <= (actualEnd - 1); i++) {
        selectedBitrixIds[data[i].BITRIX_ID] = 1
      }
    }
    this.setState({
      selectedBitrixIds: { ...selectedBitrixIds },
      selectAll: !(this.state.selectAll)
    })
  }

  handleBulkAssign = (e) => {
    let tableData = [...this.state.tableData];
    let selectedBitrixIds = { ...this.state.selectedBitrixIds };
    let bulkAssign = { ...this.state.bulkAssign };
    let count = this.state.count;

    this.props.editBulkLeads(selectedBitrixIds, tableData, bulkAssign, count);
    // let arr = []
    // Object.keys(selectedBitrixIds).map((val) => {
    //   if (selectedBitrixIds[val] == 1) {
    //     arr.push(val)
    //   }
    // })

    // let lead = {
    //   BITRIX_ID: arr,
    //   ...obj
    // }


    // Object.keys(selectedBitrixIds).map((bitrixId) => {

    //   let index = this.state.tableData.findIndex((val) => val.BITRIX_ID == bitrixId);
    //   Object.keys(obj).map((field) => {
    //     console.log("tableData", tableData, "tableDatataindeddd", tableData[index])
    //     tableData[index][field] = obj[field];
    //   })
    // })

    this.setState({
      // tableData: [...tableData],
      // loading: true
    })
  }

  //---------------------------------bulk assign function ends----------------------------------------//

  //---------------------------------save custom filter functions--------------------------------------//

  handleModalInput = (e) => {
    let { value } = e.target
    this.setState({
      filterName: value
    })
  }

  handleSavedFilterClick = (e, filter) => {
    let savedFilters = { ...this.state.savedFilters };
    let newFilterData = savedFilters[filter];
    this.setState({
      filterData: {
        ...newFilterData
      }
    }, () => {
    })
  }

  handleSavedFilterDelete = (e, button) => {
    let savedFilters = { ...this.state.savedFilters }
    delete savedFilters[button];
    this.setState({
      savedFilters: { ...savedFilters }
    }, () => {
      localStorage.setItem("savedFilters", JSON.stringify(this.state.savedFilters))
    })
  }

  onSaveFilter = (e) => {
    let name = this.state.filterName;
    let filterObj = { ...this.state.filterData };
    filterObj = this.removingNullKeys(filterObj);
    if (Object.keys(this.state.savedFilters).length < 5) {
      if (Object.keys(filterObj).length > 0) {
        this.setState({
          savedFilters: {
            ...this.state.savedFilters,
            [name]: filterObj
          },
          filterName: ""
        }, () => {
          localStorage.setItem("savedFilters", JSON.stringify(this.state.savedFilters))
        })
      } else {
        swal({
          text: "No filters Selected"
        })
      }

    } else {
      swal({
        text: "You can save upto 5 filters",
        button: {
          cancel: "close"
        }
      })
    }
  }

  //-------------------------------------save custom filter function ends-------------------------------//

  removingNullKeys = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  onApplyFilter = (e) => {
    let shallowObj = { ...this.state.filterData };
    let deepObj = JSON.parse(JSON.stringify(shallowObj));
    let filterObj = this.removingNullKeys(deepObj);
    this.setState({
      loading: true
    })
    let { limit, page } = this.state;

    this.props.fetchAllLeads(limit, page, [], JSON.parse(JSON.stringify(filterObj)));
    this.setState({
      filterData: shallowObj
    })
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }

  render() {

    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }

    const columns = [
      {
        Header:
          <div>
            <i style={{ cursor: "pointer" }} data-toggle="modal" data-target="#selectTableOptionsModal" class="fa fa-cog fa-lg"></i><br></br>
          </div>,
        width: 40,
        filterable: false,
        sortable: false,
        columns: false,

      },
      {
        Header: row => {
          return (
            <div>
              <input type="checkbox" onChange={(e) => { this.handleAllCheckbox(e, row) }} checked={this.state.selectAll ? 'checked' : ''} ></input>
            </div>
          )
        },
        width: 50,
        filterable: true,
        sortable: false,
        columns: false,
        Filter: () => {
          let selectedCheckboxCount = Object.keys(this.state.selectedBitrixIds || {}).filter((val) => this.state.selectedBitrixIds[val] == 1);
          return (
            <div>{selectedCheckboxCount.length}</div>
          )
        },
        Cell: row => (
          <div>
            <input type="checkbox" onChange={(e) => { this.handleCheckbox(e, row.original.BITRIX_ID, row.original, row.index) }} checked={this.state.selectedBitrixIds[row.original.BITRIX_ID] ? 'checked' : ''} ></input>
          </div>
        )
      },
      {
        Header: 'Bitrix ID',
        accessor: 'BITRIX_ID',
        // width: 150,
        show: this.state.tableMap.bitrixId ? true : false,
        Cell: row => (
          <div>
            <Link to={"/lead/details/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/lead/details/" + row.value); }}>{row.value}</Link>
          </div>
        )
      },
      {
        Header: 'Name',
        accessor: 'TITLE',
        show: this.state.tableMap.name ? true : false,
        // width: 200,

      },
      {
        Header: 'Stage',
        accessor: 'STATUS_ID',
        show: this.state.tableMap.stage ? true : false,
        // width: 220,
      },
      {
        Header: 'Quote ID',
        accessor: 'QUOTE_ID',
        show: this.state.tableMap.quoteId ? true : false,
      },
      {
        Header: 'SA Date',
        accessor: 'SITE_ASSESSMENT_DATE',
        show: this.state.tableMap.saDate ? true : false,
        // width: 200,
        Cell: row => {
          return (
            row.value ? moment(row.value).format("DD, MMM YYYY") : ''
          )
        }
      },
      {
        Header: 'Responsible',
        accessor: 'N_RESPONSIBLE',
        show: this.state.tableMap.responsible ? true : false,
        // width: 200,
      },
      {
        Header: 'Next Action Date',
        accessor: 'NEXT_ACTION_DATE',
        show: this.state.tableMap.nextActionDate ? true : false,
        // width: 200,
        Cell: row => {
          return (
            row.value ? moment(row.value).format("DD, MMM YYYY") : ''
          )
        }
      },
      {
        Header: 'Region',
        accessor: 'REGION',
        show: this.state.tableMap.region ? true : false,
        // width: 200
      },
      {
        Header: 'State',
        accessor: 'STATE',
        show: this.state.tableMap.state ? true : false,
        // width: 200,
      },
      {
        Header: 'Zipcode',
        accessor: 'ZIP_CODE',
        show: this.state.tableMap.zip_code ? true : false,
      },
      {
        Header: 'Source',
        accessor: 'SOURCE_ID',
        show: this.state.tableMap.source ? true : false,
        // width: 200,
      },
      {
        Header: 'Sanctioned Load',
        accessor: 'SANCTIONED_LOAD',
        show: this.state.tableMap.sanctioned_load ? true : false,
        // width: 200,
      },
      {
        Header: 'Added On',
        accessor: 'ADDED_ON',
        show: this.state.tableMap.addedOn ? true : false,
        // width: 200,
        Cell: row => {
          return (
            row.value ? moment(row.value).format("YYYY-MM-DD") : ''
          )
        }
      },
      {
        Header: 'Last reach out',
        accessor: 'lastReachOut',
        show: this.state.tableMap.lastReachOut ? true : false,
        // width: 200,
        Cell: row => {
          return (
            row.value ? moment(row.value).format("YYYY-MM-DD") : ''
          )
        }
      },
      {
        Header: 'Sales Date',
        accessor: 'SALES_DATE',
        Cell: row => {
          return (
            row.value ? moment(row.value).format("YYYY-MM-DD") : ''
          )
        }
      },
      {
        Header: 'WhatsApp SA',
        accessor: 'WHATSAPP_SA',
        show: this.state.tableMap.whatsapp_sa ? true : false,
        // width: 200,
      },
      {
        Header: 'Active',
        accessor: 'ACTIVE',
        // width: 200,
        show: this.state.tableMap.active ? true : false
      },
      {
        Header: 'High Value',
        accessor: 'HIGH_VALUE',
        // width: 200,
        show: this.state.tableMap.highValue ? true : false
      },
      {
        Header: 'SA Type',
        accessor: 'SA_TYPE'
      }

    ]

    const filterOptions = [
      { label: 'Stage', value: "stage" },
      { label: "Active", value: "active" },
      { label: "Added On", value: "addedOn" },
      { label: "SA Date", value: "saDate" },
      { label: "Responsible", value: "responsible" },
      { label: "Region", value: "region" },
      { label: "Next Action Date", value: "nextActionDate" },
      { label: "Source", value: "source" },
      { label: "High Value", value: "highValue" },
      { label: "State", value: "state" },
      { label: "Reason For Verified", value: "reasonForVerified" },
      { label: "Languages", value: "languages" },
      { label: "SA Paid", value: "saPaid" },
      { label: "Zipcode", value: "zipcode" },
      { label: "Product", value: "product" },
      { label: "Modified By", value: "updatedBy" },
      { label: "Modified On", value: "modifiedOn" },
      { label: "SA Type", value: "saType" },
      { label: "Last reach out", value: "lastReachOut" },
    ]
    const tableOptions = [
      { label: 'ID', value: 'bitrixId' },
      { label: 'Name', value: 'name' },
      { label: 'Stage', value: "stage" },
      { label: "Active", value: "active" },
      { label: "Added On", value: "addedOn" },
      { label: "SA Date", value: "saDate" },
      { label: "Responsible", value: "responsible" },
      { label: "Region", value: "region" },
      { label: "Created", value: "created" },
      { label: "Source", value: "source" },
      { label: "High Value", value: "highValue" },
      { label: "State", value: "state" },
      { label: "Next Action Date", value: "nextActionDate" },
      { label: "Quote Id", value: "quoteId" },
      { label: "Sanctioned Load", value: "sanctioned_load" },
      { label: "WhatsApp SA", value: "whatsapp_sa" },
      { label: "Zipcode", value: "zip_code" },
      { label: "Last reach out", value: "lastReachOut" }
    ]

    if (this.state.recieved) {
      return (
        <div className="container-fluid" style={{ paddingTop: "50px", backgroundColor: "#f7f7f7" }}>

          <div className="topbar-section allLeadViewFilters" style={this.props.searchData.length > 0 ? style.hide : style.show}>
            <div className="pull-right">
              <i data-toggle="modal" data-target="#selectFilterModal" onClick={this.handleFilterModal} class="fa fa-cog" style={{ float: "right", fontSize: "25px", cursor: "pointer" }} aria-hidden="true"></i>
              {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                              Launch demo modal
                          </button> */}
              {/* modal body */}
              <div className="modal fade" id="selectFilterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Select Filters</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        {filterOptions.map(option => {
                          return <div className="col-md-6"><input name="filterMap" type="checkbox" value={option.value} checked={this.state.filterMap[option.value]} onChange={this.handleShowFilter} /> <span>{option.label}</span></div>
                        })}
                      </div>
                    </div>
                    {/* <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b>
              <span style={Object.keys(this.state.savedFilters).length > 0 ? style.show : style.hide}>

                {Object.keys(this.state.savedFilters).map((button) => {
                  return (
                    <span style={{ marginLeft: "20px" }}>
                      <button class="btn btn-outline-secondary savedFilterBtn" onClick={(e) => this.handleSavedFilterClick(e, button)}>{button}</button>
                      {/* <button class="btn btn-outline editFilterName"><i class="fa fa-pencil"></i></button> */}
                      <button class="btn btn-outline deleteFilterBtn"><i onClick={(e) => this.handleSavedFilterDelete(e, button)} class="fa fa-times trashButton"></i></button>
                    </span>
                  )
                })}

              </span>
            </div>
            <hr style={this.state.showFilter ? style.show : style.hide}></hr>
            <div className="row" style={this.state.showFilter ? style.show : style.hide}>
              {/* <div class="col-md-2">
                          <DatetimeRangePicker
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}

                          >
                              <input type="text" />
                          </DatetimeRangePicker>
                      </div> */}

              <div className="col-md-2" style={this.state.filterMap.stage ? style.show : style.hide}>
                <label>Stage</label>
                {this.multiSelectType("STATUS_ID", this.state.stages, "Stage", (this.state.filterData.STATUS_ID || []).map((val) => ({ label: val, value: val })))}
                {/* {this.selectType("stages",this.state.mappingData.stages?this.state.mappingData.stages:[],"Stage","STATUS_NAME",this.state.filterData.stages,)} */}
              </div>
              <div className="col-md-2" style={this.state.filterMap.active ? style.show : style.hide}>
                <label>Active</label>
                {this.multiSelectType("ACTIVE", this.state.active, "Active", (this.state.filterData.ACTIVE || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.addedOn ? style.show : style.hide}>
                <label>Added On Start</label>
                {this.dateType("startDate", this.state.filterData.ADDED_ON ? this.state.filterData.ADDED_ON.startDate : "", "ADDED_ON")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.addedOn ? style.show : style.hide}>
                <label>Added On End</label>
                {this.dateType("endDate", this.state.filterData.ADDED_ON ? this.state.filterData.ADDED_ON.endDate : "", "ADDED_ON")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.saDate ? style.show : style.hide}>
                <label>SA Date Start</label>
                {this.dateType("startDate", this.state.filterData.SITE_ASSESSMENT_DATE ? this.state.filterData.SITE_ASSESSMENT_DATE.startDate : "", "SITE_ASSESSMENT_DATE")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.saDate ? style.show : style.hide}>
                <label>SA Date End</label>
                {this.dateType("endDate", this.state.filterData.SITE_ASSESSMENT_DATE ? this.state.filterData.SITE_ASSESSMENT_DATE.endDate : "", "SITE_ASSESSMENT_DATE")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.responsible ? style.show : style.hide}>
                <label>Responsible</label>
                {this.multiSelectType("N_RESPONSIBLE", this.state.responsible, "Responsible", (this.state.filterData.N_RESPONSIBLE || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.state ? style.show : style.hide}>
                <label>State</label>
                {/* {this.multiSelectType("Responsible", this.state.mappingData.stages ? this.state.mappingData.stages : [], "State", "STATUS_NAME")}*/}
                <div className="form-group" style={{ width: '100%' }}>
                  <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { this.handleStateChange(e, "STATE") }}
                    placeholder="State "
                    name="STATE"
                    options={this.state.states}
                    value={(this.state.filterData.STATE || []).map((val) => ({ label: val, value: val }))}
                  />
                </div>
              </div>

              <div className="col-md-2" style={this.state.filterMap.region ? style.show : style.hide}>
                <label>Region</label>
                {/* {this.multiSelectType("ADDRESS_REGION", this.state.mappingData.stages ? this.state.mappingData.stages : [], "Region", "STATUS_NAME")} */}
                <div className="form-group" style={{ width: '100%' }}>
                  <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { this.handleMultiSelectChange(e, "REGION") }}
                    placeholder="Region"
                    name="ADDRESS_REGION"
                    defaultValue={this.optionFunctionState(this.state.regions ? this.state.regions : [])}
                    options={this.optionFunctionState(this.state.regions ? this.state.regions : [])}
                    value={(this.state.filterData.REGION || []).map((val) => ({ label: val, value: val }))}
                  />
                </div>
              </div>

              <div className="col-md-2" style={this.state.filterMap.source ? style.show : style.hide}>
                <label>Source</label>
                {this.multiSelectType("SOURCE_ID", [ {label: 'Select All', value: '*'}, ...this.state.source ], "Source", (this.state.filterData.SOURCE_ID || []).map((val) => ({ label: val, value: val })))}
              </div>

              <div className="col-md-2" style={this.state.filterMap.nextActionDate ? style.show : style.hide}>
                <label>Next Action Start</label>
                {this.dateType("startDate", this.state.filterData.NEXT_ACTION_DATE ? this.state.filterData.NEXT_ACTION_DATE.startDate : '', "NEXT_ACTION_DATE")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.nextActionDate ? style.show : style.hide}>
                <label>Next Action End</label>
                {this.dateType("endDate", this.state.filterData.NEXT_ACTION_DATE ? this.state.filterData.NEXT_ACTION_DATE.endDate : '', "NEXT_ACTION_DATE")}
              </div>

              <div className="col-md-2" style={this.state.filterMap.highValue ? style.show : style.hide}>
                <label>High Value</label>
                {this.multiSelectType("HIGH_VALUE", this.state.highValue, "High Value", (this.state.filterData.HIGH_VALUE || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.reasonForVerified ? style.show : style.hide}>
                <label>Reason For Verified</label>
                {this.multiSelectType("REASON_FOR_VERIFIED", this.state.reasonForVerified, "Reason For Verified", (this.state.filterData.REASON_FOR_VERIFIED || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.reasonForVerified ? style.show : style.hide}>
                <label>Property Type</label>
                {this.multiSelectType("PROPERTY_TYPE", this.state.propertyType, "Property Type", (this.state.filterData.PROPERTY_TYPE || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.reasonForVerified ? style.show : style.hide}>
                <label>Inside Sales Opinion</label>
                {this.multiSelectType("INSIDE_SALES_OPINION", this.state.isOpinion, "Inside sales opinion", (this.state.filterData.INSIDE_SALES_OPINION || []).map((val) => ({ label: val, value: val })))}
              </div>
              {/* <div className="col-md-2" style={this.state.filterMap.modified ? style.show : style.hide}>
            <label>Modified</label>
            {this.multiSelectType("Modified", this.state.modified, "Modified")}
          </div> */}
              <div className="col-md-2" style={this.state.filterMap.languages ? style.show : style.hide}>
                <label>Language</label>
                {this.multiSelectType("LANGUAGE", this.state.languages, "Language", (this.state.filterData.LANGUAGE || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.zipcode ? style.show : style.hide}>
                <label>Zipcode</label>
                {this.inputTypeText("ZIP_CODE", this.state.filterData.ZIP_CODE || "", "number", "zipcode")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.saPaid ? style.show : style.hide}>
                <label>SA Paid</label>
                {this.inputTypeText("SITE_ASSESSMENT_PAID", this.state.filterData.SITE_ASSESSMENT_PAID || "", "text", "SA PAID")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.product ? style.show : style.hide}>
                <label>Product</label>
                {this.multiSelectType("PRODUCT", this.state.product, "Product", (this.state.filterData.PRODUCT || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.product ? style.show : style.hide}>
                <label>SA Count</label>
                {this.singleSelectType("SA_COUNT", this.state.saCount, this.state.filterData.SA_COUNT)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.modifiedOn ? style.show : style.hide}>
                <label>Modified On Start</label>
                {this.dateType("startDate", this.state.filterData.modifiedOn ? this.state.filterData.modifiedOn.startDate : '', "modifiedOn")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.modifiedOn ? style.show : style.hide}>
                <label>Modified On End</label>
                {this.dateType("endDate", this.state.filterData.modifiedOn ? this.state.filterData.modifiedOn.endDate : '', "modifiedOn")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.updatedBy ? style.show : style.hide}>
                <label>Modified By</label>
                {this.multiSelectType("updatedBy", this.state.updatedByOptions, "Modified By", this.state.updatedByOptions.filter(responsible => (this.state.filterData.updatedBy || []).includes(responsible.value)))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.saType ? style.show : style.hide}>
                <label>SA Type</label>
                {this.multiSelectType("SA_TYPE", this.state.saType.map(val => ({ label: val, value: val })), "SA_TYPE", (this.state.filterData.SA_TYPE || []).map((val) => ({ label: val, value: val })))}
              </div>
              <div className="col-md-2" style={this.state.filterMap.lastReachOut ? style.show : style.hide}>
                <label>Last reach out Start</label>
                {this.dateType("startDate", this.state.filterData.lastReachOut ? this.state.filterData.lastReachOut.startDate : "", "lastReachOut")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.lastReachOut ? style.show : style.hide}>
                <label>Last reach out End</label>
                {this.dateType("endDate", this.state.filterData.lastReachOut ? this.state.filterData.lastReachOut.endDate : "", "lastReachOut")}
              </div>
              <div style={Object.values(this.state.filterMap).includes(true) ? style.show : style.hide}>
                <label></label><br></br>
                <button style={{ marginTop: "10px" }} onClick={this.onApplyFilter} className="btn btn-success btn-sm">Apply filter</button>
              </div>
              {/* <div className="col-md-1" style={Object.values(this.state.filterMap).includes(true) ? style.show : style.hide}>
                <label></label><br></br>
                <button onClick={this.onSaveFilter} className="btn btn-outline-secondary">Save filter</button>
              </div> */}
              <div className="col-md-2" style={(Object.values(this.state.filterMap).includes(true) && Object.keys(this.state.filterData).length > 0) ? style.show : style.hide}>
                <label></label><br></br>
                <button style={{ marginTop: "10px" }} class="btn btn-outline-secondary btn-sm" data-toggle="modal" data-target="#exampleModal">Save Filter</button>
              </div>

              {/* modal */}
              <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    {/* <div class="modal-header"> */}
                    {/* <h5 class="modal-title" id="exampleModalLabel">Type Name for filter</h5> */}
                    {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div> */}
                    <div class="modal-body">
                      <form>
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Type FilterName:</label>
                          <input type="text" class="form-control" id="recipient-name" name={this.state.filterName} value={this.state.filterName || ""} onChange={(e) => { this.handleModalInput(e) }} />
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-primary btn-sm" onClick={this.onSaveFilter}>Save</button>
                    </div>
                  </div>
                </div>
              </div>

              {/* modal ends */}

            </div>
          </div>
          <div>
            <div className="row" style={{ margin: '20px 0px' }}>
              <div class="col-md-8">
                {/* <span> Leads : {this.state.tableData.length} </span> */}
                <span style={this.props.searchData.length > 0 ? style.hide : style.show}><div style={{ display: 'inline-block', width: '150px' }}><Select placeholder="No. of Leads" options={this.state.limitoption} onChange={e => this.selectFetchLeads(e.value, e)} /></div></span>
                <span style={this.props.searchData.length > 0 ? style.hide : style.show}><button className="moreLeads" onClick={e => this.fetchLeads(e)}> More Leads <i className="fas fa-angle-double-right" aria-hidden="true"></i></button></span>
                <span style={this.props.searchData.length > 0 ? style.hide : style.show} className="refreshButton"><button onClick={e => this.handleRefresh()}><i className="fa fa-refresh fa-lg"></i></button></span>
                <span style={{ paddingLeft: "30px" }}><b>Total Leads</b> : <b>{this.state.count}</b></span>
              </div>
              <div class="col-md-4" style={{ textAlign: "right" }}>
                <Link to="/createlead" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> New Lead</button></Link>
              </div>
            </div>
            <div className="row" style={{ marginTop: "25px" }} style={Object.values(this.state.selectedBitrixIds).includes(1) ? style.show : style.hide}>
              <div className="col-md-2">
                <label>Responsible</label>
                {this.selectNewType("N_RESPONSIBLE", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.bulkAssign.N_RESPONSIBLE)}
              </div>
              <div className="col-md-2">
                <label>Stage</label>
                {this.selectNewType("STATUS_ID", this.state.mappingData.stages ? this.state.mappingData.stages : [], "STATUS_NAME", this.state.bulkAssign.STATUS_ID)}
              </div>
              <div className="col-md-2">
                <label>Active</label>
                {this.selectType("ACTIVE", this.state.ACTIVE, this.state.bulkAssign.ACTIVE)}
              </div>
              <div className="col-md-2">
                <label>Next Action Date</label>
                {this.inputType('NEXT_ACTION_DATE', this.state.bulkAssign.NEXT_ACTION_DATE, 'date', "Next Action Date")}
              </div>

              <div className="col-md-1" style={{ marginTop: "32px" }}>
                <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkAssign(e) }}>Submit</button>
              </div>
            </div>

            {/* Modal for Selecting Table Headers */}

            <div className="modal fade" id="selectTableOptionsModal" tabindex="-1" role="dialog" aria-labelledby="selectTableOptionsLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="selectTableOptionsLabel">Select Table Headers</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      {tableOptions.map(option => {
                        return <div className="col-md-6"><input name="tableMap" type="checkbox" value={option.value} checked={this.state.tableMap[option.value]} onChange={(e) => this.handleShowTable(e)} /> <span>{option.label}</span></div>
                      })}
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* Modal Ends */}

            <div style={{ marginTop: "10px" }}>
              <ReactTable
                filterable={true}
                loading={this.state.loading}
                showPagination={true}
                data={this.state.tableData}
                defaultPageSize={100}
                columns={columns}
                ref={this.reactTable}
                style={{ backgroundColor: "#ffffff" }}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                onFilteredChange={this.filterChanged}
              // getTrProps={this.getTr}
              //    defaultPageSize = {2}
              //    pageSizeOptions = {[2,4,6]}
              />

            </div>
          </div>
        </div>
      )
    }

    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }
  }

}

export default tableView;
