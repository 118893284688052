import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_PROCUREMENT_VENDORS, FETCH_PROCUREMENT_PAYMENTS, FETCH_PROCUREMENT_MAPPING, FETCH_PROCUREMENT_PAYMENTS_BY_ID, FETCH_PROCUREMENT_LEDGER, FETCH_SUPPLY_DETAILS } from './actiontypes';

export function addVendor(vendorData = {}) {
    return async (dispatch) => {
        try {
            console.log(vendorData);
            let formData = new FormData();
            Object.keys(vendorData).forEach(key => {
                let value = vendorData[key];
                if(key == 'products') value = (value || []).join(',');
                console.log(key, value);
                formData.append(key, value);
            });
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/vendor/create`, formData);
            response = response.data;
            console.log(response);
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            alert("Success");
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchVendorList() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/procurement/vendor/all`);
            response = response.data;
            console.log(response);
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_PROCUREMENT_VENDORS, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function downloadFile(filename) {
    return async (dispatch) => {
        try {
            console.log(filename);
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/vendor/file/download`, { filename });
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let fileData = response.data;
            let { Body = {}, ContentType } = fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        catch(e) {
            console.log("Some error occured", e);
            return;
        }
    }
}

export function updateVendorData(name, value, vendorList, index, id) {
    return async (dispatch) => {
        try {
            console.log(name, value, index, id);
            if(name == "products") {
                value = (value || []).join(',');
            }
            let formData = new FormData();
            formData.append(name, value);
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/vendor/edit/${id}`, formData);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let editedFields = response.data || {};
            vendorList = JSON.parse(JSON.stringify(vendorList));
            vendorList[index] = { ...vendorList[index], ...editedFields };
            dispatch({ type: FETCH_PROCUREMENT_VENDORS, payload: vendorList || [] });
        }
        catch(e) {
            console.log("Some error occured", e);
            alert("Some error occured");
            return;
        }
    }
}

export function deleteVendor(vendorList, index, id) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/vendor/edit/${id}`, { active: 0 });
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            vendorList = JSON.parse(JSON.stringify(vendorList));
            vendorList.splice(index, 1);
            dispatch({ type: FETCH_PROCUREMENT_VENDORS, payload: vendorList || [] });
        }
        catch(e) {
            console.log("Some error occured", e);
            alert("Some error occured");
            return;
        }
    }
}

export function fetchVendorLedger(filters = {}) {
    return async (dispatch) => {
        try {
            if(!filters.vendorId || filters.vendorId.length < 1) return;
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/vendorLedger/transactions`, { conditions: filters });
            response = response.data;
            console.log(response);
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_PROCUREMENT_LEDGER, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function addPayout(payoutData = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/vendorLedger/create`, payoutData);
            response = response.data;
            console.log(response);
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            alert("Success");
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchProcurementPayments(conditions = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/transactions/all`,{conditions});
            response = response.data;
            if (response.e || response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_PROCUREMENT_PAYMENTS, payload: response || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchProcurementPaymentsById(orderId) {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/procurement/getOrder/${orderId}`);
            response = response.data;
            if (response.e || response.error) {
                swal("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_PROCUREMENT_PAYMENTS_BY_ID, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMappingProcurement() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/procurement/getGstUnitsMappings`);
            response = response.data;
            if (response.e || response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_PROCUREMENT_MAPPING, payload: response.data || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function procurementStatusChange(newData,oldData) {
    return async (dispatch) => {
        try {
            let response = await axios.put(`${url.pathBackend}zunsolar/procurement/changeStatus/${newData.status}/${newData.siNo}`);
            response = response.data;
            if (response.e || response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let index = oldData.findIndex(row => row.siNo == newData.siNo);
            oldData = JSON.parse(JSON.stringify(oldData));
            oldData[index]['status'] = newData.status
            dispatch({ type: FETCH_PROCUREMENT_PAYMENTS, payload: {data:oldData}});
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function createProcurement(procurementData) {
    return async () => {
        try {
            let formData = new FormData();
            Object.keys(procurementData).forEach(key => {
                let value = procurementData[key];
                formData.append(key, value);
            });
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/create`,formData);
            response = response.data;
            if (response.e || response.error) {
                alert("Some Error Ocurred");
                return;
            }
            swal('order created successfully')
        }
        catch (e) {
            console.log(e);
            alert("Some Error Ocurred");
        }
    }
}

export function fetchSupplyDetails(conditions= {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/supply/all`,{conditions});
            response = response.data;
            if (response.e || response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_SUPPLY_DETAILS, payload: response || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function createDelivery(deliveryFormData) {
    return async () => {
        try {
            let formData = new FormData();
            Object.keys(deliveryFormData).forEach(key => {
                let value = deliveryFormData[key];
                formData.append(key, value);
            });
            let response = await axios.post(`${url.pathBackend}zunsolar/procurement/supply/add-delivery`,formData);
            response = response.data;
            if (response.e || response.error) {
                alert("Some Error Ocurred");
                return;
            }
            alert('Delivery created successfully')
        }
        catch (e) {
            console.log(e);
            alert("Some Error Ocurred");
        }
    }
}