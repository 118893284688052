import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../components/HeaderComponent';
import { Loader } from '../components/loader'
import { CustomTable } from './CustomTable';
import { FollowUpDetailView } from './FollowupDetailView';
import { fetchAllLeads, fetchMapping, updateLeadData } from '../actions/followupActions';
import axios from 'axios';
import url from '../config';
import moment from 'moment';
import Modal from 'react-responsive-modal';

const pageSize = 15;

class FollowUpMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            received: true,
            data: [],
            filteredData: [],
            mapping: {},
            selectedIndex: -1,
            selectedLeadData: {},
            editedLeadData: {},
            edit: false,
            comments: {},
            currentPage: 1,
            filters: {
                prospect: 'Hot',
                nad: -1,
                state: '',
                sortKey: 2
            },
            nextActionModal: false,
            nextActionUpdate: {

            }
        };
    }

    componentDidMount() {
        window.onpopstate = () => {
            console.log('pop state called')
        }
    }

    componentWillMount() {
        this.props.fetchAllLeads();
        this.props.fetchMapping();
    }


    componentWillReceiveProps({ leads = [], mapping = {} }) {
        let stateLeadsCount = {}, maxCount = 0, stateWithMaxCount = '';
        leads.forEach(row => {
            let { STATE } = row;
            if (!STATE) return;
            stateLeadsCount[STATE] = (stateLeadsCount[STATE] || 0) + 1;
            if (stateLeadsCount[STATE] > maxCount) {
                maxCount = stateLeadsCount[STATE];
                stateWithMaxCount = STATE;
            }
        })
        console.log(stateWithMaxCount, maxCount);
        this.setState({
            data: leads,
            mapping: mapping,
            filteredData: leads,
            stateFilterOptions: Object.keys(stateLeadsCount),
            filters: {
                ...this.state.filters,
                state: stateWithMaxCount
            }
        }, () => this.applyFilterAndSort());
    }

    NextButton = ({ index }) => {
        return (
            <button onClick={() => this.selectIndex(index)} className='btn'><i className='fa fa-edit'></i></button>
        )
    }

    handlePrevClickLeadView = () => {
        this.selectIndex(this.state.selectedIndex - 1, false);
    }

    handleNextClickLeadView = () => {
        this.selectIndex(this.state.selectedIndex + 1, false);
    }

    selectIndex = (index, tableView = true) => {
        let { currentPage } = this.state;
        let selectedIndex = index;
        if(tableView) {
            selectedIndex = (currentPage - 1) * pageSize + index;
        }
        console.log("selected index", index, selectedIndex);
        let { filteredData } = this.state;
        this.setState({
            selectedIndex: selectedIndex,
            selectedLeadData: filteredData[selectedIndex],
            editedLeadData: filteredData[selectedIndex]
        })
        this.fetchComments(filteredData[selectedIndex].BITRIX_ID);
    }

    handleEditClick = () => {
        this.setState({
            edit: true
        })
    }

    handleCancelClick = () => {
        this.setState({
            edit: false
        })
    }

    handleSaveClick = () => {
        let { selectedLeadData, editedLeadData } = this.state;
        if ((selectedLeadData.ACTIVE == 1 && editedLeadData.ACTIVE == 0) && !editedLeadData.dropOffReason) {
            alert("Please select drop off resaon");
            return;
        }
        if (selectedLeadData.NEXT_ACTION_DATE != editedLeadData.NEXT_ACTION_DATE && !editedLeadData.nextActionComment) {
            alert("Please enter comment");
            return;
        }
        this.props.updateLeadData(selectedLeadData, editedLeadData);
        let updatedLeadData = {};
        Object.keys(selectedLeadData).forEach(key => {
            updatedLeadData[key] = editedLeadData[key];
        })
        this.setState({
            selectedLeadData: JSON.parse(JSON.stringify(updatedLeadData)),
            editedLeadData: {
                ...(JSON.parse(JSON.stringify(updatedLeadData))),
                nextActionComment: '',
                commentSelect: ''
            },
            edit: false
        }, () => {
            console.log(this.state.editedLeadData);
        })
    }

    handleOnChange = (e) => {
        let { name, value } = (e || {}).target || {};
        if(name == 'NEXT_ACTION_DATE') {
            this.setState({
                editedLeadData: {
                    ...this.state.editedLeadData,
                    [name]: moment(value).format("YYYYMMDD")
                }
            })
            return;
        }
        if(name == 'commentSelect') {
            this.setState({
                editedLeadData: {
                    ...this.state.editedLeadData,
                    [name]: value,
                    nextActionComment: value
                }
            })
            return;
        }
        if (name == 'dropReasonSelect') {
            this.setState({
                editedLeadData: {
                    ...this.state.editedLeadData,
                    [name]: value,
                    dropOffReason: value
                }
            })
            return;
        }
        this.setState({
            editedLeadData: {
                ...this.state.editedLeadData,
                [name]: value
            }
        })
    }

    fetchComments = async (leadId) => {
        try {
            let response = await axios.post(url.pathBackend + "lead/comments/" + leadId);
            console.log(response.data);
            this.setState({
                comments: {
                    [leadId]: (response.data || []).sort((a, b) => +b.timestamp - +a.timestamp)
                }
            })
        }
        catch (error) {
            console.log("Error in fetch comments");
        }
    }

    handleNextClick = () => {
        this.setState({
            currentPage: this.state.currentPage + 1
        })
    }

    handlePrevClick = () => {
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    applyFilterAndSort = () => {
        let { data = [], filters = {} } = this.state;
        let { prospect, nad, state, sortKey } = filters;
        console.log(filters);
        let nextActionStartDate = '';
        if (nad == 1) nextActionStartDate = moment().format("YYYYMMDD");
        else if (nad == 2) nextActionStartDate = moment().subtract(7, 'd').format("YYYYMMDD")
        else if (nad == 3) nextActionStartDate = moment().subtract(30, 'd').format("YYYYMMDD")
        let nextActionEndDate = moment().format("YYYYMMDD")
        let filteredData = data.filter(row => {
            return (!state || state == row.STATE) &&
                (!prospect || prospect.toLowerCase() == (row.PROSPECT || "Moderate").toLowerCase()) &&
                (!nextActionStartDate || (row.NEXT_ACTION_DATE >= nextActionStartDate && row.NEXT_ACTION_DATE <= nextActionEndDate))
        });
        filteredData = filteredData.sort((a, b) => {
            if (sortKey == 1) return a.NEXT_ACTION_DATE - b.NEXT_ACTION_DATE;
            if (sortKey == 2) return b.NEXT_ACTION_DATE - a.NEXT_ACTION_DATE;
            if (sortKey == 3) return a.systemSize - b.systemSize;
            if (sortKey == 4) return b.systemSize - a.systemSize;
            return b.NEXT_ACTION_DATE - a.NEXT_ACTION_DATE;
        })
        console.log(data.length, filteredData.length)
        this.setState({
            filteredData: filteredData,
            currentPage: 1
        })
    }

    handleSortFilterChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        }, () => this.applyFilterAndSort());
    }

    handleNextActionClick = (row) => {
        console.log('handleNextActionClick', row);
        this.setState({
            nextActionModal: true,
            nextActionUpdate: {
                BITRIX_ID: row.BITRIX_ID,
                nextActionDate: row.NEXT_ACTION_DATE && moment(row.NEXT_ACTION_DATE, 'YYYYMMDD', true).isValid() ? moment(row.NEXT_ACTION_DATE, 'YYYYMMDD', true).format("YYYY-MM-DD") : '',
                comment: '',
                QUOTE_ID: row.QUOTE_ID
            }
        })
    }

    closeNextActionModal = () => {
        this.setState({
            nextActionModal: false
        })
    }

    handleNextActionChange = (e) => {
        let { name, value } = e.target;
        if(name == 'commentSelect') {
            this.setState({
                nextActionUpdate: {
                    ...this.state.nextActionUpdate,
                    [name]: value,
                    comment: value
                }
            })
            return;
        }
        this.setState({
            nextActionUpdate: {
                ...this.state.nextActionUpdate,
                [name]: value
            }
        })
    }

    handleNextActionSubmit = () => {
        let { nextActionUpdate = {} } = this.state;
        let { comment, nextActionDate, BITRIX_ID, QUOTE_ID } = nextActionUpdate;
        if(!BITRIX_ID || !QUOTE_ID) {
            alert("Some error occured");
            return;
        }
        if(!comment || !nextActionDate) {
            alert("Please enter comment and next action date");
            return;
        }
        this.props.updateLeadData({ BITRIX_ID: BITRIX_ID, QUOTE_ID: QUOTE_ID }, { nextActionComment: comment, NEXT_ACTION_DATE: moment(nextActionDate).format("YYYYMMDD"), QUOTE_ID: QUOTE_ID });
        this.closeNextActionModal();
        let { filteredData, data } = this.state;
        let filteredDataCopy = JSON.parse(JSON.stringify(filteredData));
        let allDataCopy = JSON.parse(JSON.stringify(data));
        let indexInFilter = filteredDataCopy.findIndex(row => row.BITRIX_ID == BITRIX_ID);
        let indexInAll = allDataCopy.findIndex(row => row.BITRIX_ID == BITRIX_ID);
        filteredDataCopy[indexInFilter].NEXT_ACTION_DATE = moment(nextActionDate).format("YYYYMMDD");
        allDataCopy[indexInAll].NEXT_ACTION_DATE = moment(nextActionDate).format("YYYYMMDD");
        this.setState({
            filteredData: filteredDataCopy,
            data: allDataCopy
        })
    }

    downloadSAFile = async (QUOTE_ID) => {
        try {
            console.log(QUOTE_ID)
            let response = await axios.post(url.pathBackend + "sales/followup/getSaFileUrl", {
                qid: QUOTE_ID
            });
            let fileUrl = response.data;
            console.log(fileUrl);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', `${QUOTE_ID}.pdf`);
            link.setAttribute('target', `_blank`);
            document.body.appendChild(link);
            link.click();
        }
        catch (error) {
            console.log("Error in downloadSAFile", error);
        }
    }

    handleAllLeadClick = () => {
        this.setState({ selectedIndex: -1 })
    }

    handleSubmitCommentClick = () => {
        let { editedLeadData = {} } = this.state;
        let { nextActionComment = '' } = editedLeadData;
        if(!nextActionComment) {
            alert('Please enter commemt');
            return;
        }
        let { BITRIX_ID, QUOTE_ID } = editedLeadData;
        this.props.updateLeadData({ BITRIX_ID: BITRIX_ID, QUOTE_ID: QUOTE_ID }, { nextActionComment: nextActionComment, QUOTE_ID: QUOTE_ID });
        this.setState({
            editedLeadData: {
                ...this.state.editedLeadData,
                nextActionComment: '',
                commentSelect: ''
            }
        })
    }

    render() {

        let { selectedIndex, edit, selectedLeadData, editedLeadData, mapping, comments, currentPage, filteredData = [], stateFilterOptions = [], filters = {}, nextActionUpdate = {} } = this.state;
        let { NextButton, handleEditClick, handleCancelClick, handleSaveClick, handleOnChange, handleNextClick, handlePrevClick, handleNextClickLeadView, handlePrevClickLeadView, handleNextActionClick, handleNextActionChange, handleNextActionSubmit, downloadSAFile, handleAllLeadClick, handleSubmitCommentClick } = this;

        let stateRegionMap = mapping.stateRegionMap || {};
        let dropOffReasonMap = mapping.dropOffReason || [];
        let prospectMap = mapping.prospect || [];

        const modalStyles = {
            modal: {
                width: '100%',
                maxWidth: '100%',
                padding: '40px',
                background: '#f7f7f7'
            }
        }

        let TableColumns = [
            {
                Header: 'Name',
                accessor: 'TITLE'
            },
            {
                Header: 'Quote Id',
                accessor: 'QUOTE_ID'
            },
            {
                Header: 'Size',
                accessor: 'systemSize'
            },
            {
                Header: 'NAD',
                accessor: 'NEXT_ACTION_DATE'
            },
            {
                Header: 'Edit',
                component: NextButton,
                Key: 'Edit'
            }
        ];

        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 60, paddingLeft: 0, paddingRight: 0 }} className="container-fluid">
                    {selectedIndex >= 0 ?
                        <FollowUpDetailView
                            leadData={selectedLeadData}
                            edit={edit}
                            editedLeadData={editedLeadData}
                            handleEditClick={handleEditClick}
                            handleCancelClick={handleCancelClick}
                            handleSaveClick={handleSaveClick}
                            handleOnChange={handleOnChange}
                            stateRegionMap={stateRegionMap}
                            dropReasonMapping={dropOffReasonMap}
                            prospectMapping={prospectMap}
                            comments={comments[selectedLeadData.BITRIX_ID]}
                            handleAllLeadClick={handleAllLeadClick}
                            commentOptions={mapping.comment}
                            handleSubmitCommentClick={handleSubmitCommentClick}
                        /> : ""}
                    {selectedIndex >= 0 ?
                        <div className='row mt-3' style={{ textAlign: "center" }}>
                            <div className='col-4'><button className='btn btn-outline-info' style={{ width: '100%' }} disabled={selectedIndex < 1} onClick={handlePrevClickLeadView}>Prev</button></div>
                            <div className='col-4'>

                            </div>
                            <div className='col-4'><button className='btn btn-outline-info' style={{ width: '100%' }} disabled={selectedIndex >= filteredData.length - 1} onClick={handleNextClickLeadView}>Next</button></div>
                        </div> : ""}
                    {selectedIndex == -1 ? <div className='tableDiv'>
                        <CustomTable
                            config={TableColumns}
                            data={filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                            caption={this.props.leadDataReceived ? filteredData.length == 0 ? "No data found" : `Page ${currentPage} of ${Math.ceil(filteredData.length / pageSize)}` : "Loading..."}
                            handleNextActionClick={handleNextActionClick}
                            downloadSAFile={downloadSAFile}
                        />
                        <div className='row mb-3' style={{ textAlign: "center" }}>
                            <div className='col-4'><button className='btn btn-outline-info' style={{ width: '100%' }} disabled={currentPage <= 1} onClick={handlePrevClick}>Prev 15</button></div>
                            <div className='col-4'>
                                <select className="form-control" name='sortKey' value={filters.sortKey} onChange={this.handleSortFilterChange}>
                                    <option value="1">NAD Asc</option>
                                    <option value="2">NAD Desc</option>
                                    <option value="3">Size Asc</option>
                                    <option value="4">Size Desc</option>
                                </select>
                            </div>
                            <div className='col-4'><button className='btn btn-outline-info' style={{ width: '100%' }} disabled={filteredData.length == 0 || currentPage >= Math.ceil(filteredData.length / pageSize)} onClick={handleNextClick}>Next 15</button></div>
                        </div>
                        <div className='row'>
                            <div className='col-4'>
                                <select className="form-control" name='state' value={filters.state} onChange={this.handleSortFilterChange}>
                                    <option value=''>Select State</option>
                                    {stateFilterOptions.map(state => <option value={state}>{state}</option>)}
                                </select>
                            </div>
                            <div className='col-4'>
                                <select className="form-control" name='prospect' value={filters.prospect} onChange={this.handleSortFilterChange}>
                                    <option value=''>Select Prospect</option>
                                    {prospectMap.map(val => <option value={val}>{val}</option>)}
                                </select>
                            </div>
                            <div className='col-4'>
                                <select className="form-control" name='nad' value={filters.nad} onChange={this.handleSortFilterChange}>
                                    <option value="0">All</option>
                                    <option value="1">Today</option>
                                    <option value="2">Last 7 Days</option>
                                    <option value="3">Last 30 Days</option>
                                </select>
                            </div>
                        </div>
                    </div> : ""}
                    <Modal open={this.state.nextActionModal} onClose={this.closeNextActionModal} styles={modalStyles} center>
                        <div class="form-group">
                            <label>Next Action Date</label>
                            <input type="date" className="form-control" value={nextActionUpdate.nextActionDate} name='nextActionDate' onChange={handleNextActionChange} />
                        </div>
                        <div class="form-group">
                            <label>Select Comment</label>
                            <select name='commentSelect' className='form-control' onChange={handleNextActionChange} value={nextActionUpdate.commentSelect}>
                                    <option>Select</option>
                                    {(mapping.comment || []).map(reason => <option value={reason}>{reason}</option>)}
                                </select>
                        </div>
                        <div class="form-group">
                            <label>Comment</label>
                            <input type="text" className="form-control" value={nextActionUpdate.comment} name='comment' onChange={handleNextActionChange} />
                        </div>
                        <div class="form-group mt-3" style={{ textAlign: 'center' }}>
                            <button className='btn btn-outline-primary' onClick={handleNextActionSubmit}>Submit</button>
                        </div>
                    </Modal>
                </div>
                    : <Loader />}
            </div>
        );


    }
}

function mapStateToProps({ followup }) {
    let { leads, mapping, leadDataReceived } = followup;
    return {
        leads: leads,
        mapping: mapping,
        leadDataReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllLeads, fetchMapping, updateLeadData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpMobile);