import React from "react";
import { getSearchResult } from "../../actions/searchAction";
import { Component } from "react";
import { bindActionCreators } from "redux";
import Header from "../../components/HeaderComponent";
import { connect } from "react-redux";
import Select from "react-select";
import debounce from "lodash/debounce";
import {
	fetchDealersData,
	generatePi,
	fetchPiProductDetails,
	fetchZunsolarPiOrders,
} from "../../actions/zunsolarPiPortalActions";
import {
	fetchMapping,
	fetchCombo,
} from "../../actions/zunsolarOrderTrackerActions";
import config from "../../config";
import { parse } from "query-string";
import moment from "moment";
import {isMobile} from 'react-device-detect'
import { Redirect } from "react-router-dom";

const gstRateOptions = [
	{ label: "5%", value: 5 },
	{ label: "12%", value: 12 },
	{ label: "18%", value: 18 },
	{ label: "28%", value: 28 },
];

class ZunsolarGeneratePI extends Component {
	constructor() {
		super();
		this.state = {
			invoiceData: {
				productList: [
					{
						comboId: "null",
					},
				],
				date: moment().format("YYYY-MM-DD"),
			},
			dealersData: [],
			categoryMapping: {},
			fetchDealersListError: false,
			comboIdSelected: "",
			piDataUpdated: false,
			piNumber: "",
			type:'',
			termConditions:[''],
			companyType:''
		};
	}

	componentWillMount() {
		if (localStorage.getItem("userObj")) {
			const {
				location: { search },
			} = this.props;
			let { pi_number = "" } = parse(search);
			if (pi_number) {
				this.props.fetchZunsolarPiOrders(pi_number);
			}
			this.props.fetchMapping();
			this.props.fetchCombo();
			this.props.fetchPiProductDetails();
			let userObj = JSON.parse(localStorage.getItem("userObj"));
			this.setState({
				userObj,
				piNumber: pi_number,
			});
		}
	}

	componentWillReceiveProps({
		dealersData,
		fetchDealersListError,
		singlePIDetails,
		categoryMapping,
	}) {
		this.setState({
			dealersData,
			categoryMapping,
			fetchDealersListError,
		});
		let defaultTermCondition = [
			"Validity of Quoted Price: Price will be valid for order booking within 7 days",
			"Delivery : Within a week of receiving your payment and confirmation.",
			"Payment: 100% before Dispatch of material",
			"Company's written approval is required prior to selling the above mentioned products on e-commerce platforms"]
		this.setState({
			termConditions:defaultTermCondition
		})

		let { piDataUpdated, piNumber } = this.state;
		if (piNumber && !piDataUpdated && singlePIDetails) {
			if (Object.keys(singlePIDetails).length == 0) return;
			let products = [];
			let piDetails = singlePIDetails || {};
			(piDetails.products || []).forEach((row) => {
				products.push({
					category: row.category,
					subcategory: row.subcategory,
					description: row.description,
					warranty: row.warranty,
					price: row.price,
					gstRate: row.gstRate,
					gstValue: row.gstValue,
					quantity: row.quantity,
					discount: row.discount,
					priceAfterDiscount: row.priceAfterDiscount,
					totalGstValue: row.totalGstValue,
					priceAfterDiscountWithGST: row.priceAfterDiscountWithGST,
					totalPriceAfterDiscount: row.totalPriceAfterDiscount,
					totalPriceAfterDiscountWithGST: row.totalPriceAfterDiscountWithGST,
					priceWithGst: row.priceWithGst,
					totalPriceWithoutGst: row.totalPriceWithoutGst,
					comboId: row.comboId || "null",
				});
			});
			let invoiceData = {
				date: moment().format("YYYY-MM-DD"),
				dealerId: piDetails.dealerId,
				dealerName: piDetails.dealerName,
				dealerAddress: piDetails.dealerAddress,
				dealerGstin: piDetails.dealerGstin,
				phoneNumber: piDetails.phoneNumber,
				freight: piDetails.freight,
				gstFreight: piDetails.gstFreight,
				productList: products,
				internalComment: piDetails.internalComment,
				externalComment: piDetails.externalComment
			};
			this.setState({
				piDataUpdated: true,
				invoiceData,
			});
		}
	}
	handleInputChangeV2 = (e, index)=>{
		let {name, value} = e.target;
		console.log('name, value');
		let {termConditions}  = this.state;
		if(value){
			termConditions[index] = value
		}
		this.setState({
			termConditions:termConditions
		})
	}
	InvoiceInputV2 = ({label, name, type, required, index, readonly})=>{
		let {termConditions} = this.state
		return(
			<div className='row'>
			<label className="col-md-3 mb-2" style={{ fontSize: "17px", fontWeight: "bold" }}>
				{label +" "+ (index+1)}
			</label>
			<div className=" col-md-9 mb-3">
			<input
							className="form-control customInput"
							type={type}
							name={name}
							placeholder={label}
							onChange={(e) => this.handleInputChangeV2(e, index)}
							required={required}
							readOnly = {readonly}
							value= {termConditions[index]}
						/>

			</div>
			</div>
		)

	}

	InvoiceInput = ({
		label,
		name,
		type,
		inputParams = {},
		required = true,
		disabled = false,
	}) => {
		let { invoiceData = {} } = this.state;
		let value = invoiceData[name] || "";
		return (
			<div
				className="col-12 col-md-3"
				style={{ width: "100%", paddingTop: "1%" }}
			>
				<div className="form-group">
					<div className="mb-3">
						<label>{label}</label>
						<input
							className="form-control customInput"
							type={type}
							name={name}
							placeholder={label}
							value={value}
							onChange={(e) => this.handleInvoiceInputChange(e)}
							{...inputParams}
							required={required}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	};

	InvoiceProductInput = ({
		label,
		name,
		type,
		productIndex,
		options = [],
		disabled = false,
		inputParams = {},
		step
	}) => {
		let { invoiceData = {} } = this.state;
		let productList = invoiceData["productList"] || [];
		let value = (productList[productIndex] || {})[name] || "";
		if (type == "select") {
			return (
				<div className="col" style={{ width: "100%", padding: "0%" }}>
					<div className="form-group">
						<div className="input-group mb-3">
							<select
								className="form-select form-control customInput"
								name={name}
								placeholder={label}
								value={value}
								onChange={(e) =>
									this.handleInvoiceProductInputChange(e, productIndex)
								}
								required
							>
								<option value="">Select</option>
								{options.map((row) => (
									<option value={row.value}>{row.label}</option>
								))}
							</select>
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="col" style={{ width: "100%", padding: "0%" }}>
				<div className="form-group">
					<div className="input-group mb-3">
						<input
							className="form-control customInput"
							type={type}
							name={name}
							placeholder={label}
							value={value}
							step={step}
							onChange={(e) =>
								this.handleInvoiceProductInputChange(e, productIndex)
							}
							disabled={disabled}
							{...inputParams}
							required
						/>
					</div>
				</div>
			</div>
		);
	};

	handleAddTermCondition = ()=>{
		let {termConditions} = this.state;
		termConditions = [...termConditions,''];
		this.setState({
			termConditions
		})
	}

	handleAddProduct = () => {
		let { invoiceData = {} } = this.state;
		invoiceData = JSON.parse(JSON.stringify(invoiceData));
		invoiceData.productList = invoiceData.productList || [];
		invoiceData.productList.push({
			comboId: "null",
		});
		this.setState({
			invoiceData,
		});
	};

	handleInvoiceInputChange = (e) => {
		let { name, value } = e.target;
		let { invoiceData = [] } = this.state;
		invoiceData = JSON.parse(JSON.stringify(invoiceData));
		if (["freight"].includes(name)) {
			invoiceData["gstFreight"] = parseFloat(parseInt(value) * 0.18).toFixed(2);
		}
		if(name=="otherAmount"){
			value = parseInt(value)
		}
		invoiceData[name] = value;
		this.setState({
			invoiceData,
		});
	};

	checkFloat = (value) => {
		if(!isNaN(parseFloat(value)) && isFinite(value) && value>=0){
			return true;
		}
		return false;
	}

	handleInvoiceProductInputChange = (e, productIndex) => {
		let { name, value } = e.target;
		let { invoiceData = [] } = this.state;
		invoiceData = JSON.parse(JSON.stringify(invoiceData));
		invoiceData["productList"][productIndex][name] = value;
		if (["price", "gstRate", "quantity", "discount"].includes(name)) {
			let { price, gstRate, quantity, discount } =
				invoiceData["productList"][productIndex];
			if ([price, gstRate, quantity, discount].every(this.checkFloat)) {

				// let priceAfterDiscount = parseFloat((price - discount).toFixed(2));
				// let gstValue = parseFloat((priceAfterDiscount*gstRate*0.01).toFixed(3));
				// let totalGstValue = parseFloat((gstValue*quantity).toFixed(2));
				// let priceAfterDiscountWithGST = parseFloat((priceAfterDiscount + gstValue).toFixed(2));
				// let totalPriceAfterDiscount = parseFloat((priceAfterDiscount*quantity).toFixed(2));
				// let totalPriceAfterDiscountWithGST = parseFloat((priceAfterDiscountWithGST * quantity).toFixed(2));

				let priceAfterDiscount = parseFloat((price - discount));
				let gstValue = parseFloat((priceAfterDiscount*gstRate*0.01));
				let totalGstValue = parseFloat((gstValue*quantity));
				let priceAfterDiscountWithGST = parseFloat((priceAfterDiscount + gstValue));
				let totalPriceAfterDiscount = parseFloat((priceAfterDiscount*quantity));
				let totalPriceAfterDiscountWithGST = parseFloat((priceAfterDiscountWithGST * quantity));

				invoiceData["productList"][productIndex] = {
					...invoiceData["productList"][productIndex],
					priceAfterDiscount: parseFloat(priceAfterDiscount.toFixed(2)),
					gstValue: parseFloat(gstValue.toFixed(2)),
					totalGstValue: parseFloat(totalGstValue.toFixed(2)),
					priceAfterDiscountWithGST: parseFloat(priceAfterDiscountWithGST.toFixed(2)),
					totalPriceAfterDiscount: parseFloat(totalPriceAfterDiscount.toFixed(2)),
					totalPriceAfterDiscountWithGST: parseFloat(totalPriceAfterDiscountWithGST.toFixed(2))
				}
			}
		}
		if (["category", "subcategory"].includes(name)) {
			let { category, subcategory } = invoiceData["productList"][productIndex];
			if (category && subcategory) {
				let { piProductDetails = {} } = this.props;
				let details = piProductDetails[`${category}_${subcategory}`] || {};
				invoiceData["productList"][productIndex] = {
					...invoiceData["productList"][productIndex],
					...details,
				};
			}
		}
		this.setState({
			invoiceData,
		});
	};

	handleSubmitInvoiceForm = (e) => {
		e.preventDefault();
		let { invoiceData = {} } = this.state;
		invoiceData.termConditions = this.state.termConditions
		console.log(invoiceData);
		this.props.generatePi(invoiceData);
	};

	handleDeleteProduct = () => {
		let { invoiceData = {} } = this.state;
		if (invoiceData["productList"].length <= 1) {
			return;
		}
		invoiceData = JSON.parse(JSON.stringify(invoiceData));
		invoiceData.productList.splice(invoiceData.productList.length - 1, 1);
		this.setState({ invoiceData });
	};

	inputDealerIdChange = (e) => {
		if (e.length <= 3) return;
		this.props.fetchDealersData(e);
		this.setState({
			fetchDealersListError: false,
		});
	};

	handleSelectDealerId = (e) => {
		let { value, name, address, gst_number, city, state, pincode } = e;
		// console.log(city, state);
		this.setState((prevState) => ({
			invoiceData: {
				...prevState.invoiceData,
				dealerId: value,
				dealerName: name,
				dealerAddress: address,
				dealerGstin: gst_number,
				dealerCity: city,
				dealerState: state,
				dealerPincode: pincode
			},
		}));
	};

	handleSelectPiDetailsChange = (e, name) => {
		let { value } = e;
		if(name=='type'){
			this.setState({
				type:value
			})
		}
		if(name=="companyType"){
			let defaultTermCondition = [
				"Validity of Quoted Price: Price will be valid for order booking within 7 days",
				"Delivery : Within a week of receiving your payment and confirmation.",
				"Payment: 100% before Dispatch of material",
				"Company's written approval is required prior to selling the above mentioned products on e-commerce platforms"]
			this.setState({
				companyType:value,
				termConditions:defaultTermCondition


				
			})
		}
		this.setState((prevState) => ({
			invoiceData: {
				...prevState.invoiceData,
				[name]: value,
			},
		}));
	};

	handleComboSelect = (e) => {
		this.setState({
			comboIdSelected: e.target.value,
		});
	};

	handleAddCombo = () => {
		let {
			comboIdSelected = "",
			invoiceData = {},
			categoryMapping = {},
		} = this.state;
		let { comboList = [], piProductDetails = {} } = this.props;
		if (!comboIdSelected) return;
		let selectedCombo = comboList.find((row) => row.comboId == comboIdSelected);
		console.log(selectedCombo);
		if (!selectedCombo) return;
		if (!categoryMapping["Combos"].includes(selectedCombo.comboId)) {
			categoryMapping.Combos.push(selectedCombo.comboId);
		}
		let products = [];
		for (let i = 1; i <= 8; i++) {
			if (
				selectedCombo[`product${i}Category`] &&
				selectedCombo[`product${i}SubCategory`]
			) {
				let details =
					piProductDetails[
						`${selectedCombo[`product${i}Category`]}_${
							selectedCombo[`product${i}SubCategory`]
						}`
					];
				products.push({
					// category: selectedCombo[`product${i}Category`],
					// subcategory: selectedCombo[`product${i}SubCategory`],
					category: "Combos",
					subcategory: selectedCombo.comboId,
					quantity: selectedCombo[`product${i}Qty`],
					comboId: comboIdSelected,
					...details,
				});
			}
		}
		invoiceData = JSON.parse(JSON.stringify(invoiceData));
		invoiceData.productList = [...products, ...invoiceData.productList];
		this.setState({ invoiceData }, () => {
			console.log(this.state.invoiceData);
		});
	};

	InvoiceProductIdInput = ({
		label,
		name,
		productIndex,
		options = [],
		disabled = false,
		inputParams = {}
	}) => {
        let selectOptions = options.map((uniProductId) => {
            let variantType = uniProductId.variantType;
            if(!variantType ||  variantType == "NULL" || variantType== "null") variantType = "";
            return {
                label: `${uniProductId.pID} ${uniProductId.productName} ${variantType}`, 
				value: uniProductId.pID,
				details: uniProductId
            }
        });
        return (
			<div className="col-2" style={{ width: "100%", padding: "0%" }}>
				<div className="form-group" style={{ width: '100%' }}>
					<Select
						isSearchable
						onChange={(e) => { this.handleInvoiceProductIdInputChange(productIndex, e, name) }}
						placeholder={"Select product id"}
						name={name}
						options={selectOptions}
					/>
				</div>
			</div>
        )
	}
	
	handleInvoiceProductIdInputChange = (productIndex, e, name) => {
		let value = e.value;
		let unicommerceId = e.details.unicommerceId;
		let { invoiceData = [] } = this.state;
		invoiceData = JSON.parse(JSON.stringify(invoiceData));
		invoiceData["productList"][productIndex][name] = value;
		invoiceData["productList"][productIndex]["skuID"] = unicommerceId;
		let { uniProductIds = {} } = this.props.mapping || {};
		let selectedProduct = e.details;
		let { category = "", subCategory = "", productName = "", variantType = "", sellingPrice = 0, piDefaultPrice = "", piDefaultGst = "" } = selectedProduct || {};
		invoiceData["productList"][productIndex] = {
			...invoiceData["productList"][productIndex],
			category,
			subcategory: subCategory,
			productName,
			variantType,
			onlinePrice: sellingPrice,
			description:productName,
			price: piDefaultPrice,
			gstRate: piDefaultGst
		}
		this.setState({
			invoiceData,
		});
	};

	render() {

		if (isMobile) {
			return <Redirect to="/zunsolar/pi/generate-mobile" />
		}

		let {
			InvoiceInput,
			InvoiceProductIdInput,
			InvoiceProductInput,
			handleAddProduct,
			state,
			handleSubmitInvoiceForm,
			handleDeleteProduct,
			handleSelectDealerId,
			props,
			handleAddCombo,
			InvoiceInputV2,
			handleAddTermCondition
		} = this;
		let {
			invoiceData = [],
			dealersData = [],
			fetchDealersListError,
			categoryMapping = {},
		} = state;
		let { comboList = [] } = props;
		let { productList = [], dealerId = "", dealerName = "" } = invoiceData;
		let { uniProductIds = {} } = this.props || {};
		return (
			<div>
				<Header />
				<div
					className="container-fluid"
					style={{ marginTop: "60px", fontSize: "12px !important" }}
				>
					<h4 style={{ fontWeight: "bold", textAlign: "center" }}>
						Generate PI
					</h4>
					<div style={{ width: "90%", margin: "0 auto" }}>
						<a
							href={`${config.pathFrontend}/zunsolar/createlead`}
							target="_blank"
						>
							<button
								type="button"
								className="btn btn-outline-success ml-3"
								style={{ float: "right" }}
							>
								Add new dealer
							</button>
						</a>
						<a
							href={`${config.pathFrontend}/zunsolar/addcombo`}
							target="_blank"
						>
							<button
								type="button"
								className="btn btn-outline-success"
								style={{ float: "right" }}
							>
								Add new combo
							</button>
						</a>
						<div style={{ clear: "both" }}></div>
					</div>
					<form onSubmit={handleSubmitInvoiceForm}>
						{
							<div
								style={{
									border: "1px solid #8ACB95",
									width: "90%",
									margin: "40px auto 0 auto",
									padding: "2% 3%",
								}}
							>
								<div className="row">
									<InvoiceInput label="Date" name="date" type="date" />
									<div
										className="col-12 col-md-3"
										style={{ width: "100%", paddingTop: "1%" }}
									>
										<div className="form-group">
											<div className="mb-3">
												<label>Company Type</label>
												<Select
													label="Company Type"
													placeholder="Select Company Type"
													options={["Zunvolt", "Zunsolar", "Zunpulse","Zunroof"].map((val) => {
														return { label: val, value: val };
													})}
													onChange={(e) =>
														this.handleSelectPiDetailsChange(e, "companyType")
													}
													required={true}
												/>
											</div>
										</div>
									</div>
									<div
										className="col-12 col-md-3"
										style={{ width: "100%", paddingTop: "1%" }}
									>
										<div className="form-group">
											<div className="mb-3">
												<label>Type</label>
												<Select
													label="Type"
													placeholder="Select Type"
													options={["Dealer", "Distributor", "Partner","DC Challan","Credit Note","B2B","Consultative Sales"].map(
														(val) => {
															return { label: val, value: val };
														}
													)}
													onChange={(e) =>
														this.handleSelectPiDetailsChange(e, "type")
													}
													required={true}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div
										className="col-12 col-md-3"
										style={{ width: "100%", paddingTop: "1%" }}
									>
										<div className="form-group">
											<div className="mb-3">
												<label>Buyer Id</label>
												<Select
													isSearchable
													placeholder="Select Buyer Id"
													name="dealer"
													options={dealersData.map((val) => {
														console.log(val);
														return {
															label: `${val.solarId} ${val.companyName || ""}`,
															value: val.solarId,
															name: val.companyName || "",
															address: val.address || "",
															gst_number: val.gst_number || "",
															city: val.city || "",
															state: val.state || "",
															pincode: val.pincode || ""
														};
													})}
													onInputChange={debounce(
														(e) => this.inputDealerIdChange(e),
														3000
													)}
													noOptionsMessage={() =>
														fetchDealersListError
															? "No results found"
															: "Loading..."
													}
													onChange={(e) => handleSelectDealerId(e)}
													value={{
														label: `${dealerId} ${dealerName}`,
														value: dealerId,
													}}
												/>
											</div>
										</div>
									</div>
									<InvoiceInput
										label="Buyer Name"
										name="dealerName"
										type="text"
									/>
									<InvoiceInput
										label="Buyer Address"
										name="dealerAddress"
										type="text"
									/>
									<InvoiceInput
										label="Buyer GST"
										name="dealerGstin"
										type="text"
										required={false}
									/>
									<InvoiceInput
										label="Buyer Pincode"
										name="dealerPincode"
										type="text"
										required = {true}
									/>
								</div>
								<div className="row" style={{"justify-content": "space-between"}}>
									
									<InvoiceInput
										label="Phone Number"
										name="phoneNumber"
										type="number"
										required={false}
									/>
										<InvoiceInput
										label="Ship To"
										name="shipTo"
										type="text"
										required={false}
									/>
										<div
										className="col-12 col-md-3"
										style={{ width: "100%", paddingTop: "1%" }}
									>
										<div className="form-group">
											<div className="mb-3">
												<label>Bill From</label>
												<Select
													label="Bill From"
													placeholder="Select Bill From"
													options={["Haryana", "Bangalore", "Gujarat"].map((val) => {
														return { label: val, value: val };
													})}
													onChange={(e) =>
														this.handleSelectPiDetailsChange(e, "billFrom")
													}
													required={true}
												/>
											</div>
										</div>
									</div>
									{this.state.type && ["Credit Note","DC Challan"].includes(this.state.type) && <InvoiceInput
										label="Original Invoice Number"
										name="originalInvoiceValue"
										type="text"
										required={this.state.type == "Credit Note"}
									/>}
								
									<div
										className="col-12 col-md-3"
										style={{ width: "100%", padding: "1%", float:'right' }}
									>
										<div className="form-group">
											<label>Add Combo</label>
											<div className="input-group mb-3">
												<select
													className="form-select form-control customInput"
													onChange={(e) => this.handleComboSelect(e)}
												>
													<option value="">Select</option>
													{comboList.map((val, index) => (
														<option value={val.comboId}>{val.comboId}</option>
													))}
												</select>
												<div className="input-group-append">
													<button
														className="btn btn-outline-success"
														type="button"
														onClick={handleAddCombo}
													>
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="productsDiv col-12">
									<div>
										<span style={{ fontSize: "20px" }}>Products &nbsp;</span>
										<span>
											<button
												type="button"
												className="btn btn-outline-info btn-sm"
												onClick={() => handleAddProduct()}
											>
												<i className="fa fa-plus" aria-hidden="true"></i>
											</button>
										</span>
										<span>
											<button
												type="button"
												className="btn btn-outline-danger btn-sm"
												onClick={() => handleDeleteProduct()}
											>
												<i className="fa fa-minus" aria-hidden="true"></i>
											</button>
										</span>
									</div>
									<div>
										<div
											className="row"
											style={{ fontSize: "15px", textAlign: "center" }}
										>
											{/* <div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Category
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Sub - Category
											</div> */}
											<div
												className="col-2"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Product
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Description
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												SKU ID
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Warranty
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Price (Without GST Without Discount)
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												GST Rate
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Discount
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Price (Without GST After Discount)
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												GST Value
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Price (With GST After Discount)
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Quantity
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Total GST Value
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Total Price (Without GST After Discount)
											</div>
											<div
												className="col"
												style={{ paddingTop: "1%", border: "1px solid #ccc" }}
											>
												Total Price (With GST After Discount)
											</div>
										</div>
										{(productList || []).map((productValue, productIndex) => (
											<div
												key={`Product_${productIndex}`}
												className="row"
												style={{ fontSize: "15px", textAlign: "center" }}
											>
												{/* <InvoiceProductInput
													label="Category"
													name="category"
													type="select"
													productIndex={productIndex}
													options={Object.keys(categoryMapping || {}).map(
														(val) => ({ label: val, value: val })
													)}
												/>
												<InvoiceProductInput
													label="Sub Category"
													name="subcategory"
													type="select"
													productIndex={productIndex}
													options={(
														categoryMapping[productValue.category || ""] || []
													).map((val) => ({ label: val, value: val }))}
												/> */}
												<InvoiceProductIdInput
													label="Product"
													name="pID"
													type="select"
													productIndex={productIndex}
													options={Object.values(uniProductIds)}
												/>
												<InvoiceProductInput
													label="Description"
													name="description"
													type="text"
													productIndex={productIndex}
												/>
													<InvoiceProductInput
													label="SKU ID"
													name="skuID"
													type="text"
													productIndex={productIndex}
													disabled={true}
												/>
												<InvoiceProductInput
													label="Warranty"
													name="warranty"
													type="text"
													productIndex={productIndex}
												/>
												<InvoiceProductInput
													label="Item Price (Without GST Without Discount)"
													name="price"
													type="number"
													step="any"
													productIndex={productIndex}
													inputParams={{ min: 0 }}
												/>
												<InvoiceProductInput
													label="GST Rate"
													name="gstRate"
													type="select"
													productIndex={productIndex}
													options={gstRateOptions}
												/>
												<InvoiceProductInput
													label="Discount"
													name="discount"
													type="number"
													step="any"
													productIndex={productIndex}
													inputParams={{ min: 0 }}
												/>
												<InvoiceProductInput
													label="Item Price (Without GST After Discount)"
													name="priceAfterDiscount"
													type="number"
													step="any"
													productIndex={productIndex}
													disabled={true}
												/>
												<InvoiceProductInput
													label="GST Value"
													name="gstValue"
													type="text"
													productIndex={productIndex}
													disabled={true}
												/>
												<InvoiceProductInput
													label="Item Price (With GST After Discount)"
													name="priceAfterDiscountWithGST"
													type="number"
													productIndex={productIndex}
													disabled={true}
												/>
												<InvoiceProductInput
													label="Quantity"
													name="quantity"
													type="number"
													productIndex={productIndex}
													inputParams={{ min: 1 }}
												/>
												<InvoiceProductInput
													label="Total GST Value"
													name="totalGstValue"
													type="number"
													productIndex={productIndex}
													disabled={true}
												/>
												<InvoiceProductInput
													label="Total Price (Without GST After Discount)"
													name="totalPriceAfterDiscount"
													type="text"
													productIndex={productIndex}
													disabled={true}
												/>
												<InvoiceProductInput
													label="Total Price (With GST After Discount)"
													name="totalPriceAfterDiscountWithGST"
													type="text"
													productIndex={productIndex}
													disabled={true}
												/>
											</div>
										))}
									</div>
								</div>
								<div className="row">
									<InvoiceInput
										label="Freight (INR)"
										name="freight"
										type="number"
									/>
									<InvoiceInput
										label="GST Freight (INR)"
										name="gstFreight"
										type="number"
										disabled={true}
									/>
									<InvoiceInput
										label="Internal Comment"
										name="internalComment"
										type="text"
									/>
									<InvoiceInput
										label="External Comment"
										name="externalComment"
										type="text"
									/>
									<InvoiceInput
										label="Other Amount"
										name="otherAmount"
										type="number"
										required= {false}

									/>
									
									{/* <label>{"Term and Condition "}</label>
										<textarea readOnly={this.state.companyType!=="Zunroof"} value = {invoiceData["termConditions"]}  name="termConditions"  required={true}  cols={60} row={4}  onChange={(e)=>{this.handleInvoiceInputChange(e)}} /> */}

								</div>
								<div>
                                <div className="row ml-7 ">
                                    <button className="button-save col-2" type="button" disabled={this.state.companyType!="Zunroof"}  onClick={handleAddTermCondition}>Add Term & Conditions</button>
                                </div>

								{(this.state.termConditions|| []).map((row, j) => (
                                   
										<InvoiceInputV2 
										label={"Term & Condition"}
										name="termCondition"
										type="text"
										required={true}
										index={j}
										readonly={ this.state.companyType!='Zunroof'}
										/>
                                    // </div>

									))}
                            </div>
								<button
									type="submit"
									className="btn btn-outline-success"
									style={{ float: "right" }}
								>
									Submit
								</button>
								<div style={{ clear: "both" }}></div>
							</div>
						}
					</form>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ zunsolarPiPortal, zunSolarOrderTracker }) {
	let {
		dealersData = [],
		fetchDealersListError = false,
		piProductDetails,
		singlePIDetails,
	} = zunsolarPiPortal;
	let { mapping = {}, comboList = [] } = zunSolarOrderTracker || {};
	return {
		dealersData,
		fetchDealersListError,
		categoryMapping: mapping.category || {},
		comboList,
		piProductDetails,
		singlePIDetails,
		uniProductIds: mapping.uniProductIds || {}
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getSearchResult,
			fetchDealersData,
			fetchMapping,
			fetchCombo,
			generatePi,
			fetchPiProductDetails,
			fetchZunsolarPiOrders,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarGeneratePI);
