import axios from 'axios';
import url,{ zcommerceUrl } from '../config';
import { listingImageData } from '../helper/productDashboardMapper';
import { FETCH_PRODUCT_DATA_SUCCESS, FETCH_ALL_PRODUCTS_SUCESS, FETCH_ALL_PRODUCTS_DATA, FETCH_SINGLE_PRODUCT_DATA } from './actiontypes';

export function fetchProductData(productId) {
    return async (dispatch) => {
        let response = await axios.post(`${url.pathBackend}zunpulse/products/fetch`, { id: productId });
        response = response.data;
        if(response.error) {
            alert("Some error occured");
            return;
        }
        let { productData } = response;
        console.log(productData);
        dispatch({ type: FETCH_PRODUCT_DATA_SUCCESS, payload: productData });
        return;
    }
}

export function updateProductData(productId, path, value, previousData) {
    return async (dispatch) => {
        let response = await axios.post(`${url.pathBackend}zunpulse/products/update`, { id: productId, path, value });
        response = response.data;
        if(response.error) {
            alert("Some error occured");
            return;
        }
        value = response.value;
        previousData = JSON.parse(JSON.stringify(previousData));
        let dataCopy = previousData;
        let keysList = path.split(`/`);
        keysList.forEach((key, index) => {
            if(index == keysList.length - 1) {
                dataCopy[key] = value;
            }
            else {
                dataCopy = dataCopy[key] || {};
            }
        })
        dispatch({ type: FETCH_PRODUCT_DATA_SUCCESS, payload: previousData });
        return;
    }
}

export function updateImage(productId, path, value, previousData) {
    return async (dispatch) => {
        let formData = new FormData();
        formData.append("id", productId);
        formData.append("path", path);
        formData.append("value", value);
        let response = await axios.post(`${url.pathBackend}zunpulse/products/updateimage`, formData);
        response = response.data;
        if(response.error) {
            alert("Some error occured");
            return;
        }
        let filename = response.filename;
        previousData = JSON.parse(JSON.stringify(previousData));
        let dataCopy = previousData;
        let keysList = path.split(`/`);
        keysList.forEach((key, index) => {
            if(index == keysList.length - 1) {
                dataCopy[key] = filename;
            }
            else {
                dataCopy = dataCopy[key] || {};
            }
        })
        dispatch({ type: FETCH_PRODUCT_DATA_SUCCESS, payload: previousData });
        return;
    }
}

export function fetchAllProducts() {
    return async (dispatch) => {
        let response = await axios.post(`${url.pathBackend}zunpulse/products/fetchall`);
        response = response.data;
        if(response.error) {
            alert("Some error occured");
            return;
        }
        let { productsData } = response;
        console.log(productsData);
        dispatch({ type: FETCH_ALL_PRODUCTS_SUCESS, payload: productsData });
        return;
    }
}
export function fetchAllProductData(pid='') {
    return async (dispatch) => {
        let response = await axios.get(`${zcommerceUrl.productPathBackend}product/getAllProduct?pid=${pid}`);
        response = response.data;
        if(response.error) {
            alert("Some error occured");
            return;
        }
        let productsData  =  response.data;

        // console.log("products data",productsData);
        if(pid){
            dispatch({type: FETCH_SINGLE_PRODUCT_DATA ,payload:productsData})
        }
        if(!pid){
        dispatch({ type: FETCH_ALL_PRODUCTS_DATA, payload: productsData });
        }

        return;
    }
}

export function updateProductDataV2(productId, fieldName, value, previousData) {
    return async (dispatch) => {  
        let userObj = JSON.parse(localStorage.getItem("userObj"));
        const emailId = userObj?.email;
            console.log(emailId);
        const data ={}      
         data[fieldName.replace(/\//g, '.')] = value
        //  data["email"] = emailId
         console.log('value',value);
        const apiData = {pid:productId,data:data,email:emailId}
        console.log('apiData', apiData);
        // return;
        let response = await axios.post(`${zcommerceUrl.productPathBackend}product/updateProduct`,apiData);
        response = response.data;
        // value = response.value;
        // console.log('res update data',response);
        previousData = JSON.parse(JSON.stringify(previousData));
        let dataCopy = previousData;
        let updateProductData = [];
        // let keysList = path.split(`/`);
        // keysList.forEach((key, index) => {
        //     if(index == keysList.length - 1) {
        //         dataCopy[key] = value;
        //     }
        //     else {
        //         dataCopy = dataCopy[key] || {};
        //     }
        // })
        // previousData
        console.log('data', fieldName,value);
        previousData[fieldName]=value;  
        console.log('update singleData', previousData )
        updateProductData[0]= previousData;
        dispatch({ type: FETCH_SINGLE_PRODUCT_DATA, payload:updateProductData });
        return;
    }
}



export function updateListingImage(productId, name,  file, previousData, currentPath) {
    return async (dispatch) => {
        let userObj = JSON.parse(localStorage.getItem("userObj"));
        const emailId = userObj?.email;
        let imageIndex=0;
        const formData = listingImageData(productId, name,  file, previousData, currentPath,emailId);
        // let formData = new FormData();
        // formData.append("pid", productId);
        // formData.append("path", path);
        // formData.append("file", file);
        // formData.append("imageIndex", imageIndex);
// return;
        let response = await axios.post(`${zcommerceUrl.productPathBackend}product/uploadListingImage`, formData);
        response = response.data;
        console.log('response', response);
        if(response?.metadata?.message=='failed') {
            alert("Some error occured");
            return;
        }
        previousData= JSON.parse(JSON.stringify(previousData));
        console.log('responseImage ', response);
        let updateProductData =[]
        previousData.productMedia = response.data.productMedia;
        updateProductData[0]= previousData;
        // console.log('previous data images', previousData);
        // previousData[]
        // let filename = response.filename;
        // previousData = JSON.parse(JSON.stringify(previousData));
        // let dataCopy = previousData;
        // let keysList = path.split(`/`);
        // keysList.forEach((key, index) => {
        //     if(index == keysList.length - 1) {
        //         dataCopy[key] = filename;
        //     }
        //     else {
        //         dataCopy = dataCopy[key] || {};
        //     }
        // })
        dispatch({ type: FETCH_SINGLE_PRODUCT_DATA, payload: updateProductData });
        return;
    }
}
