import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import url from '../../config';
import axios from 'axios';
import { bulkInvoiceColumns } from '../../config/column_fields';
import { CSVDownload,CSVLink } from 'react-csv';
import {updateBulkInvoiceData} from '../../actions/zunsolarPiPortalActions'

class PIInvoiceAllV2 extends Component {

    constructor() {
        super();
        this.state = {
            allInvoices: [],
            invoiceFilteredData :[],
            showFilter: false,
            filters: {},
            tableEditFlag: {},
            approvalStatusMapping: [],
            invoiceTypeMapping: [],
            userObj: {},
            uploadCsvModal: false,
            tableData :[],
            totalAmount: 0,
            isFilterApplied:false,
            mapping:{productMapping:[]},
            invoiceEditFlag:{},
            editedInvoiceData: {},
            platforms:["Amazon","Zunsolar","Snapdeal","Flipkart","Dealer","Distributor","Industry Buying","Moglix","Indiamart","B2B","Direct","NGOs","Testing","Procurement As A Service(PAAS)","app","Meesho","Livspace","NobrookerHood","Shopee","Tatacliq","Pepperfry","web","Cred","City sales","Zunpulse","Jio Mart","Croma","PROJECT_SALES","AMAZON_EASYSHIP","GIFTING","Udaan","Consultative Sales"],
            platformOptions:[]

        };
    }

    componentWillMount() {
        this.props.getAllPIInvoiceOrders()
        this.props.getUnicommerceMapping();
        let {platforms} = this.state
        let platformOptions = []
        platforms.forEach((platform)=>{
            platformOptions.push({label:platform,value:platform});
        })
        this.setState({
            platformOptions: platformOptions
        })
    }

    componentWillReceiveProps(props) {
        let {allInvoices, userObj, unicommerceMapping} = props
        // console.log('allInvoices', allInvoices);
        // console.log('unicommerceMapping',unicommerceMapping);
        const productMapping = [];
        let totalAmount = 0;
        // allInvoices.forEach((invoice)=>{
        //     totalAmount+= parseInt(invoice.invoiceAmount);

        // })
        unicommerceMapping.forEach((product)=>{
            productMapping.push({label:product.productName, value:product.pID})
        })
        // const tableDataConfig = [];
        //     for(let key in allInvoices[0]){
        //  const row = {
        //         Header: key,
        //         accessor: key
        //         }
        //         tableDataConfig.push(row);
        //     }
            this.setState({
            allInvoices: allInvoices || [],
            userObj,

            totalAmount,
            mapping:{...this.state.mapping,productMapping},
            invoiceFilteredData:allInvoices
        })
    }
    openUploadCsvModal = () => {
        this.setState({
        uploadCsvModal: true,

        })
    }

    closeUploadCsvModal = () => {
        this.setState({
          uploadCsvModal: false,
          successMessage: undefined,
          errorMessage: undefined
        })
      }
    
    uploadFile = (e) => {
        let files = e.target.files
        this.setState({
            uploadFile: files[0]
        })
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = (e) => {
        let { filters, allInvoices,invoiceFilteredData } = this.state

        console.log('filters', filters);
        this.setState({
            isFilterApplied:true
        })
        if(filters && !Object.keys(filters).length){
            filters = ''
            return;

        }
        let filteredInvoices = allInvoices;
        console.log('filteredInvoices',filteredInvoices);
        let startDate = filters.startDate;
        let endDate = filters.endDate;
        let dealerId = filters.dealerId;
        let pids = filters.pids;
        let platforms = filters.platforms;
        if(startDate){
            filteredInvoices = filteredInvoices.filter((invoice)=>{
                return invoice.date>=startDate;
            })
        }
        if(endDate){

            filteredInvoices = filteredInvoices.filter((invoice)=>{
                return invoice.date<=endDate;
            })
        }
        if(dealerId){

            filteredInvoices = filteredInvoices.filter((invoice)=>{
                return invoice.dealerId==dealerId;
            })
        }
        if(pids && pids.length>0){
            filteredInvoices = filteredInvoices.filter((invoice)=>{
                return pids.includes(invoice.pid);
            })
        }
        if(platforms && platforms.length>0){
            filteredInvoices = filteredInvoices.filter((invoice)=>{
                return platforms.includes(invoice.platform)
            })
        }
        this.setState({
            invoiceFilteredData:filteredInvoices || allInvoices
        })
        // let filterInvoices = 
     

        // this.props.getAllPIInvoiceOrders(filters)
    }

    AddSelect = ({ isMulti, label, name, options = [], onChange, required = false, value, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required ? '*' : ''}`}</label>
                        <Select
                            isSearchable={true}
                            placeholder={label}
                            name={name}
                            options={options}
                            onChange={(e) => onChange(e, name)}
                            isMulti={isMulti}
                            value={{ value: value, label: value }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    tableSelect = (name, value, options, row, onChange) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { onChange(e, row) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    tableInput = (name, value, type, row, onChange) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => onChange(e, row)} />
    }

  

    renderInputCellStirct = (row) => {
        let { id, type } = row.column;
        let { tableEditFlag, editedTableData } = this.state;
        return (!tableEditFlag[row.index] || row.value ? row.value : this.tableInput(id, editedTableData[row.index][id], type, row, this.handleTableInputChange))
    }

    multiSelectType = (name, options, placeholder, onChange) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }
    selectType = (name, options, placeholder, onChange) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }

    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} value = {this.state.filters.name} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        console.log('values', value);
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
        console.log();
    }

    handleDownloadCSV = ()=>{
        let {invoiceFilteredData}  = this.state;
        console.log('CSVDownload',invoiceFilteredData);
        if(invoiceFilteredData && invoiceFilteredData.length>0){
            console.log('inside if');
           return <CSVDownload data={invoiceFilteredData}  target="_blank"/> 
        }


    }
    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        console.log('value',value);
        // if(value){
        filters = JSON.parse(JSON.stringify(filters));
        // filters[type] = filters[type] || {};
        filters[name] = value;
        console.log('filters', filters);
        this.setState({
            filters
        })
    
    }

    handleTableSelectChange = (e,row) => {
        let {name, value} = e.target
        let { index } = row;
        let { editedTableData } = this.state;
        editedTableData = JSON.parse(JSON.stringify(editedTableData))
        editedTableData[index][name] = value;
        this.setState({
            editedTableData
        })
    }
    

    handleInvoiceDataChanges = (e, row) => {
        let { name, value } = e.target;
        console.log('data', name, value, row);
        let { id } = row.original;
        let { editedInvoiceData } = this.state;
        editedInvoiceData = JSON.parse(JSON.stringify(editedInvoiceData))
        console.log('editedInvoiceData',editedInvoiceData);
        editedInvoiceData[id][name] = value;
        this.setState({
            editedInvoiceData
        })
    }
    invoiceDataInput = (name, value, type, row) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInvoiceDataChanges(e, row)} />
    }
    renderInputCell = (row) => {
        let {id,type} = row.column;
        // console.log('row',row.column);

        return (
            !this.state.editedInvoiceData[row.original.id]?row.value:this.invoiceDataInput(id,this.state.editedInvoiceData[row.original.id][id],"text",row)
        )
    }
   
    handleTableInputChange = (e,row) => {
        let { name, value } = e.target;
        let { index } = row;
        let { editedTableData } = this.state;
        editedTableData = JSON.parse(JSON.stringify(editedTableData))
        editedTableData[index][name] = value;
        this.setState({
            editedTableData
        })
    }

    handleSaveInvoiceDataClick = (row) => {
        let {id} = row.original;
        let {editedInvoiceData,allInvoices} = this.state
        // let{editedInvoiceData,}
        let newData = editedInvoiceData[id];
        console.log('newData', newData);
        this.props.updateBulkInvoiceData(newData,allInvoices)
        this.setState({
            invoiceEditFlag:{
                ...this.state.invoiceEditFlag,
                [id]: false
            },
            editedInvoiceData:{
                ...this.state.editedInvoiceData,
                [id]:false
            }
        })

    }
    handleInputChange = (e) => {
        let { name, value } = e.target;
        console.log('name', name, value);
        let {formData} = this.state;
        // formData = JSON.parse(JSON.stringify(formData));
        // formData[name]=value
        this.setState({
            formData:{
                ...this.state.formData,
                [name]:value
            }
        })
        
    }
    InputTypeText = (name, value, type ) => {
        console.log('data',name, type );
        return (
            <input type={type} name ={name} value = {value} className="form-control"  onChange={(e) => this.handleInputChange(e)} ></input>
        )
    }

    handleEditInvoiceDataClick = (row)=>{
        console.log('row',row);
        let{id} = row;
        this.setState({
            invoiceEditFlag:{
                ...this.state.invoiceEditFlag,
                [id]:true
            },
            editedInvoiceData:{
                ...this.state.editedInvoiceData,
                [id]:row
            }
        })
    }
    handleCancelInvoiceDataClick = (row)=>{
        let {id} = row.original;
        this.setState({
            invoiceEditFlag:{
                ...this.state.invoiceEditFlag,
                [id]: false
            },
            editedInvoiceData:{
                ...this.state.editedInvoiceData,
                [id]:false
            }
        })


    }
   
    submitFileUpload = async () => {
        console.log('file', this.state.uploadFile);
        const file = this.state.uploadFile;
        if(!file){
            alert("Please upload file")
            return;
        }
        const apiFormData = new FormData();
        apiFormData.append("invoiceFile",file )
        let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/invoiceBulkUpload`, apiFormData);
        response = response.data
        console.log('response', response);
        if (response.status && response.status=='success') {
            this.setState({
              errorMessage: undefined,
              successMessage: "Data Uploaded Successfully",
            //   uploadMessage: "Data Uploaded Successfully",
            })
            setTimeout(()=>{
                this.closeUploadCsvModal();
              },2000);
          
          }
          else{
            this.setState({
                errorMessage: response && response.data && response.data.join(" , ") || "something went wrong",
                uploadMessage: "",
              })
            }
            
            
        };
        
        render() {
        let { state, props, handleSaveTableClick, handleCancelTableClick, handleEditTableClick, handleDownloadFileClick, handleTableSelectChange, tableSelect, tableInput, handleTableInputChange, renderInputCell, renderInputCellStirct } = this
         const bulkInvoiceColumns = [
            {Header: 'Id', accessor: 'id'},
            {
                Header: 'Dealer Id',
                accessor: 'dealerId',
                Cell: renderInputCell
        
            },
            {   Header: 'HsnSac',
                accessor: 'hsnSac',
                type:"text",
                Cell: renderInputCell
            },
            {
                Header: 'Product',
                accessor: 'product',
                Cell: renderInputCell
            },
            {
                Header: 'Pid',
                accessor: 'pid',
                Cell: renderInputCell
            },
            {   Header: 'Description',
                accessor: 'description',
                Cell: renderInputCell
            },
            {Header: 'Quantity', 
            accessor: 'qty',
            Cell: renderInputCell,
        },
            {
                Header: row => {
                    let { data } = row;
                    let total = data.reduce((a, b) => a + (b.rate && !isNaN(b.rate) ? parseInt(b.rate) : 0), 0);
                    return `Rate (${total})`;
                },
                accessor: 'rate',
                Cell: renderInputCell
            },
            {
                Header: row => {
                let { data } = row;
                let total = data.reduce((a, b) => a + (b.tax && !isNaN(b.tax) ? parseInt(b.tax) : 0), 0);
                return `Tax (${total})`;
                    },
            accessor: 'tax',
            Cell: renderInputCell
        },
            { Header: row => {
                    let { data } = row;
                    let total = data.reduce((a, b) => a + (b.amount && !isNaN(b.amount) ? parseInt(b.amount) : 0), 0);
                    return `Amount (${total})`;
                },
             accessor: 'amount',
             Cell: renderInputCell
            },
            {Header: 'Platform', accessor: 'platform',Cell: renderInputCell},
            {Header: 'Date', accessor: 'date',Cell: renderInputCell},
            {Header: 'Transaction Type', accessor: 'transactionType',Cell: renderInputCell},
            {Header: 'Invoice Number', accessor: 'invoiceNumber',Cell: renderInputCell},
            {Header: 'Name', accessor: 'name',Cell: renderInputCell},
            {Header: 'Memo Description', accessor: 'memoDescription',Cell: renderInputCell},
            {Header: 'Account', accessor: 'account',Cell: renderInputCell},
            {Header: 'Split', accessor: 'split',Cell: renderInputCell},
            { 
                Header: 'Edit',
                Cell: row => {
                    return (
                    this.state.invoiceEditFlag[row.original.id] ? <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-success btn-sm" style={{marginRight: '3px'}} onClick={(e) => this.handleSaveInvoiceDataClick(row)}>Save</button><button className="btn btn-light btn-sm" onClick={(e) => this.handleCancelInvoiceDataClick(row)}>Cancel</button></div> : <div className="row text-center" style={{width: 'fit-content'}}>{((this.state.userObj || {}).access || []).includes("Zunsolar-Admin")?<button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => this.handleEditInvoiceDataClick (row.original)}>Edit</button>:''}</div>
                    )
                },
                minWidth: 150
        }
        
        
           
        
        
        ]
         
        const modalStyles = {
            modal: {
              width: '40%',
              maxWidth: '40%',
              padding: '40px',
              background: '#f7f7f7',
              textAlign: 'center'
            }
        };
        // console.log(this.state.allInvoices)
        let { allInvoices, tableEditFlag, editedTableData, approvalStatusMapping, invoiceTypeMapping, userObj, tableData,invoiceFilteredData } = state;

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        // console.log('allInvoice', allInvoices);
        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Invoices</h4>
                <div className="topbar-section allLeadViewFilters">
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye"></i>} </b></div>
                    <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                    <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                        <div className="col-md-3">
                            {this.inputType("startDate", "Start Date", 'date', this.handleInputDateChangeFilters,'startDate')}
                        </div>
                        <div className="col-md-3">
                            {this.inputType("endDate", " End Date", 'date', this.handleInputDateChangeFilters, 'endDate')}
                        </div>
                        <div className="col-md-3">
                        {this.inputType("dealerId", "Dealer Id", 'text', this.handleInputDateChangeFilters,'dealerId')}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("pids", this.state.mapping.productMapping, "Product Names", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("platforms", this.state.platformOptions, "Platform", this.handleSelectChangeFilters)}
                        </div>
                        
                        <div className="col-md-3">
                            <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>
                   
                </div>
                <div className="allLeadViewFilters btn btn-light mb-4 ml-2" style={{fontWeight:'bold'}}  onClick={this.openUploadCsvModal}>
                        Upload CSV

                </div>
                <div className="allLeadViewFilters btn btn-light mb-4 ml-2" style={{fontWeight:'bold'}}  >
                <CSVLink data={this.state.invoiceFilteredData} filename ={"invoiceData.csv"}>Download  CSV</CSVLink>
                </div>
                   {this.state.isFilterApplied && this.state.filters && this.state.filters?.orderId  && <div className='mb-4' style={{fontSize:'18px'}}>
                        Total Amount {this.state.totalAmount}
                    </div>}
                    <Modal open={this.state.uploadCsvModal} onClose={this.closeUploadCsvModal} styles={modalStyles} center>
                            <div style={{ border: "1px solid #92b0b3", padding: "5px" }}>
                            <div className=" container-fluid uploadBox">
                            <input style={{ width: "50 %", height: '42px', fontWeight: "bold" }} type="file" onChange={(e) => { this.uploadFile(e) }} accept=".csv"></input>
                            <button style={{ height: '38px', fontWeight: "bold" }} className="btn btn-info btn-sm" onClick={this.submitFileUpload}>Upload <i className="fa fa-upload"></i></button>
                            <br></br>
                            <div style={{ margin: "20px" }} >
                                <span style={{ color: "red" }}>{this.state.errorMessage ? this.state.errorMessage : ""}</span>
                                <span style={{ color: "green" }}>{this.state.successMessage ? this.state.successMessage : ""}</span>
                                <span style={{ color: "green" }}>{this.state.uploadMessage ? this.state.uploadMessage : ""}</span>
                            </div>
                            </div>
                            </div>
                    </Modal>
                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={invoiceFilteredData}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    columns={bulkInvoiceColumns}
                    defaultSorted={[{ id: "id", desc: false }]}
                />
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateBulkInvoiceData}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PIInvoiceAllV2);