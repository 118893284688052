import React, { Component } from 'react'
import HeaderComponent from '../../components/HeaderComponent';
import  '../../styles/returnOrder.css'
import Select from 'react-select'
import {fetchReturnOrderMapping} from '../../actions/fetchAllMapping'
import { createReturnOrder, fetchSubOrderDataForReturn } from '../../actions/zunsolarOrderTrackerActions';
import { getUnicommerceMapping } from '../../actions/zunsolarPiInvoiceAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

class  ReturnOrders extends Component {
  constructor(){
    super();
    this.state = {
      returnOrderData :{qcChecked:"No", returnDate: moment().format("YYYY-MM-DD"), quantity: 1},
      returnOptionsMapping:{},
      productsMapping: {},
      userObj:{},
      subOrderIdMapping :[],
      subOrdersMap :{},
      damageColourMapping :[{color:"Green", meaning:"Refurbishment at warehouse | Parts to be replaced"},{color:"Yellow", meaning:"Not Functioning"},{color:"Red", meaning:"Refurbishment at OEM @Full Product to be Sent"}]
    }
  }
  componentWillMount() {
    if (localStorage.getItem("userObj")) {
        let userObj = JSON.parse(localStorage.getItem("userObj"));
        this.setState({
            userObj
        })
    }
}
  componentDidMount(){
    this.props.fetchReturnOrderMapping();
    this.props.getUnicommerceMapping();
    // if(localStorage.getItem("userObj")){
    //   let userObj = JSON.parse(localStorage.getItem("userObj"));
    //   this.setState({
    //     userObj
    //   })
    // }
  }


  componentWillReceiveProps(props){
    let {returnMapping,unicommerceMapping,returnSubOrders={}} = props;
    let {subOrdersMap={}, subOrderIdMapping=[]} = returnSubOrders
    let productMap  = {};
    let skuOptions = [];
    let wrongProduct = {pID:"NA", productName:"NA",unicommerceId:"NA", variantType:'',isActive:1}
    let updatedUnicommerceMapping = JSON.parse(JSON.stringify(unicommerceMapping || []));
    updatedUnicommerceMapping.push(wrongProduct)
    updatedUnicommerceMapping.forEach((product)=>{
      if(product.isActive){
      productMap[product.pID] = product;
      let variant = product.variantType && product.variantType=="NULL"? '':product.variantType
       skuOptions.push({label:product.productName + " "+  variant, value: product.pID});
      }
    })
    let productsMapping = {}
    productsMapping.productMap = productMap;
    productsMapping.skuOptions = skuOptions;
    this.setState({
      returnOptionsMapping:returnMapping,
      productsMapping: productsMapping,
      subOrdersMap,
      subOrderIdMapping
    })
  }

  handleReturnOrderInputChange = (e) => {
		let { name, value } = e.target;
    if(name=="orderId"){
      this.props.fetchSubOrderDataForReturn(value);
    }
		let { returnOrderData = [] } = this.state;
		returnOrderData = JSON.parse(JSON.stringify(returnOrderData));
		returnOrderData[name] = value;
		this.setState({
			returnOrderData,
		});
	};

  handleSubmitReturnIdForm = (e) => {
		e.preventDefault();
		let { returnOrderData = {} } = this.state;
  };
  
  handleSubmitIncoingTextForm = (e) => {
    e.preventDefault();
    let { incomingText = '' } = this.state.returnOrderData;
    let [ incomingDate = '', courierPartnerCode = '' ] = incomingText.split('/');
    if(!moment(incomingDate, "DD-MM-YYYY", true).isValid()) {
      alert("Invalid format");
      return;
    }
    let returnDate = moment(incomingDate, "DD-MM-YYYY", true).format("YYYY-MM-DD");
    this.setState({
      returnOrderData: {
        ...this.state.returnOrderData,
        returnDate,
        courierPartnerCode: courierPartnerCode
      }
    })
  };

  handleSubmitReturnOrderForm = (e) => {
		e.preventDefault();
		let { returnOrderData = {}, userObj, productsMapping } = this.state;
    let createdBy = userObj.username;
    // check required field
    returnOrderData.responsible = createdBy;

    delete returnOrderData.damageClassification;
    if((!returnOrderData.subOrderId || !returnOrderData.subOrderId.length) && returnOrderData?.sku){
      let productMap = productsMapping && productsMapping.productMap || {}
      let pid = returnOrderData?.sku;
      let productName = productMap[pid] && productMap[pid]?.productName;
      returnOrderData.sku =      productName || returnOrderData.sku 
      returnOrderData.pid = pid;
      returnOrderData.skuCode = productMap[pid]?.unicommerceId 
    }
   

    let requiredFields = [ "returnDate","platform",
      "sku","responsible","returnMode" ];
      let missingFields = []
      requiredFields.forEach((field) => {
        if(!returnOrderData[field] || !returnOrderData[field].length){
          missingFields.push(field)
        }

      })
      if(!returnOrderData.returnId &&   !returnOrderData.orderId   ){
        missingFields.push("orderId/returnId")
      }
      if(returnOrderData.qcStatus && returnOrderData.qcStatus == "QC Fail"){
        let qcRequiredFields = ["itemPart","defect"];
          qcRequiredFields.forEach((field)=>{
          if(!returnOrderData[field]  || !returnOrderData[field].length){
            missingFields.push(field);
          }
        })

      }
      if(returnOrderData.qcChecked == 'Yes' && !returnOrderData.qcStatus) {
        missingFields.push(`qcStatus`);
      }
      if(returnOrderData.putaway == 'done' && !returnOrderData.putawayId) {
        missingFields.push(`putawayId`);
      }
      if(['QC Fail', 'QC Pass'].includes(returnOrderData.qcStatus) && !returnOrderData.defect) {
        missingFields.push(`defect`);
      }
      if(missingFields && missingFields.length){
        alert(`Following Required Fields are missing  ${missingFields.join(",")}`)
        return;
      }
      //sku productName, pid  productId, skuCode uniId
    delete returnOrderData.productName
    let apiData = {
      createdBy: createdBy,
      ...returnOrderData
    }
    if(apiData.incomingText) {
      delete apiData.incomingText;
    }
    this.props.createReturnOrder(apiData);
    this.setState({
      returnOrderData:{}
    })
	};

  handleSelectChange = (e, name) => {
    let {value} = e;
		let { returnOrderData = [], subOrdersMap,returnOptionsMapping,productsMapping } = this.state;
    returnOrderData = JSON.parse(JSON.stringify(returnOrderData));
    if(name=="subOrderId"){
      let subOrderData = subOrdersMap[value] || {};
      returnOrderData.sku = subOrderData.productName || ''
      returnOrderData.skuCode  = subOrderData.unicomerceItemSkuCode || ''
      returnOrderData.platform = subOrderData.platform || ''
      returnOrderData.pid = subOrderData.pID || ''
      returnOrderData.subcategory = subOrderData.subCategory;
      
     }
     if(name == "sku"){
      let productMap = productsMapping && productsMapping.productMap || {}
      let pid = value;
      let productName = productMap[pid] && productMap[pid]?.productName;
      returnOrderData.productName =  productName || returnOrderData.sku 
      returnOrderData.subcategory = productMap[pid]?.subCategory;
      returnOrderData.itemPart = ''

     }

     if(name=="defect"){
      returnOrderData.damageClassification = returnOptionsMapping.damageClassification[value];
     }

		returnOrderData[name] = value;
		this.setState({
			returnOrderData,
		});
    
    // let value = (e || []).map(option => option.value);
   
}

SelectType = ({label,name, options, placeholder, disabled, value , required=false}) => {
  return (
    <div
    className="col-12 col-md-3"
    style={{ width: "100%", paddingTop: "1%" }}
  >
    <div className="form-group">
      <div className="mb-3">
        <label>{label}</label>
        <Select
                isSearchable
                onChange={(e) => { this.handleSelectChange(e, name) }}
                value = {{label:value,value:value}}
                placeholder = {placeholder}
                name = {name}
                options={options}
                isDisabled = {disabled}
                required={required}
                id = {name}
            />

      </div>
    </div>
  </div>
  )
}


  ReturnInput = ({
		label,
		name,
		type,
		inputParams = {},
		required = false,
		disabled = false,
	}) => {
		let { returnOrderData = {} } = this.state;
		let value = returnOrderData[name] || "";
		return (
			<div
				className="col-12 col-md-3"
				style={{ width: "100%", paddingTop: "1%" }}
			>
				<div className="form-group">
					<div className="mb-3">
						<label>{label}</label>
						<input
							className="form-control customInput"
							type={type}
							name={name}
							placeholder={label}
							value={value}
							onChange={(e) => this.handleReturnOrderInputChange(e)}
							{...inputParams}
							required={required}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		);
	};

  render(){
    let {state, ReturnInput,handleSubmitReturnOrderForm,handleSubmitReturnIdForm, SelectType, handleSubmitIncoingTextForm} = this
    let {returnOrderData, returnOptionsMapping,productsMapping, subOrderIdMapping, userObj, damageColourMapping} = state
    console.log('userObj',userObj);
    if(!((userObj.access || []).includes("Return-Admin"))) {
      return <Redirect to={"/"} />
  }

    return (
      <div>
        <HeaderComponent/>

        <div
					className="container-fluid"
					style={{ paddingTop: "70px", fontSize: "12px !important" }}	>

        </div>
        <div className='headContainer'>
        <h4  className='headText'>
						Return Order Portal
					</h4>
          

          </div>
          {/* <div className='headContainer'>
        <h6 >
            Damage Color Code
					</h6>
          <div className='colorMain'>
          {damageColourMapping.map((colourMapping)=>(
            <div className='colorContainer' >
              <h6>{colourMapping.color}</h6>
              <p>{colourMapping.meaning}</p>
              </div>
          ))}
          </div>

          </div> */}
          <div className='formContainer'>
          <form onSubmit={handleSubmitReturnIdForm}>
            <div>
            <div className='row'>
									<ReturnInput label="Return Id" name="returnId" type="text"  />   
            </div>
                </div>
          </form>
          <form onSubmit={handleSubmitIncoingTextForm}>
            <div>
            <div className='row'>
									<ReturnInput label="Incoming text" name="incomingText" type="text"  />   
            </div>
                </div>
          </form>
          <form onSubmit={handleSubmitReturnOrderForm}>
            <div>
            <div className='row'>
									<ReturnInput label="Incoming Date" name="returnDate" type="date" required={true} /> 
                  <SelectType label = "Courier Partner Code" name = "courierPartnerCode"  options={returnOptionsMapping.courierPartner || []} placeholder="select courier partner" value = {returnOrderData.courierPartnerCode} required={true}/>
									<ReturnInput label="Order Id" name="orderId" type="text" />   
                  {/* <SelectType label = "Sub Order Id" name = "subOrderId"  options={subOrderIdMapping || []} placeholder="select subOrder Id" value = {returnOrderData.subOrderId} required={true} defaultValue = { subOrderIdMapping.length && subOrderIdMapping[0]}/> */}
                  <SelectType label = "Mode" name = "returnMode"  options={returnOptionsMapping.returnMode || []} placeholder="select mode" value = {returnOrderData.returnMode} required={true}/>
								{returnOrderData.subOrderId && returnOrderData.subOrderId.length &&		<ReturnInput label="Platform" name="platform" type="text" required={true} />}   

                 {(!returnOrderData.subOrderId || !returnOrderData.subOrderId.length) &&  <SelectType label = "Platform" name = "platform" options={returnOptionsMapping.platform || []} value = {returnOrderData.platform}  placeholder="select platform"  required={true} />}
									{/* <ReturnInput label="SKU code" name="skuCode" type="text" required={true} />    */}
									<ReturnInput label="Batch code" name="batchCode" type="text"  />   
								{returnOrderData.subOrderId && returnOrderData.subOrderId.length &&	<ReturnInput label="SKU" name="sku" type="text" required={true} />}   

                 {(!returnOrderData.subOrderId || !returnOrderData.subOrderId.length) &&  <SelectType label = "SKU" name = "sku"  options={productsMapping.skuOptions || []} placeholder="select SKU"  value = {returnOrderData.productName} required={true}/>}
									{/* <ReturnInput label="QC Checked" name="qcChecked" type="text" />  */}

                  <SelectType label = "QC Checked" name = "qcChecked"  options={returnOptionsMapping.qcChecked || []} value = {returnOrderData.qcChecked} placeholder="QC Checked"/>
									{/* <ReturnInput label="QC Date" name="qcDate" type="date" disabled={returnOrderData.qcChecked!="Yes"}/>  */}
									<ReturnInput label="Quantity" name="quantity" type="number" /> 

                  {/* <SelectType label = "Responsible Person" name = "responsible"  options={returnOptionsMapping.responsiblePerson || []} placeholder="Select Responsible" required={true}/> */}
                  <SelectType label = "QC Status" name = "qcStatus"  options={returnOptionsMapping.qcStatus || []} placeholder="QC Status" value={returnOrderData.qcStatus} disabled={returnOrderData.qcChecked!="Yes"}/>
									<ReturnInput label="Sub category" name="subcategory" type="text" disabled={returnOrderData.qcStatus!="QC Fail"} /> 

                  <SelectType label = "Item Part" name = "itemPart"  options={returnOrderData.subcategory && returnOptionsMapping.itemParts[returnOrderData.subcategory] || returnOptionsMapping.itemParts && returnOptionsMapping.itemParts['other']} value = {returnOrderData.itemPart} placeholder="Item part" disabled={returnOrderData.qcStatus!="QC Fail"}/>
                  <SelectType label = "Defect" name = "defect"  options={returnOrderData.qcStatus=="QC Fail" ? returnOptionsMapping.defectQCFail : returnOrderData.qcStatus=="QC Pass"? returnOptionsMapping.defectQCPass : []} placeholder="Defect" value={returnOrderData.defect} />
									<ReturnInput label="Damage Category Classfication" name="damageClassification" type="text" disabled={true} /> 

                  {/* <SelectType label = "QC Feedback" name = "qcFeedback"  options={returnOptionsMapping.qcFeedback || []} placeholder="QC Feedback" value={returnOrderData.qcFeedback} disabled={returnOrderData.qcChecked!="Yes"}/> */}
									{/* <ReturnInput label="Damage Claim Id" name="damageClaimId" type="text" disabled={!returnOrderData.qcStatus  ||   !["damaged/missing product","wrong product"].includes(returnOrderData.qcStatus) } />   
									<ReturnInput label="Damage Claim Date" name="damageClaimDate" type="date"  disabled={!returnOrderData.qcStatus  ||   !["damaged/missing product","wrong product"].includes(returnOrderData.qcStatus) } />   
									<ReturnInput label="Damage Claim Amount" name="damageClaimAmount" type="number"  disabled={!returnOrderData.qcStatus  ||   !["damaged/missing product","wrong product"].includes(returnOrderData.qcStatus) }  />    */}
                  <SelectType label = "Putaway" name = "putaway"  options={returnOptionsMapping.putaway || []} placeholder="select Putaway"  value = {returnOrderData.putaway} disabled={returnOrderData.qcChecked!="Yes"}/>
                  <ReturnInput label="Putaway Id" name="putawayId" type="text" disabled={returnOrderData.qcChecked!="Yes"}  />
                  {/* <SelectType label = "sent (refurbish/warranty)" name = "sent"  options={returnOptionsMapping.sent || []} placeholder="select Sent" disabled={true}/> */}
                  {/* <SelectType label = "Item Received" name = "itemReceived"  options={returnOptionsMapping.itemReceived || []} placeholder="select Item Received" value={returnOrderData.itemReceived} disabled={!returnOrderData.qcStatus  ||   !["damaged/missing product","fail","damaged","QC Fail"].includes(returnOrderData.qcStatus) } /> */}
									<ReturnInput label="Remarks" name="remarks" type="text" />   




            </div>
            <button
									type="submit"
									className="btn btn-outline-success"
									style={{ float: "right" }}>
									Submit
								</button>

           

                </div>
          </form>
          </div>
      </div>


    )

  }
}

function mapStateToProps(state){
  return{
    returnMapping: state.fetchAllMapping && state.fetchAllMapping.returnMapping || {},
    unicommerceMapping: state.zunsolarBulkPIInvoice && state.zunsolarBulkPIInvoice.unicommerceMapping || [],
    returnSubOrders :   state.zunSolarOrderTracker &&  state.zunSolarOrderTracker.returnSubOrders || {}


  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({fetchReturnOrderMapping,createReturnOrder, getUnicommerceMapping, fetchSubOrderDataForReturn }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnOrders)