import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/HeaderComponent';
import { addZunpulseCombo, fetchComboDetails, updateCombo } from '../../actions/zunpulseOrderTrackingActions';
import { bindActionCreators } from 'redux';
import { parse } from 'query-string';
import swal from 'sweetalert';


class ZunpulseAddCombo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products: [{}],
            formData: {},
            categorySubCategoryMapping: {
                Lighting: {
                    "smart downlight 9W":"D10020",
                    "smart bulb 10W": "D10000",
                    "smart bulb 10W": "D10000",
                    "smart bulb 9W": "D10001",
                    "smart bulb 12W": "D11000",
                    "smart striplight 15W": "D12000",
                    "smart downlight 15W": "D20020",
                    "Batten Light 20W": "D20040",
                    "Surface-light-10W": "D20030"

                },
                Control: {
                    "smart energy monitor":	"D2",
                    "smart plug 16A": "D20000",
                    "smart AC remote": "D30000",
                    "smart TV remote": "D31000",
                    "Room Heater 2000 W": "D29000",
                    "Geyser 3L": "D29001",
                    "Geyser 15L": "D29002",
                    "smart air purifier": "D20050",
                    "smart plug 16A EM": "D20010",
                },
                Security: {
                    "smart security" :  "D40000",
                    "smart camera" :  "D50000",
                    "smart doorbell" :  "D51000",
                    "smart door lock" : "D60000"
                }
            },
            editCheck: false
        };
        // this.deviceMap = {
        //     "Smart Light": 10000,
        //     "Smart Downlight": 10020,
        //     "Smart Energy Monitor": 2,
        //     "Smart Plug": 20000,
        //     "AC Remote": 30000,
        //     ":": 31000,
        //     "Smart Security": 40000,
        //     "Smart Camera": 50000,
        //     "Smart Doorbell": 51000
        // };
    }

    componentWillMount(){
        const { location: { search } } = this.props;
            let { comboId = '' } = parse(search);
            if(comboId) {
                this.props.fetchComboDetails(comboId);
                this.setState({
                    editCheck: true
                })
            }
    }

    componentWillReceiveProps(props){
        let {combo, productList} = props
        if(combo && productList.length > 0){
            this.setState({
                products: productList,
                formData: combo
            })
        }
    }

    addProduct = () => {
        let { products } = this.state;
        if (products.length > 9) {
            return;
        }
        products = [...products, {}];
        this.setState({
            products
        })
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleProductChange = (e, index) => {
        let {products, categorySubCategoryMapping} = this.state
        // let products = JSON.parse(JSON.stringify(this.state.products));
        let { name, value } = e.target;
        if(name == "subCategory"){
            let cat = (products[index] || {}).category
            if(cat && value){
                products[index].productId = categorySubCategoryMapping[cat][value]
            }
        }
        products[index][name] = value;
        this.setState({
            products
        }, () => { })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { products, formData, editCheck } = this.state;
        if(editCheck){
            this.props.updateCombo(formData, products)
        } else {
            let comboDetails = {
                comboName: formData.comboName,
                comboId: formData.comboId,
                productsArray: products,
            }
            this.props.addZunpulseCombo(comboDetails)
        }
    }

    render() {
        let { products, categorySubCategoryMapping, formData, editCheck } = this.state
        let { comboId = '', comboName = ''} = formData
        return (
            <div className="analyseAc">
                <Header />
                <div class="container">
                    <div className="row">
                        <h4 className="col-9" style={{ fontWeight: "bold" }}>Add Combo</h4>
                        {!editCheck?<button className="button-save col-2" onClick={(e) => this.addProduct(e)}>Add Product</button>:''}
                    </div>
                    <hr className="analyseAcHr"></hr>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Combo Name
                            </label>
                            <div class="col-md-8">
                                <input type="text" className="form-control" name="comboName" onChange={(e) => this.handleInputChange(e)} value={comboName} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Combo Id
                            </label>
                            <div class="col-md-8">
                                <input type="text" className="form-control" name="comboId" onChange={(e) => this.handleInputChange(e)} value={comboId} required disabled={editCheck}></input>
                            </div>
                        </div>
                        {
                            products.map((row, index) => {
                                return (
                                    <div className="row">
                                        {/* <div className="col-md-4">
                                            <p style={{ fontSize: "17px", fontWeight: "bold" }}>Product {index + 1}</p>
                                        </div> */}
                                        <div className="col-md-3">
                                            <select className="form-control" name="category" onChange={(e) => this.handleProductChange(e, index)} value={products[index].category || ""} required>
                                                <option key="-1" value="">Select Device</option>
                                                {
                                                    Object.keys(categorySubCategoryMapping || {}).map((category, i) => {
                                                        return (
                                                            <option key={i} value={category}>{category}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <select className="form-control" name="subCategory" onChange={(e) => this.handleProductChange(e, index)} value={products[index].subCategory || ""} required>
                                                <option key="-1" value="">Select Device</option>
                                                {
                                                    (Object.keys(categorySubCategoryMapping[products[index].category] || {}) || []).map((subCategory, i) => {
                                                        return (
                                                            <option key={i} value={subCategory}>{subCategory}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <input type="number" value={products[index].quantity || ""} className="form-control" name="quantity" onChange={(e) => this.handleProductChange(e, index)} placeholder="Quantity" required></input>
                                        </div>
                                        <div className="col-md-3">
                                            <input type="text" value={products[index].productId || ""} className="form-control" name="productId" onChange={(e) => this.handleProductChange(e, index)} placeholder="Product Id" required disabled></input>
                                        </div>
                                        <br /><br />
                                    </div>
                                )
                            })
                        }

                        {/* <div className="row">
                            <div className="col-md-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                <p>MRP</p>
                            </div>
                            <div className="col-md-4">
                                <input type="number" className="form-control" name="mrp" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <br /><br />
                        </div>

                        <div className="row">
                            <div className="col-md-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                <p>Selling Price</p>
                            </div>
                            <div className="col-md-4">
                                <input type="number" className="form-control" name="sellingPrice" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                        </div> */}

                        <br /><br />
                        <div className="callCountSubmit">
                            <button className="btn btn-success" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ zunpulseOrders }) {
    let { combo = {}, productList= [] } = zunpulseOrders || {};
    return {
        combo,
        productList
    };
  };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ addZunpulseCombo, fetchComboDetails, updateCombo }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseAddCombo)


