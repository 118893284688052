import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/loginPageStyles.css';
import { Link } from 'react-router-dom'
export class LoginComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitted: false,
      passHidden: true,
      success: '',
      error: ''
    }
  }

  componentWillReceiveProps(props) {
    console.log("props.error", props.error);
    this.setState({
      error: props.error,
      success: props.success
    })
  }

  handleToggle = (e) => {
    this.setState({
      passHidden: !this.state.passHidden
    })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.username && this.state.password) {
      let user = {
        username: this.state.username.trim(),
        password: this.state.password.trim()
      }
      console.log(user);
      this.props.login(user)
    }

    this.setState({
      submitted: true
    })
  }

  iconType = () => {
    return (
      <i onClick={(e) => { this.handleToggle() }} style={{ padding: "0px", marginTop: "13px", cursor: "pointer" }} class="fa fa-eye icon" aria-hidden="true"></i>
    )
  }
  blindIconType = () => {
    return (
      <i onClick={(e) => { this.handleToggle() }} style={{ padding: "0px", marginTop: "13px", cursor: "pointer" }} class="fa fa-eye-slash icon" aria-hidden="true"></i>
    )
  }

  render() {
    const style = {
      error: {
        fontSize: "0.938vw",
        color: "#e34845",
        marginTop: "-0.781vw"
      },
      success: {
        color: 'green',
        marginTop: '20px'

      }
    }
    return (

      <div className="row no-gutters">
        <div className="col-md-6 right">
          <h1>Login</h1>
          <form onSubmit={this.handleSubmit} name="form" control="">
            <div className="form-group">
              <input type="text" name="username" id="username" value={this.state.username} onChange={this.handleChange} className="form-control" placeholder="User name" />
            </div>
            <div className="form-group" style={{ display: "flex" }}>
              <input type={this.state.passHidden ? "password" : "text"} name="password" id="password" value={this.state.password} onChange={this.handleChange} className="form-control" placeholder="Password" />
              {this.state.password.length > 0 ? (this.state.passHidden ? this.blindIconType() : this.iconType()) : ''}
            </div>
            <Link to="/forgotPassword" ><p className="forget-password">Forget Password?</p></Link>
            <p style={style.error}>{this.state.error}</p>
            <p style={style.success}>{this.state.success}</p>
            <button type="submit" value="Submit" className="loginSubmit">Login</button>
          </form>
          <div className="register">
            <h2>Don’t have an account? </h2>
            <Link to="/register"><p>Register here</p></Link>
          </div>
        </div>

        <div className="col-md-6 left">
          <span className="company__logo">
            <img alt="zrm" src={require('../assets/zrm.png')} />
          </span>
          <h5>Powered by Zunroof</h5>
        </div>
      </div>
      // <div className="container-fluid">
      //   <div className="row main-content bg-success text-center">
      //     <div className="col-md-4 text-center company__info">
      //       <span className="company__logo"><h2><img src="https://zunroof.com/assets/logo.png" width="110px" /></h2></span>
      //       <h4 className="company_title">ZRM</h4>
      //     </div>
      //     <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
      //       <div className="container-fluid">
      //         <div className="row">
      //           <h2 className="loginHead">Log In</h2>
      //           <p style={style.error}>{this.state.error}</p>
      //           <p style={style.success}>{this.state.success}</p>
      //         </div>
      //         <div className="row">
      //           <form onSubmit={this.handleSubmit} name="form" control="" className={"form-group loginForm"}>
      //             <div className={"row" + (this.state.submitted && !this.state.username ? ' has-error' : '')}>
      //               <input type="text" name="username" id="username" className="form__input" value={this.state.username} onChange={this.handleChange} placeholder="Username" />
      //               {this.state.submitted && !this.state.username &&
      //                 <div className="help-block">Username is required</div>
      //               }
      //             </div>
      //             <div className={this.state.password.length>0?"pass__show" :"row"+ (this.state.submitted && !this.state.firstname ? ' has-error' : '')}>
      //               <input type={this.state.passHidden? "password" : "text"} name="password" id="password" value={this.state.password} onChange={this.handleChange} className="form__input" placeholder="Password" />
      //               {this.state.password.length>0?(this.state.passHidden? this.blindIconType() : this.iconType()):''}
      //               {this.state.submitted && !this.state.password &&
      //                 <div className="help-block">Password is required</div>
      //               }
      //             </div>
      //             <div className="row">
      //               <input type="submit" value="Submit" className="btn loginSubmit" />
      //             </div>
      //           </form>
      //         </div>
      //         <div className="row">
      //           <div className="col-md-7">
      //             <p>Don't have an account? <a href="/register">Register Here</a></p>
      //           </div>
      //           <div className="col-md-5" >
      //             <a href="/forgotPassword">Forgot Password?</a>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    )
  }
}
