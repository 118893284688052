import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { orderValueConfigZunpulse } from '../../config/column_fields';
import { salesSummaryData, orderValueData, shippingSummaryData, lastTenDaysSummaryData } from '../../helper/zunpulseDashboardHelper';
import { fetchOrdersMapping } from '../../actions/zunpulseOrderTrackingActions.js';
import { fetchZunpulseDashboard } from '../../actions/zunpulseOpsDashboardActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Loader } from '../../components/loader'
import { SalesSummary, ShippingSummary, OrderValueView, LastTenDaysSummary } from '../../components/zunpulseComponents/ZunpulseOpsDashboardComponent';

class ZunpulseOpsDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            salesSummaryFilters: {
                date: {
                    startDate: '',
                    end: '',
                },
                state: [],
                product: [],
                status: []
            },
            shippingSummaryFilters: {
                date: {
                    startDate: '',
                    end: '',
                },
                state: [],
                product: [],
                source: []
            },
            orderValueFilters: {
                date: {
                    startDate: '',
                    end: '',
                },
                state: [],
                product: [],
                status: [],
                source: []
            },
            lastTenDaysSummaryFilters: {
                state: [],
                product: [],
                status: []
            },
            loading: true,
            received: false,
            mapping: {}
        };
        this.statusMap = ["Not Procured", "Procured", "Shipped", "Delivered", "Cancelled", "Returning (Before Delivery)", "Returning (After Delivery)", "Returned", "Replacement"];
        this.deviceMap = [
            { label: "Smart Light", value: "D10000" },
            { label: "Smart Downlight", value: "D10020" },
            { label: "Smart Energy Monitor", value: "D2" },
            { label: "Smart Plug", value: "D20000" },
            { label: "AC Remote", value: "D30000" },
            { label: "TV Remote", value: "D31000" },
            { label: "Smart Security", value: "D40000" },
            { label: "Smart Camera", value: "D50000" },
            { label: "Smart Doorbell", value: "51000" }
        ]
        this.sourceMap = ["web", "app", "manual", "Flipkart", "Snapdeal", "Amazon", "Cred","City sales", "B2B"];
        this.salesSummaryColumns = [
            {
                Header: row => {
                    let { data } = row;
                    return `Months (${data.length})`;
                },
                accessor: "month"
            },
            ...(this.sourceMap.map(source => {
                return {
                    Header: row => {
                        let { data } = row;
                        let total = data.reduce((a, b) => a + (!isNaN(b[source]) ? parseInt(b[source]) : 0), 0);
                        return `${source} (${total.toLocaleString("en-IN")})`;
                    },
                    accessor: source,
                    Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
                }
            })),
            {
                Header: row => {
                    let { data } = row;
                    let total = data.reduce((a, b) => a + (!isNaN(b.total) ? parseInt(b.total) : 0), 0);
                    return `Total (${total.toLocaleString("en-IN")})`;
                },
                accessor: "total",
                Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
            }
        ];
        this.shippingSummaryColumns = [
            {
                Header: row => {
                    let { data } = row;
                    return `Months (${data.length})`;
                },
                accessor: "month"
            },
            ...(this.statusMap.map((status, index) => {
                return {
                    Header: row => {
                        let { data } = row;
                        let total = data.reduce((a, b) => a + (!isNaN(b[index]) ? parseInt(b[index]) : 0), 0);
                        return `${status} (${total.toLocaleString("en-IN")})`;
                    },
                    accessor: index + "",
                    Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
                }
            })),
            {
                Header: row => {
                    let { data } = row;
                    let total = data.reduce((a, b) => a + (!isNaN(b.total) ? parseInt(b.total) : 0), 0);
                    return `Total (${total.toLocaleString("en-IN")})`;
                },
                accessor: "total",
                Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
            }
        ];
        this.lastTenDaysSummaryColumns = [
            {
                Header: row => {
                    let { data } = row;
                    return `Date (${data.length})`;
                },
                accessor: "orderDate",
                Cell: row => row.value ? moment(+row.value).format("DD MMM, YYYY") : ""
            },
            ...(this.sourceMap.map(source => {
                return {
                    Header: row => {
                        let { data } = row;
                        let total = data.reduce((a, b) => a + (!isNaN(b[source]) ? parseInt(b[source]) : 0), 0);
                        return `${source} (${total.toLocaleString("en-IN")})`;
                    },
                    accessor: source,
                    Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
                }
            })),
            {
                Header: row => {
                    let { data } = row;
                    let total = data.reduce((a, b) => a + (!isNaN(b.total) ? parseInt(b.total) : 0), 0);
                    return `Total (${total.toLocaleString("en-IN")})`;
                },
                accessor: "total",
                Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
            }
        ];
    }

    componentWillMount() {
        this.props.fetchZunpulseDashboard();
        this.props.fetchOrdersMapping();
    }

    componentWillReceiveProps(props) {
        let { mapping = {}, salesSummary, shippingSummary, orderValue, lastTenDaysSummary, received, lastEdited } = props;
        this.setState({
            loading: false,
            received: true,
            mapping,
            salesSummary,
            shippingSummary,
            orderValue,
            lastTenDaysSummary,
            received,
            lastEdited
        })
    }

    handleFilterChange = (type, name, value) => {
        let appliedFilters = (value || []).map(option => option.value);
        this.setState({
            [type]: {
                ...this.state[type],
                [name]: appliedFilters
            }
        })
    }

    handleDateFilterChange = (type, dateType, e) => {
        let { name, value } = e.target;
        let filters = JSON.parse(JSON.stringify(this.state[type]));
        filters[dateType][name] = value;
        this.setState({
            [type]: filters
        })
    }

    applyFilter = (type) => {
        let filters = this.state[type] || {};
        let { ordersData = [] } = this.props;
        let data = [];
        // console.log("filters", filters)
        switch (type) {
            case "salesSummaryFilters":
                data = salesSummaryData(ordersData, filters);
                this.setState({ salesSummary: data })
                break;
            case "shippingSummaryFilters":
                data = shippingSummaryData(ordersData, filters);
                this.setState({ shippingSummary: data })
                break;
            case "orderValueFilters":
                data = orderValueData(ordersData, filters);
                this.setState({ orderValue: data })
                break;
            case "lastTenDaysSummaryFilters":
                data = lastTenDaysSummaryData(ordersData, filters);
                this.setState({ lastTenDaysSummary: data })
                break;
            default:
                break;
        }
    }

    render() {
        let { mapping = {}, salesSummary, shippingSummary, orderValue, lastTenDaysSummary, received, subCategoryOptions, lastEdited } = this.state;
        let { handleFilterChange, handleDateFilterChange, applyFilter, salesSummaryColumns, shippingSummaryColumns, lastTenDaysSummaryColumns, statusMap, sourceMap, deviceMap } = this;
        let sourceOptions = (sourceMap || []).map(option => ({ label: option, value: option }))
        let statusOptions = (statusMap || []).map((status, index) => ({ label: status, value: index }));
        let stateOptions = Object.keys(mapping.stateCityMapping || {}).map(state => ({ label: state, value: state }))
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <b>Last Edited - {moment(lastEdited).format('MMMM Do YYYY, h:mm:ss a')}</b>
                    <SalesSummary
                        data={salesSummary}
                        columns={salesSummaryColumns}
                        loading={!received}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        productOptions={deviceMap}
                        shippingStatusOptions={statusOptions}
                        type={"salesSummaryFilters"}
                        applyFilter={applyFilter}
                    />
                    <ShippingSummary
                        data={shippingSummary}
                        columns={shippingSummaryColumns}
                        loading={!received}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        productOptions={deviceMap}
                        sourceOptions={sourceOptions}
                        type={"shippingSummaryFilters"}
                        applyFilter={applyFilter}
                    />
                    <OrderValueView
                        data={orderValue}
                        columns={orderValueConfigZunpulse}
                        loading={!received}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        productOptions={deviceMap}
                        sourceOptions={sourceOptions}
                        shippingStatusOptions={statusOptions}
                        type={"orderValueFilters"}
                        applyFilter={applyFilter}
                    />
                    <LastTenDaysSummary
                        data={lastTenDaysSummary}
                        columns={lastTenDaysSummaryColumns}
                        loading={!received}
                        handleSelectChange={handleFilterChange}
                        handleDateChange={handleDateFilterChange}
                        stateOptions={stateOptions}
                        productOptions={deviceMap}
                        shippingStatusOptions={statusOptions}
                        type={"lastTenDaysSummaryFilters"}
                        applyFilter={applyFilter}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}

function mapStateToProps({ zunPulseOpsDashboard, zunpulseOrders }) {
    let { ordersData = [], salesSummary = [], shippingSummary = [], orderValue = [], lastTenDaysSummary = [], received = false, lastEdited } = zunPulseOpsDashboard;
    let { mapping } = zunpulseOrders;
    return {
        salesSummary,
        shippingSummary,
        orderValue,
        lastTenDaysSummary,
        received,
        mapping,
        ordersData,
        lastEdited
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchZunpulseDashboard, fetchOrdersMapping, getSearchResult }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseOpsDashboard);