import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import {resetPassword} from '../actions/forgotPasswordAction';
import Header from '../components/HeaderComponent';
import { connect } from 'react-redux';
import { ResetPasswordComponent } from '../components/ResetPasswordComponent'

class ResetPassword extends Component {
  componentWillMount(){
    console.log(this.props.match.params.token)
    this.state = {
      token : this.props.match.params.token
    }
  }

  render() {
    console.log(this.state.token)
    return (
      <div>
        <ResetPasswordComponent error={this.props.error} success={this.props.success} token={this.state.token} resetPassword={this.props.resetPassword}/>
      </div>
    )
  }
}
function mapStateToProps(state) {
  
  return {
    success : state.resetPassword.success,
    error : state.resetPassword.error
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({resetPassword}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);