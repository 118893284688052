import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { comboColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchMapping, addInventory } from '../../actions/zunsolarOrderTrackerActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader';
import styles from '../../styles/inventory.module.css';

class ZunsolarAddInventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            received: false,
            mapping: {},
            subCategoryOptions: [],
            formData: {}
        };
    }

    componentDidMount() {
        this.setState({
        })
    }

    handleInputChange = (e) => {
        console.log(e);
        let { name, value } = e.target;
        if (name == 'category' && value) {
            let { mapping = {} } = this.state;
            let { category = {} } = mapping;
            let subCategoryOptions = category[value];
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value
                },
                subCategoryOptions
            })
        }
        else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value
                }
            })
        }
    }

    componentWillMount() {
        this.props.fetchMapping();
    }

    componentWillReceiveProps(props) {
        let { mapping } = props;
        this.setState({
            loading: false,
            received: true,
            mapping: mapping,
            formData: {
                accountBookedStatus: "No"
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { formData } = this.state;
        console.log(formData)
        this.props.addInventory(formData);
        console.log(formData);
    }

    handleFileUpload = (e) => {
        let { name, files } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: files[0]
            }
        })
    }

    inputType = (name, value, type) => {

        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    render() {
        let { mapping, subCategoryOptions } = this.state;
        return (
            <div className="analyseAc">
                <Header />
                <div className={styles.mainDiv}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className={styles.headingDiv}>
                            <div className={styles.left}>
                                <h4 style={{ fontWeight: "bold" }}>Add Inventory</h4>
                            </div>
                            <div className={styles.inventorySubmitBtn}>
                                <button className="btn btn-success" type="submit">Submit</button>
                            </div>
                        </div>
                        <hr className="analyseAcHr"></hr>
                        <div className={styles.inventoryFormDiv}>
                            <div>
                                <label>
                                    Payment Centre
                                </label>
                                <select className="form-control" name="paymentCentre" onChange={(e) => this.handleInputChange(e)}>
                                    <option key="-1" value="">Select Payment Centre</option>
                                    {
                                        (mapping.paymentCentre || []).map((centre, i) => {
                                            return (
                                                <option key={i} value={centre}>{centre}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>
                                    Cost Centre
                                </label>
                                <select className="form-control" name="costCentre" onChange={(e) => this.handleInputChange(e)}>
                                    <option key="-1" value="">Select Cost Centre</option>
                                    {
                                        (mapping.costCentre || []).map((centre, i) => {
                                            return (
                                                <option key={i} value={centre}>{centre}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>
                                    Sub Cost Centre
                                </label>
                                <select className="form-control" name="subCostCentre" onChange={(e) => this.handleInputChange(e)}>
                                    <option key="-1" value="">Select Sub-Cost Centre</option>
                                    {
                                        (mapping.subCostCentre || []).map((centre, i) => {
                                            return (
                                                <option key={i} value={centre}>{centre}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>
                                    Category
                            </label>
                                <select className="form-control" name="category" onChange={(e) => this.handleInputChange(e)} required>
                                    <option key="-1" value="">Select Category</option>
                                    {
                                        Object.keys(mapping.category || {}).map((category, i) => {
                                            return (
                                                <option key={i} value={category}>{category}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className={styles.categoryDiv}>
                                <label>
                                    Sub Category
                                </label>
                                <select type="select" className="form-control" name="subCategory" onChange={(e) => this.handleInputChange(e)} required>
                                    <option key="-1" value="">Select Sub Category</option>
                                    {
                                        subCategoryOptions.map((subCategory, i) => {
                                            return (
                                                <option key={i} value={subCategory}>{subCategory}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>
                                    Invoice Date
                            </label>
                                <input type="date" className="form-control" name="preferenceDate" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <div>
                                <label>
                                    Invoice Value
                            </label>
                                <input type="number" className="form-control" name="invoiceValue" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <div>
                                <label>
                                    PO/PI No
                            </label>
                                <input type="text" className="form-control" name="supplyOrderId" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <div>
                                <label>
                                    PO/PI Date
                            </label>
                                <input type="date" className="form-control" name="date" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <div>
                                <label>
                                    PO/PI Value
                            </label>
                                <input type="text" className="form-control" name="poValue" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <div>
                                <label>
                                    Order Qty
                            </label>
                                <input type="number" className="form-control" name="orderQty" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>

                            <div>
                                <label>
                                    Unit Price
                            </label>
                                <input type="number" className="form-control" name="unitPrice" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <div>
                                <label>
                                    GST
                            </label>
                                <input type="number" step="0.01" className="form-control" name="gst" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <div>
                                <label>
                                    Freight
                            </label>
                                <input type="number" className="form-control" name="freight" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>

                            <div>
                                <label>
                                    Freight Tax
                            </label>
                                <input type="number" className="form-control" name="freightTax" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <div>
                                <label>
                                    Expense Month
                            </label>
                                <input type="month" className="form-control" name="expenseMonth" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <div>
                                <label>
                                    Invoice Month
                            </label>
                                <input type="month" className="form-control" name="invoiceMonth" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <div>
                                <label>
                                    Booking Month
                            </label>
                                <input type="month" className="form-control" name="bookingMonth" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <div>
                                <label>
                                    Account Booked Status
                                </label>
                                <select value={this.state.formData.accountBookedStatus} className="form-control" name="accountBookedStatus" onChange={(e) => this.handleInputChange(e)}>
                                    <option key="-1" value="">Select Status</option>
                                    {
                                        (mapping.accountBookingStatus || []).map((status, i) => {
                                            return (
                                                <option value={status}>{status}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label>
                                    Invoice No
                            </label>
                                <input type="text" className="form-control" name="invoiceNo" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <div>
                                <label>
                                    UOM
                            </label>
                                <input type="text" className="form-control" name="uom" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            {/* <label>
                                Packaging
                            </label>
                            <div>
                                <select className="form-control" name="packaging" onChange={(e) => this.handleInputChange(e)} required>
                                    <option key="-1" value="">Select</option>
                                    {
                                        (mapping.packaging || []).map((packaging, i) => {
                                            return (
                                                <option key={i} value={packaging}>{packaging}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <label>
                                Transportation Charge
                            </label>
                            <div>
                                <input type="number" className="form-control" name="transportationCharge" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div> */}

                            <div>
                                <label>
                                    Proforma Invoice
                            </label>
                                <input type="file" className="form-control" name="purchaseInvoice" onChange={(e) => this.handleFileUpload(e)}></input>
                            </div>
                            <div>
                                <label>
                                    Transport Challan
                            </label>
                                <input type="file" className="form-control" name="transportChallan" onChange={(e) => this.handleFileUpload(e)}></input>
                            </div>
                            <div>
                                <label>
                                    Purchase Order
                            </label>
                                <input type="file" className="form-control" name="purchaseOrder" onChange={(e) => this.handleFileUpload(e)}></input>
                            </div>
                            <div>
                                <label>
                                    GST Invoice
                            </label>
                                <input type="file" className="form-control" name="gstInvoice" onChange={(e) => this.handleFileUpload(e)}></input>
                            </div>
                            <div>
                                <label>
                                    MIS
                            </label>
                                <input type="file" className="form-control" name="mis" onChange={(e) => this.handleFileUpload(e)}></input>
                            </div>
                        </div>
                        <br></br>
                    </form>
                </div>
            </div >
        )
    }
}

function mapStateToProps({ zunSolarOrderTracker }) {
    let { mapping = [] } = zunSolarOrderTracker || {};
    return {
        mapping
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMapping, getSearchResult, addInventory }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarAddInventory);