import { FETCH_CURRENT_CALLS_COUNTS, FETCH_CURRENT_CALLS_DATA, FETCH_IVR_TICKETING_DATA, FETCH_IVR_TICKET_COUNT, FETCH_IVR_TICKET_HISTORY, FETCH_ORDER_ID_MAPPING, FETCH_OUTBOUNDS_CALLS_DATA, FETCH_PREVIOUS_TICKET_DATA, FETCH_RO_CLIENT_ID_MAPPING, FETCH_SINGLE_TICKET_DATA, FETCH_TICKETS_FOR_ORDER_ID, FETCH_TICKET_ALL_COMMENTS, FETCH_TICKET_RESONSIBLE_PERSON } from "../actions/actiontypes";


export default function(state= {}, action){
    switch (action.type){
        case FETCH_IVR_TICKETING_DATA:{
            return {...state, allTickets: [...action.payload],ticketsDataReceived: true }
        }
        case FETCH_ORDER_ID_MAPPING:{
            return {...state, orderIdMapping: action.payload}
        }
        case FETCH_PREVIOUS_TICKET_DATA:{
            return {...state,previousTicketData: [...action.payload],ticketsDataReceived: true}
        }
        case FETCH_SINGLE_TICKET_DATA:{
            return {...state, singleTicketDetails: action.payload}
        }
        case FETCH_TICKET_RESONSIBLE_PERSON:{
            return {...state, responsibleMapping: action.payload};
        }
        case FETCH_TICKET_ALL_COMMENTS:{
            return {...state, ticketComments:action.payload}
        }
        case FETCH_TICKETS_FOR_ORDER_ID:{
            return {...state, ticketDataForOrderId: action.payload}
        }
        case FETCH_CURRENT_CALLS_DATA:{
            return {...state, currentCallsData: action.payload}
        }
        case FETCH_OUTBOUNDS_CALLS_DATA:{
            return {...state, outboundCallsData: action.payload}
        }
        case FETCH_CURRENT_CALLS_COUNTS:{
            return {...state, currentCallsCounts: action.payload}
        }
        case FETCH_RO_CLIENT_ID_MAPPING:{
            return {...state, clientIdMapping: action.payload}
        }
        case FETCH_IVR_TICKET_COUNT:{
            return {...state, ticketCount: action.payload }
        }
        case FETCH_IVR_TICKET_HISTORY:{
            return {...state, ticketHistory: action.payload }
        }
        default:
            return state;
    }
}