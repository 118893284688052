import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_BOOKED_ORDERIDS_FROM_ZUNSOLAR_ORDERS, FETCH_SUB_ORDER_DATA_FROM_ORDER_ID, FETCH_ALL_INVOICE_ORDERS, FETCH_ALL_INVOICE_LEDGER, FETCH_ZUNSOLAR_PI_ALL_INVOICES, FETCH_ZUNSOLAR_PI_LEDGER, FETCH_UNICOMMERCE_MAPPING } from './actiontypes';

export function fetchBookedOrderIdsFromZunsolarOrders() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/invoicePiPortal/getallBookedOrderIds`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_BOOKED_ORDERIDS_FROM_ZUNSOLAR_ORDERS, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function getAllPIInvoiceOrders(conditions = undefined) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/getAllPIInvoiceOrders`,{conditions});
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_ALL_INVOICE_ORDERS, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function getAllPIInvoiceLedger(conditions = undefined) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/getAllPIInvoiceLedger`,{conditions});
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_ALL_INVOICE_LEDGER, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSubOrdersFromOrderId(orderId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/getsubOrderDataByorderId`,{orderId});
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_SUB_ORDER_DATA_FROM_ORDER_ID, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function requestInvoice(formData) {
    return async () => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/requestInvoice`,formData);
            response = response.data;
            if (response.error) {
                alert(response.message || "Some Error Ocurred");
                return;
            }
            swal('Invoice Created')
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updatePIInvoice(index, modifiedData, allInvoices,newData) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/updatePIInvoiceOrder`,modifiedData);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            allInvoices[index] = newData
            dispatch({ type: FETCH_ALL_INVOICE_ORDERS, payload: [...allInvoices] || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function downloadFile(filename) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/file/download`, { filename });
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let fileData = response.data;
            let { Body = {}, ContentType } = fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
        catch(e) {
            console.log("Some error occured", e);
            return;
        }
    }
}

export function downloadFileByInvoiceNumber(invoiceNumber, invoiceFileName){
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/file/downloadByInvoiceNumber`, { invoiceNumber, invoiceFileName });
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let fileData = response.data;
            let { Body = {}, ContentType } = fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${invoiceNumber}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
        catch(e) {
            console.log("Some error occured", e);
            return;
        }
    }
}

export function invoiceBulkUpload(formData){
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/invoiceBulkUpload`, formData);
            console.log("response", response);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            alert("uploaded Successfully");
        }
        catch (e) {
            console.log(e);
            alert("Some Error Ocurred");
        }
    }
}

export function getAllBulkPIInvoice(conditions = undefined) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/getAllPiInvoices`,{conditions});
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_ZUNSOLAR_PI_ALL_INVOICES, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function getAllBulkPILedger(conditions = undefined) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/getAllPILedger`,{conditions});
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_ZUNSOLAR_PI_LEDGER, payload: response.data || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function getUnicommerceMapping() {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/invoicePiPortal/getUnicommerceMapping`);
            response = response.data;
            if(response.status=="success"){
                dispatch({ type: FETCH_UNICOMMERCE_MAPPING, payload: response.data || [] });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}