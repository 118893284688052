import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import HeaderComponent from '../components/HeaderComponent';
import { connect } from 'react-redux';
import { LoginComponent } from '../components/LoginComponent.js';
import { login } from '../actions/login';
import { Loader } from '../components/loader.jsx';
import {isMobile} from 'react-device-detect';
import { LoginComponentMobile } from '../components/zunsolar/LoginComponentMobile';
class Login extends Component {

  render() {
    return (
      <div>
        {isMobile ?
         <LoginComponentMobile login={this.props.login} success={this.props.success} error={this.props.error}/>:
         <LoginComponent login={this.props.login} success={this.props.success} error={this.props.error} />}
      </div>
    )
  }
}


function mapStateToProps(state) {
  // console.log(state.login)
  return {

    success: state.login.success,
    error: state.login.error
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ login }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);