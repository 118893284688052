import { FETCH_PROJECTSALES_MAPPING_SUCCESS, FETCH_PROJECTSALES_LEADS_SUCCESS, FETCH_PROJECTSALES_LEADDATA_SUCCESS, FETCH_PROJECTSALES_LEAD_COMMENTS, FETCH_PROJECTSALES_LEAD_HISTORY } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_PROJECTSALES_MAPPING_SUCCESS: {
            return { ...state, mapping: action.payload };
        }
        case FETCH_PROJECTSALES_LEADS_SUCCESS: {
            return { ...state, leads: action.payload, leadDataReceived: true };
        }
        case FETCH_PROJECTSALES_LEADDATA_SUCCESS: {
            return { ...state, leadData: action.payload, leadDataReceived: true };
        }
        case FETCH_PROJECTSALES_LEAD_COMMENTS: {
            return { ...state, comments: action.payload };
        }
        case FETCH_PROJECTSALES_LEAD_HISTORY: {
            return { ...state, history: action.payload };
        }
        default:
            return state;
    }
}