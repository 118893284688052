import React from 'react';
import { fetchMappingZunpulse, fetchAllMapping } from '../../actions/fetchAllMapping';
import { getSearchResult } from '../../actions/searchAction';
import { createLead } from '../../actions/createLeadAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import ZunpulseCreateLeadComponent from '../../components/zunpulseComponents/ZunpulseCreateLeadComponent';


class ZunPulseCreateLead extends Component {
  componentWillMount() {
    let viewList = ["employees"];
    this.props.fetchAllMapping(viewList);
    this.props.fetchMappingZunpulse();
  }

  render() {
    return (
      <div>
        <Header getSearchResult={this.props.getSearchResult} />
        {/* <CreateLeadComponent allMapping={this.props.allMapping} newLead={this.props.createLead} success={this.props.createLeadSuccess}/> */}
        <ZunpulseCreateLeadComponent allMapping={this.props.allMapping} success={this.props.success} newLead={this.props.createLead} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log("state.createlead", state.createLead)
  return {
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    createLeadSuccess: state.createLead ? state.createLead.success : {}
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchMappingZunpulse, fetchAllMapping, getSearchResult, createLead }, dispatch)
}

// function isLeadData() {
//   return (
//     <p>this is not found</p>
//   )
// }

export default connect(mapStateToProps, mapDispatchToProps)(ZunPulseCreateLead)