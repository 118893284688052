import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/createLead.css';
import swal from 'sweetalert';
import moment from 'moment';

const meetingTypeOptions = [ 'Scheduled meeting', 'Cold meeting' ];

export default class ZunsolarCreateLeadView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leadData: {},
      mappingData: {},
      subType: [],
      isOtherFieldsVisible: false,
      isDistributorIDEnable:false,


    }
  }

  componentWillReceiveProps(props) {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let username = userObj.username;
    console.log("12", props.allMapping.employees)
    let defaultResponsible = props.allMapping && props.allMapping.employees ? props.allMapping.employees.find((user) => user.e_email == username) : "";
    console.log("12", defaultResponsible)

    this.setState({
      mappingData: (props.allMapping && props.allMapping.mapping) ? props.allMapping.mapping : {},
      leadData: {
        source: "Referral",
        stage: "New",
        active: "Yes",
        responsible_person: defaultResponsible ? defaultResponsible.e_name : "Prateek Suraj",
        has_gst: "No",
        onboardedBy:username,
        partner_onboarding_date : moment().format("YYYY-MM-DD")
        
      },
      stateCityMap: props.allMapping ? props.allMapping.stateCityMap : {},
      employeesArr: props.allMapping && props.allMapping.employees ? props.allMapping.employees : [],
    })
  }
  handleToggleOtherFieldsVisible = ()=>{
    this.setState({
      isOtherFieldsVisible: !this.state.isOtherFieldsVisible
    })
  }
  handleInputChange = (e) => {
    let { name, value } = e.target;
    value = this.checkSelectString(value);
    let distributorIdCheck = ["Retailer","C&F"];
    let isDistributorIDEnable = false;
    if (name == "leadType") {
      if(distributorIdCheck.includes(value)){
        isDistributorIDEnable = true
      }
      let subType = this.state.mappingData.type[value]
      this.setState({
        subType: subType,
        isDistributorIDEnable,
      })
    }
    this.setState({
      leadData: {
        ...this.state.leadData,
        [name]: value,
      }
    })
  }


  inputType = (name, value, type, disabled=false) => {
    return <input type={type} className="form-control" name={name} value={value} disabled = {disabled} onChange={(e) => this.handleInputChange(e)} />
  }

  selectType = (name, value, options) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }

  removingNullKeys = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  onNewLeadDataSubmit = (e) => {
    let newData = { ...this.state.leadData };
    newData = this.removingNullKeys(newData);
    if (newData.phoneNumber && /^\d{10}$/.test(newData.phoneNumber)) {
      {
        this.props.newLead(newData, 'zunsolar')
        this.setState({
          leadData: {}
        })
      }
    } else {
      swal("Required Fields Missing!", "FIll Atleast Phone (10 digits)")
    }
  }

  render() {
    console.log(this.state.mappingData.type ? Object.values(this.state.mappingData.type) : [])
    return (
      <div className="container-fluid" style={{ marginTop: "60px" }}>
        <div className="topbar-section" >
          <div className="row">
            <div className="col-md-10 col-xs-12 topbar-left">
              <div className="col-md-12">
                <strong>Create New Lead</strong>
              </div>
            </div>
            <div className="col-md-2 col-xs-12 topbar-right">
              <div className="col-md-12 col-xs-12">
                <button className="button-save" onClick={(e) => this.onNewLeadDataSubmit(e)}>Save</button>

              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "35px", marginBottom: "8px" }}>
          <div className="col-md-1" style={{ paddingLeft: '35px' }}>
            <h5><b>Stage :</b></h5>
          </div>
          <div className="col-md-3">
            <span className="input-group" style={{ width: "95%", paddingLeft: "78px" }}>
              {this.selectType("stage", this.state.leadData.stage, this.state.mappingData.stage)}
            </span>
          </div>
          <div className="col-md-1">
            <h5 ><b>Active :</b></h5>
          </div>
          <div className="col-md-3">
            <span className="input-group" style={{ width: "95%", paddingLeft: "78px" }}>
              {this.selectType("active", this.state.leadData.active, this.state.mappingData.active)}
            </span>
          </div>
          <div className="col-md-1">
            <h5 ><b>High Value :</b></h5>
          </div>
          <div className="col-md-3">
            <span className="input-group" style={{ width: "95%", paddingLeft: "78px" }}>
              {this.selectType("highValue", this.state.leadData.highValue, ['Yes', 'No'])}
            </span>
          </div>

        </div>
        <div className="row">
          <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
            <table>

              <tr>
                <td>Lead Name</td>
                <td>
                  <span className="input-group">
                    {this.inputType("name", this.state.leadData.name, "text")}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Phone</td>
                <td>
                  <span className="input-group">
                    {this.inputType("phoneNumber", this.state.leadData.phoneNumber, "number")}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Alternate Phone</td>
                <td>
                  <span className="input-group">
                    {this.inputType("alternate_number", this.state.leadData.alternate_number, "number")}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <span className="input-group">
                    {this.inputType("email", this.state.leadData.email, "email")}
                  </span>
                </td>
              </tr>
              <tr>
                  <td>GST Number</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("gst_number", this.state.leadData.gst_number, "text")}
                    </span>
                  </td>
                </tr>


              {/* <tr>
                <td>Aadhar Number</td>
                <td>
                  <span className="input-group">
                    {this.inputType("aadharNumber", this.state.leadData.aadharNumber, "aadharNumber")}
                  </span>
                </td>
              </tr> */}

              {/* <tr>
                <td>Date of Birth</td>
                <td>
                  <span className="input-group">
                    {this.inputType("dateOfBirth", this.state.leadData.dateOfBirth, "date")}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Date of Anniversary</td>
                <td>
                  <span className="input-group">
                    {this.inputType("dateOfAnniversary", this.state.leadData.dateOfAnniversary, "date")}
                  </span>
                </td>
              </tr> */}

              <tr>
                <td>Address</td>
                <td>
                  <span className="input-group">
                    {this.inputType("address", this.state.leadData.address, "text")}
                  </span>
                </td>
              </tr>

              <tr>
                <td>ZipCode</td>
                <td>
                  <span className="input-group">
                    {this.inputType("pincode", this.state.leadData.pincode, "text")}
                  </span>
                </td>
              </tr>

              <tr>
                  <td>State</td>
                  <td>
                    <span className="input-group">
                      {/* {this.selectType("ADDRESS_CITY", this.state.leadData.ADDRESS_CITY ? this.state.leadData.ADDRESS_CITY : '', this.state.cities)} */}
                      {this.selectType("state", this.state.leadData.state, this.state.stateCityMap ? Object.keys(this.state.stateCityMap) : [])}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>City</td>
                  <td>
                    <span className="input-group">
                      {/* {this.selectType("ADDRESS_CITY", this.state.leadData.ADDRESS_CITY ? this.state.leadData.ADDRESS_CITY : '', this.state.cities)} */}
                      {this.selectType("city", this.state.leadData.city, this.state.leadData.state ? ((this.state.stateCityMap || {})[this.state.leadData.state] || []) : [])}
                    </span>
                  </td>
                </tr>
              <tr>
                <td>Latitude</td>
                <td>
                  <span className="input-group">
                    {this.inputType("latitude", this.state.leadData.latitude, "number")}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Longitude</td>
                <td>
                  <span className="input-group">
                    {this.inputType("longitude", this.state.leadData.longitude, "number")}
                  </span>
                </td>
              </tr>

            </table>
          </div>

          <div className="col-md-8 table-wrapper">
            <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
              <table className="customer-info-left">

               

                <tr>
                  <td>Lead Type</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("leadType", this.state.leadData.leadType, this.state.mappingData.type ? Object.keys(this.state.mappingData.type) : [])}
                    </span>

                  </td>
                </tr>
                <tr>
                  <td>Lead Sub Type</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("leadSubType", this.state.leadData.leadSubType, this.state.subType)}
                    </span>

                  </td>
                </tr>

                <tr>
                  <td>Partner Onboarding Date</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("partner_onboarding_date", this.state.leadData.partner_onboarding_date, "date",true)}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Onboarded By</td>
                  <td>
                    <span className="input-group">
                      {/* {this.selectNewType("onboardedBy", this.state.employeesArr ? this.state.employeesArr : '', "e_name", this.state.leadData.onboardedBy)} */}
                      {this.inputType("onboardedBy", this.state.leadData.onboardedBy, "text", true)}

                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Next Action Date</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("nextActionDate", this.state.leadData.nextActionDate, "date")}
                    </span>
                  </td>
                </tr>
            
                <tr>
                  <td>Source</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("source", this.state.leadData.source, this.state.mappingData.source)}
                    </span>
                  </td>
                </tr>

             
              </table>
            </div>
            <div className="col-md-6 customer-info">
              <table className="customer-info-right">
             {this.state.isDistributorIDEnable  && <tr>
                <td>Distributor Id</td>
                <td>
                  <span className="input-group">
                    {this.inputType("distributorId", this.state.leadData.distributorId, "text")}
                  </span>
                </td>
              </tr>}
              
              </table>
            </div>
          </div>
        </div>
        <div className='otherSectionMain' onClick={()=>this.handleToggleOtherFieldsVisible()}>
              <h5  className='otherSection'>Others</h5>
            </div>
          {this.state.isOtherFieldsVisible && <div className="row">
          <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
            <table>

            

              <tr>
                <td>Aadhar Number</td>
                <td>
                  <span className="input-group">
                    {this.inputType("aadharNumber", this.state.leadData.aadharNumber, "aadharNumber")}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Date of Birth</td>
                <td>
                  <span className="input-group">
                    {this.inputType("dateOfBirth", this.state.leadData.dateOfBirth, "date")}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Date of Anniversary</td>
                <td>
                  <span className="input-group">
                    {this.inputType("dateOfAnniversary", this.state.leadData.dateOfAnniversary, "date")}
                  </span>
                </td>
              </tr>

              
            </table>
          </div>

          <div className="col-md-8 table-wrapper">
            <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
              <table className="customer-info-left">

              

                <tr>
                  <td>Responsible Person</td>
                  <td>
                    <span className="input-group">
                      {this.selectNewType("responsible_person", this.state.employeesArr ? this.state.employeesArr : '', "e_name", this.state.leadData.responsible_person)}
                    </span>
                  </td>
                </tr>

               
                <tr>
                  <td>Language</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("language", this.state.leadData.language, this.state.mappingData.language)}
                    </span>
                  </td>
                </tr>
               

                <tr>
                  <td>Campaign</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("UTM_CAMPAIGN", this.state.leadData.UTM_CAMPAIGN, "text")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Tracking Number</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("trackingNumber", this.state.leadData.trackingNumber, "text")}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>No of Meeting</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("noOfMeeting", this.state.leadData.noOfMeeting, [1,2,3,4,5,6,7,8,9,10])}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Meeting Date</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("meetingDate", this.state.leadData.meetingDate, "date")}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Meeting Type</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("meetingType", this.state.leadData.meetingType, meetingTypeOptions)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Total Billing Potential</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("totalBillingPotential", this.state.leadData.totalBillingPotential, "text")}
                    </span>
                  </td>
                </tr>
              </table>
            </div>

            <div className="col-md-6 customer-info">
              <table className="customer-info-right">

                <tr>
                  <td>Company Name</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("companyName", this.state.leadData.companyname, "text")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Current Business</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("currentBusiness", this.state.leadData.currentBusiness, this.state.mappingData.currentBusiness)}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Has GST Number</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("has_gst", this.state.leadData.has_gst, ['Yes', 'No'])}
                    </span>
                  </td>
                </tr>

                

                <tr>
                  <td>Existing dealership/distributorship</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("existing_dealership", this.state.leadData.existing_dealership, ['Yes', 'No'])}
                    </span>
                  </td>
                </tr>


                <tr>
                  <td>Annual Turnover</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("turnover", this.state.leadData.turnover, this.state.mappingData.turnover)}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Partner Code</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("partner_code", this.state.leadData.partner_code, "text")}
                    </span>
                  </td>
                </tr>

              

                <tr>
                  <td>Reason For Hold</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("reasonForHold", this.state.leadData.reasonForHold, this.state.mappingData.reasonForHold)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Marketing Material Status</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("marketingMaterialStatus", this.state.leadData.marketingMaterialStatus, this.state.mappingData.marketingMaterialStatus)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Shop Owner</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("shopOwner", this.state.leadData.shopOwner, ['Yes','No'])}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Account Details</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("accountDetails", this.state.leadData.accountDetails, "text")}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Meeting Scheduled By</td>
                  <td>
                    <span className="input-group">
                      {this.selectNewType("meetingScheduledBy", this.state.employeesArr ? this.state.employeesArr : '', "e_name", this.state.leadData.meetingScheduledBy)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Meeting Done By</td>
                  <td>
                    <span className="input-group">
                      {this.selectNewType("meetingDoneBy", this.state.employeesArr ? this.state.employeesArr : '', "e_name", this.state.leadData.meetingDoneBy)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Establishment Type</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("establishment_type", this.state.leadData.establishment_type, Object.keys(this.state.mappingData.establishments || {}))}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Closed Order Size</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("closed_order_size", this.state.leadData.closed_order_size, "text")}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}
