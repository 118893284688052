import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css';

export class ErrorComponent extends Component {
  constructor(props){
    super(props)
    this.state={
      errorMessage : 'dkfhjhjghjg'
    }
  }

  componentWillReceiveProps(props){
    console.log("coming from error component",props.error)
  }
  render() {
    
    return (
      <div style={{backgroundColor:"#f5b5b9",margin:"100px auto",width:'40%',borderRadius:'10px',padding:"20px",}}>
        {this.props.error}
      </div>
    )
  }
}
