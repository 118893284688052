import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../../components/HeaderComponent';
import axios from 'axios';
import url from '../../config';
import { Redirect } from 'react-router-dom';

const updateKeyList = ['orderId', 'subOrderId', 'trackingNumber'];

class BulkStatusUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            uploadFile: {},
            loading: false
        }
    }

    componentWillMount() {
        let userObj = JSON.parse(localStorage.getItem("userObj"));
        if (Object.keys(userObj).length > 0) {
            this.setState({
                userObj: userObj
            })
        }
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleInputFileChange = (e) => {
        let { files } = e.target;
        this.setState({
            uploadFile: files[0]
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let { formData, uploadFile } = this.state;
        let apiFormData = new FormData();
        apiFormData.append('uploadFile', uploadFile);
        Object.keys(formData).forEach(key => {
            apiFormData.append(key, formData[key]);
        })
        let response = await axios.post(`${url.pathBackend}zunsolar/orderstatusbulkupdate`, apiFormData);
        console.log('response', response.data);
        response = response.data;
        if (response.error) {
            alert(response.error);
        }
        else {
            alert('Updated');
        }
    }


    render() {

        if(this.state.userObj.role!='Super Admin'){
            return <Redirect to={"/"}/>
        }

        return (
            <div className="analyseAc">
                <Header />
                <div class="container">
                    <h4 style={{ fontWeight: "bold" }}>Bulk Status Update</h4>
                    <hr className="analyseAcHr"></hr>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Update Key *
                            </label>
                            <div class="col-md-8">
                                <select className="form-control" name="updateField" onChange={(e) => this.handleInputChange(e)} required>
                                    <option key="-1" value="">Select</option>
                                    {
                                        (updateKeyList || []).map((updateKey, i) => {
                                            return (
                                                <option key={i} value={updateKey}>{updateKey}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                File *
                            </label>
                            <div class="col-md-8">
                                <input type="file" className="form-control" name="startDate" onChange={(e) => this.handleInputFileChange(e)} accept=".csv" required></input>
                            </div>
                        </div>
                        <br></br>
                        <div className="callCountSubmit">
                            <button className="btn btn-success" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


export default BulkStatusUpdate;