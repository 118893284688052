import axios from "axios"
import url, { zunpureBackend } from '../config'
import { FETCH_CURRENT_CALLS_COUNTS, FETCH_CURRENT_CALLS_DATA, FETCH_IVR_TICKETING_DATA, FETCH_IVR_TICKET_COUNT, FETCH_IVR_TICKET_HISTORY, FETCH_ORDER_ID_MAPPING, FETCH_OUTBOUNDS_CALLS_DATA, FETCH_RO_CLIENT_ID_MAPPING, FETCH_SINGLE_TICKET_DATA, FETCH_TICKETS_FOR_ORDER_ID, FETCH_TICKET_ALL_COMMENTS, FETCH_TICKET_RESONSIBLE_PERSON } from "./actiontypes"


export function fetchAllTickets(page =0 , limit = 1200, oldData= [], filters,searchText){
    try {
        return async(dispatch)=>{
            let apiData = {
                page: page,
                limit: limit,
                filters: filters,
                searchText: searchText
            }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/fetchAllTickets`, apiData)
            apiRes = apiRes.data
            if(apiRes.status="success"){
                let count = apiRes.count || 0;
                apiRes = apiRes.data || [];
                dispatch({type: FETCH_IVR_TICKETING_DATA, payload:  apiRes.concat(oldData) || []})
                dispatch({type:FETCH_IVR_TICKET_COUNT, payload:count})
            }
        }
    } catch (error) {
        alert("something went wrong")
        
    }

}

export function createTicket(data, oldData){
    try {
        return async(dispatch)=>{
        let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/createTicket`, data);
        apiRes = apiRes.data
        if(apiRes.status=="success"){
            let ticketData = apiRes.ticketData;
            // dispatch({type: FETCH_IVR_TICKETING_DATA, payload:  ticketData.concat(oldData) || []})
            alert(`Ticket Addedd Successfully ${ ticketData.ticketId}`);
            // window.location.reload(true)

            return
    
        }
        alert("something went wrong")
    }
    } catch (error) {
        alert("something went wrong")
    
        
    }
        
    }
export function fetchOrderIdMapping(phoneNumber) {
    try {
        return async (dispatch) => {
            let apiData = {
                phoneNumber: phoneNumber
            }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/fetchOrderIdMapping`, apiData);
            apiRes = apiRes.data
            if (apiRes.status == "success") {
                apiRes = apiRes.orderIdMapping
                dispatch({ type: FETCH_ORDER_ID_MAPPING, payload: apiRes || [] })


            }
        }
    } catch (error) {
        alert("something went wrong")
    }
}
export function fetchROClientIDMapping(phoneNumber) {
    try {
        console.log('phoneNumber',phoneNumber);
        return async (dispatch) => {
            let apiData = {
                phoneNumber: phoneNumber,
                key: zunpureBackend.authKey
            }
            let apiRes = await axios.post(`${zunpureBackend.backendUrl}zunpure/api/order/getOrderMapping`, apiData);
            apiRes = apiRes.data
            console.log('apiRes',apiRes);
            if (apiRes.status == "success") {
                apiRes = apiRes.data
                dispatch({ type: FETCH_RO_CLIENT_ID_MAPPING, payload: apiRes || [] })


            }
        }
    } catch (error) {
        alert("something went wrong")
    }
}
export function fetchOrderIdMappingByOrderId(orderId) {
    try {
        return async (dispatch) => {
            let apiData = {
                orderId: orderId
            }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/fetchOrderIdMappingByOrderId`, apiData);
            apiRes = apiRes.data
            if (apiRes.status == "success") {
                apiRes = apiRes.orderIdMapping
                dispatch({ type: FETCH_ORDER_ID_MAPPING, payload: apiRes || {} })


            }
        }
    } catch (error) {
        alert("something went wrong")
    }
}

export function fetchSingleTicket(tickedId) {
    try {
        return async (dispatch) => {
            let apiData = {
                ticketId: tickedId
            }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/fetchSingleTicket`, apiData);
            apiRes = apiRes.data
            if (apiRes.status == "success") {
                apiRes = apiRes.data
                dispatch({ type: FETCH_SINGLE_TICKET_DATA, payload: apiRes || {} })


            }
        }
    } catch (error) {
        alert("something went wrong")


    }

}

export function updateSingleTicketData(tickedId, oldData, newData, user, apiData) {
    try {
        return async (dispatch) => {


            // let apiData = {
            //     ticketId: tickedId,
            //     updatedData: newData,
            //     user: user
            // }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/updateSingleTicket`, apiData);
            apiRes = apiRes.data
            if (apiRes.status == "success") {
                apiRes = apiRes.data
               return  alert("Updated Successfully")
                // dispatch({ type: FETCH_SINGLE_TICKET_DATA, payload: apiRes || {} })


            }
            return  alert("something went wrong")

        }
    } catch (error) {
        alert("something went wrong")


    }

}

export function fetchResponsiblePersonMapping(tickedId, oldData, newData) {
    try {
        return async (dispatch) => {
            let apiData = {
                ticketId: tickedId,
                updatedData: newData
            }
            let apiRes = await axios.get(`${url.pathBackend}zunsolar/ivrTicketing/fetchResponsiblePersonMapping`);
            apiRes = apiRes.data
            if (apiRes.status == "success") {
                apiRes = apiRes.responsibleMapping
                console.log('apiRes updated', apiRes);
                dispatch({ type: FETCH_TICKET_RESONSIBLE_PERSON, payload: apiRes || {} })


            }
        }
    } catch (error) {
        alert("something went wrong")


    }

}


export function addTicketComment(apiData) {
    try {
        return async (dispatch) => {
          
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/addComment`, apiData);
            apiRes = apiRes.data
            if (apiRes.status == "success") {
                // return alert("comment added successfully")
                // dispatch({ type: FETCH_TICKET_RESONSIBLE_PERSON, payload: apiRes || {} })

            }

        }
    } catch (error) {
        alert("something went wrong")


    }

}


export function fetchAllTicketComment(ticketId) {
    try {
        return async (dispatch) => {
            let apiData = {
                ticketId:ticketId
            }
          
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/fetchComments`, apiData);
            apiRes = apiRes.data
            if (apiRes.status == "success") {
                console.log('fetchComment', apiRes);
                let comments = apiRes.comment
                dispatch({ type: FETCH_TICKET_ALL_COMMENTS, payload: comments || [] })

            }

        }
    } catch (error) {
        alert("something went wrong")


    }

}


export function fetchTicketsForOrderId(orderId) {
    try {
        console.log('inside fetchTicketsForOrderId ');
        return async (dispatch) => {


            let apiData = {
                orderId: orderId
            }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/fetchTicketOrderId`, apiData);
            apiRes = apiRes.data
            if (apiRes.status == "success") {
                apiRes = apiRes.data
                dispatch({ type: FETCH_TICKETS_FOR_ORDER_ID, payload: apiRes || {} })


            }

        }
    } catch (error) {
        console.log("error", error)


    }

}


export function fetchCurrentCalls(filters){
    try {
        return async(dispatch)=>{
            let apiData = {
                filters: filters,
                apiKey:"8ALS!Fcm#iWX_IgN"
            }
           
            let apiRes = await axios.post(`${url.pathBackend}ivrCalls/fetchCurrentCalls`,apiData )
            apiRes = apiRes.data
            if(apiRes.status="success"){
                let callCounts = apiRes.callCounts;
                apiRes = apiRes.data || [];
                dispatch({type: FETCH_CURRENT_CALLS_DATA, payload:  apiRes || []})
                dispatch({type: FETCH_CURRENT_CALLS_COUNTS, payload:callCounts||{}})
            }
        }
    } catch (error) {
        alert("something went wrong")
        
    }

}
export function fetchOutboundsCalls(filters){
    try {
        return async(dispatch)=>{
            let apiData = {
                filters: filters,
                apiKey:"8ALS!Fcm#iWX_IgN"
            }
           
            let apiRes = await axios.post(`${url.pathBackend}ivrCalls/fetchOutboundCalls`,apiData)
            apiRes = apiRes.data
            if(apiRes.status="success"){
                apiRes = apiRes.data || [];
                dispatch({type: FETCH_OUTBOUNDS_CALLS_DATA, payload:  apiRes || []})
            }
        }
    } catch (error) {
        alert("something went wrong")
        
    }

}

export function fetchOutboundInsideSales(filters) {
    try {
        return async(dispatch)=>{
            let apiData = {
                filters: filters,
                key:"idsgKRloobBCPeN"
            }
           
            let apiRes = await axios.post(`${url.pathBackend}insideSalesIvr/fetchOutboundCalls`,apiData)
            apiRes = apiRes.data
            console.log(apiRes);
            if(apiRes.status="success"){
                apiRes = apiRes.data || [];
                dispatch({type: FETCH_OUTBOUNDS_CALLS_DATA, payload:  apiRes || []})
            }
        }
    } catch (error) {
        alert("something went wrong")
        
    }
}

export function fetchTicketHistory(ticketId){
    try {
        return async(dispatch)=>{
            let apiData = {
                ticketId: ticketId
            }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/fetchTicketHistory`, apiData)
            apiRes = apiRes.data
            if(apiRes.status="success"){
                apiRes = apiRes.data || [];
                dispatch({type:FETCH_IVR_TICKET_HISTORY, payload:apiRes|| []})
            }
        }
    } catch (error) {
        alert("something went wrong")
        
    }

}

export function createTicketInMaintenance(ticketId, roClientId, responsiblePe = {}, comment = '', purposeOfVisit, maintenanceDate){
    try {
        return async(dispatch)=>{
            let apiData = {
                ticketId: ticketId,
                roClientId: roClientId,
                responsiblePe: responsiblePe,
                comment: comment,
                purposeOfVisit,
                maintenanceDate
            }
            let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/createTicketInMaintenance`, apiData)
            apiRes = apiRes.data
            if(apiRes.status="success"){
                apiRes = apiRes.data || [];
                alert("Ticket created in maintenance");
            }
            else {
                alert("Some error occured");
            }
            return;
        }
    } catch (error) {
        alert("something went wrong")
        
    }

}