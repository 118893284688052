import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/forgotPassword.css'


export class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      existing: false,
      error:'',
      success : ''
    }
  }

  componentWillReceiveProps(props){
    this.setState({
      error : props.error,
      success:props.success
    })
  }

  handleChange = (e) => {
    if (e.target.value) {
      this.setState({
        email: e.target.value
      })
    }

  }
  handleSubmit = (e) => {
    e.preventDefault()
    let obj = {
      username : this.state.email
    }
    this.props.forgotPassword(obj)
  }

  render() {
    const style = {
      error: {
        fontSize: "0.938vw",
        color: "#e34845",
        marginTop: "-0.781vw"
      },
      success: {
        color: 'green'
      }
    }
    return (
      <div className="row no-gutters">
        <div className="col-md-6 rightForgot">
          <h1>Forgot Password?</h1>
          {/* <h3 style={{marginLeft:"100px"}}><i className="fa fa-lock fa-4x"></i></h3> */}
          <form onSubmit={this.handleSubmit} name="form" control="">
            <div className="form-group">
              <input type="text" name="username" id="username" value={this.state.username} onChange={this.handleChange} className="form-control" placeholder="User name" />
            </div>
            <h6 style={{ color: "blue" }}>Get a link to your registered email id</h6>

            <p style={style.error}>{this.state.error}</p>
          <p style={style.success}>{this.state.success}</p>
            <button type="submit" value="Submit" className="loginSubmit">Submit</button>
          </form>
        </div>

        <div className="col-md-6 left">
          <span className="company__logo">
            <img alt="zrm" src={require('../assets/zrm.png')} />
          </span>
          <h5>Powered by Zunroof</h5>
        </div>
      </div>
    )
  }
}
