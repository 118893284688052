import React from 'react';
import axios from 'axios';
import url from '../config';
import { SEARCH_START, SEARCH_SUCCESS } from './actiontypes';
import swal from 'sweetalert';

export function getSearchResult(string, view = "solar") {
  return (dispatch) => {

    dispatch({ type: SEARCH_START, payload: [] })

    // let route = (view == "zunpulse") ? "zunpulse/lead/search" : "lead/search"
    let route;
    switch (view) {
      case 'solar':
        route = "lead/search";
        break;
      case 'zunpulse':
        route = "zunpulse/lead/search";
        break;
      case 'zunsolar':
        route = "zunsolar/lead/search";
        break;
      default:
        route = "lead/search";
        break;
    }
    let searchObj = { query: string }

    axios.post(url.pathBackend + route, searchObj).then((response => {
      console.log("response.data", response.data);
      if (response.data) {
        if ((response.data).length <= 0) {
          swal({
            text: "Oops No Data Found!!",
            button: {
              cancel: "close"
            }
          })
        }
        dispatch({ type: SEARCH_SUCCESS, payload: response.data })
      }

    }))

  }
}

// async function getSearchResult(input){
//     try{
//         let data = await axios.post(url.pathBackend + "lead/search",value)
//     }
// }