import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import moment from 'moment';

class ProcurementSupply extends Component {

    constructor() {
        super();
        this.state = {
            supplyData: [],
            showFilter: false,
            filters: {},
            supplyEditFlag: {},
            // modalOrderView: {
            //     showModal: false
            // },
            modalAddDeliveryView: false,
            deliveryFormData: {},
            siNoDropDown: [],
            paymentsData: []
        };
    }

    componentWillMount() {
        this.props.fetchMappingZunsolar()
        this.props.fetchSupplyDetails()
        this.props.fetchProcurementPayments()
    }

    componentWillReceiveProps(props) {
        let siNoDropDown = ((props.paymentsData || {}).data || []).map((element) => {return {label: element.siNo, value:element.siNo}})
        this.setState({
            supplyData: (props.supplyData || {}).data || [],
            siNoDropDown,
            paymentsData: (props.paymentsData || {}).data || [],
            statusMapping: props.statusMapping || []
        })
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = (e) => {
        let { filters } = this.state
        this.props.fetchSupplyDetails(filters)
    }

    multiSelectType = (name, options, placeholder, onChange, inputParams = {}) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                    {...inputParams}
                />
            </div>
        )
    }

    SelectType = (name, options, placeholder, onChange, inputParams = {}) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                    value={{value: inputParams.selectedValue, label: inputParams.selectedValue}}
                    {...inputParams}
                />
            </div>
        )
    }

    inputType = (name, placeholder, type, onChange, dateType, inputParams = {}) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e, dateType)} {...inputParams}/>
                </div>
            </div>
        )
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        if (name == 'category') {
            let category = this.props.zunsolarCategoryMapping || {}
            let subCategoryOptions = [];
            value.map(val => {
                let options = (category[val] || []).map((subCategory) => { return { label: subCategory, value: subCategory } });
                subCategoryOptions = [...subCategoryOptions, ...(options || [])]
            })
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value,
                },
                subCategoryOptions
            })
        }
        else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value
                }
            })
        }
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[type] = filters[type] || {};
        filters[type][name] = value;
        this.setState({
            filters
        })
    }


    // handleSaveSupplyClick = (row) => {
    //     let { id } = row.original;
    //     let { editedSupplyData } = this.state
    //     let newData = editedSupplyData[id] || {};
    //     this.setState({
    //         supplyEditFlag: {
    //             ...this.state.supplyEditFlag,
    //             [id]: false
    //         }
    //     })
    // }

    // handleCancelSupplyClick = (row) => {
    //     let { id } = row.original;
    //     this.setState({
    //         supplyEditFlag: {
    //             ...this.state.supplyEditFlag,
    //             [id]: false
    //         }
    //     })
    // }

    // handleEditSupplyClick = (row) => {
    //     let { id } = row.original;
    //     this.setState({
    //         supplyEditFlag: {
    //             ...this.state.supplyEditFlag,
    //             [id]: true
    //         },
    //         editedSupplyData: {
    //             ...this.state.editedSupplyData,
    //             [id]: row.original
    //         }
    //     })
    // }

    // handleSupplyChange = (e, row) => {
    //     let { name, value } = e.target;
    //     let { id } = row.original;
    //     let { editedSupplyData } = this.state;
    //     editedSupplyData = JSON.parse(JSON.stringify(editedSupplyData))
    //     editedSupplyData[id][name] = value;
    //     this.setState({
    //         editedSupplyData
    //     })
    // }

    // closeModalOrderView = (e) => {
    //     this.setState({
    //         modalOrderView: {
    //             showModal: false
    //         }
    //     })
    // }

    closeAddDeliveryView = (e) => {
        this.setState({
            modalAddDeliveryView: false
        })
    }

    handleSelectDeliveryChange = (e,name) => {
        let { deliveryFormData, paymentsData } = this.state
        let value = Array.isArray(e) ? e.map(row => row.value) : e.value
        if (!Array.isArray(e)) {
            if (name == 'siNo') {
                let index = paymentsData.findIndex(row => row.siNo == value);
                deliveryFormData['category'] = paymentsData[index]['category']
                deliveryFormData['subcategory'] = paymentsData[index]['subcategory']
                deliveryFormData['orderId'] = paymentsData[index]['orderId']
                deliveryFormData['unitsReceived'] = paymentsData[index]['unitsReceived']
                deliveryFormData['vendorId'] = paymentsData[index]['vendorId']
        }
        }
            this.setState({
                deliveryFormData: {
                    ...deliveryFormData,
                    [name]: value
                }
            })
    }

    handleInputDeliveryChange = (e) => {
        let { name, value } = e.target;
        let { deliveryFormData } = this.state;
        deliveryFormData[name] = value;
        this.setState({
            deliveryFormData
        })
    }

    handleFileInputDeliveryChange = (e) => {
        let { name, files } = e.target;
        this.setState(prevState => ({
            deliveryFormData: {
                ...prevState.deliveryFormData,
                [name]: files[0]
            }
        }))
    }

    handleSubmitDeliveryForm = (e) => {
        e.preventDefault();
        this.props.createDelivery(this.state.deliveryFormData)
        this.setState({
            modalAddDeliveryView: false
        })
    }

    handleShowAddDelivery = () => {
        this.setState({
            modalAddDeliveryView: true
        })
    }

    handleDownloadFileClick = (fileName) => {
        this.props.downloadFile(fileName)
    }

    render() {
        let { state, props, closeAddDeliveryView, handleSubmitDeliveryForm, handleShowAddDelivery, handleDownloadFileClick, handleFileInputDeliveryChange, handleInputDeliveryChange } = this
        let { supplyData, siNoDropDown, deliveryFormData, paymentsData, modalAddDeliveryView } = state;
        let supplyColumn = [
            {
                Header: 'Date',
                accessor: 'createdAt',
                Cell: row => {
                    return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
                }
            },
            {
                Header: 'Vendor ID',
                accessor: 'vendorId'
            },
            {
                Header: 'Vendor',
                accessor: 'vendorName'
            },
            {
                Header: 'Order Id',
                accessor: 'orderId'
            },
            {
                Header: 'Product Category',
                accessor: 'category'
            },
            {
                Header: 'Product Sub Category',
                accessor: 'subcategory'
            },
            {
                Header: 'Recieved',
                accessor: 'itemsReceived'
            },
            {
                Header: 'Pending',
                accessor: 'itemsPending'
            },
            {
                Header: 'QTY',
                accessor: 'noOfUnits'
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
            {
                Header: 'Responsible',
                accessor: 'createdBy'
            },
            {
                Header: 'Proof Of Receipt',
                accessor: 'receiptFile',
                Cell: row => {
                     return row.value ? <button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleDownloadFileClick(row.value)}>Proof Of Receipt</button> : ''
                }
            },
            // {
            //     Header: "Buttons",
            //     Cell: row => {
            //         return (
            //             procurementTransactionEditFlag[row.original.id] ? <div className="row text-center" style={{ width: 'fit-content' }}><button className="btn btn-success btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleSaveProcurementTransactionClick(row)}>Save</button><button className="btn btn-light btn-sm" onClick={(e) => handleCancelProcurementTransactionClick(row)}>Cancel</button></div> : <div className="row text-center" style={{ width: 'fit-content' }}><button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => handleEditProcurementTransactionClick(row)}>Edit</button></div>
            //         )
            //     },
            //     minWidth: 200
            // }
        ]

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        const modalStyles = {
            modal: {
                width: '100%',
                maxWidth: '100%',
                padding: '40px',
                background: '#f7f7f7',
                textAlign: 'center'
            }
        }
        
        return (
            <div>
                {/* <Modal open={state.modalOrderView['showModal']} onClose={closeModalOrderView} styles={modalStyles} center>
                    <div className="procurementAddVendor">
                        <div className='row'>
                            <div className="col-md-3">
                                {this.inputType("endDate", "End Date", 'date', this.handleInputDateChangeFilters, 'paymentDate')}
                            </div>
                        </div>
                    </div>
                </Modal> */}
                <Modal open={modalAddDeliveryView} onClose={(e) => closeAddDeliveryView(e)} styles={modalStyles} center>
                    <div className="procurementAddVendor">
                        <form onSubmit={handleSubmitDeliveryForm}>
                            <div className="row">
                                <div className="col-md-3">
                                    {this.SelectType("siNo", siNoDropDown, "SI No.", this.handleSelectDeliveryChange,{selectedValue:deliveryFormData['siNo']})}
                                </div>
                                <div className="col-md-3">
                                    {this.inputType("category", "Category", 'text', handleInputDeliveryChange, 'category', {disabled:true, value:deliveryFormData['category']})}
                                </div>
                                <div className="col-md-3">
                                    {this.inputType("subcategory", "Sub Category", 'text', handleInputDeliveryChange, 'subcategory', {disabled:true, value:deliveryFormData['subcategory']})}
                                </div>
                                <div className="col-md-3">
                                    {this.inputType("orderId", "Order Id", 'text',     handleInputDeliveryChange, 'orderId', {disabled:true, value:deliveryFormData['orderId']})}
                                </div>
                                <div className="col-md-3">
                                    {this.inputType("vendorId", "Vendor Id", 'text',     handleInputDeliveryChange, 'vendorId', {disabled:true, value:deliveryFormData['vendorId']})}
                                </div>
                                <div className="col-md-3">
                                    {this.inputType("unitsReceived", "Units Receieved", 'number', handleInputDeliveryChange, 'unitsReceived', {min:0, value:deliveryFormData['unitsReceived']})}
                                </div>
                                <div className="col-md-3">
                                    {this.inputType("date", "Date", 'date', handleInputDeliveryChange, 'date', { value:deliveryFormData['date']})}
                                </div>
                                <div className="col-md-3">
                                    {this.inputType("receiptFile", "Proof Of Receipt", 'file', handleFileInputDeliveryChange, 'receiptFile')}
                                </div>
                            </div>
                            <button type='submit' className="btn btn-outline-success" style={{ float: 'right', marginLeft: '10px' }}>Submit</button>
                        </form>
                    </div>
                </Modal>
                <div className="procurementAddVendor">
                    <h4 className="procurementAddVendorHeading">Supply</h4>
                    <div className="topbar-section allLeadViewFilters">
                        <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye"></i>} </b></div>
                        <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                        <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                            <div className="col-md-3">
                                {this.inputType("startDate", "Start Date", 'date', this.handleInputDateChangeFilters, 'createdAt')}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("endDate", "End Date", 'date', this.handleInputDateChangeFilters, 'createdAt')}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("vendorId", (paymentsData || []).map((element) => { return { label: element.vendorId, value: element.vendorId } }), "Vendor Id", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("category", Object.keys(props.zunsolarCategoryMapping || {}).map((element) => { return { label: element, value: element } }), "Category", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("subCategory", this.state.subCategoryOptions || [], "Sub Category", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("status", (this.state.statusMapping || []).map((element) => { return { label: element, value: element } }), "Status", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-3">
                                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                            </div>
                        </div>
                    </div>
                    <div className='allLeadViewFilters'>
                        <button className='btn btn-outline-primary' onClick={handleShowAddDelivery}> Add Delivery </button>
                    </div>
                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={supplyData}
                        columns={supplyColumn}
                        defaultSorted={[{ id: "id", desc: true }]}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementSupply);