import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import { ErrorComponent } from '../../components/ErrorComponent';
import { fetchAllProductData, updateProductDataV2, updateListingImage } from '../../actions/zunpulseProductActions';
import { Redirect } from 'react-router-dom';

const BooleanStrOptions = [{label: 'true', value: 'true'}, {label:'false', value: false}];

const baseImageUrl = `https://d1vfpdpyv21g5i.cloudfront.net/zunpulse/products_v5/`;

class SingleProductDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: {},
            productData: {},
            editedProductData: {},
            userObj: {}
        }
    }

    componentWillMount() {
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            console.log(userObj);
            if((userObj.access || []).includes("Website-Admin")) {
                this.props.fetchAllProductData(this.props.match.params.id);
            }
            this.setState({
                userObj
            })
        }
    }

    componentWillReceiveProps(props) {
        let { singleProductData = [] } = props;
        console.log('singleProductData', singleProductData[0]);
        this.setState({
            
            productData:  singleProductData[0]
        })
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            editedProductData: {
                ...this.state.editedProductData,
                [name]: value
            }
        })
    }

    handleImageChange = (e) => {
        let { name, files } = e.target;
        this.setState({
            editedProductData: {
                ...this.state.editedProductData,
                [name]: files[0]
            }
        })
    }

    SelectInput = ({ name, value, options }) => {
        return (
            <div style={{ width: '100%' }}>
                <select onKeyUp={(e) => this.handleKeyPress(e)} onChange={(e) => { this.handleInputChange(e) }} className="form-control" id="sel1" name={name} value={value} >
                    {(options ? options : []).map(e => <option value={e.value}>{e.label}</option>)}
                </select>
            </div>
        )
    }

    InputType = ({ name, value, type, placeholder }) => {
        if (type == "file") {
            return <input type={type} accept="image/*" placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleImageChange(e)} />
        }
        return <input onKeyUp={(e) => this.handleKeyPress(e)} type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    FieldComponent = ({ label, name, options = [], type, fieldType }) => {
        // console.log('name in edit',name);
        let { SelectInput, InputType, handleDoubleClick, state } = this;
        let { edit = {} } = state;
        edit = edit[name];
        let value = "", editValue = "";
        value = this.getValue(this.state.productData, name);
        editValue = this.state.editedProductData[name] || "";
        if (fieldType == "select") {
            return (<tr onDoubleClick={() => handleDoubleClick(name)}>
                <td>{label}</td>
                <td>{edit ? <span className="input-group">
                    <SelectInput
                        name={name}
                        options={options}
                        value={editValue}
                    />
                </span> : <span>{value}</span>}
                </td>
            </tr>)
        }
        else if (fieldType == "input") {
            return (<tr onDoubleClick={() => handleDoubleClick(name)}>
                <td>{label}</td>
                <td>{edit ? <span className="input-group">
                    <InputType
                        name={name}
                        value={editValue}
                        type={type}
                        placeholder={label}
                    />
                </span> : <span>{value}</span>}
                </td>
            </tr>)
        }
        else if (fieldType == "image") {
            return (<tr onDoubleClick={() => handleDoubleClick(name)}>
                <td>{label}</td>
                <td>{edit ? <span className="input-group">
                    <InputType
                        name={name}
                        type={"file"}
                        placeholder={label}
                    />
                    <button onClick={(e) => this.onImageSaveClick(name,value)} className="btn btn-outline-info">Save</button>
                </span> : <span><a href={`${baseImageUrl}${value}`} target="_blank">View</a></span>}
                </td>
            </tr>
            )
        }
        else {
            return '';
        }
    }

    handleDoubleClick = (name) => {
        console.log("name",name, this.state.edit)
        if(this.state.edit[name]) return;
        this.setState({
            edit: {
                ...this.state.edit,
                [name]: true
            },
            editedProductData: {
                ...this.state.editedProductData,
                [name]: this.getValue(this.state.productData, name)
            }
        })
    }

    handleKeyPress = (e) => {
        let { name } = e.target;
        if (e.keyCode == 27) {
            this.setState({
                edit: {
                    ...this.state.edit,
                    [name]: false
                }
            })
        }
        else if (e.keyCode === 13 && !e.shiftKey) {
            this.setState({
                edit: {
                    ...this.state.edit,
                    [name]: false
                }
            })
            console.log('data',this.props.match.params.id, name, this.state.editedProductData[name], this.state.productData);
            // return;
            this.props.updateProductDataV2(this.props.match.params.id, name, this.state.editedProductData[name], this.state.productData);
        }
    }

    onImageSaveClick = (name,value) => {
        console.log('name image',name);
        this.setState({
            edit: {
                ...this.state.edit,
                [name]: false
            }
        })
        console.log('value',value);

        console.log('update images data',this.props.match.params.id, name, this.state.editedProductData[name], this.state.productData,value);
        this.props.updateListingImage(this.props.match.params.id, name, this.state.editedProductData[name], this.state.productData,value);
    }

    getValue = (data, path) => {
        data = JSON.parse(JSON.stringify(data));
        path.split('/').forEach(key => {
            data = (data || {})[key];
        })
        if (data == true || data == false) {
            data = data.toString();
        }
        return data;
    }

    CardHeader = ({ label }) => {
        return (<div className="card-header">
            <div style={{ float: "left" }}>{label}</div>
        </div>)
    }

    render() {
        if (this.props.error) {
            return (
                <div>
                    <ErrorComponent error={this.props.error} />
                </div>
            )
        }

        if(!((this.state.userObj.access || []).includes("Website-Admin"))) {
            return <Redirect to={"/"} />
        }

        let { FieldComponent, CardHeader, getValue } = this;

        return (
            <div>
                <Header />
                {this.state.productData.pid?<div className="container-fluid" style={{ marginTop: "80px" }}>
                    <div className="topbar-section" >
                        <div className="row">
                            <div className="col-md-9 col-xs-12 topbar-left">
                                <div className="col-md-12">
                                    <strong style={{ marginRight: "5px" }}>{this.state.productData.pid}</strong>
                                    <strong>{this.state.productData.productName}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Details"
                        />
                        <div className="card-body">
                            <div className="bottom-section">
                                <div className="row" style={{ margin: '0 20px' }}>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="bestSeller"
                                                    label="Best Seller"
                                                    fieldType="select"
                                                    type="text"
                                                />
                                                  <FieldComponent
                                                    name="productName"
                                                    label="product Name"
                                                    fieldType="input"
                                                    options={BooleanStrOptions}
                                                />
                                                {/* <FieldComponent
                                                    name="maxQuantity"
                                                    label="Max Quantity"
                                                    fieldType="input"
                                                    type="number"
                                                />
                                                <FieldComponent
                                                    name="showTag"
                                                    label="Show tag"
                                                    fieldType="select"
                                                    options={BooleanStrOptions}
                                                /> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="countInStock"
                                                    label="Count in stock"
                                                    fieldType="input"
                                                    type="number"
                                                />
                                                <FieldComponent
                                                    name="mrp"
                                                    label="MRP"
                                                    fieldType="input"
                                                    type="number"
                                                />
                                                <FieldComponent
                                                    name="description"
                                                    label="Description"
                                                    fieldType="input"
                                                    type="text"
                                                />
                                                 <FieldComponent
                                                    name="keywords"
                                                    label="keywords"
                                                    fieldType="input"
                                                    type="text"
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col customer-info">
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="isVisible"
                                                    label="Is visible"
                                                    fieldType="select"
                                                    options={BooleanStrOptions}
                                                />
                                                <FieldComponent
                                                    name="sellingPrice"
                                                    label="Selling Price"
                                                    fieldType="input"
                                                    type="number"
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Product Specification"
                        />
                        <div className="card-body">
                            <div className="bottom-section">
                                <div className="row" style={{ margin: '0 20px' }}>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="descriptions/alt"
                                                    label="Alt"
                                                    fieldType="input"
                                                    type='text'
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="descriptions/img"
                                                    label="Image"
                                                    fieldType="image"
                                                    type="image"
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Description"
                        />
                        <div className="card-body">
                            <div className="bottom-section">
                                <div className="row" style={{ margin: '0 20px' }}>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="descriptions/alt"
                                                    label="Alt"
                                                    fieldType="input"
                                                    type='text'
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="descriptions/img"
                                                    label="Image"
                                                    fieldType="image"
                                                    type="image"
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Desktop Description"
                        />
                        <div className="card-body">
                            <div className="bottom-section">
                                <div className="row" style={{ margin: '0 20px' }}>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="desktopDescription/alt"
                                                    label="Alt"
                                                    fieldType="input"
                                                    type='text'
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="desktopDescription/img"
                                                    label="Image"
                                                    fieldType="image"
                                                    type="image"
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Features"
                        />
                        <div className="card-body">
                            <div className="bottom-section">
                                <div className="row" style={{ margin: '0 20px' }}>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="features/alt"
                                                    label="Alt"
                                                    fieldType="input"
                                                    type='text'
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="features/img"
                                                    label="Image"
                                                    fieldType="image"
                                                    type="image"
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Desktop Features"
                        />
                        <div className="card-body">
                            <div className="bottom-section">
                                <div className="row" style={{ margin: '0 20px' }}>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="desktopFeatures/alt"
                                                    label="Alt"
                                                    fieldType="input"
                                                    type='text'
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col customer-info" style={{ borderRight: '0px' }}>
                                        <table>
                                            <tbody>
                                                <FieldComponent
                                                    name="desktopFeatures/img"
                                                    label="Image"
                                                    fieldType="image"
                                                    type="image"
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Display Videos"
                        />
                        {
                            Object.keys(getValue(this.state.productData, 'displayVideos') || {}).map(index => {
                                return (
                                    <div className="card-body">
                                        <div className="bottom-section">
                                            <div className="row" style={{ margin: '0 20px' }}>
                                                <div className="col customer-info" style={{ borderRight: '0px' }}>
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`displayVideos/${index}/thumbnail`}
                                                                label="Thumbnail"
                                                                fieldType="input"
                                                                type='text'
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col customer-info" style={{ borderRight: '0px' }}>
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`displayVideos/${index}/url`}
                                                                label="Url"
                                                                fieldType="input"
                                                                type="text"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col customer-info">
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`displayVideos/${index}/videoTag`}
                                                                label="Video Tag"
                                                                fieldType="input"
                                                                type="text"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </div> */}
{/* 
                    <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="FAQs"
                        />
                        {
                            Object.keys(getValue(this.state.productData, 'faqs') || {}).map(index => {
                                return (
                                    <div className="card-body">
                                        <div className="bottom-section">
                                            <div className="row" style={{ margin: '0 20px' }}>
                                                <div className="col customer-info" style={{ borderRight: '0px' }}>
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`faqs/${index}/question`}
                                                                label="Question"
                                                                fieldType="input"
                                                                type='text'
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col customer-info">
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`faqs/${index}/answer`}
                                                                label="Answer"
                                                                fieldType="input"
                                                                type="text"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </div> */}

                    <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Listing Images"
                        />
                        {
                            Object.keys(getValue(this.state.productData, 'productMedia') || {}).map(index => {
                                return (
                                    <div className="card-body">
                                        <div className="bottom-section">
                                            <div className="row" style={{ margin: '0 20px' }}>
                                                <div className="col customer-info" style={{ borderRight: '0px' }}>
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`productMedia/${index}/alt`}
                                                                label="Alt"
                                                                fieldType="input"
                                                                type='text'
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col customer-info">
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`productMedia/${index}/img`}
                                                                label="Image"
                                                                fieldType="image"
                                                                type="image"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/* <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Styles"
                        />
                        {
                            Object.keys(getValue(this.state.productData, 'styles/content') || {}).map(index => {
                                return (
                                    <div className="card-body">
                                        <div className="bottom-section">
                                            <div className="row" style={{ margin: '0 20px' }}>
                                                <div className="col customer-info" style={{ borderRight: '0px' }}>
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`styles/content/${index}/dImage`}
                                                                label="Desktop Image"
                                                                fieldType="image"
                                                                type='image'
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col customer-info">
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`styles/content/${index}/image`}
                                                                label="Mobile Image"
                                                                fieldType="image"
                                                                type="image"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col customer-info">
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`styles/content/${index}/text`}
                                                                label="Text"
                                                                fieldType="input"
                                                                type="text"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </div> */}

                    {/* <div className="card shadow mt-3" style={{ margin: '0 20px' }}>
                        <CardHeader
                            label="Videos"
                        />
                        {
                            Object.keys(getValue(this.state.productData, 'videos') || {}).map(index => {
                                return (
                                    <div className="card-body">
                                        <div className="bottom-section">
                                            <div className="row" style={{ margin: '0 20px' }}>
                                                <div className="col customer-info" style={{ borderRight: '0px' }}>
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`videos/${index}/showVideo`}
                                                                label="Show Video"
                                                                fieldType="select"
                                                                options={BooleanStrOptions}
                                                            />
                                                            <FieldComponent
                                                                name={`videos/${index}/url`}
                                                                label="Url"
                                                                fieldType="input"
                                                                type="text"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col customer-info">
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`videos/${index}/thumbnail`}
                                                                label="Thumbnail"
                                                                fieldType="input"
                                                                type="text"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col customer-info">
                                                    <table>
                                                        <tbody>
                                                            <FieldComponent
                                                                name={`videos/${index}/title`}
                                                                label="Title"
                                                                fieldType="input"
                                                                type="text"
                                                            />
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </div> */}

                </div>:""}
            </div>
        )
    }
}


function mapStateToProps({ zunpulseProducts }) {
    let { singleProductData = [] } = zunpulseProducts;
    return {
        singleProductData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, fetchAllProductData, updateProductDataV2, updateListingImage }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProductDetails);