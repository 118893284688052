import React from 'react';
import axios from 'axios';
import url from '../config';
import moment from 'moment';
import { LEAD_TRANSFER_START, LEAD_TRANSFER_ERROR, LEAD_TRANSFER_SUCCESS } from './actiontypes';
import swal from 'sweetalert';

export function leadTransferGet(filterData, view = "zunpulse") {
  return async (dispatch) => {

    let route;
    switch (view) {
      case 'zunpulse':
        route = "zunpulse/leadtransfer/leads/get";
        break;
      case 'zunsolar':
        route = "zunsolar/leadtransfer/leads/get";
        break;
      default:
        route = "zunpulse/leadtransfer/leads/get";
        break;
    }

    dispatch({ type: LEAD_TRANSFER_START, payload: {} });
    let employees = filterData.employees
    delete filterData.employees;
    // console.log("shivam", filterData)
    let conditions = filterData;
    let response = await axios.post(url.pathBackend + route, { conditions, employees });
    if (response.data) {
      // console.log("123", response);
      dispatch({ type: LEAD_TRANSFER_SUCCESS, payload: response.data })
    }
    if (response.data.error) {
      console.log(response.data.error)
      dispatch({ type: LEAD_TRANSFER_ERROR, payload: response.data.error })
    }
  }
}