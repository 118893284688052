import React, { Component } from "react";
import swal from "sweetalert";
import HeaderComponent from "../HeaderComponent";
import '../styles/mtCreateLead.css'
import { createLead } from "../../actions/createLeadAction";
import { bindActionCreators } from "redux";
import { fetchAllMapping, fetchMappingZunsolar } from "../../actions/fetchAllMapping";
import { connect } from "react-redux";
import MtCreateLeadSecond from "./MtCreateLeadSecond";

class MtCreateLeadComponent extends Component {

    componentWillMount() {
        let viewList = ["employees"];
        this.props.fetchAllMapping(viewList);
        this.props.fetchMappingZunsolar();
    }

    constructor(props) {
        super(props)
        this.getLocation = this.getLocation.bind(this);

        this.state = {
            leadData: {},
            mappingData: {},
            location: {},
        }
    }

    componentWillReceiveProps(props) {
        let { bitrixId } = props;
        let { finalLeadData } = this.state;


        let allEmployees = props.allMapping && props.allMapping.employees ? props.allMapping.employees : []
        let employeesName = []
        allEmployees.forEach((emp) => {
            employeesName.push(emp.e_name)
        })

        this.setState({
            finalLeadData,
            mappingData: (props.allMapping && props.allMapping.mapping) ? props.allMapping.mapping : {},
            stateCityMap: props.allMapping ? props.allMapping.stateCityMap : {},
            employeesArr: employeesName
        })
    }

    getLocation=() =>{
        navigator.geolocation.getCurrentPosition(

            (position) => {

                this.setState({
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    onNewLeadDataSubmit = (e) => {
        let leadData = this.state.leadData  

            const lat=this.state.location.lat
            const lng=this.state.location.lng

            leadData.latitude=lat
            leadData.longitude=lng
        // leadData.leadType = "Retailer"
        leadData = this.removingNullKeys(leadData);
        if (leadData.phoneNumber && /^\d{10}$/.test(leadData.phoneNumber)) {
            this.props.createLead(leadData, 'zunsolar',true)

        } else {
            swal("Required Fields Missing!", "FIll Atleast Phone (10 digits)")
        }
    }

    removingNullKeys = (obj) => {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
                delete obj[propName];
            }
        }
        return obj
    }

    checkSelectString = (str) => {
        if (str == "not selected") {
            return str = ""
        }
        else {
            return str
        }
    }

    handleInputChange = (e) => {

        let { name, value } = e.target;
        value = this.checkSelectString(value);

        this.setState({
            leadData: {
                ...this.state.leadData,
                [name]: value,
            },
        })
    }

    inputType = (name, value, type, placeholder, disabled = false) => {
        return <input type={type} name={name} value={value} disabled={disabled} onChange={(e) => this.handleInputChange(e)} placeholder={placeholder} />
    }

    selectType = (name, value, options, placeholder) => {

        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleInputChange(e) }}
                    className="formControl-select" id="sel1" name={name} value={value}
                >
                    <option>{placeholder}</option>
                    {(options ? options : []).map(e => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    render() {

        return (
            <>
                <HeaderComponent />
                <div className="createDealerContainer">

                    <div className="topHeadContainer">
                        <h5>Create New Lead</h5>
                    </div>

                    <div className="mtFirstContainer">

                        <div className="form-select">
                            {this.selectType("leadType", this.state.leadData.leadType, this.state.mappingData.type ? Object.keys(this.state.mappingData.type) : [], "Lead Type")}
                        </div>

                        <div className="form-select">
                            {this.selectType("leadSubType", this.state.leadData.leadSubType ? this.state.leadData.leadSubType : '', this.state.leadData.leadType ? ((this.state.mappingData.type || {})[this.state.leadData.leadType] || []) : [], "Lead Sub Type")}
                        </div>

                        <div className="form-ctn">
                            {this.inputType("phoneNumber", this.state.leadData.phoneNumber, "number", "Phone Number")}
                        </div>

                        <div className="form-ctn">
                            {this.inputType("name", this.state.leadData.name, "text", "Name")}
                        </div>

                        <div className="form-ctn">
                            {this.inputType("email", this.state.leadData.email, "email", "Email Id")}
                        </div>

                        <div className="form-ctn">
                            {this.inputType("pincode", this.state.leadData.pincode, "text", "Pincode")}
                        </div>

                        <div className="form-select">
                            {this.selectType("state", this.state.leadData.state, this.state.stateCityMap ? Object.keys(this.state.stateCityMap) : [], "State")}
                        </div>

                        <div className="form-select">
                            {this.selectType("city", this.state.leadData.city, this.state.leadData.state ? ((this.state.stateCityMap || {})[this.state.leadData.state] || []) : [], "City")}
                        </div>

                        <div className="form-ctn">
                            {this.inputType("address", this.state.leadData.address, "text", "Address")}
                        </div>

                        <div className="getlocation-ctn">
                            <button onClick={this.getLocation}>Get Location</button>
                        </div>

                        <div className="save-next-container">
                        <button onClick={(e) => this.onNewLeadDataSubmit(e)}>Save and Next</button>
                        </div>

                    </div>

                </div>
            </>

        )
    }
}

function mapStateToProps(state) {
    return {
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
        createLeadSuccess: state.createLead ? state.createLead.success : {},
        bitrixId: state.createLead ? state.createLead.bitrixId : '',
        mtLeadData: state.createLead ? state.createLead.mtLeadData : []
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllMapping, createLead, fetchMappingZunsolar }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(MtCreateLeadComponent);