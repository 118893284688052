import { LEAD_TRANSFER_SUCCESS, LEAD_TRANSFER_START, LEAD_TRANSFER_ERROR } from '../actions/actiontypes';

export default function (state = {}, action) {
  switch (action.type) {
    case LEAD_TRANSFER_START: {
      return {};
    }
    case LEAD_TRANSFER_ERROR: {
      return { error: action.payload }
    }
    case LEAD_TRANSFER_SUCCESS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}