import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import { ErrorComponent } from '../../components/ErrorComponent';
import { fetchAllProductData } from '../../actions/zunpulseProductActions';
import { Redirect } from 'react-router-dom';
import Select from 'react-select'

const baseImageUrl = `https://d1vfpdpyv21g5i.cloudfront.net/zunpulse/products_v5/`;

class ZunpulseProductView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allProductData: [],
            filteredProductData :[],
            userObj: {},
            optionMapping:{category:[], subCategory:[], stock:[], visible:[], pids:[]},
            filters:{},
            showFilter:false,
            isFilterApplied:false

        }
    }


    componentWillMount() {
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            if((userObj.access || []).includes("Website-Admin")) {
                this.props.fetchAllProductData();
            }
            this.setState({
                userObj
            })
        }
    }

    componentWillReceiveProps(props) {
        let { allProductData = [] } = props;
        // console.log("prodDataSet", allProductData);
        let category = [];
        let subCategory = [];
        let visible = [];
        let stock = [];
        let pids = [];
        

        allProductData.forEach((product)=>{
            category.push(product.category);
            subCategory.push(product.subCategory);
            visible.push(product.isVisible);
            stock.push(product.countInStock);
            pids.push(product.pid)
        })
        // console.log('pids', pids);
        category = [...new Set(category)];
        subCategory = [...new Set(subCategory)];
        visible = [...new Set(visible)]
       let optionCategory = category.map((data)=>{
            return {label:data, value:data}
            
        })
        let optionSubCategory =  subCategory.map((data)=>{
            return {label:data, value:data}
            
        })
        let optionVisible = visible.map((data)=>{
            return {label:data? "true":"false", value:data? "true":"false"}
            
        })
        let optionStock =  visible.map((data)=>{
            return {label:data? "true":"false", value:data? "true":"false"}
            
        })
        let optionPid = pids.map((data)=>{
            return {label:data, value:data}

        })
        console.log( optionVisible);
        let optionMapping= this.state.optionMapping;
        optionMapping.category = optionCategory;
        optionMapping.subCategory = optionSubCategory;
        optionMapping.visible = optionVisible;
        optionMapping.stock = optionStock;
        optionMapping.pids = optionPid
        this.setState({
            allProductData,
            filteredProductData:allProductData,
            optionMapping
        })
    }
    handleSelectChangeFilters = (e, name) => {
        console.log('data', e, name);
        let value = (e || []).map(option => option.value);
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }
    

    onApplyFilter = () => {
        let { filters, allProductData,filteredProductData } = this.state
        console.log('filters', filters);
        // console.log('allProductData', allProductData);
        const pids = filters.pids;
        const category = filters.category;
        const subCategory = filters.subCategory;
        const stock = filters.stock;
        const visible = filters.visible;
        let filterProducts = allProductData;
        console.log('filterProducts', filterProducts);
        console.log('pids', pids);
        if(pids && pids.length>0){
           filterProducts =  filterProducts.filter((product)=> {  
                console.log(pids.includes(product.pid));
                return pids.includes(product.pid)
            })   
        }
        if(category && category.length>0){
            filterProducts =  filterProducts.filter((product)=> {  
                 return category.includes(product.category)
             })   
         }
         if(subCategory && subCategory.length>0){
            filterProducts =  filterProducts.filter((product)=> {  
                 return subCategory.includes(product.subCategory)
             })   
         }
         if(stock && stock.length>0){
        
            filterProducts =  filterProducts.filter((product)=> {  
                return stock[0]=="true" ? !product.countInStock : product.countInStock;
            }) 

         }
         if(visible && visible.length>0){
            filterProducts =  filterProducts.filter((product)=> {  
                return visible[0]=="true" ? product.isVisible : !product.isVisible;
            }) 
         }
        console.log('filterProd', filterProducts);
        this.setState({
            filteredProductData:filterProducts
        })
        console.log('pids', pids);
    

        
        

    }
    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    selectType = (name, options, placeholder, onChange) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }

    // AddSelect = ({ isMulti, label, name, options = [], onChange, required = false, value, inputParams = {} }) => {
    //     return (
    //         <div className="col-12 col-md-2 addVendorInput">
    //             <div className="form-group">
    //                 <div className="mb-3">
    //                     <label>{`${label} ${required ? '*' : ''}`}</label>
    //                     <Select
    //                         isSearchable={true}
    //                         placeholder={label}
    //                         name={name}
    //                         options={options}
    //                         onChange={(e) => onChange(e, name)}
    //                         isMulti={isMulti}
    //                         value={{ value: value, label: value }}
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    render() {
        if (this.props.error) {
            return (
                <div>
                    <ErrorComponent error={this.props.error} />
                </div>
            )
        }

        if(!((this.state.userObj.access || []).includes("Website-Admin"))) {
            return <Redirect to={"/"} />
        }

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        return (
            <div>
                <Header />
                <div className="topbar-section allLeadViewFilters" style={{marginTop:'120px'}}>
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} className="fa fa-eye"></i>} </b></div>
                    <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                    <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                    <div className="col-md-3">
                            {this.selectType("pids", this.state.optionMapping.pids, "Product Id", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("category", this.state.optionMapping.category, "Category", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("subCategory", this.state.optionMapping.subCategory, "Sub Category", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("stock", this.state.optionMapping.stock, "Out Of Stock", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("visible", this.state.optionMapping.visible, "Visible", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>
                   
                </div>
                <div className="container-fluid" style={{ marginTop: "80px" }}>
                    <div className="row">
                        {
                            this.state.filteredProductData &&  this.state.filteredProductData.length &&  this.state.filteredProductData?.map((product,index) => {
                                return (
                                    <a key={index} href={`/zunpulse/admin/productView/${product.pid}`}  className="mt-3 shadow" style={{ display: 'table', border: '2px solid #A9A9A9', borderRadius: '6px', width: '31%', margin: '0 1%', textDecoration: 'none' }}>
                                        <span><img src={product.homeImg ? `${baseImageUrl}${product.homeImg.img}` : ''} style={{ verticalAlign: 'middle', display: 'table-cell', width: "200px", height: "200px" }} /></span>
                                        <span style={{ verticalAlign: 'middle', display: 'table-cell', padding: '7px 30px', textDecoration: 'none' }}>{product.productName}</span>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps({ zunpulseProducts }) {
    let { allProductData = [] } = zunpulseProducts;
    return {
        allProductData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, fetchAllProductData }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseProductView);