import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


class AffliateAddKYC extends Component {

    constructor() {
        super();
        this.myRef=React.createRef();
        this.state = {
            KYCData: {}
        };
    }

    componentWillReceiveProps({ isAdded }) {
        console.log('working');
        const form=this.myRef.current;
        if (isAdded){
            setTimeout(()=>{
                form.reset()
            },200);
            this.setState({KYCData:{}})
        }
    }

    AddKYCInput = ({ label, name, type, required = true, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <input className="form-control customInput" type={type} name={name} placeholder={label}  onChange={(e) => this.handleAddKYCInputChange(e)} {...inputParams} required={required} />
                    </div>
                </div>
            </div>
        )
    }

    AddKYCFileInput = ({ label, name, required = true, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <input className="form-control customInput" type={"file"} name={name} placeholder={label} onChange={(e) => this.handleAddKYCFileChange(e)} {...inputParams} required={required} />
                    </div>
                </div>
            </div>
        )
    }


    handleAddKYCInputChange = (e) => {
        let { name, value } = e.target;
        let { KYCData = {} } = this.state;
        KYCData[name] = value;
        this.setState({
            KYCData
        })
    }
    handleAddKYCFileChange = (e) => {
        let { name, files } = e.target;
        console.log(name, files);
        this.setState(prevState => ({
            KYCData: {
                ...prevState.KYCData,
                [name]: files[0]
            }
        }))
    }


    handleSubmitVendorForm = (e) => {
        e.preventDefault();
        let { KYCData = {} } = this.state;
        this.props.addKYC(KYCData);
        console.log("Submit", KYCData);
    }

    render() {

        let { AddKYCInput, handleSubmitVendorForm , AddKYCFileInput } = this;

        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Add KYC</h4>
                <form onSubmit={handleSubmitVendorForm} ref={this.myRef}>
                    <div className="addVendorForm">
                        <div className="row">
                            <AddKYCInput
                                label="Name"
                                name="name"
                                type="text"
                                required={true}
                                inputParams={{
                                    pattern:"[a-zA-Z ]*"
                                }}
                            />
                            <AddKYCInput
                                label="Pan Card"
                                name="panCard"
                                type="text"
                                required={true}
                                inputParams={{
                                    pattern: "([A-Z]){5}([0-9]){4}([A-Z]){1}",
                                    minLength: 10,
                                    maxLength: 10
                                }}
                            />
                            <AddKYCFileInput
                                label="Pan Card"
                                name="panCardDetail"
                                required={true}
                            />
                            <AddKYCInput
                                label="Phone"
                                name="phoneNumber"
                                type="tel"
                                required={true}
                                inputParams={{
                                    pattern: "[0-9]{10}",
                                    minLength: 10,
                                    maxLength: 10
                                }}
                            />
                        </div>
                        <h5 className="col-12">Bank Details</h5>
                        <div className="row">
                            <AddKYCInput
                                label="Account Number"
                                name="accountNumber"
                                type="text"
                                required={true}
                                inputParams={{
                                    pattern: "[0-9]{10,15}",
                                    minLength: 10,
                                    maxLength: 15,
                                    }}
                            />
                            <AddKYCInput
                                label="IFSC Code"
                                name="ifsc"
                                type="text"
                                inputParams={{
                                    pattern: "[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}",
                                    minLength: 11,
                                    maxLength: 11
                                }}
                                required={true}
                            />
                        </div>
                        <button type="submit" className="btn btn-outline-success" style={{ float: 'right' }}>Submit</button>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </form>
            </div>
        )   
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AffliateAddKYC);