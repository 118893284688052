import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../components/HeaderComponent';
import { fetchMapping, insertManualEntry } from '../actions/logisticsTrackerActions';
import { bindActionCreators } from 'redux';
import Select from "react-select";
import debounce from "lodash/debounce";
import { fetchDealersData } from "../actions/zunsolarPiPortalActions";

class LogisticsManualEntry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [{}],
            formData: {},
            mapping: {},
            dealersData: [],
            fetchDealersListError: false
        };
    }

    componentWillMount() {
        this.props.fetchMapping();
    }

    componentWillReceiveProps(props) {
        let { mapping, dealersData, fetchDealersListError } = props;
        this.setState({
            mapping,
            dealersData,
            fetchDealersListError
        })
    }

    addProduct = () => {
        let { products } = this.state;
        if (products.length > 9) {
            return;
        }
        products = [...products, {}];
        this.setState({
            products
        })
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleProductChange = (e, index) => {
        let { products } = this.state;
        let { name, value } = e.target;
        products[index][name] = value;
        this.setState({
            products
        }, () => { })
    }

    handleProductNameChange = (e, name, index) => {
        let { products } = this.state;
        let { value, label } = e;
        products[index][name] = value;
        products[index].productName = label;
        this.setState({
            products
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { products, formData } = this.state;
        console.log(products, formData);
        this.props.insertManualEntry({ ...formData, products });
    }

    inputDealerIdChange = (e) => {
		if (e.length <= 3) return;
		this.props.fetchDealersData(e);
		this.setState({
			fetchDealersListError: false,
		});
    };
    
    handelDealerIdChange = (e) => {
        let { value, name, pincode, city } = e;
        this.setState({
            formData: {
                ...this.state.formData,
                dealerId: value,
                dealerName: name,
                dealerPincode: pincode,
                dealerCity: city
            }
        })
    }

    render() {
        let { products, formData, mapping, dealersData, fetchDealersListError } = this.state;
        return (
            <div className="analyseAc">
                <Header />
                <div class="container">
                    <div className="row">
                        <h4 className="col-9" style={{ fontWeight: "bold" }}>Add Shipment</h4>
                        <button className="button-save col-2" onClick={(e) => this.addProduct(e)}>Add Product</button>
                    </div>
                    <hr className="analyseAcHr"></hr>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Invoice Number
                            </label>
                            <div class="col-md-8">
                                <input type="text" className="form-control" name="invoiceNumber" onChange={(e) => this.handleInputChange(e)} value={formData.invoiceNumber} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Invoice Date
                            </label>
                            <div class="col-md-8">
                                <input type="date" className="form-control" name="invoiceDate" onChange={(e) => this.handleInputChange(e)} value={formData.invoiceDate} required></input>
                            </div>
                        </div>

                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Invoice Value
                            </label>
                            <div class="col-md-8">
                                <input type="number" className="form-control" name="invoiceValue" onChange={(e) => this.handleInputChange(e)} value={formData.invoiceValue} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Channel
                            </label>
                            <div className="col-md-8">
                                <select onChange={(e) => { this.handleInputChange(e) }} className="form-control" name="channel" value={formData.channel} required>
                                    <option value="">not selected</option>
                                    {(mapping.channel ? mapping.channel : []).map(e => <option key={e}>{e}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Mode
                            </label>
                            <div className="col-md-8">
                                <select onChange={(e) => { this.handleInputChange(e) }} className="form-control" name="mode" value={formData.mode} required>
                                    <option value="">not selected</option>
                                    {(mapping.mode ? mapping.mode : []).map(e => <option key={e}>{e}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Dealer Id
                            </label>
                            <div className="col-md-8">
                                <Select
                                    isSearchable
                                    placeholder="Select Dealer Id"
                                    name="dealerId"
                                    options={dealersData.map((val) => {
                                        return {
                                            label: `${val.solarId} ${val.companyName || ""}`,
                                            value: val.solarId,
                                            name: val.companyName || "",
                                            pincode: val.pincode || "",
                                            city: val.city || ""
                                        };
                                    })}
                                    onInputChange={debounce(
                                        (e) => this.inputDealerIdChange(e),
                                        3000
                                    )}
                                    noOptionsMessage={() =>
                                        fetchDealersListError
                                            ? "No results found"
                                            : "Loading..."
                                    }
                                    onChange={(e) => this.handelDealerIdChange(e)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Dealer Name
                            </label>
                            <div class="col-md-8">
                                <input type="text" className="form-control" name="dealerName" onChange={(e) => this.handleInputChange(e)} value={formData.dealerName} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Dealer Pincode
                            </label>
                            <div class="col-md-8">
                                <input type="text" className="form-control" name="dealerPincode" onChange={(e) => this.handleInputChange(e)} value={formData.dealerPincode} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Dispatch Date
                            </label>
                            <div class="col-md-8">
                                <input type="date" className="form-control" name="dispatchDate" onChange={(e) => this.handleInputChange(e)} value={formData.dispatchDate} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Tentative Weight (in kgs)
                            </label>
                            <div class="col-md-8">
                                <input type="number" className="form-control" name="tentativeWeight" onChange={(e) => this.handleInputChange(e)} value={formData.tentativeWeight} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Shipping Charges
                            </label>
                            <div class="col-md-8">
                                <input type="number" className="form-control" name="shippingCharges" onChange={(e) => this.handleInputChange(e)} value={formData.shippingCharges} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Tentative Delivery Date
                            </label>
                            <div class="col-md-8">
                                <input type="date" className="form-control" name="tentativeDeliveryDate" onChange={(e) => this.handleInputChange(e)} value={formData.tentativeDeliveryDate} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Responsible Person
                            </label>
                            <div className="col-md-8">
                                <select onChange={(e) => { this.handleInputChange(e) }} className="form-control" name="responsiblePerson" value={formData.responsiblePerson} required>
                                    <option value="">not selected</option>
                                    {(mapping.responsiblePerson ? mapping.responsiblePerson : []).map(e => <option key={e}>{e}</option>)}
                                </select>
                            </div>
                        </div>
                        {
                            products.map((productRow, index) => {
                                return (
                                    <div className="row">
                                        <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                            Product {index + 1}
                                        </label>
                                        <div className="col-md-4">
                                            <Select
                                                isSearchable
                                                onChange={(e) => { this.handleProductNameChange(e, "pid", index) }}
                                                placeholder={"Select product id"}
                                                options={(mapping.productsList || []).map(row => {
                                                    let variantType = row.variantType;
                                                    if (!variantType || variantType == "NULL" || variantType == "null") variantType = "";
                                                    return {
                                                        label: `${row.pID} ${row.productName} ${variantType}`,
                                                        value: row.pID
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input type="number" value={products[index].qty || ""} className="form-control" name="qty" onChange={(e) => this.handleProductChange(e, index)} placeholder="Quantity" min="1" required></input>
                                        </div>
                                        <br /><br />
                                    </div>
                                )
                            })
                        }
                        <br /><br />
                        <div className="callCountSubmit">
                            <button className="btn btn-success" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ logisticsTracker, zunsolarPiPortal }) {
    let { dealersData = [], fetchDealersListError = false } = zunsolarPiPortal;
    let { mapping = {} } = logisticsTracker || {};
    return {
        mapping,
        dealersData,
        fetchDealersListError
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMapping, insertManualEntry, fetchDealersData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsManualEntry);


