import {REGISTER_SUCCESS,REGISTER_UNSUCCESSFUL} from '../actions/actiontypes';

export default function(state = '', action) {
	switch(action.type){
		case REGISTER_UNSUCCESSFUL:{
			return {...state,error:action.payload, success: undefined};
		}
		case REGISTER_SUCCESS:{
			return {...state,success:action.payload, error : undefined};
		}
		default:
			return state;
	}
}
