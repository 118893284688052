import React from 'react';
import { addComments, editZunpulseLead, fetchZunpulseLead,addZunpulseCustomerQuotation,getZunpulseCustomerQuotation, getSellingPriceMapping, getZunpulseQuotationProducts } from '../../actions/fetchBitrixId';
import { fetchMappingZunpulse, fetchAllMapping } from '../../actions/fetchAllMapping';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import ZunpulseSingleLeadViewComponent from '../../components/zunpulseComponents/ZunpulseSingleLeadViewComponent';
import {getUnicommerceMapping} from '../../actions/zunsolarPiInvoiceAction';


class ZunpulseSingleLeadView extends Component {
  componentWillMount() {
    let viewList = ["employees"]
    this.props.fetchAllMapping(viewList)
    this.props.getUnicommerceMapping();
    this.props.fetchZunpulseLead(this.props.match.params.id);
    this.props.getZunpulseCustomerQuotation(this.props.match.params.id)
    this.props.getSellingPriceMapping();
    this.props.fetchMappingZunpulse();
    this.props.getZunpulseQuotationProducts(this.props.quotationId)
  }

  render() {
    if (this.props.leadData) {
      return (
        <div style={{ backgroundColor: "#e6ffe9" }}>
          <Header getSearchResult={this.props.getSearchResult} />
          {/* <LeadView leadDetails={this.props.leadData} addComments={this.props.addComments} editLead={this.props.editLead} allMapping={this.props.allMapping} fetchBitrixId={this.props.fetchBitrixId} /> */}
          <ZunpulseSingleLeadViewComponent allMapping={this.props.allMapping} leadDetails={this.props.leadData} editLead={this.props.editZunpulseLead} addComments={this.props.addComments} fetchZunpulseLead={this.props.fetchZunpulseLead} unicommercMapping={this.props.unicommercMapping} addZunpulseCustomerQuotation = {this.props.addZunpulseCustomerQuotation} getZunpulseCustomerQuotation={this.props.getZunpulseCustomerQuotation} getZunpulseQuotationProducts = {this.props.getZunpulseQuotationProducts}  sellingPriceMapping={this.props.sellingPriceMapping}/>
        </div>
      )
    } else {
      return <p>this is not found</p>
    }
  }
}

function mapStateToProps(state) {

  return {
    leadData: state.fetchlead ? state.fetchlead : '',
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    searchData: state.searchResults ? state.searchResults : [],
    unicommercMapping: state.zunsolarBulkPIInvoice.unicommerceMapping? state.zunsolarBulkPIInvoice.unicommerceMapping:[],
    sellingPriceMapping : state.zunsolarBulkPIInvoice.sellingPriceMapping ?state.zunsolarBulkPIInvoice.sellingPriceMapping :{},

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchZunpulseLead, fetchAllMapping, editZunpulseLead, fetchMappingZunpulse, getSearchResult, addComments,getUnicommerceMapping,addZunpulseCustomerQuotation,getZunpulseCustomerQuotation,getSellingPriceMapping, getZunpulseQuotationProducts }, dispatch)
}

function isLeadData() {
  return (
    <p>this is not found</p>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseSingleLeadView)