import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../components/HeaderComponent';
import { connect } from 'react-redux';
// import {registerAction} from '../actions/register';
import { RegisterComponent } from '../components/RegisterComponent.js';
import { register } from '../actions/register';

class Register extends Component {

  render() {
    return (
      <div>

        <RegisterComponent register={this.props.register} success={this.props.success} error={this.props.error} />
      </div>
    )
  }
}


function mapStateToProps(state) {
  // console.log(state);
  return {
    success: state.register.success,
    error: state.register.error
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ register }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
