import {LOGIN_SUCCESS,LOGIN_UNSUCCESSFUL} from '../actions/actiontypes';

export default function(state = '', action) {
	switch(action.type){
		case LOGIN_UNSUCCESSFUL:{
			return {...state,error:action.payload, success: undefined};
		}
		case LOGIN_SUCCESS:{
			return {...state,success:action.payload, error : undefined};
		}
		default:
			return state;
	}
}
