import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/createLead.css';
import swal from 'sweetalert';

export default class ZunpulseCreateLeadComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leadData: {},
      mappingData: {},
      stateCityMap:{}
    }
  }

  componentWillReceiveProps(props) {
    let mapping = props.allMapping ? props.allMapping : {};
    
    if (Object.keys(mapping).length > 0) {
      let userObj = JSON.parse(localStorage.getItem("userObj"));
      let username = userObj.username
      let defaultResponsible = props.allMapping.employees ? props.allMapping.employees.find((user) => user.e_email == username) : "";
      this.setState({
        mappingData: props.allMapping ? props.allMapping : {},
        leadData: {
          stage: "New",
          active: "1",
          responsible_person: defaultResponsible ? defaultResponsible.e_name : "Prateek Suraj"
        },
        stateCityMap: mapping ? mapping.stateCityMap : {},

      })
    }
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    value = this.checkSelectString(value);
    if (name == "establishment_type") {
      let establishment_answer_options = this.state.mappingData.establishments[value].options
      let establishment_question = this.state.mappingData.establishments[value].title
      this.setState({
        leadData: {
          ...this.state.leadData,
          [name]: value,
          establishment_question
        },
        establishment_answer_options
      })
    }
    else {
      this.setState({
        leadData: {
          ...this.state.leadData,
          [name]: value,
        }
      })
    }
  }


  inputType = (name, value, type) => {
    return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
  }

  selectType = (name, value, options) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }

  removingNullKeys = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  onNewLeadDataSubmit = (e) => {
    let newData = { ...this.state.leadData };
    newData = this.removingNullKeys(newData);
    console.log(newData)
    if (newData.phoneNumber && /^\d{10}$/.test(newData.phoneNumber) && newData.name && newData.companyName) {
      {
        this.props.newLead(newData, 'zunpulse')
        this.setState({
          leadData: {}
        })
      }
    } else {
      swal("Required Fields Missing!", "Phone Number(10 digits), Lead Name and POC are required")
    }
  }

  render() {
    return (
      <div className="container-fluid" style={{ marginTop: "80px" }}>
        <div className="topbar-section" >
          <div className="row">
            <div className="col-md-10 col-xs-12 topbar-left">
              <div className="col-md-12">
                <strong>Create New Lead</strong>
              </div>
            </div>
            <div className="col-md-2 col-xs-12 topbar-right">
              <div className="col-md-12 col-xs-12">
                <button className="button-save" onClick={(e) => this.onNewLeadDataSubmit(e)}>Save</button>

              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "35px", marginBottom: "8px" }}>
          <div className="col-md-1" style={{ paddingLeft: '35px' }}>
            <h5><b>Stage :</b></h5>
          </div>
          <div className="col-md-3">
            <span className="input-group" style={{ width: "95%", paddingLeft: "78px" }}>
              {this.selectType("stage", this.state.leadData.stage, this.state.mappingData.stage)}
            </span>
          </div>
          <div className="col-md-1">
            <h5 ><b>Active :</b></h5>
          </div>
          <div className="col-md-3">
            <span className="input-group" style={{ width: "95%", paddingLeft: "78px" }}>
              {this.selectType("active", this.state.leadData.active, this.state.mappingData.active)}
            </span>
          </div>
          <div className="col-md-1">
            <h5 ><b>High Priority</b></h5>
          </div>
          <div className="col-md-3">
            <span className="input-group" style={{ width: "95%", paddingLeft: "78px" }}>
              {this.selectType("isHighPriority", this.state.leadData.isHighPriority, this.state.mappingData.isHighPriority)}
            </span>
          </div>

        </div>
        <div className="row">
          <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
            <table>

              <tr>
                <td>Lead Name</td>
                <td>
                  <span className="input-group">
                    {this.inputType("name", this.state.leadData.name, "text")}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Quote Id</td>
                <td>
                  <span className="input-group">
                    {this.inputType("quoteId", this.state.leadData.quoteId, "text")}
                  </span>
                </td>
              </tr>



              <tr>
                <td>Phone</td>
                <td>
                  <span className="input-group">
                    {this.inputType("phoneNumber", this.state.leadData.phoneNumber, "number")}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Alternate Phone</td>
                <td>
                  <span className="input-group">
                    {this.inputType("alternate_number", this.state.leadData.alternate_number, "number")}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <span className="input-group">
                    {this.inputType("email", this.state.leadData.email, "email")}
                  </span>
                </td>
              </tr>
              <tr>
                  <td>GST Number</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("gst_number", this.state.leadData.gst_number, "text")}
                    </span>
                  </td>
                </tr>

              <tr>
                <td>Address</td>
                <td>
                  <span className="input-group">
                    {this.inputType("address", this.state.leadData.address, "text")}
                  </span>
                </td>
              </tr>
              <tr>
                  <td>State</td>
                  <td>
                    <span className="input-group">
                      {/* {this.selectType("ADDRESS_CITY", this.state.leadData.ADDRESS_CITY ? this.state.leadData.ADDRESS_CITY : '', this.state.cities)} */}
                      {this.selectType("state", this.state.leadData.state, this.state.stateCityMap ? Object.keys(this.state.stateCityMap) : [])}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>City</td>
                  <td>
                    <span className="input-group">
                      {/* {this.selectType("ADDRESS_CITY", this.state.leadData.ADDRESS_CITY ? this.state.leadData.ADDRESS_CITY : '', this.state.cities)} */}
                      {this.selectType("city", this.state.leadData.city, this.state.leadData.state ? ((this.state.stateCityMap || {})[this.state.leadData.state] || []) : [])}
                    </span>
                  </td>
                </tr>
              {/* <tr>
                <td>City</td>
                <td>
                  <span className="input-group">
                    {this.selectType("city", this.state.leadData.city, this.state.mappingData.city)}
                  </span>
                </td>
              </tr> */}

            </table>
          </div>

          <div className="col-md-8 table-wrapper">
            <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
              <table className="customer-info-left">

                <tr>
                  <td>Lead Type</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("leadType", this.state.leadData.leadType, this.state.mappingData.type)}
                    </span>

                  </td>
                </tr>

                <tr>
                  <td>Account Manager</td>
                  <td>
                    <span className="input-group">
                      {this.selectNewType("responsible_person", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.leadData.responsible_person)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Co Manager</td>
                  <td>
                    <span className="input-group">
                      {this.selectNewType("co_responsible_person", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.leadData.co_responsible_person)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Point of contact</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("companyName", this.state.leadData.companyname, "text")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Designation</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("designation", this.state.leadData.designation, "text")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Total Billing Potential</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("totalBillingPotential", this.state.leadData.totalBillingPotential, "text")}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Next Action Date</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("nextActionDate", this.state.leadData.nextActionDate, "date")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Language</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("language", this.state.leadData.language, this.state.mappingData.language)}
                    </span>
                  </td>
                </tr>

              </table>
            </div>
            <div className="col-md-6 customer-info">
              <table className="customer-info-right">
                <tr>
                  <td>Source</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("source", this.state.leadData.source, this.state.mappingData.source)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Campaign</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("UTM_CAMPAIGN", this.state.leadData.UTM_CAMPAIGN, "text")}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Projected Order Size</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("projected_order_size", this.state.leadData.projected_order_size, "text")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Closed Order Size</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("closed_order_size", this.state.leadData.closed_order_size, "text")}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Establishment Type</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("establishment_type", this.state.leadData.establishment_type, Object.keys(this.state.mappingData.establishments || {}))}
                    </span>
                  </td>
                </tr>
                {/* <tr>
                  <td>{this.state.leadData.establishment_question || "Select Establishment"}</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("establishment_answer", this.state.leadData.establishment_answer, this.state.establishment_answer_options)}
                    </span>
                  </td>
                </tr> */}
                <tr>
                  <td>Potential</td>
                  <td>
                    <span className="input-group">
                      {this.selectType("potential", this.state.leadData.potential, this.state.mappingData.potential)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Total Number</td>
                  <td>
                    <span className="input-group">
                      {this.inputType("totalNumberOfSpace", this.state.leadData.totalNumberOfSpace, "text")}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
