import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { companyTypeOptions } from '../../config/column_fields';

class ProcurementAddVendor extends Component {

    constructor() {
        super();
        this.state = {
            vendorData: {}
        };
    }

    AddVendorInput = ({ label, name, type, required = true, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <input className="form-control customInput" type={type} name={name} placeholder={label} onChange={(e) => this.handleAddVendorInputChange(e)} {...inputParams} required={required} />
                    </div>
                </div>
            </div>
        )
    }

    AddVendorSelect = ({ isMulti, label, name, options = [], required = true }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <Select
                            isSearchable={true}
                            placeholder={label}
                            name={name}
                            options={options}
                            onChange={(e) => this.handleAddVendorSelectChange(e, name)}
                            isMulti={isMulti}
                        />
                    </div>
                </div>
            </div>
        )
    }

    AddVendorFileInput = ({ label, name, required = true, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <input className="form-control customInput" type={"file"} name={name} placeholder={label} onChange={(e) => this.handleAddVendorFileChange(e)} {...inputParams} required={required} />
                    </div>
                </div>
            </div>
        )
    }

    handleAddVendorInputChange = (e) => {
        let { name, value } = e.target;
        let { vendorData = {} } = this.state;
        vendorData[name] = value;
        this.setState({
            vendorData
        })
    }

    handleAddVendorSelectChange = (e, name) => {
        if(!e) {
            let { editedData = {} } = this.state;
            editedData[name] = e;
            this.setState({
                editedData
            });
            return;
        }
        let value = e;
        if (Array.isArray(e)) value = e.map(row => row.value);
        else value = e.value;
        let { vendorData = {} } = this.state;
        vendorData[name] = value;
        this.setState({
            vendorData
        })
    }

    handleAddVendorFileChange = (e) => {
        let { name, files } = e.target;
        console.log(name, files);
        this.setState(prevState => ({
            vendorData: {
                ...prevState.vendorData,
                [name]: files[0]
            }
        }))
    }

    handleSubmitVendorForm = (e) => {
        e.preventDefault();
        let { vendorData = {} } = this.state;
        let { products = [], companyType } = vendorData;
        if(!companyType || products.length < 1) {
            alert("Required fields missing.");
            return;
        }
        this.props.addVendor(vendorData);
        console.log("Submit", vendorData);
    }

    render() {

        let { AddVendorInput, handleSubmitVendorForm, AddVendorSelect, AddVendorFileInput, props, state } = this;

        let { vendorProductsList = [] } = props;
        let { vendorData = {} } = state;

        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Add Vendor</h4>
                <form onSubmit={handleSubmitVendorForm}>
                    <div className="addVendorForm">
                        <div className="row">
                            <AddVendorInput
                                label="Company Name"
                                name="companyName"
                                type="text"
                                required={true}
                            />
                            <AddVendorSelect
                                label="Company Type"
                                name="companyType"
                                isMulti={false}
                                options={companyTypeOptions}
                                required={true}
                            />
                            <AddVendorInput
                                label="Address"
                                name="address"
                                type="text"
                                required={true}
                            />
                            <AddVendorInput
                                label="Pan Card"
                                name="panNumber"
                                type="text"
                                required={true}
                            />
                            <AddVendorInput
                                label="POC Name"
                                name="pocName"
                                type="text"
                                required={true}
                            />
                            <AddVendorInput
                                label="Phone"
                                name="phoneNumber"
                                type="tel"
                                required={true}
                                inputParams={{
                                    pattern: "[0-9]{10}",
                                    minlength: 10,
                                    maxlength: 10
                                }}
                            />
                            <AddVendorInput
                                label="Email"
                                name="email"
                                type="email"
                                required={true}
                            />
                            <AddVendorSelect
                                label="Products"
                                name="products"
                                isMulti={true}
                                options={vendorProductsList}
                                required={true}
                            />
                            <AddVendorInput
                                label="GST Number"
                                name="gst"
                                type="text"
                                required={(vendorData.products || []).join(',') != "Logistics"}
                            />
                            <AddVendorInput
                                label="Certification"
                                name="certifications"
                                type="text"
                                required={false}
                            />
                        </div>
                        <h5 className="col-12">Bank Details</h5>
                        <div className="row">
                            <AddVendorInput
                                label="Beneficiary Name 1"
                                name="beneficiary"
                                type="text"
                                required={true}
                            />
                            <AddVendorInput
                                label="Account Number 1"
                                name="accNo"
                                type="text"
                                required={true}
                            />
                            <AddVendorInput
                                label="IFSC Code 1"
                                name="IFSC"
                                type="text"
                                required={true}
                            />
                        </div>
                        <div className="row">
                            <AddVendorInput
                                label="Beneficiary Name 2"
                                name="beneficiary2"
                                type="text"
                                required={false}
                            />
                            <AddVendorInput
                                label="Account Number 2"
                                name="accNo2"
                                type="text"
                                required={false}
                            />
                            <AddVendorInput
                                label="IFSC Code 2"
                                name="IFSC2"
                                type="text"
                                required={false}
                            />
                        </div>
                        <h5 className="col-12">Files</h5>
                        <div className="row">
                            <AddVendorFileInput
                                label="GST Certificate"
                                name="gstCertificate"
                                required={true}
                            />
                            <AddVendorFileInput
                                label="Pan Card"
                                name="panCard"
                                required={false}
                            />
                            <AddVendorFileInput
                                label="Cancelled Cheque"
                                name="cancelledCheque"
                                required={false}
                            />
                        </div>
                        <button type="submit" className="btn btn-outline-success" style={{ float: 'right' }}>Submit</button>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcurementAddVendor);