import React from 'react';
import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_PROJECTSALES_MAPPING_SUCCESS, FETCH_PROJECTSALES_LEADS_SUCCESS, FETCH_PROJECTSALES_LEADDATA_SUCCESS, FETCH_PROJECTSALES_LEAD_COMMENTS, FETCH_PROJECTSALES_LEAD_HISTORY } from './actiontypes';

export function fetchMapping() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}projectsales/fetchMapping`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let mapping = response.mapping;
            console.log(mapping)
            dispatch({ type: FETCH_PROJECTSALES_MAPPING_SUCCESS, payload: mapping || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function createNewLead(data) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}projectsales/create`, data);
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            let leadId = response.leadId;
            swal({
                text: "Lead Created Successfully",
                buttons: {
                  cancel: "Close"
                },
                icon: "success",
                content: (
                  <div>
                    <p>Lead id <span><a style={{ cursor: "pointer", color: "blue" }} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + '/projectsales/lead/' + leadId); }}>{leadId}</a></span></p>
                  </div>
                )
              })
            return;
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchAllLeads(page = 1, limit = 500, oldData = [], filters = {}, searchString = '') {
    return async (dispatch) => {
        try {
            console.log(page, limit);
            let response = await axios.post(`${url.pathBackend}projectsales/fetchall`, {
                filters,
                searchString,
                page,
                limit
            });
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            let leadsData = response.data;
            dispatch({ type: FETCH_PROJECTSALES_LEADS_SUCCESS, payload: leadsData.concat(oldData) || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function fetchLeadData(leadId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}projectsales/fetchlead`, {
                leadId
            });
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            let leadData = response.data || {};
            dispatch({ type: FETCH_PROJECTSALES_LEADDATA_SUCCESS, payload: leadData });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchComments(leadId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}projectsales/fetchcomments`, {
                leadId
            });
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            let comments = response.comments || [];
            dispatch({ type: FETCH_PROJECTSALES_LEAD_COMMENTS, payload: comments });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchHistory(leadId) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}projectsales/fetchhistory`, {
                leadId
            });
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            let history = response.history || [];
            dispatch({ type: FETCH_PROJECTSALES_LEAD_HISTORY, payload: history });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateLeadData(leadId, oldData, newData) {
    return async (dispatch) => {
        try {
            let modifiedData = {};
            Object.keys(newData).map(field => {
                if (newData[field] != oldData[field]) {
                    modifiedData[field] = newData[field]
                }
            });
            modifiedData.leadId = leadId;
            let response = await axios.post(`${url.pathBackend}projectsales/update`, modifiedData);
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_PROJECTSALES_LEADDATA_SUCCESS, payload: newData || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateLeadDataBulk(leadIds, newData) {
    return async (dispatch) => {
        try {
            let modifiedData = newData;
            modifiedData.leadId = leadIds;
            let response = await axios.post(`${url.pathBackend}projectsales/update`, modifiedData);
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            alert("Data updated");
            return;
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateSingleCellData(leadId, key, value, allData) {
    return async (dispatch) => {
        try {
            let modifiedData = { [key]: value };
            modifiedData.leadId = leadId;
            let response = await axios.post(`${url.pathBackend}projectsales/update`, modifiedData);
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            let leadIndex = allData.findIndex(row => row.leadId == leadId);
            console.log(leadIndex);
            allData[leadIndex][key] = value;
            dispatch({ type: FETCH_PROJECTSALES_LEADS_SUCCESS, payload: allData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function addComment(comment, commentsArray) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}projectsales/addcomment`, comment);
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_PROJECTSALES_LEAD_COMMENTS, payload: commentsArray || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function deleteLead(leadId, allData = []) {
    return async (dispatch) => {
        try {
            console.log(leadId);
            let response = await axios.post(`${url.pathBackend}projectsales/update`, {
                leadId: leadId,
                active: 0
            });
            response = response.data;
            if (response.error) {
                alert(response.error || "Some Error Ocurred");
                return;
            }
            let leadsData = allData.filter(row => row.leadId != leadId);
            alert(`Lead #${leadId} deleted`);
            dispatch({ type: FETCH_PROJECTSALES_LEADS_SUCCESS, payload: leadsData });
        }
        catch (e) {
            console.log(e);
        }
    }
}