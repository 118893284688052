import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


class AddAffliate extends Component {

    constructor() {
        super();
        this.myRef=React.createRef();
        this.state = {
            affliateData: {}
        };
    }

    componentWillReceiveProps({ isAffiliateAdded }) {
        console.log('working');
        const form=this.myRef.current;
        if (isAffiliateAdded){
            setTimeout(()=>{
                form.reset()
            },200);
            this.setState({affliateData:{}})
        }
    }

    AddAffliateInput = ({ label, name, type, required = true, inputParams = {} }) => {
        return (
            <div className="col-12 col-md-2 addVendorInput">
                <div className="form-group">
                    <div className="mb-3">
                        <label>{`${label} ${required?'*':''}`}</label>
                        <input className="form-control customInput" type={type} name={name} placeholder={label} onChange={(e) => this.handleAddAffliateInputChange(e)} {...inputParams} required={required} />
                    </div>
                </div>
            </div>
        )
    }





    handleAddAffliateInputChange = (e) => {
        let { name, value } = e.target;
        let { affliateData = {} } = this.state;
        affliateData[name] = value;
        if (name === 'discountPercent' || name === 'cashbackPercent'){
            affliateData[name] = parseInt(value);
        } else if (name === 'name') {
            affliateData[name] =  value.toLowerCase(); 
        }
        this.setState({
            affliateData
        })
    }



    handleSubmitVendorForm = (e) => {
        e.preventDefault();
        let { affliateData = {} } = this.state;
        this.props.addAffliate(affliateData);
        console.log("Submit", affliateData);
    }

    render() {

        let { AddAffliateInput, handleSubmitVendorForm } = this;

        return (
            <div className="procurementAddVendor">
                <h4 className="procurementAddVendorHeading">Add Affiliate</h4>
                <form onSubmit={handleSubmitVendorForm} ref={this.myRef}>
                    <div className="addVendorForm">
                        <div className="row">
                            <AddAffliateInput
                                label="Affiliate Name"
                                name="affiliateName"
                                type="text"
                                required={true}
                            />
                            <AddAffliateInput
                                label="Phone"
                                name="affiliateNumber"
                                type="tel"
                                required={true}
                                inputParams={{
                                    pattern: "[0-9]{10}",
                                    minLength: 10,
                                    maxLength: 10
                                }}
                            />
                            <AddAffliateInput
                                label="CashBack Percent"
                                name="cashbackPercent"
                                type="number"
                                required={true}
                                inputParams={{
                                    min: 0,
                                    max: 100
                                }}
                            />
                            <AddAffliateInput
                                label="Discount Percent"
                                name="discountPercent"
                                type="number"
                                required={true}
                                inputParams={{
                                    min: 0,
                                    max: 100
                                }}
                            />
                            <AddAffliateInput
                                label="Affliate Code"
                                name="name"
                                type="text"
                                required={true}
                            />
                        </div>
                        <button type="submit" className="btn btn-outline-success" style={{ float: 'right' }}>Submit</button>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </form>
            </div>
        )   
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAffliate);