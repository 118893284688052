export const listingImageData = (productId, name,  file, previousData, currentPath,emailId)=>{
console.log('form data', productId, name,  file, previousData, currentPath);
const imageIndex = name.split('/')[1];
const currentPathArr = currentPath.split('/');
const path = currentPathArr.slice(0, currentPathArr.length-1).join('/');
console.log('imageIndex', imageIndex, path);
let formData = new FormData();
   formData.append("pid", productId);
   formData.append("path", path);
   formData.append("file", file);
   formData.append("imageIndex", imageIndex)
   formData.append("email", emailId);

   return formData;


}