import React, { Component } from "react";
import Header from "../../components/HeaderComponent";
import "../../styles/ProcurementPortalStyles.css";
import ConsolidatedView from "./ConsolidatedView";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DetailedView from "./DetailedView";
import { getSearchResult } from "../../actions/searchAction";
import {
  fetchConsolidatedList,
  fetchDetailedList,
} from "../../actions/affliatePortalActions";

class LedgerView extends Component {
  constructor() {
    super();
    this.state = {
      selectedTab: "consolidatedView",
    };
  }

  handleOnViewChange = (e) => {
    let { name = "" } = e.target;
    this.setState({ selectedTab: name });
  };

  TabsComponent = ({ name, label }) => {
    let { selectedTab = "" } = this.state;
    let { handleOnViewChange } = this;
    return (
      <button
        className={`tabButtons btn btn-sm ${
          selectedTab === name ? "btn-info" : "btn-outline-info"
        }`}
        name={name}
        onClick={handleOnViewChange}
      >
        {label}
      </button>
    );
  };

  render() {
    let { TabsComponent, state, props } = this;
    let { selectedTab } = state;
    let {
      consolidatedList,
      fetchConsolidatedList,
      detailedList,
      fetchDetailedList,
    } = props;
    return (
      <div>
        <Header />
        <div className={`container-fluid procurementMainDiv`}>
          <div className="tabComponent">
            <TabsComponent name="consolidatedView" label="Consolidated View" />
            <TabsComponent name="detailedView" label="Detailed View" />
          </div>
          {selectedTab === "consolidatedView" ? (
            <ConsolidatedView
              fetchConsolidatedList={fetchConsolidatedList}
              consolidatedList={consolidatedList}
            />
          ) : (
            ""
          )}
          {selectedTab === "detailedView" ? (
            <DetailedView
              fetchDetailedList={fetchDetailedList}
              detailedList={detailedList}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ affliatePortal }) {
  let { consolidatedList, detailedList } = affliatePortal;
  return {
    consolidatedList,
    detailedList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getSearchResult, fetchConsolidatedList, fetchDetailedList },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LedgerView);
