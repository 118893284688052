import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_ZUNSOLAROPS_LAST_EDIT, FETCH_ZUNSOLAROPS_SALES_SUMMARY, FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY, FETCH_ZUNSOLAROPS_ORDER_VALUE, FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE, FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY, FETCH_WEEKLY_ORDERS, FETCH_WEEKLY_DISPATCH_STATUS, FETCH_WEEKLY_DELIVERED_ORDER_STATUS, FETCH_RETURNED_RECEIVED_ORDER_STATUS, FETCH_RETURNING_ORDER_STATUS, FETCH_STATE_SUMMARY_DATA, FETCH_METRICS_SUMMARY_ZUNSOLAR, FETCH_ZUNSOLAROPS_SALES_SUMMARY_ORDER, FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_ORDER, FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE_VIEW_NEW, FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_ORDER, FETCH_ZUNSOLAROPS_CATEGORY_SUMMARY, FETCH_ZUNSOLAROPS_STATE_SUMMARY, FETCH_ZUNSOLAROPS_SALES_SUMMARY_UNIT, FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_UNIT, FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_UNIT, FETCH_ZUNSOLAROPS_MAPDATA, FETCH_ZUNSOLAROPS_SALES_SUMMARY_DISPLAY_ORDER_Id, FETCH_ORDER_VALUE_NEW } from './actiontypes';

const getColumnFields = (columns) => {
    let columnFields = [];
    columns.forEach(field => {
        if (!field) return;
        let fieldConfig = {
            Header: field,
            accessor: field
        };
        if(field == 'average') {
            fieldConfig = {
                Header: field,
                accessor: field
            };
        }
        else if (field == 'month' || field == 'date' || field == 'state') {
            fieldConfig.Header = row => {
                let { data } = row;
                return `${field} (${data.length})`;
            }
        }
        else {
            fieldConfig.Header = row => {
                let { data } = row;
                let total = data.reduce((a, b) => a + (!isNaN(b[field]) && b[field] ? parseInt(b[field]) : 0), 0);
                return `${field} (${total.toLocaleString("en-IN")})`;
            }
            fieldConfig.Cell = row => (parseInt(row.value || 0)).toLocaleString("en-IN")
        }
        columnFields.push(fieldConfig);
    })
    return columnFields;
}

export function fetchLastEditTime() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchlastedittime`);
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let lastEdited = response.date;
            dispatch({ type: FETCH_ZUNSOLAROPS_LAST_EDIT, payload: lastEdited || "" });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMetricsSummary() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchmetricssummary`);
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            dispatch({ type: FETCH_METRICS_SUMMARY_ZUNSOLAR, payload: response.metricsSummary || {} }); 
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSalesSummaryOrderView(mapping={}, filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsummary`, {
                dateFormatType: 'month',
                orderByKey: 'platform',
                filters
            });
            response = response.data || {};
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            if(mapping.platformType){
                let platformTypes = Object.keys(mapping.platformType || {});
                columns = [...columns, ...platformTypes]
                    platformTypes.forEach( platformType => {
                        mapping.platformType[platformType].forEach((platform) => {
                            data.forEach((subOrder) => {
                                if(!subOrder[platformType]){
                                    subOrder[platformType] = 0
                                }
                                subOrder[platformType] += subOrder[platform.toUpperCase()] || 0
                            })
                        })
                    })
            }
            dispatch({ type: FETCH_ZUNSOLAROPS_SALES_SUMMARY_ORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSalesSummaryDisplayOrderIdView(mapping={}, filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsummary`, {
                dateFormatType: 'month',
                orderByKey: 'platform',
                orderIdName: 'displayOrderId',
                filters
            });
            response = response.data || {};
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            if(mapping.platformType){
                let platformTypes = Object.keys(mapping.platformType || {});
                columns = [...columns, ...platformTypes]
                    platformTypes.forEach( platformType => {
                        mapping.platformType[platformType].forEach((platform) => {
                            data.forEach((subOrder) => {
                                if(!subOrder[platformType]){
                                    subOrder[platformType] = 0
                                }
                                subOrder[platformType] += subOrder[platform.toUpperCase()] || 0
                            })
                        })
                    })
            }
            dispatch({ type: FETCH_ZUNSOLAROPS_SALES_SUMMARY_DISPLAY_ORDER_Id, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSalesSummaryUnitView(mapping = {}, filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsummary`, {
                tableType: 'Unit',
                dateFormatType: 'month',
                orderByKey: 'platform',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            if(mapping.platformType){
                let platformTypes = Object.keys(mapping.platformType || {});
                columns = [...columns, ...platformTypes]
                    platformTypes.forEach( platformType => {
                        mapping.platformType[platformType].forEach((platform) => {
                            data.forEach((subOrder) => {
                                if(!subOrder[platformType]){
                                    subOrder[platformType] = 0
                                }
                                subOrder[platformType] += subOrder[platform.toUpperCase()] || 0
                            })
                        })
                    })
            }
            dispatch({ type: FETCH_ZUNSOLAROPS_SALES_SUMMARY_UNIT, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchShippingSummaryOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsummary`, {
                dateFormatType: 'month',
                orderByKey: 'status',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_ORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchShippingSummaryUnitView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsummary`, {
                tableType: 'Unit',
                dateFormatType: 'month',
                orderByKey: 'status',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_UNIT, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSubOrderValueViewNew(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsubordervaluenew`, {
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE_VIEW_NEW, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchLastTenDaysSummaryOrderView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsummary`, {
                dateFormatType: 'date',
                orderByKey: 'platform',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_ORDER, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchLastTenDaysSummaryUnitView(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsummary`, {
                tableType: 'Unit',
                dateFormatType: 'date',
                orderByKey: 'platform',
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_UNIT, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchCategorySummary(filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchcategorysummary`, {
                filters
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNSOLAROPS_CATEGORY_SUMMARY, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchStateSummary(selectedCategory="Unit", filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchstatesummary`, {
                filters,
                selectedCategory
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ZUNSOLAROPS_STATE_SUMMARY, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchMapData(mapViewType = '', filters = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchmapview`, {
                filters,
                mapViewType
            });
            response = response.data;
            if (response.error) {
                alert("Some error occured");
                return;
            }
            dispatch({ type: FETCH_ZUNSOLAROPS_MAPDATA, payload: response || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchSalesSummary(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsalessummary`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let salesSummary = response.data || [];
            salesSummary = salesSummary.map((obj) => {
                obj.onlineTotal = ((obj.Amazon || 0) + (obj.Flipkart || 0) + (obj.Snapdeal || 0) + (obj.Zunsolar || 0) + (obj.Moglix || 0) + (obj["Industry Buying"] || 0));
                obj.offlineTotal = ((obj.Dealer || 0) + (obj.Distributor || 0));
                return obj;
            });
            dispatch({ type: FETCH_ZUNSOLAROPS_SALES_SUMMARY, payload: salesSummary || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function fetchShippingSummary(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchshippingsummary`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let shippingSummary = response.data;
            dispatch({ type: FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY, payload: shippingSummary || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function fetchOrderValue(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchordervalue`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let orderValue = response.data;
            dispatch({ type: FETCH_ZUNSOLAROPS_ORDER_VALUE, payload: orderValue || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function fetchSubOrderValue(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchsubordervalue`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let orderValue = response.data;
            dispatch({ type: FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE, payload: orderValue || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}


export function fetchLastTenDaysSummary(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchlasttendayssummary`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let lastTenDaysSummary = response.data || [];
            lastTenDaysSummary = lastTenDaysSummary.map((obj) => {
                obj.onlineTotal = ((obj.Amazon || 0) + (obj.Flipkart || 0) + (obj.Snapdeal || 0) + (obj.Zunsolar || 0) + (obj.Moglix || 0) + (obj["Industry Buying"] || 0)) + ((obj.Zunpulse || 0))
                obj.offlineTotal = ((obj.Dealer || 0) + (obj.Distributor || 0));
                return obj;
            })
            dispatch({ type: FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY, payload: lastTenDaysSummary || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchStateSummaryData(filters, type) {
    return async (dispatch) => {
        try {
            if(!filters.category) return;
            filters = JSON.parse(JSON.stringify(filters));
            filters.category = [ filters.category ];
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchstatesummarydata`, { filters, type });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let { data = [], fields = [] } = response;
            let columnFields = [];
            fields.forEach(field => {
                if(!field) return;
                let fieldConfig = {
                    Header: field,
                    accessor: field
                };
                if(field != 'State') {
                    fieldConfig.Header = row => {
                        let { data } = row;
                        let total = data.reduce((a, b) => a + (!isNaN(b[field]) ? parseInt(b[field]) : 0), 0);
                        return `${field} (${total.toLocaleString("en-IN")})`;
                    }
                    fieldConfig.Cell = row => (parseInt(row.value || 0)).toLocaleString("en-IN")
                }
                columnFields.push(fieldConfig);
            })
            dispatch({ type: FETCH_STATE_SUMMARY_DATA, payload: { data, fields: columnFields } });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchWeeklyOrders(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchweeklyorders`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let weeklyOrders = response.data;
            dispatch({ type: FETCH_WEEKLY_ORDERS, payload: weeklyOrders || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchWeeklyDispatchStatus(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchweeklydispatchstatus`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let weeklyDispatchStatus = response.data;
            dispatch({ type: FETCH_WEEKLY_DISPATCH_STATUS, payload: weeklyDispatchStatus || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchWeeklyDeliveredOrderStatus(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchweeklydeliveredorderstatus`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let weeklyDeliveredOrderStatus = response.data;
            dispatch({ type: FETCH_WEEKLY_DELIVERED_ORDER_STATUS, payload: weeklyDeliveredOrderStatus || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchReturnedReceivedOrderStatus(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchreturnedrecievedorderstatus`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let returnedReceivedOrderStatus = response.data;
            dispatch({ type: FETCH_RETURNED_RECEIVED_ORDER_STATUS, payload: returnedReceivedOrderStatus || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchReturningOrderStatus(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchreturningorderstatus`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let returningOrderStatus = response.data;
            dispatch({ type: FETCH_RETURNING_ORDER_STATUS, payload: returningOrderStatus || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchOrderValueNew(filters) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/opsdashboard/fetchordervaluenew`, { filters });
            response = response.data;
            if(response.error){
                alert("Some Error Ocurred");
                return;
            }
            let { data = [], columns = [] } = response;
            dispatch({ type: FETCH_ORDER_VALUE_NEW, payload: { data, columns: getColumnFields(columns) } });
        }
        catch (e) {
            console.log(e);
        }
    }
}