const url = {
  pathBackend:"https://zrm.zunroof.com:3002/",
  pathFrontend : "http://zrm.zunroof.com",
  JWT_SECRET : "SECRET2",
  mapsApiKey: "AIzaSyDIUFG3YZUl7tApKoorlOaGffWVjy5EV1U",
  googleMapsKey: "AIzaSyBkIwlJxIAo5EVayPC36sV1KHGo32bzDk8",
  apiKey:"8ALS!Fcm#iWX_IgN"
};

// const urlFrontend = {path : "http://localhost:3000"};
export const zcommerceUrl={
    couponPathBackend:"https://zrm.zunroof.com:4044/zcommerce/api/",
    productPathBackend:"https://zrm.zunroof.com:4040/zcommerce/api/"  
  }

export const appBackendUrl = {
    baseUrl: "https://us-central1-zunpulsedevapp.cloudfunctions.net/",
    apiKey: "VVyyTThghg67675757bbcYUU_###*!@@@#"
  }

export const zunpureBackend = {
    backendUrl: "https://zuntalks.in:8089/",
    authKey : "0D45rwmKnijvMfQ"
  }

export default url;

