import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import Select from 'react-select';
import { repeatSalesOfferColumnConfig } from '../../config/column_fields';
import axios from 'axios';
import url, { zcommerceUrl } from '../../config';
import { Redirect } from 'react-router-dom';
import { couponDataMapper, mainDataMapper, productDataMapper } from '../../helper/dataMapper';
import '../../styles/addCoupon.css';
import ReactTable from 'react-table';
import { fetchRepeatSalesOffersData } from '../../actions/repeatSalesActions';


const baseImageUrl = `https://d1vfpdpyv21g5i.cloudfront.net/zunpulse/products_v5/`;


class ZunpulseRepeatViewOffer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: {},
      productData: {},
      editedProductData: {},
      userObj: {},
      offerData: []
    }
  }



  componentWillMount() {
    if (localStorage.getItem("userObj")) {
      let userObj = JSON.parse(localStorage.getItem("userObj"));
      console.log(userObj);
      if ((userObj.access || []).includes("Website-Admin")) {
        // this.props.fetchAllProductData(this.props.match.params.id);
        this.props.fetchRepeatSalesOffersData()
        // this.props.fetchRepe
        // this.bannerData = bannerData;
      }
      this.setState({
        userObj
      })
    }
  }

  FieldComponent = ({ label, name, options = [], type, fieldType }) => {
    // console.log('name in edit',name);
    let { SelectInput, InputType, handleDoubleClick, state } = this;
    let { edit = {} } = state;
    edit = edit[name];
    let value = "", editValue = "";
    value = this.getValue(this.state.productData, name);
    editValue = this.state.editedProductData[name] || "";
    if (fieldType == "select") {
      return (<tr onDoubleClick={() => handleDoubleClick(name)}>
        <td>{label}</td>
        <td>{edit ? <span className="input-group">
          <SelectInput
            name={name}
            options={options}
            value={editValue}
          />
        </span> : <span>{value}</span>}
        </td>
      </tr>)
    }
    else if (fieldType == "input") {
      return (<tr onDoubleClick={() => handleDoubleClick(name)}>
        <td>{label}</td>
        <td>{edit ? <span className="input-group">
          <InputType
            name={name}
            value={editValue}
            type={type}
            placeholder={label}
          />
        </span> : <span>{value}</span>}
        </td>
      </tr>)
    }
    else if (fieldType == "image") {
      return (<tr onDoubleClick={() => handleDoubleClick(name)}>
        <td>{label}</td>
        <td>{edit ? <span className="input-group">
          <InputType
            name={name}
            type={"file"}
            placeholder={label}
          />
          <button onClick={(e) => this.onImageSaveClick(name, value)} className="btn btn-outline-info">Save</button>
        </span> : <span><a href={`${baseImageUrl}${value}`} target="_blank">View</a></span>}
        </td>
      </tr>
      )
    }
    else {
      return '';
    }
  }


  componentWillReceiveProps(props) {

    console.log('table Data', props.offerData);
    this.setState({
      offerData: props.offerData
    })
  }

  render() {

    return (
      <div className='mt-3 mx-auto' >

        <h4 style={{ fontSize: '20px', fontWeight: 'bold' }}>RepeatSales Offer's</h4>

        <div className="table-section">
          <ReactTable
            filterable={true}
            sortable={false}
            showPagination={true}
            data={this.state.offerData}
            defaultPageSize={10}
            columns={repeatSalesOfferColumnConfig}
            style={{ backgroundColor: "#fff" }}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
          />
        </div>
      </div>
    )
  }
}



function mapStateToProps(state) {
  let { offerData = [] } = state.repeatSalesStore
  return {
    offerData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSearchResult, fetchRepeatSalesOffersData }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseRepeatViewOffer);


