import React from 'react';
import { fetchAllMapping } from '../actions/fetchAllMapping';
import { getSearchResult } from '../actions/searchAction';
import { createLead, checkExistingPhone } from '../actions/createLeadAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../components/HeaderComponent';
import { connect } from 'react-redux';
import CreateLeadComponent from '../components/CreateLeadComponent';


class CreateLead extends Component {
  componentWillMount() {
    let viewList = ["stages", "employees", "saslots", "regions", "source", "stateregion", "languages", "reasonforverified", "pincodes"]
    this.props.fetchAllMapping(viewList);
  }

  render() {
    return (
      <div>
        <Header getSearchResult={this.props.getSearchResult} />
        <CreateLeadComponent allMapping={this.props.allMapping} newLead={this.props.createLead} success={this.props.createLeadSuccess} checkExistingPhone={this.props.checkExistingPhone} phoneNumbersList={this.props.phoneNumbersList} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log("state.createlead", state.createLead)
  return {

    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    createLeadSuccess: state.createLead ? state.createLead.success : {},
    phoneNumbersList: state.existingPhonesList ? state.existingPhonesList : [],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAllMapping, getSearchResult, createLead, checkExistingPhone }, dispatch)
}

function isLeadData() {
  return (
    <p>this is not found</p>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLead)