import React, { useState } from 'react';
import { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/header.css'
import url from '../config';
import axios from 'axios'
import { Link, NavLink } from 'react-router-dom';
import { UserProfileComponent } from './UserProfileComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSearchResult } from '../actions/searchAction';
import { getUserInfo } from '../actions/user';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export class HeaderComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchContent: '',
      username: '',
      userObj: {},
      userInfo: false,
      firstname: 'Shivam',
      lastname: 'Dubey',
      email: 'shivam.dubey@zunroof.com',
      access: 'bitrix',
      role: 'Super Admin',
      active: false,
      dropDownValue: 'Select action',
      dropdownOpen: false,
      solarDropDown: false,
      roofDropdown: false,
      offlineDropDown:false
    }

  }

  componentDidMount() {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    if (Object.keys(userObj).length > 0) {
      this.setState({
        userObj: userObj,
        username: userObj.username
      })
    }
  }

  toggle = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  }

  handleLogout = (e) => {

    localStorage.clear()
    window.location.href = url.pathFrontend
    // }
  }

  handleSearch = (e) => {
    // e.preventDefault();
    if (e.target.value && e.key === 'Enter') {
      let { value } = e.target

      let searchObj = {
        query: e.target.value
      }
      if ((window.location.href).includes("zunsolar")) {
        window.open(url.pathFrontend + "/zunsolar/search/" + value)
      } else if ((window.location.href).includes("zunpulse")) {
        window.open(url.pathFrontend + "/zunpulse/search/" + value)
      } else {
        window.open(url.pathFrontend + "/search/" + value)
      }
    }
  }
  handleUserInfoModal = (e) => {
    let obj = { username: this.state.username };
    this.props.getUserInfo(obj);
    this.setState({
      userInfo: true
    }, () => {
    })
  }
  handleCloseUserInfo = (e) => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  }
  handleRedirectToPayment = ()=>{
    window.open("https://operations.zunroof.com/paymentview","_blank")
    
  }

  render() {
    const userInfoStyle = {
      hide: {
        right: '-300px',
      },
      show: {
        display: '',
        right: "0px",
      }
    }
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }

    return (

      <nav className='navbar navbar-expand-lg navbar-light'>
        <a className="navbar-brand" style={{display:'flex'}}>
          <img alt="Zunroof" src={require('../assets/zunpulseLogo.svg')} width="100%" />
        {/* <p className='zrmText'>zrm</p> */}
        </a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <input className="headerInput" onKeyPress={this.handleSearch} type="text" name="search" aria-label="Search" placeholder="Search.."></input>

          <ul className="navbar-nav ml-auto headerUl">
          <li className="nav-item pulseNav" style={(this.state.userObj.access || []).includes("Zunpulse") ? style.show : style.hide}>
              {/* <NavLink className="nav-link" to="/zunpulse/all" id="Home">Pulse</NavLink> */}
              {/* <i class="fa fa-caret-down fa-lg"></i> */}
              <Dropdown className="pulseDropdown" isOpen={this.state.offlineDropDown} toggle={() => this.toggle("offlineDropDown")}>
                <DropdownToggle caret>
                  Offline
                </DropdownToggle>
                <DropdownMenu className="pulseDropdownMenu">
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/all/offline">Offline Leads</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/all/offline/retailer">Retailers</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/all/offline/distributor">Onboarded Distributors</NavLink></DropdownItem>
        
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/pi/generate">Generate PI</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/pi/all">PI View</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/orders/invoices">Order Invoices</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink  to="/" onClick={this.handleRedirectToPayment}>Payment</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/mt/home">MT Home</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/pi/bulkInvoices">Bulk Invoices</NavLink></DropdownItem>



                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item pulseNav">
              {/* <NavLink target="_blank" className="nav-link" to="/lead/all" id="Home">Roof</NavLink> */}
              <Dropdown className="pulseDropdown" isOpen={this.state.roofDropdown} toggle={() => this.toggle("roofDropdown")}>
                <DropdownToggle caret>
                  Roof
                </DropdownToggle>
                <DropdownMenu className="pulseDropdownMenu">
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/lead/all">All Leads View</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/saview">SA View</NavLink></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item pulseNav" style={(this.state.userObj.access || []).includes("Zunpulse") ? style.show : style.hide}>
              {/* <NavLink className="nav-link" to="/zunpulse/all" id="Home">Pulse</NavLink> */}
              {/* <i class="fa fa-caret-down fa-lg"></i> */}
              <Dropdown className="pulseDropdown" isOpen={this.state.dropdownOpen} toggle={() => this.toggle("dropdownOpen")}>
                <DropdownToggle caret>
                  Pulse
                </DropdownToggle>
                <DropdownMenu className="pulseDropdownMenu">
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunpulse/all">All Leads View</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunpulse/leadtransfer">Lead Transfer</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunpulse/orders">Order tracker</NavLink></DropdownItem>
                  {
                    (this.state.userObj.access || []).includes('Zunpulse-Admin') ?
                    <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunpulse/orders/invoices">Orders Invoices</NavLink></DropdownItem>
                    : null
                  }
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunpulse/callcount">Call Count</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunpulse/opsdashboard">Ops Dashboard</NavLink></DropdownItem>
                  { this.state.userObj.role == "Super Admin" ? 
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunpulse/admin/products">Admin Portal</NavLink></DropdownItem> : "" }
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/warrantyDetails">Warranty Details</NavLink></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item pulseNav" style={(this.state.userObj.access || []).includes("Zunsolar") ? style.show : style.hide}>
              {/* <NavLink className="nav-link" to="/zunpulse/all" id="Home">Pulse</NavLink> */}
              {/* <i class="fa fa-caret-down fa-lg"></i> */}
              <Dropdown className="pulseDropdown" isOpen={this.state.solarDropDown} toggle={() => this.toggle("solarDropDown")}>
                <DropdownToggle caret>
                  Solar
                </DropdownToggle>
                  <DropdownMenu className="pulseDropdownMenu">
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/orders">Order tracker</NavLink></DropdownItem>
                  {
                    (this.state.userObj.role == "Manager") ?
                    <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/orders/invoices">Orders Invoices</NavLink></DropdownItem>
                    : null
                  }
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/inventory">Inventory</NavLink></DropdownItem>
                  {/* <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/opsdashboard">Ops dashboard</NavLink></DropdownItem> */}
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/vendorlocation">Vendor location</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/addvendor">Add vendor</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/combo">Combos</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/all">All Leads View</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/leadtransfer">Lead Transfer</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/callcount">Call Count</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/opsdashboard">Ops Dashbaord</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/pi/generate">Generate PI</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/pi/all">PI Portal</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/zunsolar/orders/invoices">Order Invoices</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink  to='/' onClick={this.handleRedirectToPayment} >Payment Portal</NavLink></DropdownItem>
                  <DropdownItem className="dropItem"><NavLink target="_blank" to="/returnOrders">Return Orders</NavLink></DropdownItem>


                </DropdownMenu>
              </Dropdown>
            </li>
            {/* <li className="nav-item" style={(this.state.userObj.access || []).includes("Zunsolar") ? style.show : style.hide}>
              <NavLink className="nav-link" to="/zunsolar/all" id="Home">Solar</NavLink>
            </li> */}
            <li className="nav-item navItem">
              <NavLink target="_blank" to="/userroles" className="nav-link" id="Access">UserRoles</NavLink>
            </li>
            {/* <div className="dropdown-content">
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div> */}
          </ul>


          <div onClick={(e) => { this.handleUserInfoModal(e) }} className="dropdown headerDropdown" style={this.state.username ? style.show : style.hide}>
            <img src={require('../assets/person.svg')} alt="icon" />
            <a className="dropbtn">{this.state.username ? this.state.username.split('@')[0].split('.')[0] + ' ' + (this.state.username.split('@')[0].split('.')[1] || "") : ''}</a>
            {/* <div className="dropdown-content">
              <Link onClick={(e) => { this.handleUserInfoModal(e) }}>Profile</Link>
              <Link onClick={(e) => { this.handleLogout(e) }}>Logout</Link>
            </div> */}
          </div>
        </div>
        <div class="sidePanelComponent" style={this.state.userInfo ? userInfoStyle.show : userInfoStyle.hide}>
          <div className="closeUserInfo">
            <i class="fas fa-times fa-lg" onClick={(e) => { this.handleCloseUserInfo(e) }} style={{ cursor: "pointer" }}></i>
          </div>
          <div className="container-fluid parentDiv">
            <div style={{ width: '100%' }}>
              <img class="userImg" src={require('../assets/userDefault.png')} width="70"></img>
              <div class="circle"></div>
              <p className="online">online</p>
            </div>

            <h4 class="heading">User Info</h4>
            <hr></hr>
            <div className="infoDiv">
              <div className="individual">
                <p class="fieldName">First name</p>
                {/* {console.log(this.props.userInfo)} */}
                <p>{this.props.userInfo.fistname}</p>
              </div>
              <div className="individual">
                <p className="fieldName">Last name</p>
                <p>{this.props.userInfo.lastname}</p>
              </div>
              <div className="email">
                <p className="fieldName">Email</p>
                <p>{this.props.userInfo.email}</p>
              </div>
              <div className="individual">
                <p className="fieldName">Role</p>
                <p>{this.props.userInfo.role ? this.props.userInfo.role : 'None'}</p>
              </div>
              {/* <div className="individual">
                <p className="fieldName">Access</p>
                <p>{this.state.access}</p>
              </div> */}
            </div>
          </div>
          <div className="logoutDiv">
            <Link className="logoutBtn" onClick={(e) => { this.handleLogout(e) }}><i class="fa fa-sign-out" aria-hidden="true"></i>  Logout </Link>
          </div>
        </div>
      </nav >
    )
  }
}

function mapStateToProps(state) {
  // console.log("state.createlead", state)
  return {
    userInfo: state.userInfo ? state.userInfo : {}
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSearchResult, getUserInfo }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)