import React from 'react';
import '../styles/followupDetailView.css'
import moment from 'moment'

export const FollowUpDetailView = ({ leadData = {}, edit = true, handleEditClick, editedLeadData = {}, handleOnChange, handleCancelClick, handleSaveClick, stateRegionMap = {}, prospectMapping, dropReasonMapping, comments = [], handleAllLeadClick, commentOptions = [], handleSubmitCommentClick }) => {
    console.log(leadData)
    return (
        <div className='followUpDetailView'>
            <div className='mb-3'>
                <button onClick={handleAllLeadClick} className='btn btn-light' style={{ float: 'right' }}>All Leads</button>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div class="details-container">
                <div class="details-header">
                    <h1>#{leadData.BITRIX_ID}</h1>
                    {!edit ? <button onClick={handleEditClick} className='btn btn-outline-info'>Edit</button> : ""}
                    {edit ? <button onClick={handleCancelClick} className='btn btn-outline-info'>Cancel</button> : ""}
                    {edit ? <button onClick={handleSaveClick} className='btn btn-outline-info'>Save</button> : ""}
                </div>
                <ul class="details-list">
                    <li class="details-item">
                        <h2>Name</h2>
                        {!edit ? <p>{leadData.TITLE}</p> : ""}
                        {edit ? <div>
                            <input className='form-control' type='text' value={editedLeadData.TITLE} name='TITLE' onChange={handleOnChange} />
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>Phone</h2>
                        {!edit ? <p>{leadData.PHONE_VALUE}</p> : ""}
                        {edit ? <div>
                            <input className='form-control' type='number' value={editedLeadData.PHONE_VALUE} name='PHONE_VALUE' onChange={handleOnChange} />
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>Quote Id</h2>
                        {!edit ? <p>{leadData.QUOTE_ID}</p> : ""}
                        {edit ? <div>
                            <input className='form-control' type='text' value={editedLeadData.QUOTE_ID} name='QUOTE_ID' onChange={handleOnChange} />
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>NAD</h2>
                        {!edit ? <p>{leadData.NEXT_ACTION_DATE && moment(leadData.NEXT_ACTION_DATE, "YYYYMMDD", true).isValid() ? moment(leadData.NEXT_ACTION_DATE, "YYYYMMDD", true).format("DD MMM YYYY") : ""}</p> : ""}
                        {edit ? <div>
                            <input className='form-control' type='date' value={editedLeadData.NEXT_ACTION_DATE && moment(editedLeadData.NEXT_ACTION_DATE, "YYYYMMDD", true).isValid() ? moment(editedLeadData.NEXT_ACTION_DATE, "YYYYMMDD", true).format("YYYY-MM-DD") : ""} name='NEXT_ACTION_DATE' onChange={handleOnChange} />
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>Size</h2>
                        {!edit ? <p>{leadData.systemSize}</p> : ""}
                        {edit ? <div>
                            <input className='form-control' type='number' value={editedLeadData.systemSize} name='systemSize' onChange={handleOnChange} />
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>State</h2>
                        {!edit ? <p>{leadData.STATE}</p> : ""}
                        {edit ? <div>
                            <select name='STATE' className='form-control' onChange={handleOnChange} value={editedLeadData.STATE}>
                                <option>Select</option>
                                {Object.keys(stateRegionMap).map(state => <option value={state}>{state}</option>)}
                            </select>
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>City</h2>
                        {!edit ? <p>{leadData.ADDRESS_CITY}</p> : ""}
                        {edit ? <div>
                            <select name='ADDRESS_CITY' className='form-control' onChange={handleOnChange} value={editedLeadData.ADDRESS_CITY}>
                                <option>Select</option>
                                {editedLeadData.STATE && stateRegionMap[editedLeadData.STATE] ? stateRegionMap[editedLeadData.STATE].map(city => <option value={city}>{city}</option>) : ""}
                            </select>
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>Address</h2>
                        {!edit ? <p>{leadData.ADDRESS}</p> : ""}
                        {edit ? <div>
                            <input className='form-control' type='text' value={editedLeadData.ADDRESS} name='ADDRESS' onChange={handleOnChange} />
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>Email</h2>
                        {!edit ? <p>{leadData.EMAIL_VALUE}</p> : ""}
                        {edit ? <div>
                            <input className='form-control' type='text' value={editedLeadData.EMAIL_VALUE} name='EMAIL_VALUE' onChange={handleOnChange} />
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>Propsect</h2>
                        {!edit ? <p>{leadData.PROSPECT}</p> : ""}
                        {edit ? <div>
                            <select name='PROSPECT' className='form-control' onChange={handleOnChange} value={editedLeadData.PROSPECT}>
                                <option>Select</option>
                                {prospectMapping.map(val => <option value={val}>{val}</option>)}
                            </select>
                        </div> : ""}
                    </li>
                    <li class="details-item">
                        <h2>Active</h2>
                        {!edit ? <p>{leadData.ACTIVE}</p> : ""}
                        {edit ? <div>
                            <select name='ACTIVE' className='form-control' onChange={handleOnChange} value={editedLeadData.ACTIVE}>
                                <option>Select</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                            </select>
                        </div> : ""}
                    </li>
                    {edit ? <li class="details-item">
                        <h2>Select drop off reason</h2>
                        <div>
                            <select name='dropReasonSelect' className='form-control' onChange={handleOnChange} value={editedLeadData.dropReasonSelect}>
                                <option>Select</option>
                                {dropReasonMapping.map(reason => <option value={reason}>{reason}</option>)}
                            </select>
                        </div>
                    </li> : ""}
                    {edit ? <li class="details-item">
                        <h2>Drop off reason</h2>
                        <div>
                            <input className='form-control' type='text' value={editedLeadData.dropOffReason} name='dropOffReason' onChange={handleOnChange} />
                        </div>
                    </li> : ""}
                    <li class="details-item">
                        <h2>Select Comment</h2>
                        <div>
                            <select name='commentSelect' className='form-control' onChange={handleOnChange} value={editedLeadData.commentSelect}>
                                <option>Select</option>
                                {commentOptions.map(reason => <option value={reason}>{reason}</option>)}
                            </select>
                        </div>
                    </li>
                    <li class="details-item">
                        <h2>Comment</h2>
                        <div>
                            <input className='form-control' type='text' value={editedLeadData.nextActionComment} name='nextActionComment' onChange={handleOnChange} />
                        </div>
                    </li>
                    { !edit && editedLeadData.nextActionComment ? <li class="details-item">
                        <h2></h2>
                        <div><button className='btn btn-outline-success' onClick={handleSubmitCommentClick}>Submit Comment</button></div>
                    </li> : "" }
                </ul>
            </div>
            <div className="comments-container mt-3">
                <div className="comments-header">
                    <h1>Comments</h1>
                </div>
                <ul className="comments-list">
                    {
                        comments.map(comment => <li className="comment-item">
                            <p><b>{comment.commentedBy}</b>: {comment.commentBody}<span className="timestamp">- {moment(+comment.timestamp).format('DD MMM YYYY HH:mm:ss')}</span></p>
                        </li>)
                    }
                </ul>
            </div>
        </div>
    )
}