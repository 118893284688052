import React from 'react';
import { fetchAllLeads } from '../../actions/fetchAllLeads';
import { fetchMappingZunpulse, fetchAllMapping, fetchMappingZunsolar } from '../../actions/fetchAllMapping';
import { getSearchResult } from '../../actions/searchAction';
import { editBulkLeads } from '../../actions/editMultipleLeadsAction'
import { getUserInfo } from '../../actions/user'
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import AllLeadView from '../../components/zunsolar/AllLeadView';
import PropTypes from 'prop-types';
import { ErrorComponent } from '../../components/ErrorComponent';
import { Loader } from '../../components/loader'
import AllMappedDealersView from '../../components/zunsolar/AllMappedDealersView';

class AllMappedDealers extends Component {
  componentWillMount() {
    let viewList = ["employees"]
    let distributorId = this.props.match.params.distributorId;
    this.props.fetchAllMapping(viewList);
    this.props.fetchAllLeads(100, 1, [], undefined, 'zunsolar', false , distributorId );
    this.props.fetchMappingZunsolar();

    // this.props.getSearchResult()
  }

  render() {
    if (this.props.error) {
      return (
        <div>
          <ErrorComponent error={this.props.error} />
        </div>
      )
    }

    return (
      <div>
        <Header />
        <AllMappedDealersView allLeads={this.props.allLeads} allMapping={this.props.allMapping} fetchAllLeads={this.props.fetchAllLeads} searchData={this.props.searchData} editBulkLeads={this.props.editBulkLeads} distributorId={this.props.match.params.distributorId} />
      </div>
    )


  }
}


function mapStateToProps(state) {
  // console.log("searchData", state)

  return {
    allLeads: state.allFetchLead ? state.allFetchLead : {},
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    searchData: [],
    error: state.allFetchLead.error ? state.allFetchLead.error : undefined

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ editBulkLeads, fetchAllLeads, fetchAllMapping, fetchMappingZunpulse, getSearchResult, getUserInfo, fetchMappingZunsolar }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AllMappedDealers);