import React, { Component } from "react";
import '../styles/paymentSummary.css'
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.css';
import HeaderComponent from "../HeaderComponent";

class PaymentSummaryMobile extends Component {

    constructor(props) {
        super(props)

        this.state = {
            totalAmount: 0,
            mtVisitDetails: {}
        }
    }

    componentWillReceiveProps(props) {

        let { mtVisitDetails = {}, mtPaymentHistory } = props;
        let totalAmount = 0;

        mtPaymentHistory.forEach((data) => { totalAmount += parseInt(data.amount) })

        if (props.searchData.length > 0) {
            this.setState({
                count: props.searchData.length,
            }, () => { })

        } if (Object.keys(props.allLeads).length > 0 && Object.keys(props.allMapping).length > 0) {

            this.setState({
                count: props.allLeads.count || "",
                mtVisitDetails,
                totalAmount: totalAmount
            })
        }

    }

    handleCreatePaymentRedirect = () => {
        window.open(window.location.origin + "/mt/createPayment", "_self")
    }

    handlePaymentViewRedirect = () => {
        window.open(window.location.origin + "/mt/paymentView", "_self")
    }

    handleVisitSearchRedirect = () => {
        window.open(window.location.origin + "/mt/visitSearch", "_self")
    }

    handleAddDealerRedirect = () => {
        window.open(window.location.origin + "/mt/createlead", "_self")
    }

    render() {

        const { mtVisitDetails } = this.state

        return (
            <>
                <HeaderComponent />

                <div className="mainContainerPayment">
                    <hr></hr>

                    <div className="secondContainer">
                        <h2>Summary</h2>

                        <div className="summaryVisit">
                            <div className="todayVisit">
                                <p>Today's Visits</p>
                                <p className="count">{mtVisitDetails?.today || 0}</p>
                            </div>

                            <div className="left-border-ctn"></div>
                            <div className="weeklyVisit">
                                <p>This week's Visits</p>
                                <p className="count">{mtVisitDetails?.week || 0}</p>
                            </div>

                        </div>
                    </div>

                    <hr></hr>

                    <div className="thirdContainer">
                        <h2>Payments</h2>

                        <div className="addNew">

                            <div className="paymentContainer">
                                <div className="addPayment" onClick={(e) => { this.handleCreatePaymentRedirect() }}>
                                    <i class="fa fa-plus"></i>
                                    <p>Add New</p>
                                </div>

                                <div className="addPayment" onClick={(e) => { this.handlePaymentViewRedirect() }}>
                                    <i class="fa fa-history"></i>
                                    <p>History</p>
                                </div>
                                <div>

                                </div>
                            </div>

                            <div className="datePayment">
                                <p>Total Amount</p>
                                <p>{this.state.totalAmount}</p>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="fourthContainer">
                        <h2>Visits</h2>

                        <div className="dealerSearchContainer">
                            <div className="paymentContainer">
                                <div className="addPayment" onClick={(e) => { this.handleAddDealerRedirect(e) }}>
                                    <i class="fa fa-plus"></i>
                                    <p>Add Retailer</p>

                                </div>
                                <div className="addPayment" onClick={(e) => { this.handleVisitSearchRedirect(e) }}>
                                    <i class="fa fa-search"></i>
                                    <p>All Leads</p>
                                </div>
                                <div>

                                </div>
                            </div>
                            <div className="datePayment">
                                <p>Total Visit</p>
                                <p>{mtVisitDetails?.total || 0}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default PaymentSummaryMobile;