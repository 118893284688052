import { FETCH_ZUNPULSEOPS_ORDERS_DATA, FETCH_ZUNPULSEOPS_SALES_SUMMARY, FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY, FETCH_ZUNPULSEOPS_ORDER_VALUE, FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY, FETCH_LATEST_EDITED_ZUNPULSE_ORDER, FETCH_METRICS_SUMMARY, FETCH_ZUNPULSEOPS_SALES_SUMMARY_SUBORDER, FETCH_ZUNPULSEOPS_SALES_SUMMARY_UNIT, FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_SUBORDER, FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_UNIT, FETCH_ZUNPULSEOPS_ORDER_VALUE_VIEW, FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_SUBORDER, FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_UNIT, FETCH_ZUNPULSEOPS_MAPPING, FETCH_ZUNPULSEOPS_CATEGORY_SUMMARY, FETCH_ZUNPULSEOPS_STATE_SUMMARY, FETCH_ZUNPULSEOPS_MAPDATA, FETCH_ZUNPULSEOPS_ORDER_SUMMARY, FETCH_ZUNPULSEOPS_SALES_SUMMARY_ORDER, FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_ORDER, FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_ORDER } from '../actions/actiontypes';
export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ZUNPULSEOPS_ORDERS_DATA: {
            return { ...state, ordersData: [...action.payload], received: true };
        }
        case FETCH_ZUNPULSEOPS_SALES_SUMMARY: {
            return { ...state, salesSummary: [...action.payload] };
        }
        case FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY: {
            return { ...state, shippingSummary: [...action.payload] };
        }
        case FETCH_ZUNPULSEOPS_ORDER_VALUE: {
            return { ...state, orderValue: [...action.payload] };
        }
        case FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY: {
            return { ...state, lastTenDaysSummary: [...action.payload] };
        }
        case FETCH_LATEST_EDITED_ZUNPULSE_ORDER: {
            return { ...state, lastEdited: action.payload };
        }
        case FETCH_METRICS_SUMMARY: {
            return { ...state, metricsSummary: action.payload, metricsSummaryReceived: true  }
        }
        case FETCH_ZUNPULSEOPS_SALES_SUMMARY_SUBORDER: {
            return { ...state, salesSummarySubOrder: [ ...(action.payload.data || []) ], salesSummarySubOrderColumns: [ ...(action.payload.columns || []) ], salesSummarySubOrderReceived: true  }
        }
        case FETCH_ZUNPULSEOPS_SALES_SUMMARY_UNIT: {
            return { ...state, salesSummaryUnit: [ ...(action.payload.data || []) ], salesSummaryUnitColumns: [ ...(action.payload.columns || []) ], salesSummaryUnitReceived: true }
        }
        case FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_SUBORDER: {
            return { ...state, shippingSummarySubOrder: [ ...(action.payload.data || []) ], shippingSummarySubOrderColumns: [ ...(action.payload.columns || []) ], shippingSummarySubOrderReceived: true  }
        }
        case FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_UNIT: {
            return { ...state, shippingSummaryUnit: [ ...(action.payload.data || []) ], shippingSummaryUnitColumns: [ ...(action.payload.columns || []) ], shippingSummaryUnitReceived: true }
        }
        case FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_SUBORDER: {
            return { ...state, lastTenDaysSummarySubOrder: [ ...(action.payload.data || []) ], lastTenDaysSummarySubOrderColumns: [ ...(action.payload.columns || []) ], lastTenDaysSummarySubOrderReceived: true  }
        }
        case FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_UNIT: {
            return { ...state, lastTenDaysSummaryUnit: [ ...(action.payload.data || []) ], lastTenDaysSummaryUnitColumns: [ ...(action.payload.columns || []) ], lastTenDaysSummaryUnitReceived: true }
        }
        case FETCH_ZUNPULSEOPS_ORDER_VALUE_VIEW: {
            return { ...state, orderValueView: [ ...(action.payload.data || []) ], orderValueViewColumns: [ ...(action.payload.columns || []) ], orderValueViewReceived: true }
        }
        case FETCH_ZUNPULSEOPS_MAPPING: {
            return { ...state, mapping: action.payload, mappingReceived: true };
        }
        case FETCH_ZUNPULSEOPS_CATEGORY_SUMMARY: {
            return { ...state, categorySummary: [ ...(action.payload.data || []) ], categorySummaryColumns: [ ...(action.payload.columns || []) ], categorySummaryReceived: true  }
        }
        case FETCH_ZUNPULSEOPS_STATE_SUMMARY: {
            return { ...state, stateSummary: [ ...(action.payload.data || []) ], stateSummaryColumns: [ ...(action.payload.columns || []) ], stateSummaryReceived: true  }
        }
        case FETCH_ZUNPULSEOPS_MAPDATA: {
            return { ...state, mapData: [ ...action.payload ], mapDataReceived: true }
        }
        case FETCH_ZUNPULSEOPS_SALES_SUMMARY_ORDER: {
            return { ...state, salesSummaryOrder: [ ...(action.payload.data || []) ], salesSummaryOrderColumns: [ ...(action.payload.columns || []) ], salesSummaryOrderReceived: true  }
        }
        case FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_ORDER: {
            return { ...state, lastTenDaysSummaryOrder: [ ...(action.payload.data || []) ], lastTenDaysSummaryOrderColumns: [ ...(action.payload.columns || []) ], lastTenDaysSummaryOrderReceived: true  }
        }
        case FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_ORDER: {
            return { ...state, shippingSummaryOrder: [ ...(action.payload.data || []) ], shippingSummaryOrderColumns: [ ...(action.payload.columns || []) ], shippingSummaryOrderReceived: true  }
        }
        default:
            return state;
    }
}