import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import Select from 'react-select';
import { fetchLogisticsData, fetchMapping, updateLogisticsData, downloadFile } from '../actions/logisticsTrackerActions';
import moment from 'moment';
import Header from '../components/HeaderComponent';
import { getSearchResult } from '../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../components/loader';

class Logistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allData: [],
            loading: true,
            received: false,
            mapping: {},
            editFlag: {},
            editedData: {},
            uploadedFiles: {},
            showFilter: false,
            filters: {},
            viewData: {}
        };
    }

    componentWillMount() {
        this.props.fetchLogisticsData();
        this.props.fetchMapping();
    }

    componentWillReceiveProps(props) {
        let { logisticsData, logisticsDataReceived = false, mapping = {} } = props;
        console.log("mapping props", mapping);
        this.setState({
            loading: false,
            received: true,
            allData: logisticsData,
            logisticsDataReceived,
            mapping,
            viewData: logisticsData
        })
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    inputType = (name, placeholder, type, onChange, value) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={"End Date"} onChange={(e) => onChange(e)} />
                </div>
            </div>
        )
    }

    handleEditClick = (row) => {
        let { id } = row;
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                [id]: true
            },
            editedData: {
                ...this.state.editedData,
                [id]: row
            }
        })
    }

    handleSaveClick = (row) => {
        let { id } = row.original;
        let { editedData, allData, uploadedFiles } = this.state;
        let newData = editedData[id] || {};
        console.log("oldData", row.original)
        console.log("newData", newData)
        console.log(row);
        this.props.updateLogisticsData(id, row.original, newData, allData, uploadedFiles);
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                [id]: false
            }
        })
    }

    handleCancelClick = (row) => {
        let { id } = row.original;
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                [id]: false
            }
        })
    }

    InputTag = (name, value, type, row) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e, row)} />
    }

    SelectTag = (name, value, options, row) => {
        return <select
            onChange={(e) => { this.handleInputChange(e, row) }}
            className="form-control" id="sel1" name={name} value={value}
        >
            <option>not selected</option>
            {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
    }

    handleInputChange = (e, row) => {
        let { name, value, files, type } = e.target;
        console.log(name, value, files, type);
        if (type == "file") {
            this.setState({
                uploadedFiles: {
                    ...this.state.uploadedFiles,
                    [name]: files[0]
                }
            })
            return;
        }
        let { id } = row.original;
        let { editedData } = this.state;
        editedData = JSON.parse(JSON.stringify(editedData))
        editedData[id][name] = value;
        this.setState({
            editedData
        })
    }

    renderInputCell = (row) => {
        let { id, type } = row.column;
        let { editFlag, editedData } = this.state;
        return (!editFlag[row.original.id] ? row.value : this.InputTag(id, editedData[row.original.id][id], type, row))
    }

    renderFileInputCell = (row) => {
        let { id, type } = row.column;
        let { editFlag, editedData } = this.state;
        return (!editFlag[row.original.id] ? this.downloadFileButton(row) : this.InputTag(id, '', type, row))
    }

    downloadFileButton = (row) => {
        return row.value ? <button className="btn btn-info btn-sm" style={{ marginRight: '3px' }} onClick={(e) => this.downloadFile(row)}>Download</button> : ""
    }

    downloadFile = (row) => {
        this.props.downloadFile(row.column.id, row.value);
    }

    renderSelectCell = (row) => {
        let { id, options } = row.column;
        let { editFlag, editedData } = this.state;
        return (!editFlag[row.original.id] ? row.value : this.SelectTag(id, editedData[row.original.id][id], options, row))
    }

    handleInputChangeFilters = (e) => {
        let { name, value } = e.target;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    multiSelectType = (name, options, placeholder, onChange, value) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    onApplyFilter = () => {
        let { filters, allData } = this.state;
        let { startDate = "", endDate = "", dispatchStartDate = "", dispatchEndDate = "", deliveryStartDate = "", deliveryEndDate = "", status = [], mode = [], responsiblePerson = [], channel = [], dealerId = "" } = filters;
        let viewData = allData.filter(row => {
            return (
                (!startDate ? true : row.invoiceDate >= startDate) &&
                (!endDate ? true : row.invoiceDate <= endDate) &&
                (!dispatchStartDate ? true : row.dispatchDate >= dispatchStartDate) &&
                (!dispatchEndDate ? true : row.dispatchDate <= dispatchEndDate) &&
                (!deliveryStartDate ? true : row.deliveryDate >= deliveryStartDate) &&
                (!deliveryEndDate ? true : row.deliveryDate <= deliveryEndDate) &&
                (!status.length ? true : status.includes(row.status)) &&
                (!mode.length ? true : mode.includes(row.mode)) &&
                (!responsiblePerson.length ? true : responsiblePerson.includes(row.responsiblePerson)) &&
                (!channel.length ? true : channel.includes(row.channel)) &&
                (!dealerId ? true : row.dealerId == dealerId)
            )
        })
        this.setState({
            viewData
        });
        return;
    }

    render() {

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        let { renderInputCell, renderSelectCell, renderFileInputCell } = this;
        let { logisticsDataReceived, editFlag, mapping, viewData } = this.state;

        const responsiblePersonOptions = (mapping.responsiblePerson || []).map(row => ({ label: row, value: row }));
        const channelOptions = (mapping.channel || []).map(row => ({ label: row, value: row }));
        const modeOptions = (mapping.mode || []).map(row => ({ label: row, value: row }));
        const statusOptions = (mapping.status || []).map(row => ({ label: row, value: row }));

        const columnsList = [
            {
                Header: "Edit",
                Cell: row => {
                    return (
                        editFlag[row.original.id] ? <div className="row"><button className="btn btn-success btn-sm col-md-6" onClick={(e) => this.handleSaveClick(row)}>Save</button><button className="btn btn-light btn-sm col-md-6" onClick={(e) => this.handleCancelClick(row)}>Cancel</button></div> : <button className="btn btn-info btn-sm" onClick={(e) => this.handleEditClick(row.original)}>Edit</button>
                    )
                }
            },
            {
                Header: 'Invoice No',
                accessor: 'invoiceNumber',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'Pi No',
                accessor: 'piNumber'
            },
            {
                Header: 'Invoice Date',
                accessor: 'invoiceDate'
            },
            {
                Header: 'Invoice Value',
                accessor: 'invoiceValue'
            },
            {
                Header: 'Channel',
                accessor: 'channel'
            },
            {
                Header: 'Mode',
                accessor: 'mode',
                options: mapping.mode,
                Cell: renderSelectCell
            },
            {
                Header: 'Dealer Id',
                accessor: 'dealerId'
            },
            {
                Header: 'Dealer Name',
                accessor: 'dealerName'
            },
            {
                Header: 'Dealer City',
                accessor: 'dealerCity'
            },
            {
                Header: 'Dealer Pincode',
                accessor: 'dealerPincode'
            },
            {
                Header: 'Distance (in kms)',
                accessor: 'distance'
            },
            {
                Header: 'Dispatch Date',
                accessor: 'dispatchDate',
                Cell: renderInputCell,
                type: 'date'
            },
            {
                Header: 'Tentative Weight (in kgs)',
                accessor: 'tentativeWeight',
                Cell: renderInputCell,
                type: 'number'
            },
            {
                Header: 'Shipping Charges',
                accessor: 'shippingCharges',
                Cell: renderInputCell,
                type: 'number'
            },
            {
                Header: 'Logistics %',
                Cell: row => {
                    return row.original.shippingCharges && row.original.invoiceValue ? parseFloat((+row.original.shippingCharges + +(row.original.extraCharges || 0)) / +row.original.invoiceValue * 100).toFixed(2) : '';
                }
            },
            {
                Header: 'Tentative Delivery Date',
                accessor: 'tentativeDeliveryDate',
                Cell: renderInputCell,
                type: 'date'
            },
            {
                Header: 'Responsible Person',
                accessor: 'responsiblePerson',
                options: mapping.responsiblePerson,
                Cell: renderSelectCell
            },
            {
                Header: 'Logistics Partner',
                accessor: 'logisticsPartner',
                options: mapping.logisticsPartner,
                Cell: renderSelectCell
            },
            {
                Header: 'Approved',
                accessor: 'approvalStatus',
                options: mapping.approvalStatus,
                Cell: renderSelectCell
            },
            {
                Header: 'Status',
                accessor: 'status',
                options: mapping.status,
                Cell: renderSelectCell
            },
            {
                Header: 'AWB',
                accessor: 'awb',
                Cell: renderInputCell,
                type: 'text'
            },
            {
                Header: 'Delivery Date',
                accessor: 'deliveryDate',
                Cell: renderInputCell,
                type: 'date'
            },
            {
                Header: 'POD File',
                accessor: 'podPath',
                Cell: renderFileInputCell,
                type: 'file'
            },
            {
                Header: 'E-way bill',
                accessor: 'ewayBill',
                Cell: renderFileInputCell,
                type: 'file'
            },
            {
                Header: 'Invoice File',
                accessor: 'invoiceFile',
                Cell: renderFileInputCell,
                type: 'file'
            },
            {
                Header: 'Payment Status',
                accessor: 'paymentStatus',
                options: mapping.paymentStatus,
                Cell: renderSelectCell
            },
            {
                Header: 'Extra Charges',
                accessor: 'extraCharges',
                Cell: renderInputCell,
                type: 'number'
            },
            {
                Header: 'Reason',
                accessor: 'reason',
                Cell: renderInputCell,
                type: 'text'
            }
        ]

        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <div className="topbar-section allLeadViewFilters">
                        <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                        <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                        <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                            <div className="col-md-2">
                                {this.inputType("startDate", "Start Date", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("endDate", "End Date", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("dispathStartDate", "Dispatch Date Start", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("dispatchEndDate", "Dispatch Date End", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("deliveryStartDate", "Delivery Date Start", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("deliveryEndDate", "Delivery Date End", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.multiSelectType("responsiblePerson", responsiblePersonOptions, "Responsible Person", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.multiSelectType("channel", channelOptions, "Channel", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.multiSelectType("mode", modeOptions, "Mode", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.multiSelectType("status", statusOptions, "Status", this.handleSelectChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("dealerId", "Dealer Id", "text", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                            </div>
                        </div>
                    </div>
                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={viewData}
                        columns={columnsList}
                        defaultSorted={[{ id: "date", desc: true }]}
                        style={{ height: "90vh", textAlign: 'center' }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        loading={!logisticsDataReceived}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}



function mapStateToProps({ logisticsTracker }) {
    let { logisticsData = [], logisticsDataReceived = false, mapping = {} } = logisticsTracker || {};
    return {
        logisticsData,
        logisticsDataReceived,
        mapping
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchLogisticsData, getSearchResult, fetchMapping, updateLogisticsData, downloadFile }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Logistics);