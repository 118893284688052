import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import '../../styles/reactTable.css'
import '../../styles/allLeadsView.css';
import { Link } from 'react-router-dom';
import Select, { NonceProvider } from 'react-select';
import moment from 'moment';
import swal from 'sweetalert';
import 'react-dates/initialize';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Loader } from '../loader'


class AllLeadView extends Component {

  constructor(props) {

    super(props);
    this.reactTable = React.createRef();
    this.state = {
      stages: [],
      responsible: [],
      source: [],
      regions: [],
      statesArr: [],
      type: [],
      city: [],
      updatedByOptions: [],
      highValue: [{ value: 0, label: 0 }, { value: 1, label: 1 }],
      languages: [{ value: "English", label: "English" }, { value: "Hindi", label: "Hindi" }, { value: "Telugu", label: "Telugu" }, { value: "Gujarati", label: "Gujarati" }, { value: "Kannada", label: "Kannada" }],
      active: [],
      modified: [{ value: "yes", label: "yes" }, { value: "no", label: "no" }],
      yesNo : [{ label: 'Not Selected', value: '' }, { label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }],
      preSalesMessageType : [
        { label : 'Not Selected', value : '' },
        // { label : 'Pincode Message', value : 'pincodeMessage' },
        { label : 'Out Of Service Area', value : 'oosaMessage' },
        // { label : 'Bill Message', value : 'billMessage' },
        { label : 'Low Bill', value : 'lowBillMessage' },
        // { label : 'Rooftop Message', value : 'rooftopMessage' },
        { label : 'No Ownership', value : 'noRightsMessage' },
        { label : 'SA Date Message', value : 'siteAssessmentMessage' },
        { label : 'Name Message', value : 'nameMessage' },
        { label : 'Email Message', value : 'emailMessage' },
        { label : 'FAQ Message', value : 'faqMessage' },
      ],
      postSalesMessageType : [
        { label : 'Not Selected', value : '' },
        { label : 'QuoteId Selection', value : 'multipleQuoteIdsMessage' },
        { label : 'Menu Message', value : 'runningCustomerMessage' },
        { label : 'Project Status', value : 'projectStatusMessage' },
        { label : 'Payment Status', value : 'paymentStatusMessage' },
        { label : 'Cost Structure', value : 'costStructureMessage' },
        { label : 'Zun Pay Message', value : 'zunpayLinkMessage' },
        { label : 'No Ownership', value : 'noRightsMessage' },
        { label : 'SA Date Message', value : 'siteAssessmentMessage' },
        { label : 'Name Message', value : 'nameMessage' },
        { label : 'Email Message', value : 'emailMessage' },
        { label : 'FAQ Message', value : 'faqMessage' },
      ],
      allLeadData: [],
      tableData: [],
      mappingData: {},
      limit: 100,
      limitoption: [
        {value: 500, label:'500'},
        {value: 1000, label:'1000'},
        {value: 5000, label: '5000'}
      ],
      page: 1,
      showFilter: false,
      filterMap: {
        createdOn : true,
        language : true,
        m2d : true,
        phoneNumber : true,
        bitrixId : true,
        bookedSA : true,
        stop : true,
        quoteIdTaken : true,
        chatType : true
      },
      tableMap: {
        createdOn : true,
        language : true,
        m2d : true,
        phoneNumber : true,
        bitrixId : true,
        bookedSA : true,
        stop : true,
        quoteIdTaken : true,
        chatType : true
      },
      filterObj: false,
      filterData: {},
      filterDate: {},
      selectedLeadIds: {},
      selectAll: false,
      bulkAssign: {},
      startDate: moment(),
      endDate: null,
      // startDate: moment().subtract(29, 'days'),
      // endDate: moment(),
      focusedInput: null,
      count: "",
      loading: true,
      recieved: false
      // ranges: {
      //   'Today': [moment(), moment()],
      //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      //   'This Month': [moment().startOf('month'), moment().endOf('month')],
      //   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      // },
    }
  }

  componentDidMount() {
    this.setState({
      filterMap: localStorage.getItem("filterMapWhatsAppDashboard") ? JSON.parse(localStorage.getItem("filterMapWhatsAppDashboard")) : this.state.filterMap,
      tableMap: localStorage.getItem("tableMapWhatsAppDashboard") ? JSON.parse(localStorage.getItem("tableMapWhatsAppDashboard")) : this.state.tableMap
    })
  }

  componentWillReceiveProps(props) {
    // console.log("1", props.allMapping)
    let stages = this.optionFunction(props.allMapping.stage ? props.allMapping.stage : [], "STATUS_NAME");
    let source = this.optionFunction(props.allMapping.source ? props.allMapping.source : [], "language_name");
    let active = this.optionFunction(props.allMapping.active ? props.allMapping.active : [], "language_name");
    let type = this.optionFunction(props.allMapping.type ? props.allMapping.type : [], "language_name");
    let state = this.optionFunction(props.allMapping.stateCityMap ? Object.keys(props.allMapping.stateCityMap) : [], "langauge_name")

    let cityList = props.allMapping.stateCityMap ? [].concat(...Object.values(props.allMapping.stateCityMap)) : [];
    let city = this.optionFunction(cityList, "language_name");
    // let city = this.optionFunction(props.allMapping.city ? props.allMapping.city : [], "language_name");
    let employees = this.optionFunction(props.allMapping.employees ? props.allMapping.employees : [], "e_name")
    let turnover = this.optionFunction(props.allMapping.turnover ? props.allMapping.turnover : [], "e_name")
    let drop_off_reason = this.optionFunction(props.allMapping.drop_off_reason ? props.allMapping.drop_off_reason : [], "e_name")
    let updatedByOptions = (props.allMapping.employees || []).map(employee => ({ label: employee.e_name, value: employee.e_email }))
    let mappingData = props.allMapping
    if (props.searchData.length > 0) {
      this.setState({
        allLeadData: props.searchData,
        tableData: props.searchData,
        stages: stages,
        source: source,
        mappingData: mappingData,
        active: active,
        type: type,
        city: city,
        turnover,
        count: props.searchData.length,
        updatedByOptions,
        drop_off_reason,
        loading: false,
        recieved: true,
        statesArr: state
      }, () => { })
    } if (Object.keys(props.allLeads).length > 0 && Object.keys(props.allMapping).length > 0) {
      this.setState({
        loading: false,
        recieved: true,
        stages: stages,
        source: source,
        allLeadData: props.allLeads.data,
        tableData: props.allLeads.data,
        mappingData: mappingData,
        active: active,
        city: city,
        type: type,
        count: props.allLeads.count || "",
        employees: employees,
        turnover,
        updatedByOptions,
        drop_off_reason,
        statesArr: state
      })
    }

  }

  optionFunction = (array, value) => {
    let multiSelectOptionsMap = array.map((data) => {
      let obj = {}

      if (data[value]) {
        obj = {
          label: data[value].replace(/(^"|"$)/g, ''),
          value: data[value].replace(/(^"|"$)/g, '')
        }
      } else {
        obj = {
          label: data,
          value: data
        }
      }
      return obj
    })
    return multiSelectOptionsMap;
  }

  optionFunctionState = (array) => {
    let multiSelectOptionsStateMap = array.map((val) => {
      let obj = {
        label: val.replace(/(^"|"$)/g, ''),
        value: val.replace(/(^"|"$)/g, ''),
      }
      return obj;
    })
    return multiSelectOptionsStateMap
  }

  handleSelectChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: [value]
      }
    }, () => {
    })
  }

  handleMultiSelectChange = (e, name) => {
    let selectedList;
    if (Array.isArray(e)) {
      selectedList = (e || []).map((data) => {
        return data.value
      });
      if (selectedList.length <= 1) {
        selectedList = selectedList[0]
      }
    } else {
      selectedList = e.value || undefined
    }
    if (name == "messageType") {
      selectedList = { [name] : selectedList};
    }
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: selectedList
      },
      page: 1
    }, () => {
      // console.log("filterData after multiSelect", this.state.filterData)
    })

  }

  handleDateChange = (e, field) => {
    let { name, value } = e.target
    this.setState({
      filterData: {
        ...this.state.filterData,
        [field]: {
          ...this.state.filterData[field] ? this.state.filterData[field] : {},
          [name]: value
        }
      },
      page: 1
    }, () => {
    });
  }

  handleInputChange = (e) => {
    let { name, value } = e.target
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: value,
      }
    })
  }

  handleNewDateChange = (e) => {
    console.log("eeeeeee", e)
  }

  handleStateChange = (e, name) => {
    let regionArr = []
    let selectedState = (e || []).map((data) => {
      regionArr = regionArr.concat(this.props.allMapping.stateregion[data.label])
      return data.label
    });
    this.setState({
      regions: regionArr,
      filterData: {
        ...this.state.filterData,
        [name]: selectedState
      }
    })
  }

  handleSelectChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      bulkAssign: {
        ...this.state.bulkAssign,
        [name]: value
      }
    }, () => {
    })
  }

  multiSelectType = (name, options, placeholder, value, isMulti = true) => {
    return (
      <div className="form-group" style={{ width: '100%' }}>
        <Select
          isMulti = {isMulti}
          isSearchable
          onChange={(e) => { this.handleMultiSelectChange(e, name) }}
          placeholder={placeholder}
          name={name}
          options={options}
        />
      </div>
    )
  }
  /*value={(options || []).filter(opt => (value || []).includes(opt.value))}*/

  selectType = (name, value, options) => {

    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  inputTypeText = (name, value, type, placeholder) => {

    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />

  }

  dateType = (name, value, field) => {
    return (
      <div class="form-group">
        <span class="dateSideBar" />
        <input name={name} style={{ width: "100%" }} className="form-control" type="date" onChange={(e) => { this.handleDateChange(e, field) }} value={value}></input>

      </div>
    )
  }

  newDateType = () => {
    return (
      <div>
        <DateRangePicker
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={(e) => this.handleNewDateChange(e)} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          isOutsideRange={() => false}
        />
      </div>
    )
  }

  fetchLeads = (e) => {
    let { limit, page, allLeadData } = this.state;
    if (Object.keys(this.state.filterData).length > 0) {
      let Obj = { ...this.state.filterData }
      let filterObj = this.removingNullKeys(Obj);
      this.props.fetchAllLeads(limit, page + 1, allLeadData, filterObj, 'whatsApp');
    } else {
      this.props.fetchAllLeads(limit, page + 1, allLeadData, undefined, 'whatsApp')
    }
    this.setState({
      page: page + 1
    })
  }

  selectFetchLeads = (lim,e) => {
    this.setState({
      page: 1,
      limit: lim,
      allLeadData: []
    },
      () => {
        let {limit , page, allLeadData } = this.state;
        this.props.fetchAllLeads(limit, page, allLeadData, undefined, 'whatsApp')
      })
  }

  handleFilterToggle = (e) => {
    let val = !this.state.showFilter;
    this.setState({
      showFilter: val
    })
  }

  handleShowFilter = (e) => {
    let obj = { ...this.state.filterMap }
    obj[e.target.value] = e.target.checked

    this.setState({
      filterMap: {
        ...obj,
      }
    }, () => {
      localStorage.setItem("filterMapWhatsAppDashboard", JSON.stringify(obj))
    })
  }

  handleShowTable = (e) => {
    let obj = { ...this.state.tableMap }
    obj[e.target.value] = e.target.checked

    this.setState({
      tableMap: {
        ...obj
      }
    }, () => {
      localStorage.setItem("tableMapWhatsAppDashboard", JSON.stringify(obj))
    })
  }

  handleCheckbox = (e, solarId, row) => {

    if (e.nativeEvent.shiftKey == true) {
      let selectedLeadIds = { ...this.state.selectedLeadIds }
      let sortedArray = this.reactTable.current.state.sortedData;
      let selectedCheckboxCount = Object.keys(this.state.selectedLeadIds || {}).filter((val) => this.state.selectedLeadIds[val] == 1);
      let indexees = [];
      let lastSelectedIndex = -1;
      sortedArray.map((lead, index) => {
        if (selectedLeadIds[lead.solarId]) {
          // console.log("123", index)
          lastSelectedIndex = index;
        }
      })
      if (lastSelectedIndex == -1) {
        selectedLeadIds[solarId] = 1
      } else {
        let currentIndex = sortedArray.findIndex(row => row.solarId == solarId);
        let [start, end] = currentIndex > lastSelectedIndex ? [lastSelectedIndex, currentIndex] : [currentIndex, lastSelectedIndex];
        for (let i = start; i <= end; i++) {
          // console.log("123456", selectedLeadIds[sortedArray])
          selectedLeadIds[sortedArray[i].solarId] = 1;
        }
      }
      this.setState({
        selectedLeadIds: selectedLeadIds
      })
    } else {
      let status = this.state.selectedLeadIds[solarId];
      let selectedBitrixId = JSON.parse(JSON.stringify(this.state.selectedLeadIds))
      let selectedLeadIds = { ...selectedBitrixId, [solarId]: status ? 0 : 1, };
      this.setState({
        selectedLeadIds: selectedLeadIds,
        selectAll: this.state.selectAll && status ? false : this.state.selectAll
        // selectedLeadIds: [...selectedLeadIds]
      }, () => {
        // console.log(this.state.selectedLeadIds)
      })
    }
  }

  handleAllCheckbox = (e, row) => {

    let page = this.reactTable.current.state.page;
    let pageSize = this.reactTable.current.state.pageSize;
    let start = page * pageSize;
    let end = (page * pageSize + pageSize);
    let actualEnd = (row.data.length > end) ? end : row.data.length
    let data = row.data;
    let selectedLeadIds = {};
    if (!this.state.selectAll) {
      for (let i = start; i <= (actualEnd - 1); i++) {
        selectedLeadIds[data[i].solarId] = 1
      }
      // console.log(selectedLeadIds);
    }

    this.setState({
      selectedLeadIds: { ...selectedLeadIds },
      selectAll: !(this.state.selectAll)
    })

    // console.log("row.original", row);
    // let selectedLeadIds = {};
    // // let obj = {}
    // if (!this.state.selectAll) {
    //   row.data.map((val) => {
    //     selectedLeadIds[val.solarId] = 1;
    //   })
    //   console.log(selectedLeadIds);
    // }
    // this.setState({
    //   selectedLeadIds: { ...selectedLeadIds },
    //   selectAll: !(this.state.selectAll)
    // }, () => { console.log(this.state.selectAll) })
  }

  handleBulkAssign = (e) => {
    let tableData = [...this.state.tableData];
    let selectedLeadIds = { ...this.state.selectedLeadIds };
    let bulkAssign = { ...this.state.bulkAssign }
    let count = this.state.count;
    // let arr = []
    // Object.keys(selectedLeadIds).map((val) => {
    //   if (selectedLeadIds[val] == 1) {
    //     arr.push(val)
    //   }
    // })
    // let obj = { ...this.state.bulkAssign };
    // let lead = {
    //   solarId: arr,
    //   ...obj
    // }

    // setTimeout(() => {
    //   this.props.fetchAllLeads(100, 1, []);
    // }, 10000);

    // Object.keys(selectedLeadIds).map((bitrixId) => {

    //   let index = this.state.tableData.findIndex((val) => val.solarId == bitrixId);
    //   Object.keys(obj).map((field) => {
    //     // console.log("tableData", tableData, "tableDatataindeddd", tableData[index])
    //     tableData[index][field] = obj[field];
    //   })
    // })

    this.setState({
      // tableData: [...tableData]
    })
  }

  removingNullKeys = (obj) => {

    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }

    return obj
  }


  onApplyFilter = (e) => {
    let filterData = { ...this.state.filterData }
    filterData = JSON.parse(JSON.stringify(filterData));
    let filterObj = this.removingNullKeys(filterData);
    console.log("Naman", filterObj);
    if (Object.keys(filterObj).length > 0) {
      let { limit, page } = this.state;
      this.props.fetchAllLeads(limit, page, [], filterObj, 'whatsApp');
      this.setState({
        // filterObj: filterObj
      })
    } else {
      swal("Please apply atleast one filter")
    }

  }

  render() {

    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }

    const columns = [
      {
    		Header : "Date",
    		accessor : "date"
    	},
      {
    		Header : "Language",
    		accessor : "language"
    	},
      {
    		Header : "Pincode",
    		accessor : "pincode"
    	},
      {
    		Header : "SA Booked",
    		accessor : "bookedSA"
    	},
      {
        Header : "State",
        accessor : "state"
      },
    	{
    		Header : "STOP",
    		accessor : "stop"
    	},
    	{
    		Header : "M2D",
    		accessor : "m2d"
    	},
    	{
    		Header : "QID",
    		accessor : "quoteIdList",
        Cell: row => (
          <div>
            {row.value.map( quoteId => (<Link to={"https://operations.zunroof.com/quoteid/" + quoteId} onClick={(event) => { event.preventDefault(); window.open("https://operations.zunroof.com/quoteid/" + quoteId); }}>{quoteId}</Link>))}
          </div>
        )
    	},
      {
        Header : "Quote Id Taken",
        accessor : "quoteIdTaken",
      },
    	{
    		Header : "Chat Link",
    		accessor : "_id",
        Cell: row => (
          <div>
            <Link to={"/whatsapp/dashboard/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/whatsapp/dashboard/" + row.value); }}>Chat Link</Link>
          </div>
        )
    	},
      {
    		Header : "View In ZRM",
    		accessor : "phoneNumber",
        Cell: row => {
          return (<div>{row.original.bitrixId ? <div>
            <Link to={"/lead/details/" + row.original.bitrixId} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/lead/details/" + row.original.bitrixId); }}>ZRM Link</Link>
          </div> : row.value ? <div>
            <Link to={"/search/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/search/" + row.value); }}>ZRM Link</Link>
          </div> : ""}</div>)
        }
    	},
    ]
    const filterOptions = [
      { label: "Created On", value: "createdOn" },
      { label: "language", value: "language" },
      { label: "M2D", value: "m2d" },
      { label: 'Phone Number', value: "phoneNumber" },
      { label: 'Bitrix Id', value: "bitrixId" },
      { label: "SA Booked", value: "bookedSA" },
      { label: "STOP", value: "stop" },
      { label: "Quote Id Taken", value: "quoteIdTaken" },
      { label: "Chat Type", value: "chatType" },
    ]
    const tableOptions = columns.map(val => {
      if (val.accessor) {
        return { label: val.Header, value: val.accessor };
      }
    }).filter(val => val !== undefined);

    if (this.state.recieved) {
      return (
        <div className="container-fluid" style={{ marginTop: "80px" }}>

          <div className="topbar-section allLeadViewFilters" style={this.props.searchData.length > 0 ? style.hide : style.show}>
            <div className="pull-right">
              <i data-toggle="modal" data-target="#selectFilterModal" onClick={this.handleFilterModal} class="fa fa-cog" style={{ float: "right", fontSize: "25px", cursor: "pointer" }} aria-hidden="true"></i>
              {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                                Launch demo modal
                            </button> */}
              {/* modal body */}
              <div class="modal fade" id="selectFilterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Select Filters</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div className="row">
                        {filterOptions.map(option => {
                          return <div className="col-md-6"><input name="filterMap" type="checkbox" value={option.value} checked={this.state.filterMap[option.value] ? true : false} onChange={this.handleShowFilter} /> <span>{option.label}</span></div>
                        })}
                      </div>
                    </div>
                    {/* <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
            <hr style={this.state.showFilter ? style.show : style.hide}></hr>
            <div className="row" style={this.state.showFilter ? style.show : style.hide}>
              <div className="col-md-2" style={this.state.filterMap.createdOn ? style.show : style.hide}>
                <label>Created On Start</label>
                {this.dateType("startDate", this.state.filterData.createdOn ? this.state.filterData.createdOn.startDate : "", "createdOn")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.createdOn ? style.show : style.hide}>
                <label>Created On End</label>
                {this.dateType("endDate", this.state.filterData.createdOn ? this.state.filterData.createdOn.endDate : "", "createdOn")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.language ? style.show : style.hide}>
                <label>Language</label>
                {this.multiSelectType("language", this.state.languages, "Language", this.state.filterData.language)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.phoneNumber ? style.show : style.hide}>
                <label>Phone Number</label>
                {this.inputTypeText("phoneNumber", this.state.filterData.phoneNumber ,"text", "Phone Number")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.bitrixId ? style.show : style.hide}>
                <label>Bitrix Id</label>
                {this.inputTypeText("bitrixId", this.state.filterData.bitrixId ,"text", "Bitrix Id")}
              </div>
              <div className="col-md-2" style={this.state.filterMap.m2d ? style.show : style.hide}>
                <label>M2D</label>
                {this.multiSelectType("m2d",this.state.yesNo, "m2d", this.state.filterData.m2d, false)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.bookedSA ? style.show : style.hide}>
                <label>SA Booked</label>
                {this.multiSelectType("bookedSA", this.state.yesNo, "bookedSA", this.state.filterData.bookedSA, false)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.stop ? style.show : style.hide}>
                <label>STOP</label>
                {this.multiSelectType("stop", this.state.yesNo, "stop", this.state.filterData.stop, false)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.quoteIdTaken ? style.show : style.hide}>
                <label>Quote Id Taken</label>
                {this.multiSelectType("quoteIdTaken", this.state.yesNo, "quoteIdTaken", this.state.filterData.quoteIdTaken, false)}
              </div>
              <div className="col-md-2" style={this.state.filterMap.chatType ? style.show : style.hide}>
                <label>Chat Type</label>
                {this.multiSelectType("chatType", [{ label: 'Not Selected', value: '' }, { label: 'Pre Sales', value: 'preSales' }, { label: 'Post Sales', value: 'postSales' }], "chatType", this.state.filterData.chatType, false)}
              </div>
              <div className="col-md-2" style={this.state.filterData.chatType == "postSales" ? style.show : style.hide}>
                <label>Message Start</label>
                {this.dateType("startDate", this.state.filterData.messageType ? this.state.filterData.messageType.startDate : "", "messageType")}
              </div>
              <div className="col-md-2" style={this.state.filterData.chatType == "postSales" ? style.show : style.hide}>
                <label>Message End</label>
                {this.dateType("endDate", this.state.filterData.messageType ? this.state.filterData.messageType.endDate : "", "messageType")}
              </div>
              <div className="col-md-2" style={this.state.filterData.chatType && false ? style.show : style.hide}>
                <label>Message Type</label>
                {this.multiSelectType("messageType", this.state[this.state.filterData.chatType + "MessageType"], "messageType", this.state.filterData.messageType, false)}
              </div>
              <div style={Object.values(this.state.filterMap).includes(true) ? style.show : style.hide}>
                <label></label><br></br>
                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
              </div>
            </div>
          </div>
          <div>
            <div className="row" style={{ margin: '20px 0px' }}>
              <div className="col-md-8">
                {/* <span> Leads : {this.state.tableData.length} </span> */}
                <span style={this.props.searchData.length > 0 ? style.hide : style.show}><div style={{ display: 'inline-block', width: '150px' }}><Select placeholder="No. of Leads" options={this.state.limitoption} onChange={e => this.selectFetchLeads(e.value,e)} /></div></span>
                <span style={this.props.searchData.length > 0 ? style.hide : style.show}><button className="moreLeads" onClick={e => this.fetchLeads()}> More Leads <i class="fas fa-angle-double-right" aria-hidden="true"></i></button></span>
                <span style={{ paddingLeft: "30px" }}><b>Total Leads</b> : <b>{this.state.count}</b></span>
              </div>
            </div>

            <div className="row" style={{ marginTop: "25px" }} style={Object.values(this.state.selectedLeadIds).includes(1) ? style.show : style.hide}>
              <div class="col-md-2">
                <label>Responsible</label>
                {this.selectNewType("responsible_person", this.state.mappingData.employees ? this.state.mappingData.employees : '', "e_name", this.state.bulkAssign.responsible_person)}
              </div>
              <div class="col-md-2">
                <label>Stage</label>
                {this.selectType("stage", this.state.bulkAssign.stage, this.state.mappingData.stage ? this.state.mappingData.stage : "")}
              </div>


              <div className="col-md-1" style={{ marginTop: "32px" }}>
                <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkAssign(e) }}>Submit</button>
              </div>

            </div>


            {/* Modal for Selecting Table Headers */}

            <div style={{ marginTop: '350px' }} class="modal fade" id="selectTableOptionsModal" tabindex="-1" role="dialog" aria-labelledby="selectTableOptionsLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="selectTableOptionsLabel">Select Table Headers</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      {tableOptions.map(option => {
                        return <div className="col-md-6"><input name="tableMap" type="checkbox" value={option.value} checked={this.state.tableMap[option.value] ? true : false} onChange={(e) => this.handleShowTable(e)} /> <span>{option.label}</span></div>
                      })}
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* Modal Ends */}

            <div style={{ marginTop: "10px" }}>
              <ReactTable
                // loading = {true}
                filterable={true}
                showPagination={true}
                data={this.state.tableData}
                defaultPageSize={100}
                columns={columns}
                ref={this.reactTable}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
              //    defaultPageSize = {2}
              //    pageSizeOptions = {[2,4,6]}
              />

            </div>
          </div>
        </div>
      )

    }

    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }


  }

}

export default AllLeadView;
