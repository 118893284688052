import React from 'react';
import {  addComments,editZunsolarLead,fetchZunpulseLead, fetchZunsolarLead, fetchZunsolarDealerInventory, editZunsolarDealerInventory, addZunsolarDealerInventory,addZunsolarDealerVisitData, fetchZunsolarDealerVisitData, addZunsolarDistributorKYC, fetchZunsolarDistributorKYCData,downloadKYCFile, fetchRetailersCountForDistributor,fetchLeadgerDataForDistributor  } from '../../actions/fetchBitrixId';
import { fetchMapping } from '../../actions/zunsolarOrderTrackerActions';
import { fetchMappingZunpulse,fetchAllMapping, fetchMappingZunsolar, fetchDistributorIDMapping } from '../../actions/fetchAllMapping';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import ZunsolarLeadView from '../../components/zunsolar/ZunsolarLeadView';
import ZunsolarDistributorLeadView from '../../components/zunsolar/ZunsolarDistributorLeadView';
import MTSingleLeadView from '../../components/zunsolar/MTSingleLeadView';
import MobileHeaderComponent from '../../components/MobileHeaderComponent';
import MTSingleDealerLeadView from '../../components/zunsolar/MTSingleDealerLeadView';
import HeaderComponent from '../../components/HeaderComponent';


class MTSingleDealerLead extends Component {
  componentWillMount() {
    let viewList = ["employees"]
    this.props.fetchAllMapping(viewList)
    this.props.fetchZunsolarLead(this.props.match.params.id);
    this.props.fetchMappingZunsolar();
    this.props.fetchZunsolarDealerInventory(this.props.match.params.id);
    this.props.fetchZunsolarDealerVisitData(this.props.match.params.id);
    this.props.fetchZunsolarDistributorKYCData(this.props.match.params.id);
    this.props.fetchRetailersCountForDistributor(this.props.match.params.id)
    this.props.fetchLeadgerDataForDistributor(this.props.match.params.id);
    this.props.fetchMapping();
    this.props.fetchDistributorIDMapping();
  }

  render() {
    if (this.props.leadData) {
      return (
        <div style={{ backgroundColor: "#e6ffe9" , overflow:'hidden'}}>
          <HeaderComponent/>
          {/* <Header getSearchResult={this.props.getSearchResult} /> */}
          {/* <LeadView leadDetails={this.props.leadData} addComments={this.props.addComments} editLead={this.props.editLead} allMapping={this.props.allMapping} fetchBitrixId={this.props.fetchBitrixId} /> */}
          <MTSingleDealerLeadView allMapping={this.props.allMapping} leadDetails={this.props.leadData} editLead={this.props.editZunsolarLead} addComments={this.props.addComments} fetchZunsolarLead = {this.props.fetchZunsolarLead} zunsolarInventoryDealerData = {this.props.zunsolarInventoryDealerData} productsMapping = { this.props.productsMapping } editZunsolarDealerInventory = {this.props.editZunsolarDealerInventory} addZunsolarDealerInventory = {this.props.addZunsolarDealerInventory} distributorMapping = {this.props.distributorMapping} dealerVisitData = {this.props.dealerVisitData} addZunsolarDealerVisitData = {this.props.addZunsolarDealerVisitData} fetchZunsolarDealerVisitData = {this.props.fetchZunsolarDealerVisitData} addZunsolarDistributorKYC = {this.props.addZunsolarDistributorKYC} distributorKycData = {this.props.distributorKycData}  downloadKYCFile = {this.props.downloadKYCFile} retailersCount = {this.props.retailersCount} distributorLedgerData = {this.props.distributorLedgerData}/>
        </div>
      )
    } else {
      return <p>this is not found</p>
    }
  }
}

function mapStateToProps(state) {
  console.log("fetchlead", state.fetchlead)
  console.log("state", state)
  let { zunSolarOrderTracker = {} } = state;
  return {
    leadData: state.fetchlead ? state.fetchlead : '',
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    distributorMapping: state.fetchAllMapping && state.fetchAllMapping.distributorMapping || [],
    searchData : state.searchResults ? state.searchResults : [],
    zunsolarInventoryDealerData: state.fetchlead && state.fetchlead.zunsolarInventoryDealerData ? state.fetchlead.zunsolarInventoryDealerData : [],
    dealerVisitData: state.fetchlead && state.fetchlead.dealerVisitData || [],
    distributorKycData : state.fetchlead && state.fetchlead.distributorKYCData || [],
    distributorLedgerData: state.fetchlead && state.fetchlead.distributorLedgerData || [],
    retailersCount: state.fetchlead && state.fetchlead.retailersCount || 0,
    productsMapping: zunSolarOrderTracker.mapping && zunSolarOrderTracker.mapping.uniProductIds ? Object.values(zunSolarOrderTracker.mapping.uniProductIds) : []
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchZunsolarLead,fetchAllMapping, editZunsolarLead, fetchMappingZunsolar, getSearchResult,addComments, fetchZunsolarDealerInventory, fetchMapping, editZunsolarDealerInventory, addZunsolarDealerInventory,fetchDistributorIDMapping, addZunsolarDealerVisitData, fetchZunsolarDealerVisitData,addZunsolarDistributorKYC, fetchZunsolarDistributorKYCData,downloadKYCFile,fetchRetailersCountForDistributor,fetchLeadgerDataForDistributor }, dispatch)
}

function isLeadData() {
  return (
    <p>this is not found</p>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MTSingleDealerLead)