import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import { fetchRedeemedVouchers } from '../../actions/walletActions';
import Header from '../../components/HeaderComponent';
import { Loader } from '../../components/loader';
import { redeemedVouchersConfig } from '../../config/column_fields';
import { CSVLink } from 'react-csv';

class RedeemedPromocash extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            allData: [],
            viewData: [],
            loading: true,
            received: false,
            filters: {
                startDate: 0,
                endDate: 0,
                phone: ""
            },
        };
    }

    componentWillMount() {
        this.props.fetchRedeemedVouchers();
    }

    componentWillReceiveProps(props) {
        let { redeemedVouchersData = [], redeemedVouchersDataReceived = false } = props;
        this.setState({
            loading: false,
            received: true,
            allData: redeemedVouchersData,
            redeemedVouchersDataReceived,
            viewData: redeemedVouchersData
        })
    }

    handleInputChangeFilters = (e) => {
        let { name, value } = e.target;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }

    inputType = (name, placeholder, type, onChange, value) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e)} />
                </div>
            </div>
        )
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = () => {
        let { filters, allData } = this.state;
        let { startDate = "", endDate = "", phone = "" } = filters;
        let viewData = allData.filter(row => {
            return (
                (!startDate ? true : row.transaction_dt >= startDate) &&
                (!endDate ? true : row.transaction_dt <= endDate) &&
                (!phone ? true : phone == row.user_id)
            )
        })
        this.setState({
            viewData
        })
    }

    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        let { redeemedVouchersDataReceived = false, viewData = [] } = this.state;
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <div className="topbar-section allLeadViewFilters">
                        <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                        <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                        <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                            <div className="col-md-2">
                                {this.inputType("startDate", "Date", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("endDate", "Date", "date", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                {this.inputType("phone", "Phone", "number", this.handleInputChangeFilters)}
                            </div>
                            <div className="col-md-2">
                                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <CSVLink style={{ marginLeft: "30px" }} filename={"redeemedPromocash.csv"} data={viewData} headers={redeemedVouchersConfig.map(val => ({ label: val.csvHeader || val.Header, key: val.accessor }))} type="button" className="btn btn-light btn-md">
                            Download CSV <i className="fa fa-download"></i>
                        </CSVLink>
                    </div>
                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={viewData}
                        columns={redeemedVouchersConfig}
                        defaultSorted={[{ id: "createdAt", desc: true }]}
                        style={{ height: "90vh", textAlign: 'center' }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        loading={!redeemedVouchersDataReceived}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}


function mapStateToProps({ wallet }) {
    let { redeemedVouchersData = [], redeemedVouchersDataReceived = false } = wallet;
    return {
        redeemedVouchersData,
        redeemedVouchersDataReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchRedeemedVouchers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RedeemedPromocash);
