import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchProductSummary, fetchMarketingSummary, fetchPlatformSummary, fetchOrderWiseMaketingSummary, fetchDeviceWiseMaketingSummary } from '../../actions/zunpulseBurnDashboardAction';
import Header from '../../components/HeaderComponent';
import { GeneralTableView } from '../../components/zunpulseComponents/zunpulseBurnDashboardComponent.jsx';
import '../../styles/pulseOpsDashboardStyles.css';
import { Link } from 'react-router-dom';


class ZunpulseBurnDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableType: 'Order',
            orderDateFilters: {
                startDate: '',
                endDate: ''
            },
            mapViewType: 'Order',
            productsFilterMapView: []
        };
    }

    componentWillMount() {
        this.props.fetchProductSummary();
        this.props.fetchMarketingSummary();
        this.props.fetchPlatformSummary();
        this.props.fetchOrderWiseMaketingSummary();
        this.props.fetchDeviceWiseMaketingSummary();
    }

    componentWillReceiveProps(props) {
        let { 
            productData, 
            productDataReceived,
            productColumns,
            marketingData,
            marketignDataReceived,
            marketingColumns,
            platformData,
            platformColumns,
            platformDataReceived,
            orderwiseMarketingData,
            orderwiseMarketingColumns,
            orderwiseMarketingReceived,
            devicewiseMarketingData,
            devicewiseMarketingColumns,
            devicewiseMarketingReceived
        } = props;
        this.setState({
            productData, 
            productDataReceived,
            productColumns,
            marketingData,
            marketignDataReceived,
            marketingColumns,
            platformData,
            platformColumns,
            platformDataReceived,
            orderwiseMarketingData,
            orderwiseMarketingColumns,
            orderwiseMarketingReceived,
            devicewiseMarketingData,
            devicewiseMarketingColumns,
            devicewiseMarketingReceived
        })
    }

    render() {
        let { 
            productData=[], 
            productDataReceived=false,
            productColumns=[],
            marketingData=[],
            marketignDataReceived=false,
            marketingColumns=[],
            platformData=[],
            platformColumns=[],
            platformDataReceived=false,
            orderwiseMarketingData=[], 
            orderwiseMarketingColumns=[], 
            orderwiseMarketingReceived=true,
            devicewiseMarketingData=[], 
            devicewiseMarketingColumns=[], 
            devicewiseMarketingReceived=false
        } = this.state;
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <div style={{ "marginTop": 80 }} className="container-fluid zunpulseOpsDashboardMainDiv">
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontWeight: "bold" }}>Zunpulse Burn Dashboard</h2>
                    </div>
                    <div style={{ margin: '20px 0px' }}>
                        <div style={{ float: "right" }}>
                            <Link to="/zunpulse/addmarketingspending" target="_blank"><button className="createLeadBtn"><i class="fa fa-plus"></i> Add Spending</button></Link>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                    <GeneralTableView 
                        data={productData}
                        columns={productColumns}
                        loading={!productDataReceived}
                        type={"Device Metrics Summary (Devices X Month) sale count"}
                    />
                    <GeneralTableView 
                        data={marketingData}
                        columns={marketingColumns}
                        loading={!marketignDataReceived}
                        type={"Device Metrics Summary Total count/sum X month"}
                    />
                    <GeneralTableView 
                        data={platformData}
                        columns={platformColumns}
                        loading={!platformDataReceived}
                        type={"Platform X Type summary Overall Summary"}
                    />
                    <GeneralTableView 
                        data={orderwiseMarketingData}
                        columns={orderwiseMarketingColumns}
                        loading={!orderwiseMarketingReceived}
                        type={"Platform X Type summary Order wise distribution"}
                    />
                    <GeneralTableView 
                        data={devicewiseMarketingData}
                        columns={devicewiseMarketingColumns}
                        loading={!devicewiseMarketingReceived}
                        type={"Platform X Type summary Device wise distribution"}
                    />
                </div>
            </div>
        );


    }
}

function mapStateToProps({ zunpulseBurnDashboard }) {
    let { productData=[], productDataReceived=false, productColumns=[], marketingData=[], marketingColumns=[], marketignDataReceived=false, platformData=[], platformColumns=[], platformDataReceived=false, orderwiseMarketingData=[], orderwiseMarketingColumns=[], orderwiseMarketingReceived=false, devicewiseMarketingData=[], devicewiseMarketingColumns=[], devicewiseMarketingReceived=false } = zunpulseBurnDashboard;
    return {
        productData, 
        productDataReceived,
        productColumns,
        marketingData,
        marketignDataReceived,
        marketingColumns,
        platformData,
        platformColumns,
        platformDataReceived,
        orderwiseMarketingData,
        orderwiseMarketingColumns,
        orderwiseMarketingReceived,
        devicewiseMarketingData,
        devicewiseMarketingColumns,
        devicewiseMarketingReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchProductSummary, fetchMarketingSummary, fetchPlatformSummary, fetchOrderWiseMaketingSummary, fetchDeviceWiseMaketingSummary }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseBurnDashboard);