import React, { Component } from 'react';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import url from './config'
import Header from './components/HeaderComponent'
import LeadsView from './components/leadsView';
import FetchLead from './containers/fetchLead';
import FetchAllLeads from './containers/fetchAllLeads';
import Login from './containers/Login';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Register from './containers/Register';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import Search from './containers/Search';
import UserRole from './containers/UserRoles';
import CreateLead from './containers/CreateLead';
import ZunpulseCreateLead from './containers/ZunpulseContainers/ZunpulseCreateLead';
import ZunsolarCreateLead from './containers/Zunsolar/ZunsolarCreateLead';
import ZunpulseAllLeads from './containers/ZunpulseContainers/ZunpulseAllLeads';
import ZunpulseSingleLeadView from './containers/ZunpulseContainers/ZunpulseSingleLeadView';
// import ZunsolarLead from './containers/Zunsolar/ZunsolarLead';
import ZunpulseOrders from './containers/ZunpulseContainers/ZunpulseOrders';
import ZunpulseNewOrders from './containers/ZunpulseContainers/ZunpulseNewOrders';
import ZunpulseSingleOrderView from './containers/ZunpulseContainers/ZunpulseSingleOrderView';
import ZunpulseSingleOrderViewNew from './containers/ZunpulseContainers/ZunpulseSingleOrderViewNew';
import ZunpulseSearch from './containers/ZunpulseContainers/ZunpulseSearch';
import ZunsolarSearch from './containers/Zunsolar/ZunsolarSearch';
import AllLeads from './containers/Zunsolar/AllLeads';
import 'bootstrap';
import './App.css';
import ZunsolarLead from './containers/Zunsolar/ZunsolarLead';
import CallCount from './components/zunpulseComponents/CallCountComponent';
import ZunpulseLeadTransfer from './containers/ZunpulseContainers/ZunpulseLeadTransfer';
import CallCountZunsolar from './components/zunsolar/CallCountZunsolar';
import SAView from './containers/SAView';
import ZunsolarLeadTransfer from './containers/Zunsolar/ZunsolarLeadTransfer';
import AddVendor from './components/zunsolar/AddVendor';
import AnalayseAc from './components/zunpulseComponents/AnalyseAc';
import VendorLocationMap from './components/zunsolar/VendorLocationMap';
import ZunsolarInventory from './containers/Zunsolar/ZunsolarInventory'
import ZunsolarCombo from './containers/Zunsolar/ZunsolarCombo'
import ZunsolarAddInventory from './containers/Zunsolar/ZunsolarAddInventory'
import ZunsolarCreateOrder from './containers/Zunsolar/ZunsolarCreateOrder'
import ZunsolarAllOrderView from './containers/Zunsolar/ZunsolarAllOrderView'
import ZunsolarOrderView from './containers/Zunsolar/ZunsolarOrderView';
import ZunsolarSubOrderView from './containers/Zunsolar/ZunsolarSubOrderView';
import ZunsolarAddCombo from './containers/Zunsolar/ZunsolarAddCombo';
import DownloadAcData from './containers/ZunpulseContainers/DownloadAcData';
import WhatsAppAllLeads from './containers/WhatsAppContainers/AllCustomersView';
import WhatsAppCustomer from './containers/WhatsAppContainers/singleCustomerView';
import WhatsAppPreSalesMetrics from './containers/WhatsAppContainers/PreSalesMetrics';
import WhatsAppPostSalesMetrics from './containers/WhatsAppContainers/PostSalesMetrics';
import ZunsolarOpsDashboard from './containers/Zunsolar/ZunsolarOpsDashboard';
import ZunsolarOpsDashboardNew from './containers/Zunsolar/ZunsolarOpsDashboardNew';
import ZunpulseCreateOrder from './containers/ZunpulseContainers/ZunpulseCreateOrder';
import ZunpulseNewCreateOrder from './containers/ZunpulseContainers/ZunpulseNewCreateOrder';
import ZunpulseOpsDashboard from './containers/ZunpulseContainers/ZunpulseOpsDashboard';
import ZunpulseOpsDashboardNew from './containers/ZunpulseContainers/ZunpulseOpsDashboardNew';
import ZunpulseAddCombo from './containers/ZunpulseContainers/ZunpulseAddCombo';
import ZunpulseOrdersInvoices from './containers/ZunpulseContainers/ZunpulseOrdersInvoices';
import ZunsolarOrdersInvoices from './containers/Zunsolar/ZunsolarOrdersInvoices';
import ZunpulseCombo from './containers/ZunpulseContainers/ZunpulseCombo';
import WarrantyDetails from './containers/Zunsolar/WarrantyDetails'
import FulfillmentReport from './containers/Zunsolar/FulfillmentReport';
import ZunpulseProducts from './containers/ZunpulseContainers/ZunpulseProducts';
import ZunpulseAllProducts from './containers/ZunpulseContainers/ZunpulseAllProducts';
import ZunpulseAddCoupon from './containers/ZunpulseContainers/ZunpulseAddCoupon';
import ZunsolarGeneratePI from './containers/Zunsolar/ZunsolarGeneratePI';
import zunsolarPIOrders from './containers/Zunsolar/zunsolarPIOrders';
import ProcurementPortal from './containers/Zunsolar/ProcurementPortal';
import AffiliatePortal from './containers/Zunsolar/AffiliatePortal';
import ZunsolarPIInvoice from './containers/Zunsolar/ZunsolarPIInvoice';
import ZunpulseBurnDashboard from './containers/ZunpulseContainers/zunpulseBurnDashboard';
import zunpulseCreateMarketingSpending from './containers/ZunpulseContainers/zunpulseCreateMarketingSpending';
import WalletTransactions from './containers/ZunpulseContainers/WalletTransactions';
import RedeemedPromocash from './containers/ZunpulseContainers/RedeemedPromocash';
import ZunpulseCouponView from './containers/ZunpulseContainers/ZunpulseCouponView';
import ZunpulseProductView from './containers/ZunpulseContainers/ZunpulseProductView';
import SingleProductDetails from './containers/ZunpulseContainers/SingleProductDetails';
import ZunpulseRepeatNotification from './containers/ZunpulseContainers/ZunpulseRepeatNotification';
import ZunpulseRepeatSalesApp from './containers/ZunpulseContainers/ZunpulseRepeatSalesApp';
import ZunpulseRepeatViewBanners from './containers/ZunpulseContainers/ZunpulseRepeatViewBanners';
import ZunsolarPIInvoiceV2 from './containers/Zunsolar/ZunsolarPIInvoiceV2';
import ReturnOrders from './containers/Zunsolar/ReturnOrders';
import AllReturnOrdersView from './containers/Zunsolar/AllReturnOrdersView';
import IVRTicketingView from './components/zunsolar/IVRTicketingView';
import CreateIVRTicket from './components/zunsolar/CreateIVRTicket';
import SingleIVRTicket from './components/zunsolar/SingleIVRTicket';
import AllOfflineDistributorLeads from './containers/Zunsolar/AllOfflineDistributorLeads';
import AllOfflineLeads from './containers/Zunsolar/AllOfflineLeads';
import AllOfflineDealerLeads from './containers/Zunsolar/AllOfflineDealerLeads';
import AllMappedDealers from './containers/Zunsolar/AllMappedDealers';
import ZunsolarConsultativeSales from './containers/Zunsolar/ZunsolarConsultativeSales';
import ZunsolarDitributorLead from './containers/Zunsolar/ZunsolarDitributorLead';
import IVRCurrentCallView from './components/zunsolar/IVRCurrentCallView';
import IVROutBoundCallView from './components/zunsolar/IVROutBoundCallView';
import CreatePaymentMobile from './components/zunsolar/CreatePaymentMobile';
import PaymentSummary from './containers/PaymentSummary';
import {isMobile} from 'react-device-detect'
import MTPaymentHistory from './components/zunsolar/MTPaymentHistory';
import MTSearchVisitView from './components/zunsolar/MTSearchVisitView';
import MTAddVisitSingleDealer from './components/zunsolar/MTAddVisitSingleDealer';
import MTSingleLeadView from './components/zunsolar/MTSingleLeadView';
import MTSingleLead from './containers/Zunsolar/MTSingleLead';
import MTSingleDealerLead from './containers/Zunsolar/MTSingleDealerLead';
import MtCreateLeadComponent from './components/zunsolar/MtCreateLeadComponent';
import MtCreateLeadSecond from './components/zunsolar/MtCreateLeadSecond';
import MtCreateLeadThird from './components/zunsolar/MtCreateLeadThirdComponent';
import Logistics from './containers/Logistics';
import LogisticsManualEntry from './containers/LogisticsManualEntry';
import ProjectSalesCreateLead from './containers/ProjectSalesCreateLead'; 
import ProjectSalesAllLeads from './containers/ProjectSalesAllLeads';
import ProjectSalesLeadView from './containers/ProjectSalesLeadView';
import RoofTracker from './containers/RoofTracker';
import OrderStatusBulkUpdate from './containers/Zunsolar/BulkStatusUpdate';
import GeneratePiMobileView from './containers/GeneratePiMobileView/GeneratePiMobileView';
import FollowUpMobile from './containers/FollowUpMobile';
import IVROutboundInsideSales from './components/zunsolar/IVROutboundInsideSales';
import ReferralLeads from './containers/ReferralLeads';

const Auth = {
  isAuthenticated: false,
  authenticate() {

    let token = localStorage.getItem("authToken")

    if (token) {
      let decodeUser = jwt.decode(token, url.JWT_SECRET)
      // console.log(decodeUser.exp)
      if (decodeUser.exp > new Date().getTime() / 1000) {
        this.isAuthenticated = true
      }
      else {
        this.isAuthenticated = false
      }
      // console.log("isAuthenticated", this.isAuthenticated)
    }
  }
}
Auth.authenticate()

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuthenticated == true ? <Component {...props} /> : <Redirect to='/login' />
  )} />
)
const redirect = () => {
  return <Route path="/" exact component={Login} />
}


class App extends Component {
  constructor(props){
    super();
    this.state={
      employeeDetails:{}
    }
  }

  componentWillMount(){
    let employeeDetails = JSON.parse(localStorage.getItem('userObj'));
    console.log('employeeDetails',employeeDetails);
    this.setState({
      employeeDetails
    })
    console.log('isMobile',isMobile);
  }
  render() {
    let {employeeDetails} = this.state
    return (
      <div>
        <Router>
          <Switch>
            <Route path='/' exact render={() => (
                <Redirect to="/lead/all" />

            )
            } />
            <Route path='/login' exact component={Login} />
            <Route path='/forgotPassword' exact component={ForgotPassword} />
            <Route path='/password/reset/:token' exact component={ResetPassword} />
            <Route path='/register' exact component={Register} />
            {/* <Route path ='/'><Redirect to='/lead/all'></Redirect></Route>  */}
            <PrivateRoute path='/mt/home' exact component={PaymentSummary}/>
            <PrivateRoute path='/lead/details/:id' exact component={FetchLead} />
            <PrivateRoute path='/lead/all' exact component={FetchAllLeads} />
            <PrivateRoute path='/lead/referral' exact component={ReferralLeads} />
            <PrivateRoute path='/mt/createPayment' exact component={CreatePaymentMobile}/>
            <PrivateRoute path='/mt/paymentView' exact component={MTPaymentHistory}/>
            <PrivateRoute path='/mt/visitSearch' exact component={MTSearchVisitView}/>
            <PrivateRoute path='/mt/addVisit/:id' exact component={MTAddVisitSingleDealer} />
            <PrivateRoute path='/mt/distributorLeadDetails/:id' exact component={MTSingleLead} />
            <PrivateRoute path='/mt/dealerLeadDetails/:id' exact component={MTSingleDealerLead} />
            <PrivateRoute path='/mt/createlead' exact component={MtCreateLeadComponent}/>
            <PrivateRoute path='/mt/createUpdateFirst/:id' exact component={MtCreateLeadSecond}/>
            <PrivateRoute path='/mt/createUpdateFinal/:id' exact component={MtCreateLeadThird}/>
            <PrivateRoute path='/search/:string' exact component={Search} />
            <PrivateRoute path='/userroles' exact component={UserRole} />
            <PrivateRoute path='/createlead' exact component={CreateLead} />
            <PrivateRoute path='/zunpulse/createlead' exact component={ZunpulseCreateLead} />
            <PrivateRoute path='/zunsolar/createlead' exact component={ZunsolarCreateLead} />
            <PrivateRoute path='/zunpulse/all' exact component={ZunpulseAllLeads} />
            <PrivateRoute path='/zunsolar/all/consultativeSales' exact component={ZunsolarConsultativeSales} />
            <PrivateRoute path='/zunsolar/all' exact component={AllLeads} />
            <PrivateRoute path='/zunsolar/all/offline' exact component={AllOfflineLeads} />
            <PrivateRoute path='/zunsolar/all/offline/retailer' exact component={AllOfflineDealerLeads} />
            <PrivateRoute path='/zunsolar/all/offline/distributor' exact component={AllOfflineDistributorLeads} />
            <PrivateRoute path='/zunsolar/lead/all/retailer/:distributorId' exact component={AllMappedDealers} />
            <PrivateRoute path='/zunpulse/details/:id' exact component={ZunpulseSingleLeadView} />
            <PrivateRoute path='/zunsolar/details/:id' exact component={ZunsolarLead} />
            <PrivateRoute path='/zunsolar/details/distributor/:id' exact component={ZunsolarDitributorLead} />

            <PrivateRoute path='/zunpulse/orders/new' exact component={ZunpulseOrders} />
            <PrivateRoute path='/zunpulse/orders/invoices' exact component={ZunpulseOrdersInvoices} />
            <PrivateRoute path='/zunpulse/orders' exact component={ZunpulseNewOrders} />
            <PrivateRoute path='/zunpulse/orders/new/:id' exact component={ZunpulseSingleOrderView} />
            <PrivateRoute path='/zunpulse/orders/:id' exact component={ZunpulseSingleOrderViewNew} />
            <PrivateRoute path='/zunpulse/search/:id' exact component={ZunpulseSearch} />
            <PrivateRoute path='/zunsolar/search/:id' exact component={ZunsolarSearch} />
            <PrivateRoute path='/zunpulse/callcount' exact component={CallCount} />
            <PrivateRoute path='/zunpulse/leadtransfer' exact component={ZunpulseLeadTransfer} />
            <PrivateRoute path='/zunsolar/callcount' exact component={CallCountZunsolar} />
            <PrivateRoute path='/saview' exact component={SAView} />
            <PrivateRoute path='/zunsolar/leadtransfer' exact component={ZunsolarLeadTransfer} />
            <PrivateRoute path='/zunsolar/addvendor' exact component={AddVendor} />
            <PrivateRoute path='/zunpulse/analyseac' exact component={AnalayseAc} />
            <PrivateRoute path='/zunsolar/vendorlocation' exact component={VendorLocationMap} />
            <PrivateRoute path='/zunsolar/inventory' exact component={ZunsolarInventory} />
            <PrivateRoute path='/zunsolar/combo' exact component={ZunsolarCombo} />
            <PrivateRoute path='/zunsolar/addinventory' exact component={ZunsolarAddInventory} />
            <PrivateRoute path='/zunsolar/createorder' exact component={ZunsolarCreateOrder} />
            <PrivateRoute path='/zunsolar/orders' exact component={ZunsolarAllOrderView} />
            <PrivateRoute path='/zunsolar/orders/invoices' exact component={ZunsolarOrdersInvoices} />
            <PrivateRoute path='/zunsolar/order/:id' exact component={ZunsolarOrderView} />
            <PrivateRoute path='/zunsolar/suborder/:id' exact component={ZunsolarSubOrderView} />
            <PrivateRoute path='/zunsolar/addcombo' exact component={ZunsolarAddCombo} />
            <PrivateRoute path='/zunpulse/downloadacdata' exact component={DownloadAcData} />
            <PrivateRoute path='/whatsapp/dashboard' exact component={WhatsAppAllLeads} />
            <PrivateRoute path='/whatsapp/dashboard/:id' exact component={WhatsAppCustomer} />
            <PrivateRoute path='/whatsapp/dashboard/metrics/presales' exact component={WhatsAppPreSalesMetrics} />
            <PrivateRoute path='/whatsapp/dashboard/metrics/postsales' exact component={WhatsAppPostSalesMetrics} />
            <PrivateRoute path='/zunsolar/opsdashboard/new' exact component={ZunsolarOpsDashboard} />
            <PrivateRoute path='/zunsolar/opsdashboard' exact component={ZunsolarOpsDashboardNew} />
            <PrivateRoute path='/zunpulse/createOrder/New' exact component={ZunpulseCreateOrder} />
            <PrivateRoute path='/zunpulse/createOrder' exact component={ZunpulseNewCreateOrder} />
            <PrivateRoute path='/zunpulse/opsdashboard/new' exact component={ZunpulseOpsDashboard} />
            <PrivateRoute path='/zunpulse/opsdashboard' exact component={ZunpulseOpsDashboardNew} />
            <PrivateRoute path='/zunpulse/burndashboard' exact component={ZunpulseBurnDashboard} />
            <PrivateRoute path='/zunpulse/addmarketingspending' exact component={zunpulseCreateMarketingSpending} />
            <PrivateRoute path='/zunpulse/addCombo' exact component={ZunpulseAddCombo} />
            <PrivateRoute path='/zunpulse/combo' exact component={ZunpulseCombo} />
            <PrivateRoute path='/zunsolar/warrantyDetails' exact component={WarrantyDetails} />
            <PrivateRoute path='/zunsolar/fulfillmentreport' exact component={FulfillmentReport} />
            <PrivateRoute path='/zunpulse/admin/product/:id' exact component={ZunpulseProducts} />
            <PrivateRoute path='/zunpulse/admin/products' exact component={ZunpulseAllProducts} />
            <PrivateRoute path='/zunpulse/admin/productsView' exact component={ZunpulseProductView} />
            <PrivateRoute path='/zunpulse/admin/productView/:id' exact component={SingleProductDetails  } />
            <PrivateRoute path='/zunpulse/admin/addcoupon' exact component={ZunpulseAddCoupon} />
            <PrivateRoute path='/zunpulse/repeatSales/notification' exact component={ZunpulseRepeatNotification} />
            <PrivateRoute path='/zunpulse/repeatSalesApp/' exact component={ZunpulseRepeatSalesApp} />
            <PrivateRoute path='/zunpulse/repeatSales/bannerView/:offerId' exact component={ZunpulseRepeatViewBanners  } />

            <PrivateRoute path='/zunsolar/pi/generate' exact component={ZunsolarGeneratePI} />
            <PrivateRoute path='/zunsolar/pi/generate-mobile' exact component={GeneratePiMobileView} />
            <PrivateRoute path='/zunsolar/pi/all' exact component={zunsolarPIOrders} />
            <PrivateRoute path='/zunsolar/procurement' exact component={ProcurementPortal} />
            <PrivateRoute path='/zunsolar/affiliate' exact component={AffiliatePortal} />
            <PrivateRoute path='/zunsolar/pi/invoice' exact component={ZunsolarPIInvoice} />
            <PrivateRoute path='/zunsolar/pi/bulkInvoices' exact component={ZunsolarPIInvoiceV2} />
            <PrivateRoute path='/zunpulse/wallet-transactions' exact component={WalletTransactions} />
            <PrivateRoute path='/zunpulse/redeemed-promocash' exact component={RedeemedPromocash} />
            <PrivateRoute path='/zunpulse/admin/couponView' exact component={ZunpulseCouponView} />
            <PrivateRoute path = '/returns' exact component={ReturnOrders} />
            <PrivateRoute path = '/returnOrders' exact component={AllReturnOrdersView} />
            <PrivateRoute path='/zunsolar/ticketing' exact component={IVRTicketingView} />
            <PrivateRoute path='/zunsolar/createTicket' exact component={CreateIVRTicket} />
            <PrivateRoute path = "/zunsolar/ticket/:ticketId" exact component={SingleIVRTicket}/>
            <PrivateRoute path='/zunsolar/currentCalls' exact component={IVRCurrentCallView} />
            <PrivateRoute path='/zunsolar/outboundCalls' exact component={IVROutBoundCallView} />
            <PrivateRoute path='/zunsolar/insidesalesoutbound' exact component={IVROutboundInsideSales} />
            <PrivateRoute path='/logistics' exact component={Logistics} />
            <PrivateRoute path='/logistics/manual-entry' exact component={LogisticsManualEntry} />
            <PrivateRoute path='/projectsales/createlead' exact component={ProjectSalesCreateLead} />
            <PrivateRoute path='/projectsales/all' exact component={ProjectSalesAllLeads} />
            <PrivateRoute path='/projectsales/lead/:id' exact component={ProjectSalesLeadView} />
            <PrivateRoute path='/rooftracker' exact component={RoofTracker} />
            <PrivateRoute path='/zunsolar/statusbulkupdate' exact component={OrderStatusBulkUpdate} />
            <PrivateRoute path='/followup' exact component={FollowUpMobile} />

            


          </Switch>
        </Router>
      </div>
    );
  }
}
export default connect()(App);
