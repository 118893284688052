import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../HeaderComponent';
import '../../styles/addVendor.css';
import axios from 'axios';
class AddVendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {}
        }
    }
    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        let vendorData = { ...this.state.formData, key:"bgir7LWsTD" };
        Object.keys(vendorData).forEach(field => {
            vendorData[field] = (vendorData[field] || "").trim();
        })
        let url = "https://gx7qoxrw3j.execute-api.ap-south-1.amazonaws.com/production/zunsolar/api/setvendors";
        let response = await axios.post(url, vendorData)
        let { data } = response;
        alert(data.message);
    }
    render() {
        return (
            <div className="addVendor">
                <Header />
                <div class="container">
                    <h4 style={{ fontWeight: "bold" }}>Add New Vendor</h4>
                    <hr className="addVendorHr"></hr>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Name
                            </label>
                            <div class="col-md-10">
                                <input type="text" className="form-control" name="Name" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Phone
                            </label>
                            <div class="col-md-10">
                                <input type="number" className="form-control" name="Phone" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Alternate Phone
                            </label>
                            <div class="col-md-10">
                                <input type="number" className="form-control" name="alternatePhone" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Email
                            </label>
                            <div class="col-md-10">
                                <input type="text" className="form-control" name="Email" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Name of Owner
                            </label>
                            <div class="col-md-10">
                                <input type="text" className="form-control" name="owner" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                State
                            </label>
                            <div class="col-md-10">
                                <input type="text" className="form-control" name="state" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                City
                            </label>
                            <div class="col-md-10">
                                <input type="text" className="form-control" name="city" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Pincode
                            </label>
                            <div class="col-md-10">
                                <input type="number" className="form-control" name="Pincode" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-2 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Address
                            </label>
                            <div class="col-md-10">
                                <input type="text" className="form-control" name="address" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                        </div>
                        <br></br>
                        <div className="callCountSubmit">
                            <button className="btn btn-success" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddVendor;