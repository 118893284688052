import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';

export default class UserRolesComponent extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      usersList: [],
      roles: [],
      access: [],
      loading: true,
      managerList:[]
    }
  }

  componentWillReceiveProps(props) {
    // console.log("this is called")
    // console.log("props.usersList", props.usersList);
    let usersList = props.usersList;
    let users =  props.usersList ? props.usersList['']:[]

    let emailList = users?.map((user)=>{ return user.email})

    if (Object.keys(usersList).length > 0) {
      this.setState({
        usersList: props.usersList ? props.usersList[''] : [],
        roles: props.usersList ? props.usersList.get : [],
        access: props.usersList ? props.usersList['access/get'] : [],
        loading: false,
        managerList:emailList
      }, () => {
        console.log("rolsss", this.state.roles)
      })
    }
  }

  handleRoleChange = (e, id, index) => {
    // console.log("eeeeee",e.target.value,id);
    let obj = {
      username: id,
      role: e.target.value
    }
    let user = [...this.state.usersList]
    user[index].role = e.target.value;
    this.props.changeRole(obj);
    this.setState({
      usersList: user
    })
  }

  handleUserManagerUpdate = (e, id, index) => {
    // console.log("eeeeee",e.target.value,id);
    let obj = {
      username: id,
      manager: e.target.value
    }
    let user = [...this.state.usersList]
    user[index].manager = e.target.value;
    this.props.addUserManager(obj);
    this.setState({
      usersList: user
    })
  }

  handleAccessChange = (e, email, index) => {
    console.log("", e.target.value, index)
    let { value } = e.target
    let obj = {
      username: email,
      access: e.target.value
    }
    console.log(obj)
    let user = JSON.parse(JSON.stringify(this.state.usersList))

    if (user[index].access.includes(value)) {
      console.log("userrrr", user[index]);
      let indexOfValue = user[index].access.indexOf(value);
      user[index].access.splice(indexOfValue, 1);
    } else {
      user[index].access.push(value)
    }
    this.setState({
      usersList: user
    })


    // let acc = user[index].access.length>0 ? user[index].access : []
    // let newArr =  acc.map((i)=>{
    //   if(acc.includes(e.target.value)){
    //     acc.splice(i,1)
    //     // user[index].access.concat(acc)
    //     return acc
    //   }
    //   acc.push(e.target.value)
    //   return acc
    //   // user[index].access.concat(acc)
    // })
    // console.log("newArrrrrr",newArr)
    console.log(user);
    this.props.changeAccess(obj)
  }

  handleAdminChange = (e, email, index) => {
    // console.log("e for button",e.target.value,email,index)
    let obj = {
      username: email
    }
    let user = [...this.state.usersList]
    user[index].isAdmin = !user[index].isAdmin;
    this.props.changeAdmin(obj);
    this.setState({
      usersList: user
    })
  }

  handleActiveChange = (e, email, index) => {
    console.log("e for button", e.target.value, email, index)
    let obj = {
      username: email
    }
    let user = [...this.state.usersList];
    user[index].isActive = !user[index].isActive;
    this.props.changeActive(obj);
    this.setState({
      usersList: user
    })
  }

  handleDelete = (e, email, index) => {
    console.log("email", email, index)
    let obj = {
      username: email
    }
    let users = [...this.state.usersList]
    console.log("userrrr", users)
    users.splice(index, 1)

    this.props.deleteUser(obj);

    this.setState({
      usersList: users
    })




    // let newUser = user.map((i) => {
    //   if (i == user[index]) {
    //     user.splice(i,1)
    //   }
    //   return user
    // })
    // console.log("newUser", newUser);

    // this.setState({
    //   usersList: newUser
    // })
  }


  render() {
    const columns = [
      // {
      //   Header : '#',
      //   accessor : 'abcd', 
      // },
      {
        Header: 'Email',
        accessor: 'username',
        width: 250
      },
      {
        Header: 'Manager',
        accessor: 'manager',
        width: 250
      },
     
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Access',
        accessor: 'access',
        Cell: row => (
          (row.value || []).join(',')
        )
      },
      {
        Header: 'isAdmin',
        accessor: 'isAdmin',
        Cell: row => (
          (row.value == true) ? 'true' : 'false'
        )
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: row => (
          (row.value == true) ? 'true' : 'false'
        )
      },
      // {
      //   Header: 'Try',
      //   accessor: 'abc',
      //   Cell: row => (
      //     <div className="dropdown" id="valueItemDrop">
      //       <button className="selectbox" id="dLabel" type="button" data-toggle="dropdown" aria-haspopup="true"
      //         aria-expanded="false">
      //         Select
      //       </button>
      //       <ul className="dropdown-menu" aria-labelledby="dLabel">
      //         <li className="checkbox form-group">
      //           <input type="checkbox" id="valuePot" value="Value Pot" name="Value Pot" />
      //           <label htmlFor="valuePot">Value Pot</label>
      //         </li>
      //         <li className="checkbox form-group">
      //           <input type="checkbox" id="payback" value="Payback" name="Payback" />
      //           <label htmlFor="payback">Payback</label>
      //         </li>
      //       </ul>
      //     </div>

      //   )
      // },
      {
        Header: 'Change Role',
        accessor: 'ab',
        Cell: row => {
          // console.log("row.original", row)
          return (

            <div>
              <select name="role" className="btn-sm btn-default" onChange={(e) => { this.handleRoleChange(e, row.original.email, row.index) }}>
                <option>Role</option>
                {(this.state.roles ? this.state.roles : []).map(e => <option key={e}>{e}</option>)}
              </select>
            </div>
          )
        }
      },
      {
        Header: 'Change Access',
        accessor: 'gh',
        Cell: row => (
          <div>
            <select name="access" className="btn-sm btn-default" onChange={(e) => { this.handleAccessChange(e, row.original.email, row.index) }}>
              <option>Access</option>
              {(this.state.access ? this.state.access : []).map(e => <option key={e}>{e}</option>)}
            </select>
          </div>
        )
      },
       {
        Header: 'Manager',
        accessor: 'manager',
        width: 250,
        Cell: row => {
          // console.log("row.original", row)
          return (

            <div>
              <select name="manager" className="btn-sm btn-default" onChange={(e) => { this.handleUserManagerUpdate(e, row.original.email, row.index) }}>
                <option>Select</option>
                {(this.state.managerList ? this.state.managerList : []).map(e => <option key={e}>{e}</option>)}
              </select>
            </div>
          )
        }
      },
      {
        Header: 'Change Admin',
        accessor: 'dg',
        Cell: row => {

          return (
            <div>
              <button className="btn btn-success btn-sm" value="admin" onClick={(e) => { this.handleAdminChange(e, row.original.email, row.index) }}>admin</button>
            </div>
          )
        }
      },
      {
        Header: 'Change Active',
        accessor: 'xv',
        Cell: row => (
          <div>
            <button className="btn btn-primary btn-sm" value="active" onClick={(e) => { this.handleActiveChange(e, row.original.email, row.index) }}>active</button>
          </div>
        )
      },
      {
        Header: 'Delete',
        accessor: 'hs',
        Cell: row => (
          <div>
            <button className="btn btn-danger btn-sm" onClick={(e) => { this.handleDelete(e, row.original.email, row.index) }}>delete</button>
          </div>
        )
      }

    ]
    return (
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <ReactTable
          filterable={true}
          loading={this.state.loading}
          showPagination={true}
          data={this.state.usersList}
          columns={columns}
          ref={this.reactTable}
        //    defaultPageSize = {2}
        //    pageSizeOptions = {[2,4,6]}
        />
      </div>
    )
  }
}
