import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { fetchMapping, createNewLead } from '../actions/projectSalesLeadActions';
import Header from '../components/HeaderComponent';
import { Loader } from '../components/loader';
import swal from '@sweetalert/with-react'

const phoneFields = [ "poc1Phone", "poc2Phone", "poc3Phone" ];
const emailFields = [ "poc1Email", "poc2Email", "poc3Email" ];

class ProjectSalesCreateLead extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leadData: {},
            mapping: {},
            received: true
        };
    }

    componentWillMount() {
        this.props.fetchMapping();
    }

    componentWillReceiveProps(props) {
        let { mapping } = props;
        this.setState({
            mapping
        })
    }

    inputType = (name, value, type) => {

        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    selectType = (name, value, options) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleInputChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    createLeadSubmit = () => {
        let { leadData } = this.state;
        console.log(leadData);
        for(let index = 0; index < phoneFields.length; index++) {
            let key = phoneFields[index];
            if(leadData[key] && !this.ValidatePhone(leadData[key])) {
                alert(`Invalid phone number - ${key}`);
                return;
            }
        }
        for(let index = 0; index < emailFields.length; index++) {
            let key = emailFields[index];
            if(leadData[key] && !this.ValidateEmail(leadData[key])) {
                alert(`Invalid email - ${key}`);
                return;
            }
        }
        this.props.createNewLead(leadData);
    }

    ValidateEmail = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    }

    ValidatePhone = (phone) => {
        if (/^[0-9]{10}$/.test(phone)) {
            return true;
        }
        return false;
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        if(phoneFields.includes(name) && !(/^[0-9]{0,10}$/.test(value))) {
            return;
        }
        this.setState({
            leadData: {
                ...this.state.leadData,
                [name]: value
            }
        })
    }

    render() {
        let { mapping = {} } = this.state;
        console.log(mapping);
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 60 }} className="container-fluid">
                    <div className="topbar-section" >
                        <div className="row">
                            <div className="col-md-10 col-xs-12 topbar-left">
                                <div className="col-md-12">
                                    <strong>Create Lead</strong>
                                </div>
                            </div>
                            <div className="col-md-2 col-xs-12 topbar-right">
                                <div className="col-md-12 col-xs-12">
                                    <button className="button-save" onClick={(e) => this.createLeadSubmit(e)}>Save</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table>
                                <tr>
                                    <td>Project Name</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("projectName", this.state.leadData.projectName, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Developer</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("developer", this.state.leadData.developer, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Project Type</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("projectType", this.state.leadData.projectType, mapping.projectType)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Possession Date</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("possessionDate", this.state.leadData.possessionDate, "date")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Smart Provision</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("smartProvision", this.state.leadData.smartProvision, mapping.smartProvision)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Project Stage</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("projectStage", this.state.leadData.projectStage, mapping.projectStage)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Launch Date</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("launchDate", this.state.leadData.launchDate, "date")}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table className="customer-info-left">
                                <tr>
                                    <td>Lead Status</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("status", this.state.leadData.status, mapping.status)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Responsible</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("responsible", this.state.leadData.responsible, mapping.responsible)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Last Visit Date</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("lastVisitDate", this.state.leadData.lastVisitDate, "date")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("address", this.state.leadData.address, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>
                                        <span className="input-group">
                                            {this.selectType("city", this.state.leadData.city, mapping.city)}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Next Action Date</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("nextActionDate", this.state.leadData.nextActionDate, "date")}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info">
                            <table className="customer-info-right">
                                <tr>
                                    <td>POC 1 Name</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc1Name", this.state.leadData.poc1Name, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 1 Team</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc1Team", this.state.leadData.poc1Team, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 1 Phone</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc1Phone", this.state.leadData.poc1Phone, "number")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 1 Email</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc1Email", this.state.leadData.poc1Email, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 2 Name</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc2Name", this.state.leadData.poc2Name, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 2 Team</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc2Team", this.state.leadData.poc2Team, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 2 Phone</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc2Phone", this.state.leadData.poc2Phone, "number")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 2 Email</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc2Email", this.state.leadData.poc2Email, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 3 Name</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc3Name", this.state.leadData.poc3Name, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 3 Team</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc3Team", this.state.leadData.poc3Team, "text")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 3 Phone</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc3Phone", this.state.leadData.poc3Phone, "number")}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>POC 3 Email</td>
                                    <td>
                                        <span className="input-group">
                                            {this.inputType("poc3Email", this.state.leadData.poc3Email, "text")}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div > : <Loader />}
            </div >
        );
    }
}



function mapStateToProps({ projectSales }) {
    let { mapping = {} } = projectSales;
    return {
        mapping
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMapping, createNewLead }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSalesCreateLead);