import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import Select from 'react-select';
import { orderColumns } from '../../config/column_fields';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Loader } from '../../components/loader';
import { fetchOrdersInvoices, downloadInvoicePdf } from '../../actions/zunpulseOrderTrackingActions';
import { invoiceColumns } from '../../config/column_fields';
import moment from 'moment';
import { CSVLink } from 'react-csv';

class ZunpulseOrdersInvoices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilter: false,
            showColumns: [],
            allData: [],
            viewData: [],
            filters: {},
            loading: true,
            received: false,
            ordersDataReceived: false,
            page: 0,
            limit: 500,
            limitoption: [
                { value: 500, label: '500' },
                { value: 1000, label: '1000' },
                { value: 5000, label: '5000' },
                { value: -1, label: 'All Leads'}
            ]
        };
    }

    componentWillMount() {
        this.props.fetchOrdersInvoices();
        // console.log(invoiceColumns);
        let showColumns = invoiceColumns.map((val) => {
            if (val.accessor == "invoiceNumber") {
                val.Cell = row => {
                    return (row.value ? <button onClick={() => this.props.downloadInvoicePdf(row.original.orderId, row.original.invoiceType)} className="btn btn-sm btn-link">{row.value}</button> : "")
                }
            }
            return val;
        })
        this.setState({
            showColumns
        })
    }

    componentWillReceiveProps(props) {
        let { ordersInvoices, ordersDataReceived  } = props;
        console.log(ordersInvoices);
        let dateFormatedInvoiceData =  ordersInvoices.map(row => ({...row,date:moment(row.date).format("DD MMM, YYYY")}))
        this.setState({
            loading: false,
            received: true,
            viewData: ordersInvoices,
            allData: dateFormatedInvoiceData,
            ordersDataReceived
        })
    }
    
    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        console.log('line no 69',value);
        if (name == 'orderId' && value !='') {
            filters[name] = [value];
        } else if (type=='date') {
            filters = JSON.parse(JSON.stringify(filters));
            filters[type] =  filters[type] || {};
            filters[type][name] = moment(value).valueOf();
        } else {
            filters = {};
        }
        
        this.setState({
            filters
        })
    }

    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })

    }


    multiSelectType = (name, options, placeholder, onChange, value) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }


    inputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={"Order Id"} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    handleFilterToggle = (e) => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val
        })
    }

    onApplyFilter = () => {
        let { limit, filters } = this.state;
        console.log(filters);
        this.props.fetchOrdersInvoices(0,limit,[],filters);
        this.setState({
          page: 0,
          orderDataReceived : false
        })
    }

    render() {

        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        let { showColumns, viewData = [], allData = [] , filters, ordersDataReceived } = this.state;
        let options = ['Tax','Cancelled'];
        let invoiceTypeOptions = options.map(option => ({label:option,value:option}));
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <div style={{ textAlign: "center" }}>
                                <h2 style={{ fontWeight: "bold" }}>Invoice View</h2>
                    </div>  
                    <div className="topbar-section allLeadViewFilters">
                        <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={this.handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b>
                                {/* <span style={{ paddingRight: "30px" }}><b>Total orders</b> : <b>{this.state.ordersData.length}</b></span> */}
                            <CSVLink style={{ marginLeft: "30px" }} filename={"zunpulseorderinvoice.csv"} data={ allData } type="button" className="btn btn-light btn-md">
                                        Download CSV <i className="fa fa-download"></i>
                            </CSVLink>
                        </div>
                        <hr style={this.state.showFilter ? style.show : style.hide}></hr>
                        <div className="row" style={this.state.showFilter ? style.show : style.hide}>
                            <div className="col-md-3">
                                {this.inputType("orderId", "Order Id", "text", this.handleInputDateChangeFilters,"orderId")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("startDate", "Start Date", "date", this.handleInputDateChangeFilters, "date")}
                            </div>
                            <div className="col-md-3">
                                {this.inputType("endDate", "End Date", "date", this.handleInputDateChangeFilters, "date")}
                            </div>
                            <div className="col-md-3">
                                {this.multiSelectType("invoiceType", invoiceTypeOptions, "Invoice Type", this.handleSelectChangeFilters)}
                            </div>

                            
                            <div className="col-md-3">
                                <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                            </div>
                        </div>
                    </div>
                    <ReactTable
                        filterable={true}
                        showPagination={true}
                        sortable={true}
                        data={viewData}
                        columns={showColumns}
                        defaultSorted={[{ id: "date", desc: true }]}
                        style={{ height: "90vh", textAlign: 'center' }}
                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                        loading={!ordersDataReceived}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}



function mapStateToProps({ zunpulseOrders }) {
    let { ordersInvoices = [],  ordersDataReceived } = zunpulseOrders || {};
    return {
        ordersInvoices,
        ordersDataReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchOrdersInvoices ,getSearchResult ,downloadInvoicePdf }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseOrdersInvoices);