import { FETCH_USER_START,FETCH_USER_SUCCESS} from '../actions/actiontypes';

export default function(state={},action){
  switch(action.type){
		case FETCH_USER_START:{
			return {};
    }
		case FETCH_USER_SUCCESS:{
			return {...state, ...action.payload};
		}
		default:
			return state;
	}
}