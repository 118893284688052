import React, { Component } from 'react';
import '../../styles/pulseLeadTransfer.css';
import Select, { NonceProvider } from 'react-select';
import { Loader } from '../loader';
import ReactTable from "react-table";
import { zunsolarLeadTransferColumns } from '../../config/column_fields';

export default class ZunsolarLeadTransferComponent extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      limit: 100,
      page: 1,
      filterData: {},
      loading: true,
      recieved: false,
      stageList: {},
      tableData: [],
      newTableData: [],
      bulkAssignMap: {},
      employeeLeadsMap: {},
      employeeLeadsMapCopy: {},
      allLeadsMap: {},
      reactTableLoader: true,
      isSubmitClicked: false,
      stageColorMap: {
        "New": "#ccd7e8",
        "Not Interested": "",
        "Interested": "",
        "Closing": "",
        "Sold": ""
      }
    }
    this.columns = [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "New",
        accessor: "New",
        Cell: row => {
          return (
            // this.inputTypeText("New", this.state.tableData.map((value) => { return value.New }), "number")
            <div>
              <p>New : {row.value}</p>
              {this.inputTypeText("New", row.original, this.state.employeeLeadsMapCopy[row.original.Name] ? this.state.employeeLeadsMapCopy[row.original.Name]["New"] : 0)}
            </div>

          )
        }
      },
      {
        Header: "Not Interesed",
        accessor: "Not Interested",
        Cell: row => {
          return (
            <div>
              <p>Not Interested : {row.value}</p>
              {this.inputTypeText("Not Interested", row.original, this.state.employeeLeadsMapCopy[row.original.Name] ? this.state.employeeLeadsMapCopy[row.original.Name]["Not Interested"] : 0)}
            </div>
            // <input style={{}} type="number" value={this.state.tableData.map((value) => { return value["Not Interested"] })} />
          )
        }
      },
      {
        Header: "Interested",
        accessor: "Interested",
        Cell: row => {
          return (
            <div>
              <p>Interested : {row.value}</p>
              {this.inputTypeText("Interested", row.original, this.state.employeeLeadsMapCopy[row.original.Name] ? this.state.employeeLeadsMapCopy[row.original.Name]["Interested"] : 0)}
            </div>
          )
        }
      },
      {
        Header: "Closing",
        accessor: "Closing",
        Cell: row => {
          return (
            <div>
              <p>Closing : {row.value}</p>
              {this.inputTypeText("Closing", row.original, this.state.employeeLeadsMapCopy[row.original.Name] ? this.state.employeeLeadsMapCopy[row.original.Name]["Closing"] : 0)}
            </div>
            // <input style={{}} type="number" value={this.state.tableData.map((value) => { return value.Closing })} />
          )
        }
      },
      {
        Header: "Sold",
        accessor: "Sold",
        Cell: row => {
          return (
            <div>
              <p>Sold : {row.value}</p>
              {this.inputTypeText("Sold", row.original, this.state.employeeLeadsMapCopy[row.original.Name] ? this.state.employeeLeadsMapCopy[row.original.Name]["Sold"] : 0)}

            </div>
            // <input style={{}} type="number" value={this.state.tableData.map((value) => { return value.Sold })} />
          )
        }
      },
      {
        Header: "Submit",
        Cell: row => {
          return (
            <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleReactTableSubmit(e, row.original) }}>Submit</button>
          )
        }
      }
    ]
  }


  componentWillReceiveProps(props) {
    console.log(props.leadTransferData)
    let leadTransferData = props.leadTransferData ? props.leadTransferData : {}
    let allMapping = props.allMapping ? props.allMapping : {};
    if (allMapping.employees) {
      let stages = this.optionFunction(allMapping.stage || []);
      let type = this.optionFunction(allMapping.type || []);
      let active = this.optionFunction(allMapping.active || []);
      let employees = this.optionFunction(props.allMapping.employees ? props.allMapping.employees : [], "e_name");
      let city = this.optionFunction(allMapping.city || []);
      this.setState({
        stages: stages,
        type: type,
        active: active,
        city: city,
        employees: employees,
        loading: false,
        recieved: true
      })
    }
    if (Object.keys(leadTransferData).length > 0) {
      let stages = this.optionFunction(allMapping.stage || []);
      // let tableData = [];
      let employeeLeads = leadTransferData.employeeLeads || {};

      let modifiedEmployeeLeads = {}
      Object.keys(employeeLeads).map((value) => {
        employeeLeads[value]["Name"] = value
        // modiffiedEmployeeLeads = {
        //   ["Name"]: value,
        //   ...employeeLeads[value]

        // }
        // return modifiedEmployeeLeads
      })
      // let tableData = Object.values(employeeLeads)
      let employeeLeadsMapCopy = JSON.parse(JSON.stringify(employeeLeads));
      let allLeads = leadTransferData.allLeads || {}
      let stageList = {}
      Object.keys(allLeads).map((stage) => {
        return stageList[stage] = allLeads[stage].length
      })
      // stages.map((stage) => {
      //   if (allLeads[stage]) {
      //     return stageList.stage = allLeads[stage].length
      //   }
      // })
      this.setState({
        stages: stages,
        stageList: stageList,
        // tableData: tableData,
        employeeLeadsMap: employeeLeadsMapCopy,
        // employeeLeadsMapCopy: employeeLeads,
        allLeadsMap: allLeads,
        reactTableLoader: false

      }, () => {

      })
    }
  }

  optionFunction = (array, value) => {

    let multiSelectOptionsMap = array.map((data) => {
      let obj = {}
      if (data[value]) {
        obj = {
          label: data[value].replace(/(^"|"$)/g, ''),
          value: data[value].replace(/(^"|"$)/g, '')
        }
      } else {
        obj = {
          label: data,
          value: data
        }
      }
      return obj
    })
    return multiSelectOptionsMap;
  }

  handleReactTableInputChange = (e, row) => {

    let { name, value } = e.target
    let nameOfEmployee = row.Name
    let newMap = { ...this.state.employeeLeadsMapCopy }
    newMap[nameOfEmployee] = newMap[nameOfEmployee] || {}
    newMap[nameOfEmployee][name] = value
    this.setState({
      employeeLeadsMapCopy: newMap
      // tableData: oldTableData
    })
  }

  inputTypeText = (name, row, value) => {

    return <input type="number" className="form-control" name={name} value={value} onChange={(e) => this.handleReactTableInputChange(e, row)} />

  }

  handleMultiSelectChange = (e, name) => {

    let selectedList = (e || []).map((data) => {
      return data.label
    });
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: selectedList
      },
      page: 1
    }, () => {
    })
  }
  multiSelectType = (name, options, value) => {
    return (
      <div className="form-group" style={{ width: '100%' }}>
        <Select
          isMulti
          isSearchable
          onChange={(e) => { this.handleMultiSelectChange(e, name) }}
          // placeholder={placeholder}
          name={name}
          options={options}
          value={value}
        />
      </div>
    )
  }

  handleReactTableSubmit = (e, row) => {
    let name = row.Name;
    let leadIdArr = [];

    //  updating table data real time
    let employeeLeadsMap = { ...this.state.employeeLeadsMap };
    let employeeLeadsMapCopy = JSON.parse(JSON.stringify(this.state.employeeLeadsMapCopy));
    // employeeLeadsMapCopy[name];

    Object.keys(employeeLeadsMap[name]).map((stage) => {
      if (stage != "Name") {
        employeeLeadsMap[name][stage] = (employeeLeadsMap[name][stage] || 0) + parseInt((employeeLeadsMapCopy[name] || {})[stage] || 0)

      }
    })
    // updating table data real time ends

    // updating all leads data real time 
    // let employeeLeadsMapCopy = { ...this.state.employeeLeadsMapCopy };
    let allLeadsMap = { ...this.state.allLeadsMap };
    Object.keys(employeeLeadsMapCopy[name]).map((stage) => {
      if (stage != "Name") {
        leadIdArr.push(...allLeadsMap[stage].splice(0, employeeLeadsMapCopy[name][stage]));
        employeeLeadsMapCopy[name][stage] = 0
      }
    })
    let finalObj = {
      responsible_person: name,
      solarId: leadIdArr.map(lead => lead.solarId)
    }
    this.props.editBulkLeads(finalObj, "zunsolar")
    this.setState({
      allLeadsMap: allLeadsMap,
      employeeLeadsMap: employeeLeadsMap,
      employeeLeadsMapCopy: employeeLeadsMapCopy
    }, () => {
    })
  }

  removingNullKeys = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  onApplyFilter = (e) => {
    let filterData = { ...this.state.filterData }
    filterData = JSON.parse(JSON.stringify(filterData));
    let filterObj = this.removingNullKeys(filterData);
    let { limit, page } = this.state;
    this.props.leadTransferGet(filterObj, "zunsolar")
    this.setState({
      reactTableLoader: true,
      isSubmitClicked: true
    })
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',
        padding: 20,
      })
    }
    if (this.state.recieved) {
      return (
        <div class="container-fluid" style={{ marginTop: "90px", backgroundColor: "#f7f7f7" }}>
          <div style={{ textAlign: "center" }}>
            <h2 style={{ fontWeight: "bold" }}>Solar Lead Transfer</h2>
          </div>
          <div className="row leadtransferTopbarSection">
            <div className="col-md-3">
              <label>Stage</label>
              {this.multiSelectType("stage", this.state.stages, (this.state.filterData.stage || []).map((val) => ({ label: val, value: val })))}
            </div>
            <div className="col-md-3">
              <label>Active</label>
              {this.multiSelectType("active", this.state.active, (this.state.filterData.active || []).map((val) => ({ label: val, value: val })))}
            </div>
            <div className="col-md-3">
              <label>Type</label>
              {this.multiSelectType("leadType", this.state.type, (this.state.filterData.leadType || []).map((val) => ({ label: val, value: val })))}
            </div>
            <div className="col-md-3">
              <label>City</label>
              {this.multiSelectType("city", this.state.city, (this.state.filterData.city || []).map((val) => ({ label: val, value: val })))}
            </div>
            <div className="col-md-3">
              <label>Responsible</label>
              {this.multiSelectType("responsible_person", this.state.employees, (this.state.filterData.responsible_person || []).map((val) => ({ label: val, value: val })))}
            </div>
            <div className="col-md-3">
              <label>New Responsible</label>
              {this.multiSelectType("employees", this.state.employees, (this.state.filterData.employees || []).map((val) => ({ label: val, value: val })))}
            </div>
            <div className="col-md-3">
              <button className="btn btn-success leadTransferSubmitButton" onClick={(e) => { this.onApplyFilter() }}>Submit</button>
            </div>
          </div>


          {!this.state.isSubmitClicked ? "" : !this.state.reactTableLoader ? <div>
            <div className="row countSection">
              {this.state.allLeadsMap ? (Object.keys(this.state.stageColorMap)).map((stage) => {
                console.log(stage, this.state.allLeadsMap[stage])
                let color = this.state.stageColorMap[stage]
                return (
                  <div className="col-md-2" style={{ minHeight: "40px", background: color }}>
                    <h6 style={{ fontWeight: "bold", width: "70%" }}>{stage} : {this.state.allLeadsMap ? (this.state.allLeadsMap)[stage].length : ''}</h6>
                  </div>
                )
              }) : ""}
            </div>

            <div className="table-section">
              <ReactTable
                // loading={this.state.loading}
                filterable={true}
                sortable={false}
                showPagination={true}
                data={Object.values(this.state.employeeLeadsMap)}
                defaultPageSize={10}
                columns={this.columns}
                ref={this.reactTable}
                style={{ backgroundColor: "#fff" }}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
              //    defaultPageSize = {2}
              //    pageSizeOptions = {[2,4,6]}
              />
            </div>
          </div>
            :
            "Loading.... Please Wait!!!"}
        </div>
      )
    }
    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }
  }
}

