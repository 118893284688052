import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../HeaderComponent';
import '../../styles/analyseAc.css';
import axios from 'axios';
import moment from 'moment';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Brush,
} from 'recharts';
class AnalyseAc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            acData: {},
            submitClicked: false,
            loading: false
        }
    }
    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            submitClicked: true,
            loading: true
        })
        let { formData } = this.state;
        let requestData = {
            deviceId: formData.deviceId,
            startEpoch: (moment(formData.startDate, "YYYY-MM-DD").valueOf()) / 1000,
            endEpoch: (moment(formData.endDate, "YYYY-MM-DD").valueOf()) / 1000,
            ...formData
        };
        let url = "http://52.230.66.214:5000/getacdata";
        // let url = "http://localhost:5000/getacdata";
        let response = await axios.post(url, requestData)
        let { data } = response;
        let { phase1, phase2, phase3, error } = data;
        if(error){
            this.setState({loading:false})
            alert(error)
            return;
        }
        let chartDataPhase1 = (phase1.epoch || []).map((epoch, index) => {
            return ({
                epoch: moment(parseInt(epoch) * 1000).format("DD MMM, YYYY HH:mm:ss"),
                power: phase1.power[index],
                ac: phase1.graphValues[index],
                peakDetected: phase1.peakDetected.includes(epoch)?1000:0,
                geyserData: phase1.graphValuesGeyser[index]
            })
        })
        let chartDataPhase2 = (phase2.epoch || []).map((epoch, index) => {
            return ({
                epoch: moment(parseInt(epoch) * 1000).format("DD MMM, YYYY HH:mm:ss"),
                power: phase2.power[index],
                ac: phase2.graphValues[index],
                peakDetected: phase2.peakDetected.includes(epoch)?1000:0,
                geyserData: phase2.graphValuesGeyser[index]
            })
        })
        let chartDataPhase3 = (phase3.epoch || []).map((epoch, index) => {
            return ({
                epoch: moment(parseInt(epoch) * 1000).format("DD MMM, YYYY HH:mm:ss"),
                power: phase3.power[index],
                ac: phase3.graphValues[index],
                peakDetected: phase3.peakDetected.includes(epoch)?1000:0,
                geyserData: phase3.graphValuesGeyser[index]
            })
        })
        let calculatedData = {
            phase1: {
                chartData: chartDataPhase1,
                values: {
                    acEnergy: phase1.energyAc,
                    acTime: phase1.time,
                    totalEnergy: phase1.totalEnergy,
                    totalEnergyGeyser: phase1.totalEnergyGeyser,
                    totalTimeGeyser: phase1.totalTimeGeyser,
                    averagePower: phase1.averagePower,
                    peakDetected: phase1.peakDetected.map(time => moment(parseInt(time)*1000).format("DD MMM, YYYY hh:mm:ss")),
                    averagePowerAC: phase1.averagePowerAC,
                    averagePower2AC: phase1.averagePower2AC
                }
            },
            phase2: {
                chartData: chartDataPhase2,
                values: {
                    acEnergy: phase2.energyAc,
                    acTime: phase2.time,
                    totalEnergy: phase2.totalEnergy,
                    totalEnergyGeyser: phase2.totalEnergyGeyser,
                    totalTimeGeyser: phase2.totalTimeGeyser,
                    averagePower: phase2.averagePower,
                    peakDetected: phase2.peakDetected.map(time => moment(parseInt(time)*1000).format("DD MMM, YYYY hh:mm:ss")),
                    averagePowerAC: phase2.averagePowerAC,
                    averagePower2AC: phase2.averagePower2AC,
                }
            },
            phase3: {
                chartData: chartDataPhase3,
                values: {
                    acEnergy: phase3.energyAc,
                    acTime: phase3.time,
                    totalEnergy: phase3.totalEnergy,
                    totalEnergyGeyser: phase3.totalEnergyGeyser,
                    totalTimeGeyser: phase3.totalTimeGeyser,
                    averagePower: phase3.averagePower,
                    peakDetected: phase3.peakDetected.map(time => moment(parseInt(time)*1000).format("DD MMM, YYYY hh:mm:ss")),
                    averagePowerAC: phase3.averagePowerAC,
                    averagePower2AC: phase3.averagePower2AC,
                }
            }
        }
        this.setState({
            acData: calculatedData,
            loading: false
        })
    }
    render() {
        let { acData, submitClicked, loading } = this.state;
        let { phase1 = {}, phase2 = {}, phase3 = {} } = acData;
        return (
            <div className="analyseAc">
                <Header />
                <div class="container">
                    <h4 style={{ fontWeight: "bold" }}>Analyse Ac</h4>
                    <hr className="analyseAcHr"></hr>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="row">
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Device Id *
                            </label>
                            <div class="col-md-8">
                                <input type="text" className="form-control" name="deviceId" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Start Date *
                            </label>
                            <div class="col-md-8">
                                <input type="date" className="form-control" name="startDate" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                End Date *
                            </label>
                            <div class="col-md-8">
                                <input type="date" className="form-control" name="endDate" onChange={(e) => this.handleInputChange(e)} required></input>
                            </div>
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Change In Power(1 AC)
                            </label>
                            <div class="col-md-4">
                                <input type="number" placeholder="Start(In W)" className="form-control" name="powerChangeStart" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <div class="col-md-4">
                                <input type="number" placeholder="End(In W)" className="form-control" name="powerChangeEnd" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Change In Power(2 AC)
                            </label>
                            <div class="col-md-4">
                                <input type="number" placeholder="Start(In W)" className="form-control" name="powerChangeStart2" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <div class="col-md-4">
                                <input type="number" placeholder="End(In W)" className="form-control" name="powerChangeEnd2" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                AC Cycle Time
                            </label>
                            <div class="col-md-8">
                                <input type="number" placeholder="Seconds" className="form-control" name="timeChange" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                            <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                                Fan Power
                            </label>
                            <div class="col-md-8">
                                <input type="number" placeholder="In W" className="form-control" name="fanPower" onChange={(e) => this.handleInputChange(e)}></input>
                            </div>
                        </div>
                        <br></br>
                        <div className="callCountSubmit">
                            <button className="btn btn-success" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
                {submitClicked ? (loading ? "Loading..." : <div className="row mt-5">
                    <div className="col-4">
                        {calculatedValues(phase1.values, 1)}
                        {drawChart(phase1.chartData)}
                    </div>
                    <div className="col-4">
                        {calculatedValues(phase2.values, 2)}
                        {drawChart(phase2.chartData)}
                    </div>
                    <div className="col-4">
                        {calculatedValues(phase3.values, 3)}
                        {drawChart(phase3.chartData)}
                    </div>
                </div>) : ""}
            </div>
        )
    }
}

const calculatedValues = (data, phase) => {
    let { acEnergy, acTime, totalEnergy, averagePower, peakDetected = [], averagePowerAC, averagePower2AC, totalEnergyGeyser, totalTimeGeyser  } = data || {};
    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <h4>{`Phase${phase || ""}`}</h4>
            </div>
            <div className="row">
                <div className="col-12">
                    Energy(AC) - {acEnergy} kWh
                </div>
                <div className="col-12">
                    Energy(Total) - {totalEnergy} kWh
                </div>
                <div className="col-12">
                    Time(AC) - {acTime} Hrs
                </div>
                <div className="col-12">
                    Average Change In Power(AC) - {averagePower} W
                </div>
                <div className="col-12">
                    Average Power(1 AC) - {averagePowerAC} W
                </div>
                <div className="col-12">
                    Average Power(2 AC) - {averagePower2AC} W
                </div>
                <div className="col-12">
                    Peak Detected - {peakDetected.join(',')}
                </div>
                <div className="col-12">
                    Energy(Geyser) - {totalEnergyGeyser} kWh
                </div>
                <div className="col-12">
                    Time(Geyser) - {totalTimeGeyser} Hrs
                </div>
            </div>
        </div>
    )
}

const drawChart = (data) => {
    return (
        <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
                top: 10, right: 30, left: 0, bottom: 0,
            }}
        >
            <Brush></Brush>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="epoch" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="power" stackId="1" stroke="#8884d8" fill="#8884d8" />
            <Area type="monotone" dataKey="ac" stackId="2" stroke="#82ca9d" fill="#82ca9d" />
            <Area type="monotone" dataKey="peakDetected" stackId="3" stroke="#ffc658" fill="#ffc658" />
            <Area type="monotone" dataKey="geyserData" stackId="4" stroke="#FF5733" fill="#FF5733" />
        </AreaChart>
    );
}

export default AnalyseAc;