import React from "react";
import { getSearchResult } from "../../actions/searchAction";
import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { affliateKYCColumns } from "../../config/column_fields";

class AffliateKYCList extends Component {
  constructor() {
    super();
    this.state = {
      KYCList: [],
      KYCStatus: [],
      KYCListColumns: [],
    };
  }

  componentDidMount() {
    let KYCListColumns = affliateKYCColumns.map((row) => {
      if (row.accessor == "status") {
        row.Cell = (row) => {
          return this.state.KYCList[row.index].status === "false" ? (
            <button
              onClick={() => this.handleStatusChange(row.index)}
              className="btn btn-primary btn-sm"
            >
              Click to Approve
            </button>
          ) : (
            <button className="btn btn-success" disabled>
              Approved
            </button>
          );
        };
        
      }
      if (row.accessor == "panCard") {
        row.Cell = (row) => (
          <button onClick={()=>this.downloadClick(row.index)} className="btn btn-sm btn-link">
            Download
          </button>
        );
      }
      return row;
    });
    console.log(KYCListColumns);
    this.setState({ KYCListColumns });
  }

  componentWillMount() {
    this.props.fetchKYCList();
  }

  componentWillReceiveProps({ KYCList = [] }) {
    this.setState({ KYCList });
  }

  handleStatusChange = (index) => {
    let { KYCList } = this.state;
    KYCList[index].status = "true";
    this.setState({
      KYCList: KYCList,
    });
    let phoneNumber = KYCList[index].phoneNumber;
    this.props.updateKYCStatus({ phoneNumber });
  };

  downloadClick = (index) => {
    let { KYCList = [] } = this.state;
    let KYCData = KYCList[index] || {};
    let fileName = KYCData.panCard;
    console.log(fileName);
    this.props.downloadFile(fileName);
  };

  render() {
    let { KYCList, KYCListColumns } = this.state;

    return (
      <div className="procurementAddVendor">
        <ReactTable
          filterable={true}
          showPagination={true}
          sortable={true}
          data={KYCList}
          columns={KYCListColumns}
          defaultSorted={[{ id: "id", desc: true }]}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AffliateKYCList);
