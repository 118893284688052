import { FETCH_KYC_DETAILS, START_ADD_KYC ,ADD_KYC_SUCCESS, FETCH_AFFILIATE_DETAILS , FETCH_CONSOLIDATED_DETAILS , FETCH_DETAILED_DETAILS, ADD_AFFILIATE_SUCCESS, START_ADD_AFFILIATE, START_ADD_PAYMENT, ADD_PAYMENT_SUCCESS } from "../actions/actiontypes";

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_KYC_DETAILS: {
      console.log(action.payload);
      return { ...state, KYCList: action.payload || [] };
    }
    case START_ADD_KYC: {
      return {...state, isAdded:false };
    }
    case ADD_KYC_SUCCESS: {
      return {...state, isAdded:true };
    }
    case START_ADD_AFFILIATE: {
      return {...state, isAffiliateAdded:false};
    }
    case ADD_AFFILIATE_SUCCESS: {
      return {...state, isAffiliateAdded:true };
    }
    case FETCH_AFFILIATE_DETAILS: {
      return { ...state, affiliateList: action.payload || [] };
    }
    case FETCH_CONSOLIDATED_DETAILS: {
      return { ...state, consolidatedList: action.payload || [] };
    }
    case FETCH_DETAILED_DETAILS: {
      return { ...state, detailedList: action.payload || [] };
    }
    case START_ADD_PAYMENT: {
      return { ...state, isPaymentAdded:false};
    }
    case ADD_PAYMENT_SUCCESS: {
      return {...state, isPaymentAdded:true};
    }
    default:
      return state;
  }
}
