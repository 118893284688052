import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { weeklyOrderColumns, fulfillmentReportDurationColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchMapping, fetchCombo } from '../../actions/zunsolarOrderTrackerActions';
import { fetchWeeklyOrders, fetchWeeklyDispatchStatus, fetchWeeklyDeliveredOrderStatus, fetchReturnedReceivedOrderStatus, fetchReturningOrderStatus } from '../../actions/zunsolarOpsDashboardActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader'
import { ReactTableCardComponent } from '../../components/zunsolar/ZunsolarOpsDashboardComponent';

class FulfillmentReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: {
                date: {
                    startDate: moment().subtract(6, 'days').format("YYYY-MM-DD"),
                    endDate: moment().format('YYYY-MM-DD'),
                },
                state: [],
                category: [],
                subCategory: [],
                platform: []
            },
            mapping: {},
            subCategoryOptions: [],
            received: false
        };
    }

    componentWillMount() {
        this.props.fetchMapping();
        this.props.fetchCombo();
        this.applyFilter();
    }

    componentWillReceiveProps(props) {
        let { mapping = {}, comboList, weeklyOrders, weeklyDispatchStatus, weeklyDeliveredOrderStatus, returnedReceivedOrderStatus, returningOrderStatus, weeklyOrdersReceived = false, weeklyDispatchStatusReceived = false, weeklyDeliveredOrderStatusReceived = false, returnedReceivedOrderStatusReceived = false, returningOrderStatusReceived = false } = props;
        this.setState({
            mapping,
            comboList,
            weeklyOrdersReceived,
            weeklyDispatchStatusReceived,
            weeklyDeliveredOrderStatusReceived,
            returnedReceivedOrderStatusReceived,
            returningOrderStatusReceived,
            weeklyOrders,
            weeklyDispatchStatus,
            weeklyDeliveredOrderStatus,
            returnedReceivedOrderStatus,
            returningOrderStatus,
            received: true
        })
    }

    handleFilterChange = (name, value) => {
        let { comboList = [], mapping } = this.state;
        let { category } = mapping;
        let appliedFilters = (value || []).map(option => option.value);
        if (name == 'category') {
            let subCategoryOptions = [];
            appliedFilters.map(val => {
                let options = (val == "Combos") ? comboList.map(combo => combo.comboId) : category[val];
                subCategoryOptions = [...subCategoryOptions, ...(options || [])]
            })
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: appliedFilters
                },
                subCategoryOptions: subCategoryOptions
            })
        }
        else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: appliedFilters
                }
            })
        }
    }

    handleDateFilterChange = (dateType, e) => {
        let { name, value } = e.target;
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        filters[dateType][name] = value;
        this.setState({
            filters
        })
    }

    applyFilter = () => {
        let { filters = {} } = this.state;
        console.log("filters", filters)
        this.props.fetchWeeklyOrders(filters);
        this.props.fetchWeeklyDispatchStatus(filters);
        this.props.fetchWeeklyDeliveredOrderStatus(filters);
        this.props.fetchReturnedReceivedOrderStatus(filters);
        this.props.fetchReturningOrderStatus(filters);
        this.setState({
            weeklyOrdersReceived: false,
            weeklyDispatchStatusReceived: false,
            weeklyDeliveredOrderStatusReceived: false,
            returnedReceivedOrderStatusReceived: false,
            returningOrderStatusReceived: false
        })
    }

    render() {
        let { mapping = {}, filters, subCategoryOptions, weeklyOrders, weeklyDispatchStatus, weeklyDeliveredOrderStatus, returnedReceivedOrderStatus, returningOrderStatus, weeklyOrdersReceived, weeklyDispatchStatusReceived, weeklyDeliveredOrderStatusReceived, returnedReceivedOrderStatusReceived, returningOrderStatusReceived } = this.state;
        let { handleFilterChange, handleDateFilterChange, applyFilter } = this;
        let { platform, states, category, status } = mapping;
        let platformOptions = (platform || []).map(option => ({ label: option, value: option }))
        let categoryOptions = Object.keys(category || {}).map(category => ({ label: category, value: category }));
        let stateOptions = (states || []).map(state => ({ label: state, value: state }))
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <div className="container">
                        <h4 style={{ fontWeight: "bold", textAlign: "center" }}>Fulfillment Report</h4>
                        <hr className="analyseAcHr"></hr>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                    </div>
                                    <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateFilterChange("date", e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                    </div>
                                    <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateFilterChange("date", e)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <Select
                                isSearchable
                                isMulti
                                onChange={(e) => handleFilterChange("state", e)}
                                placeholder={"Choose State"}
                                name={"state"}
                                options={stateOptions}
                            />
                        </div>
                        <div className="col-md">
                            <Select
                                isSearchable
                                isMulti
                                onChange={(e) => handleFilterChange("category", e)}
                                placeholder={"Choose Category"}
                                name={"category"}
                                options={categoryOptions}
                            />
                        </div>
                        <div className="col-md">
                            <Select
                                isSearchable
                                isMulti
                                onChange={(e) => handleFilterChange("subCategory", e)}
                                placeholder={"Choose Sub Category"}
                                name={"subcategory"}
                                options={(subCategoryOptions||[]).map(value => ({label: value, value: value}))}
                            />
                        </div>
                        <div className="col-md">
                            <Select
                                isSearchable
                                isMulti
                                onChange={(e) => handleFilterChange("platform", e)}
                                placeholder={"Platform"}
                                name={"platform"}
                                options={platformOptions}
                            />
                        </div>
                        <div className="col-md">
                            <button onClick={(e) => applyFilter()} className="btn btn-success btn-sm">Apply Filter</button>
                        </div>
                    </div>
                    <ReactTableCardComponent
                        columns={weeklyOrderColumns}
                        data={weeklyOrders}
                        loading={!weeklyOrdersReceived}
                        cardHeading={"Weekly Orders"}
                        showPagination={false}
                        defaultSorted={[]}
                        pageSize={weeklyOrders.length}
                    />
                    <ReactTableCardComponent
                        columns={fulfillmentReportDurationColumns}
                        data={weeklyDispatchStatus}
                        loading={!weeklyDispatchStatusReceived}
                        cardHeading={"Weekly Dispatch Status"}
                        showPagination={false}
                        defaultSorted={[]}
                        pageSize={weeklyDispatchStatus.length}
                    />
                    <ReactTableCardComponent
                        columns={fulfillmentReportDurationColumns}
                        data={weeklyDeliveredOrderStatus}
                        loading={!weeklyDeliveredOrderStatusReceived}
                        cardHeading={"Weekly Delivered Order Status"}
                        showPagination={false}
                        defaultSorted={[]}
                        pageSize={weeklyDeliveredOrderStatus.length}
                    />
                    <ReactTableCardComponent
                        columns={fulfillmentReportDurationColumns}
                        data={returnedReceivedOrderStatus}
                        loading={!returnedReceivedOrderStatusReceived}
                        cardHeading={"Returned Received Order Status"}
                        showPagination={false}
                        defaultSorted={[]}
                        pageSize={returnedReceivedOrderStatus.length}
                    />
                    <ReactTableCardComponent
                        columns={fulfillmentReportDurationColumns}
                        data={returningOrderStatus}
                        loading={!returningOrderStatusReceived}
                        cardHeading={"Returning Orders Status"}
                        showPagination={false}
                        defaultSorted={[]}
                        pageSize={returningOrderStatus.length}
                    />
                </div> : <Loader />}
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps({ zunSolarOrderTracker, zunSolarOpsDashboard = {} }) {
    let { mapping = {}, comboList = [] } = zunSolarOrderTracker || {};
    let { weeklyOrders = [], weeklyDispatchStatus = [], weeklyDeliveredOrderStatus = [], returnedReceivedOrderStatus = [], weeklyOrdersReceived = false, weeklyDispatchStatusReceived = false, weeklyDeliveredOrderStatusReceived = false, returnedReceivedOrderStatusReceived = false, returningOrderStatus = [], returningOrderStatusReceived = false } = zunSolarOpsDashboard;
    return {
        mapping,
        comboList,
        weeklyOrders,
        weeklyOrdersReceived,
        weeklyDispatchStatus,
        weeklyDispatchStatusReceived,
        weeklyDeliveredOrderStatus,
        weeklyDeliveredOrderStatusReceived,
        returnedReceivedOrderStatus,
        returnedReceivedOrderStatusReceived,
        returningOrderStatus,
        returningOrderStatusReceived
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchWeeklyOrders, fetchWeeklyDispatchStatus, fetchWeeklyDeliveredOrderStatus, fetchReturnedReceivedOrderStatus, fetchReturningOrderStatus, fetchMapping, fetchCombo, getSearchResult }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentReport);