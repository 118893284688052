import React from 'react';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { fetchMappingZunpulse, fetchAllMapping } from '../../actions/fetchAllMapping';
import { editBulkLeads } from '../../actions/editMultipleLeadsAction';
import { leadTransferGet } from '../../actions/leadTransferAction';
import { render } from '@testing-library/react';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorComponent } from '../../components/ErrorComponent';
import { Loader } from '../../components/loader';
import ZunpulseLeadTransferComponent from '../../components/zunpulseComponents/ZunpulseLeadTransferComponent';

class ZunpulseLeadTransfer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      recieved: false
    }
  }
  componentWillMount() {
    let viewList = ["employees"]
    this.props.fetchAllMapping(viewList)
    this.props.fetchMappingZunpulse();
  }

  render() {
    if (this.props.error) {
      return (
        <div>
          <ErrorComponent error={this.props.error} />
        </div>
      )
    }

    return (
      <div>
        <Header />
        <ZunpulseLeadTransferComponent allMapping={this.props.allMapping} leadTransferGet={this.props.leadTransferGet} leadTransferData={this.props.leadTransferData} editBulkLeads={this.props.editBulkLeads} />
      </div>
    )
  }
}


function mapStateToProps(state) {
  // console.log("searchData", state)

  return {
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    leadTransferData: state.leadTransferData ? state.leadTransferData : {}
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchMappingZunpulse, fetchAllMapping, leadTransferGet, editBulkLeads }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseLeadTransfer);