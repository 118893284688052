//for single lead
export const FETCH_BITRIXID = 'FETCH_BITRIXID';
export const FETCH_BITRIXID_START = 'FETCH_BITRIXID_START';
export const FETCH_BITRIXID_SUCCESS = 'FETCH_BITRIXID_SUCCESS';

export const FETCH_COMMENTS_START = 'FETCH_COMMENTS_START';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';

export const POST_COMMENTS_START = 'POST_COMMENTS_START';
export const POST_COMMENTS_SUCCESS = 'POST_COMMENTS_SUCCESS';

export const FETCH_ALL_LEADS_START = 'FETCH_ALL_LEADS_START';
export const FETCH_ALL_LEADS_SUCCESS = 'FETCH_ALL_LEADS_SUCCESS';
export const FETCH_ALL_LEADS_ERROR = 'FETCH_ALL_LEADS_ERROR';
export const CREATE_LEAD_DEALERID='CREATE_LEAD_DEALERID';
export const MT_LEAD_DATA='MT_LEAD_DATA';

export const FETCH_STAGES_START = 'FETCH_STAGES_START';
export const FETCH_STAGES_SUCCESS = 'FETCH_STAGES_SUCCESS';

export const FETCH_EMPLOYEES_START = 'FETCH_EMPLOYEES_START';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';

export const FETCH_ALL_MAPPING_START = 'FETCH_ALL_MAPPING_START';
export const FETCH_ALL_MAPPING_SUCCESS = 'FETCH_ALL_MAPPING_SUCCESS';

export const SEARCH_START = 'SEARCH_START';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_UNSUCCESSFUL = 'REGISTER_UNSUCCESSFUL'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_UNSUCCESSFUL = 'LOGIN_UNSUCCESSFUL';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'

export const CREATE_LEAD_START = 'CREATE_LEAD_START';
export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';
export const CREATE_LEAD_ERROR = 'CREATE_LEAD_ERROR';

export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const CREATE_NEW_PASSWORD_ERROR = "CREATE_NEW_PASSWORD_ERROR";
export const CREATE_NEW_PASSWORD_SUCCESS = "CREATE_NEW_PASSWORD_SUCCESS";

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export const CHECK_EXISTING_PHONE_START = 'CHECK_EXISTING_PHONE_START';
export const CHECK_EXISTING_PHONE_SUCCESS = 'CHECK_EXISTING_PHONE_SUCCESS'

//Zunpulse Order Tracking

export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDER_MAPPING_SUCCESS = 'FETCH_ORDER_MAPPING_SUCCESS';
export const FETCH_ORDERS_SUCCESS_NEW = 'FETCH_ORDERS_SUCCESS_NEW';
export const FETCH_ORDER_MAPPING_SUCCESS_NEW = 'FETCH_ORDER_MAPPING_SUCCESS_NEW';

export const BULK_EDIT_START = 'BULK_EDIT_START';
export const BULK_EDIT_SUCCESS = 'BULK_EDIT_SUCCESS';

export const LEAD_TRANSFER_START = 'LEAD_TRANSFER_START';
export const LEAD_TRANSFER_SUCCESS = 'LEAD_TRANSFER_SUCCESS';
export const LEAD_TRANSFER_ERROR = 'LEAD_TRANSFER_ERROR';

export const SA_VIEW_FETCH_DATA_START = 'SA_VIEW_FETCH_DATA_START ';
export const SA_VIEW_FETCH_DATA_SUCCESS = 'SA_VIEW_FETCH_DATA_SUCCESS ';
export const SA_VIEW_FETCH_DATA_ERROR = 'SA_VIEW_FETCH_DATA_ERROR ';

export const FETCH_UNIQUE_COMMENT_START = 'FETCH_UNIQUE_COMMENT_START';
export const FETCH_UNIQUE_COMMENT_SUCCESS = 'FETCH_UNIQUE_COMMENT_SUCCESS'

export const FETCH_ZUNPULSE_COMBO_LIST_SUCCESS = 'FETCH_ZUNPULSE_COMBO_LIST_SUCCESS';
export const FETCH_ZUNPULSE_COMBO_DETAILS = "FETCH_ZUNPULSE_COMBO_DETAILS";

// Zunsolar Order Tracker
export const FETCH_ZUNSOLAR_INVENTORY_SUCCESS = 'FETCH_ZUNSOLAR_INVENTORY_SUCCESS'
export const FETCH_ZUNSOLAR_ORDERTRACKER_MAPPING_SUCCESS = 'FETCH_ZUNSOLAR_ORDERTRACKER_MAPPING_SUCCESS';
export const FETCH_ZUNSOLAR_COMBO_SUCCESS = "FETCH_ZUNSOLAR_COMBO_SUCCESS";
export const FETCH_ZUNSOLAR_ORDERS_SUCCESS = 'FETCH_ZUNSOLAR_ORDERS_SUCCESS';
export const FETCH_ZUNSOLAR_SUBORDERS_SUCCESS = 'FETCH_ZUNSOLAR_SUBORDERS_SUCCESS'
export const FETCH_ZUNSOLAR_ORDERDATA_SUCCESS = 'FETCH_ZUNSOLAR_ORDERDATA_SUCCESS'
export const FETCH_ZUNSOLAR_ORDER_COMMENTS_SUCCESS = 'FETCH_ZUNSOLAR_ORDER_COMMENTS_SUCCESS'
export const FETCH_ZUNSOLAR_SUBORDERDATA_SUCCESS = 'FETCH_ZUNSOLAR_SUBORDERDATA_SUCCESS';
export const FETCH_ZUNSOLAR_COMBOLOTS_SUCCESS = "FETCH_ZUNSOLAR_COMBOLOTS_SUCCESS";
export const FETCH_ZUNSOLAR_WARRANTY_DETAILS_SUCCESS = 'FETCH_ZUNSOLAR_WARRANTY_DETAILS_SUCCESS';
export const FETCH_ZUNSOLAR_ORDER_HISTORY_SUCCESS = "FETCH_ZUNSOLAR_ORDER_HISTORY_SUCCESS";

// WhatsApp Dashboard

export const FETCH_SINGLE_CUSTOMER = "FETCH_SINGLE_CUSTOMER";
export const FETCH_METRICS_DATA = "FETCH_METRICS_DATA";

//Zunsolar Ops Dashboard
export const FETCH_ZUNSOLAROPS_LAST_EDIT = 'FETCH_ZUNSOLAROPS_LAST_EDIT';
export const FETCH_ZUNSOLAROPS_SALES_SUMMARY = 'FETCH_ZUNSOLAROPS_SALES_SUMMARY';
export const FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY = 'FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY';
export const FETCH_ZUNSOLAROPS_ORDER_VALUE = 'FETCH_ZUNSOLAROPS_ORDER_VALUE';
export const FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE = 'FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE';
export const FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY = 'FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY';
export const FETCH_STATE_SUMMARY_DATA = 'FETCH_STATE_SUMMARY_DATA';
export const FETCH_METRICS_SUMMARY_ZUNSOLAR = 'FETCH_METRICS_SUMMARY_ZUNSOLAR';
export const FETCH_ZUNSOLAROPS_SALES_SUMMARY_ORDER = 'FETCH_ZUNSOLAROPS_SALES_SUMMARY_ORDER';
export const FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_ORDER = 'FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_ORDER';
export const FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE_VIEW_NEW = 'FETCH_ZUNSOLAROPS_SUB_ORDER_VALUE_VIEW_NEW';
export const FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_ORDER = 'FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_ORDER';
export const FETCH_ZUNSOLAROPS_CATEGORY_SUMMARY = 'FETCH_ZUNSOLAROPS_CATEGORY_SUMMARY';
export const FETCH_ZUNSOLAROPS_STATE_SUMMARY = 'FETCH_ZUNSOLAROPS_STATE_SUMMARY';
export const FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_UNIT = 'FETCH_ZUNSOLAROPS_SHIPPING_SUMMARY_UNIT';
export const FETCH_ZUNSOLAROPS_SALES_SUMMARY_UNIT = "FETCH_ZUNSOLAROPS_SALES_SUMMARY_UNIT";
export const FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_UNIT = "FETCH_ZUNSOLAROPS_LASTTENDAYS_SUMMARY_UNIT";
export const FETCH_ZUNSOLAROPS_MAPDATA = "FETCH_ZUNSOLAROPS_MAPDATA";
export const FETCH_ZUNSOLAROPS_SALES_SUMMARY_DISPLAY_ORDER_Id = "FETCH_ZUNSOLAROPS_SALES_SUMMARY_DISPLAY_ORDER_Id";
export const FETCH_ORDER_VALUE_NEW = 'FETCH_ORDER_VALUE_NEW';

//Zunpulse Ops Dashboard
export const FETCH_ZUNPULSEOPS_ORDERS_DATA = 'FETCH_ZUNPULSEOPS_ORDERS_DATA';
export const FETCH_ZUNPULSEOPS_SALES_SUMMARY = 'FETCH_ZUNPULSEOPS_SALES_SUMMARY';
export const FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY = 'FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY';
export const FETCH_ZUNPULSEOPS_ORDER_VALUE = 'FETCH_ZUNPULSEOPS_ORDER_VALUE';
export const FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY = 'FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY';
export const FETCH_LATEST_EDITED_ZUNPULSE_ORDER = `FETCH_LATEST_EDITED_ZUNPULSE_ORDER`;
export const FETCH_METRICS_SUMMARY = 'FETCH_METRICS_SUMMARY';
export const FETCH_ZUNPULSEOPS_SALES_SUMMARY_SUBORDER = 'FETCH_ZUNPULSEOPS_SALES_SUMMARY_SUBORDER';
export const FETCH_ZUNPULSEOPS_SALES_SUMMARY_UNIT = 'FETCH_ZUNPULSEOPS_SALES_SUMMARY_UNIT';
export const FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_SUBORDER = 'FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_SUBORDER'
export const FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_UNIT = 'FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_UNIT'
export const FETCH_ZUNPULSEOPS_ORDER_VALUE_VIEW = 'FETCH_ZUNPULSEOPS_ORDER_VALUE_VIEW';
export const FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_SUBORDER = 'FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_SUBORDER';
export const FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_UNIT = 'FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_UNIT';
export const FETCH_ZUNPULSEOPS_MAPPING = 'FETCH_ZUNPULSEOPS_MAPPING';
export const FETCH_ZUNPULSEOPS_CATEGORY_SUMMARY = 'FETCH_ZUNPULSEOPS_CATEGORY_SUMMARY';
export const FETCH_ZUNPULSEOPS_STATE_SUMMARY = 'FETCH_ZUNPULSEOPS_STATE_SUMMARY';
export const FETCH_ZUNPULSEOPS_MAPDATA = 'FETCH_ZUNPULSEOPS_MAPDATA';
export const FETCH_ZUNPULSEOPS_ORDER_SUMMARY = 'FETCH_ZUNPULSEOPS_ORDER_SUMMARY';
export const FETCH_ZUNPULSEOPS_SALES_SUMMARY_ORDER = 'FETCH_ZUNPULSEOPS_SALES_SUMMARY_ORDER';
export const FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_ORDER = 'FETCH_ZUNPULSEOPS_LASTTENDAYS_SUMMARY_ORDER';
export const FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_ORDER = 'FETCH_ZUNPULSEOPS_SHIPPING_SUMMARY_ORDER';

export const FETCH_ZUNPULSE_BURN_DASHBOARD_PRODUCT_SUMMARY = 'FETCH_ZUNPULSE_BURN_DASHBOARD_PRODUCT_SUMMARY';
export const FETCH_ZUNPULSE_BURN_DASHBOARD_MARKETING_SUMMARY = 'FETCH_ZUNPULSE_BURN_DASHBOARD_MARKETING_SUMMARY';
export const FETCH_ZUNPULSE_BURN_DASHBOARD_PLATFORM_SUMMARY = 'FETCH_ZUNPULSE_BURN_DASHBOARD_PLATFORM_SUMMARY';
export const FETCH_ZUNPULSE_BURN_DASHBOARD_ORDERWISE_MARKETING_SUMMARY = 'FETCH_ZUNPULSE_BURN_DASHBOARD_ORDERWISE_MARKETING_SUMMARY';
export const FETCH_ZUNPULSE_BURN_DASHBOARD_DEVICE_MARKETING_SUMMARY = 'FETCH_ZUNPULSE_BURN_DASHBOARD_DEVICE_MARKETING_SUMMARY';
export const FETCH_ZUNPULSE_MONTHLY_MARKETING_SPENDING = 'FETCH_ZUNPULSE_MONTHLY_MARKETING_SPENDING';
export const FETCH_ZUNPULSE_DAILY_MARKETING_SPENDING = 'FETCH_ZUNPULSE_DAILY_MARKETING_SPENDING';
export const FETCH_ZUNPULSE_COUPON_DETAILS = 'FETCH_ZUNPULSE_COUPON_DETAILS';

//Zunsolar Fulfillment report
export const FETCH_WEEKLY_ORDERS = 'FETCH_WEEKLY_ORDERS';
export const FETCH_WEEKLY_DISPATCH_STATUS = 'FETCH_WEEKLY_DISPATCH_STATUS';
export const FETCH_WEEKLY_DELIVERED_ORDER_STATUS = 'FETCH_WEEKLY_DELIVERED_ORDER_STATUS';
export const FETCH_RETURNED_RECEIVED_ORDER_STATUS = 'FETCH_RETURNED_RECEIVED_ORDER_STATUS';
export const FETCH_RETURNING_ORDER_STATUS = 'FETCH_RETURNING_ORDER_STATUS';

// Zunpulse Invoices

export const FETCH_ORDERS_INVOICES_SUCCESS = 'FETCH_ORDERS_INVOICES_SUCCESS';

// Zunsolar Invoices

export const FETCH_ZUNSOLAR_ORDERS_INVOICES_SUCCESS = 'FETCH_ZUNSOLAR_ORDERS_INVOICES_SUCCESS';

//ZunPulse Products
export const FETCH_PRODUCT_DATA_SUCCESS = 'FETCH_PRODUCT_DATA_SUCCESS';
export const FETCH_ALL_PRODUCTS_SUCESS = 'FETCH_ALL_PRODUCTS_SUCESS';

//zunpulse products V2
export const FETCH_ALL_PRODUCTS_DATA = 'FETCH_ALL_PRODUCTS_DATA';
export const FETCH_SINGLE_PRODUCT_DATA = 'FETCH_SINGLE_PRODUCT_DATA';



//ZunSolar Generate PI
export const FETCH_DEALERS_DATA_SEARCH = 'FETCH_DEALERS_DATA_SEARCH';
export const FETCH_ZUNSOLAR_PI_ORDERS_ALL = 'FETCH_ZUNSOLAR_PI_ORDERS_ALL';
export const FETCH_ZUNSOLAR_PI_ORDERS_SINGLE = 'FETCH_ZUNSOLAR_PI_ORDERS_SINGLE';
export const FETCH_ZUNSOLAR_PI_ORDERS_COMMENTS = 'FETCH_ZUNSOLAR_PI_ORDERS_COMMENTS';
export const FETCH_PI_PRODUCT_DETAILS = 'FETCH_PI_PRODUCT_DETAILS';

// Procurement Portal Actions
export const FETCH_PROCUREMENT_VENDORS = 'FETCH_PROCUREMENT_VENDORS';
export const FETCH_PROCUREMENT_LEDGER = 'FETCH_PROCUREMENT_LEDGER';
export const FETCH_PROCUREMENT_PAYMENTS = 'FETCH_PROCUREMENT_PAYMENTS';
export const FETCH_PROCUREMENT_MAPPING = 'FETCH_PROCUREMENT_MAPPING';
export const FETCH_PROCUREMENT_PAYMENTS_BY_ID = 'FETCH_PROCUREMENT_PAYMENTS_BY_ID'
export const FETCH_SUPPLY_DETAILS = 'FETCH_SUPPLY_DETAILS'

// Affliate Portal Actions
export const START_ADD_KYC = 'START_ADD_KYC';
export const ADD_KYC_SUCCESS = 'ADD_KYC_SUCCESS';
export const FETCH_KYC_DETAILS = 'FETCH_KYC_DETAILS';
export const START_ADD_AFFILIATE = 'START_ADD_AFFILIATE';
export const ADD_AFFILIATE_SUCCESS = 'ADD_AFFILIATE_SUCCESS';
export const FETCH_AFFILIATE_DETAILS = 'FETCH_AFFILIATE_DETAILS';
export const FETCH_CONSOLIDATED_DETAILS = 'FETCH_CONSOLIDATED_DETAILS';
export const FETCH_DETAILED_DETAILS = 'FETCH_DETAILED_DETAILS';
export const START_ADD_PAYMENT = 'START_ADD_PAYMENT';
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';

// Zunsolar PI Invoices
export const FETCH_BOOKED_ORDERIDS_FROM_ZUNSOLAR_ORDERS = 'FETCH_BOOKED_ORDERIDS_FROM_ZUNSOLAR_ORDERS'
export const FETCH_SUB_ORDER_DATA_FROM_ORDER_ID = 'FETCH_SUB_ORDER_DATA_FROM_ORDER_ID'
export const FETCH_ALL_INVOICE_ORDERS = 'FETCH_ALL_INVOICE_ORDERS'
export const FETCH_ALL_INVOICE_LEDGER = 'FETCH_ALL_INVOICE_LEDGER'


// ZUNSOLAR DEALER INVENTORY
export const FETCH_ZUNSOLAR_DEALER_INVENTORY_SUCCESS = 'FETCH_ZUNSOLAR_DEALER_INVENTORY_SUCCESS';

//WALLET
export const FETCH_WALLET_TRANSACTIONS = 'FETCH_WALLET_TRANSACTIONS';
export const FETCH_REDEEMED_VOUCHERS = 'FETCH_REDEEMED_VOUCHERS';


//Coupon System Actions Types
export const FETCH_COUPONS_DATA = 'FETCH_COUPONS_DATA';


//repeatSales dashboard Action Types

export const FETCH_REPEAT_SALES_BANNER_DATA = "FETCH_REPEAT_SALES_BANNER_DATA"
export const FETCH_REPEAT_SALES_BANNER_ID = "FETCH_REPEAT_SALES_BANNER_ID"
export const FETCH_REPEAT_SALES_OFFER_DATA = "FETCH_REPEAT_SALES_OFFER_DATA"
export const FETCH_REPEAT_SALES_VERSION_DATA = "FETCH_REPEAT_SALES_VERSION_DATA"


//ZunSolar PI Invoice Bulk upload
export const FETCH_ZUNSOLAR_PI_ALL_INVOICES = 'FETCH_ZUNSOLAR_PI_ALL_INVOICES';
export const FETCH_ZUNSOLAR_PI_LEDGER = 'FETCH_ZUNSOLAR_PI_LEDGER';

//Unicommerce Mapping
export const FETCH_UNICOMMERCE_MAPPING = "FETCH_UNICOMMERCE_MAPPING"


//zunpulse customer Quotation
export const FETCH_ZUNPULSE_CUSTOMER_QUOTATION = 'FETCH_ZUNPULSE_CUSTOMER_QUOTATION';
export const FETCH_ZUNPULSE_CUSTOMER_QUOTATION_PRODUCTS = 'FETCH_ZUNPULSE_CUSTOMER_QUOTATION_PRODUCTS';

//zunpulse website sellingPrice mapping 
export const FETCH_ZUNPULSE_SELLING_PRICE = 'FETCH_ZUNPULSE_SELLING_PRICE'


//return order tracker
export const FETCH_RETURN_ORDERS_MAPPING = 'FETCH_RETURN_ORDERS_MAPPING'
export const FETCH_ALL_RETURN_ORDERS = 'FETCH_ALL_RETURN_ORDERS'
export const FETCH_SUB_ORDER_DATA_FOR_RETURN = 'FETCH_SUB_ORDER_DATA_FOR_RETURN'


//IVR ticketing
export const FETCH_IVR_MAPPING = "FETCH_IVR_MAPPING"
export const FETCH_IVR_TICKETING_DATA = "FETCH_IVR_TICKETING_DATA"
export const FETCH_ORDER_ID_MAPPING = "FETCH_ORDER_ID_MAPPING"
export const FETCH_PREVIOUS_TICKET_DATA = "FETCH_PREVIOUS_TICKET_DATA"
export const FETCH_SINGLE_TICKET_DATA = "FETCH_SINGLE_TICKET_DATA"
export const FETCH_TICKET_RESONSIBLE_PERSON = "FETCH_TICKET_RESONSIBLE_PERSON"
export const FETCH_TICKET_ALL_COMMENTS = "FETCH_TICKET_ALL_COMMENTS"
export const FETCH_TICKETS_FOR_ORDER_ID = "FETCH_TICKETS_FOR_ORDER_ID"
export const FETCH_CURRENT_CALLS_DATA = "FETCH_CURRENT_CALLS_DATA"
export const FETCH_OUTBOUNDS_CALLS_DATA = "FETCH_OUTBOUNDS_CALLS_DATA"
export const FETCH_CURRENT_CALLS_COUNTS = "FETCH_CURRENT_CALLS_COUNTS"
export const FETCH_RO_CLIENT_ID_MAPPING = "FETCH_RO_CLIENT_ID_MAPPING"
export const FETCH_IVR_TICKET_COUNT = "FETCH_IVR_TICKET_COUNT"
export const FETCH_IVR_TICKET_HISTORY = "FETCH_IVR_TICKET_HISTORY"






export const FETCH_DISTRIBUTOR_MAPPING = "FETCH_DISTRIBUTOR_MAPPING"
export const FETCH_DEALER_VISIT_DATA = "FETCH_DEALER_VISIT_DATA"
export const FETCH_DISTRIBUTOR_KYC_DATA = "FETCH_DISTRIBUTOR_KYC_DATA"
export const FETCH_DISTRIBUTOR_LEDGER_DATA = "FETCH_DISTRIBUTOR_LEDGER_DATA"


export const FETCH_RETAILERS_COUNT_FOR_DISTRIBUTOR = "FETCH_RETAILERS_COUNT_FOR_DISTRIBUTOR"


//mt view actions
export const FETCH_MT_VISIT_DETAILS = "FETCH_MT_VISIT_DETAILS"
export const FETCH_MT_PAYMENT_HISTORY = "FETCH_MT_PAYMENT_HISTORY"
export const FETCH_MT_SEARCH_VISITS = "FETCH_MT_SEARCH_VISITS"

// Logistics
export const FETCH_LOGISTICS_DATA_SUCCESS = "FETCH_LOGISTICS_DATA_SUCCESS";
export const FETCH_LOGISTICS_MAPPING_SUCCESS = "FETCH_LOGISTICS_MAPPING_SUCCESS";

//PROJECT SALES
export const FETCH_PROJECTSALES_MAPPING_SUCCESS = "FETCH_PROJECTSALES_MAPPING_SUCCESS";
export const FETCH_PROJECTSALES_LEADS_SUCCESS = "FETCH_PROJECTSALES_LEADS_SUCCESS";
export const FETCH_PROJECTSALES_LEADDATA_SUCCESS = "FETCH_PROJECTSALES_LEADDATA_SUCCESS";
export const FETCH_PROJECTSALES_LEAD_COMMENTS = "FETCH_PROJECTSALES_LEAD_COMMENTS";
export const FETCH_PROJECTSALES_LEAD_HISTORY = "FETCH_PROJECTSALES_LEAD_HISTORY";

// Follow up
export const FETCH_FOLLOWUP_LEADS_SUCCESS = 'FETCH_FOLLOWUP_LEADS_SUCCESS';
export const FETCH_FOLLOWUP_MAPPING = 'FETCH_FOLLOWUP_MAPPING';