import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/leadsView.css';
import ReactTable from "react-table";
import moment from "moment";
import swal from 'sweetalert';
import { Loader } from '../loader';
import Select from 'react-select'
import { Link, Redirect } from 'react-router-dom';
import { downloadKYCFile } from '../../actions/fetchBitrixId';


const meetingTypeOptions = [ 'Scheduled meeting', 'Cold meeting' ];

class MTSingleLeadView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      newComment: '',
      leadData: {},
      newLeadData: {},
      username: '',
      comments: [],
      mappingData: {},
      loading: true,
      recieved: false,
      showUtmVariables: false,
      inventoryRowEdit: {},
      newInventoryData: {},
      distributorMapping : [],
      isDistributorIDEnable:false,
      isMappedDealer :false,
      dealerVisitData :[],
      visitData:{},
      isDealerVisitDataEnable: false,
      isVisitSectionEnabled: false,
      isOtherFieldsVisible: false,
      sectionName: ['Visits',"Ledger","KYC", 'Comments'],
      activeSectionName :'Visits',
      kycData :{},
      allKYCData :[],
      kycTypeOptions :[{label:"COI",value:"COI"},{label:"PAN Card",value:"PAN Card"},
                        {label:"Partnership Document",value:"Partnership Document"},{label:"Aadhaar Owner",value:"Aadhaar Owner"},
                        {label:"Distributor Agreement",value:"Distributor Agreement"}, {label:"Last Year GST Return",value:"Last Year GST Return"}, {label:"2 Security Cheques",value:"2 Security Cheques"}]

    }

    this.commentColumns = [
      {
        Header: 'Comment By',
        accessor: 'commentedBy'
      },
      {
        Header: 'Time',
        accessor: 'timestamp',
        Cell: row => {
          return new Date(parseInt(row.original.timestamp)).toLocaleDateString();
        }
      },
      {
        Header: 'Comment',
        accessor: 'commentBody',
        style: { 'whiteSpace': 'unset' }
      }
    ];
    this.historyColumns = [
      {
        Header: 'Time',
        accessor: 'timestamp',
        Cell: row => {

          return moment(+row.original.timestamp).format('DD/MM/YYYY hh:mm a');
        }
      },
      {
        Header: 'Updated By',
        accessor: 'updatedBy',
        Cell: row => {

          return row.original.updatedBy.split('@')[0].split('.')[0] + ' ' + row.original.updatedBy.split('@')[0].split('.')[1]
        }
      },
      {
        Header: 'Field Name',
        accessor: 'updatedField'
      },
      {
        Header: 'Old Value',
        accessor: 'updatedFrom'
      },
      {
        Header: 'New Value',
        accessor: 'updatedTo'
      }
    ];
  }

  InventoryColumn = (row) => {
    let { inventoryRowEdit = {}, editedDataInventory = {} } = this.state;
    let { id } = row.original;
    // return "";
    if(!inventoryRowEdit[id]) {
      switch(row.column.id) {
        case "date":
        case "quantity":
          return row.value;
        case "product":
          return `${row.original.productId} ${row.original.productName}`
        default:
          return <button className="btn btn-success" onClick={() => this.handleEditInventoryClick(id)}>Edit</button>;
      }
    }
    else {
      switch(row.column.id) {
        case "date":
          return <input type="date" className="form-control" name={"date"} value={editedDataInventory[row.original.id]["date"] || row.value} onChange={(e) => this.handleInventoryInputChange(e, row.original.id)} />
        case "quantity":
          return <input type="number" className="form-control" name={"quantity"} value={editedDataInventory[row.original.id].hasOwnProperty("quantity") ? editedDataInventory[row.original.id]["quantity"] : row.value} onChange={(e) => this.handleInventoryInputChange(e, row.original.id)} />
        case "product":
          return <select
            onChange={(e) => { this.handleInventoryInputChange(e, row.original.id) }}
            className="form-control" name={"productId"} value={editedDataInventory[row.original.id]["productId"] || row.original.productId}
          >
          {(this.props.productsMapping || []).map(e => <option key={e.id} value={e.pID}>{`${e.pID} ${e.productName}`}</option>)}
          </select>
        default:
          return <button className="btn btn-success" onClick={() => this.handleSaveInventoryClick(id)}>Save</button>;;
      }
    }
  }

  handleInventoryInputChange = (e, id) => {
    let { name, value } = e.target;
    let { editedDataInventory = {} } = this.state;
    if(!editedDataInventory[id]) editedDataInventory[id] = {};
    editedDataInventory[id][name] = value;
    this.setState({
      editedDataInventory
    })
  }

  handleEditInventoryClick = (id) => {
    let { inventoryRowEdit = {}, editedDataInventory = {} } = this.state;
    this.setState({
      inventoryRowEdit: {
        ...this.state.inventoryRowEdit,
        [id]: true
      },
      editedDataInventory: {
        ...this.state.editedDataInventory,
        [id]: {}
      }
    })
  }

  handleSaveInventoryClick = (id) => {
    let { editedDataInventory = {} } = this.state;
    let { productsMapping = [], zunsolarInventoryDealerData = [] } = this.props;
    let data = editedDataInventory[id] || {};
    if(!Object.keys(data).length) return;
    if(data.productId) {
      data.productName = (productsMapping.find(row => row.pID == data.productId)).productName || "";
    }
    this.props.editZunsolarDealerInventory(id, data, zunsolarInventoryDealerData);
    this.setState({
      editedDataInventory: {
        ...this.state.editedDataInventory,
        [id]: {}
      },
      inventoryRowEdit: {
        ...this.state.inventoryRowEdit,
        [id]: false
      }
    })
  }

  componentWillMount() {
    if (localStorage.getItem("userObj")) {
      let userObj = JSON.parse(localStorage.getItem("userObj"));
      let username = userObj.username
      this.setState({
        username: username
      })
    }
  }

  componentWillReceiveProps(props) {
    // console.log("1", props.allMapping);
    // console.log(props.leadDetails);
    let leadDetails = props.leadDetails;
    let retailersCount = props.retailersCount;
    console.log('retailersCount',retailersCount);
    let mappingData = props.allMapping && props.allMapping.mapping ? props.allMapping.mapping : {};
    let distributorMapping = props.distributorMapping;
    let distributorIdCheck = ["Retailer","C&F"];
    let visitSectionCheck = ["Retailer","C&F","Distributor","Distributor ZP","Distributor ZV"];
    let isVisitSectionEnabled = false;
    let isDistributorIDEnable = false;
    let isMappedDealer = false;
    if (leadDetails.data) {
      if(distributorIdCheck.includes(leadDetails.data.leadType)){
        isDistributorIDEnable = true
      }
      if(visitSectionCheck.includes(leadDetails.data.leadType)){
        isVisitSectionEnabled = true
      }
      if((leadDetails.data.leadType=="Distributor" || leadDetails.data.leadType=="Distributor ZP" || leadDetails.data.leadType=="Distributor ZV" ) && leadDetails.data.stage=="Sold" && leadDetails.data.partner_onboarding_date){
        isMappedDealer = true
      }
      this.setState({
        leadData: (leadDetails && leadDetails.data) ? leadDetails.data : {},
        mappingData: mappingData,
        loading: false,
        recieved: true,
        stateCityMap: props.allMapping ? props.allMapping.stateCityMap : {},
        employeesArr: props.allMapping ? props.allMapping.employees : [],
        isDistributorIDEnable,
        distributorMapping ,
        isMappedDealer,
        isVisitSectionEnabled

      })
    }

  }

  handleEdit = () => {
    // console.log("this function is called", this.state.edit);
    // console.log("this.props.newwwwwwww", this.props.allMapping)
    this.editDetails = {};
    if (this.state.edit) {
      this.setState({
        edit: false
      })
    } else {
      this.setState({
        mappingData: { ...this.props.allMapping.mapping },
        stateCityMap: { ...this.props.allMapping.stateCityMap },
        newLeadData: { ...this.state.leadData },
        edit: true
      })
    }
  }
  handleToggleOtherFieldsVisible = ()=>{
    this.setState({
      isOtherFieldsVisible: !this.state.isOtherFieldsVisible
    })
  }
  handleActiveSectionName = (name)=>{
    this.setState({
      activeSectionName:name
    })
  }

  handleUtmDivToggle = () => {
    this.setState({
      showUtmVariables: !this.state.showUtmVariables
    })
  }

  handleInputChange = (e, selectTypeV2 = false) => {
    // console.log("this is callesddddddd",e.target.value)
    let name = '' ,value = ''
    if (selectTypeV2){

       name = selectTypeV2;
       value = e.value;
    }
    else{
       name = e.target.name;
       value = e.target.value

    }
    value = this.checkSelectString(value);
    this.setState({
      // mappingData :{
      //     ...this.state.mappingData,
      //     [name] : value,
      // },
      newLeadData: {
        ...this.state.newLeadData,
        [name]: value,
      }
    })
    // console.log("newleaddata",this.state.newLeadData)
  }


  handleKYCInputChange = (e, selectType = false) => {
    // console.log("this is callesddddddd",e.target.value)
    let name = '' ,value = ''
    if (selectType){

       name = selectType;
       value = e.value;
    }
    else{
       name = e.target.name;
       value = e.target.value

    }
    // value = this.checkSelectString(value);
    this.setState({
   
      kycData: {
        ...this.state.kycData,
        [name]: value,
      }
    })
    // console.log("newleaddata",this.state.newLeadData)
  }
  handlePageRefresh = () => {
    this.props.fetchZunsolarLead(this.state.leadData.solarId);
  }

  onClickCancel = () => {
    this.setState({
      edit: false
    })
  }

  onCommentChange = (e) => {
    let { name, value } = e.target;
    // let comments = [...this.state.leadData.comments];
    // comments.push({
    //     bitrixId : this.state.leadData.BITRIX_ID,
    //     commentedBy : "Shivam Dubey",
    //     commentBody : e.target.value
    // })
    this.setState({
      newComment: value,
      // leadData:{
      //     ...this.state.leadData,
      //     comments:comments
      // }
    })
  }

  commentSubmit = (e) => {
    // console.log("this is new comment", this.state.newComment);
    let comments = [...this.state.leadData.comments];
    let newComment = this.state.newComment;
    let newCommentData = {
      solarId: this.state.leadData.solarId,
      commentedBy: this.state.username,
      commentBody: newComment,
      timestamp: new Date().getTime()
    };
    comments.push(newCommentData);
    this.props.addComments(newCommentData, 'zunsolar');
    this.setState({
      newLeadData: {
        ...this.state.newLeadData,
        comments: comments,
      },
      // newComment: e.target.value,
      // comments : this.state.comments.push({by:"user1",time:new Date(),text:"hey there"}) 
      leadData: {
        ...this.state.leadData,
        comments: comments,
      },
      newComment: ''
    })
  }

  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }

  onNewLeadDataSubmit = (e) => {
    let solarId = this.state.leadData.solarId;
    let oldData = this.state.leadData;
    let newData = this.state.newLeadData
    this.props.editLead(newData, oldData, solarId)
    this.setState({
      edit: false,
      leadData: { ...this.state.newLeadData },
    })
  }

  selectType = (name, value, options) => {
    return (
      <div style={{ width: '100%' }}>
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map(e => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  SelectTypeV2 = ({label,name, options, placeholder, disabled, required=false}) => {
    return (
      <div
      className="col-12 "
      style={{ width: "100%", paddingTop: "1%", paddingLeft:'0', paddingRight:'0' }}
    >
      <div className="form-group">
        <div className="mb-3">
          {/* <label>{label}</label> */}
          <Select
                  isSearchable
                  onChange={(e) => { this.handleInputChange(e, name) }}
                  placeholder = {placeholder}
                  name = {name}
                  options={options}
                  isDisabled = {disabled}
                  required={required}
              />
        </div>
      </div>
    </div>
    )
  }
  SelectKYCType = ({name, options, placeholder, disabled, required=false}) => {
    return (
      <div
      className="col-12 "
      style={{ width: "100%", paddingTop: "1%", paddingLeft:'0', paddingRight:'0' }}
    >
      <div className="form-group">
        <div className="mb-3">
          {/* <label>{label}</label> */}
          <Select
                  isSearchable
                  onChange={(e) => { this.handleKYCInputChange(e, name) }}
                  placeholder = {placeholder}
                  name = {name}
                  options={options}
                  isDisabled = {disabled}
                  required={required}
              />
        </div>
      </div>
    </div>
    )
  }

  selectNewType = (name, options, fieldName, value) => {

    return (
      <div style={{ width: '100%' }} >
        {/* <label for="sel1">{labelName}</label> */}
        <select
          onChange={(e) => { this.handleInputChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={fieldName ? e[fieldName] : e}>{fieldName ? e[fieldName].replace(/(^"|"$)/g, '') : e}</option>)}
        </select>
      </div>
    )
  }

  inputType = (name, value, type, placeholder) => {
    return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
  }
  downloadFile = (row) => {
    downloadKYCFile(row.original.kycFileUrl)
}

  handleFileChange = (e) => {
    let files = e.target.files[0]
    let { name, value } = e.target;
   this.setState({
    kycData:{
    ...this.state.kycData,
    [name]: files
}
   })
}
  FileTypeInput = (name, type, required ) => {
    return (
        <input type={type} className="form-control" name={name} accept="image/jpeg,image/png,application/pdf" onChange={(e) => this.handleFileChange(e)} required={required}></input>
    )
}
  handleAddInventoryInputChange = (e) => {
    let { name, value } = e.target;
    let { newInventoryData = {} } = this.state;
    newInventoryData[name] = value;
    this.setState({
      newInventoryData
    });
  }
  handleAddDealerVisitInputChange = (e) => {
    let { name, value } = e.target;
    let { visitData = {} } = this.state;
    visitData[name] = value;
    this.setState({
      visitData
    });
  }
  

  submitNewInventory = () => {
    let { newInventoryData } = this.state;
    let { quantity, date, productId } = newInventoryData || {};
    if(!quantity || !date || !productId) {
      alert("Required fields missing");
      return;
    }
    newInventoryData.productName = (this.props.productsMapping.find(row => row.pID == newInventoryData.productId)).productName || "";
    newInventoryData.leadId = this.state.leadData.solarId;
    this.props.addZunsolarDealerInventory(newInventoryData);
    this.setState({
      newInventoryData: {}
    })
  }

  submitDealerVisit = ()=>{
    let {visitData, username,leadData} = this.state;
    visitData.visitBy = username;
    visitData.createdBy = username;
    visitData.solarId = leadData.solarId
    let {visitDate, solarId} = visitData;
    if(!visitData || !visitDate  || !username || !solarId){
      return alert("missing required fields")
    }
    this.props.addZunsolarDealerVisitData(visitData);
  }
  submitKYCData = ()=>{
    let {kycData, username,leadData} = this.state;
    kycData.createdBy = username;
    kycData.solarId = leadData.solarId
    const apiFormData = new FormData();
    for(let key in kycData){
      apiFormData.append(key, kycData[key]);
    }
    

    let { solarId} = kycData;
    if(!kycData   || !username || !solarId){
      return alert("missing required fields")
    }
    this.props.addZunsolarDistributorKYC(apiFormData);
  }

  handleRedirectAddPayment = ()=>{
    window.open(window.location.origin+"/mt/createPayment","_self")

  }
  handleMappedDealer = (id)=>{
    window.open(window.location.origin+"/zunsolar/lead/all/retailer/"+id, "_blank")    
  }

  render() {
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }
    let { InventoryColumn,SelectTypeV2 } = this;
    const inventoryColumns = [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: InventoryColumn
      },
      {
        Header: 'Product',
        accessor: 'product',
        Cell: InventoryColumn
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: InventoryColumn
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        Cell: InventoryColumn
      },
    ]
    const dealerVisitColumn = [
      {
        Header: 'Date',
        accessor: 'visitDate',
      },
      {
        Header: 'visit by',
        accessor: 'visitBy'
      },
      {
        Header: 'comment',
        accessor: 'comment'
      }
    ]
    const  kycColumn = [
      {
        Header: 'Date',
        accessor: 'createdOn',
      },
      {
        Header: 'File Type',
        accessor: 'kycFileType'
      },
      {
        Header: 'Uploaded By',
        accessor: 'createdBy'
      },
      {
        Header: 'Download',
        Cell: row => {
          return (
              <button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => this.downloadFile(row)}>Download</button>
          )
      },
      }
    ]

    const ledgerColumn = [
      {
        Header: 'Payment Date',
        accessor: 'paymentDate',
        Cell: row => {
          return new Date(parseInt(row.original.paymentDate)).toLocaleDateString();
        }
      },
     
      {
        Header: row => {
          let { data } = row;
          let total = data.reduce((a, b) => a + (b.amount && !isNaN(b.amount) ? parseInt(b.amount) : 0), 0);
          return `Amount (${total})`;
        },
        accessor: 'amount'
      },
     
      {
        Header: 'Category',
        accessor: 'category'
       
      },
      {
        Header: 'Sub Category',
        accessor: 'subCategory'
      },
      
      {
        Header: 'Created By',
        accessor: 'createdBy'
      },
      
      {
        Header: 'Mode',
        accessor: 'mode'
      },
      {
        Header: 'Transaction Id',
        accessor: 'referenceNumber'
      },
     
      {
        Header: 'Status',
        accessor: 'status'
      }
    ]
    let {SelectKYCType} = this
    let { newInventoryData = {}, visitData={}, allKYCData, kycTypeOptions } = this.state;
    if (this.state.recieved) {
      return (
        <div className="container-fluid" style={{ marginTop: "60px" }}>
          <div className="topbar-section" >
            <div className="row">
              <div className="col-md-9 col-xs-12 topbar-left">
                <div className="col-md-12">
                  <strong>Lead</strong>
                  <strong style={{ marginRight: "5px" }}> # {(this.state.leadData.solarId ? this.state.leadData.solarId : '')}</strong>
                  <strong style={this.state.edit ? style.hide : style.show}>{this.state.leadData.name ? this.state.leadData.name.replace(/(^"|"$)/g, '') : ''}</strong>
                  <span style={this.state.edit ? style.show : style.hide} className="input-group titleInput" >
                    {this.inputType("name", this.state.newLeadData.name, "text", "Lead Name")}
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 topbar-right">
                <div className="col-md-12 col-xs-12">
                 {this.state.isMappedDealer &&  <button  className="button-edit" onClick={() =>{this.handleMappedDealer(this.state.leadData.solarId)}}>Attached Retailers</button>}
                  <span style={this.state.edit ? style.hide : style.show}><i onClick={this.handleEdit} style={{ cursor: "pointer", float: "right", marginTop: "8px" }} className="fas fa-pen"></i></span>
                  <button style={this.state.edit ? style.show : style.hide} className="button-edit" onClick={(e) => this.onNewLeadDataSubmit(e)}>Save</button>
                  <button style={this.state.edit ? style.show : style.hide} className="button-cancel" onClick={(e) => this.onClickCancel(e)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className='mobileRow'>
            <div className="mobileCol" >
              <h5> <b>Stage :</b> </h5>
            </div>
            <div className="mobileCol">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.stage}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectType("stage", this.state.newLeadData.stage, this.state.mappingData.stage)}
                </span>
              </h5>
            </div>
            </div>

            <div className='mobileRow'>
            <div className="mobileCol">
              <h5><b>Active :</b></h5>
            </div>
            <div className="mobileCol">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.active}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectType("active", this.state.newLeadData.active, this.state.mappingData.active)}
                </span>
              </h5>
            </div>
            </div>


            <div className='mobileRow'>
            <div className="mobileCol">
              <h5><b>High Value :</b></h5>
            </div>
            <div className="mobileCol">
              <h5>
                <span style={this.state.edit ? style.hide : style.show}> {this.state.leadData.highValue}</span>
                <span style={this.state.edit ? style.show : style.hide} className="input-group topbarVariables">
                  {this.selectType("highValue", this.state.newLeadData.highValue, ['Yes', 'No'])}
                </span>
              </h5>
            </div>
            </div>
          </div>
          

          <div className="bottom-section">
            <div className="row">
            <div className='row headText' style={{display:"flex",justifyContent:"space-between"}}>
          <h5>Contact Details</h5>


          </div>
              <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                <table>
                  <tr>
                    <td>Phone</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.phoneNumber}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("phoneNumber", this.state.newLeadData.phoneNumber, "number")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Alternate Phone</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.alternate_number}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("alternate_number", this.state.newLeadData.alternate_number, "number")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>

                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.email}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("email", this.state.newLeadData.email, "email")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                      <td>GST Number</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.gst_number}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("gst_number", this.state.newLeadData.gst_number, "text")}
                        </span>
                      </td>
                    </tr>

                 

                

                  <tr>
                    <td>Address</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.address}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("address", this.state.newLeadData.address, "text")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>ZipCode</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.pincode}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("pincode", this.state.newLeadData.pincode, "pincode")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>State</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.state}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.selectType("state", this.state.newLeadData.state, this.state.mappingData.state ? Object.keys(this.state.stateCityMap) : [])}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>City</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.city}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.selectType("city", this.state.newLeadData.city ? this.state.newLeadData.city : "", this.state.newLeadData.state ? ((this.state.stateCityMap || {})[this.state.newLeadData.state] || []) : [])}
                      </span>

                    </td>
                  </tr>

                  {this.state.leadData?.profileUrl && <tr>
                    <td>Owner Photo</td>
                    <td> <button className="btn btn-success" onClick={()=>downloadKYCFile(this.state.leadData.profileUrl)}>Download</button>
                    </td>
                  </tr>}


                  
                  <tr>
                    <td>Latitude</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.latitude}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("latitude", this.state.newLeadData.latitude, "number")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Longitude</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.longitude}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("longitude", this.state.newLeadData.longitude, "number")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                  <td></td>
                  <td>
                     <span><button style={{margin:'5px 4px'}} className="btn btn-success" onClick={() => this.handleRedirectToMap(this.state.leadData.latitude, this.state.leadData.longitude)}>Open In Map</button>
                    </span> 
                    </td>
              
                   
                  </tr>
                 

                 

                </table>
              </div>


              {/* <div className="col-md-8 table-wrapper"> */}
              <div className='row headText' style={{display:"flex",justifyContent:"space-between"}}>
          <h5>Activity Details</h5>


          </div>
                <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                  <table className="customer-info-left">

                  <tr>
                      <td>Lead Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.leadType}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("leadType", this.state.newLeadData.leadType, this.state.mappingData.type ? Object.keys(this.state.mappingData.type) : [])}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Sub Lead Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.leadSubType}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("leadSubType", this.state.newLeadData.leadSubType ? this.state.newLeadData.leadSubType : '', this.state.newLeadData.leadType ? ((this.state.mappingData.type || {})[this.state.newLeadData.leadType] || []) : [])}
                          {/* {this.selectType("city", this.state.newLeadData.city ? this.state.newLeadData.city : "", this.state.newLeadData.state ? ((this.state.stateCityMap || {})[this.state.newLeadData.state] || []) : [])} */}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Partner Onboarding Date</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.partner_onboarding_date ? moment(this.state.leadData.partner_onboarding_date).format("DD MMM, YYYY") : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("partner_onboarding_date", this.state.newLeadData.partner_onboarding_date ? moment(this.state.newLeadData.partner_onboarding_date, 'YYYYMMDD').format('YYYY-MM-DD') : '', "date")}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Onboarded By</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.onboardedBy}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("onboardedBy", this.state.employeesArr ? this.state.employeesArr : '', "e_name", this.state.newLeadData.onboardedBy)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Next Action Date</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.nextActionDate ? moment(this.state.leadData.nextActionDate).format("DD MMM, YYYY") : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("nextActionDate", this.state.newLeadData.nextActionDate ? moment(this.state.newLeadData.nextActionDate, 'YYYYMMDD').format('YYYY-MM-DD') : '', "date")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Source</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.source}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("source", this.state.newLeadData.source, this.state.mappingData.source)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Responsible Person</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.responsible_person}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("responsible_person", this.state.employeesArr ? this.state.employeesArr : '', "e_name", this.state.newLeadData.responsible_person)}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className='row headText' style={{display:"flex",justifyContent:"space-between"}}>
          <h5>Business Details</h5>


          </div>
                <div className="col-md-6 customer-info">
                  <table className="customer-info-right">
                  <tr>
                      <td>Retailer Id's Count</td>
                      <td><span>{this.props.retailersCount}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              {/* </div> */}
            </div>
            <div className='otherSectionMain' onClick={()=>this.handleToggleOtherFieldsVisible()}>
              <h5  className='otherSection'>Others</h5>
            </div>
           {this.state.isOtherFieldsVisible && <div className="row">
              <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                <table>
                 
                  <tr>
                    <td>Aadhar Number</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.aadharNumber}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("aadharNumber", this.state.newLeadData.aadharNumber, "number")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Date of Birth</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.dateOfBirth ? moment(this.state.leadData.dateOfBirth).format("DD MMM, YYYY") : ''}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("dateOfBirth", this.state.newLeadData.dateOfBirth ? moment(this.state.newLeadData.dateOfBirth, 'YYYYMMDD').format('YYYY-MM-DD') : '', "date")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Date of Anniversary</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.dateOfAnniversary ? moment(this.state.leadData.dateOfAnniversary).format("DD MMM, YYYY") : ''}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("dateOfAnniversary", this.state.newLeadData.dateOfAnniversary ? moment(this.state.newLeadData.dateOfAnniversary, 'YYYYMMDD').format('YYYY-MM-DD') : '', "date")}
                      </span>
                    </td>
                  </tr>

                  

                  <tr>
                    <td>Language</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.language}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.selectType("language", this.state.newLeadData.language, this.state.mappingData.language)}
                      </span>
                    </td>
                  </tr>
  
                  <tr>
                    <td>Modified On</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{ this.state.leadData.DATE_MODIFY ? moment(this.state.leadData.DATE_MODIFY).format('DD/MM/YYYY hh:mm a') : null}</span>
                    <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        { moment(this.state.leadData.DATE_MODIFY).format('DD/MM/YYYY hh:mm a')}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Modified by</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.updatedby}</span>
                    <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.state.leadData.updatedby}
                    </span>
                    </td>
                  </tr>

                </table>
              </div>




                <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                  <table className="customer-info-left">

                 
                    

                 

                    <tr>
                      <td>Has GST Number</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.has_gst}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("has_gst", this.state.newLeadData.has_gst, ['Yes', 'No'])}
                        </span>
                      </td>
                    </tr>

                   


                    {/* <tr>
                      <td>POC Name</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.pocName}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("pocName", this.state.newLeadData.pocName, "text")}
                        </span>
                      </td>
                    </tr> */}
                   
                    <tr>
                      <td>Existing dealership/distributorship</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.existing_dealership}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("existing_dealership", this.state.newLeadData.existing_dealership, ['Yes', 'No'])}
                        </span>
                      </td>
                    </tr>


                    <tr>
                      <td>Annual Turnover</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.turnover}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("turnover", this.state.newLeadData.turnover, this.state.mappingData.turnover)}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Added On</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.ADDED_ON ? moment(this.state.leadData.ADDED_ON).format('DD, MMM YYYY') : ''}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("ADDED_ON", this.state.newLeadData.ADDED_ON ? moment(this.state.newLeadData.ADDED_ON, 'YYYYMMDD').format('YYYY-MM-DD') : '', "date")}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Show On Map</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.showOnMap}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("showOnMap", this.state.newLeadData.showOnMap, ['Yes', 'No'])}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>No of Meeting</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.noOfMeeting}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("noOfMeeting", this.state.newLeadData.noOfMeeting, [1,2,3,4,5,6,7,8,9,10])}
                        </span>
                      </td>
                    </tr>

                    <tr>
                    <td>Meeting Date</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.meetingDate ? moment(this.state.leadData.meetingDate).format("DD MMM, YYYY") : ''}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("meetingDate", this.state.newLeadData.meetingDate, "date")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                      <td>Meeting Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.meetingType}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("meetingType", this.state.newLeadData.meetingType, meetingTypeOptions)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Billing Potential</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.totalBillingPotential}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("totalBillingPotential", this.state.newLeadData.totalBillingPotential, "text")}
                        </span>
                      </td>
                    </tr>

                   
                  </table>
                </div>
                <div className="col-md-6 customer-info">
                  <table className="customer-info-right">
                    <tr>
                      <td>Current Business</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.currentBusiness}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("currentBusiness", this.state.newLeadData.currentBusiness, this.state.mappingData.currentBusiness)}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Company Name</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.companyName}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("companyName", this.state.newLeadData.companyName, "text")}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Partner Code</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.partner_code}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("partner_code", this.state.newLeadData.partner_code, "text")}
                        </span>
                      </td>
                    </tr>

                  
                    <tr>
                      <td>Drop Off Reason</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.drop_off_reason}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("drop_off_reason", this.state.newLeadData.drop_off_reason, this.state.mappingData.drop_off_reason)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Drop Off Comment</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.drop_off_comment}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("drop_off_comment", this.state.newLeadData.drop_off_comment, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Next Action</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.nextAction}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("nextAction", this.state.newLeadData.nextAction, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Reason For Hold</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.reasonForHold}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("reasonForHold", this.state.newLeadData.reasonForHold, this.state.mappingData.reasonForHold)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Reason For Hold comment</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.reasonForHoldComment}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("reasonForHoldComment", this.state.newLeadData.reasonForHoldComment, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Marketing Material Status</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.marketingMaterialStatus}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("marketingMaterialStatus", this.state.newLeadData.marketingMaterialStatus, this.state.mappingData.marketingMaterialStatus)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Tracking Number</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.trackingNumber}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("trackingNumber", this.state.newLeadData.trackingNumber, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Shop Owner</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.shopOwner}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("shopOwner", this.state.newLeadData.shopOwner, ["Yes","No"])}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Account Details</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.accountDetails}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("accountDetails", this.state.newLeadData.accountDetails, "text")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Meeting scheduled by</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.meetingScheduledBy}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("meetingScheduledBy", this.state.employeesArr ? this.state.employeesArr : '', "e_name", this.state.newLeadData.meetingScheduledBy)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Meeting done by</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.meetingDoneBy}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectNewType("meetingDoneBy", this.state.employeesArr ? this.state.employeesArr : '', "e_name", this.state.newLeadData.meetingDoneBy)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Establishment Type</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.establishment_type}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.selectType("establishment_type", this.state.newLeadData.establishment_type, Object.keys(this.state.mappingData.establishments || {}))}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Closed Order Size</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{this.state.leadData.closed_order_size}</span>
                        <span style={this.state.edit ? style.show : style.hide} className="input-group">
                          {this.inputType("closed_order_size", this.state.newLeadData.closed_order_size, "text")}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
            </div>}

            <div className='row' style={{marginBottom:'8px', justifyContent:'space-between'}}>
              {this.state.sectionName.map((section)=>(
                <div  onClick={()=>{this.handleActiveSectionName(section)}} className={`${"section"} ${this.state.activeSectionName == section ? 'activeSectionV2':'disabledSectionV2'}`}>
                  <h5>{section}</h5>
                  </div>
              ))}
            </div>

            {this.state.isVisitSectionEnabled && this.state.activeSectionName == "Visits" &&  <div>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"></span>
                    <h4><b>Visit Data</b></h4>
                    <hr></hr>
                  </div>
                  {/* <div className = "row mb-3">
                      <div className="col-3">
                        <input type="date" className="form-control" name={"visitDate"} value={visitData["date"]} min={ moment().subtract(1, 'days').format('YYYY-MM-DD')} max={ moment().format('YYYY-MM-DD')} onChange={(e) => this.handleAddDealerVisitInputChange(e)} />
                      </div>
                      <div className="col-3">
                        <input type="text" className="form-control" name={"visitBy"} value={this.state.username} disabled={true} />
                      </div>
                      <div className="col-3">
                        <input type="text" placeholder = "comment" className="form-control" name={"comment"} value={visitData["comment"]} onChange={(e) => this.handleAddDealerVisitInputChange(e)} />
                      </div>
                      <div className="col-3" style = {{textAlign: 'center'}}>
                        <button className="btn btn-success" onClick={() => this.submitDealerVisit()}>Add</button>
                      </div>
                  </div> */}
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.props.dealerVisitData || []}
                    columns={dealerVisitColumn}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>}

          {this.state.isVisitSectionEnabled && this.state.activeSectionName == "KYC" &&  <div>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"></span>
                    <h4><b>KYC Data</b></h4>
                    <hr></hr>
                  </div>
                  <div className = "col mb-3">
                      <div className="col-12">
                      <SelectKYCType name="kycFileType" options={kycTypeOptions} placeholder={"Select file type"} required={true}/>
                      </div>
                      <div className="col-12">
                       {this.FileTypeInput("kycFile", "file", true)}
                      </div>
                    
                      <div className="col-12" style = {{textAlign: 'center'}}>
                        <button className="btn btn-success" onClick={() => this.submitKYCData()}>Submit</button>
                      </div>
                  </div>
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.props.distributorKycData || []}
                    columns={kycColumn}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>}

          {this.state.isVisitSectionEnabled && this.state.activeSectionName == "Ledger" &&  <div>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"></span>
                    <h4><b>Ledger Data</b></h4>
                    <hr></hr>
                  </div>
                  <div className = "row mb-3" >
                     
                      <div className="col-3" style = {{textAlign: 'center'}}>
                        <button className="btn btn-success" onClick={() => this.handleRedirectAddPayment()}>Add Payment</button>
                      </div>
                  </div>
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.props.distributorLedgerData || []}
                    columns={ledgerColumn}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>}

           {this.state.activeSectionName == "Comments" && <div className="row" style={{ padding: "0px" }}>
              <div className="topbar-section col-12">
                <div className="container">
                  <div>
                    <h4><b>Comments</b></h4>
                  </div>
                  <hr></hr>
                  <div>
                    <div>
                      {(this.state.leadData.comments || []).map(comment => {
                        return <div><b>{comment.commentedBy.split('@')[0].split('.')[0] + ' ' + comment.commentedBy.split('@')[0].split('.')[1]} - {moment(+(comment.timestamp)).format('DD/MM/YYYY hh:mm a')}</b> - {comment.commentBody}</div>
                      })}
                    </div>
                    <div className="row" style={{ width: "80%", margin: "0px", padding: "0px" }}>
                      <div className="col-md-10 form-group"  style={{border:'none'}} >
                        <label for="newComment"></label>
                        <textarea placeholder="Add New Comment" className="form-control" rows="2" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
                      </div>
                      <div style={{ marginTop: "46px", marginLeft: '0px', border:'none' }} className="col-md-1 form-group">
                        <button s className="btn btn-success" onClick={(e) => this.commentSubmit(e)}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>


          {/* <div>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"></span>
                    <h4><b>Inventory</b></h4>
                    <hr></hr>
                  </div>
                  <div className = "row mb-3">
                      <div className="col-3">
                        <input type="date" className="form-control" name={"date"} value={newInventoryData["date"]} onChange={(e) => this.handleAddInventoryInputChange(e)} />
                      </div>
                      <div className="col-3">
                      <select
                        onChange={(e) => { this.handleAddInventoryInputChange(e) }}
                        className="form-control" name={"productId"} value={newInventoryData["productId"] || ""}
                      >
                      {(this.props.productsMapping || []).map(e => <option key={e.id} value={e.pID}>{`${e.pID} ${e.productName}`}</option>)}
                      </select>
                      </div>
                      <div className="col-3">
                        <input type="number" placeholder = "quantity" className="form-control" name={"quantity"} value={newInventoryData["quantity"]} onChange={(e) => this.handleAddInventoryInputChange(e)} />
                      </div>
                      <div className="col-3" style = {{textAlign: 'center'}}>
                        <button className="btn btn-success" onClick={() => this.submitNewInventory()}>Add</button>
                      </div>
                  </div>
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.props.zunsolarInventoryDealerData || []}
                    columns={inventoryColumns}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div> */}



         
          <div className="scrap-variables">
            <h6 style={{ paddingLeft: "10px" }}><b>UTM Variables | {this.state.showUtmVariables ? <i className="fa fa-eye-slash" style={{ cursor: "pointer" }} onClick={this.handleUtmDivToggle}></i> : <i className="fa fa-eye" onClick={this.handleUtmDivToggle} style={{ cursor: "pointer" }}></i>}</b></h6>
            <hr style={this.state.showUtmVariables ? style.show : style.hide}></hr>

            <div className="row utmVariables" style={this.state.showUtmVariables ? style.show : style.hide}>
              <div className="col-md-1">
                <p> Source</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_SOURCE}
              </div>
              <div className="col-md-1">
                <p>Campaign  </p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_CAMPAIGN}
              </div>
              <div className="col-md-1">
                <p> Content</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_CONTENT}
              </div>
              <div className="col-md-1">
                <p> Medium </p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_MEDIUM}
              </div>
              <div className="col-md-1">
                <p> Term</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.UTM_TERM}
              </div>
              <div className="col-md-1">
                <p>Adposition</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.adposition}
              </div>
              <div className="col-md-1">
                <p>Placement</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.placement}
              </div>
              <div className="col-md-1">
                <p>Loc physical ms</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.loc_physical_ms}
              </div>
              <div className="col-md-1">
                <p>Loc interest ms</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.loc_interest_ms}
              </div>
              <div className="col-md-1">
                <p>Device</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.device}
              </div>
              <div className="col-md-1">
                <p>Keyword</p>
              </div>
              <div className="col-md-2">
                {this.state.leadData.keyword}
              </div>
            </div>
          </div>

          <div>
            <div className="">
              <div className="card shadow">
                <div className="card-body">
                  <div className="card-title">
                    <span className="float-right"><button><i className="fa fa-refresh" onClick={this.handlePageRefresh}></i></button></span>
                    <h4><b>History</b></h4>
                    <hr></hr>
                  </div>
                  <ReactTable
                    filterable={true}
                    showPagination={true}
                    data={this.state.leadData.history}
                    columns={this.historyColumns}
                    ref={this.reactTable}
                    defaultPageSize={5}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      )
    }

    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      )
    }

  }
}

export default MTSingleLeadView;