import React from 'react';
import axios from 'axios';
import url from '../config';
import { LOGIN_START, LOGIN_SUCCESS, LOGIN_UNSUCCESSFUL } from './actiontypes';
axios.defaults.headers.common['Authorization'] = `Basic ${localStorage.getItem("authToken")}`;

export function login(user) {
  // console.log("newUser", user)
  return (dispatch) => {

    axios.post(url.pathBackend + "user/login", user).then((response) => {
      // console.log("response login", response.data);
      if (response.data.error) {
        dispatch({ type: LOGIN_UNSUCCESSFUL, payload: response.data.error })
      }
      if (response.data && response.data.auth) {
        // console.log("response.data.user", response.data.user);
        window.location.href = url.pathFrontend + response.data.path;
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("userObj", JSON.stringify(response.data.user));
        dispatch({ type: LOGIN_SUCCESS, payload: "successfully signed up" })
      }
    })
  }
}
