import React, {Component} from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select/dist/react-select.cjs.prod';
import { bindActionCreators } from 'redux';
import HeaderComponent from '../HeaderComponent';
import {fetchIVRMapping} from '../../actions/fetchAllMapping';
import {fetchAllTickets,fetchResponsiblePersonMapping} from '../../actions/IVRActions';
import Select from "react-select";
import { CSVLink } from "react-csv";
import ReactTable from 'react-table';
import '../../styles/returnOrder.css'
import {ticketColumns} from '../../config/column_fields'
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import axios from 'axios';
import url from '../../config';




class IVRTicketingView extends Component {
    constructor(props){
        super();
        this.state = {
            allTickets : [],
            filters: {
            },
            showFilter: false,
            ticketMapping: {},
            page: 0,
            limit: 1200,
            ticketsDataReceived: false,
            loading: true,
            userObj: {},
            responsibleMapping:{},
            searchText : '',
            ticketCount:0,
            downloadTicketData:[],
            uploadFile:'',
            uploadCsvModal: false


        }
    }

    componentDidMount(){
        this.props.fetchIVRMapping();
        this.props.fetchResponsiblePersonMapping();
        if(localStorage.getItem("userObj")){
          let userObj = JSON.parse(localStorage.getItem("userObj"));
          this.setState({
            userObj
          })
        }
    
      }
    


    componentWillMount() {
        this.props.fetchAllTickets();
    }

    componentWillReceiveProps(props) {
        let { allTickets=[], ticketsDataReceived = false, ticketMapping,responsibleMapping,ticketCount=0 } = props
        let downloadTicketData = []
        allTickets.forEach((ticketData)=>{
            let ticket = ticketData;
            delete ticket.phoneNumber;
            delete ticket.ticketType;
            delete ticket.closingDate;
            downloadTicketData.push(ticket)



    })
        this.setState({
            allTickets: allTickets,
            loading: false,
            ticketsDataReceived,
            ticketMapping,
            responsibleMapping,
            ticketCount,
            downloadTicketData
            })
    }
    handleFilterToggle = () => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val,

        })
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[name] = value;
        this.setState({
            filters
        })
    }
    handleSelectChangeFilters = (e, name) => {
        let value = '';
        value = (e || []).map(option => option.value);
        let {filters} = this.state;
        filters = JSON.parse(JSON.stringify(filters))

        this.setState({
            filters: {
                ...filters,
                [name]: value
            }
        })

    }

    fetchMoreTickets = () => {
        let { page, limit, allTickets, filters } = this.state;
        this.props.fetchAllTickets(page + 1,limit,allTickets,filters);
        this.setState({
            page: page + 1,
            ticketsDataReceived: false
        })
    }
    openUploadCsvModal = () => {
        this.setState({
        uploadCsvModal: true,

        })
    }

    closeUploadCsvModal = () => {
        this.setState({
          uploadCsvModal: false,
        })
      }
      handleUploadFile = (e) => {
        let files = e.target.files
        this.setState({
            uploadFile: files[0]
        })
    }
    handleSubmitFileUpload = async()=>{
        try {
            const file = this.state.uploadFile;
            let userObj = this.state.userObj;
            if(!file){
                return alert("Please upload file")
                
            }
            const apiFormData = new FormData();
            apiFormData.append('file', file)
            apiFormData.append('userMail',userObj.email)
            let response = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/ticketBulkUpload`,apiFormData);
            response = response.data;
            if(response && response.status=="success"){
                this.closeUploadCsvModal();
                return alert("updated successfully")
            }
            if(response.data && response.data.length){
                let missingFields = response.data;
                return alert(`wrong data- ${missingFields.join(",")}`)
            }
            return alert("somwthing went wrong")
        } catch (error) {
            return alert("somwthing went wrong")
            
        }
    

        
    }


    onApplyFilter = () => {
        let { limit, filters } = this.state;
        let searchText = filters.searchText;
        let filterData = {}
        for(let key in filters){
            if(key!="startDate" && key!="endDate" && key!="searchText" && key!="updatedStartDate" &&  key!="updatedEndDate"){
                filterData[key] = filters[key]
            }
            else{
                filterData[key] = filters[key]
            }
        }
        console.log('filterData',filterData);
        this.props.fetchAllTickets(0, limit, [], filterData,searchText);
        this.setState({
            page: 0,
            ticketsDataReceived: false
        })
    }

    // handleEditReturnOrderDataClick = (row)=>{
    //     let{uniqueRowId} = row;
    //     this.setState({
    //         returnOrderEditFlag:{
    //             ...this.state.returnOrderEditFlag,
    //             [uniqueRowId]:true
    //         },
    //         editedReturnOrderData:{
    //             ...this.state.editedReturnOrderData,
    //             [uniqueRowId]:row
    //         }
    //     })
    // }

    // handleCancelReturnOrderDataClick = (row)=>{
    //     let {uniqueRowId} = row.original;
    //     this.setState({
    //         returnOrderEditFlag:{
    //             ...this.state.returnOrderEditFlag,
    //             [uniqueRowId]: false
    //         },
    //         editedReturnOrderData:{
    //             ...this.state.editedReturnOrderData,
    //             [uniqueRowId]:false
    //         }
    //     })


    // }

    // handleSaveReturnOrderDataClick = (row) => {
    //     let {uniqueRowId} = row.original;
    //     let KeysCanBeChanged = ["qcChecked", "qcStatus", 'sent', "damageClaimAmount","damageClaimDate","damageClaimId","itemReceived","putaway","qcFeedback"]
    //     let {editedReturnOrderData,returnOrders, userObj} = this.state
    //     let newData = editedReturnOrderData[uniqueRowId];
    //     let newDataToSend = {};
    //     let updatedBy = userObj.email;
    //     KeysCanBeChanged.map((key)=>{
    //         if(newData[key]!= row.original[key]){
    //             newDataToSend[key] = newData[key];
    //         }
    //     })
        
    //     newDataToSend.updatedBy = updatedBy;
    //     if(!Object.keys(newDataToSend).length){
    //         return;
    //     }
    //     this.props.updateRetunOrderData(uniqueRowId,newDataToSend, returnOrders)
    //     this.setState({
    //         returnOrderEditFlag:{
    //             ...this.state.returnOrderEditFlag,
    //             [uniqueRowId]: false
    //         },
    //         editedReturnOrderData:{
    //             ...this.state.editedReturnOrderData,
    //             [uniqueRowId]:false
    //         }
    //     })

    // }
    
    multiSelectType = (name, options, placeholder) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isMulti
                    isSearchable
                    onChange={(e) => { this.handleSelectChangeFilters(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }
    inputType = (name, placeholder, type) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                   {type=="date" &&  <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>}
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => this.handleInputDateChangeFilters(e)} />
                </div>
            </div>
        )
    }

    // handleOrderChanges = (e,row)=>{
    //     let {name, value} = e.target;
    //     let {uniqueRowId} = row.original
    //     let {editedReturnOrderData} = this.state;
    //     editedReturnOrderData = JSON.parse(JSON.stringify(editedReturnOrderData));
    //     editedReturnOrderData[uniqueRowId][name] = value;
    //     this.setState({
    //         editedReturnOrderData
    //     })


    // }
    // InputTypeText = (name, value, type, row ) => {
    //     return (
    //         <input type={type} name ={name} value = {value} className="form-control"  onChange={(e) => this.handleReturnOrderChanges(e, row)} ></input>
    //     )
    // }
    // renderInputCell = (row) => {
    //     let { id, type } = row.column;
    //     let {returnOrderEditFlag} = this.state
    //     let checked = ["damaged/missing product","wrong product"]
    //     return (
    //             returnOrderEditFlag[row.original.uniqueRowId]  && checked.includes(row.original.qcStatus)? this.InputTypeText( id,  this.state.editedReturnOrderData[id] , type, row): row.value
    //     )
    // }

    // ticketSelect = (name, options,  placeholder, row, value='' ) => {
    //     // console.log('options', options);
    //     return (
    //         <div style={{ width: '100%' }}>
    //     <select
    //       onChange={(e) => { this.handleReturnOrderChanges(e,row) }}
    //       className="form-control" id="sel1" name={name} value={value}
    //     >
    //       <option>{placeholder}</option>
    //       {(options ? options : []).map(e => <option key={e.value}>{e.label}</option>)}
    //     </select>
    //   </div>
    //     )
    // }


    render(){ 
        let {ticketMapping,ticketsDataReceived, allTickets, showFilter,responsibleMapping, handleSelectChangeFilters ,filters,ticketCount,downloadTicketData,uploadCsvModal} = this.state
        let { handleFilterToggle, inputType, onApplyFilter, fetchMoreTickets, openUploadCsvModal, closeUploadCsvModal, handleUploadFile, handleSubmitFileUpload } = this

        const modalStyles = {
            modal: {
              width: '40%',
              maxWidth: '40%',
              padding: '40px',
              background: '#f7f7f7',
              textAlign: 'center'
            }
        };
        return (
            <div>
                <HeaderComponent/>
                <div className="container-fluid">
                <div style={{ "marginTop": 80 }} className="topbar-section allLeadViewFilters">
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                    <hr className={showFilter ? "showFilter" : "hideFilter"}></hr>
                    <div className={`${showFilter ? "showFilter" : "hideFilter"} ${"row"}`}>
                        <div className="col-md-3">
                            {inputType("startDate", "Created Start  Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("endDate", " Created End  Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("updatedStartDate", "Updated Start  Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("updatedEndDate", "Updated End  Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("nextActionStartDate", "Next Action Start  Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("nextActionEndDate", "Next Action End  Date", "date")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("responsibleTeam", responsibleMapping?.responsibleTeam, "Responsible Team")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("responsiblePerson",  filters && filters.responsibleTeam &&   responsibleMapping?.responsiblePerson[filters?.responsibleTeam] || [], "Responsible Person")}
                        </div>
                        
                        <div className="col-md-3">
                                {this.multiSelectType("type", ticketMapping.type, "Ticket type")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("subtype",filters && filters['type']  && ticketMapping.typeSubTypeMapping[filters['type']] || [] , "Ticket Sub-type")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("reasonforTicketClosed", ticketMapping.reasonforTicketClosed, "Reason for Ticket Closed")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("complaintStatus", ticketMapping.complaintStatus, "Ticket Status")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("createdBy", ticketMapping.createdBy, "Created By")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("updatedBy", ticketMapping.createdBy, "Updated By")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("b2b", ticketMapping.b2bMapping, "B2B")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("state", ticketMapping.state, "State")}
                        </div>
                        <div className="col-md-3">
                            {inputType("searchText", "Search", "text")}
                        </div>
                        

                        
                        <div className="col-md-3">
                            <button onClick={onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>


                </div>
                <div className="moreDataContainer">
                    <div>
                        <button className="btn btn-light" style={{ margin: '0 10px' }} onClick={() => fetchMoreTickets()}>More Tickets</button>

                    </div>
                    <div>
                        <button disabled className="btn btn-dark" style={{ margin: '0 10px', cursor:'auto' }}>Ticket Counts:
                         {ticketCount}</button>

                    </div>
                    <div>
                    <CSVLink filename={"ticket Data.csv"} data={downloadTicketData || []}   className="btn btn-light">
                                Download CSV <i className="fa fa-download"></i>
                    </CSVLink>
                    </div>
                    <div className="btn btn-light"  style={{ margin: '0 10px' }}  onClick={openUploadCsvModal}>
                        Upload CSV
                     </div>
                    <div style={{ float: "right", marginLeft:'5%' }}>
                            <Link to="/zunsolar/createTicket" target="_blank"><button className="createLeadBtn">Create Ticket</button></Link>
                    </div>

                </div>
                <Modal open={uploadCsvModal} onClose={closeUploadCsvModal} styles={modalStyles} center>
                            <div style={{ border: "1px solid #92b0b3", padding: "5px" }}>
                            <div className=" container-fluid uploadBox">
                            <input style={{ width: "50 %", height: '42px', fontWeight: "bold" }} type="file" onChange={(e) => { handleUploadFile(e) }} accept=".csv"></input>
                            <button style={{ height: '38px', fontWeight: "bold" }} className="btn btn-info btn-sm" onClick={handleSubmitFileUpload}>Upload <i className="fa fa-upload"></i></button>
                            <br></br>
                            {/* <div style={{ margin: "20px" }} >
                                <span style={{ color: "red" }}>{this.state.errorMessage ? this.state.errorMessage : ""}</span>
                                <span style={{ color: "green" }}>{this.state.successMessage ? this.state.successMessage : ""}</span>
                                <span style={{ color: "green" }}>{this.state.uploadMessage ? this.state.uploadMessage : ""}</span>
                            </div> */}
                            </div>
                            </div>
                    </Modal>

                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={allTickets}
                    columns={ticketColumns}
                    defaultSorted={[{ id: "updatedAt", desc: true }]}
                    style={{ height: "90vh", textAlign: 'center' }}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={!ticketsDataReceived}
                />

            </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    let ticketMapping = state.fetchAllMapping && state.fetchAllMapping.IVRMapping || {}
    console.log('ticketMapping2',ticketMapping);
    let {allTickets, ticketsDataReceived,responsibleMapping, ticketCount} = state.IVRReducer
    // let 
    return {
        ticketMapping,
        allTickets,
        ticketsDataReceived,
        responsibleMapping,
        ticketCount
    }

   
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllTickets, fetchIVRMapping,fetchResponsiblePersonMapping }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(IVRTicketingView);
