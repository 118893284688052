import { FETCH_PRODUCT_DATA_SUCCESS, FETCH_ALL_PRODUCTS_SUCESS, FETCH_ALL_PRODUCTS_DATA, FETCH_SINGLE_PRODUCT_DATA } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_PRODUCT_DATA_SUCCESS: {
            return { ...state, productData: { ...action.payload } };
        }
        case FETCH_ALL_PRODUCTS_SUCESS: {
            return { ...state, productsData: { ...action.payload } };
        }
        case FETCH_ALL_PRODUCTS_DATA:{
            return {...state,allProductData:action.payload}
        }
        case FETCH_SINGLE_PRODUCT_DATA:{
            return {...state,singleProductData:action.payload}
        }
        
        
        default:
            return state;
    }
}