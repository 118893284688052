import { render } from "@testing-library/react";
import React, { Component } from "react";

import { createTicket, fetchOrderIdMapping,fetchResponsiblePersonMapping,fetchTicketsForOrderId,fetchAllTickets,fetchOrderIdMappingByOrderId, fetchROClientIDMapping } from '../../actions/IVRActions';
import { fetchSubOrderDataForReturn } from "../../actions/zunsolarOrderTrackerActions";
import { fetchIVRMapping } from '../../actions/fetchAllMapping';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select'
import '../../styles/returnOrder.css'
import HeaderComponent from '../../components/HeaderComponent';
import ReactTable from "react-table";
import {ticketColumns} from '../../config/column_fields'
import {getUnicommerceMapping} from '../../actions/zunsolarPiInvoiceAction';



class CreateIVRTicket extends Component {
    constructor() {
        super();
        this.state = {
            ticketData: {},
            ticketMapping: {},
            userObj: {},
            orderIdMapping:[],
            responsibleMapping:{},
            ticketDataForOrderId:[],
            searchOrderId:"",
            orderIdData:{},
            clientIdMapping:{},
            clientIdMap:[],
            productsMapping: {},
            subOrderIdMapping:[],
            marketingMailList:['anuj.gangwar@zunroof.com','shivanshi.borthakur@zunpulse.com',
            "vivek.kumar@zunpulse.com","fazal.abbas@zunpulse.com","manjeet.singh@zunpulse.com","suyash.rakesh@zunpulse.com"],
            isMarketing:false,
            defaultTicketData : {
                type:"Product Issue",
                subtype:"Software Issue",
                complaintStatus:"Issue escalated to other team (Open)",
                responsibleTeam:"Support",
                responsiblePerson:"Yatin Thakur",
                priority:"Medium"
                
            }
        }
    }

    componentDidMount() {
        this.props.fetchIVRMapping();
        this.props.fetchAllTickets();
        this.props.getUnicommerceMapping();
        this.props.fetchResponsiblePersonMapping();
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            if(this.state.marketingMailList.includes(userObj.email)){
                this.setState({
                    isMarketing:true,
                    ticketData: this.state.defaultTicketData
                })

            }
            this.setState({
                userObj
            })
        }
    }

    componentWillReceiveProps(props) {
        let { ticketMapping,orderIdMapping={},responsibleMapping,ticketDataForOrderId,unicommerceMapping,clientIdMapping={},returnSubOrders={} } = props;
        let {subOrdersMap={}, subOrderIdMapping=[]} = returnSubOrders
        let  orderIdData = orderIdMapping;
        let orderIdMap = []
        let clientIdMap = []
        let productMap  = {};
        let productsMapping = {}
        let skuOptions = [];
        let {ticketData, isMarketing, defaultTicketData} = this.state;
        if(isMarketing){
            let updatedTicketData = {...ticketData, ...defaultTicketData};
            this.setState({ticketData:updatedTicketData})

        }

        unicommerceMapping.forEach((product)=>{
            productMap[product.pID] = product;
            let variant = (product.variantType && product.variantType!="NULL") ? product.variantType: '';
             skuOptions.push({label:product.productName + " "+  variant, value: product.pID});
          })

          productsMapping.productMap = productMap;
          productsMapping.skuOptions = skuOptions;
        Object.keys(orderIdMapping).forEach((orderId)=>{
            orderIdMap.push({label:orderId, value:orderId})
        })
        Object.keys(clientIdMapping).forEach((clientId)=>{
            clientIdMap.push({label:clientId, value:clientId})
        })
        this.setState({
            ticketMapping: ticketMapping,
            orderIdMapping:orderIdMap,
            responsibleMapping,
            ticketDataForOrderId,
            orderIdData,
            clientIdMapping,
            clientIdMap,
            productsMapping,
            subOrdersMap,
            subOrderIdMapping
        })
    }
    handleFileChange = (e) => {
        let files = e.target.files
        let {ticketData={}} = this.state;
        // let allFiles = {...files} || {}
        // console.log('allFiles',allFiles);
        let { name } = e.target;
        ticketData = JSON.parse(JSON.stringify(ticketData));
        ticketData[name] = files

       this.setState({
            ticketData
        })
    };


    FileTypeInput = ({name, type, label, placeholder, required=false }) => {
        return (<div
                className="col-12 col-md-3"
                style={{ width: "100%", paddingTop: "1%" }}
            >
                <div className="form-group">
                    <div className="mb-3">
                        <label>{label}</label>
            <input type={type} className="form-control" multiple  placeholder ={placeholder} name={name}  onChange={(e) => this.handleFileChange(e)} required={required}></input>
            </div>
            </div>
            </div>
        )
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        if(name=='searchOrderId'){
            this.setState({
                searchOrderId:value
            })
        }
       
        // if(name=="phoneNumber" && value.length==10){
        //     this.props.fetchOrderIdMapping(value);

        // }
        // else{
        //     this.setState({
        //         orderIdMapping:[]
        //     })
        // }
        let { ticketData = {} } = this.state;
        ticketData = JSON.parse(JSON.stringify(ticketData));
        ticketData[name] = value;
        this.setState({
            ticketData
        });
    };
    handleFetchSearchOrderId = (e)=>{
        e.preventDefault();
        let {searchOrderId} = this.state
        if(searchOrderId && searchOrderId.length>=7){
            this.setState({
                orderIdMapping:[],
                ticketData:{}
            })
            this.props.fetchOrderIdMappingByOrderId(searchOrderId);
            this.props.fetchROClientIDMapping(searchOrderId);
            return;
        }
        alert("enter at least 7  letters");
        return
    }

    handleSubmitCreateTicket = (e) => {
        e.preventDefault();
        let { ticketData = {}, userObj, ticketMapping, productsMapping, isMarketing } = this.state;
        let createdBy = userObj.username;
        ticketData.createdBy = createdBy;
        let productMap = productsMapping.productMap;
        let productName = ''
        let productId = ticketData.productId;
        if(productId && !ticketData.subOrderId){
            productName = productMap[productId].productName;
            ticketData.productName = productName
        }
        let requiredFieldsTypeInfo = [ "createdBy","phoneNumber","priority","description","type","subtype","complaintStatus", "platform"]
        let requiredFields = [ "createdBy","phoneNumber","responsibleTeam","responsiblePerson", "priority","description","type","subtype","complaintStatus", "platform"]
        let marketingRequiredFields = [ "createdBy","responsibleTeam","responsiblePerson", "priority","type","subtype","complaintStatus", "platform"]
        let missingFields = []
        if(ticketData.type=="Information" || ticketData.type=="RO" ){
            requiredFieldsTypeInfo.forEach((field)=>{
                if(!ticketData[field] || !ticketData[field].length){
                    missingFields.push(field);
                }
            }) 
        }
        else if(isMarketing){
            marketingRequiredFields.forEach((field)=>{
                if(!ticketData[field] || !ticketData[field].length){
                    missingFields.push(field);
                }
            })
        }
        else{
        requiredFields.forEach((field)=>{
            if(!ticketData[field] || !ticketData[field].length){
                missingFields.push(field);
            }
        })
    }
          if(missingFields && missingFields.length){
            alert(`Following Required Fields are missing  ${missingFields.join(",")}`)
            return;
          } 
          const formData = new FormData();
          for(let key in ticketData){
              if(key=="attachment"){
                let value = ticketData[key];
                for(let i =0;i<value.length;i++){
                    console.log('value', value[i]);
                    formData.append(key,value[i])
                }
            }
            else{
                formData.append(key, ticketData[key]);
            }
          }
        this.props.createTicket(formData, this.props.allTickets)
    
    };

    handleSelectChange = (e, name) => {
        let { value } = e;
        let { ticketData, orderIdData, clientIdMapping, subOrdersMap } = this.state;
        if(name=='orderId'){
            this.props.fetchTicketsForOrderId(value)
            this.props.fetchSubOrderDataForReturn(value, true);

        }

        ticketData = JSON.parse(JSON.stringify(ticketData));
        ticketData[name] = value;
        if(name == "orderId"){
            ticketData["phoneNumber"] =  orderIdData[value];
        }
        if(name == "roClientId"){
            ticketData["roProductId"] =  clientIdMapping[value];
        }
        if(name == "subOrderId"){
            let subOrderData = subOrdersMap[value] || {};
            ticketData["productName"] = subOrderData.productName;
            ticketData["productId"] =  subOrderData.pid;
            ticketData["platform"] = subOrderData.platform;
        }
        this.setState({
            ticketData
        });

        // let value = (e || []).map(option => option.value);

    }
    SelectType = ({ label, name, options, placeholder, disabled, value , required = false }) => {
        return (
            <div
                className="col-12 col-md-3"
                style={{ width: "100%", paddingTop: "1%" }}
            >
                <div className="form-group">
                    <div className="mb-3">
                        <label>{label}</label>
                        <Select
                            isSearchable
                            onChange={(e) => { this.handleSelectChange(e, name) }}
                            value = {{label:value,value:value}}
                            placeholder={placeholder}
                            name={name}
                            options={options}
                            isDisabled={disabled}
                            required={required}
                        />
                    </div>
                </div>
            </div>
        )
    }

    TicketInput = ({
        label,
        name,
        type,
        defaultValue,
        inputParams = {},
        required = false,
        disabled = false,
    }) => {
        let { ticketData = {} } = this.state;
        let value = ticketData[name] || defaultValue || ""
        return (
            <div
                className="col-12 col-md-3"
                style={{ width: "100%", paddingTop: "1%" }}
            >
                <div className="form-group">
                    <div className="mb-3">
                        <label>{label}</label>
                        <input
                            className="form-control customInput"
                            type={type}
                            name={name}
                            placeholder={label}
                            value={value}
                            onChange={(e) => this.handleInputChange(e)}
                            {...inputParams}
                            required={required}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        );
    };

    TicketTextArea = ({
        label,
        name,
        type,
        inputParams = {},
        required = false,
        disabled = false,
    }) => {
        let { ticketData = {} } = this.state;
        let value = ticketData[name] || "";
        return (
            <div
                className="col-12"
                style={{ width: "100%", paddingTop: "1%" }}
            >
                <div className="form-group">
                    <div className="mb-3">
                        <label>{label}</label>
                        <textarea
                            rows = {12}
                            className="form-control customInput"
                            type={type}
                            name={name}
                            placeholder={label}
                            value={value}
                            onChange={(e) => this.handleInputChange(e)}
                            {...inputParams}
                            required={required}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        let {state, TicketInput,handleSubmitCreateTicket, SelectType, TicketTextArea, handleFetchSearchOrderId,FileTypeInput} = this
        let {ticketData, ticketMapping, orderIdMapping,responsibleMapping,ticketDataForOrderId, clientIdMap, clientIdMapping, productsMapping,subOrderIdMapping,isMarketing} = state
    
        

        return (
            <div>
                <HeaderComponent />
                <div
                    className="container-fluid"
                    style={{ paddingTop: "70px", fontSize: "12px !important" }}	>

                </div>
                <div className='headContainer'>
                    <h4 className='headText' style={{textAlign:"center"}}>
                       Create Ticket
                    </h4>

                </div>
                <div className='formContainer' style={{marginTop:'12px'}}>
                    <form onSubmit={handleFetchSearchOrderId} style={{display:'flex'}}>
						 <TicketInput label="Search Orders by orderId/number" name="searchOrderId" type="text" defaultValue = {this.state.searchOrderId} />   

                        <button
                            type="submit"
                            className="btn btn-outline-success"
                            style={{ marginLeft: '15px', height: "35px",marginTop: "42px"
                         }}
                            >
                            search
                        </button>
                    </form>
                    <form onSubmit={handleSubmitCreateTicket}>
                        <div>
                            <div className='row' >
                                    <SelectType label = "Order Id" name = "orderId" options={orderIdMapping || []} placeholder="Select orderId" value ={ticketData.orderId}  required={true} />
                                    <SelectType label = "Sub Order Id" name = "subOrderId"  options={subOrderIdMapping || []}  placeholder="select subOrder Id"  value ={ticketData.subOrderId}/>
                                    <SelectType label = "Client Id" name = "roClientId" options={clientIdMap || []} placeholder="Select clientId" value = {ticketData.roClientId}  required={true} />
									<TicketInput label="RO Product Id" name="roProductId" type="text" />   
									<TicketInput label="Phone Number" name="phoneNumber" type="text" />   
                                    <SelectType label = "Type" name = "type" options={ticketMapping.type || []} placeholder="select Type"  value = {ticketData.type} required={true} />
                                    <SelectType label = "Sub-Type" name = "subtype" options={ticketData && ticketData['type']  && ticketMapping.typeSubTypeMapping &&  ticketMapping.typeSubTypeMapping[ticketData['type']] || []} placeholder="select Sub-Type"  value = {ticketData.subtype} required={true} />
                                   {(!ticketData.subOrderId || !ticketData.subOrderId.length)  && <SelectType label = "Product" name = "productId" options={productsMapping.skuOptions || []} placeholder="select product"   value ={ticketData.productId} />}
									{ticketData.subOrderId && ticketData.subOrderId.length && <TicketInput label="Product" name="productName" type="text" />}   
                                   
                                    <SelectType label = "Ticket Status" name = "complaintStatus" options={ticketMapping.complaintStatus || []} placeholder="select ticket status"  value={ticketData.complaintStatus} />
									<TicketInput label="Next Action Date" name="nextActionDate" type="date" />   
                                    <SelectType label = "B2B" name = "b2b" options={ ticketMapping.b2bMapping  || []} placeholder="select B2B"  value = {ticketData.b2b} required={false} />
                                    <SelectType label = "Platform" name = "platform" options={ticketMapping.platform || []} placeholder="select platform"  value = {ticketData.platform} required={true} />
                                    <div className="row" style={{width:"100%"}}>
                                        <div className="form-group col-9">
                                            <label>Previous Data</label>
                                            <ReactTable
                                                filterable={true}
                                                showPagination={true}
                                                sortable={true}
                                                data={ticketDataForOrderId}
                                                columns={ticketColumns}
                                                defaultSorted={[{ id: "createdOn", desc: true }]}
                                                style={{ height: "40vh", textAlign: 'center' }}
                                                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                                loading={false}
                                            />


                                        </div>
                                        <div className="form-group col-3">
									    <TicketTextArea label="Description" name="description" required={isMarketing?false:true} />   


                                        </div>


                                    </div>
                                    {/* <div className="row"> */}
                                    <SelectType label = "Responsible Team" name = "responsibleTeam" options={responsibleMapping.responsibleTeam || []} placeholder="select team"  value={ticketData.responsibleTeam} required={true} />
                                    <SelectType label = "Responsible Person" name = "responsiblePerson" options={ticketData && ticketData?.responsibleTeam &&  responsibleMapping?.responsiblePerson && responsibleMapping?.responsiblePerson[ticketData?.responsibleTeam] || []} placeholder="select Person"  value ={ticketData.responsiblePerson} required={true} />
                                    <SelectType label = "Priority" name = "priority" options={ticketMapping.priority || []} placeholder="select Priority"  value = {ticketData.priority} required={true} />
                                    <FileTypeInput name = "attachment"  type= "file" label = "Attachement" placeholder  = "Attachement"  />
                                    
                                    {/* { ticketData.type == 'RO' ? <SelectType label = "Create in maintenance" name = "createInMaintenance" options={[{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}]} value = {ticketData.createInMaintenance} required={false} /> : "" } */}

                                    {/* </div> */}




                            </div>
                            <button
                                type="submit"
                                className="btn btn-outline-success"
                                style={{ float: "right" }}>
                                Submit
                            </button>



                        </div>
                    </form>
                </div>

            </div>
        )
    }
}


function mapStateToProps(state) {
   let ticketMapping =state.fetchAllMapping && state.fetchAllMapping.IVRMapping || {}
   let {orderIdMapping,responsibleMapping={},ticketDataForOrderId=[],allTickets,clientIdMapping} = state.IVRReducer || [];
   let unicommerceMapping = state.zunsolarBulkPIInvoice && state.zunsolarBulkPIInvoice.unicommerceMapping || []
   let  returnSubOrders  =  state.zunSolarOrderTracker &&  state.zunSolarOrderTracker.returnSubOrders || {}


    return {
        ticketMapping,
        orderIdMapping,
        responsibleMapping,
        ticketDataForOrderId,
        allTickets,
        clientIdMapping,
        unicommerceMapping,
        returnSubOrders

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchIVRMapping, createTicket,fetchOrderIdMapping,fetchResponsiblePersonMapping,fetchTicketsForOrderId,fetchAllTickets,fetchOrderIdMappingByOrderId, fetchROClientIDMapping,getUnicommerceMapping,fetchSubOrderDataForReturn }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateIVRTicket)