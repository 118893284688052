import React from 'react';
import { useForm, Controller, set, useFieldArray } from "react-hook-form";
import { useState, useEffect } from "react";
import url from '../../config';
import axios from 'axios';
import Select from 'react-select';
import Header from '../../components/HeaderComponent';
import debounce from "lodash/debounce";
import '../../styles/generatePiMobile.css';

const mandatoryFiels = [
    ['dealerId', 'dealerName', 'dealerAddress', 'dealerGstin', 'dealerPincode'],
    ['date', 'companyType', 'type', 'phoneNumber', 'billFrom', 'shipTo'],
    ['pID', 'description', 'skuID', 'warranty', 'price', 'gstRate', 'discount', 'priceAfterDiscount', 'gstValue', 'priceAfterDiscountWithGST', 'quantity', 'totalGstValue', 'totalPriceAfterDiscount', 'totalPriceAfterDiscountWithGST'],
    ['freight', 'gstFreight', 'internalComment', 'externalComment', 'otherAmount'],
    ['termConditions']
]

const companyTypeOptions = ["Zunvolt", "Zunsolar", "Zunpulse", "Zunroof"];

const typeOptions = ["Dealer", "Distributor", "Partner", "DC Challan", "Credit Note", "B2B", "Consultative Sales"];

const billFromOptions = ["Haryana", "Bangalore", "Gujarat"];

const defaultTermCondition = [
    "Validity of Quoted Price: Price will be valid for order booking within 7 days",
    "Delivery : Within a week of receiving your payment and confirmation.",
    "Payment: 100% before Dispatch of material",
    "Company's written approval is required prior to selling the above mentioned products on e-commerce platforms"
]

const gstRateOptions = [
    { label: "5%", value: 5 },
    { label: "12%", value: 12 },
    { label: "18%", value: 18 },
    { label: "28%", value: 28 },
];

const StepsView = ({ currentIndex, currentStep, totalSteps }) => {
    let stepClassName, lineClassName;
    if (currentIndex < currentStep) {
        stepClassName = 'steps';
        lineClassName = 'line';
    }
    else if (currentIndex == currentStep) {
        stepClassName = 'steps';
        lineClassName = 'lineWhite';
    }
    else {
        stepClassName = 'stepsNotDone';
        lineClassName = 'lineWhite';
    }
    return (<>
        <div className={stepClassName}>
            <span class="font-weight-bold">{currentIndex + 1}</span>
        </div>
        {currentIndex + 1 < totalSteps ? <span className={lineClassName}></span> : ""}
    </>);
}

const ProgressBar = ({ currentStep }) => {
    const totalSteps = 5;
    return (
        <div class="container d-flex justify-content-center align-items-center">
            <div class="progresses">
                {
                    (Array.from(Array(totalSteps))).map((val, currentIndex) => {
                        console.log("currentIndex", currentIndex)
                        return <StepsView currentIndex={currentIndex} currentStep={currentStep} totalSteps={totalSteps} />
                    })
                }
            </div>
        </div>
    )
}

const RenderFormView = ({ currentStep, currentProduct, register, control, selectCustomOnChange, errors, selectCustomOnChangeProduct, productOptions, setValue, getValues, updateGstFreight, updatePrice }) => {
    let formView = '';
    switch (currentStep) {
        case 0:
            formView = <BuyerForm register={register} control={control} selectCustomOnChange={selectCustomOnChange} errors={errors} />
            break;
        case 1:
            formView = <PiForm register={register} errors={errors} />
            break;
        case 2:
            formView = <ProductDetails currentProduct={currentProduct} selectCustomOnChangeProduct={selectCustomOnChangeProduct} control={control} errors={errors} productOptions={productOptions} register={register} setValue={setValue} getValues={getValues} updatePrice={updatePrice} />
            break;
        case 3:
            formView = <OtherDetails register={register} errors={errors} setValue={setValue} updateGstFreight={updateGstFreight} />
            break;
        case 4:
            formView = <Terms
                getValues={getValues}
                register={register}
                errors={errors}
            />
            break;
        default:
            break;
    }
    return formView;
}

const FieldErrorView = ({ field, errors = {} }) => {
    console.log("field error view", field, errors);
    return (
        <p className='formFieldError'>{errors[field] ? errors[field].message : ""}</p>
    );
}

const ProductFieldErrorView = ({ field, errors = {}, currentProduct }) => {
    console.log("field error view", field, errors);
    errors = errors.productList || {};
    return (
        <p className='formFieldError'>{errors[currentProduct] && errors[currentProduct][field] ? errors[currentProduct][field].message : ""}</p>
    );
}

const BuyerForm = ({ register, control, selectCustomOnChange, errors }) => {

    const [dealerOptions, setDealerOptions] = useState([]);

    const inputDealerIdChange = (searchTerm) => {
        if (!searchTerm) return;
        let searchObj = { query: searchTerm }
        axios.post(`${url.pathBackend}zunsolar/lead/search`, searchObj).then(response => {
            response = response.data || [];
            response = response.map((val) => {
                return {
                    label: `${val.solarId} ${val.companyName || ""}`,
                    value: val.solarId,
                    name: val.companyName || "",
                    address: val.address || "",
                    gst_number: val.gst_number || "",
                    city: val.city || "",
                    state: val.state || "",
                    pincode: val.pincode || ""
                };
            })
            setDealerOptions(response);
        });
    }

    return (<>
        <h3 className='currentStepHeading'>Buyer Info</h3>
        <div className="form-group">
            <label for="dealerId">Buyer Id</label>
            <Controller
                name="dealerId"
                control={control}
                render={({ value, ref, field }) => (
                    <Select
                        options={dealerOptions}
                        value={dealerOptions.find(c => c.value === value)}
                        onChange={(val) => {
                            field.onChange(val.value);
                            selectCustomOnChange(val);
                        }}
                        onInputChange={debounce(
                            (e) => inputDealerIdChange(e),
                            2000
                        )}
                    />
                )}
                rules={{ required: "buyer id is mandatory" }}
                shouldUnregister={false}
            />
            <FieldErrorView field="dealerId" errors={errors} />
        </div>
        <div className="form-group">
            <label for="dealerName">Buyer Name</label>
            <input type="text" className="form-control" id="dealerName" name="dealerName" {...register("dealerName", { required: "buyer name is mandatory" })} placeholder="Buyer Name" />
            <FieldErrorView field="dealerName" errors={errors} />
        </div>
        <div className="form-group">
            <label for="dealerName">Buyer Address</label>
            <input type="text" className="form-control" id="dealerAddress" name="dealerAddress" {...register("dealerAddress", { required: "buyer address is mandatory" })} placeholder="Buyer Address" />
            <FieldErrorView field="dealerAddress" errors={errors} />
        </div>
        <div className="form-group">
            <label for="dealerGstin">Buyer GST</label>
            <input type="text" className="form-control" id="dealerGstin" name="dealerGstin" {...register("dealerGstin", { required: "buyer gst is mabdatory" })} placeholder="Buyer GST" />
            <FieldErrorView field="dealerGstin" errors={errors} />
        </div>
        <div className="form-group">
            <label for="dealerPincode">Buyer Pincode</label>
            <input type="number" className="form-control" id="dealerPincode" name="dealerPincode" {...register("dealerPincode", { required: "buyer pincode is mabdatory" })} placeholder="Buyer Pincode" />
            <FieldErrorView field="dealerPincode" errors={errors} />
        </div>
    </>)
}

const PiForm = ({ register, errors }) => {
    return (<>
        <h3 className='currentStepHeading'>PI Info</h3>
        <div className="form-group">
            <label for="date">Date</label>
            <input type="date" className="form-control" id="date" name="date" {...register("date", { required: "date is mandatory" })} placeholder="Date" />
            <FieldErrorView field="date" errors={errors} />
        </div>
        <div className="form-group">
            <label for="companyType">Company Type</label>
            <select className="form-control" name="companyType" {...register("companyType", { required: "company type is mandatory" })}>
                <option></option>
                {companyTypeOptions.map(option => <option value={option}>{option}</option>)}
            </select>
            <FieldErrorView field="companyType" errors={errors} />
        </div>
        <div className="form-group">
            <label for="type">Type</label>
            <select className="form-control" name="type" {...register("type", { required: "type is mandatory" })}>
                <option></option>
                {typeOptions.map(option => <option value={option}>{option}</option>)}
            </select>
            <FieldErrorView field="type" errors={errors} />
        </div>
        <div className="form-group">
            <label for="phoneNumber">Phone number</label>
            <input type="number" className="form-control" id="phoneNumber" name="phoneNumber" {...register("phoneNumber", { required: "phone number is mandatory", minLength: 10, maxLength: 10, pattern: /^[0-9]+$/ })} placeholder="Phone number" />
            <FieldErrorView field="phoneNumber" errors={errors} />
        </div>
        <div className="form-group">
            <label for="billFrom">Bill from</label>
            <select className="form-control" name="billFrom" {...register("billFrom", { required: "bill from is mandatory" })}>
                <option></option>
                {billFromOptions.map(option => <option value={option}>{option}</option>)}
            </select>
            <FieldErrorView field="billFrom" errors={errors} />
        </div>
        <div className="form-group">
            <label for="shipTo">Ship to</label>
            <input type="text" className="form-control" id="shipTo" name="shipTo" {...register("shipTo", { required: "ship to is mandatory" })} placeholder="ship to" />
            <FieldErrorView field="shipTo" errors={errors} />
        </div>
    </>)
}

const ProductDetails = ({ currentProduct, control, productOptions, selectCustomOnChangeProduct, errors, register, getValues, setValue, updatePrice }) => {

    return (<>
        <h3 className='currentStepHeading'>Product #{currentProduct + 1}</h3>
        <div className="form-group" key={`productList.${currentProduct}.pID`}>
            <label for="pID">Product</label>
            <Controller
                name={`productList.${currentProduct}.pID`}
                control={control}
                render={({ value, ref, field }) => (
                    <Select
                        options={productOptions}
                        value={productOptions.find(c => c.value === value)}
                        onChange={(val) => {
                            field.onChange(val.value);
                            selectCustomOnChangeProduct(val, currentProduct);
                        }}
                    />
                )}
                rules={{ required: "product id is mandatory" }}
            />
            <ProductFieldErrorView field={`pID`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.description`}>
            <label for="description">Description</label>
            <input type="text" className="form-control" id="description" name="description" {...register(`productList.${currentProduct}.description`, { required: "description is mandatory" })} placeholder="description" />
            <ProductFieldErrorView field={`description`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.skuID`}>
            <label for="skuID">SKU ID</label>
            <input type="text" className="form-control" id="skuID" name="skuID" {...register(`productList.${currentProduct}.skuID`, { required: "skuID is mandatory" })} placeholder="SKU ID" disabled={true} />
            <ProductFieldErrorView field={`skuID`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.warranty`}>
            <label for="warranty">Warranty</label>
            <input type="text" className="form-control" id="warranty" name="warranty" {...register(`productList.${currentProduct}.warranty`, { required: "warranty is mandatory" })} placeholder="warranty" />
            <ProductFieldErrorView field={`warranty`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.price`}>
            <label for="price">Item Price (Without GST Without Discount)</label>
            <input type="number" className="form-control" id="price" name="price" {...register(`productList.${currentProduct}.price`, { required: "price is mandatory", min: 1 })} placeholder="price" />
            <ProductFieldErrorView field={`price`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.gstRate`}>
            <label for="gstRate">GST Rate</label>
            <select className="form-control" name="gstRate" {...register(`productList.${currentProduct}.gstRate`, { required: "gst rate is mandatory" })}>
                <option></option>
                {gstRateOptions.map(option => <option value={option.value}>{option.label}</option>)}
            </select>
            <ProductFieldErrorView field={`gstRate`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.discount`}>
            <label for="discount">Discount</label>
            <input type="number" className="form-control" id="discount" name="discount" {...register(`productList.${currentProduct}.discount`, { required: "discount is mandatory" })} placeholder="discount" />
            <ProductFieldErrorView field={`discount`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.priceAfterDiscount`}>
            <label for="priceAfterDiscount">Item Price (Without GST After Discount)</label>
            <input type="number" className="form-control" id="priceAfterDiscount" name="priceAfterDiscount" {...register(`productList.${currentProduct}.priceAfterDiscount`, { required: "discount is mandatory" })} placeholder="discount" disabled={true} />
            <ProductFieldErrorView field={`priceAfterDiscount`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.gstValue`}>
            <label for="gstValue">GST Value</label>
            <input type="number" className="form-control" id="gstValue" name="gstValue" {...register(`productList.${currentProduct}.gstValue`, { required: "gstValue is mandatory" })} placeholder="GST Value" disabled={true} />
            <ProductFieldErrorView field={`gstValue`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.priceAfterDiscountWithGST`}>
            <label for="priceAfterDiscountWithGST">Item Price (With GST After Discount)</label>
            <input type="number" className="form-control" id="priceAfterDiscountWithGST" name="priceAfterDiscountWithGST" {...register(`productList.${currentProduct}.priceAfterDiscountWithGST`, { required: "Price is mandatory" })} placeholder="Price" disabled={true} />
            <ProductFieldErrorView field={`priceAfterDiscountWithGST`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.quantity`}>
            <label for="quantity">Quantity</label>
            <input type="number" className="form-control" id="quantity" name="quantity" {...register(`productList.${currentProduct}.quantity`, { required: "quantity is mandatory", onChange: (event) => updatePrice(event), min: 1 })} placeholder="quantity" />
            <ProductFieldErrorView field={`quantity`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.totalGstValue`}>
            <label for="totalGstValue">Total GST Value</label>
            <input type="number" className="form-control" id="totalGstValue" name="totalGstValue" {...register(`productList.${currentProduct}.totalGstValue`, { required: "mandatory field" })} placeholder="total gst value" disabled={true} />
            <ProductFieldErrorView field={`totalGstValue`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.totalPriceAfterDiscount`}>
            <label for="totalPriceAfterDiscount">Total Price (Without GST After Discount)</label>
            <input type="number" className="form-control" id="totalPriceAfterDiscount" name="totalPriceAfterDiscount" {...register(`productList.${currentProduct}.totalPriceAfterDiscount`, { required: "mandatory field" })} placeholder="total price after discount" disabled={true} />
            <ProductFieldErrorView field={`totalPriceAfterDiscount`} errors={errors} currentProduct={currentProduct} />
        </div>
        <div className="form-group" key={`productList.${currentProduct}.totalPriceAfterDiscountWithGST`}>
            <label for="totalPriceAfterDiscountWithGST">Total Price (With GST After Discount)</label>
            <input type="number" className="form-control" id="totalPriceAfterDiscountWithGST" name="totalPriceAfterDiscountWithGST" {...register(`productList.${currentProduct}.totalPriceAfterDiscountWithGST`, { required: "mandatory field" })} placeholder="total price after discount with gst" disabled={true} />
            <ProductFieldErrorView field={`totalPriceAfterDiscountWithGST`} errors={errors} currentProduct={currentProduct} />
        </div>
    </>)
}

const OtherDetails = ({ register, errors, setValue, updateGstFreight }) => {

    return (<>
        <h3 className='currentStepHeading'>Other Info</h3>
        <div className="form-group">
            <label for="freight">Freight</label>
            <input type="number" className="form-control" id="freight" name="freight" {...register("freight", { required: "freight is mandatory", onChange: (event) => updateGstFreight(event) })} placeholder="Freight" />
            <FieldErrorView field="freight" errors={errors} />
        </div>
        <div className="form-group">
            <label for="gstFreight">GST Freight</label>
            <input type="number" className="form-control" id="gstFreight" name="gstFreight" {...register("gstFreight", { required: "gst freight is mandatory" })} placeholder="GST Freight" disabled={true} />
            <FieldErrorView field="gstFreight" errors={errors} />
        </div>
        <div className="form-group">
            <label for="internalComment">Internal Comment</label>
            <input type="text" className="form-control" id="internalComment" name="internalComment" {...register("internalComment", { required: "internal comment is mandatory" })} placeholder="Internal Comment" />
            <FieldErrorView field="internalComment" errors={errors} />
        </div>
        <div className="form-group">
            <label for="externalComment">External Comment</label>
            <input type="text" className="form-control" id="externalComment" name="externalComment" {...register("externalComment", { required: "external comment is mandatory" })} placeholder="External Comment" />
            <FieldErrorView field="externalComment" errors={errors} />
        </div>
        <div className="form-group">
            <label for="otherAmount">Other Amount</label>
            <input type="number" className="form-control" id="otherAmount" name="otherAmount" {...register("otherAmount", { required: "other amount is mandatory" })} placeholder="Other Amount" />
            <FieldErrorView field="otherAmount" errors={errors} />
        </div>
    </>)
}

const Terms = ({ getValues, register, errors }) => {
    return (<>
        <h3 className='currentStepHeading'>Terms & Conditions</h3>
        <div className="form-group">
            <label for="termConditions">Terms & Conditions</label>
            {getValues(['termConditions'])[0].map((condition, index) => {
                return (
                    <>
                        <textarea type="text" className="form-control" id="termConditions" name="termConditions" {...register(`termConditions.${index}`, { required: "termConditions is mandatory" })} placeholder="termConditions" disabled={true} />
                        <FieldErrorView field="termConditions" errors={errors} />
                    </>
                )
            })
            }
        </div>
        <input className="nextButton col-4 btn btn-outline-info" type="submit" value="Submit"></input>
    </>
    )
}

const GeneratePiMobileView = () => {

    const [productMapping, setProductMapping] = useState([]);

    const [piNumber, setPiNumber] = useState('');

    useEffect(() => {
        async function getProductMapping() {
            let response = await axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchmapping`);
            response = response.data || {};
            let { uniProductIds } = response.data || {};
            let selectOptions = uniProductIds.map((uniProductId) => {
                let variantType = uniProductId.variantType;
                if (!variantType || variantType == "NULL" || variantType == "null") variantType = "";
                return {
                    label: `${uniProductId.pID} ${uniProductId.productName} ${variantType}`,
                    value: uniProductId.pID,
                    details: uniProductId
                }
            });
            // console.log(selectOptions);
            setProductMapping(selectOptions);
        }
        getProductMapping();
    }, []);

    const formSteps = ['Buyer', 'PI Details', 'Products', 'Other Details', 'T & C'];

    const [currentProduct, setCurrentProduct] = useState(0);

    const [currentStep, setCurrentStep] = useState(0);

    const { register, handleSubmit, formState: { errors }, control, setValue, trigger, getValues } = useForm({
        defaultValues: {
            termConditions: defaultTermCondition
        },
        productList: [{ comboId: 'null' }]
    });

    const {
        append
    } = useFieldArray({
        control,
        name: "productList"
    });

    const productList = getValues(['productList'])[0];

    console.log(errors);

    console.log("productMapping", productMapping);

    const handleNextClick = async () => {
        console.log("handleNextClick", errors);
        let currentStepMandatoryFields = mandatoryFiels[currentStep];
        if (currentStep == 2) {
            currentStepMandatoryFields = currentStepMandatoryFields.map(field => `productList.${currentProduct}.${field}`)
        }
        let triggerResponse = await trigger(currentStepMandatoryFields);
        console.log(triggerResponse);
        if (!triggerResponse) return;
        if (currentStep == 2 && currentProduct < productList.length - 1) {
            setCurrentProduct(currentProduct + 1);
        }
        else if (currentStep < formSteps.length - 1) {
            setCurrentStep(currentStep + 1);
        }

    }

    const handlePreviousClick = () => {
        console.log("handlePreviousClick");
        if (currentStep == 2 && currentProduct > 0) {
            setCurrentProduct(currentProduct - 1);
        }
        else if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    }

    const handleAddProduct = async () => {
        console.log("handleAddProduct");
        let currentStepMandatoryFields = mandatoryFiels[currentStep];
        currentStepMandatoryFields = currentStepMandatoryFields.map(field => `productList.${currentProduct}.${field}`)
        let triggerResponse = await trigger(currentStepMandatoryFields);
        console.log(triggerResponse);
        if (!triggerResponse) return;
        append({ comboId: 'null' });
        console.log("productList.length - 1", productList.length - 1);
        setCurrentProduct(currentProduct + 1);

    }

    const onSubmit = async (data) => {
        console.log("form data", data);
        data.productList = (data.productList.map(row => {
            return { ...row, comboId: 'null' }
        }));
        let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/createNewPi`, { data: data });
        response = response.data;
        console.log(response);
        if (!response.success || !response.data) {
            alert("Some Error Ocurred");
            return;
        }
        setPiNumber(response.data);
        return;
    }

    const selectCustomOnChange = (value) => {
        console.log("selectCustomOnChange", value);
        let { address, city, name, gst_number, state, pincode } = value;
        setValue(`dealerName`, name);
        setValue(`dealerAddress`, address);
        setValue(`dealerPincode`, pincode);
        setValue(`dealerCity`, city);
        setValue(`dealerGstin`, gst_number);
        setValue(`dealerState`, state);
    }

    const selectCustomOnChangeProduct = (value, productIndex) => {
        console.log(value, productIndex);
        let { category = "", subCategory = "", productName = "", variantType = "", sellingPrice = 0, piDefaultPrice = "", piDefaultGst = "", unicommerceId = "" } = value.details || {};
        setValue(`productList.${productIndex}.category`, category);
        setValue(`productList.${productIndex}.subcategory`, subCategory);
        setValue(`productList.${productIndex}.productName`, productName);
        setValue(`productList.${productIndex}.variantType`, variantType);
        setValue(`productList.${productIndex}.onlinePrice`, sellingPrice);
        setValue(`productList.${productIndex}.price`, piDefaultPrice);
        setValue(`productList.${productIndex}.gstRate`, piDefaultGst);
        setValue(`productList.${productIndex}.description`, productName);
        setValue(`productList.${productIndex}.skuID`, unicommerceId);
    }

    const checkFloat = (value) => {
        if (!isNaN(parseFloat(value)) && isFinite(value) && value >= 0) {
            return true;
        }
        return false;
    }

    const updatePrice = (event) => {
        console.log("update price", event.target.value);
        let [price, gstRate, quantity, discount] = getValues([`productList.${currentProduct}.price`, `productList.${currentProduct}.gstRate`, `productList.${currentProduct}.quantity`, `productList.${currentProduct}.discount`]);
        if ([price, gstRate, quantity, discount].every(checkFloat)) {
            let priceAfterDiscount = parseFloat((price - discount));
            let gstValue = parseFloat((priceAfterDiscount * gstRate * 0.01));
            let totalGstValue = parseFloat((gstValue * quantity));
            let priceAfterDiscountWithGST = parseFloat((priceAfterDiscount + gstValue));
            let totalPriceAfterDiscount = parseFloat((priceAfterDiscount * quantity));
            let totalPriceAfterDiscountWithGST = parseFloat((priceAfterDiscountWithGST * quantity));
            setValue(`productList.${currentProduct}.priceAfterDiscount`, parseFloat(priceAfterDiscount.toFixed(2)));
            setValue(`productList.${currentProduct}.gstValue`, parseFloat(gstValue.toFixed(2)));
            setValue(`productList.${currentProduct}.totalGstValue`, parseFloat(totalGstValue.toFixed(2)));
            setValue(`productList.${currentProduct}.priceAfterDiscountWithGST`, parseFloat(priceAfterDiscountWithGST.toFixed(2)));
            setValue(`productList.${currentProduct}.totalPriceAfterDiscount`, parseFloat(totalPriceAfterDiscount.toFixed(2)));
            setValue(`productList.${currentProduct}.totalPriceAfterDiscountWithGST`, parseFloat(totalPriceAfterDiscountWithGST.toFixed(2)));
        }
    }

    const updateGstFreight = (event) => {
        let freightValue = event.target.value;
        let gstFreightValue = parseFloat(parseInt(freightValue) * 0.18).toFixed(2);
        setValue(`gstFreight`, gstFreightValue);
    }

    const downloadPiFile = async (piNumber) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/invoiceDetails/downloadFile/${piNumber}?fileType=0`);
            response = response.data;
            console.log(response)
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let { Body = {}, ContentType } = response.fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            let extension = ".pdf";
            link.setAttribute('download', `PIDetails-${piNumber}${extension}`);
            document.body.appendChild(link);
            link.click();
        }
        catch (e) {
            console.log(e);
        }
    }

    return (<div className='generatePiMobileMainDiv'>
        <Header />
        {
            piNumber ? <div className='generatePiMobileSubDiv'>
                <div className='piGeneratedDiv'>
                    <h4>PI successfully generated</h4>
                    <h4>PI number - {piNumber}</h4>
                    <button className='btn btn-outline-info' onClick={() => downloadPiFile(piNumber)}>Download</button>
                </div>
            </div> : <div className='generatePiMobileSubDiv'>
                <ProgressBar currentStep={currentStep} />
                <div className='formDiv'><form onSubmit={handleSubmit(onSubmit)}><RenderFormView
                    currentStep={currentStep}
                    currentProduct={currentProduct}
                    register={register}
                    control={control}
                    selectCustomOnChange={selectCustomOnChange}
                    setValue={setValue}
                    errors={errors}
                    selectCustomOnChangeProduct={selectCustomOnChangeProduct}
                    productOptions={productMapping}
                    getValues={getValues}
                    updateGstFreight={updateGstFreight}
                    updatePrice={updatePrice}
                /></form></div>
                {currentStep == 2 ? <div><button className='col-4 btn btn-outline-info nextButton mb-3' onClick={handleAddProduct}>Add Product</button>
                    <div style={{ clear: 'both' }}></div>
                </div> : ""}
                <div>
                    {currentStep > 0 ? <button className='col-4 btn btn-outline-info previousButton' onClick={handlePreviousClick}>Previous</button> : ""}
                    {/* {currentStep == 2 ? <button className='col-4 btn btn-outline-info' onClick={handleAddProduct}>Add New Product</button> : ""} */}
                    {currentStep < formSteps.length - 1 ? <button className='col-4 btn btn-outline-info nextButton' onClick={handleNextClick}>Next</button> : ""}
                </div>
            </div>
        }
    </div>);
}

export default GeneratePiMobileView;