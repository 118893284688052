import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { zunpulseOrderColumns } from '../../config/column_fields';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { fetchCustomer } from '../../actions/whatsAppActions';
import moment from 'moment';


class WhatsAppCustomer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      order: {},
      customer : {},
      payment: {},
      products: [],
      newData: {},
      tableColumns: [],
      editStatus: {},
      editedOrderStatus: {}
    };
  }

  componentWillMount() {
    this.props.fetchCustomer(this.props.match.params.id);
    let tableColumns = zunpulseOrderColumns.map(val => {
      if (val.accessor == 'status') {
        val.Cell = row => {
          return (this.state.editStatus[row.original.id] ? this.selectInputDiv(row, this.statusMap) : <div onDoubleClick={() => { this.handleOnTableCellClick(row) }}>{this.statusMap[row.value]}</div>)
        }
      }
      return val;
    })
    this.setState({
      tableColumns
    })
  }

  componentWillReceiveProps(props) {

    let { whatsAppSingleCustomer = {} } = props;
    // console.log("shivam", customer.products)
    this.setState({
      customer: whatsAppSingleCustomer.data,
    })
  }

  handleOnUniqueProductIdClick = (row) => {
    let { uniqueProductId } = row.original;
    this.setState({
      editStatus: {
        ...this.state.editStatus,
        [`uniqueProductId_${row.original.id}`]: true
      },
      newData: {
        ...this.state.newData,
        [`uniqueProductId_${row.original.id}`]: uniqueProductId
      }
    })
  }

  handleOnTrackingIdClick = (row) => {
    let { trackingId } = row.original;
    this.setState({
      editStatus: {
        ...this.state.editStatus,
        [`trackingId_${row.original.id}`]: true
      },
      newData: {
        ...this.state.newData,
        [`trackingId_${row.original.id}`]: trackingId
      }
    })
  }

  handleEdit = () => {
    if (this.state.edit) {
      this.setState({
        edit: false,
        newData: {}
      })
    } else {
      this.setState({
        edit: true,
        newData: {
          uniqueProductId: this.state.order.uniqueProductId,
          trackingId: this.state.order.trackingId
        }
      })
    }
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      newData: {
        ...this.state.newData,
        [name]: value,
      }
    })
  }

  render() {
    let { customer } = this.state;
    console.log("naman", this.state.customer.conversationPostSales)
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }
    if (Object.keys(customer).length > 0 ) {
      return (
        <div>
          <Header getSearchResult={this.props.getSearchResult} />
          <div style={{ "marginTop": 80 }} className="container-fluid">
            <div className="topbar-section" >
              <div className="row">
                <div className="col-md-9 col-xs-12 topbar-left">
                  <div className="col-md-12">
                    <strong>Customer Description</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-section">
              <div className="row">
                <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                  <table>
                    <tr>
                      <td>Created On Date</td>
                      <td><span>{moment(+this.state.customer.createdOn).format('ll')}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td><span>{this.state.customer.name}</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-md-8 table-wrapper">
                  <div className="col-md-6 customer-info" style={{ borderRight: '0px' }}>
                    <table className="customer-info-left">
                      <tr>
                        <td>Phone No.</td>
                        <td><span>{this.state.customer.phoneNumber}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Pin Code</td>
                        <td><span>{this.state.customer.pincode}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Quote Ids</td>
                        <td><span>{(this.state.customer.quoteIdList || []).join(', ')}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-6 customer-info">
                    <table className="customer-info-right">
                      <tr>
                        <td>Language</td>
                        <td><span>{this.state.customer.language}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow">
              <div className="card-body">
                <div className="card-title">
                  <h4><b>Chat</b></h4>
                  <hr></hr>
                </div>
                <div>
                  {
                    this.state.customer.conversation.map(chat => {
                      return (
                        <div>
                        <b>{moment(+chat.timestamp).format("ll hh:mm a")}<br></br></b>
                        <b>User :</b> {chat.userText}<br></br>
                        <b>Zunroof :</b> {chat.zunroofText.map(text => <div>{(text || "").split('\n').map(line => <div>{line}<br></br></div>)}<br></br></div>)}<br></br><br></br>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="card shadow">
              <div className="card-body">
                <div className="card-title">
                  <h4><b>Post Sales Chat</b></h4>
                  <hr></hr>
                </div>
                <div>
                  {
                    (this.state.customer.conversationPostSales || []).map(chat => {
                      return (
                        <div>
                        <b>{moment(+chat.timestamp).format("ll hh:mm a")}<br></br></b>
                        <b>User :</b> {chat.userText}<br></br>
                        <b>Zunroof :</b> {chat.zunroofText.map(text => <div>{text && (typeof text == "object") ? text.English : (text || "").split('\n').map(line => <div>{line}<br></br></div>)}<br></br></div>)}<br></br><br></br>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div><Header getSearchResult={this.props.getSearchResult} /></div>
      )
    }
  }
}

function mapStateToProps(state) {
  console.log("state", state)
  let { whatsAppSingleCustomer } = state;
  return {
    whatsAppSingleCustomer
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchCustomer }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsAppCustomer);
