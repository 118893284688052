import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import Select from 'react-select';
import { repeatSalesOfferConfig, repeatSalesBannerConfig } from '../../config/column_fields';
import axios from 'axios';
import url, { zcommerceUrl } from '../../config';
import { Redirect } from 'react-router-dom';
import { productDataMapper, repeatSalesBannerMapper, repeatSalesLandingUrlMapper } from '../../helper/dataMapper';
import '../../styles/addCoupon.css';
import { fetchRepeatSalesBannerData } from '../../actions/repeatSalesActions';


const bannerType = [
  // { label: 'Bulk', value: 'bulk' },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
 
]
const bannerIds= [
  {label:"Id1", value: "id1"}
]

class ZunpulseRepeatCreateOffer extends Component {


    constructor() {
        super()
        this.state = {
            couponType: '',
            formData: {},
            productDataMapping: [],
            mapping: { products: [], bannerType:bannerType, bannerIds:bannerIds, landingUrl:[] },
            userObj: {},
            bannerData :[{isFixed:false, imageId:'', landingUrl:"",bannerName:""} ],
            bannerApiData:[]
        };
    }

   
  

    async componentDidMount() {
        try {
            const res = await axios.get(`${zcommerceUrl.productPathBackend}product/getProductMapping`);
            const productDataMapping = res?.data?.data;
            const productMapperData = productDataMapper(productDataMapping);
            const obj = { products: productMapperData };
            this.setState({
                mapping: {
                    ...this.state.mapping,
                    ...obj

                }

            })



        } catch (error) {
            console.log('error', error)

        }


    }


    componentWillMount() {
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            console.log(userObj);
            if ((userObj.access || []).includes("Website-Admin")) {
                // this.props.fetchAllProductData(this.props.match.params.id);
                this.props.fetchRepeatSalesBannerData()
                // this.bannerData = bannerData;
                console.log(this.state.bannerApiData);
            }
            this.setState({
                userObj
            })
        }
    }



componentWillReceiveProps(props) {

    console.log('table Data', props.bannerApiData);
    let filterBannerIds = repeatSalesBannerMapper(props.bannerApiData);
    let landingUrls = repeatSalesLandingUrlMapper(props.bannerApiData)
    console.log('landingUrl', landingUrls);
    console.log('filterBannerIds', filterBannerIds);
    let {mapping} = this.state;
    mapping.bannerIds = filterBannerIds;
    mapping.landingUrl = landingUrls;
        this.setState({
           mapping
        })
    }
      
   
    handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("form Data", this.state.formData);
        // console.log('bannerData', this.state.bannerData);
        let pids = this.state.formData.products;
        let allProductMapping = this.state.mapping.products;
        let productsName =  []
        allProductMapping.map((productData,index)=>{
               if(pids?.includes(productData.value)){
                productsName.push(productData.label);
               }
         })
 
         console.log('names', productsName);
 
        // return;
        try {
            const apiData = {
                 discountPercentage : this.state.formData.discountPercentage,
                 pids: this.state.formData.products?.join(',') || '',
                 products: productsName?.join(',') || '',
                 bannerData:this.state.bannerData,
                 variableBannerCount: this.state.formData.variableBannerCount,
                 key: url.apiKey,
                 email:  this.state.userObj.email
            }
            console.log('apiData', apiData);
            let apiResponse = await axios.post(`${url.pathBackend}zunpulse/repeatSales/createRepeatSalesOffer`, apiData)
            apiResponse = apiResponse.data;
            console.log('apiResponse', apiResponse);
            if(apiResponse && apiResponse.status == "success"){
                alert("offer created successfully")
                return;
            }
            alert('failed')
            return;
            // let apiResponse = await axios.post()

            // // return;
            // let response = await axios.post(`${zcommerceUrl.couponPathBackend}createCouponV2`, apiData);
            // this.setState({
            //     formData: {},
            //     freeProducts:[]
            // })
            // console.log("res", response);
            // response = response.data || {};
            // console.log(response);

            // if (response && response.status === 'success') {
            //     window.location.reload(false);
            //     alert("Coupon Added Successfully");
            //     return;
            // }

            return;

        } catch (error) {
            console.log("errr", error)
            this.setState({ formData: {} })
            alert("Failed");
            return;


        }


    }

    handleSelectChange = (e, name) => {
        if (!e) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: ""
                }
            });
            return;
        }
        let value = '';
        console.log('event', e, name);
        if (Array.isArray(e)) {
            value = e.map(row => row.value);
        }
        else {
            value = e.value;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleSelectChangeV2 = (e, name, index) => {
        let {bannerData} = this.state
        bannerData[index][name] = e.value;
        this.setState({
            bannerData
        })
        // if (!e) {
        //     this.setState({
        //         formData: {
        //             ...this.state.formData,
        //             [name]: ""
        //         }
        //     });
        //     return;
        // }
        // let value = '';
        // console.log('event', e, name);
        // if (Array.isArray(e)) {
        //     value = e.map(row => row.value);
        // }
        // else {
        //     value = e.value;
        // }
        // this.setState({
        //     formData: {
        //         ...this.state.formData,
        //         [name]: value
        //     }
        // })
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }
    handleInputChangeV2 = (e, index)=>{
        let { name, value } = e.target;
        let {bannerData} = this.state;
        bannerData[index][name] = value;
 
        this.setState({
            bannerData
        })

    }




    InputTypeSelect = ({ name, optionsName, isMulti }) => {
        let options = this.state.mapping[optionsName] || [];
        return (
            <Select
                isSearchable
                isMulti={isMulti}
                onChange={(e) => { this.handleSelectChange(e, name) }}
                options={options}
            />
        )
    }


    InputTypeText = ({ name, type, required, min }) => {
        return (
            <input type={type} className="form-control" name={name} onChange={(e) => this.handleInputChange(e)} required={required}></input>
        )
    }

    InputTypeSelectV2 = ({ name, optionsName, isMulti, index }) => {
        let options = this.state.mapping[optionsName] || [];
         return (
            <Select
                isSearchable
                isMulti={isMulti}
                onChange={(e) => { this.handleSelectChangeV2(e, name, index) }}
                options={options}
            />
        )
    }


    InputTypeTextV2 = ({ name, type, required, min,index }) => {
        return (
            <input type={type} className="form-control" name={name} onChange={(e) => this.handleInputChangeV2(e, index)} required={required}></input>
        )
    }

    InputField = ({ label, name, type, required, optionsName, isMulti }) => {
        let { InputTypeSelect, InputTypeText } = this;
        return (
            <div className="row">
                <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                    {label}
                </label>
                <div className=" col-md-8">
                    {
                        type == 'select' ? <InputTypeSelect
                            name={name}
                            optionsName={optionsName}
                            isMulti={isMulti}
                        /> : <InputTypeText
                            name={name}
                            type={type}
                            required={required}
                        />
                    }
                </div>
            </div>
        )
    }

    InputFieldV2 = ({ label, name, type, required, optionsName, isMulti,indexValue}) => {
        console.log('index', indexValue);
        let { InputTypeSelectV2, InputTypeTextV2 } = this;
        return (
            <div className='row'>
                <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                    {label}
                </label>
                <div className=" col-md-8">
                    {
                        type == 'select' ? <InputTypeSelectV2
                            name={name}
                            optionsName={optionsName}
                            isMulti={isMulti}
                            index={indexValue}
                        /> : <InputTypeTextV2
                            name={name}
                            type={type}
                            required={required}
                            min={1}
                            index={indexValue}
                        />
                    }
                </div>
            </div>
        )
    }

    handleAddBanner = () => {
      let {bannerData} = this.state;
      bannerData = [...bannerData,{isFixed:false, imageId:'', landingUrl:"",bannerName:""}]
      this.setState({
        bannerData
      })
     
  }

  handleRemoveBanner = () => {
    let bannerData = this.state.bannerData;
    bannerData = bannerData.pop();
    this.setState({
      bannerData:[bannerData]
    })
   
}


    render() {
        let { InputField, InputFieldV2, handleAddBanner , handleRemoveBanner} = this;

        return (
            <div className='mt-5 mx-auto p-5' style={{border:'1px solid #000'}}>
                <h4 style={{ fontSize: '20px', fontWeight: 'bold' }}>Create RepeatSale Offers</h4>
                <form className='mt-4' onSubmit={(e) => this.handleSubmit(e)}>
                    {
                        repeatSalesOfferConfig.map((config, index) => <InputField key={index} {...config} />)
                    }
                     {
                      <div className=''>
                        <div className='row'>
                         <div className="row ml-7 addProductRow ">
                                    <button className="button-save col-2" onClick={(e) => handleAddBanner(e)}>Add Banner</button>
                          </div>
                          {/* <div className="row ml-7 addProductRow ">
                                    <button className="button-save col-2" onClick={(e) => handleRemoveBanner(e)}>Remove Banner</button>
                          </div> */}
                          </div>
                          { this.state.bannerData.map((row,index)=>(
                            <div key={index} className="addProductFields border-bottom">
                      {repeatSalesBannerConfig.map((config, j) => <InputFieldV2  indexValue = {index} key={j} {...config} />)}
                      </div>))}
                      </div>
                        
                    }


                    <button className="btn btn-success float-right mt-1" type="submit">Submit</button>

                </form>


            </div>
        )
    }
}

function mapStateToProps(state) {
    let {bannerData=[] } =  state.repeatSalesStore
    console.log('bannerData', bannerData);
    return {

        bannerApiData: bannerData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult, fetchRepeatSalesBannerData }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseRepeatCreateOffer);

