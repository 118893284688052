import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../HeaderComponent';
import '../../styles/callCount.css';
import url from '../../config';
class CallCountZunsolar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: ""
    }
  }
  handleDateChange = (e) => {
    console.log(e.target.value);
    let name = e.target.name
    console.log(name)
    this.setState({
      [name]: e.target.value
    }, () => {
      console.log("this.state", this.state)
    })
  }
  handleSubmit = async (e) => {
    console.log("handlesubmit", this.state);
    let { startDate, endDate } = this.state;
    if (startDate && endDate) {
      let url = `${url.pathBackend}zunsolar/call/download?` + "startDate=" + startDate + "&endDate=" + endDate;
      window.open(url)
      // let req = await fetch(url)
      // console.log("requesttttttt", req);
      // window.location.href()
    }

  }
  render() {
    return (
      <div className="pulseCallCount">
        <Header />
        <div class="container">
          <h4 style={{ fontWeight: "bold" }}>Zunsolar Call Count</h4>
          <hr className="pulseRow"></hr>
          <div className=" row">
            <label className="col-md-2" style={{ marginTop: "2px", fontSize: "17px", fontWeight: "bold" }}>
              Start Date
            <span style={{ color: "red" }}>*</span>
            </label>
            <p class="col-md-10">
              <input id="startDate" type="date" className="form-control" name="startDate" onChange={(e) => this.handleDateChange(e)}></input>
            </p>
            <label className="col-md-2" style={{ marginTop: "15px", fontSize: "17px", fontWeight: "bold" }}>
              End Date
            <span style={{ color: "red" }}>*</span>
            </label>
            <div class="col-md-10">
              <input id="endDate" type="date" className="form-control" name="endDate" onChange={(e) => this.handleDateChange(e)}></input>
            </div>
          </div>
          <br></br>
          <div className="callCountSubmit">
            <button className="btn btn-success" onClick={(e) => this.handleSubmit(e)}>Submit</button>
          </div>
        </div>



        {/* <div className="container" style={{ marginTop: "100px", width: "20%", textAlign: "center" }}>
          <h4>Call Count</h4>
          <div className="row" style={{}}>
            <label for="startDate" className="control-label col-sm-2">Start Date
            <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-sm-10">

            </div>
            <input type="date" className="form-control" name='endDate' onChange={(e) => this.handleDateChange(e)}></input>
          </div>
        </div> */}
      </div>
    )
  }
}

export default CallCountZunsolar;