import { FETCH_LOGISTICS_DATA_SUCCESS, FETCH_LOGISTICS_MAPPING_SUCCESS } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_LOGISTICS_DATA_SUCCESS: {
            return { ...state, logisticsData: [...action.payload], logisticsDataReceived: true };
        }
        case FETCH_LOGISTICS_MAPPING_SUCCESS: {
            return { ...state, mapping: action.payload };
        }
        default:
            return state;
    }
}