import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { fetchWarrantyDetails,updateWarrantyClaimedStatus, handleWarrantyStatus,handleDownloadWarrantyInvoice } from '../../actions/zunpulseOrderTrackingActions';
import Header from '../../components/HeaderComponent';
import { getUnicommerceMapping } from '../../actions/zunsolarPiInvoiceAction';
// import { handleWarrantyStatus,handleDownloadWarrantyInvoice } from '../../config/column_fields';
import Select from 'react-select'


import { bindActionCreators } from 'redux';
import moment from 'moment';
import axios from 'axios'
import { CSVLink } from "react-csv";
import url from '../../config';
import Modal from 'react-responsive-modal';





class WarrantyDetails extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            tableData: [],
            filters: {},
            userObj:{},
            isModalOpen : false,
            warrantyEditFlag: {},
            editedWarrantyData: {},
            invoiceUrl:'',
            mapping:{
                warrantyClaim:["claimed", "unclaimed"]
            },
            productOptions:{},
            statusOptions:[{label:"Pending",value:"pending"},{label:"Approved",value:"approved"},{label:"Rejected",value:'rejected'}],
            filteredWarrantyData :[],
            originalWarrantyData :[]
        }
    }

    componentWillMount() {
        this.props.fetchWarrantyDetails();
        this.props.getUnicommerceMapping();
    }

    componentWillReceiveProps(props) {
        let userObj = localStorage.getItem("userObj")?JSON.parse(localStorage.getItem("userObj")):{}
        let unicommercMapping = props.unicommercMapping;
        let productIdMapping  = []
        let categoryMapping = []
        let subCategoryMapping = [];
        let category = []
        let subCategory = []
        let pids = []
        unicommercMapping.forEach((product)=>{
            pids.push(product.pID);
            category.push(product.category);
            subCategory.push(product.subCategory);
          })
          category = [...new Set(category)]
          subCategory = [...new Set(subCategory)]
          pids = [...new Set(pids)];
          categoryMapping = category.map((data)=>{
            return {label:data, value:data}
            
        })
        productIdMapping = pids.map((data)=>{
            return {label:data, value:data}
            
        })
        subCategoryMapping = subCategory.map((data)=>{
            return {label:data, value:data}
            
        })
        let productOptions = {
            categoryOptions: categoryMapping,
            subCategoryOptions :subCategoryMapping,
            productIdOptions: productIdMapping
        }
        this.setState({
            originalWarrantyData: props.warrantyDetails,
            filteredWarrantyData : props.warrantyDetails,
            userObj,
            productOptions
        })
    }
    
    filtersInputType = (name, placeholder, type, onChange, dateType) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>
                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => onChange(e, dateType)} />
                </div>
            </div>
        )
    }

    handleEditWarrantyDataClick = (row)=>{
        console.log('row data',row);
        let{warrantyId} = row;
        this.setState({
            warrantyEditFlag:{
                ...this.state.warrantyEditFlag,
                [warrantyId]:true
            },
            editedWarrantyData:{
                ...this.state.editedWarrantyData,
                [warrantyId]:row
            }
        })
    }
    handleCancelWarrantyDataClick = (row)=>{
        let{warrantyId} = row;
        console.log('warranty',row ,warrantyId);
        this.setState({
            warrantyEditFlag:{
                ...this.state.warrantyEditFlag,
                [warrantyId]:false
            },
            editedWarrantyData:{
                ...this.state.editedWarrantyData,
                [warrantyId]:false
            }
        })


    }

    openPreviewModal = () => {
        this.setState({
            isModalOpen:true
        })
    }

    closePreviewModal = (row) => {
        this.setState({
            isModalOpen: false
        })
    }

    handleWarrantyStatusUpdate  = (status, warrantyId,allData)=>{
        this.props.handleWarrantyStatus(status, warrantyId,allData)
    }

     handlePreviewInvoice = async(fileName)=>{
        try {
            const userData =  JSON.parse(localStorage.getItem('userObj'));
            const email = userData.email || "devteam@zunpulse.com"
            if(!fileName){
                alert("file does not exits");
                return;
            }
            const apiData = {
                email: email,
                fileName: fileName,
                key: "8ALS!Fcm#iWX_IgN"
            }
            console.log('apiData', apiData)
            let invoiceRes = await axios.post(`${url.pathBackend}zunpulse/invoice/downloadWarrantyInvoice`,apiData)
            invoiceRes = invoiceRes.data;
            if(invoiceRes && invoiceRes.fileData && invoiceRes.fileData.link){
                let invoiceUrl = invoiceRes.fileData.link;
                console.log('invoiceUrl',invoiceUrl);
                this.setState({
                    invoiceUrl
                })
                // this.openPreviewModal();
                window.open(invoiceRes.fileData.link,"_blank")
                return;
            }
            this.setState({
                invoiceUrl:''
            })
            
            alert("Invoice not found");
                return;
            // console.log('warrantyres', warrantyRes );
            
            
        } catch (error) {
            this.setState({
                invoiceUrl:''
            })
            console.log('error', error);
            alert("some error occured");
            return;
            
        }
    }
    handleSelectChangeFilters = (e, name) => {
        console.log('data', e, name);
        let {value} = e;
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        })
    }
    handleSaveWarrantyDataClick = (row) => {
        let {warrantyId} = row;
        let {editedWarrantyData,tableData,userObj} = this.state
        // let{editedInvoiceData,}
        let newData = editedWarrantyData[warrantyId];
        this.props.updateWarrantyClaimedStatus(tableData, newData,warrantyId,userObj.email)
        this.setState({
            warrantyEditFlag:{
                ...this.state.warrantyEditFlag,
                [warrantyId]:false
            },
            editedWarrantyData:{
                ...this.state.editedWarrantyData,
                [warrantyId]:false
            }
        })


    }
    handleWarrantyStatusChange = (e, row) => {
        let { name, value } = e.target;
        let { warrantyId } = row.original;
        let { editedWarrantyData } = this.state;
        editedWarrantyData = JSON.parse(JSON.stringify(editedWarrantyData))
        editedWarrantyData[warrantyId][name] = value;
        this.setState({
            editedWarrantyData
        })
    }
    handleInputDateChangeFilters = (e, type) => {
        let { filters = {} } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        // filters[type] = filters[type] || {};
        if(name == "startDate"){
            let startDateTimeStamp = moment(value).startOf('day').format("YYYY-MM-DD HH:mm:ss");
            filters[name] = startDateTimeStamp;
        } else if(name == "endDate"){
            let endDateTimeStamp = moment(value).endOf('day').format("YYYY-MM-DD HH:mm:ss");
            filters[name] = endDateTimeStamp;
        } 
        console.log(filters);
        this.setState({
            filters
        })
    }
    warrantyStatusSelect = (name, value, options, row) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleWarrantyStatusChange(e, row) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }
    selectType = (name, options, placeholder, onChange) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isSearchable
                    onChange={(e) => { onChange(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                />
            </div>
        )
    }

   
    onApplyFilter = () => {
        let {filters, originalWarrantyData} = this.state
        let {pid,startDate, endDate, status, category, subCategory} = filters;
        let filteredWarrantyData = originalWarrantyData;
        if(pid && pid.length>0){
            filteredWarrantyData = filteredWarrantyData.filter((product)=>{
                return pid == product.pid;
            })
        }
        if(category && category.length>0){
            filteredWarrantyData = filteredWarrantyData.filter((product)=>{
                return category.toLowerCase() == product?.category.toLowerCase();
            })
        }
        if(subCategory && subCategory.length>0){
            filteredWarrantyData = filteredWarrantyData.filter((product)=>{
                return subCategory.toLowerCase() == product?.subCategory.toLowerCase();;
            })
        }
        if(startDate && startDate.length>0){
            filteredWarrantyData = filteredWarrantyData.filter((product)=>{
                return  product.createdAt>=startDate;
            })
        }
        if(endDate && endDate.length>0){
            filteredWarrantyData = filteredWarrantyData.filter((product)=>{
                return  product.createdAt<=endDate;

            })
        }
        if(status && status.length>0){
            let statusValue = status=="pending"?0 : status=="approved"?1:2
            filteredWarrantyData = filteredWarrantyData.filter((product)=>{
                return statusValue == product.status;
            })
        }
        this.setState({
            filteredWarrantyData:filteredWarrantyData
        })

    }

    render() {
        let {handlePreviewInvoice} = this;
         const warrantyDetailsZunsolarColumns = [
            {
                Header: "Warranty Id",
                accessor: "warrantyId"
            },
            {
                Header: "order id",
                accessor: "orderId",
            },
            {
                Header: "subOrder Id",
                accessor: "subOrderId",
            },
            {
                Header: "Combo Suborder Id",
                accessor: "subOrderDetails",
            },
            {
                Header: "Warranty Months",
                accessor: "warrantyMonths",
            },
            {
                Header: "category",
                accessor: "category",
            },
            {
                Header: "Sub Category",
                accessor: "subCategory",
            },
            {
                Header: "Product Name",
                accessor: "productName",
            },
            {
                Header: "Product Id",
                accessor: "pid",
            },
            {
                Header: "Mode",
                accessor: "mode",
                Cell: row=>{
                    return row.value==1? "Online" : "Offline"
                }
            },
            {
                Header: "Platform",
                accessor: "platform",
            },
            {
                Header: "Accepted By",
                accessor: "adminEmail",
            },
            {
                Header: "Serial Number",
                accessor: "serialNumber",
            },
            {
                Header: "Date of Order",
                accessor: "purchaseDate",
                Cell: row => {
                    return moment(+row.value * 1000).format("DD MMM, YYYY")
                }
            },
            {
                Header: "validity",
                accessor: "validity",
                Cell: row => {
                    return  row.value &&  moment(+row.value * 1000).format("DD MMM, YYYY")
                }
            },
            {
                Header: "Registered On",
                accessor: "createdAt",
                Cell: row => {
                    return moment(row.value).format("DD MMM, YYYY HH:mm:ss")
                }
            },
            
            {
                Header: "Phone",
                accessor: "phoneNumber"
            },
            {
                Header: "Pincode",
                accessor: "pincode"
            },
            {
                Header: "Invoice",
                accessor: "pdfPath"
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: row=>{
                    return row.value==0? "Pending" : row.value==1 ?  "Approved" : "Rejected"
                }
            },
            {
                Header: "Claimed Status",
                accessor: "claimedStatus",
                Cell: row => {
                    return (
                        !this.state.warrantyEditFlag[row.original.warrantyId] || row.value ? row.value : this.warrantyStatusSelect("claimedStatus", this.state.editedWarrantyData[row.original.warrantyId]["claimedStatus"] || '', this.state.mapping.warrantyClaim, row)
                    )
                }
            },
            {
                Header: "Edit",
                Cell: row => {
                    return (
                    this.state.warrantyEditFlag[row.original.warrantyId] ? <div className="row text-center" style={{width: 'fit-content'}}><button className="btn btn-success btn-sm" style={{marginRight: '3px'}} onClick={(e) => this.handleSaveWarrantyDataClick(row.original)}>Save</button><button className="btn btn-light btn-sm" onClick={(e) => this.handleCancelWarrantyDataClick(row.original)}>Cancel</button></div> : <div className="row text-center" style={{width: 'fit-content'}}>{((this.state.userObj || {}).access || []).includes("Warranty-Admin")?<button className="btn btn-info btn-sm" style={{marginRight: '3px'}} onClick={(e) => this.handleEditWarrantyDataClick(row.original)}>Edit</button>:''}</div>
                    )
                },
                minWidth: 150
            },
            {
                Header: "Download Invoice",
                filterable: true,
                sortable: false,
                columns: false,
                accessor:'pdfPath',
                onClick: () => { alert('click!'); },
                Cell: row => {
                    return (
                    <div className='row d-flex justify-content-center'>
                        {<button disabled={!row.value}  onClick={()=>{handlePreviewInvoice(row.original.pdfPath)}} className="btn btn-success btn-sm col-md-10 p-2" type="submit" value="Download Invoice">Preview</button>}
                        
                    </div>
                 )
                }
        
        
            },
            {
                Header: "Approve Warranty",
                filterable: true,
                sortable: false,
                columns: false,
                accessor:'status',
                onClick: () => { alert('click!'); },
                Cell: row => {
                    return (
                    <div className='row d-flex justify-content-center'>
                        {((this.state.userObj || {}).access || []).includes("Warranty-Admin")?<button  disabled={row.value} onClick={()=>{this.handleWarrantyStatusUpdate(1,row.original.warrantyId, this.state.originalWarrantyData)}} className="btn btn-success btn-sm col-md-10 p-2" type="submit" value="Approved">Approve</button>:""}
                        
                    </div>
                 )
                }
        
        
            },
            {
                Header: "Reject Warranty",
                filterable: true,
                sortable: false,
                columns: false,
                accessor:'status',
                onClick: () => { alert('click!'); },
                Cell: row => {
                    return (
                    <div className='row d-flex justify-content-center'>
                        {((this.state.userObj || {}).access || []).includes("Warranty-Admin")?<button  disabled={row.value} onClick={()=>{this.handleWarrantyStatusUpdate(2,row.original.warrantyId, this.state.originalWarrantyData)}} className="btn btn-warning btn-sm col-md-8 p-2" type="submit" value="Approved">Reject</button>:''}
                        
                    </div>
                 )
                }
        
        
            }
        
        ];
        const modalStyles = {
            modal: {
                width: '80%'
            }
        }
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {/* <Modal open={this.state.isModalOpen} onClose={this.closePreviewModal} center> */}
                    {/* <img src={this.state.invoiceUrl} alt="invoice"/> */}
                    {/* <iframe src={this.state.invoiceUrl} ></iframe> */}
                    {/* <Document
                        file={this.state.invoiceUrl}
                        // onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={1} />
                    </Document> */}

                {/* </Modal> */}
                <div style={{ "marginTop": 60, backgroundColor: "#f7f7f7" }} className="container-fluid">
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontWeight: "bold" }}>Warranty Details</h2>
                    </div>
                    <div className="row leadtransferTopbarSection">
                        <div className="col-md-3">
                            {/* <label>Added On Start</label> */}
                            {this.filtersInputType("startDate", "Registered Start Date", 'date', this.handleInputDateChangeFilters, 'created')}
                        </div>
                        <div className="col-md-3">
                            {/* <label>Added On End</label> */}
                            {this.filtersInputType("endDate", "Registered End Date", 'date', this.handleInputDateChangeFilters, 'created')}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("pid", this.state.productOptions.productIdOptions, "Product Id", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("category", this.state.productOptions.categoryOptions, "Category", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("subCategory", this.state.productOptions.subCategoryOptions, "Sub Category", this.handleSelectChangeFilters)}
                        </div>
                        <div className="col-md-3">
                            {this.selectType("status", this.state.statusOptions, "Status", this.handleSelectChangeFilters)}
                        </div>

                        <div className="col-md-3">
                            <button className="btn btn-success" onClick={(e) => { this.onApplyFilter() }}>Apply Filter</button>
                        </div>

                    </div>

                    <div className="moreDataContainer">
                    
                    <div>
                    <CSVLink filename={"warranty Data.csv"} data={this.state.filteredWarrantyData || []}   className="btn btn-light">
                                Download CSV <i className="fa fa-download"></i>
                    </CSVLink>
                    </div>

                </div>
                    <div class="table-section">
                        <ReactTable
                            filterable={true}
                            showPagination={true}
                            sortable={true}
                            data={this.state.filteredWarrantyData}
                            columns={warrantyDetailsZunsolarColumns}
                            defaultSorted={[{ id: "createdAt", desc: true }]}
                            style={{ height: "90vh", textAlign: 'center' }}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let { warrantyDetails = [] } = state.zunSolarOrderTracker
    let unicommercMapping = state.zunsolarBulkPIInvoice.unicommerceMapping? state.zunsolarBulkPIInvoice.unicommerceMapping:[]

    return {
        warrantyDetails,
        unicommercMapping
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchWarrantyDetails, updateWarrantyClaimedStatus, handleWarrantyStatus,getUnicommerceMapping}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyDetails)
