import axios from 'axios';
import url from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_DEALERS_DATA_SEARCH, FETCH_ZUNSOLAR_PI_ORDERS_ALL, FETCH_ZUNSOLAR_PI_ORDERS_SINGLE, FETCH_ZUNSOLAR_PI_ORDERS_COMMENTS, FETCH_PI_PRODUCT_DETAILS, FETCH_ZUNSOLAR_PI_ALL_INVOICES } from './actiontypes';

export function fetchDealersData(searchString) {
    return async (dispatch) => {
        try {
            let searchObj = { query: searchString }
            let response = await axios.post(`${url.pathBackend}zunsolar/lead/search`, searchObj);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            console.log(response);
            dispatch({ type: FETCH_DEALERS_DATA_SEARCH, payload: response || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function generatePi(invoiceData = {}) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/createNewPi`, { data: invoiceData });
            response = response.data;
            if (response.e || response.error) {
                console.log(response);
                alert("Some Error Ocurred");
                return;
            }
            console.log(response);
            alert("Success");
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchZunsolarPiOrders(piNumber = 'all') {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/invoiceDetails/getPiData/${piNumber}`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            if (piNumber == 'all') {

                let pIdata = response.data;
                dispatch({ type: FETCH_ZUNSOLAR_PI_ORDERS_ALL, payload: pIdata || {} });
            } else {
                let singlePIdata = response.data[0];
                dispatch({ type: FETCH_ZUNSOLAR_PI_ORDERS_SINGLE, payload: singlePIdata || {} });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateLogisticDetail(email,piNumber, newDataToSend, allData) {
    return async (dispatch) => {
        try {
            const apiData = {
                email:email,
                piNumber:piNumber,
                ...newDataToSend
            }
            let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/updateLogisticData`,apiData)
            response = response.data;
            console.log('repsone transposr', response);
            if (response && response.status == "success") {
                console.log('new Data', newDataToSend);
                console.log('allData', allData);
            
            let index = allData.findIndex(row => row.piNumber == piNumber);
            allData = JSON.parse(JSON.stringify(allData));
            Object.keys(newDataToSend).map((key) => {
                if(newDataToSend[key]){
                allData[index][key] = newDataToSend[key]
                }
            })
            dispatch({ type: FETCH_ZUNSOLAR_PI_ORDERS_ALL, payload: allData || [] });
            alert("Updated Successfully");
            return;
        }
        alert("Some Error Ocurred");
                return;
        }
        catch (e) {
            console.log(e);
            alert("Some Error Ocurred");
                return;
        }
    }
}

export function changeAproovalStatus(piNumber, newDataToSend, allData) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/updatePi`, { ...newDataToSend, piNumber })
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let index = allData.findIndex(row => row.piNumber == piNumber);
            allData = JSON.parse(JSON.stringify(allData));
            Object.keys(newDataToSend).map((key) => {
                allData[index][key] = newDataToSend[key]
            })
            dispatch({ type: FETCH_ZUNSOLAR_PI_ORDERS_ALL, payload: allData || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function uploadInvoice(formData) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/uploadInvoicePi`, formData)
            response = response.data;
            console.log('response', response);
            if(response && response.status=="success"){
                alert("successfully uploaded")
                return;
            }
           
                alert("Some Error Ocurred");
                return;
        }
        catch (e) {
            console.log(e);
            alert("Some Error Ocurred");
                return;
        }
    }
}
export function savePIComment(piNumber, comment, allComments) {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/comments/${piNumber}`, { body: comment })
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_ZUNSOLAR_PI_ORDERS_COMMENTS, payload: allComments || [] });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function getAllPIComment(piNumber) {
    return async (dispatch) => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/invoiceDetails/comments/${piNumber}`)
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                dispatch({ type: FETCH_ZUNSOLAR_PI_ORDERS_COMMENTS, payload: [] });
                return;
            }
            let comments = response.data
            dispatch({ type: FETCH_ZUNSOLAR_PI_ORDERS_COMMENTS, payload: comments || [] });
        } catch (error) {
            console.log(error)
        }
    }
}


export function downloadInvoice(filePath, fileType) {
    return async () => {
        try {
            const apiData = {
                filePath:filePath,
                fileType:fileType
            }
            let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/downloadInvoice`, apiData);
            response = response.data;
            if (response && response.status=="success" && response.fileData) {
            let { Body = {}, ContentType } = response.fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${filePath}`);
            document.body.appendChild(link);
            link.click();
            }
        }
        catch (e) {
            console.log(e);
            alert("something wnet wrong")
            return;
        }


    }
}


export function downloadOrderFile(piNumber, fileType = 0) {
    return async () => {
        try {
            let response = await axios.get(`${url.pathBackend}zunsolar/invoiceDetails/downloadFile/${piNumber}?fileType=${fileType}`);
            response = response.data;
            if (response.error) {
                alert("Some Error Ocurred");
                return;
            }
            let { Body = {}, ContentType } = response.fileData;
            const blob = new Blob([new Uint8Array(Body.data)], { type: ContentType });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            let extension = fileType == 1 ? ".csv" : ".pdf";
            link.setAttribute('download', `PIDetails-${piNumber}${extension}`);
            document.body.appendChild(link);
            link.click();
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function fetchPiProductDetails() {
    return async (dispatch) => {
        try {
            let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/mapping`);
            response = response.data;
            if (response.e || response.error) {
                alert("Some Error Ocurred");
                return;
            }
            dispatch({ type: FETCH_PI_PRODUCT_DETAILS, payload: response || {} });
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function updateBulkInvoiceData( newDataToSend, allData) {
    return async (dispatch) => {
        try {
            let apiData = {
                account:newDataToSend.account,
                amount: newDataToSend.amount,
                createdAt: newDataToSend.createdAt,
                date: newDataToSend.date,
                dealerId: newDataToSend.dealerId,
                description: newDataToSend.description,
                hsnSac: newDataToSend.hsnSac,
                id: newDataToSend.id,
                invoiceNumber: newDataToSend.invoiceNumber,
                memoDescription: newDataToSend.memoDescription,
                name: newDataToSend.name,
                pid: newDataToSend.pid,
                platform: newDataToSend.platform,
                product: newDataToSend.product,
                qty: newDataToSend.qty,
                rate: newDataToSend.rate,
                split: newDataToSend.split,
                tax: newDataToSend.tax

            }
            console.log('apiData', apiData);
            let response = await axios.post(`${url.pathBackend}zunsolar/invoicePiPortal/updateBulkInvoice`,apiData)
            response = response.data;
            console.log('update Response', response);
            if (response.status="success") {
                
            
            let index = allData.findIndex(row => row.id == newDataToSend.id);
            allData = JSON.parse(JSON.stringify(allData));
            Object.keys(newDataToSend).map((key) => {
                allData[index][key] = newDataToSend[key]
            })
            dispatch({ type: FETCH_ZUNSOLAR_PI_ALL_INVOICES, payload: allData || [] });
            alert("Data Updated Successfully")
            return;
        }
        else{
            
            alert("something went wrong")
            return;

        }
        }
        catch (e) {
            console.log(e);
        }
    }
}

export function deletePi(piNumber, allData, email){
    return async(dispatch)=>{
        try {
            let apiData = {
                piNumber: piNumber,
                isDelete:true,
                user:email

            }
            console.log('apiData', apiData);
            // return
            let response = await axios.post(`${url.pathBackend}zunsolar/invoiceDetails/deletePi`, apiData)
            response = response.data;
            console.log('response', response,response.status=="success");
            if(response && response.status=="success"){
                console.log('inside success');
                let index = allData.findIndex(row => row.piNumber == piNumber);
                allData = JSON.parse(JSON.stringify(allData));
               allData[index].isActive = 0;
                dispatch({ type: FETCH_ZUNSOLAR_PI_ORDERS_ALL, payload: allData || [] });
                alert("Successfully Deleted");
                return;
            }
            alert("Some Error Ocurred");
            return;

            
        } catch (error) {
            console.log(error);
            alert("Some Error Ocurred");
                return;
            
        }
    }
}