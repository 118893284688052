import { FETCH_ORDERS_SUCCESS, FETCH_ORDER_MAPPING_SUCCESS, FETCH_ORDERS_SUCCESS_NEW, FETCH_ORDER_MAPPING_SUCCESS_NEW, FETCH_ZUNPULSE_COMBO_LIST_SUCCESS, FETCH_ZUNPULSE_COMBO_DETAILS, FETCH_ORDERS_INVOICES_SUCCESS } from '../actions/actiontypes';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ORDERS_SUCCESS: {
            return { ...state, orders: { ...action.payload } };
        }
        case FETCH_ORDER_MAPPING_SUCCESS: {
            return { ...state, mapping: { ...action.payload } }
        }
        case FETCH_ORDERS_SUCCESS_NEW: {
            return { ...state, orders: { ...action.payload }, ordersDataReceived: true };
        }
        case FETCH_ORDERS_INVOICES_SUCCESS: {
            return { ...state, ordersInvoices: action.payload || [], ordersDataReceived:true };
        }
        case FETCH_ORDER_MAPPING_SUCCESS_NEW: {
            return { ...state, mapping: { ...action.payload } }
        }
        case FETCH_ZUNPULSE_COMBO_LIST_SUCCESS: {
            return { ...state, ...action.payload }
        }
        case FETCH_ZUNPULSE_COMBO_DETAILS: {
            return { ...state, ...action.payload}
        }
        default:
            return state;
    }
}