import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAllLeads } from "../actions/fetchAllLeads";
import { fetchMappingZunpulse, fetchAllMapping, fetchMappingZunsolar } from "../actions/fetchAllMapping";
import { ErrorComponent } from "../components/ErrorComponent";
import PaymentSummaryMobile from "../components/zunsolar/PaymentSummaryMobile";
import { getSearchResult } from "../actions/searchAction";
import { editBulkLeads } from "../actions/editMultipleLeadsAction";
import { getUserInfo } from "../actions/user";
import {fetchMTVisitData,fetchMTPayment} from '../actions/fetchMTActions'
import {isMobile} from 'react-device-detect'
import { Redirect } from "react-router-dom";

class paymentSummary extends Component {

    constructor(props){
        super();
        this.state= {
            mtVisitDetails:{},
            userObj:{}
        }
    }

    componentWillMount() {
        let userObj = JSON.parse(localStorage.getItem("userObj"))
        console.log('userObj',userObj);
        let filterObj = { leadType: ["Retailer", "Distributor", "C&F", "B2G", "Cash & Carry"] }
        let viewList = ["employees"]
        this.props.fetchAllMapping(viewList);
        this.props.fetchAllLeads(100, 1, [], filterObj, 'zunsolar');
        this.props.fetchMappingZunsolar();
        this.props.fetchMTVisitData(userObj.email);
        this.props.fetchMTPayment(userObj.email)

        this.setState({
            userObj
        })
    }

    render() {

        if(!isMobile){
            return <Redirect to="/"/>
        }
        if (this.props.error) {

            // return (
            //     <div>
            //         <ErrorComponent error={this.props.error} />
            //     </div>
            // )
        }
        return (
            <div>
                <PaymentSummaryMobile allLeads={this.props.allLeads} allMapping={this.props.allMapping} fetchAllLeads={this.props.fetchAllLeads} searchData={this.props.searchData} editBulkLeads={this.props.editBulkLeads} mtVisitDetails= {this.props.mtVisitDetails}  mtPaymentHistory= {this.props.mtPaymentHistory}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {mtVisitDetails,mtPaymentHistory=[]} = state.MTVisitReducer;

    return {
        allLeads: state.allFetchLead ? state.allFetchLead : {},
        allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
        searchData: [],
        error: state.allFetchLead.error ? state.allFetchLead.error : undefined,
        mtVisitDetails: mtVisitDetails,
        mtPaymentHistory:mtPaymentHistory
        
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAllLeads, fetchAllMapping, fetchMappingZunpulse, getSearchResult, getUserInfo, fetchMappingZunsolar,fetchMTVisitData,fetchMTPayment }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(paymentSummary);