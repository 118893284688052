import {FETCH_ALL_MAPPING_SUCCESS,FETCH_ALL_MAPPING_START, FETCH_RETURN_ORDERS_MAPPING, FETCH_IVR_MAPPING, FETCH_DISTRIBUTOR_MAPPING} from '../actions/actiontypes';

export default function(state = {}, action) {
	switch(action.type){
		case FETCH_ALL_MAPPING_START:{
			return {};
		}
		case FETCH_ALL_MAPPING_SUCCESS:{
			return {...state, ...action.payload};
		}
		case FETCH_RETURN_ORDERS_MAPPING:{
			return {...state, returnMapping: action.payload}
		}
		case FETCH_IVR_MAPPING:{
			return {...state , IVRMapping: action.payload}
		}
		case FETCH_DISTRIBUTOR_MAPPING:{
			return {...state,distributorMapping: action.payload}
		}
		default:
			return state;
	}
}   