import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import Select from 'react-select';
import { couponFieldsConfig, productsMapping, firstTimeUserMapping,bulkCouponMapping } from '../../config/column_fields';
import axios from 'axios';
import url, { zcommerceUrl } from '../../config';
import { Redirect } from 'react-router-dom';
import { couponDataMapper, mainDataMapper, productDataMapper } from '../../helper/dataMapper';
import '../../styles/addCoupon.css';

const couponTypeOptions = [
    // { label: 'Bulk', value: 'bulk' },
    { label: 'Flat', value: 'flat' },
    { label: 'Percentage', value: 'percentage' },
    { label: 'Free', value: 'free' },
    { label: 'BXGY', value: 'BXGY' }
]

class ZunpulseAddCoupon extends Component {

    constructor() {
        super()
        this.state = {
            couponType: '',
            formData: {},
            productDataMapping: [],
            mapping: { products: productsMapping, firstTimeUser: firstTimeUserMapping,bulkCoupon:bulkCouponMapping },
            userObj: {},
            freeProducts: [{count:0, pid:''}],
        };
    }

    async componentDidMount() {
        try {
            const res = await axios.get(`${zcommerceUrl.productPathBackend}product/getProductMapping`);
            const productDataMapping = res?.data?.data;
            const productMapperData = productDataMapper(productDataMapping);
            const obj = { products: productMapperData };
            this.setState({
                mapping: {
                    ...this.state.mapping,
                    ...obj

                }

            })



        } catch (error) {
            console.log('error', error)

        }


    }

    componentWillMount() {


        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
    }

    handleCouponTypeChange = (e) => {
        this.setState({
            couponType: e.value,
            formData: {}
        })
    }

    addFreeProduct = () => {
        let { freeProducts } = this.state;
        // if (products.length > 9) {
        //     return;
        // }
        freeProducts = [...freeProducts, {count:0, pid:''}];
        this.setState({
            freeProducts
        })
    }

    CouponTypeInput = ({ name, type, required,min }) => {
        return (
            <input type={type}  className="form-control" name={name} onChange={(e) => this.handleInputChange(e)} required={required}></input>
        )
    }

    CouponTypeSelect = ({ name, optionsName, isMulti }) => {
        let options = this.state.mapping[optionsName] || [];
        return (
            <Select
                isSearchable
                isMulti={isMulti}
                onChange={(e) => { this.handleSelectChange(e, name) }}
                options={options}
            />
        )
    }
    CouponTypeInputV2 = ({ name, type, required,min , index}) => {
        return (
            <input type={type} min={min}  className="form-control" name={name} onChange={(e) => this.handleInputChangeV2(e, index)} required={required}></input>
        )
    }

    CouponTypeSelectV2 = ({ name, optionsName, isMulti, index }) => {
        let options = this.state.mapping[optionsName] || [];
        return (
            <Select
                isSearchable
                isMulti={isMulti}
                onChange={(e) => { this.handleSelectChangeV2(e, name, index) }}
                options={options}
            />
        )
    }

    InputField = ({ label, name, type, required, optionsName, isMulti }) => {
        let { CouponTypeInput, CouponTypeSelect } = this;
        return (
            <div className="row">
                <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                    {label}
                </label>
                <div className=" col-md-8">
                    {
                        type == 'select' ? <CouponTypeSelect
                            name={name}
                            optionsName={optionsName}
                            isMulti={isMulti}
                        /> : <CouponTypeInput
                            name={name}
                            type={type}
                            required={required}
                        />
                    }
                </div>
            </div>
        )
    }

    InputFieldV2 = ({ label, name, type, required, optionsName, isMulti,indexValue}) => {
        console.log('index', indexValue);
        let { CouponTypeInputV2, CouponTypeSelectV2 } = this;
        return (
            <div className='col-md-6 row'>
                <label className="col-md-6 mb-2" style={{ fontSize: "17px", fontWeight: "bold" }}>
                    {label}
                </label>
                <div className=" col-md-6">
                    {
                        type == 'select' ? <CouponTypeSelectV2
                            name={name}
                            optionsName={optionsName}
                            isMulti={isMulti}
                            index={indexValue}
                        /> : <CouponTypeInputV2
                            name={name}
                            type={type}
                            required={required}
                            min={1}
                            index={indexValue}
                        />
                    }
                </div>
            </div>
        )
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleInputChangeV2 = (e, index)=>{
        let { name, value } = e.target;
        console.log('target', name,value);
        let {freeProducts} = this.state;
        if(value ){ 
            console.log('index free', index, freeProducts[index]);
            freeProducts[index].count = parseInt(value);   
            
        }
        console.log('freeProducts', freeProducts);  
        this.setState({
            freeProducts
        })

    }

    handleSelectChange = (e, name) => {
        if(!e) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: ""
                }
            });
            return;
        }
        let value = '';
        console.log('event', e, name);
        if (Array.isArray(e)) {
            value = e.map(row => row.value);
        }
        else {
            value = e.value;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleSelectChangeV2 = (e, name, index) => {
        let value = '';
        let {freeProducts} = this.state;
        console.log('event', e);
       
       
            value = e.value;
       
        console.log('value ', value);
            freeProducts[index].pid=value
        this.setState({
            freeProducts
           
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        console.log('free Products', this.state.freeProducts);
        console.log("form Data", this.state.formData, this.state.couponType);
        // return;
        try {

            const apiData = mainDataMapper(this.state.couponType, this.state.formData, this.state.freeProducts);
            console.log('apid data', apiData);
            // return;
            let response = await axios.post(`${zcommerceUrl.couponPathBackend}createCouponV2`, apiData);
            this.setState({
                formData: {},
                freeProducts:[]
            })
            console.log("res", response);
            response = response.data || {};
            console.log(response);

            if (response && response.status === 'success') {
                window.location.reload(false);
                alert("Coupon Added Successfully");
                return;
            }

            return;

        } catch (error) {
            console.log("errr", error)
            this.setState({ formData: {} })
            alert("Failed");
            return;


        }


    }

    render() {

        let { InputField, state, InputFieldV2 } = this;
        let { couponType } = state;

        if(!((this.state.userObj.access || []).includes("Website-Admin"))) {
            return <Redirect to={"/"} />
        }

        return (
            <div>
                <Header />
                <div className="container" style={{ marginTop: "80px", textAlign: 'center' }}>
                    <h4 style={{ fontWeight: "bold" }}>Add Coupon</h4>
                    <hr />
                    <div className="row">
                        <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                            Coupon Type
                        </label>
                        <div className=" col-md-8">
                            <Select
                                isSearchable
                                onChange={(e) => { this.handleCouponTypeChange(e) }}
                                options={couponTypeOptions}
                            />
                        </div>
                    </div>

                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        {
                            (couponFieldsConfig[couponType] || []).map((config, index) => <InputField key={index} {...config} />)
                        }
                        {
                            this.state.couponType === 'free' &&
                            <div>
                                <div className="row ml-7 addProductRow ">
                                    <button className="button-save col-2" onClick={(e) => this.addFreeProduct(e)}>Add Free Product</button>
                                </div>

                                {this.state.freeProducts.map((row, index) => (
                                    <div key={index} className="row addProductFields">
                                        {(couponFieldsConfig["FreeProduct"] || []).map((config, j) => <InputFieldV2 indexValue={index} key={j} { ...config } />)}

                                    </div>

                                ))}
                            </div>



                        }
                        {
                            couponFieldsConfig[couponType] ? <button className="btn btn-success" type="submit">Submit</button> : ""
                        }
                    </form>
                </div>
            </div>
        )
    }

}

function mapStateToProps({ }) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseAddCoupon);
