import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { zunsolarOrderHistoryColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchMapping, fetchOrderData, fetchSubOrders, updateOrderData, addComment, fetchComments, fetchInventory, updateSubOrderData, fetchComboLots, updateComboLots, deleteOrder, editBulkSubOrders, downloadInventoryFile, generateInvoice, downloadInvoicePdf, fetchUpdateHistory } from '../../actions/zunsolarOrderTrackerActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader';
import swal from 'sweetalert';
import url from '../../config';
import {fetchTicketsForOrderId} from '../../actions/IVRActions'
import {ticketColumns} from '../../config/column_fields'


class ZunsolarOrderView extends Component {


    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            // showColumns: subOrderColumns,
            edit: false,
            orderData: {},
            editedOrderData: {},
            subOrders: [],
            mapping: {},
            comments: [],
            username: '',
            subOrdersEditFlag: {},
            editedSubOrderData: {},
            openLotModal: false,
            comboLots: [],
            editedComboLots: {},
            selectAll: false,
            selectedSubOrderIds: {},
            bulkAssign: {},
            employeeDetails: {},
            historyData: [],
            ticketDataForOrderId:[]

        };
    }


    componentWillMount() {
        this.props.fetchMapping();
        let orderId = decodeURIComponent(this.props.match.params.id);
        this.props.fetchOrderData(orderId);
        this.props.fetchSubOrders(orderId);
        this.props.fetchComments(orderId);
        this.props.fetchTicketsForOrderId(orderId)
        this.props.fetchInventory();
        this.props.fetchUpdateHistory(orderId);
        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            let username = userObj.username
            this.setState({
                username: username
            })
        }
    }

    componentWillReceiveProps(props) {
        let username = JSON.parse(localStorage.getItem("userObj")).email;
        let employeeDetails = JSON.parse(localStorage.getItem("userObj"))
        console.log(employeeDetails.role);
        let { mapping, orderData = {}, subOrders = [], comments = [], inventoryData = [], comboLots = [], historyData = [],ticketDataForOrderId } = props;
        this.setState({
            loading: false,
            received: true,
            mapping: mapping,
            orderData,
            subOrders,
            comments,
            employeeDetails: employeeDetails,
            inventoryData,
            comboLots,
            historyData,
            ticketDataForOrderId
        })
    }

    inputType = (name, value, type) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    selectType = (name, value, options) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleInputChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    editOrderSubmit = () => {
        let { orderData, editedOrderData } = this.state;
        let { orderCancelled, cancellationReason, otherCancellationReason, refundTxnId = "", refundDone, platform, installationCharges } = editedOrderData;
        if((orderCancelled == "Yes" && !cancellationReason) || (orderCancelled == "Yes" && cancellationReason == "Other" && !otherCancellationReason)) {
            alert("Please fill cancellation reason");
            return;
        }
        if(platform == "Consultative Sales" && !installationCharges) {
            alert("Please fill installation charges");
            return;
        }
        // refundTxnId = refundTxnId ? refundTxnId.trim() : "";
        // if(refundDone == "Yes" && !refundTxnId) {
        //     alert("Please fill refund transaction id");
        //     return;
        // }
        this.props.updateOrderData(orderData.orderId, orderData, editedOrderData);
        this.setState({
            edit: false
        })
        console.log("valid");
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            editedOrderData: {
                ...this.state.editedOrderData,
                [name]: value,
            }
        })
    }

    handleFileUpload = (e) => {
        let { name, files } = e.target;
        console.log(files)
        this.setState({
            editedOrderData: {
                ...this.state.editedOrderData,
                [name]: files[0]
            }
        }, () => { console.log(this.state.editedOrderData) })
    }

    handleEdit = () => {
        this.editDetails = {};
        if (this.state.edit) {
            this.setState({
                edit: false
            })
        } else {
            this.setState({
                editedOrderData: { ...this.state.orderData },
                edit: true
            })
        }
    }

    onClickCancel = () => {
        this.setState({
            edit: false
        })
    }


    onCommentChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            newComment: value,
        })
    }

    commentSubmit = (e) => {
        let comments = [...this.state.comments];
        let newComment = this.state.newComment;
        let newCommentData = {
            orderId: this.state.orderData.orderId,
            commentedBy: this.state.username,
            commentBody: newComment,
            timestamp: new Date().getTime()
        };
        comments.push(newCommentData);
        this.props.addComment(newCommentData, comments);
        this.setState({
            comments: comments,
            newComment: ''
        })
    }

    handleEditSubOrderClick = (row) => {
        let { subOrderId } = row;
        this.setState({
            subOrdersEditFlag: {
                ...this.state.subOrdersEditFlag,
                [subOrderId]: true
            },
            editedSubOrderData: {
                ...this.state.editedSubOrderData,
                [subOrderId]: row
            }
        })
    }

    handleSaveSubOrderClick = (row) => {
        let { subOrderId, orderId } = row.original;
        let { editedSubOrderData, subOrders } = this.state;
        console.log(editedSubOrderData);
        let newData = editedSubOrderData[subOrderId] || {};
        console.log("oldData", row.original)
        console.log("newData", newData)
        // if(newData.returnInitiated == "Yes" && !newData.returnReceiptDate){
        //     alert("Please Enter Return Receipt Date");
        //     return;
        // }
        let validationStatus = this.checkUpdatedData(row.original, newData);
        if(!validationStatus.isValid) {
            alert(validationStatus.error);
            return;
        }
        // if(!newData.claimReceived && newData.status == 'Lost/Damaged - Claim Received'){
        //     alert("Please Enter Claim Received When Status is Lost/Damaged - Claim Received");
        //     return;
        // }

        this.props.updateSubOrderData(orderId, subOrderId, row.original, newData, subOrders)
        console.log(row);
        this.setState({
            subOrdersEditFlag: {
                ...this.state.subOrdersEditFlag,
                [subOrderId]: false
            }
        })
    }

    checkUpdatedData = (oldData, updatedData) => {
        console.log(updatedData.trackingNumber);
        if(!updatedData.claimReceived && updatedData.status == 'Lost/Damaged - Claim Received') {
            return { isValid: false, error: "Please Enter Claim Received When Status is Lost/Damaged - Claim Received" };
        }
        if (updatedData.status == "In Transit" && !updatedData.trackingNumber) {
            return { isValid:false, error: "Please Enter Tracking Id"};
        }
        if (updatedData.status == "In Transit" && !updatedData.invoiceValue) {
            return { isValid:false, error:"Please Enter the Invoice Value"};
        }
        if (updatedData.status == "Delivered" && !updatedData.deliveryDate) {
            return { isValid:false, error: "Please Fill the Delivery Date"};
        }
        if (updatedData.shippingFees > 0.15* this.state.orderData.invoiceValue){
            let answer=window.confirm("Shipping charges are too high. Kindly confirm")
            if (!answer) {
                return { isValid:false, error:''};
            }
        }
        if(updatedData.claimStatus == "Claim Received Amount" && !updatedData.claimReceived) {
            return { isValid:false , error:"Please Enter Claim Received Amount"};
        }
        if(oldData.status == "Not shipped" && updatedData.status == "In Transit" && !updatedData.pickUpDate) {
            return { isValid: false, error: "Please fill Pick Up Date." };
        }
        if(oldData.status == "Returning (Before Delivery)" && updatedData.status == "Returned (Before Delivery)" && !updatedData.returnBeforeDeliveryDate) {
            return { isValid: false, error: "Please fill Return (Before Delivery) Date." };
        }
        if(oldData.status == "Returning (After Delivery)" && updatedData.status == "Returned (After Delivery)" && !updatedData.returnAfterDeliveryDate) {
            return { isValid: false, error: "Please fill Return (After Delivery) Date." };
        }
        if(oldData.status == "Not shipped" && ["Returned (Before Delivery)", "Returned (After Delivery)", "Returning (Before Delivery)", "Returning (After Delivery)"].includes(updatedData.status)) {
            return { isValid: false, error: "Returning/Returned can't be marked for orders with Not Shipped status" };
        }
        if(["Cancelled", "Returned (Before Delivery)", "Returned (After Delivery)", "Returning (Before Delivery)", "Returning (After Delivery)"].includes(updatedData.status) && !updatedData.returnReason) {
            return { isValid: false, error: "Please Fill the return reason." };
        }
        return { isValid: true };
    }

    handleCancelSubOrderClick = (row) => {
        let { subOrderId } = row.original;
        this.setState({
            subOrdersEditFlag: {
                ...this.state.subOrdersEditFlag,
                [subOrderId]: false
            }
        })
    }

    handleBulkAssignSelectChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            bulkAssign: {
                ...this.state.bulkAssign,
                [name]: value
            }
        }, () => {
            console.log("bulk", this.state.bulkAssign)
        })
    }

    inputNewType = (name, value, type, placeholder) => {

        return <input type={type} placeholder={placeholder} className="form-control" name={name} value={value} onChange={(e) => this.handleBulkAssignSelectChange(e)} />

    }

    selectNewType = (name, options, value) => {

        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleBulkAssignSelectChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option>Select</option>
                    {(options ? options : []).map(e => <option key={e}>{e}</option>)}
                </select>
            </div>
        )
    }
    subOrderSelect = (name, value, options, row) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleSubOrderChange(e, row) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    subOrderInput = (name, value, type, row) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleSubOrderChange(e, row)} />
    }

    handleSubOrderChange = (e, row) => {
        let { name, value } = e.target;
        let { subOrderId } = row.original;
        let { editedSubOrderData } = this.state;
        editedSubOrderData = JSON.parse(JSON.stringify(editedSubOrderData))
        editedSubOrderData[subOrderId][name] = value;
        this.setState({
            editedSubOrderData
        })
    }

    renderInputCell = (row) => {
        let { id, type } = row.column;
        let { subOrdersEditFlag, editedSubOrderData } = this.state;
        return (!subOrdersEditFlag[row.original.subOrderId] ? row.value : this.subOrderInput(id, editedSubOrderData[row.original.subOrderId][id], type, row))
    }

    getLotOptions = (data) => {
        let { category, subCategory, lotNo } = data;
        let { inventoryData = [] } = this.state;
        let lotOptions = inventoryData.filter(row => (row.category == category && row.subCategory == subCategory && row.inStock > 0)).map(row => row.lotNo)
        if (!lotOptions.includes(lotNo) && lotNo) {
            lotOptions.push(lotNo)
        }
        return lotOptions;
    }

    openComboLotModal = (row) => {
        let { subOrderId } = row.original;
        this.setState({
            selectedSubOrder: subOrderId,
            openLotModal: true,
            comboLots: [],
            editedOrderId: {}
        })
        this.props.fetchComboLots(subOrderId);
    }

    closeLotModal = (row) => {
        this.setState({
            openLotModal: false
        })
    }

    comboLotSelect = (name, value, options, index) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleComboLotChange(e, index) }}
                    className="form-control" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    handleComboLotChange = (e, index) => {
        let { comboLots } = this.state;
        let { value } = e.target;
        comboLots[index].lotNo = value;
        this.setState({
            comboLots
        })
        let { id } = comboLots[index];
        this.props.updateComboLots(id, value);
    }

    handleDelete = () => {
        let { subOrders } = this.state;
        let statusArr = subOrders.map((order) => {
            return order.status
        });
        console.log(statusArr);

        let id = this.state.orderData.orderId;
        let user = JSON.parse(localStorage.getItem("userObj"));
        if (user.access.includes("Zunsolar-Admin")) {
            if (statusArr.every((val) => val == "Truncated")) {
                swal({
                    title: "Are you sure?",
                    text: "Once deleted, you will not see this order again!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            swal("Your order has been deleted!", {
                                icon: "success",
                            });
                            console.log(id)
                            this.props.deleteOrder(id);
                            window.location.href = `${url.pathFrontend}/zunsolar/orders`
                        } else {
                            swal("Your order is safe!");
                        }
                    });
            } else {
                alert("Make sure that all suborders have status truncated")
                // window.location.reload();
            }
        } else {
            swal({
                text: "You do not have access to delete the order",
                icon: "error",
            })
        }

    }

    handleAllCheckbox = (e, row) => {
        let page = this.reactTable.current.state.page;
        let pageSize = this.reactTable.current.state.pageSize;
        let start = page * pageSize;
        let end = (page * pageSize + pageSize);
        let actualEnd = (row.data.length > end) ? end : row.data.length
        let data = row.data;
        let selectedSubOrderIds = {};
        if (!this.state.selectAll) {
            for (let i = start; i <= (actualEnd - 1); i++) {
                selectedSubOrderIds[data[i].subOrderId] = 1
            }
        }
        console.log(selectedSubOrderIds);
        this.setState({
            selectedSubOrderIds: { ...selectedSubOrderIds },
            selectAll: !this.state.selectAll
        })
    }

    handleCheckbox = (e, subOrderId, row, index) => {
        let status = this.state.selectedSubOrderIds[subOrderId];
        console.log(status);
        let selectedSubOrderId = JSON.parse(JSON.stringify(this.state.selectedSubOrderIds));
        let selectedSubOrderIds = { ...selectedSubOrderId, [subOrderId]: status ? 0 : 1 };

        this.setState({
            selectedSubOrderIds: selectedSubOrderIds,
            selectAll: this.state.selectAll && status ? false : this.state.selectAll
        })
    }

    handleBulkAssign = () => {
        let { subOrders, selectedSubOrderIds, bulkAssign } = this.state;
        let subOrderStatus = []; 
        let invoiceValues = [];
        subOrderStatus = subOrders.map((suborder)=>{
            invoiceValues.push(suborder.invoiceValue);
            return suborder.status;
        })
        // console.log(invoiceValues.includes(null),'........................490');
        if (invoiceValues.includes(null)){
            return alert('please fill the invoice value')
        }
        let orderId = this.state.orderData.orderId;
        for(let index = 0; index < subOrders.length; index++) {
            let { subOrderId } = subOrders[index] || {};
            if(selectedSubOrderIds[subOrderId] == 1) {
                let validationStatus = this.checkUpdatedData(subOrders[index], { ...(subOrders[index] || {}), ...(bulkAssign || {}) });
                if(!validationStatus.isValid) {
                    alert(`${subOrderId} - ${validationStatus.error}`);
                    return;
                }
            }
        }
        this.props.editBulkSubOrders(orderId, selectedSubOrderIds, subOrders, bulkAssign);
    }

    downloadFileInventory = (type, filename) => {
        this.props.downloadInventoryFile(type, filename);
    }

    generateInvoice = (e) => {
        let { subOrders } = this.state;
        let subOrderStatus = []; 
        let invoiceValues = [];
        subOrderStatus = subOrders.map((suborder)=>{
            invoiceValues.push(suborder.invoiceValue);
            return suborder.status;
        })
        // console.log(invoiceValues.includes(null),'........................490');
        if (invoiceValues.includes(null)){
            return alert('please fill the invoice value')
        }
        if (subOrderStatus.includes('Not shipped') || subOrderStatus.includes('Not Shipped')){
            this.props.generateInvoice(this.state.orderData.orderId,'Not shipped','Tax');
        } else {
            swal('Suborder Status should be "Not shipped" to generate invoice');
        }
    }

    downloadFile = () => {
        this.props.downloadInvoicePdf(this.state.orderData.orderId,this.state.orderData.invoiceType || 'Tax');
    }

    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        const modalStyles = {
            modal: {
                width: '80%'
            }
        }
        let { mapping, orderData, showColumns, subOrders, subOrdersEditFlag = {}, editedSubOrderData = {}, comboLots = [], editedComboLots = {}, historyData = [], ticketDataForOrderId } = this.state;
        let brandMapping = (mapping || {}).brand || {};
        let invoiceType = ['Tax','Cancelled']
        let { handleSaveSubOrderClick, handleEditSubOrderClick, renderInputCell, getLotOptions, openComboLotModal, handleCancelSubOrderClick } = this;
        let subOrderColumns = [
            {
                Header: row => {
                    return (
                        <div>
                            <input type="checkbox" onChange={(e) => { this.handleAllCheckbox(e, row) }} checked={this.state.selectAll ? 'checked' : ''} ></input>
                        </div>
                    )
                },
                filterable: true,
                sortable: false,
                columns: false,
                Cell: row => {
                    return (
                    <div>
                        <input type="checkbox" onChange={(e) => { this.handleCheckbox(e, row.original.subOrderId, row.original, row.index) }} checked={this.state.selectedSubOrderIds[row.original.subOrderId] ? 'checked' : ''} ></input>
                    </div>
                 )
                }
            },
            {
                Header: "Edit",
                Cell: row => {
                    return (
                        subOrdersEditFlag[row.original.subOrderId] ? <div className="row"><button className="btn btn-success btn-sm col-md-6" onClick={(e) => handleSaveSubOrderClick(row)}>Save</button><button className="btn btn-light btn-sm col-md-6" onClick={(e) => handleCancelSubOrderClick(row)}>Cancel</button></div> : <button className="btn btn-info btn-sm" onClick={(e) => handleEditSubOrderClick(row.original)}>Edit</button>
                    )
                }
            },
            {
                Header: "Sub Order Id",
                accessor: "subOrderId"
            },
            {
                Header: "Category",
                accessor: "category",
            },
            {
                Header: "Sub Category",
                accessor: "subCategory",
            },
            {
                Header: "Product Name",
                accessor: "productName",
            },
            {
                Header: "SKU Code",
                accessor: "unicomerceItemSkuCode",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderSelect("status", editedSubOrderData[row.original.subOrderId]["status"], mapping.status, row)
                    )
                }
            },
            {
                Header: "Loss/Damage Status",
                accessor: "lossdamageStatus",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderSelect("lossdamageStatus", editedSubOrderData[row.original.subOrderId]["lossdamageStatus"], mapping.lossdamageStatus, row)
                    )
                }
            },
            {
                Header: "Claim Status",
                accessor: "claimStatus",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderSelect("claimStatus", editedSubOrderData[row.original.subOrderId]["claimStatus"], mapping.claimStatus, row)
                    )
                }
            },
            {
                Header: "Brand",
                accessor: "brand",
                Cell: row => {
                    if(!subOrdersEditFlag[row.original.subOrderId]) return ( row.value || "" );
                    if(row.original.category && brandMapping[row.original.category]) {
                        return this.subOrderSelect("brand", editedSubOrderData[row.original.subOrderId]["brand"], brandMapping[row.original.category], row)
                    }
                    return "";
                }
            },
            {
                Header: "Wattage",
                accessor: "wattage",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderSelect("wattage", editedSubOrderData[row.original.subOrderId]["wattage"], mapping.wattage, row)
                    )
                }
            },
            {
                Header: "Return Reason",
                accessor: "returnReason",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderSelect("returnReason", editedSubOrderData[row.original.subOrderId]["returnReason"], mapping.returnReason, row)
                    )
                }
            },
            {
                Header: "Claim Received",
                accessor: "claimReceived",
                type: "text",
                Cell: renderInputCell
            },
            {
                Header: "Tracking ID",
                accessor: "trackingNumber",
                type: "text",
                Cell: renderInputCell
            },
            {
                Header: "Shipping Partner",
                accessor: "shippingPartner",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderSelect("shippingPartner", editedSubOrderData[row.original.subOrderId]["shippingPartner"], mapping.shippingPartner, row)
                    )
                }
            },
            {
                Header: "Tracking Link",
                accessor: "trackingLink",
                Cell: row => {
                    return row.value ? <a href={row.value} target = "_blank">Track</a> : ""
                }
            },
            // {
            //     Header: "Tracking Number",
            //     accessor: "trackingNumber",
            //     type: "text",
            //     Cell: renderInputCell
            // },
            {
                Header: "Pick Up Date",
                accessor: "pickUpDate",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderInput("pickUpDate", editedSubOrderData[row.original.subOrderId]["pickUpDate"], "date", row)
                    )
                }
            },
            {
                Header: "Delivery Date",
                accessor: "deliveryDate",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderInput("deliveryDate", editedSubOrderData[row.original.subOrderId]["deliveryDate"], "date", row)
                    )
                }
            },
            {
                Header: "Cancellation Date",
                accessor: "cancellationDate",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderInput("cancellationDate", editedSubOrderData[row.original.subOrderId]["cancellationDate"], "date", row)
                    )
                }
            },
            {
                Header: "Online Price",
                accessor: "onlinePrice",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Platform Fees",
                accessor: "platformFees",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Shipping Fees",
                accessor: "shippingFees",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Warehouse Charges",
                accessor: "warehouseCharges",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Packaging Charges",
                accessor: "packagingCharges",
                type: "number",
                Cell: renderInputCell
            },
            // {
            //     Header: "Invoice Number",
            //     accessor: "invoiceNumber",
            //     type: "text",
            //     Cell: renderInputCell
            // },
            {
                Header: "Lot Number",
                accessor: "lotNo",
                Cell: row => {
                    return (
                        row.original.category != "Combos" ? (!subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderSelect("lotNo", editedSubOrderData[row.original.subOrderId]["lotNo"], getLotOptions(row.original), row)) : <button className="btn btn-sm btn-link" onClick={() => openComboLotModal(row)}>Update</button>
                    )
                }
            },
            {
                Header: "Return (Before Delivery) Date",
                accessor: "returnBeforeDeliveryDate",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderInput("returnBeforeDeliveryDate", editedSubOrderData[row.original.subOrderId]["returnBeforeDeliveryDate"], "date", row)
                    )
                }
            },
            {
                Header: "Return (After Delivery) Date",
                accessor: "returnAfterDeliveryDate",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderInput("returnAfterDeliveryDate", editedSubOrderData[row.original.subOrderId]["returnAfterDeliveryDate"], "date", row)
                    )
                }
            },
            {
                Header: "Return Initiated",
                accessor: "returnInitiated",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderSelect("returnInitiated", editedSubOrderData[row.original.subOrderId]["returnInitiated"], ['Yes', 'No'], row)
                    )
                }
            },
            {
                Header: "Return Receipt Date",
                accessor: "returnReceiptDate",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderInput("returnReceiptDate", editedSubOrderData[row.original.subOrderId]["returnReceiptDate"], "date", row)
                    )
                }
            },
            {
                Header: "Entry Date",
                accessor: "createdOn"
            },
            {
                Header: "Invoice Number",
                accessor: "invoiceNumber",
                type: "text",
                Cell: renderInputCell
            },
            {
                Header: "Invoice Value",
                accessor: "invoiceValue",
                type: "number",
                Cell: renderInputCell
            },
            {
                Header: "Invoice Date",
                accessor: "invoiceDate",
                Cell: row => {
                    return (
                        !subOrdersEditFlag[row.original.subOrderId] ? ( row.value || "" ) : this.subOrderInput("invoiceDate", editedSubOrderData[row.original.subOrderId]["invoiceDate"], "date", row)
                    )
                }
            },
            {
                Header: "Order Invoice",
                accessor: "orderInvoice",
                Cell: row=> {
                    return(row.value?<button onClick={() => this.downloadFileInventory('subOrder',row.value)} className="btn btn-sm btn-link">Download</button>:'')
                }
            },
            {
                Header: "Uni Shipping Package Status",
                accessor: "unicomerceshippingPackageStatus",
            },
            {
                Header: "Price without GST",
                accessor: "priceProductLevel",
            },
            {
                Header: "Discount without GST",
                accessor: "discountProductLevel",
            },
            {
                Header: "GST Rate",
                accessor: "gstRateProductLevel",
            }
        ]
        console.log(this.state)
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                <Modal styles={modalStyles} open={this.state.openLotModal} onClose={this.closeLotModal} center>
                    <div style={{ textAlign: "center" }}>
                        <b>Update Lot No</b>
                        {
                            comboLots.length ? comboLots.map((product, id) => {
                                return (
                                    <div className="row" style={{ margin: "20px" }}>
                                        <div className="col-3">{product.category}</div>
                                        <div className="col-3">{product.subCategory}</div>
                                        <div className="col-6">{this.comboLotSelect("lotNo", product.lotNo, getLotOptions(product), id)}</div>
                                    </div>
                                )
                            }) : <div>Fetching....</div>
                        }
                    </div>
                </Modal>
                {this.state.received ? <div style={{ "marginTop": 60 }} className="container-fluid">
                    <div className="topbar-section" >
                        <div className="row">
                            <div className="col-md-9 col-xs-12 topbar-left">
                                <div className="col-md-6">
                                    <strong>Order Id - {orderData.orderId}</strong>
                                </div>
                            </div>
                            <div className="col-md-3 col-xs-12 topbar-right">
                                <div className="col-md-12 col-xs-12">
                                {
                                    this.state.employeeDetails.role && ["Manager", "Senior Manager", "Admin", "Super Admin"].includes(this.state.employeeDetails.role) && (this.state.orderData.platform && ['zunpulse', 'app', 'zunsolar', 'cred'].includes(this.state.orderData.platform.toLowerCase()))  ?  
                                    <>
                                    <button className="btn btn-primary"  onClick={(e) => this.generateInvoice(e)} style={{marginLeft:"15px"}}> Generate Invoice</button>
                                    <button onClick={() => this.downloadFile()} className="btn btn-success" style={{marginLeft:"15px"}} >Download Invoice <i className="fa fa-download"></i></button>
                                    </>
                                    :
                                    <>
                                    <button className="btn btn-disabled"  disabled={true}  style={{marginLeft:"15px"}}>Generate Invoice</button>
                                    <button  className="btn btn-disabled"  disabled={true} style={{marginLeft:"15px"}}>Download Invoice <i className="fa fa-download"></i></button>
                                    </>
                                }
                                    <span style={this.state.edit ? style.hide : style.show}><i onClick={this.handleEdit} style={{ cursor: "pointer", float: "right", marginTop: "8px" }} className="fas fa-pen"></i></span>
                                    < span style={this.state.edit ? style.hide : style.show} onClick={this.handleDelete} > <i style={{ cursor: "pointer", marginTop: "8px" }} className="fa fa-trash fa-lg"></i>
                                    </span >
                                    <button style={this.state.edit ? style.show : style.hide} className="button-edit" onClick={(e) => this.editOrderSubmit(e)}>Save</button>
                                    <button style={this.state.edit ? style.show : style.hide} className="button-cancel" onClick={(e) => this.onClickCancel(e)}>Cancel</button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table>
                                <tr>
                                    <td>Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("date", this.state.editedOrderData.date, "date")}
                                            </span> : <span>{this.state.orderData.date}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Platform</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("platform", this.state.editedOrderData.platform, mapping.platform)}
                                            </span> : <span>{this.state.orderData.platform}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("phone", this.state.editedOrderData.phone, "number")}
                                            </span> : <span>{this.state.orderData.phone}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("state", this.state.editedOrderData.state, mapping.states || [])}
                                            </span> : <span>{this.state.orderData.state}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pincode</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("pincode", this.state.editedOrderData.pincode, "number")}
                                            </span> : <span>{this.state.orderData.pincode}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Payment Mode</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("paymentMode", this.state.editedOrderData.paymentMode, mapping.paymentMode)}
                                            </span> : <span>{this.state.orderData.paymentMode}</span>
                                        }
                                    </td>
                                </tr>
                                {this.state.employeeDetails.access.includes('Zunsolar-Admin') ?
                                    (
                                    <tr>
                                    <td>Invoice Type</td>
                                    <td>{ this.state.edit ?    
                                            <span>
                                                {this.selectType("invoiceType", this.state.editedOrderData.invoiceType  || "", (invoiceType || []))}
                                            </span> : <span> {this.state.orderData.invoiceType} </span>
                                    }       
                                    </td>
                                    </tr>
                                    ) : null
                                
                                }
                                <tr>
                                    <td>Order Cancelled</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("orderCancelled", this.state.editedOrderData.orderCancelled, [ "Yes", "No" ])}
                                            </span> : <span>{this.state.orderData.orderCancelled}</span>
                                        }
                                    </td>
                                </tr>
                                { ( (this.state.edit && this.state.editedOrderData.orderCancelled == "Yes") || (!this.state.edit && this.state.orderData.orderCancelled == "Yes") ) ? <tr>
                                    <td>Cancellation Reason</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("cancellationReason", this.state.editedOrderData.cancellationReason, mapping.cancellationReason)}
                                            </span> : <span>{this.state.orderData.cancellationReason}</span>
                                        }
                                    </td>
                                </tr> : "" }
                                { ( (this.state.edit && this.state.editedOrderData.cancellationReason == "Other") || (!this.state.edit && this.state.orderData.cancellationReason == "Other") ) ? <tr>
                                    <td>Other Cancellation Reason</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("otherCancellationReason", this.state.editedOrderData.otherCancellationReason, "text")}
                                            </span> : <span>{this.state.orderData.otherCancellationReason}</span>
                                        }
                                    </td>
                                </tr> : "" }
                            </table>
                        </div>
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table className="customer-info-left">

                                <tr>
                                    <td>Partner Id</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("partnerId", this.state.editedOrderData.partnerId, "text")}
                                            </span> : <span>{this.state.orderData.partnerId}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Partner GST</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("partnerGst", this.state.editedOrderData.partnerGst, "text")}
                                            </span> : <span>{this.state.orderData.partnerGst}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice Value</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("invoiceValue", this.state.editedOrderData.invoiceValue, "number")}
                                            </span> : <span>{this.state.orderData.invoiceValue}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice Number</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("invoiceNumber", this.state.editedOrderData.invoiceNumber, "text")}
                                            </span> : <span>{this.state.orderData.invoiceNumber}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("invoiceDate", this.state.editedOrderData.invoiceDate, "date")}
                                            </span> : <span>{this.state.orderData.invoiceDate}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice</td>
                                    <td>
                                        {
                                            this.props.orderData.orderInvoice ? <button onClick={() => this.downloadFile('order', this.state.orderData ? this.state.orderData.orderInvoice : "")} className="btn btn-sm btn-link">Download</button> : this.state.edit ?
                                                <span className="input-group">
                                                    <input type="file" name="orderInvoice" onChange={(e) => this.handleFileUpload(e)}></input>
                                                </span> : <span></span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Coupon Discount</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("couponDiscount", this.state.editedOrderData.couponDiscount, "number")}
                                            </span> : <span>{this.state.orderData.couponDiscount}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Promocash Discount</td>
                                    <td>
                                        {
                                            <span>{ this.state.orderData.paymentStatus && ["success", "failure"].includes(this.state.orderData.paymentStatus.toLowerCase()) ? this.state.orderData.promoCash : ""}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Refund Done</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("refundDone", this.state.editedOrderData.refundDone, [ "Yes", "No" ])}
                                            </span> : <span>{this.state.orderData.refundDone}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Refund Transaction Id</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("refundTxnId", this.state.editedOrderData.refundTxnId, "text")}
                                            </span> : <span>{this.state.orderData.refundTxnId}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Installation Charges</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("installationCharges", this.state.editedOrderData.installationCharges, "number")}
                                            </span> : <span>{this.state.orderData.installationCharges}</span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info">
                            <table className="customer-info-right">
                                <tr>
                                    <td>Name</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("name", this.state.editedOrderData.name, "text")}
                                            </span> : <span>{this.state.orderData.name}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("address", this.state.editedOrderData.address, "text")}
                                            </span> : <span>{this.state.orderData.address}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("city", this.state.editedOrderData.city, "text")}
                                            </span> : <span>{this.state.orderData.city}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transaction Id</td>
                                    <td>
                                        {
                                            this.state.orderData.transactionId
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Payment Status</td>
                                    <td>
                                        {
                                            this.state.orderData.paymentStatus
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Coupon Name</td>
                                    <td>
                                        {
                                            <span>{this.state.orderData.coupon}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Referral Code</td>
                                    <td>
                                        {
                                            this.state.orderData.referralCode
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Advanced Recieved</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("advancedRecieved", this.state.editedOrderData.advancedRecieved, "number")}
                                            </span> : <span>{this.state.orderData.advancedRecieved}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Shipment Location</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("shipmentLocation", this.state.editedOrderData.shipmentLocation, mapping.shipmentLocation)}
                                            </span> : <span>{this.state.orderData.shipmentLocation || (mapping.shipmentLocationMapping && this.state.orderData.state ? (mapping.shipmentLocationMapping[this.state.orderData.state.toUpperCase()] || "GURGAON") : "GURGAON")}</span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div className="">
                            <div className="card shadow">
                                <div className="card-body">
                                    <div className="card-title">
                                        <h4><b>Sub Orders</b></h4>
                                        <hr></hr>
                                        <div className="row" style={{ marginTop: "25px" }} style={Object.values(this.state.selectedSubOrderIds).includes(1) ? style.show : style.hide}>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Status</label>
                                                {this.selectNewType("status", mapping.status, this.state.bulkAssign.status)}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Loss/Damage Status</label>
                                                {this.selectNewType("lossdamageStatus", mapping.lossdamageStatus, this.state.bulkAssign.lossdamageStatus)}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Claim Status</label>
                                                {this.selectNewType("claimStatus", mapping.claimStatus, this.state.bulkAssign.claimStatus)}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Shipping Partner</label>
                                                {this.selectNewType("shippingPartner", mapping.shippingPartner, this.state.bulkAssign.shippingPartner)}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Pickup Date</label>
                                                {this.inputNewType("pickUpDate", this.state.bulkAssign.pickUpDate, "date")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm" >
                                                <label>Delivery Date</label>
                                                {this.inputNewType("deliveryDate", this.state.bulkAssign.deliveryDate, "date")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Cancellation Date</label>
                                                {this.inputNewType("cancellationDate", this.state.bulkAssign.cancellationDate, "date")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Platform fees</label>
                                                {this.inputNewType("platformFees", this.state.bulkAssign.platformFees, "number")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Shipping fees</label>
                                                {this.inputNewType("shippingFees", this.state.bulkAssign.shippingFees, "number")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Tracking Id</label>
                                                {this.inputNewType("trackingNumber", this.state.bulkAssign.trackingNumber, "text")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Invoice Number</label>
                                                {this.inputNewType("invoiceNumber", this.state.bulkAssign.invoiceNumber, "text")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Return Before Delivery Date</label>
                                                {this.inputNewType("returnBeforeDeliveryDate", this.state.bulkAssign.returnBeforeDeliveryDate, "date")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Return After Delivery Date</label>
                                                {this.inputNewType("returnAfterDeliveryDate", this.state.bulkAssign.returnAfterDeliveryDate, "date")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Return Receipt Date</label>
                                                {this.inputNewType("returnReceiptDate", this.state.bulkAssign.returnReceiptDate, "date")}
                                            </div>
                                            <div className="col-md-2 subOrderBulkEditForm">
                                                <label>Return Initiated</label>
                                                {this.selectNewType("returnInitiated", ['Yes', 'No'], this.state.bulkAssign.returnInitiated)}
                                            </div>
                                            <div className="col-md-2" style={{ marginTop: "32px" }}>
                                                <button className="btn btn-primary btn-sm" onClick={(e) => { this.handleBulkAssign(e) }}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <ReactTable
                                        filterable={true}
                                        showPagination={true}
                                        data={subOrders}
                                        columns={subOrderColumns}
                                        ref={this.reactTable}
                                        defaultPageSize={5}
                                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="card shadow">
                                <div className="card-body">
                                    <div className="card-title">
                                        <h4><b>Tickets</b></h4>
                                        <hr></hr>
                                    </div>
                                    <ReactTable
                                        filterable={true}
                                        showPagination={true}
                                        data={ticketDataForOrderId}
                                        columns={ticketColumns}
                                        ref={this.reactTable}
                                        defaultPageSize={5}
                                        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ padding: "0px" }}>
                        <div className="topbar-section col-9">
                            <div className="container">
                                <div>
                                    <h4><b>Comments</b></h4>
                                </div>
                                <hr></hr>
                                <div>
                                    <div>
                                        {(this.state.comments || []).map(comment => {
                                            return <div><b>{comment.commentedBy.split('@')[0].split('.')[0] + ' ' + comment.commentedBy.split('@')[0].split('.')[1]} - {moment(+(comment.timestamp)).format('DD/MM/YYYY hh:mm a')}</b> - {comment.commentBody}</div>
                                        })}
                                    </div>
                                    <div className="row" style={{ width: "80%", margin: "0px", padding: "0px" }}>
                                        <div className="col-md-10 form-group"  >
                                            <label for="newComment"></label>
                                            <textarea placeholder="Add New Comment" className="form-control" rows="2" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
                                        </div>
                                        <div style={{ marginTop: "46px", marginLeft: '0px' }} className="col-md-1 form-group">
                                            <button className="btn btn-success" onClick={(e) => this.commentSubmit(e)}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="card shadow">
                            <div className="card-body">
                                <div className="card-title">
                                    <h4><b>History</b></h4>
                                    <hr></hr>
                                </div>
                                <ReactTable
                                    filterable={true}
                                    showPagination={true}
                                    data={historyData}
                                    columns={zunsolarOrderHistoryColumns}
                                    defaultPageSize={5}
                                    defaultSorted={[{ id: "timestamp", desc: true }]}
                                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                                />
                            </div>
                        </div>
                    </div>
                </div> : <Loader />}
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps(state) {
    let { mapping = {}, subOrders = [], orderData = {}, comments = [], inventoryData = [], comboLots = [], historyData = [] } = state.zunSolarOrderTracker || {};
   let {ticketDataForOrderId=[]} = state.IVRReducer || [];

    return {
        mapping,
        subOrders,
        orderData,
        comments,
        inventoryData,
        comboLots,
        historyData,
        ticketDataForOrderId
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMapping, fetchOrderData, fetchSubOrders, updateOrderData, addComment, fetchComments, fetchInventory, updateSubOrderData, getSearchResult, fetchComboLots, updateComboLots, deleteOrder, editBulkSubOrders, downloadInventoryFile, generateInvoice, downloadInvoicePdf, fetchUpdateHistory,fetchTicketsForOrderId }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarOrderView);