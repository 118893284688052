import React from 'react';
import { fetchAllLeads, getComments, downloadFile } from '../../actions/fetchAllLeads';
import { fetchMappingZunpulse, fetchAllMapping } from '../../actions/fetchAllMapping';
import { getSearchResult } from '../../actions/searchAction';
import { editBulkLeads } from '../../actions/editMultipleLeadsAction'
import { getUserInfo } from '../../actions/user'
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { render } from '@testing-library/react';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorComponent } from '../../components/ErrorComponent';
import { Loader } from '../../components/loader'
import ZunsolarConsultativeSalesView from '../../components/zunsolar/ZunsolarConsultativeSalesView';

class ZunsolarConsultativeSales extends Component {
  componentWillMount() {
    let viewList = ["employees", "pulsePOC"];
    let filterObj = {leadType:["Consultative Sales"]}

    this.props.fetchAllMapping(viewList);
    this.props.fetchAllLeads(100, 1, [], filterObj, 'zunpulse');
    this.props.fetchMappingZunpulse();

    // this.props.getSearchResult()
  }

  render() {
    if (this.props.error) {
      return (
        <div>
          <ErrorComponent error={this.props.error} />
        </div>
      )
    }

    return (
      <div>
        <Header />
        <ZunsolarConsultativeSalesView allLeads={this.props.allLeads} allMapping={this.props.allMapping} fetchAllLeads={this.props.fetchAllLeads} searchData={this.props.searchData} editBulkLeads={this.props.editBulkLeads} getComments={this.props.getComments} comments={this.props.comments} downloadCsv={this.props.downloadFile} />
      </div>
    )


  }
}


function mapStateToProps(state) {

  return {
    allLeads: state.allFetchLead ? state.allFetchLead : {},
    allMapping: state.fetchAllMapping ? state.fetchAllMapping : {},
    comments: state.comments.data ? state.comments.data : [],
    searchData: [],
    error: state.allFetchLead.error ? state.allFetchLead.error : undefined

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ editBulkLeads, fetchAllLeads, fetchAllMapping, fetchMappingZunpulse, getSearchResult, getUserInfo, getComments, downloadFile }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarConsultativeSales);