import React from 'react';
import { getSearchResult } from '../../actions/searchAction';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import Header from '../../components/HeaderComponent';
import { connect } from 'react-redux';
import Select from 'react-select';
import { repeatNotificationFieldConfig } from '../../config/column_fields';
import axios from 'axios';
import url, { appBackendUrl, zcommerceUrl } from '../../config';
import { Redirect } from 'react-router-dom';
import {  mainDataMapper } from '../../helper/dataMapper';
import '../../styles/addCoupon.css';

const ApiVersionOptions = [
    { label: 'New', value: 'New' },
    { label: 'Old', value: 'Old' }
];

const NotificationType = [
    // { label: 'Bulk', value: 'bulk' },
    { label: 'Testing', value: true },
    { label: 'Production', value: false },
   
]

const IsNameIncludes = [
    // { label: 'Bulk', value: 'bulk' },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
   
]

class ZunpulseRepeatNotification extends Component {

    constructor() {
        super()
        this.state = {
            isTesting: true,
            formData: {},
            isNameInclude: false,
            userObj: {},
            freeProducts: [{count:0, pid:''}],
            apiVersion: 'New'
        };
    }

  

    componentWillMount() {


        if (localStorage.getItem("userObj")) {
            let userObj = JSON.parse(localStorage.getItem("userObj"));
            this.setState({
                userObj
            })
        }
    }

    handleNotificationTypeChange = (e) => {
        console.log('e',e);
        const value = e.value == "testing" ? true : false
        this.setState({
            isTesting: e.value,
            formData: {}
        })
    }

    handleApiVersionChange = (e) => {
        console.log('e',e);
        this.setState({
            apiVersion: e.value
        })
    }

    handleNameIncludeChanges = (e) => {
        console.log('e',e);
        this.setState({
            isNameInclude: e.value,
            formData: {}
        })
    }
  

    
    CouponTypeInput = ({ name, type, required,min }) => {
        return (
            <input type={type}  className="form-control" name={name} onChange={(e) => this.handleInputChange(e)} required={required}></input>
        )
    }

    CouponTypeSelect = ({ name, optionsName, isMulti }) => {
        let options = this.state.mapping[optionsName] || [];
        return (
            <Select
                isSearchable
                isMulti={isMulti}
                onChange={(e) => { this.handleSelectChange(e, name) }}
                options={options}
            />
        )
    }
    CouponTypeInputV2 = ({ name, type, required,min , index}) => {
        return (
            <input type={type} min={min}  className="form-control" name={name} onChange={(e) => this.handleInputChangeV2(e, index)} required={required}></input>
        )
    }

    CouponTypeSelectV2 = ({ name, optionsName, isMulti, index }) => {
        let options = this.state.mapping[optionsName] || [];
        return (
            <Select
                isSearchable
                isMulti={isMulti}
                onChange={(e) => { this.handleSelectChangeV2(e, name, index) }}
                options={options}
            />
        )
    }

    InputField = ({ label, name, type, required, optionsName, isMulti }) => {
        let { CouponTypeInput, CouponTypeSelect } = this;
        return (
            <div className="row">
                <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                    {label}
                </label>
                <div className=" col-md-8">
                    {
                        type == 'select' ? <CouponTypeSelect
                            name={name}
                            optionsName={optionsName}
                            isMulti={isMulti}
                        /> : <CouponTypeInput
                            name={name}
                            type={type}
                            required={required}
                        />
                    }
                </div>
            </div>
        )
    }

    InputFieldV2 = ({ label, name, type, required, optionsName, isMulti,indexValue}) => {
        console.log('index', indexValue);
        let { CouponTypeInputV2, CouponTypeSelectV2 } = this;
        return (
            <div className='col-md-6 row'>
                <label className="col-md-6 mb-2" style={{ fontSize: "17px", fontWeight: "bold" }}>
                    {label}
                </label>
                <div className=" col-md-6">
                    {
                        type == 'select' ? <CouponTypeSelectV2
                            name={name}
                            optionsName={optionsName}
                            isMulti={isMulti}
                            index={indexValue}
                        /> : <CouponTypeInputV2
                            name={name}
                            type={type}
                            required={required}
                            min={1}
                            index={indexValue}
                        />
                    }
                </div>
            </div>
        )
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleInputChangeV2 = (e, index)=>{
        let { name, value } = e.target;
        console.log('target', name,value);
        let {freeProducts} = this.state;
        if(value ){ 
            console.log('index free', index, freeProducts[index]);
            freeProducts[index].count = parseInt(value);   
            
        }
        console.log('freeProducts', freeProducts);  
        this.setState({
            freeProducts
        })

    }

    handleSelectChange = (e, name) => {
        if(!e) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: ""
                }
            });
            return;
        }
        let value = '';
        console.log('event', e, name);
        if (Array.isArray(e)) {
            value = e.map(row => row.value);
        }
        else {
            value = e.value;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleSelectChangeV2 = (e, name, index) => {
        let value = '';
        let {freeProducts} = this.state;
        console.log('event', e);
       
       
            value = e.value;
       
        console.log('value ', value);
            freeProducts[index].pid=value
        this.setState({
            freeProducts
           
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        console.log('is Name include', this.state.isNameInclude);
        console.log('is testing notification', this.state.isTesting)
        console.log("form Data", this.state.formData);
       
        // return;
        const userData =  JSON.parse(localStorage.getItem('userObj'));
        const createdBy = userData.email || "devteam@zunpulse.com"
        try {

            if(!this.state.formData.notificationMessage || !this.state.formData.notificationTitle){
                alert('Insufficient data')
            }

            let apiData, response;

            if(this.state.apiVersion == 'New') {
                apiData= {
                    authPhoneNumber: "+917011352085",
                    apiKey: appBackendUrl.apiKey,
                    isNameInclude : this.state.isNameInclude,
                    notificationMessage: this.state.formData.notificationMessage,
                    notificationTitle: this.state.formData.notificationTitle,
                    isTesting : this.state.isTesting,
                    redirectUrl: this.state.formData.redirectUrl,
                    createdBy: createdBy
                }
                // return;
                console.log('new api data', apiData);
                // return;
                response = await axios.post(`https://app.zunhome.in/v1.0.1/notification/send-repeat-notification`, apiData);
                response = response.data || {};
                this.setState({
                    formData: {}
                })
                if(response && response.data && response.data == '') {
                    alert("Notification Send SuccessFully");
                    return;
                }
                alert("Request Failed");
                return;
            }
            else {
                apiData= {
                    apiKey: appBackendUrl.apiKey,
                    isTesting : this.state.isTesting,
                    isNameInclude : this.state.isNameInclude,
                    notificationTitle: this.state.formData.notificationTitle,
                    notificationMessage: this.state.formData.notificationMessage,
                    createdBy: createdBy
                    
                }
                console.log('old api data', apiData);
                response = await axios.post(`${appBackendUrl.baseUrl}specialRepeatNotificationV2`, apiData);
                response = response.data;
                this.setState({
                    formData: {}
                })
            
                if(response.status=="Done Successfully"){
                    alert("Notification Send SuccessFully");
                    return;
                }
            
                alert("Request Failed");
                return;
            }
            

        } catch (error) {
            console.log("errr", error)
            this.setState({ formData: {} })
            alert("Failed");
            return;


        }


    }

    render() {

        let { InputField, state, InputFieldV2 } = this;

        if(!((this.state.userObj.access || []).includes("Website-Admin"))) {
            return <Redirect to={"/"} />
        }

        return (
            <div>
                <Header />
                <div className="container" style={{ marginTop: "80px", textAlign: 'center' }}>
                    <h4 style={{ fontWeight: "bold" }}>Repeat Sales Notification</h4>
                    <hr />
                    <div className="row">
                        <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                           API Version
                        </label>
                        <div className=" col-md-8">
                            <Select
                                isSearchable
                                onChange={(e) => { this.handleApiVersionChange(e) }}
                                options={ApiVersionOptions}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                           Notification Test/Prod
                        </label>
                        <div className=" col-md-8">
                            <Select
                                isSearchable
                                onChange={(e) => { this.handleNotificationTypeChange(e) }}
                                options={NotificationType}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-4 mb-4" style={{ fontSize: "17px", fontWeight: "bold" }}>
                        Is Name Include? 
                        </label>
                        <div className=" col-md-8">
                            <Select
                                isSearchable
                                onChange={(e) => { this.handleNameIncludeChanges(e) }}
                                options={IsNameIncludes}
                            />
                        </div>
                    </div>

                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        {
                            repeatNotificationFieldConfig.filter((config) => {
                                if(config.name == 'redirectUrl' && state.apiVersion == 'Old') return false;
                                return true;
                            }).map((config, index) => <InputField key={index} {...config} />)
                        }
    
                        
                             <button className="btn btn-success mt-4 float-right" type="submit">Submit</button> 
                        
                    </form>
                </div>
            </div>
        )
    }

}

function mapStateToProps({ }) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSearchResult }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZunpulseRepeatNotification);