import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { zunpulseOrderColumns } from '../../config/column_fields';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { fetchPostSalesMetrics } from '../../actions/whatsAppActions.js';
import moment from 'moment';
import swal from 'sweetalert';


class WhatsAppPostSalesMetrics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      metricsData : [],
      loading : true,
      filterData : {},
      languages: [{ value: "English", label: "English" }, { value: "Hindi", label: "Hindi" }, { value: "Telugu", label: "Telugu" }, { value: "Gujarati", label: "Gujarati" }, { value: "Kannada", label: "Kannada" }]
    };
    this.statusMap = ["Not Ordered", "Ordered", "Shipped", "Delivered"];
  }

  componentWillMount() {
    this.props.fetchPostSalesMetrics();
  }

  componentWillReceiveProps(props) {
    let { whatsAppMetricsData = [] } = props;
    this.setState({
      metricsData: whatsAppMetricsData.data || [],
      loading : false
    })
  }

  dateType = (name, value, field) => {
    return (
      <div class="form-group">
        <span class="dateSideBar" />
        <input name={name} style={{ width: "100%" }} className="form-control" type="date" onChange={(e) => { this.handleDateChange(e, field) }} value={value}></input>
      </div>
    )
  }

  handleDateChange = (e, field) => {
    let { name, value } = e.target
    this.setState({
      filterData: {
        ...this.state.filterData,
        [field]: {
          ...this.state.filterData[field] ? this.state.filterData[field] : {},
          [name]: value
        }
      },
      page: 1
    });
  }

  multiSelectType = (name, options, placeholder, value, isMulti = true) => {
    return (
      <div className="form-group" style={{ width: '100%' }}>
        <Select
          isMulti = {isMulti}
          isSearchable
          onChange={(e) => { this.handleMultiSelectChange(e, name) }}
          placeholder={placeholder}
          name={name}
          options={options}
        />
      </div>
    )
  }

  handleMultiSelectChange = (e, name) => {
    let selectedList;
    if (Array.isArray(e)) {
      selectedList = (e || []).map((data) => {
        return data.value
      });
      if (selectedList.length <= 1) {
        selectedList = selectedList[0]
      }
    } else {
      selectedList = (e || {}).value || undefined
    }
    if (name == "messageType") {
      selectedList = { [name] : selectedList};
    }
    this.setState({
      filterData: {
        ...this.state.filterData,
        [name]: selectedList
      },
      page: 1
    })
  }

  removingNullKeys = (obj) => {

    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }

    return obj
  }

  onApplyFilter = (e) => {
    let filterData = { ...this.state.filterData }
    filterData = JSON.parse(JSON.stringify(filterData));
    let filterObj = this.removingNullKeys(filterData);
    console.log("Naman", filterObj);
    if (Object.keys(filterObj).length > 0) {
      this.props.fetchPostSalesMetrics(filterObj);
      this.setState({
        // filterObj: filterObj
      })
    } else {
      swal("Please apply atleast one filter")
    }
  }

  render() {
    // console.log("100", Object.values(this.state.ordersData))
    let { metricsData } = this.state;
    const columns = [
      {
        Header : "Month",
        accessor : "startDate"
      },
      {
        Header : "Total Leads",
        accessor : "totalCount"
      },
      {
        Header : "Conversations",
        accessor : "conversationCount"
      },
      {
        Header : "Menu Options",
        accessor : "menuCount"
      },
      {
        Header : "Layouts Approved",
        accessor : "layoutApprovedCount"
      },
      {
        Header : "CallBack Tickets",
        accessor : "callBackTicketCount"
      }
    ]
    return (
      <div>
        <Header getSearchResult={this.props.getSearchResult} />
        <div style={{ "marginTop": 80 }} className="container-fluid">
          <div>
            <div className="topbar-section allLeadViewFilters">
              <div className="col-md-4"><b>Filters</b></div>
              <hr></hr>
              <div className="row">
                <div className="col-md-2">
                  <label>Created On Start</label>
                  {this.dateType("startDate", this.state.filterData.createdOn ? this.state.filterData.createdOn.startDate : "", "createdOn")}
                </div>
                <div className="col-md-2">
                  <label>Created On End</label>
                  {this.dateType("endDate", this.state.filterData.createdOn ? this.state.filterData.createdOn.endDate : "", "createdOn")}
                </div>
                <div className="col-md-2">
                  <label>Language</label>
                  {this.multiSelectType("language", this.state.languages, "Language", this.state.filterData.language)}
                </div>
                <div>
                  <label></label><br></br>
                  <button onClick={this.onApplyFilter} className="btn btn-success">Apply filter</button>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <ul>
                  <li><b>Total Leads -</b> No. of individual customers.</li>
                  <li><b>Conversations -</b> No. of messages sent to customers (Messages sent over a span of 4 hous are counted as 1).</li>
                  <li><b>Menu Option -</b> No. of times menu message was sent.</li>
                  <li><b>Layouts Approved -</b> No. of layouts approved.</li>
                  <li><b>CallBack Tickets -</b> No. of individual customers who requested for callback.</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <ReactTable
            filterable={true}
            showPagination={false}
            sortable={true}
            data={metricsData}
            columns={columns}
            pageSize={this.state.metricsData.length}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
            loading={this.state.loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // console.log("state", state)
  let { whatsAppMetricsData } = state;
  return {
    whatsAppMetricsData
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchPostSalesMetrics }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsAppPostSalesMetrics);
