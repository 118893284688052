import React from 'react';
import Select from 'react-select';
import ReactTable from "react-table";
import { Chart } from "react-google-charts";
import url from '../../config';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';

export const SalesSummary = ({ columns, data, filters, stateOptions, productOptions, shippingStatusOptions, handleDateChange, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Sales Summary</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "product", e)}
                            placeholder={"Choose Product"}
                            name={"product"}
                            options={productOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}


export const ShippingSummary = ({ columns, data, stateOptions, productOptions, sourceOptions, handleDateChange, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Shipping Summary</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "product", e)}
                            placeholder={"Choose product"}
                            name={"product"}
                            options={productOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "source", e)}
                            placeholder={"Source"}
                            name={"source"}
                            options={sourceOptions}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const OrderValueView = ({ columns, data, stateOptions, productOptions, shippingStatusOptions, sourceOptions, handleDateChange, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Order Value</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"Start Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="startDate" placeholder={"Start Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{"End Date"}</span>
                                </div>
                                <input className="form-control customInput" type="date" name="endDate" placeholder={"End Date"} onChange={(e) => handleDateChange(type, "date", e)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "product", e)}
                            placeholder={"Choose product"}
                            name={"product"}
                            options={productOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "source", e)}
                            placeholder={"Source"}
                            name={"source"}
                            options={sourceOptions}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}


export const LastTenDaysSummary = ({ columns, data, stateOptions, productOptions, shippingStatusOptions, handleDateChange, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Last 10 Days Summary</div>
                    <div style={{ float: 'right' }}>
                        <button onClick={(e) => applyFilter(type)} className="btn btn-success btn-sm">Apply Filter</button>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "product", e)}
                            placeholder={"Choose Product"}
                            name={"product"}
                            options={productOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={data.length || 5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "orderDate", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const MetricsSummaryComponent = ({ data = {}, productIdNameMapping = {} }) => {
    return (
        <div className="card mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Today's Metrics Summary</div>
                </div>
                <hr />
                <div className="card-title" style={{ textAlign: 'center' }}>
                    <div className="row mb-3">
                        <div style={{ fontWeight: "bold" }} className="col-3">Total SKU</div>
                        <div className="col-3">{data.SKU}</div>
                        <div style={{ fontWeight: "bold" }} className="col-3">Total Units</div>
                        <div className="col-3">{data.units}</div>
                    </div>
                    <div className="row">
                        <div style={{ fontWeight: "bold" }} className="col-3">Amount</div>
                        <div className="col-3">{data.amount}</div>
                        <div style={{ fontWeight: "bold" }} className="col-3">Best Seller of the Week</div>
                        <div className="col-3">{(data.bestSeller || []).map(row => productIdNameMapping[row.productId] || row.productId).join(',')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const MetricsSummaryComponentNew = ({ data = {}, productIdNameMapping = {} }) => {
    return (
        <div className="metricsSummary mt-3">
            <div style={{ fontWeight: "bold" }}>Today's Metrics Summary</div>
            <div class="row mt-3 summaryCards">
                <div class="col-md-4 col-xl">
                    <div class="card bg-c-blue order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Total Orders</h5>
                            <h2 class="text-right"><i class="fas fa-shopping-cart f-left"></i><span>{data.totalOrders}</span></h2>
                        </div>
                    </div>
                </div>

                {/* <div class="col-md-4 col-xl">
                    <div class="card bg-c-green order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Total SKU</h5>
                            <h2 class="text-right"><i class="fa fa-cart-plus f-left"></i><span>{data.SKU}</span></h2>
                        </div>
                    </div>
                </div> */}

                <div class="col-md-4 col-xl">
                    <div class="card bg-c-green order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Total Units</h5>
                            <h2 class="text-right"><i class="fa fa-truck f-left"></i><span>{data.units}</span></h2>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-xl">
                    <div class="card bg-c-yellow order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Amount</h5>
                            <h2 class="text-right"><i class="fa fa-rupee-sign f-left"></i><span>{data.amount}</span></h2>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-xl">
                    <div class="card bg-c-pink order-card">
                        <div class="card-block">
                            <h5 class="m-b-20">Best Seller of the Week</h5>
                            <h5 class="text-right"><i class="fa fa-trophy f-left"></i><span className="ml-3">{(data.bestSeller || []).map(row => productIdNameMapping[row.productId] || "").join(',')}</span></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export const SalesSummaryComponent = ({ columns, data, filters, stateOptions, productOptions, shippingStatusOptions, handleSelectChange, applyFilter, loading, type, tableType }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Sales Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    { tableType != "Order" ? <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "productId", e)}
                            placeholder={"Choose Product"}
                            name={"productId"}
                            options={productOptions}
                        />
                    </div> : "" }
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const SalesSummaryOrderViewComponent = ({ columns, data, filters, stateOptions, shippingStatusOptions, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Order Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}


export const ShippingSummaryComponent = ({ columns, data, stateOptions, productOptions, sourceOptions, handleSelectChange, applyFilter, loading, type, tableType }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Shipping Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    { tableType != "Order" ? <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "productId", e)}
                            placeholder={"Choose product"}
                            name={"productId"}
                            options={productOptions}
                        />
                    </div> : "" }
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "source", e)}
                            placeholder={"Source"}
                            name={"source"}
                            options={sourceOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const OrderValueViewComponent = ({ columns, data, stateOptions, productOptions, shippingStatusOptions, sourceOptions, handleSelectChange, applyFilter, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Sub-Order Value</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    {/* <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "productId", e)}
                            placeholder={"Choose product"}
                            name={"productId"}
                            options={productOptions}
                        />
                    </div> */}
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "source", e)}
                            placeholder={"Source"}
                            name={"source"}
                            options={sourceOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const LastTenDaysSummaryComponent = ({ columns, data, stateOptions, productOptions, shippingStatusOptions, handleSelectChange, applyFilter, loading, type, tableType }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Last 10 Days Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    { tableType != "Order" ? <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "productId", e)}
                            placeholder={"Choose Product"}
                            name={"productId"}
                            options={productOptions}
                        />
                    </div> : "" }
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "status", e)}
                            placeholder={"Shipping Status"}
                            name={"status"}
                            options={shippingStatusOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "date", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}

export const CategorySummaryComponent = ({ columns, data, loading, type }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>Category Summary</div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultSorted={[{ id: "month", desc: true }]}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}


export const StateSummaryComponent = ({ columns, data, stateOptions, handleSelectChange, applyFilter, loading, type, selectedCategory, handleCategoryChange, categoryOptions }) => {
    return (
        <div className="card shadow mt-3">
            <div className="card-body">
                <div className="card-title mb-5">
                    <div style={{ float: 'left', fontWeight: "bold" }}>State Summary</div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            isMulti
                            onChange={(e) => handleSelectChange(type, "state", e)}
                            placeholder={"Choose State"}
                            name={"state"}
                            options={stateOptions}
                        />
                    </div>
                    <div className="col-md-2">
                        <Select
                            isSearchable
                            onChange={(e) => handleCategoryChange(e)}
                            placeholder={"Choose Category"}
                            name={"stateTableSelectedCategory"}
                            options={categoryOptions}
                            defaultValue={{ label: selectedCategory, value: selectedCategory }}
                        />
                    </div>
                    <div className="col-md-2">
                        <button onClick={(e) => applyFilter(type)} className="btn btn-info btn-sm">Apply Filter</button>
                    </div>
                </div>
                <ReactTable
                    filterable={true}
                    sortable={true}
                    showPagination={true}
                    defaultPageSize={5}
                    data={data}
                    columns={columns}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                    loading={loading}
                    style={{ textAlign: 'center' }}
                />
            </div>
        </div>
    )
}


export const MapViewComponent = ({ data }) => {
    return (
        <div className="mapView mt-3">
            <Chart
                chartEvents={[
                    {
                        eventName: "select",
                        callback: ({ chartWrapper }) => {
                            const chart = chartWrapper.getChart();
                            const selection = chart.getSelection();
                            if (selection.length === 0) return;
                            const region = data[selection[0].row + 1];
                            console.log("Selected : " + region);
                        }
                    }
                ]}
                chartType="GeoChart"
                width="100%"
                height="700px"
                data={data}
                options={{ region: "IN", resolution: 'provinces', displayMode: 'regions' }}
                mapsApiKey={url.googleMapsKey}
            />
        </div>
    )
}