import React, { Component } from "react";
import { getSearchResult } from "../../actions/searchAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { consolidatedListColumns } from "../../config/column_fields";
class ConsolidatedView extends Component {
  constructor() {
    super();
    this.state = {
      consolidatedList: [],
      ListColumns: [],
    };
  }

  componentDidMount() {
    let ListColumns = consolidatedListColumns.map((row) => {
      if (row.accessor == "status") {
        row.Cell = (row) => {
          return this.state.consolidatedList[row.index].status === "false"
            ? "Not Approved"
            : "Approved";
        };
      }
      return row;
    });
    this.setState({ ListColumns });
  }

  componentWillMount() {
    this.props.fetchConsolidatedList();
  }

  componentWillReceiveProps({ consolidatedList = [] }) {
    this.setState({ consolidatedList });
  }

  render() {
    let { consolidatedList, ListColumns } = this.state;

    return (
      <div className="procurementAddVendor">
        <ReactTable
          filterable={true}
          showPagination={true}
          sortable={true}
          data={consolidatedList}
          columns={ListColumns}
          defaultSorted={[{ id: "id", desc: true }]}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSearchResult }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsolidatedView);
