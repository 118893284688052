import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import Select from 'react-select';
import { subOrderColumns } from '../../config/column_fields';
import Modal from 'react-responsive-modal';
import { fetchMapping, fetchSubOrderData, updateSubOrderData } from '../../actions/zunsolarOrderTrackerActions';
import moment from 'moment';
import Header from '../../components/HeaderComponent';
import { getSearchResult } from '../../actions/searchAction';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/loader'

class ZunsolarSubOrderView extends Component {


    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            subOrderData: {},
            editedData: {},
            mapping: {}
        };
    }


    componentWillMount() {
        this.props.fetchMapping();
        let orderId = this.props.match.params.id;
        this.props.fetchSubOrderData(orderId);
    }

    componentWillReceiveProps(props) {
        let { mapping, subOrderData = {} } = props;
        this.setState({
            loading: false,
            received: true,
            mapping: mapping,
            subOrderData
        })
    }

    inputType = (name, value, type) => {
        return <input type={type} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
    }

    selectType = (name, value, options) => {
        return (
            <div style={{ width: '100%' }}>
                <select
                    onChange={(e) => { this.handleInputChange(e) }}
                    className="form-control" id="sel1" name={name} value={value}
                >
                    <option key="-1" value="">Select</option>
                    {(options ? options : []).map(e => <option key={e} value={e}>{e}</option>)}
                </select>
            </div>
        )
    }

    editOrderSubmit = () => {
        let { subOrderData, editedData } = this.state;
        console.log("ed", editedData);
        this.props.updateSubOrderData(subOrderData.orderId,subOrderData.subOrderId, subOrderData, editedData);
        this.setState({
            edit: false
        })
        console.log("valid");
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            editedData: {
                ...this.state.editedData,
                [name]: value,
            }
        })
    }

    handleEdit = () => {
        this.editDetails = {};
        if (this.state.edit) {
            this.setState({
                edit: false
            })
        } else {
            this.setState({
                editedData: { ...this.state.subOrderData },
                edit: true
            })
        }
    }

    onClickCancel = () => {
        this.setState({
            edit: false
        })
    }

    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }
        let { mapping, subOrderData } = this.state;
        // let statusMap = (mapping.status || []).map(val => ({ label: val, value: val }))
        // let partnerOptions = (mapping.shippingPartner||[]).map(val => ({ label: val, value: val }))
        return (
            <div>
                <Header getSearchResult={this.props.getSearchResult} />
                {this.state.received ? <div style={{ "marginTop": 80 }} className="container-fluid">
                    <div className="topbar-section" >
                        <div className="row">
                            <div className="col-md-10 col-xs-12 topbar-left">
                                <div className="col-md-12">
                                    <strong>Sub Order Id - {subOrderData.subOrderId}</strong>
                                </div>
                            </div>
                            <div className="col-md-2 col-xs-12 topbar-right">
                                <div className="col-md-12 col-xs-12">
                                    <span style={this.state.edit ? style.hide : style.show}><i onClick={this.handleEdit} style={{ cursor: "pointer", float: "right", marginTop: "8px" }} className="fas fa-pen"></i></span>
                                    <button style={this.state.edit ? style.show : style.hide} className="button-edit" onClick={(e) => this.editOrderSubmit(e)}>Save</button>
                                    <button style={this.state.edit ? style.show : style.hide} className="button-cancel" onClick={(e) => this.onClickCancel(e)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table>
                                <tr>
                                    <td>Category</td>
                                    <td>
                                        {
                                            <span>{this.state.subOrderData.category}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sub Category</td>
                                    <td>
                                        {
                                            <span>{this.state.subOrderData.subCategory}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("status", this.state.editedData.status, mapping.status||[])}
                                            </span> : <span>{this.state.subOrderData.status}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Shipping Partner</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.selectType("shippingPartner", this.state.editedData.shippingPartner, mapping.shippingPartner||[])}
                                            </span> : <span>{this.state.subOrderData.shippingPartner}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tracking Number</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("trackingNumber", this.state.editedData.trackingNumber, "text")}
                                            </span> : <span>{this.state.subOrderData.trackingNumber}</span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info" style={{ borderRight: '0px' }}>
                            <table className="customer-info-left">
                                <tr>
                                    <td>Pick Up Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("pickUpDate", this.state.editedData.pickUpDate, "date")}
                                            </span> : <span>{this.state.subOrderData.pickUpDate}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Delivery Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("deliveryDate", this.state.editedData.deliveryDate, "date")}
                                            </span> : <span>{this.state.subOrderData.deliveryDate}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Cancellation Date</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("cancellationDate", this.state.editedData.cancellationDate, "date")}
                                            </span> : <span>{this.state.subOrderData.cancellationDate}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Online Price</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("onlinePrice", this.state.editedData.onlinePrice, "number")}
                                            </span> : <span>{this.state.subOrderData.onlinePrice}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Platform Fees</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("platformFees", this.state.editedData.platformFees, "number")}
                                            </span> : <span>{this.state.subOrderData.platformFees}</span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-4 customer-info">
                            <table className="customer-info-right">
                                <tr>
                                    <td>Shipping Fess</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("shippingFees", this.state.editedData.shippingFees, "number")}
                                            </span> : <span>{this.state.subOrderData.shippingFees}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Bank Transfer</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("bankTransfer", this.state.editedData.bankTransfer, "number")}
                                            </span> : <span>{this.state.subOrderData.bankTransfer}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Invoice Number</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("invoiceNumber", this.state.editedData.invoiceNumber, "text")}
                                            </span> : <span>{this.state.subOrderData.invoiceNumber}</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Lot No</td>
                                    <td>
                                        {
                                            this.state.edit ? <span className="input-group">
                                                {this.inputType("lotNo", this.state.editedData.lotNo, "text")}
                                            </span> : <span>{this.state.subOrderData.lotNo}</span>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div> : <Loader />}
            </div>
        );


    }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
    const changeHandle = (value) => { handleMultiSelectChange(value, name) }
    return (
        <div className="drop_down1">
            <Select
                isMulti
                isSearchable
                onChange={(e) => { changeHandle(e) }}
                placeholder={placeholder}
                name={name}
                options={options}
                defaultValue={defaultValue}
            />
        </div>
    )
}


function mapStateToProps({ zunSolarOrderTracker }) {
    let { mapping = {}, subOrders = [], subOrderData = {}, comments = [] } = zunSolarOrderTracker || {};
    return {
        mapping,
        subOrders,
        subOrderData,
        comments
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchMapping, fetchSubOrderData, getSearchResult, updateSubOrderData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ZunsolarSubOrderView);